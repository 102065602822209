import React from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Modal
} from "reactstrap";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import {deHashVar} from "../../../module_components/Functions/handleHashedVariable";
import getValidFileName from "../../../module_components/Functions/validation/getValidFileName";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import isEmptyObject from "../../../module_components/Functions/isEmptyObject";

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));
const selectOptions = [
    {key: "ELECTRICITY", value: 'Strom'},
    {key: "GAS", value: 'Gas'},
];

class PfcCreateModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            submit_error: "",
            loading: false,
            file: {},
            energy_type: 'ELECTRICITY'
        };
    }

    toggleModal = () => {
        this.setState({loading: false, showModal: !this.state.showModal, file: {}})
    };

    turnOffModal(){
        this.setState({loading: false, showModal: false});
    }

    handleInputChange = (name,  value) => {
        this.setState({[name]: value});
    };

    submit = () => {
        const {file, energy_type} = this.state;

        if(typeof file.name !== 'undefined'){
            let newFile = getValidFileName(file);

            let medium_type = 0;
            let keep_updated = 0;

            if (energy_type === "GAS"){
                medium_type = 1;
            }

            let params = {
                keep_updated: keep_updated,
                medium_type: medium_type,
                foreign_user_id: supplier_id,
            };

            // callback to parent PFC to add in list
            this.props.addPFC(params, newFile);
            this.setState({loading: true});
        } else this.setState({submit_error: "Bitte laden Sie eine Datei hoch"});
    };

    render(){
        const {loading, energy_type, file, submit_error, showModal} = this.state;
        const {dynamic_action_call, default_file_upload} = this.props;

        return(
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={showModal}
                toggle={() => this.toggleModal()}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent modal-title">
                            <Row>
                                <Col lg={6}>
                                    <h2 className={'mb-0'}>HPFC hinzufügen</h2>
                                </Col>
                                <Col lg={6}>
                                    <button onClick={() => this.toggleModal()} className={'close'} ><i className={'ni ni-fat-remove close-button'} /></button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="px-lg-4 py-lg-4">
                            <InputHandler
                                content_title="Energie"
                                type="select"
                                name="energy_type"
                                value={energy_type}
                                disabled={loading}
                                is_loaded={true}
                                on_change={(name, value) => this.handleInputChange(name, value)}
                                select_options={selectOptions}
                                remove_empty_option
                            />

                            <InputHandler content_title='PFC'
                                          type="file_upload_new"
                                          name='file'
                                          value={file.name}
                                          file_type="xlsx" // images / pdf / xlsx -> default
                                          return_file={(file) => this.handleInputChange("file", file)}
                                          allow_reupload={true}
                                          error_state={submit_error}
                                          dynamic_action_call={dynamic_action_call}
                                          default_file_upload={default_file_upload}
                            />

                            <div className={'modal-text-section pt-3'}>
                                Bitte achten Sie auf die folgende Vorgaben, um eine HPFC hochzuladen. Eine Beispieldatei können Sie als Orientierungshilfe hier downloaden.
                                <ul className='pfc-checklist'>
                                    <li className='ni ni-check-bold'><span>Wertintervall zwischen 15 Minuten und einem Tag</span></li>
                                    <li className='ni ni-check-bold'><span>Einheit der Preise in EUR/MWh</span></li>
                                    <li className='ni ni-check-bold'><span>Art der Preise: Base-Preise</span></li>
                                    <li className='ni ni-check-bold'><span>Deutsches .csv-Format mit Semikolon; als Trennzeichen</span></li>
                                    <li className='ni ni-check-bold'><span>Die erste Zeile wird nicht ausgelesen (Titelzeile)</span></li>
                                </ul>
                            </div>
                            <div className="text-center">
                                <PrimaryButton loading={loading} id='createPFCSubmit' disabled={isEmptyObject(file) || loading} onClick={this.submit} value='PFC hinzufügen' classes='btn-modal full-width-button' />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        );
    }
}

export default PfcCreateModal;