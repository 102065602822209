import {private_profiles, rlmProfiles, slpProfiles} from "./global";
import validateVar from "./validation/validateVariable";

function getBusinessTypeDisplay(business_type, medium_type, consumption_type){
    let profile_name = "";
    if (consumption_type === "SLP") {
        if(Object.keys(private_profiles).includes(business_type)){
            profile_name = private_profiles[business_type];
        }else {
            profile_name = slpProfiles[medium_type][business_type];
        }
        if(!validateVar(profile_name)) profile_name = rlmProfiles[medium_type][business_type];
    } else if (consumption_type === "RLM") {
        profile_name = rlmProfiles[medium_type][business_type];
        if(!validateVar(profile_name)) profile_name = slpProfiles[medium_type][business_type];
    }
    if (validateVar(profile_name)) return profile_name;
    else return "";
}

export default getBusinessTypeDisplay;