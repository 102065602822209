import validateVar from "./validation/validateVariable";
export default function combine_states(res, state, name) {
    let result_array = res[name];
    let state_tmp = (validateVar(state) && validateVar(state[name])) ? state[name] : null;
    let combined_array = [];
    if (validateVar(state_tmp) && validateVar(state_tmp)) combined_array = state_tmp;
    if (validateVar(result_array) && result_array.length > 0) {
        combined_array = combined_array.concat(result_array);
    }
    return {
        [name]: combined_array,
        next_offset: res.next_offset,
        last_request: res.last_request,
        total_count: res.total_count
    };
}
