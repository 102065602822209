import {
    apiError
} from "../../Functions/global";
import Tree from "../../Objects/tree";
import {displayErrorMessage, displaySuccessMessage} from "../user_feedback";
import validateVar from "../../Functions/validation/validateVariable";
import is_true from "../../Functions/is_true";
import Tariff from "../../Objects/tariff";



/* REQUESTS */

export function init_all_attributes(params) {
    return function (dispatch) {
        Tree.init_all_attributes(params).then(res => {
            let is_global =  (validateVar(params) && validateVar(params.options) && params.options.is_global === 1);
            if (res !== apiError) {
                if (is_true(is_global)) {
                    dispatch({type: "INIT_GLOBAL_ATTRIBUTES", res: {attributes: res, is_global: is_global}});
                }
                else dispatch({type: "INIT_ALL_ATTRIBUTES", res: {attributes: res, is_global: is_global}})
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim Laden der Attributliste ist ein Fehler aufgetreten", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätige", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}


export function init_tree(params, all_attributes) {
    return function (dispatch) {
        Tree.init_tree(params, all_attributes).then(res => {
            if (res !== apiError) {
                if (is_true(params.global)) {
                    dispatch({type: "INIT_GLOBAL_TREE", res: {tree: res, all_attributes: all_attributes}})
                } else  dispatch({type: "INIT_TREE", res: {tree: res, all_attributes: all_attributes}})
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim Laden der Preisstrategie ist ein Fehler aufgetreten", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätige", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}


export function reset_tree(tree) {
    return function (dispatch) {
        dispatch({type: "RESET_TREE", res: tree})
    };
}

export function create_empty_tree(new_tree) {
    return function (dispatch) {
        dispatch({type: "CREATE_EMPTY_TREE", res: new_tree})
    };
}

export function handle_price_table_submit(price_table_data) {
    return function (dispatch) {
        Tariff.import_price_table(price_table_data).then(res_pricing => {
            if (res_pricing !== apiError) {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "Der Baum wurde erfolgreich gespeichert", // content
                        "Erfolg", //headline
                        "/tarif-dokumente/" + price_table_data.tariff_id, // url, // url
                        true, //automatic redirect
                        false //modal
                    )
                });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim speichern der Preistabelle ist ein Fehler aufgetreten",
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätige", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    }
}

export function handle_tree_submit(method, params, all_attributes, price_table_data = null) {
    return function (dispatch) {
        Tree.handle_tree(method, params, all_attributes).then(res => {
            if (res !== apiError) {
                res.is_global = (validateVar(params) && (params.is_global === 1));
                if (res.is_global) { // if its global tree
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "Die globale Preisstrategie wurde erfolgreich gespeichert", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            false //modal
                        )
                    });
                    dispatch({type: "INIT_TREE", res: {tree: res, all_attributes: all_attributes}})
                }  else {
                    if (validateVar(price_table_data)) { // price_table update request
                        Tariff.import_price_table(price_table_data).then(res_pricing => {
                            if (res_pricing !== apiError) {
                                dispatch({
                                    type: "SET_USER_MESSAGE",
                                    res: displaySuccessMessage(
                                        "Der Baum wurde erfolgreich gespeichert", // content
                                        "Erfolg", //headline
                                        "/tarif-dokumente/" + params.tariff_id, // url, // url
                                        true, //automatic redirect
                                        false //modal
                                    )
                                });
                            } else {
                                dispatch({type: "INIT_TREE", res: {tree: res, all_attributes: all_attributes}});
                                dispatch({
                                    type: "SET_USER_MESSAGE",
                                    res: displayErrorMessage(
                                        "Beim speichern der Preistabelle ist ein Fehler aufgetreten",
                                        "Fehler", //headline
                                        null, // url
                                        false, //automatic redirect
                                        true, //modal
                                        null, // primaryBtnText
                                        "Bestätige", // secondaryBtnText
                                        true, //allowToggle (user can close modal without pressing a btn)
                                        false, // successIcon
                                        false, // infoIcon
                                        "danger" //type - danger/success
                                    )
                                });
                            }
                        });
                    }  else { // redirect -> no price_table
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displaySuccessMessage(
                                "Der Baum wurde erfolgreich gespeichert", // content
                                "Erfolg", //headline
                                "/tarif-dokumente/" + params.tariff_id, // url, // url
                                true, //automatic redirect
                                false //modal
                            )
                        });
                    }
                }
            } else {
                let error_message = (method === "POST")  ?  "Anlegen" : "Aktualisieren";
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim " + error_message + " der Preisstrategie ist ein Fehler aufgetreten",
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätige", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}


/* FUNCTIONS WITH NO REQUESTS */
export function add_attribute_to_group(data) {
    return function (dispatch) {
        dispatch({type: "ADD_ATTRIBUTE_TO_GROUP", res: data})
    };
}


export function delete_attribute_from_group(data) {
    return function (dispatch) {
        dispatch({type: "DELETE_ATTRIBUTE_FROM_GROUP", res: data})
    };
}



export function sort_tree_groups(from, type = "move_down") {
    return function (dispatch) {
        dispatch({type: "RE_ORDER_TREE_GROUPS", res: {from: from, type: type}})
    };
}


export function group_copy(data) {
    return function (dispatch) {
        dispatch({type: "GROUP_COPY", res: data})
    };
}

export function group_add(data) {
    return function (dispatch) {
        dispatch({type: "GROUP_ADD", res: data})
    };
}

export function group_edit(data) {
    return function (dispatch) {
        dispatch({type: "GROUP_EDIT", res: data})
    };
}

export function group_delete(id) {
    return function (dispatch) {
        dispatch({type: "GROUP_DELETE", res: id})
    };
}

export function delete_branch(data){
    return function (dispatch) {
        dispatch({type: "BRANCH_DELETE", res: data})
    };
}

export function add_branch(unique_id) {
    return function (dispatch) {
        dispatch({type: "BRANCH_ADD", res: unique_id})
    };
}

export function set_text_form(data) {
    return function (dispatch) {
        dispatch({type: "SET_TEXT_FORM", res: data})
    };
}

export function edit_branch(data) {
    return function (dispatch) {
        dispatch({type: "BRANCH_EDIT", res: data})
    };
}

export function sort_branches(unique_id) {
    return function (dispatch) {
        dispatch({type: "SORT_BRANCH", res: unique_id})
    };
}

export function edit_branch_operator(data) {
    return function (dispatch) {
        dispatch({type: "EDIT_BRANCH_OPERATOR", res: data})
    };
}





