import validateVar from "../Functions/validation/validateVariable";
import History from "./Components/history";
import callClassWithString from "../Functions/callClassWithString";

let key_fact_vars = {
    is_sub_arr: "keyfacts",
    has_history: true,
    translation_object: {
        contract_extension_display: "Vertragsverlängerung",
        cancellation_period_display: "Kündigungsfrist",
        quantity_tolerance: "Mehr/Mindermengen Toleranz"
    },
    contract_extension: {
        contract_extension: null,
        contract_extension_display: {
            function_name: "handleExtentionPeriod",
            params: ["", false]
        }
    },
    cancellation_period: {
        cancellation_period: null,
        cancellation_period_display: {
            function_name: "handleCancellationPeriod",
            params: ["", false]
        }
    },
    quantity_tolerance: null,
};

class Key_Facts {
    static default_values = key_fact_vars;
    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(key_fact_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static dummy() {
        let output = {};
        output = this.build_dummy(key_fact_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    }else if (validateVar(vars[index].is_class_arr)) {

                        let class_name = callClassWithString(index);
                        output[index] = class_name.constructor.dummy();
                    }else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default Key_Facts;
