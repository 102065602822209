import React from 'react';
import validateVar from "../Functions/validation/validateVariable";
import InputHandler from "./InputHandler";
import PropTypes from 'prop-types';

class DynamicInputFromHandler extends React.Component {
    render() {
        const {input_field, object, loading, error_messages, input_name, on_change, optional, on_focus, on_blur} = this.props;

        return (<InputHandler type={input_field.type}
                              content_title={input_field.title}
                              id={input_name}
                              name={input_name}
                              value={object[input_name]}
                              disabled={loading}
                              on_change={on_change}
                              input_group_text={input_field.input_group_text}
                              dropdown_mode={input_field.dropdown_mode}
                              select_options={(validateVar(input_field.select_options)) ? input_field.select_options : []}
                              text={input_field.text}
                              first_radio={input_field.first_radio}
                              second_radio={input_field.second_radio}
                              checked_value={input_field.checked_value}
                              unchecked_value={input_field.unchecked_value}
                              error_state={error_messages[input_name + '_error']}
                              function_name={input_field.function_name}
                              remove_empty_option={input_field.remove_empty_option}
                              max_length={input_field.max_length}
                              file_type={input_field.file_type}
                              upload_file={(file) => this.props.upload_file(input_name, file)}
                              optional={optional}
                              prev_value={object[input_name]}
                              tooltip={input_field.tooltip}
                              placeholder={input_field.placeholder}
                              on_focus={on_focus}
                              on_blur={on_blur}
            />
        )
    }
}

DynamicInputFromHandler.propTypes = {
    input_field: PropTypes.shape({
        type: PropTypes.string,
    }),
    object: PropTypes.object,
    loading: PropTypes.bool,
    error_messages: PropTypes.array,
    on_change: PropTypes.func,
}

export default DynamicInputFromHandler;