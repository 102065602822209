import validateVar from "./validation/validateVariable";
import get_standardised_date from "./get_standardised_date";

function getDefaultDateObject (date_param){
    let date = new Date();
    if(validateVar(date_param)){
        let date_object = new Date(get_standardised_date(date_param));
        if(validateVar(date_object) && date_object.toString() !== 'Invalid Date') date = date_object;
    }

    return date;
}

export default getDefaultDateObject;