import React from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Modal,
    Collapse
} from "reactstrap";
import PrimaryButton from "../../../Displays/Buttons/PrimaryButton";
import InputHandler from "../../../Inputs/InputHandler";
import validateVar from "../../../Functions/validation/validateVariable";
import {
    cancellation_reasons, sign_type_select

} from "../../../Functions/global";
import DownloadUploadHandlerContainer from "../../../Inputs/DownloadUploadHandler";
import is_true from "../../../Functions/is_true";
import get_standardised_date from "../../../Functions/get_standardised_date";

// import {contract_stati_selectable} from "../../../module_functions/global";


class HandleContractStatusModal extends React.Component {
    constructor(props) {
        super(props);
        let contract = props.contract;
        let select_options = this.get_possibille_stati(contract.status);
        let status = null;
        if (select_options.selectable_stati.length > 0) {
            status = select_options.selectable_stati[0].key;
        }

        this.state = {
            showModal: false,
            error_messages: {},
            status: status,
            contract: contract,
            disable_stati_select: select_options.disable_stati_select,
            select_options: select_options.selectable_stati,
            submit_disabled: true,
            sign_type: 0,
            contract_file: null,
            contract_is_uploading: false,
            continue_with_no_doc: false,
            cancellation_reason: "0"
        };
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    };

    get_possibille_stati = (contract_status) => {
        let stati_arr = [];
        let disable_stati_select = false;
        let user_feedback = null;
        if (contract_status === "confirmed") {
            stati_arr.push({value: "Widerrufen", key: "revoked"});
        } else if (contract_status === "cancelled" || contract_status === "revoked") {
            disable_stati_select = true;
        } else if (contract_status === "submitted") {
            stati_arr.push({value: "Annehmen", key: "confirmed"});
            stati_arr.push({value: "Ablehnen", key: "cancelled"});
        }
        return {
            disable_stati_select: disable_stati_select,
            selectable_stati: stati_arr,
            user_feedback: user_feedback
        }
    };

    getDisableSubmitBtn = (status, contract, file_uploaded, customer_sign_type) => {
        let contract_sign_type = contract.sign_type;
        let {signed_contract_uploaded} = this.props;
        let {contract_is_uploading, cancellation_reason, continue_with_no_doc} = this.state;
        let disabled = false;

        if (+status === 0) disabled = true;
        if (status === "confirmed" && contract_sign_type === "OFFLINE" && signed_contract_uploaded) disabled = true;
        if (status === "cancelled" && cancellation_reason === "0") disabled = true;
        if (status === "confirmed" && contract_sign_type === "OFFLINE" && customer_sign_type === "online") disabled = false;
        if (status === "confirmed" && contract_sign_type === "OFFLINE" && (customer_sign_type === "offline" || customer_sign_type+'' === '0')) disabled = true;
        if ((status === "confirmed" && contract_sign_type === "OFFLINE" && customer_sign_type === "offline" && signed_contract_uploaded) ||
            (status === "confirmed" && contract_sign_type === "OFFLINE" && customer_sign_type === "offline" && is_true(continue_with_no_doc))) {
            disabled = false;
            if(contract_is_uploading) this.setState({contract_is_uploading: false});
        }
        return disabled;
    };

    on_change = (name, value) => {
        if (name === "contract_file") {
            let {contract, protocol_id} = this.props;
            let file = value;
            var upload_params = {
                customer_id: contract.customer_id,
                contract_id: contract.contract_id,
                protocol_id: protocol_id,
                history_status: "accepted",
                name: file.name,
                submit: true,
            };
            this.props.upload_signed_contract(upload_params, file);
            this.setState({contract_is_uploading: true, contract_file: value, })
        }
        this.setState({[name]: value});
    };

    submit = () => {
        let {contract, status, sign_type, cancellation_reason} = this.state;
        if (status === "confirmed") { // accept
            if (sign_type === "online") { // insign online signing
                // get params for insign session and reload site with session id in URL for contract loading
                let params = this.get_insign_session_params(contract);
                this.props.insign_session_create(params);
            } else { // offline signing ( check data)
                this.props.change_contract_status({status: 'confirmed', protocol_id: contract.protocol_id});
            }
        } else if (status === "cancelled") { // supplier cancelled contract
            this.props.change_contract_status({status: 'cancelled', cancellation_reason: cancellation_reason});
        } else if  (status === "revoked") { // only possibille on privat customer
            if (new Date(get_standardised_date(contract.revoke_until)).getTime() > new Date().getTime()) {
                this.props.change_contract_status({status: 'revoked'});
            } else alert("Die Frist für den Widerruf des Vertrags ist bereits abgelaufen.");
        }
    };

    get_insign_session_params = (contract) => {
      return {
          customer_id: contract.customer_id,
          file_id : contract.contract_file+'',
          supplier_name: contract.supplier_name,
          call_back_url: window.location.href //this callbackURL is overwritten in sendMails
      }
    };

    render() {
        let {showModal, status, disable_stati_select, select_options, sign_type, contract_file, contract_is_uploading, cancellation_reason, continue_with_no_doc} = this.state;
        const {contract} = this.props;
        let user_feedback =  null;
        let revoke_able_message = null;
        let contract_file_name = validateVar(contract_file) ? contract_file.name : "";
        let submit_disabled = this.getDisableSubmitBtn(status, contract, contract_file_name, sign_type);
        if (contract.status === "cancelled" || contract.status === "revoked" || contract.status === "expired") {
            user_feedback = "Der Auftrag wurde abgelehnt oder die Bearbeitungsfrist ist bereits abgelaufen.";
            if (validateVar(contract.cancellation_reason)) {
                let translated_reason = cancellation_reasons.find(
                    x => x.key === contract.cancellation_reason
                );
                user_feedback = 'Der Auftrag wurde bereits akzeptiert. Grund der Ablehnung: "' + translated_reason.value + '"';
            }
        }
        else if (contract.status === "confirmed") {
            if (is_true(contract.revoke_able)) {
                user_feedback = null;
                submit_disabled = false;
                revoke_able_message = <div className={"static-warning-notification"}>Dieser Vertrag ist bis zum {contract.revoke_until_display} widerrufbar. <br/>
                    Sie können den Auftrag nur mit einer gültigen Vollmacht oder einem Widerrufsformular des Kunden kündigen.
                    Wir bitten Sie, dieses Dokument auf Anfrage in der Chronik anzuhängen.
                </div>;
            }
            else user_feedback = "Der Auftrag wurde bereits akzeptiert.";
        }
        return (
            <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={showModal}
                toggle={() => this.toggleModal()}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent modal-title">
                            <Row>
                                <Col xs={10}>
                                    <h2 className={'mb-0'}>{(validateVar(user_feedback)) ? "Auftragsstatus wurde bereits bearbeitet" : "Auftragsstatus bearbeiten"}</h2>
                                </Col>
                                <Col xs={2}>
                                    <button onClick={() => this.toggleModal()} className={'close'}>
                                        <i className={'ni ni-fat-remove close-button'}/>
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="px-lg-4 py-lg-4">
                            <div>
                                {(validateVar(user_feedback)) ? <span>{user_feedback}</span> :  <><InputHandler
                                    content_title={"Auftragsstatus"}
                                    type={"select"}
                                    name={"status"}
                                    value={status}
                                    remove_empty_option
                                    on_change={(name, value) => this.on_change(name, value)}
                                    select_options={select_options}
                                    disabled={disable_stati_select}
                                />
                                    {revoke_able_message && revoke_able_message}
                                </>
                                }

                                <Collapse isOpen={(status === "confirmed" && contract.sign_type === "OFFLINE")}>
                                    <InputHandler
                                        content_title={"Unterschriftstyp"}
                                        type={"select"}
                                        name={"sign_type"}
                                        value={sign_type}
                                        on_change={(name, value) => this.on_change(name, value)}
                                        select_options={sign_type_select}
                                    />
                                </Collapse>

                                <Collapse isOpen={((sign_type === 'offline') && status === "confirmed")}>
                                    <DownloadUploadHandlerContainer type='download_and_upload_field'
                                        title='Vertragsdokument'
                                        file_type='pdf'
                                        placeholder='Vertrag'
                                        property_name="contract_file"
                                        file_name={contract_file_name}
                                        object={contract}
                                        upload={(file) => this.on_change("contract_file", file)}
                                        is_loaded={true}
                                        api_call={"contract"}
                                        download_params={{
                                            object: {
                                                customer_id: "customer_id",
                                                contract_id: "contract_file",
                                            },
                                            version: ""
                                        }}
                                        class_object={"contract"}
                                        function_name={"getContractFile"}
                                        disable_upload={false}
                                    />

                                    <InputHandler
                                        type={"checkbox"}
                                        id={"continue_with_no_doc"}
                                        name={"continue_with_no_doc"}
                                        classes={""}
                                        label_classes={""}
                                        value={continue_with_no_doc}
                                        disabled={""}
                                        on_change={(name, value) => this.on_change(name, value)}
                                        text={"Ohne unterschriebenes Vertragsdokument fortfahren"}
                                        unchecked_value={false}
                                        checked_value={true}
                                    />

                                </Collapse>
                            </div>

                            <Collapse isOpen={(status === "cancelled")}>
                                <InputHandler
                                    content_title="Grund angeben"
                                    type="select"
                                    name="cancellation_reason"
                                    value={cancellation_reason}
                                    on_change={(name, value) => this.on_change(name, value)}
                                    is_loaded={true}
                                    select_options={cancellation_reasons}
                                />
                            </Collapse>


                            <div className="text-center">
                                {(!validateVar(user_feedback)) && <PrimaryButton loading={contract_is_uploading} disabled={submit_disabled} onClick={() => this.submit()}
                                               value='Änderungen bestätigen'
                                               classes='btn-modal full-width-button mt-3'/>}
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        );
    }
}

export default HandleContractStatusModal;
