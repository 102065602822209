import { combineReducers } from "redux";
// import tariffs
import tariffs from "./tariff";
import pfcs from "./pfc";
import contract_list from "./contract_list";
import consumption_point from "./consumption_point";
import employee from "./employee";
import company from "./company";
import integrations from "./integrations";
import price_strategy from "./pricestrategy";
import load_profiles from "./load_profiles";
import employee_data from "./employee_data";
import calculated_tariffs from "./calculated_tariffs";
import message_feedback from "./message_feedback";
import contract_templates from "./contract_templates";
import redirect from "./redirect";
import analytics from "./analytics";
import contract_single from "./contract_single";
import consultants from "./consultants";
import files from "./files";
import component_feedback from "./component_feedback";
import clean_tree from "./clean_tree";
import iban from "./iban";
import history from "./history";
import chronik from "./chronik";
import blob from "./blob";
import insign from "./in_sign";
import file_stream from "./file_stream";
import offer_result from "./offer_result";
import signed_blob from "./signed_blob";
import insign_session from "./in_sign_session";
import upload_file from "./upload_file";
import table from "./table";
import offer from "./offer";
import clearing from "./clearing";
import url from "./url";
import notification from "./notification";
import bank_account from "./bank_account";
import buergel from "./buergel";
import calculation from "./calculation";
import charge_bee from "./charge_bee";
import comparison_file from "./comparison_file";
import comparison_tariff from "./comparison_tariff";
import consumption_point_list from "./consumption_point_list";
import contract_generator from "./contract_generator";
import customer from "./customer";
import net_fee from "./net_fee";
import password from "./password";
import plattform from "./plattform";
import supplier from "./supplier";
import support from "./support";
import upload_loadprofile from "./upload_loadprofile";
import calculation_unit_list from "./calculation_unit_list";
import calculation_unit from "./calculation_unit";
import clearing_list from "./clearing_list";
import expires from "./expires";
import file_download from "./file_download";
import grouping from "./grouping";
import team from "./team";
import team_list from "./team_list";
import tree_attributes from "./tree_attributes";
import tree from "./tree";
import calculation_packet from "./calculation_packet";
import notification_signature from "./notification_signature";
import recommended_action from "./recommended_action";
import external_offer from "./external_offer";

let reduce = combineReducers({
  tariffs: tariffs,
  comparison_tariff: comparison_tariff,
  customer: customer,
  pfcs: pfcs,
  buergel: buergel,
  contract_list: contract_list,
  contract_single: contract_single,
  contract_generator: contract_generator,
  calculation_obj: calculation,
  charge_bee: charge_bee,
  comparison_file: comparison_file,
  price_strategy: price_strategy,
  consumption_point: consumption_point,
  consumption_point_list: consumption_point_list,
  employee: employee,
  employee_data: employee_data,
  company: company,
  integration: integrations,
  analytics: analytics,
  load_profiles: load_profiles,
  contract_templates: contract_templates,
  calculated_tariffs: calculated_tariffs,
  message_feedback: message_feedback,
  redirect: redirect,
  consultants: consultants,
  files: files,
  component_feedback: component_feedback,
  clean_tree: clean_tree,
  iban: iban,
  history: history,
  chronik: chronik,
  blob: blob,
  insign: insign,
  file_stream: file_stream,
  offer_result: offer_result,
  signed_blob: signed_blob,
  insign_session: insign_session,
  upload_file: upload_file,
  table: table,
  offer: offer,
  clearing: clearing,
  url: url,
  notification: notification,
  bank_account: bank_account,
  net_fee: net_fee,
  password: password,
  plattform: plattform,
  supplier: supplier,
  support: support,
  upload_load_profiles: upload_loadprofile,
  calculation_unit_list: calculation_unit_list,
  calculation_unit: calculation_unit,
  clearing_list: clearing_list,
  expires: expires,
  download_file: file_download,
  grouping: grouping,
  team: team,
  team_list: team_list,
  calculation_packet: calculation_packet,
  tree_attributes: tree_attributes,
  tree: tree,
  notification_signature: notification_signature,
  recommended_actions: recommended_action,
    external_offer: external_offer,
});

export default reduce;
