import validateVar from "../Functions/validation/validateVariable";

let initialState = null;

function load_profiles(state = initialState, action) {
  if (action.type === "GET_DEFAULT_LOAD_PROFILES") {
    return action.res;
  }else if(action.type === "GET_DEFAULT_SLP_PROFILES") {
    let new_state = {...state};
    if(!validateVar(new_state)) {
      new_state = {SLP: action.res};
    }else new_state.SLP = action.res;

    return new_state;
  }else if(action.type === "GET_DEFAULT_RLM_PROFILES") {
    let new_state = {...state};
    if(!validateVar(new_state)) {
      new_state = {RLM: action.res};
    }else new_state.RLM = action.res;

    return new_state;
  } else if (action.type === "UPLOAD_LP_ERROR") {
    return action.res;
  }
  else if (action.type === "RESET_LOAD_PROFILE") {
    return action.res;
  } else if (action.type === "DOWN_LOAD_LP") {
      return action.res;
  }
  else if (action.type === "UPLOAD_LP") {
    return action.res;
  } else {
    return state;
  }
}

export default load_profiles;
