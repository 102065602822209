

function getCustomerCompany(company, customer_type, firstname, lastname) {
   if (customer_type === "PRIVATE" || customer_type === "Privat") {
      return firstname + " " + lastname;
   }
    return company;
}

export default getCustomerCompany;
