import React from 'react';
import cn from "classnames";
import {Container, Col, Row, Card, CardBody, CardHeader, NavLink, NavItem, TabContent, TabPane, Nav} from "reactstrap";
import DropdownButton from "../../../Displays/Buttons/DropdownButton";
import SimpleHeader from "../../../Displays/Headers/SimpleHeader";
import ChangeableContractData from "../../../Displays/Clearing/ChangeableContractData";
import Timeline from "./Timeline";
import {ConsumptionPointClearing} from "../../../../globalNavigation";
import validateVar from "../../../Functions/validation/validateVariable";
import isInternetExplorer from "../../../Functions/isInternetExplorer";
import SecondaryButton from "../../../Displays/Buttons/SecondaryButton";
import HandleContractStatusModal from "../Modals/HandleContractStatusModal";
import Contract from "../../../Objects/contract";
import {deHashVar} from "../../../Functions/handleHashedVariable";
import TableInputModal from "../../../Modals/TableInputModal";
import {project_name, project_names} from "../../../Functions/global";
import PrimaryButton from "../../../Displays/Buttons/PrimaryButton";
import DownloadUploadHandlerContainer from "../../../Inputs/DownloadUploadHandler";
import BadgeDot from "../../../Displays/Badges/BadgeDot";
import BasicValueDisplay from "../../../Displays/Clearing/BasicValueDisplay";
import Badge from "../../../Displays/Badges/Badge";
import SimpleTable from "../../../Displays/Tables/SimpleTable";
import SettingsWheelBtn from "../../../Displays/Buttons/SettingsWheelBtn";
import is_true from "../../../Functions/is_true";

let is_ehub = (project_name === project_names.ehub);

let payment = [
    {
        title: "Provision",
        name: "consultant_charge_amount_display",
        frontend_display: true,
    }
];

if (is_true(is_ehub)) {
    payment = [
        {
            title: "Provisionsempfänger",
            name: "channel_cluster",
            frontend_display: true,
        },
        {
            title: "Provision (in ct/kWh)",
            name: "total_surcharge_without_supplier_display",
            frontend_display: true,
        },  {
            title: "Provision (in Euro/Jahr)",
            name: "total_socket_surcharge_yearly_display",
            frontend_display: true,
        },
    ];
}


const consumption_point_table_columns = [
    {
        value: "consumption_point_id",
        label: "ID",
        sort: true,
        classes: 'linked-col',
        style: {fontWeight: 'bold'}
    },
    {
        dataField: "consumption_display",
        text: "Verbrauch",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "full_address",
        text: "Lieferadresse",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "invoice_full_address",
        text: "Rechnungsadresse",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "switching_status_display",
        text: "Wechselstatus",
        sort: true,
        formatter: (cell, object) => <BadgeDot value={cell} color={object.status_color}/>,
        classes: 'linked-col',
    },
    {
        dataField: "market_identifier",
        text: "Malo ID",
        sort: true,
        classes: 'linked-col'
    },
];

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));

class ContractPageContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: props.activeTab,
            selected_ids: [],
            table_value: consumption_point_table_columns
        };

        this.handleContractStatusModal = React.createRef();
        this.table_input_modal = React.createRef();
    }

    change_tab_and_scroll = (element, set_tab = "2") => {
        this.setState({
            activeTab: set_tab // todo make it variable
        });
        window.scrollTo(element);
    };

    downloadContract = () => {
        let data = {
            supplier_id: supplier_id,
            contract_id: this.props.contract.contract_id
        };
        let ContractClass = new Contract();
        ContractClass.download(data);
    };

    upload_signed_contract_history = (file) => {
        const {contract} = this.props;
        let params = {
            contract_id: contract.contract_id,
            customer_id: contract.customer_id,
            protocol_id: contract.protocol_id,
            name: file.name,
            clearing: true,
        };
        this.props.upload_signed_contract(params, file)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {company, contract} = this.props;
        if(validateVar(company) && company !== prevProps.company){
            payment = this.update_payment_display_with_features(company, contract);
        }
    }

    getDownloadContractBtn(contract_type, show_authority_file_download, disable_edit_actions) {
        const {is_loaded_contract, contractName, contract, dynamic_action_call, default_file_upload} = this.props;

        if (isInternetExplorer()) {
            if (show_authority_file_download) {//render component with border bottom
                return <Row className='custom-border-bottom file-row'>
                    <Col className='font-weight-bold align-self-center'>Energieversorgungsvertrag auf Internet Explorer
                        nicht verfügbar</Col>
                </Row>
            } else {//render last download component
                return <Row className='file-row'>
                    <Col className='font-weight-bold align-self-center'>Energieversorgungsvertrag auf Internet Explorer
                        nicht verfügbar</Col>
                </Row>
            }
        } else {
            if (contract_type === 'ONLINE') {
                // contract_file
                return <DownloadUploadHandlerContainer type='contract_document_component'
                                               property_name='valid_id'
                                               title='Original Energieversorgungsvertrag'
                                               // file_name={contractName}
                                               object={contract}
                                               edit_changes={this.props.edit_changes}
                                               is_loaded={is_loaded_contract}
                                               api_call={"contract"}
                                               disable_upload={true}
                                               dynamic_action_call={dynamic_action_call}
                                               default_file_upload={default_file_upload}
                />
            } else {
                return <>
                    <DownloadUploadHandlerContainer type='contract_document_component'
                                                    property_name='valid_id'
                                                    title='Original Energieversorgungsvertrag'
                                                    // file_name={contractName}
                                                    object={contract}
                                                    edit_changes={this.props.edit_changes}
                                                    is_loaded={is_loaded_contract}
                                                    api_call={"contract"}
                                                    disable_upload={true}
                                                    border_bottom={true}
                                                    dynamic_action_call={dynamic_action_call}
                                                    default_file_upload={default_file_upload}
                    />

                    <DownloadUploadHandlerContainer type='contract_document_component'
                                            property_name='signed_id'
                                            title='Unterschriebener Energieversorgungsvertrag'
                                            // file_name={contractName}
                                            object={contract}
                                            upload={(file) => this.props.upload_file("signed_id", file)}
                                            edit_changes={this.props.edit_changes}
                                            is_loaded={is_loaded_contract}
                                            api_call={"document"}
                                            disable_upload={disable_edit_actions}
                                            border_bottom={show_authority_file_download}
                                            dynamic_action_call={dynamic_action_call}
                                            default_file_upload={default_file_upload}
                    />
                </>
            }
        }
    };

    getDownloadAuthorityBtn(contract, history_edited = null, is_loaded_contract, disable_edit_actions) {
        const {dynamic_action_call, default_file_upload} = this.props;

        if (contract.display_source === "Webseite") return <></>;
        else {
            if (isInternetExplorer()) {
                return <><Row className='file-row'>
                    <Col className='font-weight-bold align-self-center'>Maklervollmacht auf Internet Explorer nicht
                        verfügbar</Col>
                </Row>
                    <Row/> {/* This row is needed because of some styling only in IE */}
                </>
            } else {
                return <DownloadUploadHandlerContainer type='contract_document_component'
                    title='Maklervollmacht'
                    property_name='authority_file'
                    // file_name='Maklervollmacht.pdf'
                    file_url={contract.authority_file_url}
                    api_call={"tariff"}
                   is_loaded={is_loaded_contract}
                   edit_changes={this.props.edit_changes}
                   object={contract}
                   text={"Datei ändern"}
                   upload={(file) => this.props.upload_file("authority_file", file)}
                   dynamic_action_call={dynamic_action_call}
                   default_file_upload={default_file_upload}
                   disable_upload={disable_edit_actions} />
            }
        }
    }

    row_selected = (selected_ids) => {
        this.setState({selected_ids: selected_ids});
    };

    toggle_contract_status_modal = () => {
        this.handleContractStatusModal.current.toggleModal();
    };

    update_payment_display_with_features = (company, contract) => {
        if(validateVar(company) && company.features.includes('custom_margin_limit')) {
            if(is_true(is_ehub)){
                if(validateVar(company) && company.features.includes('provision_for_employee_name')) payment[0].name = 'consultant_employee_full_name';
                if(validateVar(contract.total_socket_surcharge_yearly_display)){
                    payment[1].name = 'summarized_provision_supplier';
                }
            }else{ //enermakler
                if(validateVar(contract.consultant_charge_amount2_display)) {
                    payment[0].name = 'summarized_provision_consultant'
                }
            }
        }

        return payment;
    };


    test(selected_ids) {
        this.props.toggleModal('consumption_point_status', selected_ids);
        this.setState({
            selected_ids: []
        });
    }

     render() {
        const {contract, is_loaded_contract, is_loaded_consumption_point, content, disabled_csp_rows, consumption_points, contract_id, history_edited, protocol_id, disable_edit_actions} = this.props;
        const {activeTab, selected_ids, table_value} = this.state;
        const {dynamic_action_call, default_file_upload} = this.props;

        let show_authority_file_download = false;
        if (validateVar(contract)) {
            if (validateVar(contract.authority_file)) show_authority_file_download = true;
        }

         return (<>
            <div className='position-absolute'>{alert}</div>
            {(validateVar(is_loaded_contract) && is_loaded_contract === true) &&
            <HandleContractStatusModal ref={this.handleContractStatusModal}
                    insign_session_create={(params) => this.props.insign_session_create(params)}
                    contract={contract}
                    upload_signed_contract={(params, file) => this.props.upload_signed_contract(params, file)}
                    signed_contract_uploaded={this.props.signed_contract_uploaded}
                    change_contract_status={this.props.change_contract_status}
                    protocol_id={protocol_id}
                />}
            <TableInputModal table_input_options={table_value}
                             change_table_input={(new_table_value) => this.setState({table_value: new_table_value})}
                             table_name={"enermakler_consumption_point_list_by_contract"}
                             ref={this.table_input_modal}
                             key_field={'consumption_point_id'}
            />
            <SimpleHeader name="Auftrag-Detailansicht"/>
            <Container className="mt--6" fluid>
                <Card className='contract-header'>
                    <CardBody>
                        <Row>
                            <Col className='column mb-3 mb-sm-0 pr-md-0'>
                                <ul className='list-middot mb-0'>
                                    <li>
                                        <BasicValueDisplay is_loaded={is_loaded_contract}
                                            value={"contract_id"}
                                            text={"Auftrag "}
                                            object={contract}
                                        />
                                    </li>
                                    <li className='d-none d-lg-inline-block'>
                                        <BasicValueDisplay is_loaded={is_loaded_contract}
                                            value={"customer_company"}
                                            object={contract}
                                            max_length={9}
                                        />
                                    </li>
                                    <li className='d-none d-lg-inline-block'>
                                        <BasicValueDisplay is_loaded={is_loaded_contract}
                                            value={"display_medium_type"}
                                            object={contract}
                                        />
                                    </li>
                                    <li>
                                        <BasicValueDisplay is_loaded={is_loaded_contract}
                                            value={"submitted_at_display"}
                                            object={contract}
                                        />
                                    </li>
                                    {is_ehub &&
                                    <li>
                                        <BasicValueDisplay is_loaded={is_loaded_contract}
                                            value={"channel_cluster"}
                                            object={contract}
                                        />
                                    </li>}

                                    <li>
                                        <Badge is_loaded={is_loaded_contract}
                                            value={contract.translated_status}
                                            color={contract.status_color}
                                        />
                                    </li>
                                </ul>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={5} className='column buttons-wrapper pl-md-0'>
                                {is_ehub && <SecondaryButton
                                    classes='d-responsive'
                                    onClick={() => (this.props.downloadContract())}
                                    value={'Exportieren'}
                                    disabled={!is_loaded_contract}
                                />}
                                {(is_ehub || contract.revoke_able)&& <PrimaryButton
                                    classes='d-responsive mb-0'
                                    onClick={() => (this.toggle_contract_status_modal())}
                                    value={'Status bearbeiten'}
                                    disabled={!is_loaded_contract}
                                />}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader className='p-0'>
                        <Nav className='customer-nav-tabs round five-tabs' tabs>
                            <NavItem>
                                <NavLink
                                    className={cn({active: activeTab === '1'})}
                                    onClick={() => {
                                        this.setState({activeTab: '1'})
                                    }}
                                >
                                    Chronik
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={cn({active: activeTab === '2'})}
                                    onClick={() => {
                                        this.setState({activeTab: '2'})
                                    }}
                                >
                                    Auftragsdaten
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={cn({active: activeTab === '3'})}
                                    onClick={() => {
                                        this.setState({activeTab: '3'})
                                    }}
                                >
                                    Lieferstellen
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={cn({active: activeTab === '4'})}
                                    onClick={() => {
                                        this.setState({activeTab: '4'})
                                    }}
                                >
                                    Dokumente
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={cn({active: activeTab === '5'})}
                                    onClick={() => {
                                        this.setState({activeTab: '5'})
                                    }}
                                >
                                    Vergütung
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <CardBody className='p-0'>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Timeline
                                    {...this.props}
                                    create_time_line={(params, file) => this.props.create_time_line(params, file)}
                                    showErrorMessage={function () {}}
                                    timeline_download={this.props.timeline_download}
                                    change_tab_and_scroll={(element) => this.change_tab_and_scroll(element)}
                                >
                                    <div className='static-warning-notification more-padding grey mb-2'>
                                        Sie haben Fragen oder Anmerkungen zum Auftrag? Nehmen Sie hier direkt Kontakt
                                        auf, damit Ihr Anliegen bearbeitet werden kann!
                                    </div>
                                </Timeline>
                            </TabPane>
                            <TabPane tabId="2">
                                {content}
                            </TabPane>
                            <TabPane tabId="3">
                                <SimpleTable data={consumption_points}
                                    columns={table_value}
                                    on_column_click={(clicked_row) => this.props.redirect(ConsumptionPointClearing + '/' + contract_id + '/' + clicked_row.id + "/" + protocol_id)}
                                    is_loaded={is_loaded_consumption_point}
                                    row_select_change={this.row_selected}
                                    no_checkboxes={(!is_ehub)}
                                    selected_ids={(is_ehub) ? selected_ids : null}
                                    disabled_rows={(is_ehub) ? disabled_csp_rows : null}>
                                    <Row className='mb-3'>
                                        <Col md={4}>
                                            <h2>{consumption_points.length === 1 ? consumption_points.length + ' Lieferstelle' : consumption_points.length + ' Lieferstellen'}</h2>
                                        </Col>
                                        <Col md={8} className='text-right'>
                                            <SettingsWheelBtn classes={(is_ehub) ? 'mr-3' : null} on_click={() => this.table_input_modal.current.toggleModal()} />
                                            {is_ehub && <DropdownButton id='lieferstellenoptionen'
                                                                        tooltipProp='Lieferstellen'
                                                                        classes='primary-btn'
                                                                        disabled={selected_ids.length < 1}
                                                                        clickChangeStatusBtn={() => this.test(selected_ids)}
                                                                        clickExportBtn={this.exportConsumptionPointData} //TODO Laut Alex kommt lieferstellen export iwann dazu
                                                                        include={['change_status']}/>}

                                        </Col>
                                    </Row>
                                </SimpleTable>
                            </TabPane>
                            <TabPane tabId="4">
                                <div className='contractdocuments-rows pb-0'>
                                    <DownloadUploadHandlerContainer type='contract_document_component'
                                                            title='Allgemeine Geschäftsbedingungen'
                                                            // file_name='AGB.pdf'
                                                            api_call='tariff'
                                                            text={"Datei ändern"}
                                                            property_name={'agb_file_id'}
                                                            object={contract}
                                                            object_history={history_edited}
                                                            upload={(file) => this.props.upload_file('agb_file_id', file)}
                                                            is_loaded={is_loaded_contract}
                                                            dynamic_action_call={dynamic_action_call}
                                                            default_file_upload={default_file_upload}
                                                            edit_changes={this.props.edit_changes}
                                                            disable_upload={disable_edit_actions}
                                                            border_bottom />
                                    {this.getDownloadContractBtn(contract.sign_type, show_authority_file_download, disable_edit_actions)}
                                    {show_authority_file_download ? <>
                                        {this.getDownloadAuthorityBtn(contract, history_edited, is_loaded_contract, disable_edit_actions)}
                                    </> : <></>}
                                </div>
                            </TabPane>
                            <TabPane tabId="5">
                                <ChangeableContractData properties={payment}
                                                        object={contract}
                                                        is_loaded={is_loaded_contract}/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Container>
        </>);
    }
}

export default ContractPageContent;