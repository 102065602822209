import {
    apiCalculationManagementService,
    apiError,
    apiFileSystemService, apiFunctiongetGetConsultant,
    apiFunctiongetGetSupplier,
    apiFunctionLoginSupplier, apiFunctionModifyConsultant,
    apiFunctionModifySupplier,
    apiFunctionReturnResource,
    emptyValue, project_name, project_names
} from "../Functions/global";

import { SingleContract } from "../../globalNavigation";
import validateVar from "../Functions/validation/validateVariable";
import { Redirect } from "react-router";
import React from "react";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import History from "./Components/history";

let company_vars = {
    pfc_electricity: null,
    pfc_gas_ncg: null,
    id: {
        id: null,
        supplier_id: null
    },
    information: {
        is_class_arr: "company_information",
        is_top_level: true
    },
    customer_information: {
        is_class_arr: "customer_information",
        is_top_level: true
    },

    subscription_information: {
        is_class_arr: "subscription_information",
        is_top_level: true
    },
    margins: {
        is_class_arr: "margins",
        is_top_level: true,
    },
    settings: {
        is_sub_arr: "settings",
        cancellation_service: null,
        accept_differing_billing_address: null,
        accept_consultant_fee: null,
        accept_consultant_authority: null,
        global_tree_id: null,
    },
    logo_file: {
        is_sub_arr: "logo_file",
        url: {
            logo_url: null,
        }
    },
    notifications: {
        notifications: {
            function_name: "loop_and_push",
            params: ["notifications"]
        }
    },
    notifications_count: {
        function_name: "get_array_count",
        params: ["%%notifications"]
    },
    messages: {
        messages: {
            function_name: "loop_and_push",
            params: ["notifications"]
        }
    },
    messages_count: {
        function_name: "get_array_count",
        params: ["%%messages"]
    },
    features: null,
    basic_information: {
        is_sub_arr: "basic_informationen",
        house_number: {
            provider_information_house_number: null,
        },
        street: {
            provider_information_street: null,
        },
        zip: {
            provider_information_zip: null,
        },
        city: {
            provider_information_city: null,
        },
        company: {
            provider_information_company: null,
        },
    }
};



class Company {
    static default_values = company_vars;

  constructor(data, status = "open") {
    //load basic info
    if (validateVar(data)) {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(company_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }
  }

  /*
        Instantiate a new Customer with its ID as Param
        Call Communication Class with matching global defined Vars for API Request
        return new Customer Clnass
     */
  static async instantiate() {
    let params = {
      options: {
          with_margins: 1,
          with_subscription_information: 1,
          with_messages: 1
      }
    };
    params = getAgencyID(params);
      let communication_params = {
          service: apiCalculationManagementService,
          get_function: apiFunctiongetGetSupplier,
          update_function: apiFunctionModifySupplier,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);
    let res = await c.load();
    if (res.status === "ok") {
      let data = res.result;

      if (
        data.hasOwnProperty("information") &&
        typeof validateVar(data.information.logo_file_id)
      ) {
        let logo_id = res.result.information.logo_file_id;

          communication_params = {
              service: apiFileSystemService,
              get_function: "",
              update_function: "",
              delete_function: "",
              add_function: "",
              download_function: apiFunctionReturnResource,
          };
          c = getCommunicationClass(communication_params);


        let logo_url = await c.download(
          { id: logo_id },
          "image/png",
          true
        );
        res.result.logo_file = {
          url: logo_url
        };
      }
      res.result.features = [
          'provision_for_employee_name'
      ];
      return new Company(res.result);
    } else return apiError;
  }

  /*
          update function - params must be a valid object
       */
  static async update(params) {
    params = getAgencyID(params);
    params.options = {
        with_margins: 1,
        with_subscription_information: 1,
        with_messages: 1
    };

      let communication_params = {};
      communication_params.service = apiCalculationManagementService;

      if(project_name === project_names.ehub){
          communication_params.get_function = apiFunctiongetGetSupplier;
          communication_params.update_function = apiFunctionModifySupplier;
          communication_params.delete_function = "";
          communication_params.add_function = "";
          communication_params.download_function = "";
      }else{
          communication_params.get_function = apiFunctiongetGetConsultant;
          communication_params.update_function = apiFunctionModifyConsultant;
          communication_params.delete_function = "";
          communication_params.add_function = "";
          communication_params.download_function = "";
      }


      let c = getCommunicationClass(communication_params);
      var res = await c.update(params);
    if (res.status === "ok") {
      let data = res.result;
      if (
        data.hasOwnProperty("information") &&
        typeof validateVar(data.information.logo_file_id)
      ) {
        let logo_id = res.result.information.logo_file_id;
        // add the logo url to the result obj

          communication_params = {
              service: apiFileSystemService,
              get_function: "",
              update_function: "",
              delete_function: "",
              add_function: "",
              download_function: apiFunctionReturnResource,
          };
          c = getCommunicationClass(communication_params);

        let logo_url = await c.download(
          { id: logo_id },
          "image/png",
          true
        );
        res.result.logo_file = {
          url: logo_url
        };
      }

      return new Company(res.result);
    } else return apiError;
  }

  static async login(email, password) {
    let params = {
      email: email,
      password: password
    };

      let communication_params = {
          service: apiCalculationManagementService,
          get_function: apiFunctionLoginSupplier,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);

    var res = await c.load();
    if (res.status === "ok") return res.result;
    return apiError;
  }

  getStatusDisplay(status) {
    if (status === "push") return "Ausstehend";
    else if (status === "viewed") return "Gesehen";
    else if (status === "cancled") return "Abgebrochen";
    else return emptyValue;
  }

  translate_notentification_type(type) {
    if (type === "contract") return "Auftrag";
  }

  translate_notentification_url(type, id) {
    if (type === "contract")
      return <Redirect push to={SingleContract + "/" + id} />;
  }

    static dummy() {
        let output = {};
        output = this.build_dummy(company_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    // } else if (validateVar(vars[index].is_class_arr)) { // FIXME: later if necessary comment in --> need to add dummy() and build_dummy() to class arr objects
                    //
                    //     let class_name = callClassWithString(index);
                    //     output[index] = class_name.constructor.dummy();
                    } else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default Company;
