import Communication from "./Components/Communication/communication_new";
import Constants, {
    apiCalculationManagementService,
    apiError,
    apiFileSystemService,
    apiFunctiongetGetConsultant,
    apiFunctionModifyConsultant,
    apiFunctionModifySupplier,
    apiFunctionReturnResource,
    emptyValue
} from "../Functions/global";
import {deHashVar} from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import {Redirect} from "react-router";
import getUserRole from "../Functions/getUserRole";
import React from "react";
import createFunctionName from "../Functions/createFunctionName";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";

const consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));
const employee_id = deHashVar(localStorage.getItem("consultantEmployeeSecurityToken"));

class Company_wattline {
  constructor(data) {
    /*
     *   default value for vars in this class
     *   add new vars here
     * */

    //basic
    this.first_name = "";
    this.last_name = "";
    this.company = "";
    this.email = "";
    this.phone = "";
    this.company = "";

    this.line1 = "";
    this.line2 = ""; //only customer who has been in contract before
    this.city = "";
    this.state = "";
    this.country = "";
    this.postal_code = "";

    this.logo_file_id = "";
    this.brochure_file_id = "";
    this.description = "";

    this.foundation_year = "";

    this.sales_revenue = "";
    this.status_as = "";
    this.logo_url = "";
    this.notifications = "";
    this.notifications_translated = [];
    this.notifications_count = 0;

    this.cancellation_service = "YES";
    this.accept_differing_billing_address = "YES";
    this.accept_consultant_fee = "YES";
    this.accept_consultant_authority = "YES";
    this.global_tree_id = "";

    this.electricity_margin = 0;
    this.gas_margin = 0;


    this.plan_id = "";
    this.plan_price = "";
    this.max_customers = "";
    this.max_customers_addition = "";
    this.max_customers_sum = "";
    this.employee_count = "";
    this.employee_used_count = "";
    this.customer_count = "";
    this.customer_used_count = "";

    //load basic info

    if (validateVar(data)) {
      //if data found
      this.first_name =
        typeof data.customer_information.first_name === "undefined"
          ? ""
          : data.customer_information.first_name;
      this.last_name =
        typeof data.customer_information.last_name === "undefined"
          ? ""
          : data.customer_information.last_name;
      this.company =
        typeof data.customer_information.company === "undefined"
          ? ""
          : data.customer_information.company;
      this.email =
        typeof data.customer_information.email === "undefined"
          ? ""
          : data.customer_information.email;
      this.phone =
        typeof data.customer_information.phone === "undefined"
          ? ""
          : data.customer_information.phone;
      this.company =
        typeof data.customer_information.company === "undefined"
          ? ""
          : data.customer_information.company;




      if ( data.hasOwnProperty("margins") ){
        let margins = data.margins;
        this.electricity_margin = typeof margins.electricity === "undefined"
            ? 0
            : margins.electricity;
          this.gas_margin = typeof margins.gas === "undefined"
              ? 0
              : margins.gas;
      }

        if ( data.hasOwnProperty("subscription_information") ){
            let subscription_information = data.subscription_information;
            this.plan_id = validateVar(subscription_information.plan_id) ? subscription_information.plan_id : "";
            this.plan_price = validateVar(subscription_information.plan_price) ? subscription_information.plan_price : "";
            this.max_customers = validateVar(subscription_information.max_customers) ? subscription_information.max_customers : "";
            this.max_employees = validateVar(subscription_information.max_employees) ? subscription_information.max_employees : "";
            this.employee_used_count = validateVar(subscription_information.employees) ? subscription_information.employees : "";
            this.customer_used_count = validateVar(subscription_information.customers) ? subscription_information.customers : "";

            if ( subscription_information.hasOwnProperty("addons") ){
                 let addons = subscription_information.addons;
                if ( addons.hasOwnProperty("customers") ){
                    let addons_customer = addons.customers;
                    this.max_customers_addition = validateVar(addons_customer.max_customers) ? addons_customer.max_customers : "";
                    this.quantity_customers_addition = validateVar(addons_customer.quantity) ? addons_customer.quantity : "";
                    this.unit_customer_price = validateVar(addons_customer.unit_price) ? addons_customer.unit_price : "";
                    this.max_customers_sum = validateVar(addons_customer.unit_price) ? this.getMaxCustomerSum() : this.max_customers;
                }

                if ( addons.hasOwnProperty("employees") ){
                    let addons_employees = addons.employees;
                    this.max_employees_addition = validateVar(addons_employees.max_customers) ? addons_employees.max_customers : "";
                    this.quantity_employees_addition = validateVar(addons_employees.quantity) ? addons_employees.quantity : "";
                    this.unit_employees_price = validateVar(addons_employees.unit_price) ? addons_employees.unit_price : "";
                    this.max_employees_sum = validateVar(addons_employees.unit_price) ? this.getMaxEmployeeSum() : this.max_employees;
                }

            }

        }


      if (data.hasOwnProperty("calculation_settings")) {
        this.cancellation_service =
          typeof data.calculation_settings.cancellation_service === "undefined"
            ? ""
            : data.calculation_settings.cancellation_service;

        this.accept_differing_billing_address =
          typeof data.calculation_settings.accept_differing_billing_address ===
          "undefined"
            ? ""
            : data.calculation_settings.accept_differing_billing_address;

        this.accept_consultant_fee =
          typeof data.calculation_settings.accept_consultant_fee === "undefined"
            ? ""
            : data.calculation_settings.accept_consultant_fee;

        this.global_tree_id =
          typeof data.calculation_settings.global_tree_id === "undefined"
            ? ""
            : data.calculation_settings.global_tree_id;

        this.accept_consultant_authority =
          typeof data.calculation_settings.accept_consultant_authority ===
          "undefined"
            ? ""
            : data.calculation_settings.accept_consultant_authority;
      }

      if (data.customer_information.hasOwnProperty("billing_address")) {
        this.line1 =
          typeof data.customer_information.billing_address.line1 === "undefined"
            ? ""
            : data.customer_information.billing_address.line1;
        this.line2 =
          typeof data.customer_information.billing_address.line2 === "undefined"
            ? ""
            : data.customer_information.billing_address.line2;
        this.city =
          typeof data.customer_information.billing_address.city === "undefined"
            ? ""
            : data.customer_information.billing_address.city;
        this.state =
          typeof data.customer_information.billing_address.state === "undefined"
            ? ""
            : data.customer_information.billing_address.state;
        this.country =
          typeof data.customer_information.billing_address.country ===
          "undefined"
            ? ""
            : data.customer_information.billing_address.country;
        this.zip =
          typeof data.customer_information.billing_address.zip === "undefined"
            ? ""
            : data.customer_information.billing_address.zip;
      }
        if (data.hasOwnProperty("information")) {
            this.logo_file_id =
                validateVar(data.information.logo_file_id)
                    ?  data.information.logo_file_id
                    : "";
            this.brochure_file_id =
                typeof data.information.brochure_file_id === "undefined"
                    ? ""
                    : data.information.brochure_file_id;
            this.description =
                typeof data.information.description === "undefined"
                    ? ""
                    : data.information.description;
            this.foundation_year =
                typeof data.information.foundation_year === "undefined"
                    ? ""
                    : data.information.foundation_year + "";
            this.employee_count =
                typeof data.information.employee_count === "undefined"
                    ? ""
                    : (data.information.employee_count);
            this.customer_count =
                typeof data.information.customer_count === "undefined"
                    ? ""
                    : (data.information.customer_count);
            this.sales_revenue =
                typeof data.information.sales_revenue === "undefined"
                    ? ""
                    : (data.information.sales_revenue);
            this.status_as =
                typeof data.information.status_as === "undefined"
                    ? ""
                    : data.information.status_as + "";


        }

      //handle logo
      if (data.hasOwnProperty("logo_file")) {
        this.logo_url =
          typeof data.logo_file.url === "undefined" ? "" : data.logo_file.url;
      }

      if (data.hasOwnProperty("notifications")) {
        this.notifications =
          typeof data.notifications === "undefined" ? "" : data.notifications;
        this.notifications_count =
          typeof data.notifications === "undefined"
            ? ""
            : data.notifications.length;

        let count = 0;
        if (this.notifications_count > 0) {
          for (let key in this.notifications) {
            if (this.notifications_translated.hasOwnProperty(key)) {
              let obj = this.notifications[key];
              let date = obj.created_at.split(" ");
              let notifications_translated = {
                type: this.translate_notentification_type(obj.type),
                message: obj.message,
                date: obj.created_at,
                status: obj.status,
                linked_object_id: obj.linked_object,
                status_display: this.getStatusDisplay(obj.status),
                date_display: "handleFormatDateForFrontend(date[0]),",
                url: this.translate_notentification_url(
                  obj.type,
                  obj.linked_object
                )
              };
              this.notifications_translated[key] = notifications_translated;
              if (obj.status !== "viewed") {
                count = count + 1;
              }
            }
          }
          this.notifications_count = count;
        }
      }
    }
  }

  /*
        Instantiate a new Customer with its ID as Param
        Call Communication Class with matching global defined Vars for API Request
        return new Customer Clnass
     */
  static async consultant_get(function_name ,params, url, file) {
      let is_list = false;
      let function_url = createFunctionName(function_name) + "/" + consultant_id;
      if (validateVar(params)) {
          is_list = true;
          function_url = createFunctionName(function_name);
      } else {
          // params = {
          //     options: {
          //         with_margins: 1,
          //         with_subscription_information: 1
          //     }
          // }
      }
      let communication_params = {
          method: "GET", // GET / POST / PUT / DELETE  -> method
          function_url:  function_url,
          params: params
      };
      let c = getCommunicationClass(communication_params);
      let result = await c.request_handler();
      if (result !== apiError){
          if (
              result.hasOwnProperty("information") &&
              validateVar(result.information.logo_file_id)
          ) {
              let logo_id = result.information.logo_file_id;
              // add the logo url to the result obj

              // let communication_params = {
              //     service: apiCalculationAnalyticsService,
              //     get_function: apiFileSystemService,
              //     update_function: "",
              //     delete_function: "",
              //     add_function: "",
              //     download_function: apiFunctionReturnResource,
              // };


              communication_params = {
                  method: "GET", // GET / POST / PUT / DELETE  -> method
                  function_url:  function_url,
                  params: null,
                  file: null,
                  is_download: "return_link",
              };
              c = getCommunicationClass(communication_params);
              // c = new Communication(
              //     "GET", // GET / POST / PUT / DELETE  -> method
              //     "file_system/" + logo_id, // function call
              //     null, // params
              //     null, // file
              //     "return_link"
              // );

              let logo_url = await c.request_handler();

              // let logo_url = await c.download({ id: logo_id }, "image/png", true);
              result.logo_file = {
                  url: logo_url
              };
          }
          return new Company_wattline(result);
      } else{
        return apiError;
      }

    // this.c = new Communication(
    //   apiCalculationManagementService,
    //   apiFunctiongetGetConsultant,
    //   apiFunctionModifySupplier, //todo replace to consultant
    //   "",
    //   "",
    //   "",
    //   params
    // );
    // let res = await this.c.load();
    // if (res.status === "ok"){
    //     let data = res.result;
    //     if (
    //         data.hasOwnProperty("information") &&
    //         validateVar(data.information.logo_file_id)
    //     ) {
    //         let logo_id = res.result.information.logo_file_id;
    //         // add the logo url to the result obj
    //         this.c = new Communication(
    //             apiFileSystemService,
    //             "",
    //             "",
    //             "",
    //             "",
    //             apiFunctionReturnResource,
    //             {}
    //         );
    //         let logo_url = await this.c.download({ id: logo_id }, "image/png", true);
    //         res.result.logo_file = {
    //             url: logo_url
    //         };
    //     }
    //     return new Company(res.result);
    // } else{
    //   return "ERR";
    // }
  }

  /*
          update function - params must be a valid object
       */
  static async update(params) {
      params = getAgencyID(params);

    // this.c = new Communication(
    //   apiCalculationManagementService,
    //   apiFunctiongetGetConsultant,
    //   apiFunctionModifyConsultant,
    //   "",
    //   "",
    //   "",
    //   {}
    // );

      let communication_params = {
          service: apiCalculationManagementService,
          get_function: apiFunctiongetGetConsultant,
          update_function: apiFunctionModifyConsultant,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);
    var res = await c.update(params);
    if (res.status === "ok")  {
        let data = res.result;
        if (
            data.hasOwnProperty("information") &&
            validateVar(data.information.logo_file_id)
        ) {
            let logo_id = res.result.information.logo_file_id;
            // add the logo url to the result obj
            this.c = new Communication(
                apiFileSystemService,
                "",
                "",
                "",
                "",
                apiFunctionReturnResource,
                {}
            );

            communication_params = {
                service: apiFileSystemService,
                get_function: "",
                update_function: "",
                delete_function: "",
                add_function: "",
                download_function: apiFunctionReturnResource,
            };
            c = getCommunicationClass(communication_params);
            let logo_url = await c.download({ id: logo_id }, "image/png", true);
            res.result.logo_file = {
                url: logo_url
            };
        }
      return new Company_wattline(res.result);
    }
    else return apiError;

  }

  static async updateImg(params, file) {
    // this.c = new Communication(
    //   apiFileSystemService,
    //   "",
    //   "",
    //   "",
    //   "saveFile",
    //   "",
    //   {}
    // );

      let communication_params = {
          service: apiFileSystemService,
          get_function: "",
          update_function: "",
          delete_function: "",
          add_function: "saveFile",
          download_function: "",
      };
      let c = getCommunicationClass(communication_params);


    var res = await c.add(params, file);
    return new Company_wattline(res.result);
  }

    getMaxEmployeeSum(){
        return (+this.max_employees_addition * +this.quantity_employees_addition) + +this.max_employees;
    }

    getMaxCustomerSum(){
        return (+this.max_customers_addition * +this.quantity_customers_addition) + +this.max_customers;
    }

  getStatusDisplay(status) {
    if (status === "push") return "Ausstehend";
    else if (status === "viewed") return "Gesehen";
    else if (status === "cancled") return "Abgebrochen";
    else return emptyValue;
  }

  translate_notentification_type(type) {
    if (type === "contract") return "Auftrag";
  }

  translate_notentification_url(type, id) {
    if (type === "contract")
      return (
        <Redirect push to={"/" + getUserRole() + "/" + id} />
      );
  }
}

export default Company_wattline;
