import React from "react";
import Button from "reactstrap/lib/Button";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

const SecondaryButton = (props) => {
    return (
        <>
            {props.is_loaded ?
            <Button onClick={props.onClick} disabled={props.disabled} className={'eless-btn btn-outline-eless ' + props.classes}>
                {props.value}
            </Button> : <Skeleton height={31} width={150}/>
            }
        </>
    )
};

SecondaryButton.defaultProps = {
    is_loaded: true
}

SecondaryButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.string.isRequired,
    id: PropTypes.string,
    classes: PropTypes.string,
};

export default SecondaryButton;