import getCommunicationClass from "../Functions/getCommunication";
import {apiError, description_display_length} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import validateVar from "../Functions/validation/validateVariable";
import isEmptyObject from "../Functions/isEmptyObject";
import TreeConditions from "./tree_conditions";
import handleFormatNumber from "../Functions/handleFormatNumber";
import is_false from "../Functions/is_false";
class Tree {
    constructor(data, all_attributes, group_index = null) {
        let attribute_infos = null;
        if (validateVar(data)) {
            attribute_infos = all_attributes.find(
                x => x.attribute_id === data.attribute_id
            );
        }
        this.name = "";
        this.attribute_id = "";
        this.branches = [];
        this.description = "";
        this.group_index = "";
        this.class_attribute_name = "";
        this.validation_class = "";
        this.class_name = "";
        this.is_expanded = false;
        this.disable_add = false;
        this.type = "";
        this.type_input_field = "";
        this.validation = null;
        this.is_global = null;
        this.options = [];
        this.range_min = null;
        this.range_max = null;
        this.decimal_places = null;
        this.short_info = "";
        this.abbreviation = "";
        this.unit = "";
        this.used_operators = [];
        if (validateVar(data) && !isEmptyObject(data) && validateVar(attribute_infos)) {
            this.name = attribute_infos.name;
            this.attribute_id = data.attribute_id;
            let {conditions, used_operators} = (validateVar(data.branches)) ? Tree.get_branches(data.branches, attribute_infos) : Tree.create_fake_branch(null, attribute_infos);
            this.branches = conditions;
            this.used_operators = used_operators;
            this.is_expanded = validateVar(data.is_expanded);
            this.description = attribute_infos.description;
            this.is_global = attribute_infos.is_global;
            this.group_index = group_index;
            this.class_attribute_name = attribute_infos.class_attribute_name;
            this.validation_class = attribute_infos.validation_class;
            this.class_name = attribute_infos.class_name;
            this.type = attribute_infos.type;
            this.type_input_field = Tree.translate_attribute_types(attribute_infos.type);
            this.decimal_places = (validateVar(attribute_infos.type) && attribute_infos.type.includes("float")) ? Tree.get_decimal_places(attribute_infos.type) : null;
            this.validation = attribute_infos.validation;
            this.options = Tree.get_options(attribute_infos.options);
            this.range_min = attribute_infos.range_min;
            this.range_min_display = this.get_range_display(attribute_infos.range_min);
            this.range_max = attribute_infos.range_max;
            this.range_max_display = this.get_range_display(attribute_infos.range_max);
            this.short_info = attribute_infos.short_info;
            this.abbreviation = attribute_infos.abbreviation;
            this.unit = attribute_infos.unit;
        }
    }

    static async init_all_attributes(params) {
        let function_url = "tree";
        let communication_params = {
            method: "GET", // GET / POST / PUT / DELETE  -> method
            function_url: function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        let result = await c.request_handler();
        if (result !== apiError) {
            return result;
        } else return apiError;
    }

    static async init_tree(params = {}, all_attributes) {
        let function_url = "tree/" + params.tree_id;
        params = getAgencyID(params);
        let communication_params = {
            method: "GET", // GET / POST / PUT / DELETE  -> method
            function_url: function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        let result = await c.request_handler();

        if (result !== apiError) {
            let new_groups = [];
            let index = 0;
            let tree_attributes_global_edited = null;
            result.is_global = (validateVar(params.global));
            for (let group of result.groups) {
                let tree_attributes = [];
                tree_attributes_global_edited = [];
                for (let tree_attribute of group.tree_attributes) {
                    if (is_false(result.is_global)) { // if not global filter global attributes from groups (global attributes will only be displayed in the global tree)
                        let tmp_attribute = new Tree(tree_attribute, all_attributes, index);
                        if(tmp_attribute.is_global) {
                            tmp_attribute.is_edited = true;
                            tree_attributes_global_edited.push(tmp_attribute);
                        } else {
                            tree_attributes.push(tmp_attribute);
                        }
                    } else tree_attributes.push(new Tree(tree_attribute, all_attributes, index));
                }
                group.tree_attributes = tree_attributes;
                group.description_short = validateVar(group.description) ? (group.description.length > description_display_length) ? group.description.substr(0, description_display_length) + "..." : group.description : ""; // shorten description if is longer than description_display_length -> responsiv
                new_groups.push(group);
                index = index + 1;
            }
            result.tree_attributes_global_edited = tree_attributes_global_edited;
            // result.groups = new_groups;
            return result;
        } else return apiError;
    }



    static async handle_tree(method, params = {}, all_attributes) {
        let function_url = "tree";
        if (method === "PUT")  function_url = function_url + "/" +  params.id;
        params = getAgencyID(params);
        let communication_params = {
            method: method, // GET / POST / PUT / DELETE  -> method
            function_url: function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        let result = await c.request_handler();

        if (result !== apiError) {
            let new_groups = [];
            let index = 0;
            for (let group of result.groups) {
                let tree_attributes = [];
                for (let tree_attribute of group.tree_attributes) {
                    tree_attributes.push(new Tree(tree_attribute, all_attributes, index));
                }
                group.tree_attributes = tree_attributes;
                group.description_short = validateVar(group.description) ? (group.description.length > description_display_length) ? group.description.substr(0, description_display_length) + "..." : group.description : ""; // shorten description if is longer than description_display_length -> responsiv
                new_groups.push(group);
                index = index + 1;
            }
            return result;
        } else return apiError;
    }

    static get_possibille_operators_for_branch() {
        return "EQ";
    }

    get_range_display(range) {
        if (this.type === "int") return handleFormatNumber(range);
        else if (this.type.includes("float")) return range.replace(".", ",");
        else return range;
    };

    static get_decimal_places(type) {
        if (type.includes(":")) {
            let splitted = type.split(":");
            if (validateVar(splitted[1])) return splitted[1];
        }
        return null;
    }


    static get_options(options) {
        if (this.type === "multiselect") {

        } else return options;
    }


    static create_fake_branch(branches = null, attribute) {
        let default_comp = "EQ";
        if (validateVar(branches)) {
            default_comp = Tree.get_possibille_operators_for_branch(branches)
        }
        let branch_value = (attribute.type === "multiselect") ? [] : "";
        let data = {
            base_price: "0",
            text_form: false,
            constraints: {
                comp_op: default_comp,
                expr_a: "VARIABLE",
                expr_b: {
                    VALUE: branch_value
                }
            },
            kwh_rate: "0",
            type: attribute.type,
            is_base_price: attribute.is_base_price
        };
        return {
            conditions: [new TreeConditions(data)],
            used_operators: [default_comp]
        };
    }


    static translate_attribute_types(type) {
        if (type.includes("float")) type = "float";
        switch (type) {
            case "int":
                return "number";
            case "string":
            case "float":
                return "text";
            default:
                return type
        }
    }

    static get_branches(branches, attribute) {
        let branches_arr = [];
        let used_operators = [];
        for (let index in branches) {
            if (branches.hasOwnProperty(index)) {
                let branch = branches[index];
                branch.type = attribute.type;
                branch.is_base_price = attribute.is_base_price;
                let condition = new TreeConditions(branch);
                used_operators.push(condition.comparison_operator);
                branches_arr.push(condition);
            }
        }
        // use slice() to copy the array and not just make a reference
        var sorted_by_value = branches_arr.slice(0);
        sorted_by_value.sort(function (a, b) {
            return a.comparison_value_1 - b.comparison_value_1;
        });
        return {
            conditions: sorted_by_value,
            used_operators: used_operators
        };
    }

}

export default Tree;
