import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";

let prediction_vars = {

    translation_object: {
        concession_fee_type: "Konzessionsabgabe",
        consumption_display: "Verbrauch",
        peak_display: "Leistung (Spitze)"
    },
    is_sub_arr: "prediction",
    has_history: true,
    base_consumption_ratio: null,
    original_consumption: {
        original_consumption: null,
        original_consumption_display: {
            function_name: "handleFormatNumber"
        }
    },
    concession_fee_type: null,
    load_profile_prediction_id: null,
    consumption_offset: {
        consumption_offset: null,
        consumption_offset_display: {
            function_name: "getConsumptionOffsetDisplay",
            params: ["%%is_synthetic"]
        }
    },
    peak: {
        peak: null,
        peak_display: {
            function_name: "getPeakDisplay"
        }
    },
    profile_duration: null,

    profile_begin: null,
    profile_end: null,
    work_level_bridged_fridays: null,
    work_level_bridged_mondays: null,
    yearly_consumption_hours: null
};


class Prediction {
    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(prediction_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }
}

export default Prediction;
