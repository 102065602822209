import getCommunicationClass from "../Functions/getCommunication";
import validateVar from "../Functions/validation/validateVariable";
import isEmptyObject from "../Functions/isEmptyObject";
import History from "./Components/history";
import {apiError, emptyValue} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
let package_vars = {
    bundle_name: null,
    bundle_number: null,
    calculation_dates: null,
    calculation_units: {
        is_class_arr: "calculation_units"
    },
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    contacts: {
        is_class_arr: "customer_information",
    },
    gender: {
        gender: null,
        gender_display: {
            function_name: 'getGenderDisplay',
        }
    },
    full_name_with_gender: {
        function_name: 'combine_strings',
        params: ['%%gender_display', ' ', '%%first_name', ' ', '%%last_name']
    },
    created_at: null,
    customer_advice: null,
    customer_advice_display: {
        function_name: 'getYesOrNoDisplay',
        params: ['%%customer_advice']
    },
    customer_name: null,
    customer_number: null,
    protocol_id: null,
    editing_employee: emptyValue, //TODO Sobald ein employee mit packet durch BE verlinkt ist, diese Variable richtig benennen und anwenden
    deleted_at: null,
    ee_individual: null,
    ee_individual_display: {
        function_name: 'getYesOrNoDisplay',
        params: ['%%ee_individual']
    },
    id: {
        calculation_packet_id: null
    },
    medium_type: null,
    medium_type_display: {
        function_name: 'getMediumType',
        params: ['%%medium_type']
    },
    name: {
        name: null,
        calculation_packet_id_display: null,
    },
    next_end_of_term: null,
    next_end_of_term_display: {
        function_name: 'getGermanDateFormat',
        params: ['%%next_end_of_term'],
    },
    number_calculation_units: null,
    number_consumption_points: null,
    show_actions: null,
    total_mean_consumption: null,
    total_mean_consumption_display: {
        function_name: "getDisplayConsumption",
        params: ["%%total_mean_consumption"]
    },
    updated_at: null,
};
class CalculationPackage{
    constructor(data) {
        if (validateVar(data) && !isEmptyObject(data)) {
            // this.get_contract(contract_vars, data, data.history, []);
            let class_content = new History(package_vars, data, null, []);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static async handle_calculation_packet_actions(params = {}, method, call_function_name, use_agency_id, file){
        params = getAgencyID(params, use_agency_id);
        let communication_params = {
            method: method,
            params: params,
            function_url: 'wattline/calculation/packet',
        };
        if(validateVar(call_function_name)){
            communication_params.function_url = communication_params.function_url+ "/" + call_function_name;
        }else if(method === 'GET' && validateVar(params.id)){
            communication_params.function_url = communication_params.function_url+ "/" + params.id; //so far only used for send_mail & consumption_points
        }
        let c = getCommunicationClass(communication_params, file);
        let res = await c.request_handler();
        if (res !== apiError) {
            if(validateVar(res.result)) return res.result;
            else return res;
        } else return apiError;
    }
}

export default CalculationPackage;