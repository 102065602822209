import React from 'react';
import is_true from "../Functions/is_true";
import {Col, Row} from "reactstrap";
import cn from "classnames";
import AcceptChanges from "../Displays/Footers/AcceptChanges";
import validateVar from "../Functions/validation/validateVariable";
import isEmptyObject from "../Functions/isEmptyObject";
import Skeleton from "react-loading-skeleton";
import PrevStateDownloadText from "../Displays/Clearing/PrevStateDownloadText";
import DownloadButton from "../Displays/Buttons/DownloadButton";
import GreyLinkButton from "../Displays/Buttons/GreyLinkButton";
import FileUploadModal from "../Pages/Contracts/Modals/FileUploadModal";

class ContractDocumentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.fileUploadModal = React.createRef();
    }

    is_value_edited = (history) => {
        return (validateVar(history) && !isEmptyObject(history))
    };

    open_upload_modal = () => {

    };

    render() {
        const {history, no_values_found, border_bottom, file_url_history, file_name, title, is_loaded, file_url, edit_changes, api_call, disable_upload, upload} = this.props;
        const {dynamic_action_call, default_file_upload} = this.props;
        let value_edited = (this.is_value_edited(history)); //contract_document_component

        if (is_true(no_values_found)) return (<></>);
        else if(value_edited){
            return (
                <Row className={'file-row has-changes' + cn({' custom-border-bottom': border_bottom})}>
                <Col className='font-weight-bold align-self-center'>
                    <PrevStateDownloadText file_url={file_url_history} file_name={file_name} text={title} is_loaded={is_loaded} />
                </Col>
                <Col className='btn-right'>
                    <DownloadButton file_url={file_url} file_name={file_name} is_loaded={is_loaded} />
                </Col>
                <Col className='mt-3 mb-2' md={12}>
                    {/*<AcceptChanges accept_changes={accept_changes} discard_changes={discard_changes} />*/}

                    <AcceptChanges
                        question={"Möchten Sie die vorgeschlagenen Änderungen akzeptieren?"}
                        matching_agency_text={"Ihre Anfrage wird bearbeitet. Sie werden benachrichtigt sobald die Änderungen bearbeitet wurden."}
                        modal_secure_question_accept={"Möchten Sie die Änderungen im Auftrag wirklich akzeptieren?"}
                        modal_secure_question_discard={"Möchten Sie die Änderungen im Auftrag wirklich ablehnen?"}
                        accept_btn={"Akzeptieren"}
                        discard_btn={"Ablehnen"}
                        edit_changes={(type) => edit_changes(type, history.history_id, api_call)}
                        agency_id={history.agency_id}
                        disable_edit_history_btn={disable_upload}
                    />
                </Col>
            </Row>)
        }else{
            return (<>
                <FileUploadModal modal_title={title} file_name={file_name}
                    file_type={"pdf"} name={"file"}
                    submit={(file) => upload(file)}
                    ref={this.fileUploadModal}
                    dynamic_action_call={dynamic_action_call}
                    default_file_upload={default_file_upload} />
                <Row className={'file-row' + cn({' custom-border-bottom': border_bottom})}>
                <Col className='font-weight-bold align-self-center'>
                    {is_loaded ? title : <Skeleton width={250} height={30}/>}
                </Col>
                <Col className='btn-right'>
                     <span className='d-inline-block mr-3 sm'>
                        <GreyLinkButton text={'Angaben ändern'}
                                        disabled={disable_upload}
                                        classes={'eless-color '+cn({'disabled': disable_upload})}
                                        onClick={() => this.fileUploadModal.current.toggleModal()}
                        />
                    </span>
                    <span>
                        <DownloadButton
                            file_url={file_url}
                            file_name={file_name}
                            is_loaded={is_loaded}
                        />
                    </span>
                </Col>
            </Row></>)
        }
    }
}

export default ContractDocumentComponent;