import * as ERROR_CODE from "../Errors/error_codes";
import validateVar from "../Functions/validation/validateVariable";
import {displayErrorMessage} from "./user_feedback";
import {
    apiError,
} from "../Functions/global";
import Team from "../Objects/team";

export function platform_team(function_name ,params, method, url, file) {
    return function (dispatch) {
        Team.platform_team(function_name ,params, method, url, file).then(res => {
            let is_list = false;
            if (validateVar(params)) is_list = true; // check if list request is called
            if (res !== apiError){
                if (Array.isArray(res)){ // return list
                    dispatch({type: "INITIALIZE_TEAMS_LIST", res: res});
                } else {
                    dispatch({type: "INITIALIZE_TEAM", res: res}); // return single employee
                }
            }
            else {
                if (is_list) dispatch({type: "INITIALIZE_EMPLOYEE_LIST", res: []});  // show empty table if error
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Teams konnte nicht geladen werden. (Fehlercode: " + ERROR_CODE.GetTeamsError + ")", // content
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function platform_team_employee(function_name ,params, method, url, file) {
    return function (dispatch) {
        Team.platform_team(function_name ,params, method, url, file).then(res => {
            let is_list = false;
            if (validateVar(params)) is_list = true; // check if list request is called
            if (res !== apiError){
                if (Array.isArray(res)){ // return list
                    if (res.includes(apiError)) { // includes error
                        console.log("result has api error", res);
                    } else {
                        dispatch({type: "INITIALIZE_TEAM", res: res});
                    } // todo upload list
                } else {
                    dispatch({type: "INITIALIZE_TEAM", res: res}); // return single employee
                }
            }
            else {
                if (is_list) dispatch({type: "INITIALIZE_EMPLOYEE_LIST", res: []});  // show empty table if error
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Teams konnte nicht geladen werden. (Fehlercode: " + ERROR_CODE.GetTeamsError + ")", // content
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}




