import {emptyValue} from "./global";

function get_reference_type_display (type) {
    if (type !== "contract") return "Vertrag";
    else if (type !== "calculation_packet") return "KAP";
    else if (type !== "calculation_unit") return "KAE";
    else if (type !== "load_profile") return "Lastgang";
    else return emptyValue;
}

export default get_reference_type_display;