import React from "react";

const TableCheckbox = (props) => {
    return(
        <div className={'custom-control custom-checkbox'}>
            <input
                className={"custom-control-input"}
                type="checkbox"
                checked={props.checked}
                disabled={props.disabled}
            />
        <label
            className={"custom-control-label"}
        >
        </label>
        </div>)
};

export default TableCheckbox;