import handleFormatPrice from "./handleFormatPrice";
import validateVar from "./validation/validateVariable";
import {emptyValue} from "./global";
import getChargeUnitDisplay from "./getChargeUnitDisplay";

export default function getChargeAmountDisplay(amount, unit, default_val = 'Keine Provision'){
    if(validateVar(amount) && validateVar(unit)){
        if(amount === 0.00 || amount === '0.00') return default_val;
        if(unit === 'CT_PER_KWH'){
            return handleFormatPrice(amount, 3, getChargeUnitDisplay(unit))
        }else if(unit === 'EUR_PER_YEAR') {
            return handleFormatPrice(amount, 2, getChargeUnitDisplay(unit, true))
        }else return handleFormatPrice(amount, 2, getChargeUnitDisplay(unit))
    } else return emptyValue;
}

export function getChargeAmountDisplayYearly(amount, unit){
    if(validateVar(amount) && validateVar(unit)){
        if(amount === 0.00 || amount === '0.00') return 'Keine Provision';
        if(unit === 'CT_PER_KWH'){
            return handleFormatPrice(amount, 3, getChargeUnitDisplay(unit))
        }else return handleFormatPrice(amount * 12, 2, getChargeUnitDisplay(unit, true))
    } else return emptyValue;
}

