let initialState = null;

function iban(state = initialState, action) {
  if (action.type === "VALIDATE_IBAN") {
    return action.res;
  } else if (action.type === "RESET_IBAN") {
    return action.res;
  } else {
    return state;
  }
}

export default iban;
