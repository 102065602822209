import React from "react";
import Button from "reactstrap/lib/Button";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Tooltip} from 'reactstrap';
import CustomAlert from "../../CustomAlert/CustomAlert";
import validateVar from "../../Functions/validation/validateVariable";

class DropdownButton extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            active: false,
            dropdown: '',
            tooltipOpen: false,
            button_name: (validateVar(props.button_name)) ? props.button_name : "Optionen",
        };
        this.deletionWarning = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    deleteConfirmed = () => {
        this.props.deleteSelected();
        this.deletionWarning.current.turnOffModal();
    };

    handleClickOutside = (event) => {
        if (this.wrapperRef && this.state.active && !this.wrapperRef.contains(event.target)) { //close the button on click outside
            this.buttonClicked();
            this.setState({dropdown: ''});
        }
    };

    //if export button is clicked
    clickExportBtn = () => {
        this.buttonClicked();
        this.props.downloadFromTable();
    };

    clickCopyBtn = () => {
        this.buttonClicked();
        this.props.copySelected();
    };

    clickActivateBtn = () => {
        this.buttonClicked();
        this.props.activateSelected();
    };

    clickDeactivateBtn = () => {
        this.buttonClicked();
        this.props.deactivateSelected();
    };

    clickRevokeBtn = () => {//revoke contract
        this.buttonClicked();
        this.props.clickRevokeBtn();
    };

    clickAcceptBtn = () => {
        this.buttonClicked();
        this.props.clickAcceptBtn();
    };

    clickRejectBtn = () => {
        this.buttonClicked();
        this.props.clickRejectBtn();
    };

    clickChangeStatusBtn = () => {
        this.buttonClicked();
        this.props.clickChangeStatusBtn();
    };

    clickViewedBtn = () => {
        this.buttonClicked();
        this.props.clickViewedBtn();
    };


    clickRenameGroupBtn = () => {
        this.buttonClicked();
        this.props.clickRenameGroupBtn();
    };

    handle_group_click = (btn_clicked) => {
        this.buttonClicked();
        this.props[btn_clicked]();
    };

    clickAssignBtn = () => {
        this.buttonClicked();
        this.props.clickAssignBtn();
    };


    clickAssignToBtn = () => {
        this.buttonClicked();
        this.props.clickAssignToBtn();
    };

    clickRemoveBtn = () => {
        this.buttonClicked();
        this.props.clickRemoveBtn();
    };

    clickEditeBtn = () => {
        this.buttonClicked();
        this.props.clickEditeBtn();
    };

    clickGenerateCsvBtn = () => {
        this.buttonClicked();
        this.props.clickGenerateCsvBtn();
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    buttonClicked = () => { //Beim Hinzufügen einer neuen Option muss CSS wegen der Animation angepasst werden + disabled=true erst ab +1 eingestellt werden
        var optionsCount = '';
        if(typeof this.props.include !== 'undefined'){
            if(this.props.include.length === 1){
                optionsCount = 'one-option';
            }else if(this.props.include.length === 2){
                optionsCount = 'two-options';
            }else if(this.props.include.length === 3){
                optionsCount = 'three-options';
            }else if(this.props.include.length === 4){
                optionsCount = 'four-options';
            }
        }

        let id = validateVar(this.props.id) ? this.props.id : 'optionenButton';
        let offset_width = document.getElementById(id).offsetWidth;
        if(offset_width === 0) offset_width = 135;
        var dropdownOptions = <div key={0} className={'dropdown-options '+optionsCount} style={{'width': offset_width}}>
            {(validateVar(this.props.include) && this.props.include.includes('change_status')) ? <div key={0} onClick={this.clickChangeStatusBtn}>Status ändern</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('accept')) ? <div key={1} onClick={this.clickAcceptBtn}>Akzeptieren</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('reject')) ? <div key={2} onClick={this.clickRejectBtn}>Ablehnen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('delete')) ? <div key={3} onClick={() => this.deletionWarning.current.toggle_modal()}>Löschen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('remove')) ? <div key={3} onClick={this.clickRemoveBtn}>Entfernen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('edit')) ? <div key={3} onClick={this.clickEditeBtn}>Editieren</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('export')) ? <div key={4} onClick={this.clickExportBtn}>Exportieren</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('deactivate')) ? <div key={5} onClick={this.clickDeactivateBtn}>Deaktivieren</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('activate')) ? <div key={6} onClick={this.clickActivateBtn}>Aktivieren</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('copy')) ? <div key={7} onClick={this.clickCopyBtn}>Kopieren</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('revoke')) ? <div key={8} onClick={this.clickRevokeBtn}>Widerrufen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('viewed')) ? <div key={8} onClick={this.clickViewedBtn}>Gesehen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('assign')) ? <div key={8} onClick={this.clickAssignBtn}>Mir zuweisen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('assign_to')) ? <div key={8} onClick={this.clickAssignToBtn}>Zuweisen zu ...</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('group_rename')) ? <div key={8} onClick={() => this.handle_group_click("clickRenameGroupBtn")}>Umbenennen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('group_delete')) ? <div key={8} onClick={() => this.handle_group_click("clickDeleteGroupBtn")}>Löschen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('group_copy')) ? <div key={8} onClick={() => this.handle_group_click("clickCopyGroupBtn")}>Kopieren</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('group_add')) ? <div key={8} onClick={() => this.handle_group_click("clickAddGroupBtn")}>Hinzufügen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('sort_up')) ? <div key={8} onClick={() => this.handle_group_click("clickMoveGroupIndexUpBtn")}>Eins Hoch</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('sort_down')) ? <div key={8} onClick={() => this.handle_group_click("clickMoveGroupIndexDownBtn")}>Eins Runter</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('reset_global_tree')) ? <div key={8} onClick={() => this.handle_group_click("clickResetGobalTree")}>Auf Global zurücksetzen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('edit_global_tree')) ? <div key={8} onClick={() => this.handle_group_click("clickEditGlobalTree")}>Kriterien überschreiben</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('disable_edit_global_tree')) ? <div key={8} onClick={() => this.handle_group_click("clickEditGlobalTree")}>Kriterien nicht überschreiben</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('recommended_action')) ? <div key={8} onClick={() => this.handle_group_click("clickEditGlobalTree")}>Handlungsempfehlung</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('foreing_offer')) ? <div key={8} onClick={() => this.handle_group_click("clickEditGlobalTree")}>Fremdeingebot einholen</div> : ''}
            {(validateVar( this.props.include) && this.props.include.includes('generate_csv')) ? <div key={8} onClick={() => this.clickGenerateCsvBtn()}>CSV Generieren</div> : ''}
        </div>;

        this.setState({active: !this.state.active});

        if(this.state.active === false){
            this.setState({dropdown: dropdownOptions});
        }else{
            this.setState({dropdown: ''})
        }
    };

    toggle = () => {
        if(this.props.disabled)
            this.setState({tooltipOpen: !this.state.tooltipOpen});
    };

    render(){
        var activeClass = '';

        const {hideTooltip, disableTooltip, classes, wrapper_classes} = this.props;
        const {button_name} = this.state;
        let show_tooltipp = validateVar(hideTooltip) ? hideTooltip : (validateVar(disableTooltip) ? disableTooltip : false);

        if(this.state.active){
            activeClass = 'active'
        }else{
            activeClass = '';
        }


        let disabled = false;
        if(typeof this.props.options !== 'undefined')
            if(this.props.options.length === 0) disabled=true;

        if(this.props.disabled) disabled=true;

        return(
            <>
                <CustomAlert title='Achtung'
                    text={'Möchten Sie die ausgewählten ' +this.props.tooltipProp+ ' wirklich entfernen?'}
                    primaryBtnText='Ja'
                    secondaryBtnText='Abbrechen'
                    primaryBtnClicked={this.deleteConfirmed}
                    secondaryBtnClicked={() => this.deletionWarning.current.turnOffModal()}
                    ref={this.deletionWarning}
                    allowToggle />
                <div ref={this.setWrapperRef} className={'dropdown-button-wrapper ' +wrapper_classes}>
                    <Button id={this.props.id ? this.props.id : 'optionenButton'} disabled={disabled} onClick={this.buttonClicked} className={'btn eless-btn btn-outline-eless dropdown-button ' + activeClass + ' ' + classes}>
                        {button_name}
                    </Button>
                    <ReactCSSTransitionGroup
                        transitionName="fade"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}>
                        {this.state.dropdown}
                    </ReactCSSTransitionGroup>
                </div>
                {!show_tooltipp &&
                <Tooltip placement="bottom"
                         isOpen={this.state.tooltipOpen}
                         target={this.props.id ? this.props.id : 'optionenButton'}
                         toggle={this.toggle}
                >
                    Bitte selektieren Sie die {this.props.tooltipProp}, mit denen Sie eine Aktion ausführen wollen.
                </Tooltip>}
            </>
        )
    }
}

export default DropdownButton;