/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import ReturnButton from "../Buttons/ReturnButton";
import cn from 'classnames';

class TimelineHeader extends React.Component {
  render() {
    return (
      <div className={"header header-dark pb-6 content__title content__title--calendar"+cn({' bg-info': !this.props.is_guest})}>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="12" xs="12">
                <h2 className="light d-inline-block mb-0">
                  {!this.props.is_guest && this.props.name}
                </h2>
                {this.props.returnButton && <ReturnButton prevPage={this.props.prevPage} text={this.props.returnButton} />}
                <div className={'white-color font-weight-thin'}>
                  {this.props.headerText}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  headerText: PropTypes.string
};

export default TimelineHeader;
