import {emptyValue} from "./global";
import handleFormatNumber from "./handleFormatNumber";
import validateVar from "./validation/validateVariable";
import is_false from "./is_false";


function handleFormatPrice(number = null, comma = null, unit = "") {
  // if (number+"".includes("ct / kWh")) return number;
  if (is_false(validateVar(number))) return "";
  else {
      if (is_false(Number.isInteger(+comma))) { // hotfix for error when a number is not set - the comma value moves in the number field - to fix it we take number as 0 and add the origin comma value
          let commaValueToAdd = 0;
          commaValueToAdd = commaValueToAdd.toFixed(comma) + '';
          commaValueToAdd = ','+commaValueToAdd.replace("0.", "");
          return "0" + commaValueToAdd
      }
      let negativ = (+number < 0 );
      let increase_number_before_comma = false; //If the number after comma is beeing rounded to 1,00, increase the number before comma by +1
      let str_number = number + "";
      str_number = str_number.replace(/\./g, ",");
      if (str_number === "-/-") return emptyValue;
      let str_splitted = str_number.split(",");
      if (typeof str_splitted[0] === "undefined") return emptyValue;
      else {
          let number_before_comma = handleFormatNumber(str_splitted[0]); //Add a "." to separate all sets of 3 numbers for better readability of the user

          let commaValueToAdd = 0;
          if(validateVar(str_splitted[1])){ //When there is a , value
              commaValueToAdd = commaValueToAdd + '.' + str_splitted[1]; //Adding a 0. infront of the comma value to be able to run toFixed on it
              commaValueToAdd = +commaValueToAdd; //Turn the value to numeric to be able to run toFixed on it
              commaValueToAdd = commaValueToAdd.toFixed(comma) + ''; //format comma value to the wanted length
              if(commaValueToAdd.includes('1.')) { //
                  increase_number_before_comma = true;
                  commaValueToAdd = ',00';
              }else commaValueToAdd = ','+commaValueToAdd.replace("0.", ""); // remove 0. from value to be able to add it as a string the the number
          }else {//When there is no , value add ,00 or ,000
              commaValueToAdd = commaValueToAdd.toFixed(comma) + '';
              commaValueToAdd = ','+commaValueToAdd.replace("0.", "");
          }

          if(increase_number_before_comma) {//increase number before comma if number after comma got rounded to 1.00
              number_before_comma = +number_before_comma.replace(/\./g, "");
              number_before_comma++;
              number_before_comma = handleFormatNumber(number_before_comma);
          }
          unit = (validateVar(unit)) ? " " + unit : "";
          let return_value = number_before_comma + commaValueToAdd + unit;
          return (negativ === true) ? "-" + return_value : return_value ;
      }
  }
}

export default handleFormatPrice;
