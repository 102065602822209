import React from 'react';
import {Card, CardBody, CardHeader, Col, Modal, Row} from "reactstrap";
import PrimaryButton from "../Displays/Buttons/PrimaryButton";
import InputHandler from "../../module_components/Inputs/InputHandler";
import Validation from "../Objects/Components/validation";
import create_table_content_from_options from "../Functions/createTableContentFromOptions";
import connect from "react-redux/es/connect/connect";
import {handle_table_actions} from "../Index/new_index";
import validateVar from "../Functions/validation/validateVariable";
import dataToSelectField from "../Functions/dataToSelectField";
import GreyLinkFeedbackModalButton from "../Displays/Buttons/GreyLinkFeedbackModalButton";
import SecondaryButton from "../Displays/Buttons/SecondaryButton";
import objectToArray from "../Functions/objectToArray";
import {apiError} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";

const default_layout_name = "Standardansicht";

class TableInputModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            error_messages: {},
            designs_loaded: false,
            is_loaded_table: false,
            is_loaded_sort_function: false,
        };
        this.key_field = props.key_field;
    }

    componentDidMount() {
        let {table_name} = this.props;
        let table_params = {
            table_name: table_name,
            is_list: true,
        };

        table_params = getAgencyID(table_params, true);

        // TODO: fix referencing behavior for multiple tables on one page.
        this.props.handle_table_actions(table_params, "GET");
    }

    componentDidUpdate(prevProps) {
        const {table, table_name} = this.props;

        if (validateVar(table) && table !== prevProps.table) {
            if (table !== apiError) {
                let current_table = null;
                for (let single_table of table) {
                    //get the current table from the redux props table array to render it properly in the current modal
                    //this case is important because of pages with multiple tables where the table prop got overriden by each nex request response
                    if(validateVar(single_table) && validateVar(single_table.layouts) && single_table.layouts[0].table_name === table_name) {
                        current_table = single_table;
                    }
                }

                if (validateVar(current_table)) {

                    let all_options = current_table.options;

                    let all_table_layouts = current_table.layouts;
                    let current_selected = (all_table_layouts.find(x => x.is_selected === "YES"));

                    let layouts = dataToSelectField("layout_name", "layout_name", all_table_layouts, null, true);
                    layouts.push({key: "new", value: "Neues Design hinzufügen"});

                    this.setState({
                        table: all_table_layouts,
                        current_table: current_table,
                        current_table_layout: create_table_content_from_options(current_selected.table_layout),
                        is_loaded_table: true,
                        table_layout: current_selected.table_layout_array, // selected values from multiselect
                        table_layouts: current_selected.table_layout, // selected values from multiselect
                        layout_name: current_selected.layout_name, // selected name from layout
                        design_selection: current_selected.layout_name, // selected layout from select box
                        design_selection_options: layouts, // options of select box
                        submit_disabled: false,
                        name_disabled: (current_selected.layout_name === default_layout_name),
                        multi_select_disabled: (current_selected.layout_name === default_layout_name),
                        sort_disabled: (current_selected.layout_name === default_layout_name),
                        delete_disabled: (current_selected.layout_name === default_layout_name || current_selected.layout_name === "new"),
                        all_table_layouts: all_table_layouts,
                        all_options: all_options
                    });

                    //this function overrides all tables in case there are more than one table on the page, with the table_name
                    //its possible to check if the current response belongs to the current component to avoid overriding others
                    this.props.change_table_input(create_table_content_from_options(current_selected.table_layout));
                }
            }
        }
    }


    myChangeHandler = (name, value) => {
        const {table, all_options} = this.state;
        if (name === "design_selection") {
            let selected_table = table.find(x => x.layout_name === value);
            let table_layout_array = (validateVar(selected_table) && validateVar(selected_table.table_layout_array)) ? selected_table.table_layout_array : [this.key_field];
            let table_layouts = (validateVar(selected_table) && validateVar(selected_table.table_layout)) ? selected_table.table_layout : [all_options.find(x => x.value === this.key_field)];
            this.setState({
                is_loaded_sort_function: false,
                layout_name: (value === "new") ? "" : value,
                table_layout: table_layout_array,
                table_layouts: table_layouts,
                name_disabled: (value === default_layout_name),
                sort_disabled: (value === default_layout_name),
                multi_select_disabled: (value === default_layout_name),
                delete_disabled: (value === default_layout_name || value === "new")
            });
        } else if (name === "table_layouts") {
            let table_layout_array = objectToArray(value, "value");
            this.setState({
                table_layout: table_layout_array
            });
        } else if (name === "table_layout") {
            let table_layouts = [];
            for (let index in value) {
                if (value.hasOwnProperty(index)) {
                    let val = value[index];
                    table_layouts.push(all_options.find(x => x.value === val))
                }
            }
            this.setState({
                table_layouts: table_layouts,
                is_loaded_sort_function: false
            });
        }
        this.setState({[name]: value});
    };

    toggleModal = () => {
        let {showModal} = this.state;
        this.setState({
            showModal: !showModal,
            error_messages: {},
            submit_disabled: false,
        })
    };

    turnOffModal() {
        this.setState({
            showModal: false
        });
    };

    submit = () => {
        let {table_name} = this.props;
        let {table_layout, design_selection, layout_name, table, all_options} = this.state;
        this.setState({submit_disabled: true});

        /* special cases for validation */
        let special_cases = {
            table_layout: {case_name: "multi_select"},
            design_selection: {case_name: "select"},

        };

        if (layout_name !== default_layout_name) special_cases.layout_name = {
            case_name: "compare_values",
            compare_value: [default_layout_name]
        };

        let data = {
            table_name: table_name,
            layout_name: layout_name,
            table_layout: table_layout,
        };

        data = getAgencyID(data, true);

        let validation_result = new Validation(data, special_cases);

        this.setState({
            error_messages: validation_result.error_messages
        });

        if (!validation_result.has_error) { // if no error
            let selected_options = [];
            table_layout.forEach(
                value => {
                    selected_options.push(all_options.find(x => x.value === value));
                }
            );

            let params = {
                table_name: table_name,
                layout_name: layout_name,
                table_layout: selected_options,
                is_selected: "YES"
            };
            params = getAgencyID(params, true);


            if (design_selection === "new") {
                this.props.handle_table_actions(params, "POST")
            } else {
                let selected_table = table.find(x => x.layout_name === design_selection);
                if (validateVar(selected_table)) {
                    params.layout_id = selected_table.id;
                    this.props.handle_table_actions(params, "PUT")
                } else this.setState({submit_disabled: false});
            }
        } else {
            this.setState({submit_disabled: false});
        }
    };


    delete_layout = () => {
        let {table_name} = this.props;
        let {design_selection, layout_name, table} = this.state;
        let selected_table = table.find(x => x.layout_name === design_selection);
        let params = {
            table_name: table_name,
            layout_name: layout_name,
            layout_id: selected_table.id
        };
        params = getAgencyID(params, true);
        this.props.handle_table_actions(params, "DELETE")
    };

    render() {
        const {
            error_messages, table_layout, design_selection, design_selection_options, layout_name, submit_disabled, name_disabled, sort_disabled,
            multi_select_disabled, delete_disabled, is_loaded_table, all_options, table_layouts, is_loaded_sort_function, showModal
        } = this.state;
        const {table_layout_error, design_selection_error, layout_name_error} = error_messages;
        if (is_loaded_sort_function === false && is_loaded_table) this.setState({is_loaded_sort_function: true});

        return (
            <>
                {(is_loaded_table === true) &&
                <Modal
                    className="modal-dialog-centered"
                    style={{maxWidth: '1600px', width: '80%'}}
                    isOpen={showModal}
                    toggle={() => this.toggleModal()}>
                    <div className="modal-body p-0">
                        <Card className="bg-secondary border-0 mb-0">
                            <CardHeader className="bg-transparent modal-title mb-0 mx-0">
                                <Row>
                                    <Col lg={6}>
                                        <h2 className='mb-0 text-left'>Tabellen Einträge</h2>
                                    </Col>
                                    <Col lg={6}>
                                        <button onClick={() => this.toggleModal()} className={'close'}>
                                            <i className={'ni ni-fat-remove close-button'}/>
                                        </button>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <CardBody className="px-lg-4 py-lg-4">
                                <Row>
                                    <Col lg={6}>
                                        <InputHandler
                                            content_title="Ansichtsauswahl"
                                            type="select"
                                            id='design_selection'
                                            name="design_selection"
                                            value={design_selection}
                                            on_change={this.myChangeHandler}
                                            select_options={design_selection_options}
                                            error_state={design_selection_error}
                                            no_margin={true}
                                        />
                                        <div className={"mb-3 linked-span"}
                                             onClick={() => this.myChangeHandler("design_selection", default_layout_name)}>Standardansicht
                                            wählen
                                        </div>


                                        <InputHandler
                                            content_title="Ansichtsname"
                                            type="text"
                                            name="layout_name"
                                            value={layout_name}
                                            placeholder={"Name"}
                                            on_change={this.myChangeHandler}
                                            error_state={layout_name_error}
                                            disabled={name_disabled}
                                        />

                                        <InputHandler
                                            type='multiselect'
                                            content_title='Spaltenauswahl'
                                            id={"table_layout"}
                                            name='table_layout'
                                            on_change={(name, selected) => this.myChangeHandler(name, selected)}
                                            disabled={multi_select_disabled}
                                            multiselect_options={all_options}
                                            value={table_layout}
                                            error_state={table_layout_error}
                                        />

                                        <Row>
                                            <Col className='mb-3 mb-lg-0' lg={4}>
                                                <div className="text-center">
                                                    <PrimaryButton
                                                        id='createUserSubmit'
                                                        onClick={() => this.submit()}
                                                        disabled={submit_disabled}
                                                        value={"Bestätigen"}
                                                        classes='btn-modal w-100'
                                                    />
                                                </div>
                                            </Col>

                                            <Col lg={4}>
                                                <div className="text-center">
                                                    <SecondaryButton
                                                        id='createUserSubmit'
                                                        onClick={() => this.toggleModal()}
                                                        value={"Abbrechen"}
                                                        classes='btn-modal w-100'
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={12} lg={3}>
                                                <div>
                                                    <GreyLinkFeedbackModalButton
                                                        text={"Layout löschen"}
                                                        icon={<i className="fas fa-trash mr-2"/>}
                                                        onClick={() => this.delete_layout()}
                                                        classes={"delete-layout-button mt-md-3 mt-lg-0"}
                                                        disabled={delete_disabled}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col lg={6}>
                                        <InputHandler
                                            type='drag_and_drop'
                                            content_title='Spalten Reihenfolge'
                                            id={"table_layouts"}
                                            name='table_layouts'
                                            key={"id"}
                                            key_value={"label"}
                                            input_wrapper_classes='drag-and-drop-handler'
                                            is_loaded={is_loaded_sort_function}
                                            skeleton={[400, 600]}
                                            on_change={(name, value) => this.myChangeHandler(name, value)}
                                            value={table_layouts}
                                            disabled={sort_disabled}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>} </>)
    }
}

// export default TableInputModal;

let mapStateToProps = function (state) {
    return {
        table: state.table,
    }
};

let mapDispatchToProps = {
    handle_table_actions: handle_table_actions,
};

let TableInputModalContainer = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(TableInputModal);

export default TableInputModalContainer;
