export default function get_payment_options(payment_sepa_direct_debit, payment_bank_transfer, payment_cash) {
    let payment_sepa_direct_debit_accepted =
        payment_sepa_direct_debit === "YES" ? 1 : 0;
    let payment_bank_transfer_accepted =
        payment_bank_transfer === "YES" ? 1 : 0;
    let payment_cash_accepted = payment_cash === "YES" ? 1 : 0;
    return [
        {
            label: "SEPA-Lastschrift",
            value: "payment_sepa_direct_debit",
            accepted: payment_sepa_direct_debit_accepted
        },
        {
            label: "Überweisung",
            value: "payment_bank_transfer",
            accepted: payment_bank_transfer_accepted
        },
        {
            label: "Barzahlung",
            value: "payment_cash",
            accepted: payment_cash_accepted
        }
    ];
}
