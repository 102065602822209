import React from 'react';
import validateVar from "../Functions/validation/validateVariable";
import is_true from "../Functions/is_true";
import Skeleton from "react-loading-skeleton";
import GreyLinkFeedbackModalButton from "../Displays/Buttons/GreyLinkFeedbackModalButton";
import cn from 'classnames';
import LoadingSpinner from "../LoadingAnimations/LoadingSpinner";
class FileUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultValue: "Datei wählen oder hier ablegen",
            errorMessage: "",
            disabled: false
        };

        this.fileUpload = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {disabled} = this.props;

        if(validateVar(disabled) && disabled !== prevProps.disabled) this.setState({disabled: disabled});
    }

    changeFile = (e) => {
        let {file_type, fileChanged, setErrorMessage, cropper} = this.props;
        setErrorMessage(false);
        let error = 0;
        let file = e.target.files[0];
        if (validateVar(file)){
            let file_size = file.size;
            let filename = file.name;
            let message = "";

            //var checkFile = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined
            //check file type
            var endingCheck = filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
            endingCheck = endingCheck.toLowerCase();
            if(file_type === "image"){
                if(!endingCheck.includes("png") && !endingCheck.includes("jpg")){
                    message = "Bitte laden Sie eine Bilddatei in 'JPG' oder 'PNG' Format hoch";
                    error = 1;
                }
            }else if(file_type === "pdf"){
                if (!endingCheck.includes("pdf")) {
                    message = "Bitte wählen Sie ein .pdf Dateiformat";
                    error = 1;
                }
            }
            else if (file_type === "allow_all") console.log("all files allowed");
            else {
                if (!endingCheck.includes("csv") && !endingCheck.includes("xlsx") && !endingCheck.includes("xls")) {
                    message = "Bitte wählen Sie ein .CSV Dateiformat";
                    error = 1;
                }
            }
            //check file size (kb)
            if (file_size < 100) {
                message = "Die Datei ist leer.";
                error = 1;
            } else if (file_size > 5000000) {
                message = "Die Datei darf max. 5 MB groß sein.";
                error = 1;
            }

            if (error === 0){ //success
                if(cropper){
                    fileChanged(e);
                    setErrorMessage(false);
                }else{
                    fileChanged(e);
                }
            }else{ //error
                setErrorMessage(message);
                this.setState({
                    defaultValue: "Datei wählen oder hier ablegen",
                });
            }
        }
    };

    resetValue = () => {
        if(validateVar(this.fileUpload.current)){
            this.fileUpload.current.value = null;
            this.props.hasReset();
        }
    };


    get_upload_field = () => {

        const {value, file_type, errorMessage, resetValue, cropperHeadline, classes, allowDelete, is_loaded, uploading} = this.props;
        const {defaultValue, disabled} = this.state;

        let is_loaded_component =  (validateVar(is_loaded)) ? is_loaded : true;
        if(resetValue) this.resetValue();

        let valueDisplay = value;
        if(!validateVar(value)) valueDisplay = defaultValue;

        let acceptedFiles = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
        if(file_type === "image") acceptedFiles = 'image/x-png,image/jpeg';
        else if(file_type === "pdf") acceptedFiles = '.pdf';
        else if(file_type === "allow_all") acceptedFiles = '';

        let errorPropsClass = '';
        if(errorMessage) errorPropsClass = ' alert-border';
        let aspect_ratio = 1.5;
        if (validateVar(this.props.aspect_ratio)) aspect_ratio = this.props.aspect_ratio;

        let classNames = '';
        if(classes) classNames = ' '+classes;

        return(
            <>
            {(is_true(is_loaded_component)) ? <div className={"custom-file overflow-hidden"+classNames}>
            <input
                accept={acceptedFiles}
                className="custom-file-input cursor-pointer"
                id="fileInput"
                lang="de"
                type="file"
                name={this.props.name}
                placeholder={''} //Placeholder geht hier nicht
                onChange={(e) => this.changeFile(e)}
                ref={this.fileUpload}
                data-ratio={aspect_ratio}
                data-headline={cropperHeadline}
                disabled={disabled || uploading}
            />
            <label
                className={"custom-file-label cursor-pointer"+errorPropsClass}
                htmlFor="customFileLang"
            >
                {uploading ? <LoadingSpinner classes={'button-spinner-size'} /> : valueDisplay}
            </label>
            {validateVar(value) && allowDelete && <div className='delete-file noselect' onClick={() => !uploading && this.props.deleteFile()}>Aktuelle Datei löschen</div>}
        </div>
          : <Skeleton width={200} />  }</>);
    };


    handleClickUploadBtn = (trash_class_name) => {
        if (validateVar(trash_class_name)) {
            this.fileUpload.current.click();
        } else {
            this.fileUpload.current.value = null;
            this.props.deleteFile();
        }
    };

    get_upload_button = () => {
        let {value, disabled, text} = this.props;
        let trash_class_name = "d-none";
        let title = "Bitte laden Sie eine Datei hoch";
        if (validateVar(value)) {
            trash_class_name = "";
            title = value;
        } else value = validateVar(text) ? text : "Datei anhängen";


        let disabled_class = (disabled) ? " disabled " : " ";

        return <div className='upload-button d-inline-block position-relative'>
            <label title={title} onClick={() => this.handleClickUploadBtn(trash_class_name)}  className={'cursor-pointer btn btn-secondary' + disabled_class + 'mb-0 eless-font-bold'}>
                {validateVar(trash_class_name) && <><span className='d-sm-none'>Anhang</span>
                <span className='d-none d-sm-inline-block'>{value}</span> </>}
                <i className={"fa fa-trash-alt " + trash_class_name} />
            </label>
            <input
                // accept={} Der Button befindet sich in der Chronik und akzeptiert alle Dateitypen
                id='fileInput'
                disabled={disabled}
                type='file'
                onChange={(e) => this.changeFile(e)}
                className='d-none'
                ref={this.fileUpload}
            />
        </div>
    };

    get_upload_text = () => {
        let {disabled, text, is_loaded, file_type} = this.props;

        let acceptedFiles = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
        if(file_type === "image") acceptedFiles = 'image/x-png,image/jpeg';
        else if(file_type === "pdf") acceptedFiles = '.pdf';

        return <div>
            <label onClick={() => this.handleClickUploadBtn(true)}>
                <GreyLinkFeedbackModalButton onClick={() => function (){}}
                                            is_loaded={is_loaded}
                                             classes={'text-right eless-color '+cn({'disabled': disabled})}
                                             text={text} />
            </label>
            <input
                accept={acceptedFiles}
                id='fileInput'
                disabled={disabled}
                type='file'
                onChange={(e) => this.changeFile(e)}
                className='d-none'
                ref={this.fileUpload}
            />
        </div>

    };

    render(){
        const {upload_type} = this.props;
        if (upload_type === "file_upload_text") return this.get_upload_text();
        else if (upload_type === "file_upload_button") return this.get_upload_button();
        else return this.get_upload_field();
    }
}

export default FileUpload;
