import React from 'react';
import {Col, Row} from 'reactstrap';
import validateInteger from '../Functions/validation/validateInteger';
import getCities from '../Functions/getCities';
import getStreets from '../Functions/getStreets';
import validateVar from '../Functions/validation/validateVariable';
import InputHandler from '../Inputs/InputHandler';

class AddressForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            streetsArray: [],
            currentFocus: -1,
            postal_code_error: false,
            street_error: false,
            city_disabled: true,
            city_obj: [],
            disable_city: true,
            error_messages: []
        }

        const {prefix} = this.props;
        this.postal_code_name = 'postal_code';
        this.city_name = 'city';
        this.street_name = 'street';
        this.house_number_name = 'house_number';
        this.company_name = 'company';
        this.state_name = 'state';
        this.email_name = 'email';

        if(validateVar(prefix)){//if the address to display has "invoice_" or "delivery_" in front of the property name, it can be given inside the prefix prop
            this.postal_code_name = prefix+this.postal_code_name;
            this.city_name = prefix+this.city_name;
            this.street_name = prefix+this.street_name;
            this.house_number_name = prefix+this.house_number_name;
            this.company_name = prefix+this.company_name;
            this.state_name = prefix+this.state_name;
            this.email_name = prefix+this.email_name;
        }
    }

    componentDidMount() {
        const {address} = this.props;

        document.body.addEventListener('nv-enter', function (event) {
            // logic
        });

        if(validateVar(address)){
            this.get_cities();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {address} = this.props;

        if(validateVar(address) && prevProps.address !== address){
            this.get_cities();
        }
    }

    get_cities(){
        let {address} = this.props;

        if(validateVar(address[this.postal_code_name])) address[this.postal_code_name] = address[this.postal_code_name] + '';
        let disabled = true;

        if (validateVar(address[this.postal_code_name]) && address[this.postal_code_name].length === 5) {
            getCities(address[this.postal_code_name]).then((cities_result) => {
                if (cities_result !== 'ERR') {
                    getStreets(address[this.postal_code_name], cities_result[0].city).then(streets_result => {
                        if (streets_result !== 'ERR') {
                            //set streets and remove disabled attribute from streets field
                            this.setState({
                                streetsArray: streets_result,
                                city_obj: this.formatCityResults(cities_result),
                                disable_city: false,
                            });
                        }
                    })
                }
            });
            disabled = false;
        }

        //disable inputs if empty, enable inputs if they contain value on pageload
        this.setState({
            street_disabled: disabled,
            house_number_disabled: disabled
        });
    }

    // ------------------------ AUTOCOMPLETE STREET -----------------------
    removeActive(x) {
        /*a function to remove the 'active' class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
        }
    }

    closeAllLists(inp) {
        /*close any already open lists of autocompleted values*/
        var x = document.getElementsByClassName('autocomplete-items');
        for (var i = 0; i < x.length; i++) {
            if (inp !== x[i] && inp !== inp.value) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    };

    addActive(x, currentFocus) {
        /*a function to classify an item as 'active':*/
        if (!x) return false;
        /*start by removing the 'active' class on all items:*/
        this.removeActive(x);

        var arr = Array.from(x);

        if (currentFocus >= arr.length) {
            currentFocus = 0;

        } else if (currentFocus < 0) {
            currentFocus = 0;
        }
        /*add class 'autocomplete-active':*/
        x[currentFocus].classList.add('autocomplete-active');
        return currentFocus;
    };

    handle_street_change = (name, value, input) => {
        let {address} = this.props;

        let arr = this.state.streetsArray;
        address[this.street_name] = input.value;
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/

        /*execute a function when someone writes in the text field:*/
        var a, b, i, val = input.value;

        this.closeAllLists(input);

        if (!validateVar(input.value)) {
            address[this.street_name] = '';
            this.props.on_change(address);
        } else {
            var count = 0;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement('DIV');

            /*append the DIV element as a child of the autocomplete container:*/
            input.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()) {
                    a.setAttribute('id', input.id + 'autocomplete-list');
                    a.setAttribute('class', 'autocomplete-items');
                    this.setState({street_error: false});
                    count++;
                    if (count === 8) break;
                    /*create a DIV element for each matching element:*/
                    b = document.createElement('DIV');
                    /*make the matching letters bold:*/
                    b.innerHTML = '<strong>' + arr[i].substr(0, val.length) + '</strong>';
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input class='street-val' type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    const that = this;
                    b.addEventListener('click', function (e) {

                        /*insert the value for the autocomplete text field:*/
                        input.value = this.getElementsByTagName("input")[0]['value'];
                        //set value to state
                        address[that.street_name] = input.value;

                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        // closeAllLists();

                        //close all lists
                        var x = document.getElementsByClassName('autocomplete-items');
                        for (var i = 0; i < x.length; i++) {
                            if (input !== x[i] && input !== input.value) {
                                x[i].parentNode.removeChild(x[i]);
                            }
                        }
                        document.getElementById(that.props.form_id + '_house_number').focus();
                    });

                    a.appendChild(b);
                }
            }
            if (count === 0) {
                // this.setState({street_error: 'Für Ihre Eingaben gibt es keine Strassenvorschläge.'});
            }

            this.props.on_change(address);
        }
    };

    handleKeydownStreet = (event) => {
        let address = this.props.address;

        var x = document.getElementById(event.target.id + 'autocomplete-list');
        var currentFocus = this.state.currentFocus;
        if (x) x = x.getElementsByTagName('div');
        if (event.key === 'ArrowDown') {
            currentFocus++;
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            /*and and make the current item more visible:*/
            currentFocus = this.addActive(x, currentFocus);
        } else if (event.key === 'ArrowUp') { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            currentFocus = this.addActive(x, currentFocus);
        } else if (event.key === 'Enter') {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            event.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the 'active' item:*/
                var div = x[currentFocus];
                let value = div.getElementsByTagName('input')[0].value;
                address[event.target.name] = value;
                // this.props.on_change('street', value);
                this.props.on_change(address);

                // e.target.value = div.getElementsByTagName('input')[0].value;
                this.closeAllLists(event.target);
                document.getElementById(this.props.form_id + '_house_number').focus();
            }
        }
        this.setState({currentFocus: currentFocus});
    };

    // ---------------- END AUTOCOMPLETE STREET -----------------

    /* Postleitzahl */
    handle_postal_code_change = (name, postal_code, input) => {
        let address = this.props.address;

        let postal_code_len = postal_code.length;
        this.setState({postal_code_error: false});

        let street = document.getElementById(this.props.form_id + '_street');

        if (postal_code_len === 5) {
            getCities(postal_code).then((cities_result) => {
                if (cities_result !== 'ERR') {
                    input.disabled = true;
                    //empty street on change (streets might not be valid anymore)
                    address[this.street_name] = '';

                    let disable_city = true;
                    let city_select_field_format = this.formatCityResults(cities_result);
                    if (city_select_field_format.length > 1) disable_city = false;
                    let state = cities_result[0].state;
                    //set city
                    address[this.city_name] = city_select_field_format[0].key;
                    address[this.state_name] = state;
                    let city_obj = city_select_field_format;
                    //get street results for the city
                    getStreets(postal_code, cities_result[0].city).then(streets_result => {
                        if (streets_result !== 'ERR') {
                            //set streets and remove disabled attribute from streets field
                            this.setState({
                                streetsArray: streets_result,
                            });
                            //enable fields for changes
                            //todo target.disabled = false;
                            this.setState({
                                street_disabled: false,
                                house_number_disabled: false,
                                city_obj: city_obj,
                                disable_city: disable_city
                            });
                            //set focus to street (useability)
                            street.focus();
                        } else {
                            this.setState({street_error: 'Straße konnte nicht gefunden werden.'})
                        }

                        input.disabled = false;
                    });
                } else {
                    this.setState({postal_code_error: 'Es gibt keinen Ort mit dieser Postleitzahl.'})
                }
            });
        } else {
            address[this.street_name] = '';
            this.setState({
                street_disabled: true,
                house_number_disabled: true
            });
        }
        if (validateInteger(postal_code) || postal_code === '') address[this.postal_code_name] = postal_code;
        if (postal_code === '') address[this.city_name] = '';

        this.props.on_change(address)
    };

    formatCityResults = (city_results) => {
        let formated_array = [];
        city_results.map((city) => {
            formated_array.push({
                key: city.city,
                value: city.city
            });
        });
        return formated_array;
    };

    handle_city_change = (name, city) => {
        let address = this.props.address;

        address.street = '';
        address.city = city;
        this.props.on_change(address);
        let street = document.getElementById(this.props.form_id + '_street');
        this.closeAllLists(street);
        if (validateVar(city)) {
            getStreets(address.postal_code, city).then(streets_result => {
                if (streets_result !== 'ERR') {
                    this.setState({
                        streetsArray: streets_result,
                        street_disabled: false,
                        house_number_disabled: false
                    });
                    //set focus to street (useability)
                    street.focus();
                } else {
                    this.setState({
                        street_disabled: true,
                        house_number_disabled: true
                    });
                    this.setState({street_error: 'Straße konnte nicht gefunden werden.'})
                }
            });
        } else {
            this.setState({
                street_disabled: true,
                house_number_disabled: true
            });
        }
    };

    update_state = (name, value) => {
        let address = this.props.address;
        address[name] = value;
        this.props.on_change(address);
    };

    render() {
        const {form_id, invoice_address, is_loaded, disabled, address} = this.props;
        const {street_disabled, house_number_disabled, city_obj, disable_city, postal_code_error} = this.state;
        const prop_error_messages = this.props.error_messages;

        //set priority on props error messages
        let error_messages_ = postal_code_error ? {
            postal_code_error: postal_code_error,
        } : prop_error_messages;
        let city_obj_ = city_obj.length > 0 ? city_obj : [{key: 'Ort', value: 'Ort'}];

        return (
            <div>
                {invoice_address && <Row>
                    <Col>
                        <InputHandler
                            content_title='Rechnungsempfänger'
                            type='text'
                            name={this.company_name}
                            value={address[this.company_name]}
                            disabled={disabled}
                            on_change={(name, value) => this.update_state(name, value)}
                            error_state={error_messages_[this.company_name+'_error']}
                            max_length={120}
                            optional={true}
                            is_loaded={is_loaded}
                        />
                    </Col>
                    <Col>
                        <InputHandler
                            content_title='Rechnungsmail'
                            type='text'
                            name={this.email_name}
                            value={address[this.email_name]}
                            disabled={disabled}
                            on_change={(name, value) => this.update_state(name, value)}
                            error_state={error_messages_[this.email_name+'_error']}
                            max_length={120}
                            optional={true}
                            is_loaded={is_loaded}
                        />
                    </Col>
                </Row>}
                <Row>
                    <Col>
                        <InputHandler
                            content_title='Postleitzahl'
                            type='text'
                            name={this.postal_code_name}
                            placeholder='PLZ'
                            value={address[this.postal_code_name]}
                            disabled={disabled}
                            on_change={(name, value, event) => this.handle_postal_code_change(name, value, event.target)}
                            error_state={error_messages_[this.postal_code_name+'_error']}
                            is_loaded={is_loaded}
                            max_length={5}
                        />
                    </Col>
                    <Col>
                        <InputHandler
                            content_title='Ort'
                            type='select'
                            name={this.city_name}
                            value={address[this.city_name]}
                            on_change={(name, value) => this.handle_city_change(name, value)}
                            disabled={disable_city || disabled}
                            select_options={city_obj_}
                            error_state={error_messages_[this.city_name+'_error']}
                            is_loaded={is_loaded}
                            remove_empty_option={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputHandler
                            content_title='Straße'
                            id={form_id + '_street'}
                            type='text'
                            name={this.street_name}
                            placeholder='Straße'
                            on_change={(name, value, event) => this.handle_street_change(name, value, event.target)}
                            on_key_down={(event) => this.handleKeydownStreet(event)}
                            value={address[this.street_name]}
                            disabled={street_disabled || disabled}
                            error_state={error_messages_[this.street_name+'_error']}
                            is_loaded={is_loaded}
                        />
                    </Col>
                    <Col>
                        <InputHandler
                            content_title='Hausnummer'
                            id={form_id + '_house_number'}
                            type='text'
                            name={this.house_number_name}
                            placeholder='Hausnummer'
                            on_change={(name, value) => this.update_state(name, value)}
                            value={address[this.house_number_name]}
                            disabled={house_number_disabled || disabled}
                            error_state={error_messages_[this.house_number_name+'_error']}
                            is_loaded={is_loaded}
                        />
                    </Col>
                </Row>
            </div>
        );
    };
}

export default AddressForm;