import getGermanDateFormat from "./getGermanDateFormat";
import handleFormatDateForFrontend from "./handleFormatDateForFrontend";
import handleFormatPrice from "./handleFormatPrice";
import handleCancellationPeriod from "./handleFormatCancellation";
import getConsultantFeeInEuro from "./getConsultantFeeInEuro";
import handleExtentionPeriod from "./handleFormatExtention";
import getMediumType from "./getMediumType";
import handleFormatNumber from "./handleFormatNumber";
import getLastType from "./getLastType";
import handleFormatSocket from "./handleFormatSocket";
import getSignTypeDisplay from "./getSignTypeDisplay";
import getEnergySource from "./getEnergySource";
import {combine_strings_from_array} from "./combine_strings_from_array";
import replace_all_dots from "./replace_all_dots";
import validateInteger from "./validation/validateInteger";
import getValidBasePrice from "./getValidBasePrice";
import getValidPriceRate from "./getValidPriceRate";
import handleFormatDate from "./handleFormatDate";
import validatePercentageToTwoDecimal from "./validation/validatePercentageToTwoDecimal";
import getValidPercentage from "./getValidPercentage";
import getPaymentMethod from "./getPaymentMethode";
import getCustomerType from "./getCustomerType";
import getSolvencyDisplay from "./getSolvencyDisplay";
import getContractSource from "./getContractSoure";
import is_spot from "./is_spot";
import getTotalSurcharge from "./getTotalSurcharge";
import getHandlingFee from "./getHandlingFee";
import getTimeDiff from "./getTimeDiff";
import translateContractStatus from "./translateContractStatus";
import getConsultantChargeAmount from "./getConsultantChargeAmount";
import getFullAddress from "./getFullAddress";
import getSwitchingStateBtn from "./getSwitchingStateBtn";
import getDisplaySwitchingStatus from "./getDisplaySwitchingStatus";
import checkStatusCp from "./checkStatusCp";
import getDisplayConsumption from "./getDisplayConsumption";
import getBusinessTypeDisplay from "./getBusinessTypeDisplay";
import getMarketIdentifier from "./getMarketIdentifier";
import getConsumptionOffsetDisplay from "./getConsumptionOffsetDisplay";
import getPeakDisplay from "./getPeakDisplay";
import getSpecificationDisplay from "./getSpecificationDisplay";
import getSwitchingDate from "./getSwitchingDate";
import getNumericValue from "./getNumericValue";
import getCancellationDisplay from "./getCancellationDisplay";
import getHabitationStatusDisplay from "./getHabitationStatusDisplay";
import checkIfHasPreProvider from "./checkIfHasPreProvider";
import hasInvoiceAddress from "./hasInvoiceAddress";
import getDefaultDateObject from "./getDefaultDateObject";
import getBadgeColor from "./getBadgeColor";
import getContractStatus from "./getContractStatus";
import fill_value_if_not_existing from "./fill_value_if_not_existing";
import getFirstElementFromArray from "./getFirstElementFromArray";
import callComponentWithString from "./callComponentWithString";
import validateVar from "./validation/validateVariable";
import translateOfferStatus from "./translateOfferStatus";
import combine_states from "./combine_states";
import get_agency_type from "./getAgencyType";
import get_load_profile_synthetic_info from "./get_load_profile_synthetic_info";
import getStateTranslation from "./getStateTranslation";
import priceDiffColor from "./priceDiffColor";
import getMemoDisplay from "./getMemoDisplay";
import translateClearingStatus from "./translateClearingStatus";
import getClearingTranslatedStatus from "./getClearingTranslatedStatus";
import check_for_list_feedback from "./check_for_list_feedback";
import React from "react";
import getChargeAmountDisplay, {getChargeAmountDisplayYearly} from "./getChargeAmountDisplay";
import displayMessageType from "./displayMessageType";
import getNotificationStatus from "./getNotificationStatus";
import getMessageURL from "./getMessageURL";
import getCustomerCompany from "./getCustomerCompany";
import getBusinessTypeWithName from "./getBusinessTypeWithName";
import getClearingTypeDisplay from "./getClearingTypeDisplay";
import getDayDiff from "./getDayDiff";
import getConsumptionType from "./getConsumptionType";
import getIdentifierName from "./getIdentifierName";
import getConsumptionPointIdentifier from "./getConsumptionPointIdentifier";
import getLeadStatus from "./getLeadStatus";
import getGenderDisplay from "./getGenderDisplay";
import getExpiredInSeconds from "./getExpiredInSeconds";
import validateFloatOnChange from "./validation/validateFloatOnChange";
import validateIntOnChange from "./validation/validateIntOnChange";
import getYesOrNoDisplay from "./getYesOrNoDisplay";
import Badge from "../Displays/Badges/Badge";
import handleFormatWattlineId from "./handleFormatWattlineId";
import getTimestamp from "./getTimestamp";
import get_customer_company_name from "./get_customer_company_name";
import handleFormatQuantityTolerance from "./handleFormatQuantityTolerance";
import get_price_guarantee_type_display from "./get_price_guarantee_type";
import loop_and_push from "./loop_and_push";
import get_margins from "./get_margins";
import get_customer_type from "./get_customer_type";
import get_payment_options from "./get_payment_options";
import {emptyValue, rlmProfiles, slpProfiles} from "./global";
import is_true from "./is_true";
import translateRecommendedActionStatus from "./translateRecommendedActionStatus";
import get_reference_type_display from "./get_reference_type_display";
import get_standardised_date from "./get_standardised_date";
import getClearingStatus from "./getClearingStatus";
import getSignType from "./getSignType";
import getCancellationReasonDisplay from "./getCancellationReasonDisplay";

function callFunctionWithString(function_name, params){
    switch (function_name) {
        case "getGermanDateFormat":
            return getGermanDateFormat(...params);
        case "handleFormatDateForFrontend":
            return handleFormatDateForFrontend(...params);
        case "handleFormatPrice":
            return handleFormatPrice(...params);
        case "handleFormatNumber":
            return handleFormatNumber(...params);
        case "handleCancellationPeriod":
            return handleCancellationPeriod(...params);
        case "getConsultantFeeInEuro":
            return getConsultantFeeInEuro(...params);
        case "handleExtentionPeriod":
            return handleExtentionPeriod(...params);
        case "getMediumType":
            return getMediumType(...params);
        case "getLastType":
            return getLastType(...params);
        case "getCustomerCompany":
            return getCustomerCompany(...params);
        case "handleFormatSocket":
            return handleFormatSocket(...params);
        case "getCancellationReasonDisplay":
            return getCancellationReasonDisplay(...params);
        case "getSignType":
            return getSignType(...params);
        case "getSignTypeDisplay":
            return getSignTypeDisplay(...params);
        case "getEnergySource":
            return getEnergySource(...params);
        case "combine_strings":
            return combine_strings_from_array(params);
        case "price_rate_full_display":
            if(typeof params[0] === "string" && params[0].includes("ct / kWh")) // todo
                return params[0];
            let price_rate = handleFormatPrice(params[0], 3, "ct / kWh");
            let total_surcharge = params[2];
            if (total_surcharge === 0) total_surcharge = "0,000";
            if (is_true(params[1])) return combine_strings_from_array(["SPOT + ", total_surcharge]); // return SPOT Display + surcharge
            return price_rate; // return price rate  case "price_rate_full_display":
        case "solvency_score_full_display":
            if (params[0] === "Keine Information") return params[0];
            // return this.solvency_score_display +
            //             " (" +
            //             this.solvencyProvider +
            //             " / " +
            //             getGermanDateFormat(solvency_information.date) +
            //             ")";
            return params[2]; // return price rate
        case "replaceDotsWithCommas":
            return replace_all_dots(...params);
        case "validateInteger":
            return validateInteger(...params);
        case "getValidBasePrice":
            return getValidBasePrice(...params);
        case "getValidPriceRate":
            return getValidPriceRate(...params);
        case "getBusinessTypeWithName":
            return getBusinessTypeWithName(...params);
        case "multiply":
            return (+params[0] * params[1]);
        case "getTimestamp":
            return getTimestamp(...params);
        case "createNewDateObject":
            return new Date(params);
        case "getPaymantOptions":
            return get_payment_options(...params);
        case "getCustomerTypeDisplay":

            return get_customer_type(...params);
        case "getLabelFile":
            let medium_type = params[1];
            if (medium_type === "GAS") return "Gaskennzeichnung";
            else if (medium_type === "ELECTRICITY") return "Stromkennzeichnung";
            else return "Kennzeichnung";
        case "getLifecycleStatus":
            var status = params[0];
            if (status === 0 || status === '0') return "Offline";
            if (status === 1 || status === '1') return "Online";
            return "Ausstehend";
        case "is_contract_revoke_able":
            let revoke_able_until = params[0];
            let customer_type = params[1];
            let contract_status = params[2];
            if (!validateVar(revoke_able_until)) return false;
            return (new Date(get_standardised_date(revoke_able_until)).getTime() > new Date().getTime() && customer_type === "PRIVATE" && contract_status === "confirmed");
        case "handleFormatDate":
            return handleFormatDate(...params);
        case "validatePercentageToTwoDecimal":
            return validatePercentageToTwoDecimal(...params);
        case "getValidPercentage":
            return getValidPercentage(...params);
        case "getPaymentMethod":
            return getPaymentMethod(...params);
        case "getCustomerType":
            return getCustomerType(...params);
        case "getConsumptionType":
            return getConsumptionType(...params);
        case "getSolvencyDisplay":
            return getSolvencyDisplay(...params);
        case "handleFormatWattlineId":
            return handleFormatWattlineId(...params);
        case "handleFormatQuantityTolerance":
            return handleFormatQuantityTolerance(...params);
        case "getContractSource":
            return getContractSource(...params);
        case "getDayDiff":
            return getDayDiff(...params);
        case "is_spot":
            return is_spot(...params);
        case "getTotalSurcharge":
            return getTotalSurcharge(...params);
        case "getClearingTypeDisplay":
            return getClearingTypeDisplay(...params);
        case "getHandlingFee":
            return getHandlingFee(...params);
        case "displayMessageType":
            return displayMessageType(...params);
        case "getTimeDiff":
            return getTimeDiff(...params);
        case "translateContractStatus":
            return translateContractStatus(...params);
        case "translateClearingStatus":
            return translateClearingStatus(...params);
        case "getConsultantChargeAmount":
            return getConsultantChargeAmount(...params);
        case "getFullAddress":
            return getFullAddress(...params);
        case "get_customer_company_name":
            return get_customer_company_name(...params);
        case "get_price_guarantee_type_display":
            return get_price_guarantee_type_display(...params);
        case "getSwitchingStateBtn":
            return getSwitchingStateBtn(...params);
        case "getDisplaySwitchingStatus":
            return getDisplaySwitchingStatus(...params);
        case "checkStatusCp":
            return checkStatusCp(...params);
        case "getDisplayConsumption":
            return getDisplayConsumption(...params);
        case "getBusinessTypeDisplay":
            return getBusinessTypeDisplay(...params);
        case "getMarketIdentifier":
            return getMarketIdentifier(...params);
        case "getConsumptionOffsetDisplay":
            return getConsumptionOffsetDisplay(...params);
        case "getPeakDisplay":
            return getPeakDisplay(...params);
        case "getSpecificationDisplay":
            return getSpecificationDisplay(...params);
        case "getSwitchingDate":
            return getSwitchingDate(...params);
        case "getNumericValue":
            return getNumericValue(...params);
        case "getCancellationDisplay":
            return getCancellationDisplay(...params);
        case "getHabitationStatusDisplay":
            return getHabitationStatusDisplay(...params);
        case "checkIfHasPreProvider":
            return checkIfHasPreProvider(...params);
        case "hasInvoiceAddress":
            return hasInvoiceAddress(...params);
        case "getIdentifierName":
            return getIdentifierName(...params);
        case "getConsumptionPointIdentifier":
            return getConsumptionPointIdentifier(...params);
        case "getDefaultDateObject":
            return getDefaultDateObject(...params);
        case "getBadgeColor":
            return getBadgeColor(...params);
        case "getContractStatus":
            return getContractStatus(...params);
        case "getClearingTranslatedStatus":
            return getClearingTranslatedStatus(...params);
        case "getClearingStatus":
            return getClearingStatus(...params);
        case "fill_value_if_not_existing":
            return fill_value_if_not_existing(...params);
        case "getFirstElementFromArray":
            return getFirstElementFromArray(...params);
        case "callComponentWithString":
            return callComponentWithString(...params);
        case "get_time_stamp":
            return +new Date(...params);
        case "translateOfferStatus":
            return translateOfferStatus(...params);
        case "getNotificationStatus":
            return getNotificationStatus(...params);
        case "getMessageUrl":
            return getMessageURL(...params);
        case "getExpiredInSeconds":
            return getExpiredInSeconds(...params);
        case "shortenString":
            let string = params[0];
            if (validateVar(string)) {
                if (string.length > params[2]) {
                    let from = params[1];
                    let to = params[2];
                    return string.slice(from, to) + '...';
                } else return string;
            } else return string;
        case "combine_states":
            return combine_states(...params);
        case "get_agency_type":
            return get_agency_type(...params);
        case "get_load_profile_synthetic_info":
            return get_load_profile_synthetic_info(...params);
        case "getStateTranslation": // todo country
            return getStateTranslation(...params);
        case "priceDiffColor":
            return priceDiffColor(...params);
        case "getMemoDisplay":
            return getMemoDisplay(...params);
        case "check_for_list_feedback":
            return check_for_list_feedback(...params);
        case "getLeadStatus":
            return getLeadStatus(...params);
        case "getGenderDisplay":
            return getGenderDisplay(...params);
        case "getChargeAmountDisplay":
            return getChargeAmountDisplay(...params);
        case "getChargeAmountDisplayYearly": {
            return getChargeAmountDisplayYearly(...params);
        }
        case "loop_and_push": {
            return loop_and_push(...params);
        }
        case "get_array_count": {
            let array = params[0];
            return (validateVar(array) && Array.isArray(array)) ? array.length : 0;
        }
        case "get_margins": {
            return get_margins(...params);
        }
        case "validateFloatOnChange": {
            return validateFloatOnChange(...params);
        }
        case "validateIntOnChange": {
            return validateIntOnChange(...params);
        }
        case "get_reference_type_display": {
            return get_reference_type_display(...params);
        }
        case "getExpiresDisplay": {
            let expires = params[0];
            if (expires === 'expired') return <Badge value='Abgelaufen' color='red'/>;
            else return expires + " Std";
        }
        case "getSyntheticDisplay": {
            return (params[0] + "" === "1") ? "Nicht vorhanden" : "Vorhanden"
        }
        case "get_switching_date": {
            let status = params[1];
            return (status === "successful") ? getDefaultDateObject(...params) : "";
        }
        case "get_switching_date_display": {
            let status = params[1];
            let switching_date = params[0];
            return (validateVar(status) && status !== "open" && status !== "error" && status !== "pending") ? handleFormatDateForFrontend(params[0]) : emptyValue;
        }
        case "translateRecommendedActionStatus": {
            return translateRecommendedActionStatus(...params);
        }
        case "getYesOrNoDisplay": {
            return getYesOrNoDisplay(...params);
        }
        case "get_url_test": {
            return "";
        }
        default:
            return alert("Diese Function muss noch importiert werden: " + function_name);
    }
}

export default callFunctionWithString;