import Communication from "./Components/Communication/communication";
import { apiError, apiPlatformService } from "../Functions/global";
import { deHashVar } from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));

class Consultant {
  constructor(data) {
    //basic
    this.value = "";
    this.label = "";
    this.accepted = 0;

    //load basic info
    if (validateVar(data) && validateVar(data.consultant_id)) {
      //if data found
      this.value = !validateVar(data.consultant_id)
        ? ""
        : data.consultant_id + "";
      this.accepted = !validateVar(data.accepted) ? "" : data.accepted + "";
      this.label = !validateVar(data.consultant) ? "" : data.consultant;
    }
  }

  /*
              Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
              Call Communication Class with matching global defined Vars for API Request
              return Array with all TariffCalculatedContainer Obj
         */
  static async getAllConsultants() {
    // this.c = new Communication(
    //   apiPlatformService,
    //   "list_partners",
    //   "",
    //   "",
    //   "",
    //   "",
    //   { supplier_id: supplier_id }
    // );

    let params = getAgencyID({});
      let communication_params = {
          service: apiPlatformService,
          get_function: "list_partners",
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);
      var res = await c.load();
      if (res.status === "ok") {
      let consultants = res.result;
      let listResults = [];
      for (let consultant in consultants) {
        if (
          consultants.hasOwnProperty(consultant) &&
          validateVar(consultants[consultant].consultant)
        ) {
          let name = consultants[consultant].consultant.toLowerCase();
          if (
            validateVar(name) // filter names and check if blacklisted
          ) {
            listResults.push(new Consultant(consultants[consultant]));
          }
        }
      }
      return listResults;
    } else return apiError;
  }

  static async update_partners(params) {
    params = getAgencyID(params);

    // this.c = new Communication(
    //   apiPlatformService,
    //   "list_partners",
    //   "update_partners",
    //   "",
    //   "",
    //   "",
    //   {}
    // );

      let communication_params = {
          service: apiPlatformService,
          get_function: "list_partners",
          update_function: "update_partners",
          delete_function: "",
          add_function: "",
          download_function: "",
      };
      let c = getCommunicationClass(communication_params);
      let res = await c.update(params);
      if (res.status === "ok") {
      if (res.result === "[]") return [];
      let consultant_list = [];
      res.result.map(result => {
        if (validateVar(result)) {
          consultant_list.push(new Consultant(result));
        }
      });
      return consultant_list;
    } else return apiError;
  }
}

export default Consultant;
