import { apiFunctionGetPfc } from "../Functions/global";
import validateVar from "../Functions/validation/validateVariable";

let initialState = null;
function pfc(state = initialState, action) {
  if (action.type === "INITIALIZE_PFC") {
    return action.res;
  } else if (action.type === "DELETE_PFC") {
    let newState = [];
    let pfc_ids = action.ids; // deleted employee ids
    for (let pfc of state) {
      // loop through current state
      if (!pfc_ids.includes(pfc.id)) {
        // check if delted ids are matchting current employee id
        newState.push(pfc); // only push not deleted employees into the new state
      }
    }
    return newState; // return updated state
  } else if (action.type === "DOWNLOAD_PFC") {
    let download_ids_array = action.ids;
    for (let pfc of state) {
      if (download_ids_array.includes(pfc.id)) {
        pfc
          .download(apiFunctionGetPfc, { id: pfc.id, data_type: "download" })
          .then(res => {
            if (res !== true) alert("Fehler: " + res.result);
          });
      }
    }
    return state;
  } else if (action.type === "DELETE_PFCS_FROM_LIST") {
    let newState = [];
    let pfc_ids = action.ids;
    for (let pfc of state) {
      if (!pfc_ids.includes(pfc.id)) newState.push(pfc);
    }
    return newState;
  } else if (action.type === "ADD_PFC") {
    let newState = [];
    newState.push(action.res);
    for (let pfc of state) {
      newState.push(pfc);
    }
    return newState;
  } else if (action.type === "DOWNLOAD_GLOBAL_HPFC") {
    return state;
  } else if (action.type === "UPDATE_PFC") {
    let newState = [...state];
    let submitted_params = action.res;
    let updatePfcIndex = newState.findIndex(
      x => x.id === submitted_params.pfc_id
    );
    if (validateVar(updatePfcIndex) && validateVar(newState[updatePfcIndex]))
      newState[updatePfcIndex].is_default = submitted_params.active;
    else alert("Beim erneutem Laden der PFC ist ein Fehler augetreten");
    return newState;
  } else {
    return state;
  }
}

export default pfc;
