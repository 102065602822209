import validateVar from "../Functions/validation/validateVariable";
import History from "./Components/history";
import callClassWithString from "../Functions/callClassWithString";

let price_vars = {
    is_sub_arr: "price",
    has_history: true,
    translation_object: {
        socket_rate_formated: "Grundpreis (Netto)",
        socket_gross_rate_formated: "Grundpreis (Brutto)",
        price_rate: "Energiepreis",
        price_rate_display: "Energiepreis",
        price_rate_full_display: "Energiepreis",
        contract_price_net_full_display: "Arbeitspreis (Netto)",
        contract_price_gross_display: "Arbeitspreis",

        socket_display: "Grundpreis",
        yearly_price_display: "Kosten / Jahr",
        yearly_contract_price_net_display: "Kosten / Jahr",
        term_price_net_display: "Kosten / Laufzeit",
        yearly_saving_net_display: "Kosten / Jahr",
        term_saving_net_display: "Ersparnis / Laufzeit",
        consultant_charge_display_euro: "Marge / Jahr",
        consultant_charge_amount_display: "Marge / Verbrauch",
    },
    consultant_charge: {
        is_sub_arr: "consultant_charge",
        consultant_charge: null,
        unit: {
            consultant_charge_unit: null,
        },
        amount: {
            consultant_charge_amount_plain: null,
            consultant_charge_amount: {
                function_name: "getConsultantChargeAmount",
                params: ["%%consultant_charge_unit", "%%consumption"]
            },
            consultant_charge_amount_display: {
                function_name: "getChargeAmountDisplay",
                params: ["%%consultant_charge_unit"]
            },
            consultant_charge_display_euro: {
                function_name: "getConsultantFeeInEuro",
                params: ["%%consumption"]
            },
            consultant_charge_amount_display_yearly: {
                function_name: "getChargeAmountDisplayYearly",
                params: ["%%consultant_charge_unit"]
            }
        },
        unit2: {
            consultant_charge_unit2: null,
        },
        amount2: {
            consultant_charge_amount2_plain: null,
            consultant_charge_amount2_display: {
                function_name: "getChargeAmountDisplay",
                params: ["EUR_PER_YEAR"]
            }
        },
        summarized_provision_consultant: {
            function_name: "combine_strings",
            params: ["%%consultant_charge_amount_display", " + ", "%%consultant_charge_amount2_display"]
        },
    },
    is_spot: null,
    consumption: null,
    term_saving_net: {
        term_saving_net: null,
        term_saving_net_display: {
            function_name: "handleFormatPrice",
            params: ["2", "€"]
        }
    },
    yearly_saving_net: {
        yearly_saving_net: null,
        yearly_saving_net_display: {
            function_name: "handleFormatPrice",
            params: ["2", "€"]
        }
    },
    term_price_net: {
        term_price_net: null,
        term_price_net_display: {
            function_name: "handleFormatPrice",
            params: ["2", "€"]
        }
    },
    agency_fee: {
        is_sub_arr: "agency_fee",
        unit: {
            agency_fee_unit: null,
        },
        amount: {
            agency_fee_amount: null,
            agency_fee_display: {
                function_name: "getChargeAmountDisplay",
                params: ["%%eless_fee_unit"]
            }
        },
    },
    eless_fee: {
        is_sub_arr: "eless_fee",
        unit: {
            eless_fee_unit: null,
        },
        amount: {
            eless_fee_amount: null,
            eless_fee_display: {
                function_name: "getChargeAmountDisplay",
                params: ["%%eless_fee_unit"]
            }
        },
    },
    transaction_fee: {
        is_sub_arr: "transaction_fee",
        unit: {
            transaction_fee_unit: null,
        },
        amount: {
            transaction_fee_amount: null,
            transaction_fee_display: {
                function_name: "getChargeAmountDisplay",
                params: ["%%eless_fee_unit"]
            }
        },
    },
    supplier_fee: {
        is_sub_arr: "supplier_fee",
        rate: {
            supplier_fee_rate: null,
            supplier_fee_rate_display: {
                function_name: "handleFormatPrice",
                params: ["3", "ct/kWh"]
            }
        },
        socket: {
            supplier_socket: null,
            supplier_socket_display: {
                function_name: "handleFormatPrice",
                params: ["2", "€/Monat"] //KZ / 07.03.2022 / EUR per month is always displayed with 2 decimals (usecase in evulogin/angebote/{id} @Provision
            }
        }
    },
    summarized_supplier_fee: {
        function_name: 'combine_strings',
        params: ['%%supplier_fee_rate_display', ' + ', '%%supplier_socket_display']
    },
    total_surcharge: {
        function_name: "getTotalSurcharge",
        params: [
            "%%consultant_charge_amount",
            "%%consultant_charge_unit",
            "%%consultant_charge_amount2_plain",
            "%%consultant_charge_unit2",
            "%%eless_fee_amount",
            "%%eless_fee_unit",
            "%%supplier_fee_rate",
            "%%agency_fee_amount",
            "%%agency_fee_unit",
            "%%transaction_fee_amount",
            "%%transaction_fee_unit",
        ]
    },
    total_surcharge_display: {
        function_name: "handleFormatPrice",
        params: ["%%total_surcharge", "3", "ct/kWh"]
    },
    total_surcharge_without_supplier: {
        function_name: "getTotalSurcharge",
        params: [
            "%%consultant_charge_amount",
            "%%consultant_charge_unit",
            "%%consultant_charge_amount2_plain",
            "%%consultant_charge_unit2",
            "%%eless_fee_amount",
            "%%eless_fee_unit",
            0,
            "%%agency_fee_amount",
            "%%agency_fee_unit",
            "%%transaction_fee_amount",
            "%%transaction_fee_unit",
        ]
    },
    total_socket_surcharge: {
        function_name: "getTotalSurcharge",
        params: [
            "%%consultant_charge_amount_plain",
            "%%consultant_charge_unit",
            "%%consultant_charge_amount2_plain",
            "%%consultant_charge_unit2",
            "%%eless_fee_amount",
            "%%eless_fee_unit",
            0,
            "%%agency_fee_amount",
            "%%agency_fee_unit",
            "%%transaction_fee_amount",
            "%%transaction_fee_unit",
            "EUR_PER_MONTH"
        ]
    },
    total_socket_surcharge_yearly: {
        function_name: "multiply",
        params: ["%%total_socket_surcharge", 12]
    },
    total_socket_surcharge_yearly_display: {
        function_name: "handleFormatPrice",
        params: ["%%total_socket_surcharge_yearly", "2", "€/Jahr"]
    },
    total_surcharge_without_supplier_display: {
        function_name: "handleFormatPrice",
        params: ["%%total_surcharge_without_supplier", "3", "ct/kWh"]
    },
    summarized_provision_supplier: {
        function_name: "combine_strings",
        params: ["%%total_surcharge_without_supplier_display", " + ", "%%total_socket_surcharge_yearly_display"]
    },
    handling_fee: {
        function_name: "getHandlingFee",
        params: ["%%consultant_charge_amount", "%%eless_fee"]
    },
    handling_fee_display: {
        function_name: "handleFormatPrice",
        params: ["%%handling_fee", 3, "ct / kWh"]
    },
    price_rate: {
        price_rate: null,
        price_rate_plain: {
            function_name: "handleFormatPrice",
            params: ["3", ""]
        },
        price_rate_offer: null,
        price_rate_full_display: {
            function_name: "price_rate_full_display",
            params: ["%%is_spot", "%%total_surcharge_display"],
            reference: "price_rate"
        },
        price_rate_formated: {
            function_name: "handleFormatPrice",
            params: ["3", "ct / kWh"]
        },
        price_rate_display: {
            function_name: "handleFormatPrice",
            params: ["3", "ct / kWh"],
            reference: "price_rate"
        }
    },
    contract_price_gross: {
        contract_price_gross: null,
        contract_price_gross_display: {
            function_name: "handleFormatPrice",
            params: [3, "ct / kWh"]
        },
        contract_price_gross_full_display: {
            function_name: "price_rate_full_display",
            params: ["%%is_spot", "%%total_surcharge"],
            reference: "contract_price_gross"
        },
    },

    contract_price_net: {
        contract_price_net: null,
        contract_price_net_plain: {
            function_name: "handleFormatPrice",
            params: [3, ""]
        },
        contract_price_net_display: {
            function_name: "handleFormatPrice",
            params: [3, "ct / kWh"]
        },
        contract_price_net_full_display: {
            function_name: "price_rate_full_display",
            params: ["%%is_spot", "%%total_surcharge"],
            reference: "contract_price_net"
        },
    },
    yearly_socket_net: {
        yearly_socket_net: null,
        yearly_socket_net_plain: {
            function_name: "handleFormatPrice",
            params: [2]
        },
        yearly_socket_net_display: {
            function_name: "handleFormatPrice",
            params: [2, "€ / Jahr"]
        },
    },
    yearly_socket_gross: {
        yearly_socket_gross: null,
        yearly_socket_gross_display: {
            function_name: "handleFormatPrice",
            params: [2, "€ / Jahr"]
        }
    },
    consumption_point_id: null,
    market_price: null,
    socket_net: {
        socket: null,
        socket_net: null,
        price_socket: null,
        socket_plain: {
            function_name: "handleFormatPrice",
            params: [2, ""]
        },
        socket_rate_formated: {
            function_name: "handleFormatSocket"
        },
        socket_display: {
            function_name: "handleFormatSocket",
        },
        socket_display_plain: {
            function_name: "handleFormatPrice",
            params: ["2", "€ / Monat"]
        },
        socket_net_yearly_display: {

        }
    },
    socket_gross: {
        socket_gross: null,
        socket_gross_rate_formated: {
            function_name: "handleFormatSocket"
        },
        socket_gross_display: {
            function_name: "handleFormatSocket",
        },
        socket_gross_display_plain: {
            function_name: "handleFormatPrice",
            params: ["2", "€ / Monat"]
        }
    },
    summarized_total_surcharge: {
        function_name: "combine_strings",
        params: ["%%total_surcharge_display", " + ", "%%socket_display_plain"]
    },
    yearly_contract_price_gross: {
        yearly_contract_price_gross: null,
        yearly_contract_price_gross_display: {
            function_name: "handleFormatPrice",
            params: [2, "€"]
        }
    },
    yearly_contract_price_net: {
        yearly_contract_price_net: null,
        yearly_contract_price_net_display: {
            function_name: "handleFormatPrice",
            params: [2, "€"]
        }
    },
    yearly_price_net: {
        yearly_price: null,
        price_yearly: null,
        yearly_price_display: {
            function_name: "handleFormatPrice",
            params: [2, "€"]
        }
    },
    yearly_price_gross: {
        yearly_price_gross: null,
        price_yearly_gross: null,
        yearly_price_gross_display: {
            function_name: "handleFormatPrice",
            params: [2, "€"]
        }
    },
    yearly_price_rate: {
        yearly_price_rate: null,
        yearly_price_rate_display: {
            function_name: "handleFormatPrice",
            params: [2, "€"] //removed "/ Jahr" for enermakler Tariff display reasons
        }
    },
    supplier_margin: null,
    taxes: {
        is_sub_arr: "taxes",
        sales_tax: {
            is_sub_arr: "sales_tax",
            yearly_amount: {
                sales_tax_yearly_amount: null,
                sales_tax_yearly_amount_display: {
                    function_name: "handleFormatPrice",
                    params: [2, "€/Jahr"]
                }
            },
            socket_amount: {
                sales_tax_socket_amount: null
            },
            rate_amount: {
                sales_tax_rate_amount: null,
            },
            rate: {
                sales_tax_rate: null,
                sales_tax_rate_display: {
                    function_name: "multiply",
                    params: [100]
                }
            }
        },
        //Wattline recommended object props
        avg_consultant_charge: {
            avg_consultant_charge: null,
            avg_consultant_charge_display: {
                function_name: "handleFormatPrice",
                params: [2, "ct/kWh"]
            },
        },
        term_consultant_charge: {
            term_consultant_charge: null,
            term_consultant_charge_display: {
                function_name: "handleFormatPrice",
                params: [2, "€"]
            },
        },
    },
    avg_price_rate: {
        avg_price_rate: null,
        avg_price_rate_display:   {
            function_name: "handleFormatPrice",
            params: [2, "ct/kWh"]
        },
    },
    avg_socket_net: {
        avg_socket_net: null,
        avg_socket_net_display: {
            function_name: "handleFormatPrice",
            params: [2, "€/Monat"]
        },
    },
};

class Price {
    static default_values = price_vars;


    constructor(data, status = "open") {
        if (validateVar(data)) {
            let class_content = new History(price_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static dummy() {
        let output = {};
        output = this.build_dummy(price_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    }else if (validateVar(vars[index].is_class_arr)) {

                        let class_name = callClassWithString(index);
                        output[index] = class_name.constructor.dummy();
                    }else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default Price;
