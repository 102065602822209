import React from 'react';
// react plugin used to create charts
import {Line} from "react-chartjs-2";
import {Card, CardHeader, CardBody, Row, Col} from "reactstrap";
import InputHandler from "../../../module_components/Inputs/InputHandler";

class LineGraph extends React.Component {

    state = {
        medium_type: 'ELECTRICITY',
        customer_type: 'B2B',
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {medium_type, customer_type} = this.state;
        //Only update component if any of those states changed to prevent multiple rerenders with weird looking initial animation on each render
        // (component is rendered first time when chart_data is set)
        return nextState.medium_type !== medium_type || nextState.customer_type !== customer_type;
    }

    getAnalysisData = () => {
        const {chart_data} = this.props;
        const {medium_type, customer_type} = this.state;

        return {
            labels: chart_data.months, //create last 12 month names array
            datasets: [
                {
                    label: "Abschlüsse",
                    data: chart_data[medium_type + '_' + customer_type], //create last 12 months contract completions
                    fill: false,
                    borderColor: "#fe821e"
                }
            ]
        };
    };

    render() {
        const {is_loaded_chart_data} = this.props;
        const {medium_type, customer_type} = this.state;
        let analyticsData = {};

        if (is_loaded_chart_data) analyticsData = this.getAnalysisData();

        return <Card className="bg-neutral">
            <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                    <Col sm={12} md={6}>
                        <h2 className="mb-1">
                            Auftragseingang
                        </h2>
                    </Col>
                    <Col sm={12} md={6}>
                        <Row>
                            <Col />
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col><InputHandler type='select'
                                                       id='medium_type'
                                                       name='medium_type'
                                                       value={medium_type}
                                                       on_change={(name, value) => this.setState({[name]: value})}
                                                       select_options={[{value: 'Strom', key: 'ELECTRICITY'}, {value: 'Gas', key: 'GAS'}]}
                                                       classes='energy-source-select line-graph-dropdown'
                                                       remove_empty_option
                                                       no_margin
                                                       no_padding
                                    /></Col>
                                    <Col><InputHandler type='select'
                                                       id='customer_type'
                                                       name='customer_type'
                                                       value={customer_type}
                                                       on_change={(name, value) => this.setState({[name]: value})}
                                                       select_options={[{value: 'Gewerbe', key: 'B2B'}, {value: 'Privat', key: 'B2C'}]}
                                                       classes='energy-source-select line-graph-dropdown'
                                                       remove_empty_option
                                                       no_margin
                                                       no_padding
                                    /></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className='dashboard-line-chart'>
                <Line redraw data={analyticsData} legend={{display: false}} options={{
                    responsive: true, maintainAspectRatio: false, borderDash: [5], scales: {
                        yAxes: [{
                            girdLines: {},
                            ticks: {
                                beginAtZero: true,
                                min: 0
                            }
                        }],
                        xAxes: [{
                            gridLines: {drawOnChartArea: false},
                        }]
                    }
                }}/>
            </CardBody>
        </Card>
    }
}

export default LineGraph;