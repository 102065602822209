import validateVar from "../Functions/validation/validateVariable";
import getDisplayConsumption from "../Functions/getDisplayConsumption";

let initialState = {};

function recommended_action(state = initialState, action) {
  if (action.type === "GET_RECOMMENDED_ACTION") {
    return action.res;
  } else if (action.type === "GET_RECOMMENDED_ACTION_FOR_OFFER") {
    return action.res;
  } else if (action.type === "GET_RECOMMENDED_ACTION_PACKET") {
    let new_state = action.res;
    let recommended_actions = action.res.recommended_actions;
    if(Array.isArray(recommended_actions) && recommended_actions.length > 0){
      let standard_data = {};
      standard_data.total_consumption = 0;
      standard_data.total_consumption_points_number = 0;
      standard_data.recommended_actions_count = recommended_actions.length;
      standard_data.medium_type_display = recommended_actions[0].medium_type_display;

      for(let recommended_action of recommended_actions){
        if(Array.isArray(recommended_action.consumption_points)){
          standard_data.total_consumption_points_number = standard_data.total_consumption_points_number + recommended_action.consumption_points.length;
        }

        if(validateVar(recommended_action.consumption)){
          standard_data.total_consumption = standard_data.total_consumption + recommended_action.consumption;
        }
      }

      standard_data.total_consumption_display = getDisplayConsumption(standard_data.total_consumption);
      new_state.standard_data = standard_data;
    }

    return new_state;
  } if (action.type === "RESET_URL") {
    return initialState;
  } else {
    return state;
  }
}

export default recommended_action;
