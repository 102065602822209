import React from 'react';
import {Row, Col, Card, CardBody, CardHeader, Modal, Collapse} from "reactstrap";
import PrimaryButton from "../../../Displays/Buttons/PrimaryButton";
import validateVar from "../../../Functions/validation/validateVariable";
import InputHandler from "../../../Inputs/InputHandler";
import Validation from "../../../Objects/Components/validation";
import {electricityVoltageLevel, gasVoltageLevel, private_profiles} from "../../../Functions/global";
import handleConsumptionPointFormChange from "../../../Functions/handleConsumptionPointFormChange";
import dataToSelectField from "../../../Functions/dataToSelectField";

class ConsumptionProfileModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            contract: null,
            error_messages: {},
            object_data: {},
            is_loaded: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {object_data} = this.props;
        const {is_loaded} = this.state; //is_loaded prevents object_data from beeing updated from props twice

        if(validateVar(object_data) && object_data !== prevProps.object_data && !is_loaded){
            this.setState({object_data: object_data, is_loaded: true});
        }
    }

    handle_form_state_on_laod = (object_data) => {
        //set SLP/RLM to Disabled/Enabled & keep the business_type selected on load of the edit form
        let object_data_ = {...object_data};
        if(validateVar(object_data.consumption_input_value)) object_data_ = handleConsumptionPointFormChange(object_data_, object_data_.consumption_input_value, 'consumption_input_value');
        if(validateVar(object_data.business_type)) object_data_.business_type = object_data.business_type;
        return object_data_;
    };

    toggleModal = () => {
        const {object_data} = this.props;
        let object_data_ = this.handle_form_state_on_laod(object_data);
        this.setState({loading: false,
            showModal: !this.state.showModal,
            object_data: object_data_,
        })
    };

    turnOffModal = () => {
        this.setState({loading: false, showModal: false});
    };

    on_change = (name, value) => {
        let {object_data} = this.state;
        if(name === 'consumption_input_value' || name === 'consumption_type') {
             let test = handleConsumptionPointFormChange(object_data, value, name);
            object_data = test;
        }
        else object_data[name] = value;
        this.setState({object_data: object_data})
    };

    submit = () => {
        let {object_data} = this.state;

        let validation_data = {
            consumption_input_value: object_data.consumption_input_value,
            consumption_type: object_data.consumption_type,
            business_type: object_data.business_type,
        };
        if(object_data.consumption_type === 'RLM'){
            validation_data.specification = object_data.specification;
        }

        // validation start
        // define special cases for error validation
        let special_cases = {
            mean_consumption: {case_name: "mean_consumption"},
            business_type: {case_name: "select"},
        };

        if(object_data.consumption_type === 'RLM'){
            special_cases.specification = {case_name: "can_be_empty"};
        }

        // call abstract error validation and set values in state
        let validation_result = new Validation(validation_data, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        if(!validation_result.has_error) {

            let submit_params = {
                mean_consumption: {value: validation_data.consumption_input_value, api_name: 'mean_consumption', api_sub_structure: ["extended_information"]},
                consumption_type: {value: validation_data.consumption_type, api_name: 'consumption_type'},
                business_type: {value: validation_data.business_type, api_name: 'business_type'},
            };

            if(object_data.consumption_type === 'RLM') {
                submit_params.specification = {
                    value: validation_data.specification,
                    api_name: 'specification', api_sub_structure: ["extended_information"]
                };
            }

            this.props.onSubmit(submit_params, "consumption_point");
            this.setState({loading: true, is_loaded: false});
        }
    };

    render(){
        let {default_load_profiles, is_loaded} = this.props;
        let {loading, showModal, error_messages, object_data} = this.state;

        let specification_title = 'Spannungsebene';
        let specification_options = electricityVoltageLevel;
        if (object_data.medium_type === 'GAS') {
            specification_title = 'Druckstufe';
            specification_options = gasVoltageLevel;
        }

        let default_load_profile_options = [];
        let load_profiles_disabled = false;
        if(validateVar(default_load_profiles) && is_loaded){
            if(object_data.consumption_type === 'SLP') {
                if(Object.keys(private_profiles).includes(object_data.business_type)) {
                    default_load_profile_options = dataToSelectField(null, null, private_profiles, null, true, true);
                    load_profiles_disabled = true;
                }else default_load_profile_options = default_load_profiles[object_data.medium_type].SLP;
            }
            if(object_data.consumption_type === 'RLM') default_load_profile_options = default_load_profiles[object_data.medium_type].RLM;
        }

        return(
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={showModal}
                toggle={() => this.toggleModal()}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent modal-title">
                            <Row>
                                <Col xs={10}>
                                    <h2 className={'mb-0'}>Verbrauchsprofil</h2>
                                </Col>
                                <Col xs={2}>
                                    <button onClick={() => this.toggleModal()} className={'close'} ><i className={'ni ni-fat-remove close-button'} /></button>
                                </Col>
                            </Row>
                        </CardHeader>
                         <CardBody className="px-lg-4 py-lg-4">
                                 <InputHandler type='text'
                                               content_title='Jahresverbrauch'
                                               name='consumption_input_value'
                                               disabled={loading}
                                               value={object_data.consumption_input_value}
                                               on_change={this.on_change}
                                               error_state={error_messages.consumption_input_value_error}
                                               input_group_text={'kWh'}
                                               function_name={'handleFormatNumber'}
                                 />
                                <InputHandler type='radio'
                                              content_title='Zählertyp'
                                              name='consumption_type'
                                              value={object_data.consumption_type}
                                              first_radio={{ id: "slp", text: "SLP", value: "SLP" , disabled: object_data.disable_slp }}
                                              second_radio={{ id: "rlm", text: "RLM", value: "RLM", disabled: object_data.disable_rlm }}
                                              on_change={this.on_change} />

                             <InputHandler type='select'
                                           name='business_type'
                                           select_options={default_load_profile_options}
                                           disabled={loading || load_profiles_disabled}
                                           content_title='Branche'
                                           value={object_data.business_type}
                                           on_change={this.on_change}
                                           error_state={error_messages.business_type_error} />

                                <Collapse isOpen={object_data.consumption_type === 'RLM'}>
                                    <div className='modal-content-end-margin'>
                                        <InputHandler type='select'
                                                      name='specification'
                                                      select_options={specification_options}
                                                      disabled={loading}
                                                      content_title={specification_title}
                                                      value={object_data.specification}
                                                      on_change={this.on_change}
                                                      error_state={error_messages.specification_error}
                                                      remove_empty_option
                                        />
                                    </div>
                                </Collapse>
                             <div className="text-center">
                                <PrimaryButton loading={loading} disabled={loading} onClick={() => this.submit()} value='Änderungen bestätigen' classes='btn-modal full-width-button' />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        );
    }
}

export default ConsumptionProfileModal;



