import validateVar from "./validation/validateVariable";
import {guestUrls} from "../../globalNavigation";


function checkGuestUrl() {
  let path = window.location.pathname;
  let check = false;
  for (let url in guestUrls) {
    if (guestUrls.hasOwnProperty(url)) {
      let check_for_url = guestUrls[url];
      if(check_for_url.includes('/:')) check_for_url = check_for_url.split('/:')[0]; //In case the url contains parameters
      let checkUrl = path.substr(0, check_for_url.length);
      if (checkUrl === check_for_url) {
          check = true;
      }
    }
  }
  if(check && validateVar(localStorage.getItem("evuUserAccount"))){//if current page is a guest url and if role of the user is still set
      localStorage.removeItem("evuSecurityToken");//remove all user token
      localStorage.removeItem("evuUserAccount");
      localStorage.removeItem("employeeSecurityToken");
      localStorage.removeItem("show_banner");
      window.location.reload();//and reload page
  }
  if(check && validateVar(localStorage.getItem("consultantUserAccount"))){//if current page is a guest url and if role of the user is still set
      localStorage.removeItem("consultantSecurityToken");//remove all user token
      localStorage.removeItem("consultantUserAccount");
      localStorage.removeItem("consultantEmployeeSecurityToken");
      localStorage.removeItem("show_banner");
      window.location.reload();//and reload page
  }
  return check;
}

export default checkGuestUrl;
