import "whatwg-fetch";
import  {
    apiBearer,
    apiCalculationManagementService,
    apiContentType, apiFunctionGetTariff,
    apiURL,
    apiUser,
    apiUserAuthToken
} from "./global";

export default async function getTariff(supplierId, tariffId) {
  return await fetch(apiURL, {
    method: "POST",
    headers: {
      Authorization: apiBearer,
      "Content-Type": apiContentType
    },
    http_errors: false,
    body: JSON.stringify({
      user: apiUser,
      auth_token: apiUserAuthToken,
      service: apiCalculationManagementService,
      function: apiFunctionGetTariff,
      param: {
        supplier_id: supplierId,
        tariff_id: tariffId
      }
    })
  })
    .then(response => {
      return response;
    })
    .catch(rejected => {
      console.log("Request failed", rejected);
    });
}
