/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import SimpleHeader from "../Displays/Headers/SimpleHeader.jsx";

class PermissionDenied extends React.Component {
    state = {
        sidenavOpen: true,
    };

    render() {
        return (
                <div
                    className="main-content"
                    ref="mainContent"
                >
                    <SimpleHeader name="Seite nicht gefunden" headerText="Die angegebene URL wurde nicht gefunden oder Ihnen fehlt die Berechtigung für den Zugriff zu dieser Seite. Überprüfen Sie bitte die Eingabe oder kontaktieren Sie Ihren Administrator, um die Nutzerrechte zu verwalten." />
                </div>
        );
    }
}

export default PermissionDenied;
