import React from 'react';

class LoadingSpinner extends React.Component {
    render(){
        const {classes} = this.props;

        return(<div className={"fa-1x d-block "+ classes}>
                <i className="fas fa-circle-notch fa-spin" />
        </div>);
    }
}

export default LoadingSpinner;