let initialState = null;

function team_list(state = initialState, action) {
    if (action.type === "INITIALIZE_TEAMS_LIST") {
        return action.res;
    } else if (action.type === "RESET_FILE") {
        return action.res;
    }else if (action.type === "INITIALIZE_TEAMS_UPDATED_LIST") {
        let old_state = {...state};
        let new_state = [];
        for (let index in state) {

        }

        return action.res;
    }
    else return state;
}

export default team_list;
