import callFunctionWithString from "../Functions/callFunctionWithString";

let initialState = null;
function offer(state = initialState, action) {
  if (action.type === "INIT_OFFER_LIST") {
    return callFunctionWithString("combine_states", [
      action.res,
      state,
      "offer"
    ]);
  } else if (action.type === "OFFER_CREATED") {
    return action.res;
  }else if(action.type === "UPDATE_OFFER") {
    return action.res;
  }else if(action.type === "OFFER_FOR_CUSTOMER") {
    return action.res;
  }
  else if (action.type === "OFFER_BY_TOKEN") {
    return action.res;
  }else if (action.type === "APPEND_TO_OFFER") {
    let tmp_state = (state === null) ? [] : state;
    tmp_state = [...tmp_state];
      tmp_state.push(action.res);
      return tmp_state;
  } else if (action.type === "OFFER_RESET") {
    return action.res;
  }
  else if (action.type === "DELETE_SAVED_OFFERS"){
    let newState = [];
    let selected_ids = action.ids;

    for (let offer of state) {
      if (!selected_ids.includes(offer.memo_id)) {
        newState.push(offer);
      }
    }
    return newState;
  } else if (action.type === "INIT_OFFER") {
    return action.res;
  } else {
    return state;
  }
}

export default offer;
