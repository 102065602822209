import validateVar from "../Functions/validation/validateVariable";
import {
    apiCalculationManagementService, apiError, apiFunctionCreateBankAccount,
    apiFunctionGetBankAccount,
    apiFunctionUpadteBankAccount
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import History from "./Components/history";
import callClassWithString from "../Functions/callClassWithString";


let bank_account_vars = {
    is_sub_arr: "bank_account",
    translation_object: {
        bic: "BIC",
        iban: "IBAN",
        bank_name: "Kreditinstitut ",
        name_on_account: "Kontoinhaber"
    },
    has_history: true,
    id: {
        id: null,
        bank_accout_id: null,
    },
    iban: {
        default_value: "Keine IBAN hinterlegt",
    },
    last4: null,
    bic: {
        default_value: "Kein BIC hinterlegt"
    },
    bank_name: {
        default_value: "Kein Kreditinstitut hinterlegt"
    },
    name_on_account: {
        owner: "customer_information",
        default_value: "Kein Kontoinhaber hinterlegt"
    },
    first_name: {
        bank_first_name: {
            default_value: "-"
        }
    },
    last_name: {
        bank_last_name: {
            default_value: "-"
        }
    },

};

class BankAccount {
    static default_values = bank_account_vars;
    constructor(data, status = "open") {
        if (validateVar(data)) {
            if (validateVar(data)) {
                this.history = {};
                let class_content = new History(bank_account_vars, data, data.history, [], status, true);
                for (let name in class_content) {
                    if (class_content.hasOwnProperty(name)) {
                        this[name] = class_content[name];
                    }
                }
            }
        }
    }

    /*
          Instantiate a new Customer with its ID as Param
          Call Communication Class with matching global defined Vars for API Request
          return new Customer Clnass
       */
    static async updateBankAccount(params) {
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetBankAccount,
            update_function: apiFunctionUpadteBankAccount,
            delete_function: "",
            add_function: apiFunctionCreateBankAccount,
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);

        let res = await c.update(params);

        if (res.status === "ok") {
            if (validateVar(res.result.bank_account)) return new BankAccount(res.result.bank_account);
            else return new BankAccount(res.result);
        } else return apiError;
    }


    static async getBankAccount(id) {
        let params = {
            customer_id: id,
        };
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetBankAccount,
            update_function: apiFunctionUpadteBankAccount,
            delete_function: "",
            add_function: apiFunctionCreateBankAccount,
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);

        let res = await c.load();
        if (res.result === "[]") return null;
        if (res.status === "ok") return new BankAccount(res.result[0]);
        else return apiError;
    }


    static async createBankAccount(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetBankAccount,
            update_function: apiFunctionUpadteBankAccount,
            delete_function: "",
            add_function: apiFunctionCreateBankAccount,
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);
        let res = await c.add(params);
        if (res.status === "ok") return new BankAccount(res.result);
        else return apiError;
}




    static dummy() {
        let output = {};
        output = this.build_dummy(bank_account_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    } else if (validateVar(vars[index].is_class_arr)) {

                        let class_name = callClassWithString(index);
                        output[index] = class_name.constructor.dummy();
                    } else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }

}

export default BankAccount;
