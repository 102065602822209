import {DropdownToggle, Media, UncontrolledDropdown} from "reactstrap";
import Skeleton from "react-loading-skeleton";
import React from "react";

function EmailLoadingAnimation() {

    return (<UncontrolledDropdown nav>
        <DropdownToggle className="nav-link py-0 pr-0" color="" tag="a">
            <Media className="align-items-center">
                                                    <span className='loading-user-logo'>
                                                        <Skeleton circle={true} height={34} width={34}/>
                                                    </span>
                <Media className="ml-2 d-none d-lg-block">
                                                        <span className="mb-0 text-sm font-weight-bold">
                                                            <Skeleton height={15} width={200}/>
                                                        </span>
                </Media>
            </Media>
        </DropdownToggle>
    </UncontrolledDropdown>);
}

export default EmailLoadingAnimation;