
import validateVar from "../Functions/validation/validateVariable";
import History from "./Components/history";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import {apiError} from "../Functions/global";


let offer_vars = {
    translation_object: {
        supplier_name: "Energieversorger",
        energy_source_certificate_url: "Öko Zertifikat",
        quantity_tolerance: "Mehr/Mindermengen Toleranz",
        bonus_amount_display: "Bonus / Laufzeit",
    },
    bonus_amount: {
        bonus_amount: null,
        bonus_amount_plain: {
            function_name: "handleFormatPrice",
            params: ["2", ""]
        },
        bonus_amount_display: {
            function_name: "handleFormatPrice",
            params: ["2", "€"]
        }
    },
    bonus_unit: null,
    cancellation_period: null,
    contract_extension: null,
    document_file_id: null,
    document_type: null,
    external: {
        default_value: "is_external"
    },
    email: null,
    expires_at: null,
    first_name: null,
    id: {
        id: null,
        offer_result_id: null
    },
    last_name: null,
    offer_begin: {
        offer_begin: null,
        offer_begin_display: {
            function_name: "getGermanDateFormat"
        }
    },
    offer_end: {
        offer_end: null,
        offer_end_display: {
            function_name: "getGermanDateFormat"
        }
    },
    offer_begin_and_end: {
        function_name: 'combine_strings',
        params: ['%%offer_begin_display', ' - ', '%%offer_end_display']
    },
    offer_duration: null,
    external_prices: {
        is_class_arr: "price"
    },
    phone: null,
    price_per_cp: null,
    supplier: {
        supplier_name: null
    },
    recommended_action_id: null,
    energy_source: null,
    price: {
        is_class_arr: "price",
        is_top_level: true,
    },

    // todo
    energy_source_certificate_url: null,
    energy_source_certificate_id: null,
    quantity_tolerance: null,
    min_quantity_tolerance: null,
    max_quantity_tolerance: null,
};

class ExternalOffer {
  static default_values = offer_vars;
  constructor(data) {
    if (validateVar(data)) {
      let class_content = new History(offer_vars, data, data.history, []);
      for (let name in class_content) {
        if (class_content.hasOwnProperty(name)) {
          this[name] = class_content[name];
        }
      }
    }
  }

    static async handle_external_offer(params = {}, method, call_function_name, file){
        params = getAgencyID(params);
        let communication_params = {
            method: method,
            params: params,
            function_url: call_function_name,
        };
        if(method === 'GET'){
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }if(method === 'PUT'){
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }
        let c = getCommunicationClass(communication_params, file);
        let res = await c.request_handler();
        if (res !== apiError) {
                return new ExternalOffer(res);
        } else return apiError;
    }
}

export default ExternalOffer;
