let initialState = null;

function contract_generator(state = initialState, action) {
  if (action.type === "GENERATE_CONTRACT"){
      return action.res;
  } else if (action.type === "RESET_GENERATE_CONTRACT") {
    return action.res;
  }
  else {
    return state;
  }
}

export default contract_generator;
