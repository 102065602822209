import React from "react";
import {Collapse} from 'reactstrap';
import validateVar from "../../../Functions/validation/validateVariable";
import './AbstractDropdownButton.scss';
import cn from 'classnames';
import is_true from "../../../Functions/is_true";
import is_false from "../../../Functions/is_false";

class AbstractDropdownButton extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            active: false,
            dropdown: '',
            tooltipOpen: false,
            button_name: (validateVar(props.button_name)) ? props.button_name : "Optionen",
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handle_click_outside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handle_click_outside);
    }

    handle_click_outside = (event) => {
        if (this.wrapperRef && this.state.active && !this.wrapperRef.contains(event.target)) { //close the button on click outside
            this.button_clicked();
            this.setState({dropdown: ''});
        }
    };

    //if export button is clicked
    click_option_btn = (option_name, disabled = false) => {
        if (is_false(disabled)) {
            this.button_clicked();
            this.props[option_name]();
        }
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    button_clicked = () => { //Beim Hinzufügen einer neuen Option muss CSS wegen der Animation angepasst werden + disabled=true erst ab +1 eingestellt werden
        this.setState({active: !this.state.active});
    };

    toggle = () => {
        if(this.props.disabled)
            this.setState({tooltipOpen: !this.state.tooltipOpen});
    };

    get_classes = (options) => {
        let classes = "";
        if (options.disabled) classes = " disabled";
        return classes;
    };

    render(){
        // const {hideTooltip, disableTooltip, wrapper_classes} = this.props;
        // let show_tooltipp = validateVar(hideTooltip) ? hideTooltip : (validateVar(disableTooltip) ? disableTooltip : false);
        const {option_classes, secondary_colors, file_name} = this.props;
        const {active} = this.state;

        let colors = 'primary-dropdown-colors';
        if(is_true(secondary_colors)) colors = 'secondary-dropdown-colors';

        return(
            <>
                <div ref={this.setWrapperRef} className={'abstract-dropdown '+colors+cn({' active': active})}>
                    <div className={'dropdown-toggler d-inline-block'} onClick={this.button_clicked}>{this.props.children}</div>
                    <Collapse className={'options ' + option_classes} isOpen={active}>
                        {this.props.options.map((option, index) => {
                            let classes = this.get_classes(option);
                            if(option.name === 'download') return <a href={option.download_url} download={file_name} onClick={() => this.button_clicked()}><div key={index}>{option.label}</div></a>
                            return <div className={classes}  key={index} onClick={() => this.click_option_btn(option.name, option.disabled)}>{option.label}</div>
                        })}
                    </Collapse>
                </div>
                {/*{!show_tooltipp &&*/}
                {/*<Tooltip placement="bottom"*/}
                {/*         isOpen={this.state.tooltipOpen}*/}
                {/*         target={this.props.id ? this.props.id : 'optionenButton'}*/}
                {/*         toggle={this.toggle}*/}
                {/*>*/}
                {/*    Bitte selektieren Sie die {this.props.tooltipProp}, mit denen Sie eine Aktion ausführen wollen.*/}
                {/*</Tooltip>}*/}
            </>
        )
    }
}

export default AbstractDropdownButton;