import {electricityVoltageLevel, emptyValue, gasVoltageLevel} from "./global";

function getSpecificationDisplay(specification, medium_type){
    let specificationArr = electricityVoltageLevel;
    if (medium_type === "GAS") specificationArr = gasVoltageLevel;
    let name = emptyValue;

    for (let index in specificationArr) {
        if (
            specificationArr.hasOwnProperty(index) &&
            +specificationArr[index].key === +specification
        ) {
            name = specificationArr[index].value;
        }
    }

    return name;
}

export default getSpecificationDisplay;