function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    // edit: increment months if d2 comes later in its month than d1 in its month
    if (d2.getDate() >= d1.getDate())
        months++;

    let last_date_in_delivery_month = new Date(d2.getFullYear(), d2.getMonth() + 1, 0); // get the last date (day) in delivery end
    if (d2.getDate() === last_date_in_delivery_month.getDate() && d1.getDate() === 1){ // check if its the start is the first of a month and the end is the last day of a month
        months++; // add +1 month
    } else if (d1.getDate() -1 === d2.getDate()){
        /*
            wenn das enddatum 1 tag vor dem startdatum liegt +1 month
            example: Startdatum 04/02/2020 -> Endatum 03/02/2021 -> 12 Monate
         */
        months++; // +1 Month
    }
    return months <= 0 ? 0 : months; // end edit
}

export default monthDiff;
