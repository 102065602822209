
/*
    Handlers: format, length, unformat
    Return: formatted iban, length without spaces
 */
function handleFormatIBAN(iban, handler = 'format'){
    if(handler === 'format'){
        let l = iban.length;
        if(l === 5 || l === 10 || l === 15 || l === 20 || l === 25)
            return iban.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
        else return iban;
    }else if(handler === 'length'){
        iban = iban.replace(/\s/g, '');
        return iban.length;
    }else if(handler === 'unformat'){
        return iban.replace(/\s/g, '');
    }

    return 'Wrong handleFormatIBAN() handler';
}

export default handleFormatIBAN;