import Tariff from "../module_components/Objects/tariff";
import HPFC from "../module_components/Objects/hpfc";
import Contract from "../module_components/Objects/contract";
import ConsumptionPoint from "../module_components/Objects/consumption_point";
import Employee from "../module_components/Objects/employees";
import File from "../module_components/Objects/files";
import Company from "../module_components/Objects/company";
import Integration from "../module_components/Objects/integrations";
import LoadProfile from "../module_components/Objects/loadProfiles";
import TestCalculation from "../module_components/Objects/testCalculation";
import deleteTree from "../module_components/Functions/deleteTree";
import getSupplierInformation from "../module_components/Functions/getSupplierInformation";
import modifySupplierGlobalTree from "../module_components/Functions/modifySupplierGlobalTree";
import ContractTemplate from "../module_components/Objects/contract_template";
import * as messageTypes from "./messageTypes";
import * as ERROR_CODE from "../error_codes";
import {
  DashboardPath,
  LoginPath,
  Pricestrategy,
  tariff_list,
  tree
} from "../globalNavigation";

import {
  apiError,
  apiFunctionGetGlobalPfc,
  zipNotFound
} from "../module_components/Functions/global";

import Analytics from "../module_components/Objects/analytics";
import {
  deHashVar,
  hashVar
} from "../module_components/Functions/handleHashedVariable";
import Consultant from "../module_components/Objects/consultants";
import validateVar from "../module_components/Functions/validation/validateVariable";
import Iban from "../module_components/Objects/iban";
import defaultLoadProfiles from "../module_components/Objects/defaultLoadProfiles";
import logoutEmployee from "../module_components/Functions/supplierActions";
import History from "../module_components/Objects/Components/history";
import Customer from "../module_components/Objects/customer";
import Chronik from "../module_components/Objects/chronik";
import inSign from "../module_components/Objects/in_sign";
import Blob from "../module_components/Objects/blob";
import Offer from "../module_components/Objects/offer";
import callClassWithString from "../module_components/Functions/callClassWithString";
import getAgencyID from "../module_components/Functions/getAgencyID";
import Notification from "../module_components/Objects/notification";
import is_true from "../module_components/Functions/is_true";

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));

export function getDefaultLps() {
  return function(dispatch) {
    defaultLoadProfiles.getAllProfiles().then(res => {
      dispatch({ type: "GET_DEFAULT_LOAD_PROFILES", res: res });
    });
  };
}

/* TariffCalculatedContainer */
export function initializeTariffs() {
  return function(dispatch) {
    Tariff.instantiateList().then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_TARIFF_LIST", res: res });
      } else {
        dispatch({ type: "INITIALIZE_TARIFF_LIST", res: [] });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem Laden der Tarifliste ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetTariffList +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function init_tariff(params) {
  return function(dispatch) {
    Tariff.init_tariff_new_request(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_SINGLE_TARIFF", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem Laden der Tarifdaten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetTariff +
              ")", // content
            "Fehler", //headline
            null, // url
            tariff_list, // url
            true, //modal
            "Zurück zur Tarifliste", // primaryBtnText
            "Bestätigen", // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function get_price_table_templates(params) {
  return function(dispatch) {
    Tariff.get_price_table_templates(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INIT_PRICE_TEMPLATES", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem Laden der Preistabellenvorlagen ist ein Fehler aufgetreten.",
            "Fehler", //headline
            null, // url
            tariff_list, // url
            true, //modal
            "Zurück zur Tarifliste", // primaryBtnText
            "Bestätigen", // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function update_price_table_values(res) {
  return function(dispatch) {
    dispatch({ type: "UPDATE_PRICE_VALUES", res: res });
  };
}
export function init_price_table_values(res) {
  return function(dispatch) {
    dispatch({ type: "INIT_PRICE_VALUES", res: res });
  };
}

export function deleteTariffs(selected_array) {
  return function(dispatch) {
    Tariff.deleteMultipleTariffs(selected_array).then(res => {
      if (res !== apiError) {
        let deleted_ids = res[0]; // all deleted ids for updating redux state
        let deleted_count = 0; // how many data obj were deleted? ( user feedback )
        if (validateVar(deleted_ids) && deleted_ids.length > 0)
          deleted_count = deleted_ids.length;
        let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
        let total_count = selected_array.length; // how many data obj for delete in total
        if (error_count === 0) {
          // no delete request failed -> total success
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              deleted_count + " von " + total_count + " Tarife wurden gelöscht", // content
              "Erfolg", //headline
              null, // url, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätige", // secondaryBtnText
              true //allowToggle (user can close modal without pressing a btn)
            )
          });
        } else {
          // delete went through but some data threw a error message
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              deleted_count +
                " von " +
                total_count +
                " Tarife wurden gelöscht, bei " +
                error_count +
                " von " +
                total_count +
                " ist das löschen Fehlgeschlagen", // content
              "Erfolg", //headline
              null, // url, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätige", // secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              true // infoIcon
            )
          });
        }
        dispatch({
          // send deleted ids to redux to filter out the deleted data obj from state
          type: "DELETE_TARIFF",
          ids: deleted_ids
        });
      } else {
        // Error feedback for customer -> could not delete data
        let error_message =
          "Bei löschen des Tarifs ist ein Fehler aufgetreten. (Fehlercode: " +
          ERROR_CODE.DeleteTariff +
          ")";
        if (selected_array.length > 0)
          error_message =
            "Bei dem löschen der Tarife ist ein Fehler aufgetreten. (Fehlercode: " +
            ERROR_CODE.DeleteTariff +
            ")";
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            error_message, // content
            "Fehler", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function initializeTariff(data) {
  return function(dispatch) {
    Tariff.instantiate(data).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_SINGLE_TARIFF", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem Laden der Tarifdaten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetTariff +
              ")", // content
            "Fehler", //headline
            null, // url
            tariff_list, // url
            true, //modal
            "Zurück zur Tarifliste", // primaryBtnText
            "Bestätigen", // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function updateTariff(params, url = null) {
  return function(dispatch) {
    Tariff.update(params).then(res => {
      if (res !== apiError) {
        // dispatch({ type: "REDIRECT_USER", res: true });
        if (validateVar(url)) {
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              "", //  content
              "", // headline
              url, // url
              true, // automatic_redirect
              false // modal
            )
          });
        } else {
          dispatch({ type: "UPDATE_TARIFF", res: res });

          //Only display success message when the tariff gets activated
          if (params.lifecycle_status === 1) {
            dispatch({
              type: "SET_USER_MESSAGE",
              res: displaySuccessMessage(
                "Dieser Tarif ist nun aktiv.", // content
                "Erfolg", //headline
                null, // url, // url
                false, //automatic redirect
                false //modal
              )
            });
          }
        }
      } else {
        dispatch({ type: "UPDATE_TARIFF", res: apiError });
        // error
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.UpdateTariff +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function updateTariffTable(params, ids) {
  return function(dispatch) {
    Tariff.updateTable(params, ids).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_TARIFF_TABLE", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.UpdateTariffTable +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function copyTariff(params) {
  return function(dispatch) {
    Tariff.copyTariff(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_TARIFF", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Fehler beim kopieren der Daten. (Fehlercode: " +
              ERROR_CODE.CopyTariff +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

/* CONTRACT */
export function modifyContract(params) {
  return function(dispatch) {
    Contract.update(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "MODIFY_CONTRACT", res: res });
        if (params.protocol_id) dispatch({ type: "UPDATE_HISTORY", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.UpdateContract +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

/* GLOBAL TREET */

export function deleteGlobalTree(supplier_id) {
  return function(dispatch) {
    getSupplierInformation().then(res => {
      deleteTree(res.settings.global_tree_id).then(res => {
        if (res.status !== apiError) {
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage("Globale Kriterien wurden gelöscht.")
          });
          dispatch({ type: "REQUEST_FINISHED", res: "End" });
        } else {
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displayErrorMessage(
              "Beim löschen der Globalen Kriterien ist ein Fehler aufgetreten. (Fehlercode: " +
                ERROR_CODE.DeleteGlobalTree +
                ")", // content
              "Fehler", //headline
              null, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätige", // secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              false, // infoIcon
              "danger" //type - danger/success
            )
          });
        }
      });
      modifySupplierGlobalTree("");
    });
  };
}

export function addTariff(param) {
  return function(dispatch) {
    Tariff.add(param).then(res => {
      if (res !== apiError) {
        dispatch({ type: "ADD_TARIFF", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(
            "", // content
            "", //headline
            tree + "/" + res.id + "/" + res.tree_id, // url
            true, //automatic redirect
            false //modal
          )
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim hinzufügen des Tarifes ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.AddTariff +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

/* INTEGRATIONS */
export function initializeIntegrations(id = supplier_id) {
  return function(dispatch) {
    Integration.instantiateList(id).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_INTEGRATIONS", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim laden der Integrationen ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetIntegrations +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function deleteIntegration(integrations, id) {
  return function(dispatch) {
    Integration.delete(integrations, id).then(res => {
      if (res !== apiError) {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(messageTypes.removeIntegrationSuccess)
        });
        if (res.hasOwnProperty("new_state"))
          dispatch({ type: "DELETE_INTEGRATION", res: res.new_state });
      } else {
        // error
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim löschen der Integration(en) ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.DeleteIntegrations +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

/* INTEGRATIONS */
export function initializeIntegrationList() {
  return function(dispatch) {
    Integration.instantiateListDisconnected().then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_INTEGRATION_LIST", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim laden der Integration(en) ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetIntegrationList +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function addIntegration(data, integration, supplier_id) {
  // todo umcoden
  return function(dispatch) {
    var integrations_storage = JSON.parse(localStorage.getItem("integrations"));
    if (
      typeof integrations_storage === "undefined" ||
      integrations_storage === null
    )
      integrations_storage = [];

    let param = {
      integration_id: integration.id,
      agency_id: supplier_id,
      password: data.password,
      username: data.user
    };
    integration.c.add(param).then(res => {
      if (res.status === "ok") {
        let integration_id = parseInt(integration.id);
        if (validateVar(integration_id)) {
          integrations_storage[integration_id] = res.result.status;
          localStorage.setItem(
            "integrations",
            JSON.stringify(integrations_storage)
          );
        }
        dispatch({ type: "ADD_INTEGRATION", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(messageTypes.addIntegrationSuccess)
        });
      } else {
        //TODO bei fehler die integration wieder entfernen
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim hinzufügen der Integration(en) ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.AddIntegration +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

function has_id(integrations_storage, id) {
  if (validateVar(integrations_storage))
    return integrations_storage.hasOwnProperty(id);
  else return false;
}

/* SUPPLIER COMPANY */

export function initializeCompany(id) {
  return function(dispatch) {
    Company.instantiate(id).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_COMPANY", res: res });
      } else {
        logoutEmployee(false);
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim laden der Firmendaten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetCompny +
              ")", // content
            "Fehler", //headline
            LoginPath, // url
            false, //automatic redirect
            true, //modal
            "Zurück zum Login", // primaryBtnText
            null, // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function loginSupplier(email, password) {
  return function(dispatch) {
    Company.login(email, password).then(res => {
      if (res !== apiError) {
        logoutEmployee(false); // remove all data in localStorage without redirect
        let agencyID = res.agency;
        let customerID = res.customer_id;
        let userRole = res.roles[0].name;
        let status = res.roles[0].pivot.status;
        if (status !== "active") {
          let message =
            "Bitte aktivieren Sie Ihren Account, loggen Sie sich hierzu in Ihren Mail Account ein und drücken auf den von uns zugesandten Link.";
          let headline = "Nutzer nicht aktiviert";
          dispatch({ type: "FEEDBACK_EMPLOYEE", res: "ERR" });
          if (status === "inactive") {
            message =
              "Ihr Account wurde deaktiviert. Derzeit ist es Ihnen nicht möglich sich einzuloggen.";
            headline = "Account deaktiviert";
          }
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displayErrorMessage(
              message, // content
              headline, //headline
              null, // url,
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätige", // secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              true, // infoIcon
              "danger" //type - danger/success
            )
          });
        } else {
          let hashedAgencyID = hashVar(agencyID);
          let hashedCustomerID = hashVar(customerID);
          let hashedRole = hashVar(userRole);
          localStorage.setItem("evuSecurityToken", hashedAgencyID); // agency_id = supplier_id
          localStorage.setItem("employeeSecurityToken", hashedCustomerID); // customer_id = employee_id
          localStorage.setItem("evuUserAccount", hashedRole);

          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              "", //  content
              "", // headline
              DashboardPath,
              true, // automatic_redirect
              false // modal
            )
          });
        }
      } else {
        dispatch({ type: "FEEDBACK_EMPLOYEE", res: "ERR" });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Kombination aus E-Mail und Passwort ist falsch. Bitte versuchen Sie es noch einmal oder Registrieren Sie sich zuerst.", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            false //modal
          )
        });
      }
    });
  };
}

export function updateCompany(params, partner_params) {
  return function(dispatch) {
    //basic info update
    Company.update(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_COMPANY", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(
            "die Firmendaten wurden erfolgreich aktualisiert.", //  content
            "Erfolg", // headline
            null, // url
            false, // automatic_redirect
            false // modal
          )
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.UpdateCompany +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function updateCompanyAndParnter(params, partner_params) {
  return function(dispatch) {
    //basic info update
    Company.update(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_COMPANY", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.UpdateCompany +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function getAllConsultants() {
  return function(dispatch) {
    Consultant.getAllConsultants().then(res => {
      if (res !== apiError) {
        dispatch({ type: "GET_ALL_CONSULTANT", res: res });
      } else {
        dispatch({ type: "GET_ALL_CONSULTANT", res: [] });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetConsultants +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

/*
       EMPLOYEES
 */

export function initializeEmployee() {
  return function(dispatch) {
    Employee.instantiateEmployee().then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_EMPLOYEE", res: res });
      } else {
        dispatch({ type: "INITIALIZE_EMPLOYEE", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetEmployee +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function initializeEmployees() {
  return function(dispatch) {
    Employee.instantiateList().then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_EMPLOYEE_LIST", res: res });
      } else {
        dispatch({ type: "INITIALIZE_EMPLOYEE_LIST", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetEmployees +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

// export function addEmployee(params) {
//   return function(dispatch) {
//     Employee.addEmployee(params).then(res => {
//       if (res === "Used") {
//         //error
//         dispatch({
//           type: "SET_USER_MESSAGE",
//           res: displayErrorMessage(
//             "Nutzer konnte nicht angelegt werden. Es existiert bereits ein Nutzer mit dieser E-Mail!"
//           )
//         });
//       } else if (res === apiError) {
//         dispatch({
//           type: "SET_USER_MESSAGE",
//           res: displayErrorMessage(
//             "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
//               ERROR_CODE.GetEmployees +
//               ")", // content
//             "Fehler", //headline
//             null, // url
//             false, //automatic redirect
//             true, //modal
//             null, // primaryBtnText
//             "Bestätige", // secondaryBtnTextl
//             false, //allowToggle (user can close modal without pressing a btn)
//             false, // successIcon
//             false, // infoIcon
//             "danger" //type - danger/success
//           )
//         });
//       } else {
//         //Success
//         dispatch({ type: "ADD_EMPLOYEE", res: res });
//       }
//     });
//   };
// }

export function updateEmployee(params) {
  return function(dispatch) {
    Employee.updateEmployee(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_EMPLOYEE", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.UpdateEmployee +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function updateSingleEmployee(params) {
  return function(dispatch) {
    Employee.updateEmployee(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_SINGLE_EMPLOYEE", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage("Ihr Profil wurde aktualisiert.", "Erfolg")
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim aktualisieren des Profils ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.UpdateEmployee +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}
export function updateMultibleEmployee(employees) {
  return function(dispatch) {
    Employee.updateEmployeeStatus(employees).then(res => {
      let updated_employee = res[0];
      let error_count = res[1];
      if (res !== apiError && error_count === 0)
        dispatch({ type: "UPDATE_EMPLOYEE_ARRAY", res: updated_employee });
      else if (res !== apiError && error_count > 0) {
        dispatch({ type: "UPDATE_EMPLOYEE_ARRAY", res: updated_employee });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(
            updated_employee.length +
              " von " +
              employees.length +
              " Accounts konnten geupdated werden.", // content
            "Erfolg", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            true // infoIcon
          )
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Mitarbeiterdaten konnten nicht aktualisiert werden. (Fehlercode: " +
              ERROR_CODE.UpdateEmployee +
              ")", // content
            "Fehler", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function uploadFile(params, file) {
  return function(dispatch) {
    File.addFile(params, file).then(res => {
      if (res !== apiError && validateVar(res.file_id)) {
        dispatch({ type: "FILE_UPLOADED", res: res.file_id });
      } else {
        //Errormessage is shown in updateSingleEmployee else case
      }
    });
  };
}

export function resetPasswordEmployee(params) {
  // umcoden
  return function(dispatch) {
    Employee.resetPassword(params).then(res => {
      if (res.status === "ok") {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(
            "Ihnen wurde eine Mail zum zurücksetzen Ihres Passworts zugesendet. Bitte prüfen Sie Ihr Postfach.",
            "Erfolg"
          )
        });
        dispatch({ type: "PASSWORD_RESET", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Es ist ein Fehler beim versenden der E-Mail aufgetreten."
          )
        });
        dispatch({ type: "FEEDBACK_EMPLOYEE", res: apiError });
      }
    });
  };
}

export function activateNewPasswordEmployee(params) {
  return function(dispatch) {
    Employee.setNewPassword(params).then(res => {
      if (res !== apiError) {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(
            "Ihr neues Passwort wurde erfolgreich gesetzt.",
            "Erfolg!",
            LoginPath, // url,
            false, //automatic redirect
            true, //modal
            "Zurück zum Login", // primaryBtnText
            null, // secondaryBtnText
            false //allowToggle (user can close modal without pressing a btn)
          )
        });
        dispatch({ type: "PASSWORD_RESET", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Es ist ein Fehler aufgetreten. Ggf müssen Sie eine neue E-Mail anfordern."
          )
        });
      }
    });
  };
}

export function activateEmployee(params) {
  return function(dispatch) {
    Employee.activate(params).then(res => {
      if (res !== apiError) {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(messageTypes.activateEmployeeSuccess)
        });
        dispatch({ type: "LOG_IN_EMPLOYEE", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(messageTypes.activateEmployeeError)
        });
        dispatch({ type: "FEEDBACK_EMPLOYEE", res: apiError });
      }
    });
  };
}

export function deleteMutlipleEmployee(selected_array) {
  return function(dispatch) {
    Employee.deleteMultiple(selected_array).then(res => {
      if (res !== apiError) {
        let deleted_ids = res[0]; // all deleted ids for updating redux state
        let deleted_count = 0; // how many data obj were deleted? ( user feedback )
        if (validateVar(deleted_ids) && deleted_ids.length > 0)
          deleted_count = deleted_ids.length;
        let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
        let total_count = selected_array.length; // how many data obj for delete in total
        if (error_count === 0) {
          // no delete request failed -> total success
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              deleted_count + " von " + total_count + " Nutzer wurden gelöscht", // content
              "Erfolg", //headline
              null, // url, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätige", // secondaryBtnText
              true //allowToggle (user can close modal without pressing a btn)
            )
          });
        } else {
          // delete went through but some data threw a error message
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              deleted_count +
                " von " +
                total_count +
                " Nutzer wurden gelöscht, bei " +
                error_count +
                " von " +
                total_count +
                " ist das löschen Fehlgeschlagen.", // content
              "Erfolg", //headline
              null, // url, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätige", // secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              true // infoIcon
            )
          });
        }

        // send deleted ids to redux to filter out the deleted data obj from state
        dispatch({
          type: "DELETE_EMPLOYEE",
          ids: deleted_ids
        });
      } else {
        // Error feedback for customer -> could not delete data
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem löschen der Nutzer ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.DeleteEmployee +
              ")", // content
            "Fehler", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

/* analytics */

export function initializeAnalytics() {
  return function(dispatch) {
    Analytics.get_analytics().then(res => {
      let res_count = 0;
      let err_count = 0;
      for (let index in res) {
        res_count = res_count + 1;
        if (res.hasOwnProperty(index)) {
          let data = res[index];
          if (data === "ERR") {
            err_count = err_count + 1;
            res[index] = [];
          } else if (data === "[]") res[index] = [];
        }
      }
      if (err_count === res_count) {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Daten konnten nicht geladen werden. (Fehlercode: " +
              ERROR_CODE.GetAllAnalyticsData +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      } else if (err_count > 0) {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Einige Daten konnten nicht geladen werden. (Fehlercode: " +
              ERROR_CODE.GetAnalyticsData +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      } else {
        dispatch({ type: "INITIALIZE_ANALYTICS", res: res });
      }
    });
  };
}

/* analytics end */

/*
    START PFC
 */
export function initializePfcs() {
  return function(dispatch) {
    HPFC.instantiateList().then(res => {
      if (res !== apiError) dispatch({ type: "INITIALIZE_PFC", res: res });
      else {
        dispatch({ type: "INITIALIZE_PFC", res: [] });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim laden der PFC's ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetPfcList +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Ok", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function deleteMutliplePfc(selected_array) {
  return function(dispatch) {
    HPFC.deleteMultiple(selected_array).then(res => {
      if (res !== apiError) {
        let deleted_ids = res[0]; // all deleted ids for updating redux state
        let deleted_count = 0; // how many data obj were deleted? ( user feedback )
        if (validateVar(deleted_ids) && deleted_ids.length > 0)
          deleted_count = deleted_ids.length;
        let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
        let total_count = selected_array.length; // how many data obj for delete in total
        if (error_count === 0) {
          // no delete request failed -> total success
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              deleted_count +
                " von " +
                total_count +
                " PFC(s) wurde(n) gelöscht (Auf 'aktiv' gesetzte PFCs werden übersprungen)", // content
              "Erfolg", //headline
              null, // url, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätige", // secondaryBtnText
              true //allowToggle (user can close modal without pressing a btn)
            )
          });
        } else {
          // delete went through but some data threw a error message
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              deleted_count +
                " von " +
                total_count +
                " PFC(s) wurde(n) gelöscht, bei " +
                error_count +
                " von " +
                total_count +
                " ist das löschen Fehlgeschlagen. (Auf 'aktiv' gesetzte PFCs werden übersprungen)", // content
              "Erfolg", //headline
              null, // url, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätige", // secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              true // infoIcon
            )
          });
        }

        // send deleted ids to redux to filter out the deleted data obj from state
        dispatch({
          type: "DELETE_PFCS_FROM_LIST",
          ids: deleted_ids
        });
      } else {
        // Error feedback for customer -> could not delete data
        dispatch({ type: "DELETE_PFC_ERROR", res: "ERR" });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem löschen der PFC(s) ist ein Fehler aufgetreten. Auf 'aktiv' gesetzte PFCs werden übersprungen. (Fehlercode: " +
              ERROR_CODE.DeletePFC +
              ")", // content
            "Fehler", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function downloadPfc(ids) {
  return { type: "DOWNLOAD_PFC", ids: ids };
}

export function downloadGlobalPfc() {
  return function(dispatch) {
    let hpfc = new HPFC();
    hpfc.download(apiFunctionGetGlobalPfc, { type: "download" }).then(res => {
      if (res !== apiError) {
        dispatch({ type: "DOWNLOAD_GLOBAL_HPFC", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die globale PFC's konnten nicht runtergeladen werden. (Fehlercode: " +
              ERROR_CODE.GetGlobalPFC +
              ")", // content
            "Fehler", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function addPfc(formData, file) {
  return function(dispatch) {
    HPFC.addPfc(formData, file).then(res => {
      if (res !== apiError) {
        dispatch({ type: "ADD_PFC", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(messageTypes.addPfcSuccess)
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Hochladen der Datei ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.AddPfc +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function toggle_pfc(params) {
  return function(dispatch) {
    params = getAgencyID(params);
    HPFC.update(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_PFC", res: params });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(
            "Ihre Änderung an der PFC wurde übernommen", // content
            "Erfolg" //headline
          )
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Änderung an der PFC konnte nicht übernommen werden", // content
            "Fehler" //headline
          )
        });
      }
    });
  };
}

/*

CONSUMPTIONPOINT
*/
export function updateConsumptionPointForContract(params) {
  return function(dispatch) {
    ConsumptionPoint.updateCpForContract(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_CONSUMPTION_POINTS_FOR_CONTRACT", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetConsumptionPoints +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}
export function updateMultipleConsumptionPointsForContract(
  params,
  selected_array
) {
  return function(dispatch) {
    ConsumptionPoint.updateMultipleCpsForContract(params, selected_array).then(
      res => {
        if (res !== apiError) {
          let updated_cps = res[0]; // all deleted ids for updating redux state
          let updated_count = 0; // how many data obj were deleted? ( user feedback )
          if (validateVar(updated_cps) && updated_cps.length > 0)
            updated_count = updated_cps.length;
          let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
          let total_count = selected_array.length; // how many data obj for delete in total
          if (error_count === 0) {
            // no update request failed -> total success
            dispatch({
              type: "SET_USER_MESSAGE",
              res: displaySuccessMessage(
                updated_count +
                  " von " +
                  total_count +
                  " Lieferstelle(n) wurde(n) aktualisiert", // content
                "Erfolg", //headline
                null, // url, // url
                false, //automatic redirect
                true, //modal
                null, // primaryBtnText
                "Bestätige", // secondaryBtnText
                true //allowToggle (user can close modal without pressing a btn)
              )
            });
          } else {
            // update went through but some data threw a error message
            dispatch({
              type: "SET_USER_MESSAGE",
              res: displaySuccessMessage(
                updated_count +
                  " von " +
                  total_count +
                  " Lieferstelle(n) wurde(n) gelöscht, bei " +
                  error_count +
                  " von " +
                  total_count +
                  " ist das löschen Fehlgeschlagen. (Auf 'aktiv' gesetzte PFCs werden übersprungen)", // content
                "Erfolg", //headline
                null, // url, // url
                false, //automatic redirect
                true, //modal
                null, // primaryBtnText
                "Bestätige", // secondaryBtnText
                true, //allowToggle (user can close modal without pressing a btn)
                false, // successIcon
                true // infoIcon
              )
            });
          }
          dispatch({
            type: "UPDATE_MULTIPLE_CONSUMPTION_POINTS_FOR_CONTRACT",
            res: res
          });
        } else {
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displayErrorMessage(
              "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                ERROR_CODE.GetConsumptionPoints +
                ")", // content
              "Fehler", //headline
              null, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätigen", // secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              false, // infoIcon
              "danger" //type - danger/success
            )
          });
        }
      }
    );
  };
}

export function modifyCustomer(params) {
  return function(dispatch) {
    Customer.modifyCustomer(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_CUSTOMER", res: res });
        if (params.protocol_id) dispatch({ type: "UPDATE_HISTORY", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim aktualisieren . (Fehlercode: " +
              ERROR_CODE.GetConsumptionPoints +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function reset_history() {
  return function(dispatch) {
    dispatch({ type: "RESET_HISTORY", res: null });
  };
}

export function initializeChronik(params, site_name = null, id = null) {
  return function(dispatch) {
    Chronik.init(params, site_name, id).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INIT_CHRONIK", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Laden der Chronik ist etwas schief gelaufen. (Fehlercode: " +
              ERROR_CODE.GetChronik +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function createChronik(params, file) {
  return function(dispatch) {
    Chronik.create(params, file).then(res => {
      if (res !== apiError) {
        dispatch({ type: "ADD_CHRONIK", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim hinzufügen der Chronik ist etwas schief gelaufen. (Fehlercode: " +
              ERROR_CODE.GetChronik +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

//// INSIGN ////
export function createInSignSession(customer_params) {
  return function(dispatch) {
    inSign.configureSession(customer_params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "GET_INSIGN_SESSION_RESULT", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim erstellen der InSign Schnittstellen Session ist ein Fehler aufgetreten. (Fehlercode: " +
              +")", // content
            "Fehler", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function loadAndCheckInsignSession(
  session_id,
  refresh_only = false,
  guestPage = false
) {
  return function(dispatch) {
    inSign.loadAndCheckIn(session_id).then(res => {
      //TODO requiredSignatureFields ist ungetestet da der fehler nicht mehr auftritt
      if (res.requiredSignatureFieldsSigned === res.requiredSignatureFields) {
        //check if required signature fields are filled
        if (res !== apiError) {
          if (!refresh_only) dispatch({ type: "INITIALIZE_INSIGN", res: res });
        } else {
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displayErrorMessage(
              "Bei dem laden der Daten von InSign ist ein Fehler aufgetreten. (Fehlercode: " +
                ")", // content
              "Fehler", //headline
              null, // url, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätigen", // secondaryBtnText
              false, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              false, // infoIcon
              "danger" //type - danger/success
            )
          });
          dispatch({ type: "INITIALIZE_INSIGN", res: apiError });
        }
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Nicht alle Unterschriftsfelder wurden ausgefüllt. Überprüfen Sie bitte das Dokument noch einmal. (Fehlercode: " +
              ")", // content
            "Fehler", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            false, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
        dispatch({ type: "INITIALIZE_INSIGN", res: "ERR" });
      }
    });
  };
}

export function uploadInSignDoc(upload_params, file) {
  return function(dispatch) {
    inSign.loadAndCheckIn(upload_params.session_id).then(res => {
      if (res !== apiError) {
        inSign.uploadDocument(upload_params, file).then(res => {
          if (res !== apiError) {
            if (upload_params.send_mail) {
              // if mail was send the offer should be saved
              dispatch({ type: "MAIL_SEND_INSIGN", res: "SAVE_OFFER" });
            } else
              dispatch({
                type: "CONTRACT_SIGNED_INSIGN",
                res: "CONTRACT_SIGNED"
              });
          } else {
            dispatch({
              type: "SET_USER_MESSAGE",
              res: displayErrorMessage(
                "Bei dem Hochladen des Dokuments ist ein Fehler aufgetreten. (Fehlercode:" +
                  ")", // content
                "Fehler", //headline
                null, // url, // url
                false, //automatic redirect
                true, //modal
                null, // primaryBtnText
                "Bestätige", // secondaryBtnText
                true, //allowToggle (user can close modal without pressing a btn)
                false, // successIcon
                false, // infoIcon
                "danger" //type - danger/success
              )
            });
          }
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim laden der InSign Schnittstelle ist ein Fehler aufgetreten. (Fehlercode: " +
              ")", // content
            "Fehler", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function reset_uploaded_file() {
  return function(dispatch) {
    dispatch({ type: "RESET_UPLOADED_FILE", res: null });
  };
}

export function upload_snigned_contract(params, file) {
  return function(dispatch) {
    Contract.uploadSignedContract(params, file).then(res => {
      if (res !== apiError) {
        if (params.clearing) {
          dispatch({ type: "UPLOAD_SIGNED_CONTRACT_CLEARING", res: res });
        } else {
          dispatch({ type: "UPLOAD_SIGNED_CONTRACT_EHUB", res: res });
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              "Ihre Vertragsdatei wurde erfolgreich hochgeladen", // content
              "Datei hochgeladen" //headline
            )
          });
        }
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem Hochladen des unterschriebenen Vertragsdokuments ist ein Fehler aufgetreten. (Fehlercode: " +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
        dispatch({ type: "UPLOAD_SIGNED_CONTRACT_EHUB", res: res });
      }
    });
  };
}

export function downloadDocument(params) {
  return function(dispatch) {
    inSign.downloadDocument(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "CONTRACT_SIGNED_INSIGN", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem Erstellen des unterschriebenen Dokuments ist ein Fehler aufgetreten. (Fehlercode: " +
              ")", // content
            "Fehler", //headline
            null, // url, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
        dispatch({ type: "CONTRACT_SIGNED_INSIGN", res: apiError });
      }
    });
  };
}

export function createBlobFromURL(url) {
  return function(dispatch) {
    Blob.createBlobFromURL(url).then(res => {
      dispatch({ type: "URL_TO_BLOB", res: res });
    });
  };
}

export function createURL(url) {
  return function(dispatch) {
    Blob.fileStreamToBlob(url).then(res => {
      dispatch({ type: "URL_TO_BLOB", res: res });
    });
  };
}
/// INSIGN END ////

export function update_history_changes(params, function_url = null) {
  return function(dispatch) {
    History.update_history_changes(params, function_url).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_HISTORY", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetConsumptionPoints +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

/*
OFFER
 */

export function initOfferList(params) {
  return function(dispatch) {
    Offer.initList(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INIT_OFFER_LIST", res: res });
      } else {
        let return_default = {
          offer: [],
          next_offset: null,
          last_request: null,
          total_count: 0
        };
        dispatch({ type: "INIT_OFFER_LIST", res: return_default });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetContract +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

/*
    START CONTRACTS
 */
export function initializeContract(params) {
  return function(dispatch) {
    Contract.instantiateList(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_CONTRACT_EHUB", res: res });
      } else {
        let return_default = {
          contracts: [],
          next_offset: null,
          last_request: null,
          total_count: 0
        };
        dispatch({ type: "INITIALIZE_CONTRACT_EHUB", res: return_default });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetContract +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function create_url(params, class_name, function_name) {
  return function(dispatch) {
    let class_object = callClassWithString(class_name, null);
    class_object.constructor[function_name](params).then(res => {
      if (res !== apiError) {
        let result = {
          file_name: params.file_name,
          url: res
        };
        dispatch({ type: "CREATE_URL", res: result });
      } else {
        dispatch({ type: "RESET_URL", res: res });
      }
    });
  };
}

export function reset_url() {
  return function(dispatch) {
    dispatch({ type: "RESET_URL", res: null });
  };
}

export function downloadContracts(ids) {
  return { type: "DOWNLOAD_CONTRACTS", ids: ids };
}

export function deleteCompanyFile(params) {
  return function(dispatch) {
    let type = params.type;

    let file_type_message_error = "der Firmenbroschüre";
    let file_type_message_success = "Die Firmenbroschüre";
    if (type === "images") {
      file_type_message_error = "des Firmenlogos";
      file_type_message_success = "Das Firmenlogo";
    }

    params = {
      supplier_id: supplier_id
    };

    if (type === "images") {
      params.logo_file_id = false;
    } else params.brochure_file_id = false;

    Company.update(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPDATE_COMPANY", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(
            file_type_message_success + " wurde entfernt.",
            "Erfolg", //headline
            null, // url,
            false, //automatic redirect
            false //modal
          )
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem Entfernen " +
              file_type_message_error +
              " ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.DeleteCompanyFile +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function upload_signed_contract(params, file) {
  return function(dispatch) {
    Contract.uploadSignedContract(params, file).then(res => {
      if (res !== apiError) {
        dispatch({ type: "UPLOAD_SIGNED_CONTRACT_EHUB", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displaySuccessMessage(
            "Der unterschriebene Vertrag wurde erfolgreich hochgeladen.", // content
            "Erfolg", //headline
            null, // url
            false, //automatic redirect
            false //modal
          )
        });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem Hochladen des unterschriebenen Vertragsdokuments ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.UploadSnignedContract +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowTogdeleteContractgle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function initializeSingleContract(id) {
  return function(dispatch) {
    Contract.instantiate(id).then(res => {
      if (res !== apiError) {
        dispatch({ type: "GET_SINGLE_CONTRACT", res: res });
      } else {
        dispatch({ type: "GET_SINGLE_CONTRACT", res: apiError });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetContractTemplate +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function initTmpList(params) {
  return function(dispatch) {
    ContractTemplate.instantiateList(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "GET_CONTRACT_TEMPLATE_LIST", res: res });
      } else {
        dispatch({ type: "GET_CONTRACT_TEMPLATE_LIST", res: res });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetContractTemplate +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function modifyConsumptionPoint(params, predicion = 1, extended = 1) {
  params.options = {
    with_prediction: predicion,
    with_extended_information: extended,
    with_load_profile_name: 1
  };
  return function(dispatch) {
    ConsumptionPoint.modifyConsumptionPoint(params).then(res => {
      if (res !== apiError && res !== zipNotFound) {
        dispatch({ type: "UPDATE_CONSUMPTION_POINTS", res: res });
      } else if (res === zipNotFound) {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die angegebene Postleitzahl gibt es nicht.", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
        dispatch({ type: "RETURN_PREVIOUS_STATE" });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetConsumptionPoints +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function initConsumptionPoint(params, predicion = 1, extended = 1) {
  params.options = {
    with_prediction: predicion,
    with_extended_information: extended,
    with_load_profile_name: 1,
    with_cancellation: 1
  };
  return function(dispatch) {
    ConsumptionPoint.instantiate(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_CONSUMPTION_POINT", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetConsumptionPoints +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function initializeConsumptionPointsForContract(id, protocol_id = null) {
  let params = {
    contract_id: id,
    options: {
      with_prediction: 1,
      with_extended_information: 1
    },
    protocol_id: protocol_id,
    type: "extended"
  };

  return function(dispatch) {
    ConsumptionPoint.instantiateForContract(params).then(res => {
      if (res !== apiError)
        dispatch({ type: "GET_CONSUMPTION_POINTS_FOR_CONTRACT", cps: res });
      else {
        dispatch({ type: "GET_CONSUMPTION_POINTS_FOR_CONTRACT", cps: [] });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim laden der Abnahmestellen des Vertrags ist ein Fehler aufgetreten. (Fehlercode: )", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Ok", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function notification_viewed(params, url = null) {
  return function(dispatch) {
    Notification.handle_viewed(params, "PUT").then(res => {
      if (res !== apiError) {
        if (validateVar(url)) {
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              "", // content
              "", //headline
              url, // url
              true, //automatic redirect
              false //modal
            )
          });
        } else {
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displaySuccessMessage(
              "Der Status wurde erfolgreich bearbeitet", // content
              "Erfolg", //headline
              null, // url
              false, //automatic redirect
              false //modal
            )
          });
          dispatch({ type: "NOTIFICATION_VIEWED_COMPANY", res: res });
        }
      } else {
        dispatch({ type: "NOTIFICATION_VIEWED", res: apiError });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim dem Aufrufen der Benachrichtigungen ist ein Fehler aufgetreten.", // content
            "Fehler", //headline
            null,
            false, //automatic redirect
            true, //modal
            null,
            "Zurück", // secondaryBtnText
            true //allowToggle (user can close modal without pressing a btn)
          )
        });
      }
    });
  };
}

/* Loadprofiles */
export function getLoadProfiles(
  medium_type,
  last_type,
  save_in_object = false
) {
  return function(dispatch) {
    LoadProfile.getLps(medium_type, last_type).then(res => {
      if (res !== apiError) {
        if (is_true(save_in_object)) {
          //Here is the response saved into res.SLP or res.RLM to be able to pass both profile
          // arrays to frontend instead of overriding same property with second response
          dispatch({
            type: "GET_DEFAULT_" + last_type + "_PROFILES",
            res: res
          });
        } else {
          dispatch({ type: "GET_DEFAULT_LOAD_PROFILES", res: res });
        }
      } else {
        dispatch({ type: "GET_DEFAULT_LOAD_PROFILES", res: [{}] });
      }
    });
  };
}

export function download_load_profile(id) {
  return function(dispatch) {
    LoadProfile.download_load_profile(id).then(res => {
      if (res !== "ERR") dispatch({ type: "UPDATE_OFFER_RES", res: res });
      else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim herunterladen ihres Lastprofiles ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetLoadProfile +
              ")", // content
            "Fehler", //headline
            null,
            false, //automatic redirect
            true, //modal
            null,
            "Zurück", // secondaryBtnText
            true //allowToggle (user can close modal without pressing a btn)
          )
        });
      }
    });
  };
}

export function modify_offer_res(params) {
  return function(dispatch) {
    Contract.modify_offer_res(params).then(res => {
      if (res !== "ERR") {
        dispatch({ type: "", res: res });
        if (validateVar(params.protocol_id))
          dispatch({ type: "UPDATE_HISTORY", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Bei dem aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.GetLoadProfile +
              ")", // content
            "Fehler", //headline
            null,
            false, //automatic redirect
            true, //modal
            null,
            "Zurück", // secondaryBtnText
            true //allowToggle (user can close modal without pressing a btn)
          )
        });
      }
    });
  };
}

/* CALCULATION */
export function testCalculation(params) {
  return function(dispatch) {
    dispatch({ type: "GET_TEST_CALCULATION_RESULT", res: null }); //reset props torice_guarantee_type be able to enable input fields as soon as we get the response from request
    TestCalculation.calculation_test(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "GET_TEST_CALCULATION_RESULT", res: res });
      } else {
        dispatch({ type: "GET_TEST_CALCULATION_RESULT", res: apiError });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Beim Testkalkulieren ist ein Fehler aufgetreten. (Fehlercode: " +
              ERROR_CODE.TestCalculate +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function showErrorMessage(message_content = "", headline = "Achtung") {
  return function(dispatch) {
    dispatch({
      type: "SET_USER_MESSAGE",
      res: displayErrorMessage(
        message_content, // message
        headline
      )
    });
  };
}

export function showSuccessMessage(message_content = "", headline = "Erfolg") {
  return function(dispatch) {
    dispatch({
      type: "SET_USER_MESSAGE",
      res: displaySuccessMessage(
        message_content, // message
        headline
      )
    });
  };
}

/* Messages */
const displayErrorMessage = (
  message = "Ihre Aktion konnte nicht ausgeführt werden.",
  headline = "Fehler",
  url = null,
  automatic_redirect = false,
  modal = false,
  primaryBtnText = "OK",
  secondaryBtnText = "Abbrechen",
  allowToggle = false,
  successIcon = false,
  infoIcon = false,
  type = "danger",
  secondaryBtnFunction = null
) => {
  return {
    type: type,
    headline: headline,
    message: message,
    url: url,
    automatic_redirect: automatic_redirect,
    modal: modal,
    primaryBtnText: primaryBtnText,
    secondaryBtnText: secondaryBtnText,
    successIcon: successIcon,
    infoIcon: infoIcon,
    allowToggle: allowToggle,
    secondaryBtnFunction: secondaryBtnFunction
  };
};

const displaySuccessMessage = (
  message = "Ihre Aktion wurde erfolgreich ausgeführt.",
  headline = "Erfolg",
  url = null,
  automatic_redirect = false,
  modal = null,
  primaryBtnText = "OK",
  secondaryBtnText = "Abbrechen",
  allowToggle = false,
  successIcon = true,
  infoIcon = false,
  secondaryBtnFunction = null,
  type = "success"
) => {
  return {
    type: type,
    headline: headline,
    message: message,
    url: url,
    automatic_redirect: automatic_redirect,
    modal: modal,
    primaryBtnText: primaryBtnText,
    secondaryBtnText: secondaryBtnText,
    successIcon: successIcon,
    infoIcon: infoIcon,
    allowToggle: allowToggle,
    secondaryBtnFunction: secondaryBtnFunction
  };
};

export function resetNotification() {
  return function(dispatch) {
    dispatch({ type: "SET_USER_MESSAGE", res: null });
  };
}

/* Redirects */
export function resetRedirect() {
  return function(dispatch) {
    dispatch({ type: "REDIRECT_USER", res: false });
  };
}

/* ComponentFeedback (end loading animations etc.) */
export function resetComponentFeedback() {
  return function(dispatch) {
    dispatch({ type: "REQUEST_FINISHED", res: null });
  };
}

export function redirect(url) {
  return function(dispatch) {
    if (validateVar(url)) {
      dispatch({
        type: "SET_USER_MESSAGE",
        res: displaySuccessMessage(
          "", // content
          "", //headline
          url, // url
          true, //automatic redirect
          false //modal
        )
      });
    } else {
      dispatch({
        type: "SET_USER_MESSAGE",
        res: displayErrorMessage(
          "Beim Weiterleiten ist ein Fehler aufgetreten. (Fehlercode: " +
            ERROR_CODE.RedirectError +
            ")", // content
          "Fehler", //headline
          null, // url
          false, //automatic redirect
          true, //modal
          null, // primaryBtnText
          "Zurück", // secondaryBtnText

          true, //allowToggle (user can close modal without pressing a btn)
          false, // successIcon
          false, // infoIcon
          "danger" //type - danger/success
        )
      });
    }
  };
}
// ---------------------- IBAN --------------------------
export function validateIban(iban) {
  return function(dispatch) {
    Iban.validateIban(iban).then(res => {
      dispatch({ type: "VALIDATE_IBAN", res: res });
    });
  };
}

// -------------------------------- reset -----------------------
export function resetEmployee() {
  return function(dispatch) {
    dispatch({ type: "EMPLOYEE_RESET", res: null });
  };
}

export function resetFiles() {
  return function(dispatch) {
    dispatch({ type: "RESET_FILES", res: null });
  };
}
/*
 * Start PriceStrategy
 *
 * */

export {
  setListAttributes,
  setPriceStrategy,
  setPriceStrategyGlobal,
  sortBranches,
  resetPriceStrategy,
  initAttributs,
  getGlobalTreeBySupplier,
  getTreeIdByGetTariff,
  initPriceStrategy,
  submitNewTree,
  updateEditedTree,
  redirectUser,
  deleteOldGlobalAttributes,
  resetCleanTrees
} from "./pricestrategy";
