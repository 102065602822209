import React, { Component } from 'react';
import {socketAttribute, solvencyAttribute, tree_operators} from "../../../Functions/global";
import InputHandler from "../../../Inputs/InputHandler";
import {Row, Col, Tooltip} from "reactstrap";
import is_true from "../../../Functions/is_true";
import validateVar from "../../../Functions/validation/validateVariable";
import handleFormatNumber from "../../../Functions/handleFormatNumber";
import remove_last_char from "../../../Functions/remove_last_char";
import is_false from "../../../Functions/is_false";
import GreyButton from "../../../Displays/Buttons/GreyButton";
import handleFormatDateForFrontend from "../../../Functions/handleFormatDateForFrontend";
import cn from 'classnames';
import get_standardised_date from "../../../Functions/get_standardised_date";

class Conditions extends Component {
    constructor(props) {
        super(props);
        let date_min = null;
        let date_max = null;
        if (props.condition.type === "date") {
            date_min = this.get_min_max_date(props.attribute.range_min);
            date_max = this.get_min_max_date(props.attribute.range_max);
        }

        let price_info =  {
            default_unit_price: "ct/kWh",
            default_price_decimals: "3",
            price_name: "price_display",
        };
        if (socketAttribute.includes(props.attribute.attribute_id) && props.attribute.attribute_id !== solvencyAttribute) {
            price_info.default_unit_price = "€/Monat";
            price_info.default_price_decimals = "2";
            price_info.price_name =  "base_price_display";
        }
        let is_edit_able = ((is_true(props.is_global_edit_able) && is_true(props.is_global)) || (is_true(props.is_global_page)));
        this.state = {
            is_loaded: false,
            is_edit_able: is_edit_able,
            condition: props.condition,
            condition_tool_tipp: false,
            comparison_value_2_display_tooltipp: false,
            comparison_value_1_display_tooltipp: false,
            condition_tool_tipp_value: "",
            date_max: date_max,
            date_min: date_min,
            price_info: price_info,
            attribute: props.attribute
        };
        this.unique_condition_id = props.unique_condition_id;
    }


    componentDidUpdate(prevProps) {
        let {attribute, condition, is_global_edit_able, is_global} = this.props;
        if (validateVar(attribute) && attribute !== prevProps.attribute) {
            this.handle_condition_update(attribute, condition);
        }

        if (is_global_edit_able !== prevProps.is_global_edit_able) {
                this.props.set_text_form(true);
                this.setState({
                    is_edit_able: (is_true(is_global_edit_able) && is_true(is_global))
                })
        }
    }

    handle_condition_update = (attribute, condition) => {
        let {price_info} = this.state;
        price_info.default_unit_price  = "ct/kWh";
        price_info.default_price_decimals  = "3";
        price_info.price_name =  "price_display";
        if (socketAttribute.includes(attribute.attribute_id) && attribute.attribute_id !== solvencyAttribute) {
            price_info.default_unit_price = "€/Monat";
            price_info.default_price_decimals = "2";
            price_info.price_name =  "base_price_display";
        }
        this.setState({
            attribute: attribute,
            price_info: price_info,
            condition: condition,
        });
    };

    handle_on_change_validation = (name, value = null, attribute) => { // todo auslagern
        let {type} = attribute;
        let return_value = value;
        if ((validateVar(value) && type === "int")) {
            let val_with_out_dots = value.replace(/\./g, ""); //remove "." which was causing and error
            return_value = this.check_for_min_max_values(name, val_with_out_dots, return_value, attribute);
            return_value = handleFormatNumber(return_value)
        } else if (validateVar(value) && type.includes("float")) {
            let val_with_out_comma = value.replace(",", "."); //remove "." which was causing and error
            return_value = this.check_for_min_max_values(name, val_with_out_comma, return_value, attribute);
        } else if ((validateVar(value) && type === "string")) {
            return_value = this.check_for_min_max_values(name, return_value, return_value, attribute);
        }
        return return_value;
    };

    get_min_max_date = (date_input, min_date_value_2 = null) =>  {
        let return_value = null;
        if (validateVar(min_date_value_2)) {
            min_date_value_2 = new Date(get_standardised_date(min_date_value_2));
            return min_date_value_2.setDate(min_date_value_2.getDate() + 1)
        }else if (date_input.includes("(") || date_input.includes(")")) {
            let splitted_date = date_input.split("-");
            for (let date_part of splitted_date) {
                let current_date = new Date();
                if (date_part.includes("Y"))  {
                    let current_year = current_date.getFullYear();
                    let value = this.remove_all_non_numeric_values(date_part);
                    let year = this.get_new_date_result(date_part, current_year, value);
                    let date_api_format =  year + "-" + splitted_date[1] + "-" + splitted_date[2];
                    return new Date(date_api_format);
                } else if(date_part.includes("m")) {
                    // todo
                } else if (date_part.includes("d")) {
                    // todo
                }
            }
            return_value = new Date();
            return_value = return_value.setMonth(return_value.getMonth()+12);
            return new Date(return_value);
        } else if (date_input === "NOW") {
            return new Date();
        } else {
            return_value = new Date(date_input);
            return return_value;
        }
    };

    get_new_date_result = (date_part, current_year, value) =>  {
        if (date_part.includes("+")) return +current_year + +value;
        else if (date_part.includes("-")) return  current_year - value;
        else return current_year;
    };

    remove_all_non_numeric_values = (string) => {
        return string.replace(/\D/g,'');
    };

    check_for_min_max_values = (name, value, return_value, attribute) => {
        let {range_min, range_min_display, range_max, range_max_display, type, unit} = attribute;
        if (!validateVar(unit)) unit = "";
        if (type !== "date")  {
            if (validateVar(range_max)) {
                if (+value > +range_max ) {
                    return_value = remove_last_char(return_value);
                    this.show_tool_tipp(name, "Der Wert darf maximal " + range_max_display + " " + unit + " betragen.");
                }
            }
            if (validateVar(range_min))  {
                if (+value < +range_min) {
                    return_value = remove_last_char(return_value);
                    this.show_tool_tipp(name, "Der Wert muss größer " + range_min_display + " " + unit + " betragen.");
                }
            }
        }
        return return_value;
    };

   display_value_to_api_value = (type, value, name) => {
       if (type === "int") {
           value = value.replace(/\./g, ""); //remove "." which was causing and error
       } else if (type === "date" && name !== 'price_display') {
            if(validateVar(value)) value = new Date(value).getTime();
       } else if (type.includes("float")) {
            value = value.replace(",", ".");
       }
       return value;
    };


    on_blur = (name, value) => {
        let {condition} = this.state;
        if(validateVar(value) && condition.type === 'date' && name !== 'price_display') condition[name] = handleFormatDateForFrontend(value);
        else condition[name] = value;
        if (name.includes("display")) {
            let non_display_name = name.substring(0, name.length - 8); //"_display" has 8 chars
            let condition_type = (non_display_name === "price" || non_display_name === "base_price") ? "float" : condition.type;
            condition[non_display_name] = this.display_value_to_api_value(condition_type, value, name);
        }
        this.props.on_blur_branch(condition);
    };

    on_change_default = (name, value) => {
        let {condition, price_info, attribute} = this.state;
        if ((validateVar(attribute.type) && attribute.type === "multiselect" && name !== price_info.price_name) || (attribute.type === "date" && name !== price_info.price_name)) { // on change is on blur if its a multiselect
            this.on_blur(name, value);
        } else {
            let on_change_validation = ["comparison_value_1_display", "comparison_value_2_display"];
            if (validateVar(attribute.type) && on_change_validation.includes(name)) {
                value = this.handle_on_change_validation(name, value, attribute);
            }
            condition[name] = value;
            this.setState({condition: condition})
        }
    };

    on_change_operator = (name, value) => {
        this.props.edit_branch_operator(value); // needs to be changed in reducer so the other option fields can change as well if needed ("Greater Equal can only be used once for example -> check in reducer if needs to be hidden)
    };

    show_tool_tipp = (name, value) => {
        let tooltipp_name = name +"_tooltipp";
        let {condition_tool_tipp} = this.state;
        if (!condition_tool_tipp) {
            this.setState({
                [tooltipp_name]: true,
                condition_tool_tipp_value: (validateVar(value)) ? value : "Bitte geben Sie einen validen Wert an",
            });
            this.reset_tool_tipp(tooltipp_name);
        }
    };

    reset_tool_tipp = (tooltipp_name) => {
        setTimeout(function(){
            this.setState({
                [tooltipp_name]: false
            })
        }.bind(this), 4000);
    };

    get_input_field_by_attribute_type = (name) => {
        let {options} = this.props;
        let {condition, condition_tool_tipp_value, date_max, date_min, attribute} = this.state;
        let local_unique_condition_id = this.unique_condition_id + "_" + name;
        let tool_tipp_name = name + "_tooltipp";
        let tool_tipp_state = this.state[tool_tipp_name];

        let name_for_value = name; //dates value for the Input Component should be timestamp since the display date value cannot be converted with new Date() to valid date
        if(condition.type === 'date') {
            //set the min date of the second value of an AND branch to the value from first field + 1 day
            if(name === 'comparison_value_2_display' && validateVar(condition.comparison_value_1)) {
                date_min = this.get_min_max_date(this.props.attribute.range_min, condition.comparison_value_1);
            }
            name_for_value = name.substring(0, name.length - 8);
        }

        return <>
            <InputHandler
                id={local_unique_condition_id}
                type={attribute.type_input_field}
                name={name}
                value={condition[name_for_value]}
                error_state={condition.has_error}
                on_change={this.on_change_default}
                on_blur={this.on_blur}
                input_group_text={attribute.unit}
                function_name={(attribute.validation_class) ? attribute.validation_class : null}
                function_params={"1"}
                max_date={date_max}
                min_date={date_min}
                default_empty={true} //date is empty as first default value
                multiselect_options={options}
                is_loaded={true}
                no_margin
                no_padding
            />
            {(attribute.type_input_field !== "multiselect") && <Tooltip trigger='hover' placement='top' isOpen={tool_tipp_state} target={local_unique_condition_id} >
                {condition_tool_tipp_value}
            </Tooltip>}
        </>
    };

    get_condition_action_btns =() => {
        const {show_add, is_global, attribute, is_global_page} = this.props;
        const {condition, is_edit_able} = this.state;
        let text_form_icon = (is_true(condition.text_form)) ? "fas fa-pen" : "fas fa-check";
        let hide_delete_btn = (is_true(is_edit_able) && attribute.branches.length === 1 && is_false(is_global_page)); // cant delete the last branch in editing mode (cant delete global criteria in tariff)
        return ((is_true(is_edit_able) && is_true(is_global)) || (!validateVar(is_global) || (is_false(is_global)))) ?  <>
            <GreyButton
                on_click={() => this.props.set_text_form(!condition.text_form)}
                value={<i className={text_form_icon}/>}
            />
            {(show_add && is_false(attribute.disable_add)) && <GreyButton
                on_click={() => this.props.add_branch()}
                value={<i className="fas fa-plus" />}
            />}
            {is_false(hide_delete_btn) && <GreyButton
                on_click={() => this.props.delete_branch()}
                value={<i className="fas fa-trash" />}
            />}
        </> : null;
    };

    get_price_input_feld =(condition) => {
        const {price_info} = this.state;
        if (is_true(condition.text_form)) {
            let price_value = (is_true(condition.is_base_price)) ? condition.base_price_display :  condition.price_display;
            let number_sign = (condition.price >= 0) ? "+" : "";
            return number_sign + " " + price_value;
        } else {
            return <InputHandler
                type={"text"}
                name={price_info.price_name}
                id={this.unique_condition_id + "_price"}
                value={condition[price_info.price_name]}
                prev_value={condition[price_info.price_name]}
                on_change={this.on_change_default}
                error_state={condition.has_error_price}
                function_name={"getValidPriceRate"}
                function_params={[condition[price_info.price_name], price_info.default_price_decimals]}
                input_group_text={price_info.default_unit_price}
                on_blur={this.on_blur}
                no_margin
                no_padding
            />
        }
    };

    get_operator_select_field =(condition) => {
        let comparison_operator = condition.comparison_operator;
        let {valid_operators} = this.props;
        if (condition.type === "multiselect") { // since OR is not what we want make it EQ // todo check if this causes an error in calculation (BE)
            comparison_operator = "EQ";
        }
        return <InputHandler
                type={"select"}
                select_options={valid_operators}
                id={this.unique_condition_id + "_operator"}
                name={"comparison_operator"}
                on_change={this.on_change_operator}
                value={comparison_operator}
                no_margin
                onBlur={() => console.log("blur")}
                no_padding
                remove_empty_option
        />
    };



    multiselect_key_to_value = (condition) => {
        let return_string_value1 = "";
        let return_string_value2 = "";

        if (validateVar(condition.comparison_value_1_display) && condition.comparison_value_1_display.length > 0)  {
            return_string_value1 = this.get_string_from_loop(condition.comparison_value_1_display)
        }

        if (validateVar(condition.comparison_value_2_display) && condition.comparison_value_2_display.length > 0)  {
            return_string_value2 = this.get_string_from_loop(condition.comparison_value_2_display)
        }
        return {
            return_string_value1: return_string_value1,
            return_string_value2: return_string_value2
        }
    };

    get_string_from_loop = (loop_values) => {
        let {attribute} = this.state;
        let return_string = "";
        let value_len = loop_values.length;
        for (let index in loop_values) {
            if (loop_values.hasOwnProperty(index)) {
                let value = loop_values[index];
                let current_value_object = attribute.options.find(x => x.value === value);
                let label = (validateVar(current_value_object) && validateVar(current_value_object.label)) ? current_value_object.label : "";
                if (validateVar(return_string)) {
                    let fill_value = (+value_len === +index || (!validateVar(current_value_object))) ? "" : ", ";
                    return_string = return_string + fill_value + label;
                }
                else return_string = label;
            }
        }
        return return_string;
    };

    get_text_branch_in_text_form = () => {
        let {condition, price_info, attribute} = this.state;
        let unit = price_info.default_unit_price;
        let operator_obj = tree_operators.find(x => x.key === condition.comparison_operator);
        let operator_value = (validateVar(operator_obj) && validateVar(operator_obj.value)) ? operator_obj.value : "";
        let attribute_unit = (validateVar(attribute.unit)) ? attribute.unit : "";
        let display_value_1 = condition.comparison_value_1_display;
        let display_value_2 = condition.comparison_value_2_display;
        if (validateVar(attribute.type) && attribute.type === "multiselect") {
           let return_string_obj = this.multiselect_key_to_value(condition);
            display_value_1 = return_string_obj.return_string_value1;
            display_value_2 = return_string_obj.return_string_value2;
        }
        if (condition.comparison_operator === "AND") {
            return <span>{this.get_price_input_feld(condition) + " " + unit + " wenn "} <u>{attribute.name}</u> {" " + operator_value + " "} <b>{display_value_1 + " " +attribute_unit}</b> {" und "} <b>{display_value_2 + " "+ attribute_unit}</b> {" liegt"}</span>;
        }
        else {
            return <span>{this.get_price_input_feld(condition) + " " + unit + " wenn "} <u>{attribute.name}</u> {" " + operator_value + " "} <b>{display_value_1 + " " + attribute_unit}</b></span>;
        }
    };

    get_render_content_text_form = () => {
        let {condition} = this.state;
        return <Row className={"textform"}>
            <Col sm={7} md={8} lg={9} xl={10}>
                <div className={cn('branch', {'alert-border': condition.has_error || condition.has_error_price})}>
                {this.get_text_branch_in_text_form()}
                </div>
            </Col>
            <Col sm={5} md={4} lg={3} xl={2}>
                <div className={"tree-buttons"}>
                {this.get_condition_action_btns()}
                </div>
            </Col>
        </Row>;
    };

    create_branch_div = (input) => {
        return <div className={"branch"}>
            {input}
        </div>
    };

    get_render_content = () => {
        let {condition} = this.state;
        let content = <><Row>
            <Col md={6} lg={"3"} xl={3}>
                {this.create_branch_div(this.get_operator_select_field(condition))}
            </Col>
            <Col md={6} lg={"3"} xl={4}>
                {this.create_branch_div(this.get_input_field_by_attribute_type('comparison_value_1_display'))}
            </Col>
            <Col md={6} lg={"3"} xl={3}>
                {this.create_branch_div(this.get_price_input_feld(condition))}
            </Col>
            <Col md={6} lg={"3"} xl={2}>
                <div className={"tree-buttons"}>
                    {this.get_condition_action_btns()}
                </div>
            </Col>
        </Row>
            {this.render_tool_tip}
        </>;
        if (condition.comparison_operator === "AND") {
            content = <Row>
                <Col md={"3"}>
                    {this.create_branch_div(this.get_operator_select_field(condition.comparison_operator))}
                </Col>
                <Col md={"2"}>
                    {this.create_branch_div(this.get_input_field_by_attribute_type('comparison_value_1_display'))}
                </Col>
                <Col md={"2"}>
                    {this.create_branch_div(this.get_input_field_by_attribute_type('comparison_value_2_display'))}
                </Col>
                <Col md={"3"}>
                    {this.create_branch_div(this.get_price_input_feld(condition))}
                </Col>
                <Col md={"2"}>
                    <div className={"tree-buttons"}>
                        {this.get_condition_action_btns()}
                    </div>
                </Col>
            </Row>;
        }
        return content;
    };

    render() {
       const {condition} = this.state;
       return (is_true(condition.text_form)) ?
           <>{this.get_render_content_text_form()}</> :
           <>{this.get_render_content()}
           </>;
    }
}

export default Conditions;