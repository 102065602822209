/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AuthHeader from "../../../module_components/Displays/Headers/AuthHeader";
import {ContractsPath, DashboardPath, PasswordRecoveryPath} from "../../../globalNavigation";
import {deHashVar} from "../../../module_components/Functions/handleHashedVariable";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import {initializeIntegrations, loginSupplier, resetEmployee, redirect} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import isValidUserRole from "../../../module_components/Functions/validation/isValidUserRole";
import validateInteger from "../../../module_components/Functions/validation/validateInteger";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import Validation from "../../../module_components/Objects/Components/validation";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled_submit: false,
            loading: false,
            user_role: "",
            form_data: {
                email: '',
                password: ''
            },
            error_messages: {}
        };
    }

    componentWillUnmount() {
        document.body.classList.remove("eless-bg");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let integration = this.props.integration;
        if (integration !== prevProps.integration && validateVar(integration)) {
            window.location.href = ContractsPath;
        }

        let employee = this.props.employee;
        if (validateVar(employee) && employee !== prevProps.employee) {
            if (employee === "ERR") {
                this.props.resetEmployee();
                this.setState({
                    disabled_submit: false,
                    loading: false
                });
            }

        }
    }

    componentDidMount() {
        document.body.classList.add("eless-bg");
        //check if automatic login is activated and if yes check if we have a hashed id we can match data to
        var storedID = deHashVar(localStorage.getItem('evuSecurityToken'));
        var userRole = deHashVar(localStorage.getItem('evuUserAccount'));
        var employeeSecurityToken = deHashVar(localStorage.getItem('employeeSecurityToken'));
        if ((validateVar(storedID) && validateInteger(storedID)) && (validateVar(employeeSecurityToken) && validateInteger(employeeSecurityToken)) && isValidUserRole(userRole)) { // if no id is found for the session check localStorage for data
            this.props.redirect(DashboardPath);
        }
    }

    handle_key_press = (e) => {//Allow submit on "Enter" press
        if (e.key === 'Enter') {
            this.handle_submit_login();
        }
    };

    go_to_password_recovery = () => {
        this.props.redirect(PasswordRecoveryPath)
    };

    change_form_data = (name, value) => {
        let {form_data} = this.state;
        form_data[name] = value;
        this.setState({form_data: form_data});
    };

    handle_submit_login() {
        const {form_data} = this.state;

        // validation start
        // define special cases for error validation
        let special_cases = {
            email: {case_name: "email"},
            password: {case_name: "password"},
        };
        // call abstract error validation and set values in state
        let validation_result = new Validation(form_data, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        if(!validation_result.has_error){
            this.setState({loading: true, disabled_submit: true});
            this.props.loginSupplier(form_data.email, form_data.password);
        }
    }

    render() {
        const {disabled_submit, loading, form_data, error_messages} = this.state;
        const {password, email} = form_data;

        return (
            <div className="main-content" ref="mainContent">
                <AuthHeader title="Willkommen im e.hub!"
                    lead="Melden Sie sich hier an, um Zugriff auf Ihren Account zu erhalten."/>
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <small>Bitte melden Sie sich an.</small>
                                    </div>
                                    <InputHandler
                                        type='text'
                                        name='email'
                                        value={email}
                                        placeholder='E-Mail'
                                        on_change={this.change_form_data}
                                        prefix_addon_class='email-83'
                                        on_key_down={this.handle_key_press}
                                        error_state={error_messages.email_error}
                                    />
                                    <InputHandler
                                        type='password'
                                        name='password'
                                        value={password}
                                        placeholder='Passwort'
                                        on_change={this.change_form_data}
                                        prefix_addon_class='lock-circle-open'
                                        on_key_down={this.handle_key_press}
                                        error_state={error_messages.password_error}
                                    />
                                    <div className="text-center">
                                        <PrimaryButton onClick={(e) => this.handle_submit_login(e)}
                                           loading={loading}
                                           id='submitLogin'
                                           disabled={disabled_submit}
                                           value='Login'
                                           classes='btn-modal w-100'/>
                                    </div>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">
                                <Col xs="6">
                                    <span className="text-light cursor-pointer"
                                            onClick={(e) => this.go_to_password_recovery(e)} >
                                        <small>Passwort vergessen?</small>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


let mapStateToProps = function (state) {
    return {
        integration: state.integration,
        employee: state.employee
    }
};

let mapDispatchToProps = {
    initializeIntegrations: initializeIntegrations,
    loginSupplier: loginSupplier,
    resetEmployee: resetEmployee,
    redirect: redirect
};

let LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;

