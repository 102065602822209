import React from 'react';

class ButtonLoadingAnimation extends React.Component {
    render(){
        return(
            <div className="fa-1x button-loading d-block">
                <i className="fas fa-circle-notch fa-spin" />
            </div>
        );
    }
}

export default ButtonLoadingAnimation;