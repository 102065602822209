import validateVar from "../Functions/validation/validateVariable";
import callFunctionWithString from "../Functions/callFunctionWithString";
import Contract from "../Objects/contract";
import getAgencyID from "../Functions/getAgencyID";

let initialState = null;

function contract_single(state = initialState, action) {
  if (action.type === "INITIALIZE_CONTRACT_EHUB") {
    return callFunctionWithString("combine_states", [
      action.res,
      state,
      "contracts"
    ]);
  } else if (action.type === "INITIALIZE_CONTRACT") {
    return action.res;
  }else if (action.type === "DOWNLOAD_CONTRACTS") {
    let download_ids_array = action.ids;
    let data = getAgencyID({});
    let contract_class = new Contract(null);
    for (let id of download_ids_array) {
      data.contract_id = id;
      contract_class.download(data);
    }

    // let ContractClass = new Contract().download();
    // console.log("statees in reducer", state);
    // for (let contract of state.contracts) {
    //   if (download_ids_array.includes(contract.id)) {
    //     data.contract_id = contract.id;
    //     ContractClass.download(data);
    //   }
    // }
    return state;
  } else if (action.type === "DELETE_CONTRACT") {
    let c = [];
    for (let contract of state) {
      if (contract.contract_id !== action.id) c.push(contract);
      else {
        contract.delete().then(res => {
          if (res.status !== "ok") alert("Fehler: " + res.result);
        });
      }
    }
    return c;
  } else if (action.type === "GENERATE_CONTRACT"){
    return action.res;
  } else if (action.type === "RESET_CONTRACT"){
    return action.res;
  } else if (action.type === "CREATE_CONTRACT"){
    return action.res;
  } else if (action.type === "GET_SINGLE_CONTRACT") {
    return action.res;
  } else if (action.type === "MODIFY_CONTRACT") {
    return action.res;
  } else if (action.type === "UPLOAD_SIGNED_CONTRACT_CLEARING") {
    let new_state = { ...state };

    let new_history_index = action.res.history.length - 1;
    if (
      validateVar(action.res) &&
      validateVar(action.res.history) &&
      validateVar(action.res.history[new_history_index])
    ) {
      let history_obj = action.res.history[new_history_index];

      if (validateVar(history_obj.edited_data)) {
        let value = validateVar(history_obj.edited_data.signed_id)
          ? history_obj.edited_data.signed_id
          : null;
        if (!validateVar(value)) return return_init_state();

        let new_signed_history = {
          value: value,
          agency_id: history_obj.requesting_agency,
          employee_id: history_obj.requesting_employee,
          history_id: history_obj.id,
          status: history_obj.status,
          reference_id: history_obj.reference_id,
          history_type: "contract"
        };
        new_state.history.signed_id = new_signed_history;
      }
    }
    return return_init_state(new_state);
  } else {
    return state;
  }
}

function return_init_state(state = initialState) {
  return state;
}

export default contract_single;
