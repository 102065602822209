let initialState = null;

function message_feedback(state = initialState, action) {
  if (action.type === "SET_USER_MESSAGE") {
    return action.res;
  } else if (action.type === "RESET_USER_MESSAGE") {
    return action.res;
  } else {
    return state;
  }
}

export default message_feedback;
