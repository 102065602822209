import {emptyValue} from "./global";
import validateTime from "./validation/validateTime";
import validateVar from "./validation/validateVariable";
import get_standardised_date from "./get_standardised_date";

function getGermanDateFormat(date, get_time = false){
    date = date + "";
    if (!validateVar(date)) return emptyValue; //if date is not existing return default val
    let dateSplitt = date.split(" "); //check for time included in date and seperate it
    if(dateSplitt.length > 1)  date = dateSplitt[0]; // only use date

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // german format: 01.01.1970 options
    date = new Date(get_standardised_date(date));
    let resDate =  date.toLocaleDateString('de-DE', options); //create format
    if (resDate === "01.01.1970") return emptyValue; // if error occured return default val
    if (validateVar(get_time) && get_time == true && validateVar(dateSplitt[1])) {
        let time = dateSplitt[1];
        let timeSplitt = time.split(":");
        if(timeSplitt.length === 1) time = timeSplitt[0] +":00"; //add minutes
        else if(timeSplitt.length > 2) time = timeSplitt[0] +":"+ timeSplitt[1]; //remove seconds
        if (validateTime(time)) return resDate +" "+ time +" Uhr";
        return emptyValue;
    }

    return resDate;
}
export default getGermanDateFormat;


