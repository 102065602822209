import regexCheck from "./regexCheck";
import {numeric_and_comma_regex} from "../global";
import validateVar from "./validateVariable";
import remove_last_char from "../remove_last_char";
import handleFormatNumber from "../handleFormatNumber";
import is_true from "../is_true";

function validateFloatOnChange(float, max_after_comma_decimals, formatNumber = false) {
    if (validateVar(float)) {
        float = float + "";
        if (float.includes(".")) float = float.replace(".", ",");
        let last_char = float.substr(-1); //get last character to check user input
        let first_char = float.substr(0, 1); //get last character to check user input
        if (first_char === ",") {
            float = remove_last_char(float);
        }
        else if (!regexCheck(last_char, numeric_and_comma_regex)) { //check if value is invalid and
            float = remove_last_char(float)
        } else {
            let comma_count = (validateVar(float.match(/,/g))) ? float.match(/,/g).length : 0; //get comma count (only 1 comma allowed)
            if (comma_count > 1) {
                float = remove_last_char(float);
            }
            if (comma_count > 0) {
                let splitted = float.split(",");
                let after_comma_decimals = splitted[1];
                if (after_comma_decimals.length > max_after_comma_decimals) {
                    float = splitted[0] + ',' + splitted[1].substring(0, max_after_comma_decimals);
                }
            }
        }

        if(is_true(formatNumber)){
            if(float.includes(',')){
                let splitted = float.split(",");
                float = handleFormatNumber(splitted[0]) + ',' +splitted[1];
            }else float = handleFormatNumber(float);
        }
    }
    return float;
}

export default validateFloatOnChange;
