import { deHashVar } from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import {
    apiError,
    apiFunctionListPartners,
    apiFunctionUpdatePartners,
    apiPlatformService
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
const consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));

class Supplier {
  constructor(data, communication = undefined) {



      // this.c = new Communication(
      //     apiPlatformService,
      //     apiFunctionListPartners,
      //     apiFunctionUpdatePartners,
      //     "",
      //     "",
      //     "",
      //     {}
      // );


      let communication_params = {
          service: apiPlatformService,
          get_function: apiFunctionListPartners,
          update_function: apiFunctionUpdatePartners,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      this.c = getCommunicationClass(communication_params);


      //basic
      this.value = "";
      this.label = "";
      this.accepted = 0;

      //load basic info
      if (validateVar(data)  && validateVar(data.supplier_id)) {
          //if data found
          this.value = typeof data.supplier_id === "undefined" ? "" : data.supplier_id + "";
          this.accepted = typeof data.accepted === "undefined" ? "" : data.accepted + "";
          this.label =
              typeof data.supplier === "undefined"
                  ? ""
                  : data.supplier;
      }
  }

  /*
        Instantiate a new Customer with its ID as Param
        Call Communication Class with matching global defined Vars for API Request
        return new Customer Clnass
     */
  static async getAllSupplier() {

    // this.c = new Communication(
    //   apiPlatformService,
    //   apiFunctionListPartners,
    //     apiFunctionUpdatePartners,
    //   "",
    //   "",
    //   "",
    //   {consultant_id: consultant_id}
    // );
      let params = getAgencyID({});

      let communication_params = {
          service: apiPlatformService,
          get_function: apiFunctionListPartners,
          update_function: apiFunctionUpdatePartners,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      this.c = getCommunicationClass(communication_params);
    let res = await this.c.load();

    if (res.status === "ok") {
        let supplier_list = [];
        res.result.map((result) => {
            if (validateVar(result)) {
                supplier_list.push(new Supplier(result));
            }
        });
        return supplier_list;
    }
    else return apiError;
  }


    static async update_partners(params) {
        params.consultant_id =  consultant_id;

        params = getAgencyID(params);
        // this.c = new Communication(
        //     apiPlatformService,
        //     apiFunctionListPartners,
        //     apiFunctionUpdatePartners,
        //     "",
        //     "",
        //     "",
        //     {}
        // );

        let communication_params = {
            service: apiPlatformService,
            get_function: "",
            update_function: apiFunctionUpdatePartners,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        this.c = getCommunicationClass(communication_params);


        let res = await this.c.update(params);

        if (res.status === "ok") {
            let supplier_list = [];
            res.result.map((result) => {
                if (validateVar(result)) {
                    supplier_list.push(new Supplier(result));
                }
            });
            return supplier_list;
        }
        else return apiError;
    }

}

export default Supplier;
