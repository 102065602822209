import React from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Modal,
} from "reactstrap";
import PrimaryButton from "../Displays/Buttons/PrimaryButton";
import validateVar from "../Functions/validation/validateVariable";
import Validation from "../Objects/Components/validation";
import isEmptyObject from "../Functions/isEmptyObject";
import Collapse from "reactstrap/lib/Collapse";
import DynamicInputFromHandler from "../Inputs/DynamicInputFromHandler";
import callFunctionWithString from "../Functions/callFunctionWithString";
import is_true from "../Functions/is_true";
import checkDisplayRequirements from "../Functions/checkDisplayRequirements";
const valid_input_for_blur_and_focus = ["text"];
class DynamicInputFieldsModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            object_input_value: [],
            show_modal: false,
            object: {},
            error_messages: {}
        };
    };

    toggleModal = (input_object) => {
        const {object_data} = this.props;
        this.setState({
            loading: false,
            show_modal: !this.state.show_modal,
            object: object_data,
            title: input_object.title,
            object_input_values: input_object.content,
            api_call: input_object.api_call
        });
    };

    turnOffModal = () => {
        this.setState({loading: false, show_modal: false});
    };

    upload_file = (field_name, file) => {
        let {object} = this.state;
        object[field_name] = file.name;
        this.setState({object: object});

        this.props.upload_file(field_name, file);
    };

    show_input_in_modal(object_input_value){
        return validateVar(object_input_value.modal_input) && object_input_value.modal_input && !validateVar(object_input_value.display_requirements);
    };

    check_if_field_is_optional = (field_to_check, fields, data) => { //this function might need an update if the dependency_fields need to be check if they fulfull the display_requirements
        //if value of the field is empty, check if its set to optional or if the dependencies to be optional are fulfielld
        if(validateVar(field_to_check) && validateVar(field_to_check.optional)){
            if(typeof field_to_check.optional === 'object'){ //loop thorugh dependencies for the field required to be optional
                let field_is_optional = true;
                let optional = field_to_check.optional;
                for(let index in optional.dependency_fields){
                    if(optional.dependency_fields.hasOwnProperty(index)){
                        //if value of the dependency field does not match the requirement => optional = false
                        if(optional.values[index] !== data[optional.dependency_fields[index]]) field_is_optional = false;
                    }
                }
                return field_is_optional;
            }else if(is_true(field_to_check.optional)) return true;
        } else return false;
    };

    on_change = (name, value) => {
        let {object} = this.state;
        object[name] = value;
        this.setState(object);
    };

    submit = () => {
        let {object, object_input_values, api_call} = this.state;
        let submitted_params = {};
        let submitted_params_validate = {};
        let validation_special_cases = {};
        for (let field of object_input_values) {
            let field_name = validateVar(field.modal_name) ? field.modal_name : field.name;
            let value = (validateVar(field.api_function)) ? callFunctionWithString(field.api_function, [object[field_name]]) : object[field_name];

            submitted_params[field_name] = {
                value: value,
                api_name: field.api_name,
                api_sub_structure: field.api_sub_structure
            };

            if(!validateVar(object[field_name])) object[field_name] = ''; //set null values to empty string to be able to check length on it
            if(this.check_if_field_is_optional(field, object_input_values, object) && object[field_name].length === 0) validation_special_cases[field_name] = {can_be_empty: true}
            if (validateVar(object[field_name]) && validateVar(field.validation)) validation_special_cases[field_name] = field.validation;
            if(is_true(field.modal_input)){
                if (validateVar(field.display_requirements)) { //only add the field to validation if its visible
                    if(checkDisplayRequirements(object, field)) submitted_params_validate[field_name] = object[field_name];
                } else submitted_params_validate[field_name] = object[field_name];
            }
        }

        // validation start
        // call abstract error validation and set values in state
        let validation_result = new Validation(submitted_params_validate, validation_special_cases);

        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        if (!validation_result.has_error) {
            this.props.onSubmit(submitted_params, api_call);
            this.setState({loading: true});
        }
    };
    //
    // check_if_collapse_is_open = (object, input_field) => {
    //     //display_requirement's value and field can be an array if multiple requirements must be met to open the collapse
    //     let fields = input_field.display_requirements.field;
    //     let values = input_field.display_requirements.value;
    //     let field_is_array = Array.isArray(fields);
    //     let value_is_array = Array.isArray(values);
    //
    //     if(field_is_array && value_is_array){
    //         for(let index in fields) {
    //             if(fields.hasOwnProperty(index) && values.hasOwnProperty(index)){
    //                 let field = fields[index];
    //                 let value = values[index];
    //
    //                 if (!validateVar(object[field])) { // if field is included in api object
    //                     object[field] = input_field.default_value;
    //                 }
    //
    //                 if(Array.isArray(value) && !value.includes(object[field])) return false;
    //                 else if(!Array.isArray(value) && object[field] !== value) return false;
    //             }
    //         }
    //     }else alert ('display_requirements value and field has to be an array');
    //
    //     return true;
    // };

    handle_on_click = (type, input_type, name, object) => {
        if (valid_input_for_blur_and_focus.includes(input_type)) {
            let name_tmp = name + "_tmp";
            let value = object[name];
            if (type === "blur") {
                if (!validateVar(object[name])) {
                    object[name] = this.state[name_tmp];
                    value = null;
                }
            } else if (type === "focus") {
                object[name] = "";
            }
            this.setState({
                object: object,
                [name_tmp]: value
            });
        }
    };



    render() {
        const {loading, title, object_input_values, object, show_modal, error_messages} = this.state;
        let collapses = []; //here will be all collapses to render
        let collapses_open = []; //here is the is_open variable beeing stored for each collapse
        let collapse_inputs = []; //this array is beeing filled with all input fields for one collapse
        let previous_collapse_index = null; //this variable tells if the collapse can be saved and a new collapse can start generating
        return (
            <Modal className="modal-dialog-centered"
                   size="md"
                   isOpen={show_modal}
                   toggle={() => this.turnOffModal()}
            >
                {!isEmptyObject(object) && <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent modal-title">
                            <Row>
                                <Col xs={10}>
                                    <h2 className='mb-0'>{title}</h2>
                                </Col>
                                <Col xs={2}>
                                    <button onClick={() => this.turnOffModal()} className={'close'}><i
                                        className={'ni ni-fat-remove close-button'}/></button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="px-lg-4 py-lg-4">
                            <div>
                                {object_input_values.map((object_input_value, index) => {
                                    //this function is rendering the input fields & preparing the collapse components for rendering
                                    let last_field = object_input_values.length-1 === index;//this variable tells when the last input was inserted & to save the collapse

                                    if (validateVar(object_input_value.display_requirements)) {
                                        let current_collapse_index = object_input_value.display_requirements.collapse_index;

                                        if(!validateVar(collapses[current_collapse_index])) collapses[current_collapse_index] = [];
                                        collapses[current_collapse_index].push(object_input_value);

                                        //check if current collapse should be open
                                        collapses_open[object_input_value.display_requirements.collapse_index] = checkDisplayRequirements(object, object_input_value, false);

                                        previous_collapse_index = object_input_value.display_requirements.collapse_index;
                                    }else if(last_field && collapse_inputs.length !== 0){ //in case the last input field is not a collapse input, save the last collapse object_input_values for render
                                        collapse_inputs.push(object_input_value);
                                        collapse_inputs = []; // this is probably not necessary but clean
                                    }

                                    let input_name = validateVar(object_input_value.modal_name) ? object_input_value.modal_name : object_input_value.name;
                                    if (object_input_value.type === "date") {
                                        object[input_name] = (validateVar(object[input_name])) ? new Date(object[input_name]) : (validateVar(object_input_value.default_value)) ? new Date(object[object_input_value.default_value]) : new Date() ;
                                    }
                                    //render input if its modal_input (true) and if its not inside a collapse (display_requirements)
                                    if (validateVar(object_input_value.modal_input) && object_input_value.modal_input && !validateVar(object_input_value.display_requirements)) {
                                        return <DynamicInputFromHandler input_field={object_input_value}
                                                                        on_change={this.on_change}
                                                                        upload_file={(name, value) => this.upload_file(name, value)}
                                                                        object={object}
                                                                        error_messages={error_messages}
                                                                        loading={loading}
                                                                        input_name={input_name}
                                                                        key={index}
                                                                        optional={this.check_if_field_is_optional(object_input_value, object_input_values, object)}
                                                                        on_focus={() => this.handle_on_click("focus", object_input_value.type, input_name, object)}
                                                                        on_blur={() => this.handle_on_click("blur", object_input_value.type, input_name, object)}
                                        />
                                    } else return null;
                                })}
                                {collapses.map((collapse_object_input_values, index) => {
                                    return <Collapse isOpen={collapses_open[index]}>
                                        {collapse_object_input_values.map((object_input_value, index) => {
                                            let input_name = validateVar(object_input_value.modal_name) ? object_input_value.modal_name : object_input_value.name;
                                            if(validateVar(object_input_value.modal_input) && object_input_value.modal_input){
                                                return <DynamicInputFromHandler input_field={object_input_value}
                                                                                on_change={this.on_change}
                                                                                upload_file={(name, value) => this.upload_file(name, value)}
                                                                                object={object}
                                                                                error_messages={error_messages}
                                                                                loading={loading}
                                                                                input_name={input_name}
                                                                                key={index}
                                                                                optional={this.check_if_field_is_optional(object_input_value, object_input_values, object)}
                                                                                on_focus={() => this.handle_on_click("focus",object_input_value.type, input_name, object)}
                                                                                on_blur={() => this.handle_on_click("blur",object_input_value.type, input_name, object)}
                                                />
                                            }else{
                                                return null;
                                            }
                                        })}
                                    </Collapse>
                                })}
                            </div>
                            <div className="text-center">
                                <PrimaryButton loading={loading} disabled={loading} onClick={() => this.submit()}
                                               value='Änderungen bestätigen'
                                               classes='btn-modal full-width-button mt-3'/>
                            </div>
                        </CardBody>
                    </Card>
                </div>}
            </Modal>
        );
    }
}

export default DynamicInputFieldsModal;
