function getUrlParam() {
  var vars = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
    vars[key] = decodeURIComponent(value);
  });
  return vars;
}

export default getUrlParam;
