import validateVar from "../Functions/validation/validateVariable";
import History from "./Components/history";
/* copied from ehub 16.02.2021 */
let reportng_vars = {
    tariff_type: null,
    consumption: null,
    is_spot: {
        function_name: "is_spot",
        params: ["%%tariff_type"]
    },
    price: {
        is_class_arr: "price",
        is_top_level: true
    },
    deltas: {
        is_class_arr: "deltas",
        is_top_level: true
    },


    ranking: null,
};

class Reporting {
  static default_values = reportng_vars;
  constructor(data, status = "open") {
    if (validateVar(data)) {
      this.history = {};
      // this.get_contract(contract_vars, data, data.history, []);
      let class_content = new History(reportng_vars, data, data.history, [], status);
      for (let name in class_content) {
        if (class_content.hasOwnProperty(name)) {
          this[name] = class_content[name];
        }
      }
    }
  }

  static dummy() {
    let output = {};
    output = this.build_dummy(reportng_vars);
    return output;
  }

  static build_dummy(vars) {
    let output = {};
    for (let index in vars) {
      if (vars.hasOwnProperty(index)) {
        if (!validateVar(vars[index])) {
          output[index] = "-/-";
        } else if (typeof vars[index] === "object") {
          if (validateVar(vars[index].is_sub_arr)) {
            output[index] = this.build_dummy(vars[index]);
          } else {
            output[index] = "-/-";
          }
        }
      }
    }
    return output;
  }
}

export default Reporting;
