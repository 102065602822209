import {encriptKey} from "./global";

const AES = require("../../../node_modules/crypto-js/aes");
const ENC = require("../../../node_modules/crypto-js/enc-utf8");

const key = encriptKey;

export function hashVar(variable) {
    return AES.encrypt(variable + "", key) //empty string for numberformat
}

export function deHashVar(variable) {
    if (variable !== null)
        return AES.decrypt(variable,  key).toString(ENC);
    return "";
}
