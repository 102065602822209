class Blob {
    static async createBlobFromURL(url) {
        let blob = await fetch(url).then(r => r.blob());
        return await await blob;
    }


    static async fileStreamToBlob(respBody) {
        var encodedFile = [];
        encodedFile.push(respBody);
        let file = new File(encodedFile, "filename.pdf", {type: "application/pdf"});
        return window.URL.createObjectURL(file);
    }
}

export default Blob;
