import React, {useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import TariffInformation from "./TariffPricedetailsTabs/TariffInformation";
import PriceInformation from "./TariffPricedetailsTabs/PriceInformation";
import ProviderInformation from "./TariffPricedetailsTabs/ProviderInformation";

function TariffPricedetailsPanel(props) {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    };
    return (
        <div className={'panel three-tabs show responsive-tabs'}>
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Allgemeine Informationen
                        </NavLink>
                    </NavItem>
                    <div className='tabs-separator' />
                    {!props.testCalculation && <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            Preisbestandteile und Gesamtkosten
                        </NavLink>
                    </NavItem>}
                    <div className='tabs-separator' />
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >
                            Über den Anbieter
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <TariffInformation
                            net_fee={props.net_fee}
                            offer={props.offer}
                            tariff={props.tariff}
                            tariff_information={props.tariff_information}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <PriceInformation
                            tariff_information={props.tariff_information}
                            tariff={props.tariff}
                            offer={props.offer}
                            net_fee={props.net_fee}
                        />
                    </TabPane>
                    <TabPane tabId="3">
                        <ProviderInformation tariff={props.tariff} />
                    </TabPane>
                </TabContent>
            </div>
        </div>
    )
}

export default TariffPricedetailsPanel;