import validateVar from "./validation/validateVariable";


export default function priceDiffColor(value) {
    if (validateVar(value)) {
        return (value+"".includes("-")) ? "red" : "green";
    }
    return "";
}

