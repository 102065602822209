import "whatwg-fetch";
import {
    apiBearer,
    apiCalculationManagementService, apiContentType, apiError,
    apiFunctiongetGetSupplier, apiURL,
    apiUser,
    apiUserAuthToken
} from "./global";
import { deHashVar } from "./handleHashedVariable";

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));
//set Global Tree ID for supplier
export default async function getSupplierInformation() {
  return await fetch(apiURL, {
    method: "POST",
    headers: {
      Authorization: apiBearer,
      "Content-Type": apiContentType
    },
    http_errors: false,
    body: JSON.stringify({
      user: apiUser,
      auth_token: apiUserAuthToken,
      service: apiCalculationManagementService,
      function: apiFunctiongetGetSupplier,
      param: {
        supplier_id: supplier_id
      }
    })
  })
    .then(res => res.json())
    .then(result => {
      if (result.status === "ok") {
        return result.result;
      } else {
        return apiError;
      }
    })
    .catch(rejected => {
      return apiError;
    });
}
