import validateVar from "../Functions/validation/validateVariable";

let initialState = null;

function consumption_point(state = initialState, action) {
  if (action.type === "INITIALIZE_CONSUMPTION_POINT") {
    return action.res;
  } else if (action.type === "UPDATE_CONSUMPTION_POINTS") {
    return action.res;
  } else if (action.type === "GET_CONSUMPTION_POINT") {
    return action.res;
  } else if (action.type === "GET_CONSUMPTION_POINTS_FOR_CONTRACT") {
    return action.cps;
  }else if (action.type === "CONSUMPTION_POINT_ERROR"){
    return action.res;
  }else if (action.type === "CONSUMPTION_POINT_RESET"){
    return action.res;
  }else if (action.type === "CONSUMPTION_POINT_GET_WATTLINE_INFO"){
    return action.res;
  }else if (action.type === "UPDATE_CONSUMPTION_POINTS_FOR_CONTRACT") {
    let updatedCp = action.res;
    let new_cps = [];

    for (let index in state) {
      if (state.hasOwnProperty(index)) {
        let tmp_cp = state[index];
        if (tmp_cp.id === updatedCp.id) tmp_cp = updatedCp;
        new_cps.push(tmp_cp);
      }
    }

    return new_cps;
  } else if (
    action.type === "UPDATE_MULTIPLE_CONSUMPTION_POINTS_FOR_CONTRACT"
  ) {
    let newState = [];
    let updated_cps = action.res[0];
    for (let consumption_point of state) {
      let current_id = consumption_point.id;
      let updated_cp = updated_cps.find(x => x.id === current_id);
      if (validateVar(updated_cp)) newState.push(updated_cp);
      //take updated cp instead of the old one with the id exists in api result array
      else newState.push(consumption_point); //take old consumption point if there is not updated data in api result array
    }
    return newState;
  } else if (action.type === "RETURN_PREVIOUS_STATE") {
    state.toggle = validateVar(state.toggle) ? !state.toggle : true;
    return state;
  } else {
    return state;
  }
}

export default consumption_point;
