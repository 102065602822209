import validateVar from "../Functions/validation/validateVariable";
import handleFormatDateForFrontend from "../Functions/handleFormatDateForFrontend";
import {
    apiError,
    apiFileSystemService, apiFunctionCustomerFileAdd,
    apiFunctionCustomerFileList,
    apiFunctionDeleteMemo, apiFunctionReturnResource,
    apiInformationManagementService, emptyValue
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import getValidFileName from "../Functions/validation/getValidFileName";

class File {
    constructor(data, status = "open") {
        this.id = "";
        this.key = "";
        this.value = "";
        this.file_category = "";
        this.file_category_display = "";
        this.date = "";

        //load basic info
        if (validateVar( data ) && validateVar( data.file_id )) {
            //if data found
            this.id = !validateVar( data.memo_id ) ? "" : data.memo_id;
            this.key = !validateVar( data.file_id ) ? "" : data.file_id;
            this.value =
                !validateVar( data.name )
                    ? ""
                    : data.name;
            this.file_category =
                !validateVar( data.file_category )
                    ? ""
                    : data.file_category;
            this.file_category_display =
                !validateVar( data.file_category )
                    ? ""
                    : this.fileCategoryDisplay(data.file_category);

            this.date =
                !validateVar( data.date )
                    ? ""
                    : this.getDate(data.date);
        }
    }

    static async get_file(function_name, key, file_type = "application/pdf") {
        let function_url = function_name + key;

        let communication_params = {
            method: 'GET', // method
            function_url: function_url,
            is_download: true
        };
        let c = getCommunicationClass(communication_params);
        let res = await c.request_handler();
        if (res !== apiError)
            return true; // no api error
        else
            return res; // api error
    }

    getDate(date){
        let split = date.split("T");
        return handleFormatDateForFrontend(split.shift());
    }

    static async deleteMultipleFiles(selected_array){
        let error_count = 0;
        let deleted_ids = [];
        for (let file of selected_array) { // loop through all values (objects or ids)
            let params = {   // get params for api request
                id: file.id,
            };


            let communication_params = {
                service: apiInformationManagementService,
                get_function: "",
                update_function: "",
                delete_function: apiFunctionDeleteMemo,
                add_function: "",
                download_function: "",
                params: null,
            };
            let c = getCommunicationClass(communication_params);

            let res = await c.delete_async(params);  // start "async" (not normal delete!) delete request
            if (res.status === "ok"){
                deleted_ids.push(file.id); // get ids for updating redux states later
            } else {
                error_count = error_count +1; // get error count for user feedback
            }
        }
        if (deleted_ids.length === 0) return apiError;  // if no data was deletet send ERR back
        return [deleted_ids, error_count]; // send deleted ids, and error quote back
    }

    delete() {
        let communication_params = {
            service: apiInformationManagementService,
            get_function: "",
            update_function: "",
            delete_function: apiFunctionDeleteMemo,
            add_function: "",
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);
        return c.delete({
            id: this.id,
        });
    }


    static async upload_file_new(params, file) {
        let params_file_upload =  {
            name: file.name,
            type: params.type,
            public_static: params.public_static,
        };

        params_file_upload = getAgencyID(params_file_upload);
        let function_url = "file_system";
        let communication_params = {
            method: "POST", // GET / POST / PUT / DELETE  -> method
            function_url: function_url,
            params: params_file_upload
        };
        let c = getCommunicationClass(communication_params, file);
        let result = await c.request_handler();
        if (result !== apiError) {
            return result;
        } else return apiError;
    }


  static async getFilesForCustomer(params) {
      params = getAgencyID(params);
      // let c = getCommunicationClass(apiFileSystemService,
      //     apiFunctionCustomerFileList,
      //     "",
      //     "",
      //     "",
      //     "",
      //     params)

      let communication_params = {
          service: apiFileSystemService,
          get_function: apiFunctionCustomerFileList,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);

      var res = await c.load();
      if (res.status === "ok") {
          let files = [];
          if (res.result === [] || res.result === "[]") return files;
          res.result.map((file) => {
              files.push(new File(file))
          });
          return files;
      }
      else return apiError;
  }

    static async down_load_default_file(id, type = "image/png"){
        // let c = getCommunicationClass(apiFileSystemService,
        //     "",
        //     "",
        //     "",
        //     "",
        //     apiFunctionReturnResource,
        //     {});

        let communication_params = {
            service: apiFileSystemService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionReturnResource,
            params: null,
        };
        let c = getCommunicationClass(communication_params);

        return await c.download({ id: id }, type, false);
    }


    static async addFile(params, file) {
        params = getAgencyID(params);
        // let c = getCommunicationClass(    apiFileSystemService,
        //     "",
        //     "",
        //     "",
        //     "saveFile",
        //     "",
        //     {})


        let communication_params = {
            service: apiFileSystemService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "saveFile",
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);


        var res = await c.add(params, file);
        if (res.status === "ok"){
            res.result.state_name = params.state_name;
            return res.result;
        } else return apiError;
    }


  static async uploadFilesForCustomer(params, file) {
    params = getAgencyID(params);
    // let c = getCommunicationClass(apiFileSystemService,
    //     "",
    //     "",
    //     "",
    //     apiFunctionCustomerFileAdd,
    //     "",
    //     {});

      let communication_params = {
          service: apiFileSystemService,
          get_function: "",
          update_function: "",
          delete_function: "",
          add_function: apiFunctionCustomerFileAdd,
          download_function: "",
      };
      let c = getCommunicationClass(communication_params);
    var res = await c.add(params, file);
    if (res.status === "ok"){
        return new File(res.result);
      } else return apiError;
  }

    fileCategoryDisplay(file_category) {
        if(file_category === "authorization") return "Maklervollmacht";
        else if (file_category === "privacy_statement") return "DSGVO-Vereinbarung";
        else if (file_category === "service_agreement") return "Dienstleistungsvereinbarung";
        else if (file_category === "invoice") return "Lieferanten-Rechnung"; // todo no key here
        else if (file_category === "other") return "Sonstiges";
        else return emptyValue;
    }
}

export default File;
