import validateVar from "../Functions/validation/validateVariable";
import is_true from "../Functions/is_true";

let initialState = null;

function company(state = initialState, action) {
  if (action.type === "INITIALIZE_COMPANY") {
    return action.res;
  } else if (action.type === "UPDATE_COMPANY") {
    return action.res;
  }else if (action.type === "RESET_COMPANY") {
    return action.res;
  } else if (action.type === "NOTIFICATION_VIEWED_COMPANY") {
    let new_state = { ...state };

    let notification = null;
    let new_value = action.res;
    let is_message = false;
    if (Array.isArray(new_value)) {
      let changed_count = new_value.length;
      for (let value of new_value) {
        if (!validateVar(notification)) {
          notification = new_state.notifications;
          if (validateVar(value.is_message)) {
            notification = new_state.messages;
            is_message = true;
          }
        }
        let updateNotificationIndex = notification.findIndex(
          x => x.id === value.id
        );
        if (
          validateVar(updateNotificationIndex) &&
          validateVar(notification[updateNotificationIndex])
        )
          notification[updateNotificationIndex] = value;
      }
      if (is_true(is_message)) {
        new_state.messages = notification;
        new_state.messages_count = +new_state.messages_count - +changed_count;
      } else {
        new_state.notifications = notification;
        new_state.notifications_count =
          +new_state.notifications_count - +changed_count;
      }
    }
    return new_state;
  } else {
    return state;
  }
}

export default company;
