/* default LPs */
import React from 'react';
import defaultLoadProfiles from "../Objects/defaultLoadProfiles";
import uploadLoadProfile from "../Objects/upload_load_profile";
import Employee from "../Objects/employees";
import Company from "../Objects/company";
import Supplier from "../Objects/supplier";
import ConsumptionPoint from "../Objects/consumption_point";
import Customer from "../Objects/customer";
import Offer from "../Objects/offer";
import Calculation from "../Objects/calculation";
import validateVar from "../Functions/validation/validateVariable";
import Tariff from "../Objects/tariff";
import Netfee from "../Objects/net_fee";
import BankAccount from "../Objects/bankAccount";
import Iban from "../Objects/iban";
import File from "../Objects/files";
import * as ERROR_CODE from "../Errors/error_codes";
import handleFormatNumber from "../Functions/handleFormatNumber";
import {deHashVar, hashVar} from "../Functions/handleHashedVariable";
import {logoutUser} from "../Functions/consultantActions";
import Plattform from "../Objects/plattform";
import handleCustomData from "../Functions/setPlattformColor";
import ChargeBee from "../Objects/chargebee";
import ComparisonFile from "../Objects/comparison_file";
import Blob from "../Objects/blob";
import Support from "../Objects/support";
import Buergel from "../Objects/buergel";
import inSign from "../Objects/in_sign";
import {
    apiError,
    consumption_offset, project_name, project_names, zipNotFound,
} from "../Functions/global";
import {
    Contactoverview, ConsumptionpointPath, Contacts, ContractData, ContractsPath, DashboardPath, Data, LoginPath,
    Verification, Compare, Calculator, CalculationUnits, tariff_list
} from "../../globalNavigation";
import Contract from "../Objects/contract";
import Chronik from "../Objects/chronik";
import History from "../Objects/Components/history";
import callClassWithString from "../Functions/callClassWithString";
import Notification from "../Objects/notification";
import * as action_employee from "./action_employee";
import * as action_team from "./action_team";
import CompanyEnermakler from '../Objects/company_enermakler';
import is_true from "../Functions/is_true";
import * as action_company from "./action_company";
import getGermanDateFormat from "../Functions/getGermanDateFormat";
import handleFormatDate from "../Functions/handleFormatDate";

const employee_id = deHashVar(localStorage.getItem("consultantEmployeeSecurityToken"));
export function getDefaultLps() {
    return function (dispatch) {
        defaultLoadProfiles.getAllProfiles().then(res => {
            dispatch({type: "GET_DEFAULT_LOAD_PROFILES", res: res});
        });

    };
}


// ---------------------- CONSUMPTION POINT --------------------------

export function updateConsumptionPointForContract(params) {
    return function(dispatch) {
        ConsumptionPoint.updateCpForContract(params).then(res => {
            if (res !== apiError) {
                dispatch({ type: "UPDATE_CONSUMPTION_POINTS_FOR_CONTRACT", res: res });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                        ERROR_CODE.UpdateConsumptionPointForContractError +
                        ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}
export function updateConsumptionPoint(params_cp, params_lp = null, file = null) {
    return function (dispatch) {
        if (validateVar(params_lp)) { // repredict cause important values changed
            if (validateVar(params_lp.prediction_id)) { //update prediction
                uploadLoadProfile.update_prediction(params_lp).then(res => {
                    if (res !== "ERR") {
                        let offset = res.result.consumption_offset;
                        let warning = null;
                        let predicted_consumption = handleFormatNumber(params_lp.predicted_consumption);
                        let original_consumption = handleFormatNumber(res.result.original_consumption);
                        if (validateVar(offset)) {
                            if (params_cp.loadprofile_check && offset > consumption_offset) { // hoher unterschied zwischen dem LG verbrauch und dem prognostizierten verbrauch .-> warnung ausgeben
                                warning = "Die Differenz zwischen Prognostizierten Jahresverbrauch und Lastgang ist sehr hoch, dies kann zu schlechteren Preisen führen. " +
                                    "Ihr prognostizierter Jahresverbrauch beträgt: " + predicted_consumption + " kWh, während der von uns erkannte Verbrauch sich auf " + original_consumption + " kWh beläuft."
                            }
                        }
                        ConsumptionPoint.updateConsumptionPoint(params_cp, true).then(res => {
                            if (res !== apiError) { // updated consumption point
                                if (validateVar(warning)) {
                                    dispatch({
                                        type: "SET_USER_MESSAGE",
                                        res: displaySuccessMessage(
                                            warning, // message
                                            "Achtung", //     headline
                                            Contactoverview + "/" + res.result.customer_id, //     url
                                            false, //     automatic_redirect
                                            true, //     modal
                                            "Trotzdem fortfahren", //     primaryBtnText
                                            "Jahresverbrauch anpassen",//     secondaryBtnText
                                            false, //     allowToggle
                                            false, //     successIcon
                                            true, //     infoIcon
                                            ConsumptionpointPath + "/" + params_cp.redirect_medium_type + "/" + params_cp.customer_id + "/" + params_cp.consumption_point_id, //     secondaryBtnFunction
                                        ),
                                    });
                                } else {
                                    dispatch({
                                        type: "SET_USER_MESSAGE",
                                        res: displaySuccessMessage(
                                            "", //  content
                                            "Ihre Lieferstelle wurde erfolgreich aktualisiert",  // headline
                                            Contactoverview + "/" + params_cp.customer_id,  // url
                                            false, // automatic_redirect
                                            true, // modal
                                            "Fortfahren",  // primaryBtnText
                                            null,  // secondaryBtnText
                                            false,  // allowToggle
                                        ),
                                    });
                                }
                            } else {
                                //error message for create cp
                                dispatch({type: "CONSUMPTION_POINT_ERROR", res: apiError});// reset loading animation
                                dispatch({
                                    type: "SET_USER_MESSAGE",
                                    res: displayErrorMessage(
                                        "Es ist ein Fehler beim aktualisieren der Lieferstelle aufgetreten. Bitte überprüfen Sie Ihre Angaben oder versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.UpdateConsumptionPointError + ")",
                                        "Fehler", // headline
                                        Contactoverview + "/" + params_cp.customer_id,  // url,
                                        false, // auto redirect
                                        true, // modal
                                        "Zurück zur Kontaktübersicht", // first Btn
                                        "Abbrechen", // secondBtn
                                        false // toggle
                                    ),
                                });
                            }
                        });
                    } else {        //error message update prediction
                        dispatch({type: "CONSUMPTION_POINT_ERROR", res: apiError});// reset loading animation
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displayErrorMessage(
                                "Es ist ein Fehler beim neu Prognostizieren des Lastprofiles aufgetreten. (Fehlercode: " + ERROR_CODE.PredictLpUpdateError + ")",
                                "Fehler", // headline
                                Contactoverview + "/" + params_cp.customer_id,  // url,
                                false, // auto redirect
                                true, // modal
                                "Zurück zur Kontaktübersicht", //first Btn
                                "Abbrechen", // second Btn
                                false // toggle
                            ),
                        });
                    }
                });
            } else {//new prediction
                if (validateVar(file)) { //neu prognostizieren weil neue file hochgeladen wurde
                    uploadLoadProfile.predict_lp(params_lp, file).then(res => {
                        if (res !== apiError) {
                            let offset = res.result.consumption_offset;
                            let warning = null;
                            let predicted_consumption = handleFormatNumber(params_lp.predicted_consumption);
                            let original_consumption = handleFormatNumber(res.result.original_consumption);
                            let mean_consumption = handleFormatNumber(res.result.mean_consumption);
                            if (validateVar(offset)) {
                                if (params_cp.loadprofile_check && offset > consumption_offset)  // if offset ist greater than our limit show warning to user
                                    warning = "Die Differenz zwischen Prognostizierten Jahresverbrauch und Lastgang ist sehr hoch, dies kann zu schlechteren Preisen führen. " +
                                        "Ihr prognostizierter Jahresverbrauch beträgt: " + predicted_consumption + " kWh, während der von uns erkannte Verbrauch sich auf " + original_consumption + " kWh beläuft."
                            }
                            params_cp.prediction_id = res.result.prediction_id;
                            params_cp.load_profile_id = res.result.load_profile_id;
                            ConsumptionPoint.updateConsumptionPoint(params_cp, true).then(res => {
                                if (res !== apiError) {
                                    if (validateVar(warning)) { // success but warning was thrown
                                        dispatch({
                                            type: "SET_USER_MESSAGE",
                                            res: displaySuccessMessage(
                                                warning, // message
                                                "Achtung", //     headline
                                                Contactoverview + "/" + res.result.customer_id, //     url
                                                false, //     automatic_redirect
                                                true, //     modal
                                                "Trotzdem fortfahren", //     primaryBtnText
                                                "Jahresverbrauch anpassen",//     secondaryBtnText
                                                false, //     allowToggle
                                                false, //     successIcon
                                                true, //     infoIcon
                                                ConsumptionPoint + "/" + params_cp.redirect_medium_type + "/" + res.result.customer_id + "/" + res.result.id, //     secondaryBtnFunction
                                            ),
                                        });
                                    } else {
                                        dispatch({
                                            type: "SET_USER_MESSAGE",
                                            res: displaySuccessMessage(
                                                "", //  content
                                                "Ihre Lieferstelle wurde erfolgreich aktualisiert",  // headline
                                                Contactoverview + "/" + params_cp.customer_id,  // url
                                                false, // automatic_redirect
                                                true, // modal
                                                "Fortfahren",  // primaryBtnText
                                                null,  // secondaryBtnText
                                                false,  // allowToggle
                                            ),
                                        });
                                    }
                                } else { // error while creating cp
                                    dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"}); // reset loading animation
                                    dispatch({
                                        type: "SET_USER_MESSAGE",
                                        res: displayErrorMessage(
                                            "Es ist ein Fehler beim hinzufügen der Lieferstelle aufgetreten. Bitte überprüfen Sie Ihre Angaben oder versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.CreateConsumptionPointError + ")",
                                            "Fehler",
                                            Contactoverview + "/" + params_lp.customer_id,  // url,
                                            false,
                                            true,
                                            "Zurück zur Kontaktübersicht",
                                            "Abbrechen",
                                            false
                                        ),
                                    });
                                }
                            });
                        } else {     //error message for customer lp
                            dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"}); // reset loading animation
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displayErrorMessage(
                                    "Es ist ein Fehler beim prognostizieren ihres Lastprofils aufgetreten. Bitte der versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.PredictLpError + ")",
                                    "Fehler",
                                    Contactoverview + "/" + params_cp.customer_id,  // url,
                                    false,
                                    true,
                                    "Zurück zur Kontaktübersicht",
                                    "Abbrechen",
                                    false
                                ),
                            });
                        }
                    });
                } else { //file bleibt gleich nur die für die prognose relevanten params ändern sich -> update prognose
                    console.log('PREDICT', params_lp)
                    uploadLoadProfile.predict_default_lp(params_lp).then(res => {
                        if (res !== "ERR") {
                            params_cp.prediction_id = res.result.prediction_id;
                            params_cp.load_profile_id = res.result.load_profile_id;
                            ConsumptionPoint.updateConsumptionPoint(params_cp, true).then(res => {
                                if (res !== "ERR") {
                                    dispatch({
                                        type: "SET_USER_MESSAGE",
                                        res: displaySuccessMessage(
                                            "", //  content
                                            "Ihre Lieferstelle wurde erfolgreich aktualisiert",  // headline
                                            Contactoverview + "/" + params_cp.customer_id,  // url
                                            false, // automatic_redirect
                                            true, // modal
                                            "Fortfahren",  // primaryBtnText
                                            null,  // secondaryBtnText
                                            false,  // allowToggle
                                        ),
                                    });
                                } else {        //error message for create cp
                                    dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"});// reset loading animation
                                    dispatch({
                                        type: "SET_USER_MESSAGE",
                                        res: displayErrorMessage(
                                            "Es ist ein Fehler beim hinzufügen der Lieferstelle aufgetreten. Bitte überprüfen Sie Ihre Angaben oder versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.CreateConsumptionPointError + ")",
                                            "Fehler",
                                            Contactoverview + "/" + params_cp.customer_id,  // url,
                                            false,
                                            true,
                                            "Zurück zur Kontaktübersicht",
                                            "Abbrechen",
                                            false
                                        ),
                                    });
                                }
                            });
                        } else {  //error message for default lp
                            dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"}); // reset loading animation
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displayErrorMessage(
                                    "Es ist ein Fehler beim prognostizieren des Branchenprofiles aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.PredictDefaultLpError + ")",
                                    "Fehler",
                                    Contactoverview + "/" + params_cp.customer_id,  // url,
                                    false,
                                    true,
                                    "Zurück zur Kontaktübersicht",
                                    "Abbrechen",
                                    false
                                ),
                            });
                        }
                    });
                }
            }
        } else { // only update consumption point cause only values changed which dont need to trigger a re- prediction
            ConsumptionPoint.updateConsumptionPoint(params_cp, true).then(res => {
                if (res !== "ERR") { // updated consumption point
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "", //  content
                            "Lieferstelle wurde aktualisiert",  // headline
                            Contactoverview + "/" + params_cp.customer_id,  // url
                            false, // automatic_redirect
                            true, // modal
                            "Fortfahren",  // primaryBtnText
                            null,  // secondaryBtnText
                            false,  // allowToggle
                        ),
                    });
                } else {
                    //error message for create cp
                    dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"});// reset loading animation
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            "Es ist ein Fehler beim aktualisieren der Lieferstelle aufgetreten. Bitte überprüfen Sie Ihre Angaben oder versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.UpdateConsumptionPointError + ")",
                            "Fehler", // headline
                            Contactoverview + "/" + params_cp.customer_id,  // url,
                            false, // auto redirect
                            true, // modal
                            "Zurück zur Kontaktübersicht", // first Btn
                            "Abbrechen", // secondBtn
                            false // toggle
                        ),
                    });
                }
            });
        }
    }
}


export function createConsumptionPoint(params_cp, params_lp, file = null) {
    return function (dispatch) {
        if (file === null) { //default lp
            uploadLoadProfile.predict_default_lp(params_lp).then(res => {

                if (res !== "ERR") {
                    params_cp.prediction_id = res.result.prediction_id;
                    params_cp.load_profile_id = res.result.load_profile_id;
                    ConsumptionPoint.createConsumptionPoint(params_cp).then(res => {
                        if (res !== apiError) {
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displaySuccessMessage(
                                    "", //  content
                                    "Lieferstelle hinzugefügt",  // headline
                                    Contactoverview + "/" + params_cp.customer_id,  // url
                                    false, // automatic_redirect
                                    true, // modal
                                    "Fortfahren",  // primaryBtnText
                                    null,  // secondaryBtnText
                                    false,  // allowToggle
                                ),
                            });
                        } else {        //error message for create cp
                            dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"});// reset loading animation
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displayErrorMessage(
                                    "Es ist ein Fehler beim hinzufügen der Lieferstelle aufgetreten. Bitte überprüfen Sie Ihre Angaben oder versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.CreateConsumptionPointError + ")",
                                    "Fehler",
                                    Contactoverview + "/" + params_cp.customer_id,  // url,
                                    false,
                                    true,
                                    "Zurück zur Kontaktübersicht",
                                    "Abbrechen",
                                    false
                                ),
                            });
                        }
                    });
                } else {  //error message for default lp
                    dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"}); // reset loading animation
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            "Es ist ein Fehler beim prognostizieren des Branchenprofiles aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.PredictDefaultLpError + ")",
                            "Fehler",
                            Contactoverview + "/" + params_cp.customer_id,  // url,
                            false,
                            true,
                            "Zurück zur Kontaktübersicht",
                            "Abbrechen",
                            false
                        ),
                    });
                }
            });
        } else { // CREATE CP WITH CUSTOMER LOAD PROFILE
            uploadLoadProfile.predict_lp(params_lp, file).then(res => {
                if (res !== "ERR") {
                    let offset = res.result.consumption_offset;
                    let warning = null;
                    let predicted_consumption = handleFormatNumber(params_lp.predicted_consumption);
                    let original_consumption = handleFormatNumber(res.result.original_consumption);
                    let mean_consumption = handleFormatNumber(res.result.mean_consumption);
                    if (validateVar(offset)) {
                        if (params_cp.loadprofile_check && offset > consumption_offset)  // if offset ist greater than our limit show warning to user
                            warning = "Die Differenz zwischen Prognostizierten Jahresverbrauch und Lastgang ist sehr hoch, dies kann zu schlechteren Preisen führen. " +
                                "Ihr prognostizierter Jahresverbrauch beträgt: " + predicted_consumption + " kWh, während der von uns erkannte Verbrauch sich auf " + original_consumption + " kWh beläuft."
                    }
                    params_cp.prediction_id = res.result.prediction_id;
                    params_cp.load_profile_id = res.result.load_profile_id;
                    ConsumptionPoint.createConsumptionPoint(params_cp, file).then(res => {
                        if (res !== "ERR") {
                            if (validateVar(warning)) { // success but warning was thrown
                                dispatch({
                                    type: "SET_USER_MESSAGE",
                                    res: displaySuccessMessage(
                                        warning, // message
                                        "Achtung", //     headline
                                        Contactoverview + "/" + res.customer_id, //     url
                                        false, //     automatic_redirect
                                        true, //     modal
                                        "Trotzdem fortfahren", //     primaryBtnText
                                        "Jahresverbrauch anpassen",//     secondaryBtnText
                                        false, //     allowToggle
                                        false, //     successIcon
                                        true, //     infoIcon
                                        ConsumptionPoint + "/" + params_cp.redirect_medium_type + "/" + res.customer_id + "/" + res.id, //     secondaryBtnFunction
                                    ),
                                });
                            } else {
                                dispatch({
                                    type: "SET_USER_MESSAGE",
                                    res: displaySuccessMessage(
                                        "Sie haben eine Lieferstelle mit einem prognostizierten Verbrauch von " + mean_consumption + " kWh angelegt", //  content
                                        "Lieferstelle hinzugefügt",  // headline
                                        Contactoverview + "/" + params_cp.customer_id,  // url
                                        false, // automatic_redirect
                                        true, // modal
                                        "Fortfahren",  // primaryBtnText
                                        null,  // secondaryBtnText
                                        false,  // allowToggle
                                    ),
                                });
                            }
                        } else { // error while creating cp
                            dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"}); // reset loading animation
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displayErrorMessage(
                                    "Es ist ein Fehler beim hinzufügen der Lieferstelle aufgetreten. Bitte überprüfen Sie Ihre Angaben oder versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.CreateConsumptionPointError + ")",
                                    "Fehler",
                                    Contactoverview + "/" + params_lp.customer_id,  // url,
                                    false,
                                    true,
                                    "Zurück zur Kontaktübersicht",
                                    "Abbrechen",
                                    false
                                ),
                            });
                        }
                    });
                } else {     //error message for customer lp
                    dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"}); // reset loading animation
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            "Es ist ein Fehler beim prognostizieren ihres Lastprofils aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. (Fehlercode: " + ERROR_CODE.PredictLpError + ")",
                            "Fehler",
                            Contactoverview + "/" + params_cp.customer_id,  // url,
                            false,
                            true,
                            "Zurück zur Kontaktübersicht",
                            "Abbrechen",
                            false
                        ),
                    });
                }
            });
        }
    };
}

export function updateConsumptionPointsInOffer(data, offer_id, generate_contract_params, token, offer_token) {
    return function (dispatch) {
        ConsumptionPoint.updateConsumptionPointsInOffer(data, offer_id, generate_contract_params.customer_id).then(res => {
            if (res !== "ERR") {// success
                Contract.generateContract(generate_contract_params).then(res_generated => {
                    if (res_generated !== apiError) { // success
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displaySuccessMessage(
                                "", //  content
                                "",  // headline
                                Calculator + Verification + '/' + token, // url
                                true, // automatic_redirect
                                false, // modal
                            ),
                        });
                        dispatch({type: "GENERATE_CONTRACT", res: "GENERATED"});
                        dispatch({type: "GET_CONSUMPTION_POINT_FOR_OFFER", res: res});
                    } else { // error in generate contract
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displayErrorMessage(
                                "Bei dem Generieren des Vertragsdokuments ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GenerateContractError + ")", // content
                                "Fehler", //headline
                                Calculator + Compare + "/" + offer_token, // url
                                false, //automatic redirect
                                true,       //modal
                                "Zurück zum Vergleich", // primaryBtnText
                                null,// secondaryBtnText
                                false, //allowToggle (user can close modal without pressing a btn)
                                false,// successIcon
                                false,// infoIcon
                                "danger" //type - danger/success
                            ),
                        });
                    }
                });
            } else { // error in update cp
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei aktualisieren Ihrer Daten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GenerateContractError + ")", // content
                        "Fehler", //headline
                        Calculator + Compare + "/" + offer_token, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück zum Vergleich", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });

    };
}


export function getConsumptionPointForOffer(offer_id, customer_id = null) {
    return function (dispatch) {
        ConsumptionPoint.getConsumptionPointForOffer(offer_id, customer_id).then(res => {
            if (res !== "ERR")
                dispatch({type: "GET_CONSUMPTION_POINT_FOR_OFFER", res: res});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem laden der Abnahmestelle(n) ist ein Fehler aufgetreten.", // content
                        "Fehler", //headline
                        Calculator + Data, // url,
                        false, //automatic redirect
                        true,       //modal
                        "Zurück zum Kalkulator", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                    ),
                });
            }
        });
    };
}

export function deleteConsumptionPoint(selected_array) {
    return function (dispatch) {
        ConsumptionPoint.deleteMultipleConsumptionPoints(selected_array).then(res => {
            if (res !== apiError && res !== 'Under a contract') {
                let deleted_ids = res[0];// all deleted ids for updating redux state
                let deleted_count = 0;  // how many data obj were deleted? ( user feedback )
                if (validateVar(deleted_ids) && deleted_ids.length > 0) deleted_count = deleted_ids.length;
                let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
                let total_count = selected_array.length; // how many data obj for delete in total
                if (error_count === 0) { // no delete request failed -> total success
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count + " von " + total_count + " Lieferstellen wurden gelöscht", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                        ),
                    });
                } else { // delete went through but some data threw a error message
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count + " von " + total_count + " Lieferstellen wurden gelöscht, bei " + error_count + " von " + total_count + " ist das löschen Fehlgeschlagen", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            true,// infoIcon
                        ),
                    });
                }

                // send deleted ids to redux to filter out the deleted data obj from state
                dispatch({
                    type: "DELETE_CONSUMPTION_POINT_FROM_LIST",
                    ids: deleted_ids
                });

                // todo send redux request for state update
            } else { // Error feedback for customer -> could not delete data
                let error_text = 'Bei dem löschen der Lieferstellen ist ein Fehler aufgetreten.';
                if(res === 'Under a contract') error_text = 'Die ausgewählte Lieferstelle befindet sich in einer Vertragsbindung und kann nicht gelöscht werden.'

                dispatch({type: "CONSUMPTION_POINT_ERROR", res: "ERR"});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        error_text+" (Fehlercode: " + ERROR_CODE.DeleteConsumptionpointError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    }
}


export function download_load_profile(params) {
    return function (dispatch) {
        uploadLoadProfile.download_load_profile(params).then(res => {
            if (res !== apiError)
                dispatch({type: "GET_CONSUMPTION_POINT_FOR_OFFER", res: res});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim herunterladen ihres Lastprofiles ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DownloadLpError + ")", // content
                        "Fehler", //headline
                        null,
                        false, //automatic redirect
                        true,       //modal
                        null,
                        "Zurück",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                    ),
                });
            }
        });
    }
}

export function download_timeline_file(id) {
    return function (dispatch) {
        Chronik.download_file(id).then(res => {
            if (res === "ERR"){
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim herunterladen der Datei ist ein Fehler aufgetreten.", // content
                        "Fehler", //headline
                        null,
                        false, //automatic redirect
                        true,       //modal
                        null,
                        "Zurück",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                    ),
                });
            }
        })
    }
}

// -------------------- NOTIFICATION ------------------------

export function resetNotification() {
    return function (dispatch) {
        dispatch({type: "SET_USER_MESSAGE", res: null});
    };
}

export function setSuccessMessage() {
    return function (dispatch) {
        dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage()});
    };
}

const displaySuccessMessage = (
    message = 'Ihre Aktion wurde erfolgreich ausgeführt.',
    headline = 'Erfolg',
    url = null,
    automatic_redirect = false,
    modal = null,
    primaryBtnText = 'OK',
    secondaryBtnText = 'Abbrechen',
    allowToggle = false,
    successIcon = true,
    infoIcon = false,
    secondaryBtnFunction = null,
    type = 'success',
) => {
    return {
        type: type,
        headline: headline,
        message: message,
        url: url,
        automatic_redirect: automatic_redirect,
        modal: modal,
        primaryBtnText: primaryBtnText,
        secondaryBtnText: secondaryBtnText,
        successIcon: successIcon,
        infoIcon: infoIcon,
        allowToggle: allowToggle,
        secondaryBtnFunction: secondaryBtnFunction
    };
};

//test
const displayErrorMessage = (
    message = 'Ihre aktion konnte nicht ausgeführt werden.',
    headline = 'Es ist ein Fehler aufgetreten',
    url = null,
    automatic_redirect = false,
    modal = null,
    primaryBtnText = 'OK',
    secondaryBtnText = 'Abbrechen',
    allowToggle = false,
    successIcon = false,
    infoIcon = false,
    type = 'danger',
    secondaryBtnFunction = null,
) => {
    return {
        type: type,
        headline: headline,
        message: message,
        url: url,
        automatic_redirect: automatic_redirect,
        modal: modal,
        primaryBtnText: primaryBtnText,
        secondaryBtnText: secondaryBtnText,
        successIcon: successIcon,
        infoIcon: infoIcon,
        allowToggle: allowToggle,
        secondaryBtnFunction: secondaryBtnFunction
    };
};

// ---------------------------- EMPLOYEE ---------------------

export function get_plattform_settings(login = false, params = null) {
    return function (dispatch) {
        Plattform.get_plattform_settings(params).then(res => {
            if (res !== apiError) {
                if (is_true(login)) {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "", // content
                            "Erfolg", //headline
                            DashboardPath, // url
                            true, //automatic redirect
                        ),
                    });
                }

                handleCustomData(false, res);
                dispatch({type: "CHANGE_PLATTFORM", res: res})
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Farben von Ihrem Account konnten nicht geladen werden (Fehlercode: " + ERROR_CODE.GetEmployeeColors + ")",
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        true,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
                dispatch({type: "CHANGE_PLATTFORM", res: res});
            }
        });
    }
}


export function start_chargebee_session() {
    return function (dispatch) {
        ChargeBee.start_portal_session().then(res => {
            if (res !== apiError) dispatch({type: "START_CHARGE_BEE_SESSION", res: res});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim laden der Kundenliste ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetCustomerListError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Schließen",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger", //type - danger/success
                        //type - danger/success
                    ),
                });
            }
        });
    };
}


export function loginEmployee(params, function_name = null) {
    return function (dispatch) {
        Employee.affiliate_login(function_name, params).then(res => {
            if (res !== apiError && res !== "SERVER_DOWN") {
                logoutUser(false); // remove all data in localStorage without redirect
                let agencyID = res.agency;
                let customerID = res.customer_id;
                let userRole = res.roles[0].name;
                let status = res.roles[0].pivot.status;
                if (status !== "active") {
                    let message = "Bitte aktivieren Sie Ihren Account, loggen Sie sich hierzu in Ihren Mail Account ein und drücken auf den von uns zugesandten Link.";
                    let headline = "Nutzer nicht aktiviert";
                    dispatch({type: "ERROR_EMPLOYEE", res: "ERR"});
                    if (status === "inactive") {
                        message = "Ihr Account wurde deaktiviert. Derzeit ist es Ihnen nicht möglich sich einzuloggen";
                        headline = "Account deaktiviert";
                    }
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            message, // content
                            headline, //headline
                            null, // url,
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            true,// infoIcon
                            "danger" //type - danger/success
                        ),
                    });
                } else {
                    let hashedAgencyID = hashVar(agencyID);
                    let hashedCustomerID = hashVar(customerID);
                    let hashedRole = hashVar(userRole);
                    localStorage.setItem("consultantSecurityToken", hashedAgencyID); // agency_id = consultant_id
                    localStorage.setItem("consultantEmployeeSecurityToken", hashedCustomerID); // customer_id = employee_id
                    localStorage.setItem("consultantUserAccount", hashedRole);
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "", // content
                            "Erfolg", //headline
                            DashboardPath, // url
                            true, //automatic redirect
                        ),
                    });
                }
            } else if (res === apiError) {
                dispatch({type: "ERROR_EMPLOYEE", res: "ERR"});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bitte geben Sie eine gültige E-Mail Adresse und ein gültiges Passwort (mind. 6 Ziffern) an.", // content
                        "Achtung", //headline
                        null, // url,
                        false, //automatic redirect
                        false,       //modal
                    ),
                });
            } else {
                dispatch({type: "ERROR_EMPLOYEE", res: "ERR"});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Aufgrund von Wartungsarbeiten ist der Login derzeit leider nicht verfügbar. Bitte versuchen Sie es später erneut.", // content
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function initializeEmployee() {
    return function (dispatch) {
        Employee.instantiateEmployee().then(res => {
            if (res !== apiError)
                dispatch({type: "INITIALIZE_EMPLOYEE", res: res});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Mitarbeiterdaten konnte nicht geladen werden. (Fehlercode: " + ERROR_CODE.GetEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function initializeEmployees() {
    return function (dispatch) {
        Employee.instantiateList().then(res => {
            if (res !== apiError)
                dispatch({type: "INITIALIZE_EMPLOYEE_LIST", res: res});
            else {
                dispatch({type: "INITIALIZE_EMPLOYEE_LIST", res: []});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Mitarbeiterlisteee konnte nicht geladen werden. (Fehlercode: " + ERROR_CODE.GetEmployeeListError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function addEmployee(params) {
    return function (dispatch) {
        Employee.addEmployee(params).then(res => {
            if (res !== apiError) {
                if (res === "Used") { // already registed email -> user feedback
                    //error
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            "Der Mitarbeiter konnte nicht angelegt werden, da bereits ein anderer Mitarbeiter mit dieser E-Mail registiert wurde. (Fehlercode: " + ERROR_CODE.AddEmployeeDublicatedError + ")", // content
                            "Fehler", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            false,// infoIcon
                            "danger" //type - danger/success
                        ),
                    });
                } else  // success add user
                    dispatch({type: "INITIALIZE_EMPLOYEE_LIST", res: res});
            } else {
                dispatch({ // error
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Der Mitarbeiter konnte nicht angelegt werden. (Fehlercode: " + ERROR_CODE.AddEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function deleteMutlipleEmployee(selected_array) {
    return function(dispatch) {
        Employee.deleteMultiple(selected_array).then(res => {
            if (res !== apiError) {
                let deleted_ids = res[0]; // all deleted ids for updating redux state
                let deleted_count = 0; // how many data obj were deleted? ( user feedback )
                if (validateVar(deleted_ids) && deleted_ids.length > 0)
                    deleted_count = deleted_ids.length;
                let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
                let total_count = selected_array.length; // how many data obj for delete in total
                if (error_count === 0) {
                    // no delete request failed -> total success
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count + " von " + total_count + " Nutzer wurden gelöscht", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true, //modal
                            null, // primaryBtnText
                            "Bestätige", // secondaryBtnText
                            true //allowToggle (user can close modal without pressing a btn)
                        )
                    });
                } else {
                    // delete went through but some data threw a error message
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count +
                            " von " +
                            total_count +
                            " Nutzer wurden gelöscht, bei " +
                            error_count +
                            " von " +
                            total_count +
                            " ist das löschen Fehlgeschlagen.", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true, //modal
                            null, // primaryBtnText
                            "Bestätige", // secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false, // successIcon
                            true // infoIcon
                        )
                    });
                }

                // send deleted ids to redux to filter out the deleted data obj from state
                dispatch({
                    type: "DELETE_EMPLOYEE",
                    ids: deleted_ids
                });
            } else {
                // Error feedback for customer -> could not delete data
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem löschen der Nutzer ist ein Fehler aufgetreten. (Fehlercode: " +
                        ERROR_CODE.DeleteEmployee +
                        ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätige", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}




export function updateEmployee(params, with_employee_id = true) {
    return function (dispatch) {
        Employee.updateEmployee(params, with_employee_id).then(res => {
            if (res !== apiError) {
                dispatch({type: "UPDATE_EMPLOYEE", res: res});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "Die Nutzerdaten wurden aktualisiert."
                    )
                });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Mitarbeiterdaten konnten nicht aktualisiert werden. (Fehlercode: " + ERROR_CODE.UpdateEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function updateMultibleEmployee(employees) {
    return function (dispatch) {
        Employee.updateEmployeeStatus(employees).then(res => {
            let updated_employee = res[0];
            let error_count = res[1];
            if (res !== apiError && error_count === 0){
                dispatch({type: "UPDATE_EMPLOYEE_ARRAY", res: updated_employee});
            }
            else if (res !== apiError && error_count > 0) {
                dispatch({type: "UPDATE_EMPLOYEE_ARRAY", res: updated_employee});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        updated_employee.length + " von " + employees.length + " Accounts konnten geupdated werden.", // content
                        "Erfolg", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        true,// infoIcon
                    ),
                });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Mitarbeiterdaten konnten nicht aktualisiert werden. (Fehlercode: " + ERROR_CODE.UpdateEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function addLogoFileEmployee(params, file) {
    return function (dispatch) {
        Employee.changeLogoFile(params, file).then(res => {
            if (res !== apiError) {
                let data = {
                    picture_id: res.file_id,
                    employee_id: employee_id
                };
                Employee.updateEmployee(data).then(res => {
                    dispatch({type: "UPDATE_SINGLE_EMPLOYEE", res: res});
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "Das Logo wurde aktualisiert."
                        )
                    });
                });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Das Logo konnte nicht aktualisiert werden. (Fehlercode: " + ERROR_CODE.AddLogoFileToEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function updateSingleEmployee(params) {
    return function (dispatch) {
        Employee.updateEmployee(params).then(res => {
            if (res !== apiError) {
                dispatch({type: "UPDATE_SINGLE_EMPLOYEE", res: res});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "Ihre Daten wurden aktualisiert."
                    )
                });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem aktualisieren der Mitarbeiterdaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UpdateEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }

        });
    };
}

export function deleteEmployee(selected_array) {
    return function (dispatch) {
        Employee.deleteMultiple(selected_array).then(res => {
            if (res !== apiError) {
                let deleted_ids = res[0];// all deleted ids for updating redux state
                let deleted_count = 0;  // how many data obj were deleted? ( user feedback )
                if (validateVar(deleted_ids) && deleted_ids.length > 0) deleted_count = deleted_ids.length;
                let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
                let total_count = selected_array.length; // how many data obj for delete in total
                if (error_count === 0) { // no delete request failed -> total success
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count + " von " + total_count + " Accounts wurden gelöscht", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                        ),
                    });
                } else { // delete went through but some data threw a error message
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count + " von " + total_count + " Account wurden gelöscht, bei " + error_count + " von " + total_count + " ist das löschen Fehlgeschlagen", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            true,// infoIcon
                        ),
                    });
                }

                dispatch({// send deleted ids to redux to filter out the deleted data obj from state
                    type: "DELETE_EMPLOYEE",
                    ids: deleted_ids
                });
            } else { // Error feedback for customer -> could not delete data
                let error_message = "Bei löschen des Kontos ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DeleteEmployeeError + ")";
                if (selected_array.length > 0) error_message = "Bei dem löschen der Konten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DeleteEmployeeError + ")";
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        error_message, // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    }
}

export function closeCurrentKonto(params) {
    return function (dispatch) {
        Employee.deleteSingleEmployee(params).then(res => {
            if (res !== apiError) logoutUser(true);
            else {
                dispatch({type: "DELETE_EMPLOYEE_ERR", res: "ERR"});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem löschen ihres Kontos ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DeleteEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });

    }
}

export function resetPasswordEmployee(params) {
    return function (dispatch) {
        Employee.resetPassword(params).then(res => {
            if (res !== "ERR") {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "Ihnen wurde ein Bestätigungslink zum Zurücksetzen des Passwortes zugesendet. Bitte prüfen Sie Ihre E-Mails und klicken Sie auf den enthaltenen Link.",
                        "Erfolg!",
                        LoginPath, // url,
                        false, //automatic redirect
                        true,       //modal
                        "Zurück zum Login", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                    ),
                });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Anfordern des Links ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an unseren Support. (Fehlercode: " + ERROR_CODE.ResetPasswordError + ")", // content
                        "Fehler!", //headline
                        LoginPath, // url,
                        false, //automatic redirect
                        true,       //modal
                        "Zurück zum Login", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function activateNewPasswordEmployee(params) {
    return function (dispatch) {
        Employee.setNewPassword(params).then(res => {
            if (res !== apiError) {

                dispatch({
                    type: "SET_PASSWORD",
                    res: "SET_PASSWORD"
                });
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "Ihr neues Passwort wurde erfolgreich gesetzt.",
                        "Erfolg!",
                        LoginPath, // url,
                        false, //automatic redirect
                        true,       //modal
                        "Zurück zum Login", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                    ),
                });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim setzen des Passworts ist ein Fehler aufgetreten, bitte fordern Sie einen neuen Link an oder kontaktieren Sie den Support. (Fehlercode: " + ERROR_CODE.SetNewPasswordError + ")", // content
                        "Fehler!", //headline
                        LoginPath, // url,
                        false, //automatic redirect
                        true,       //modal
                        "Zurück zum Login", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function activateEmployee(params) {
    return function (dispatch) {
        Employee.activate(params).then(res => {
            if (res !== "ERR") {
                dispatch({ // todo automatic Login
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "Ihr Account wurde erfolgreich aktiviert.",
                        "Erfolg!",
                        LoginPath, // url,
                        false, //automatic redirect
                        true,       //modal
                        "Zum Login", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                    ),
                });

                // dispatch({ type: "LOG_IN_EMPLOYEE", res: res });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem laden der Firmendaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetCompanyError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });


            }
        });
    };
}

// ---------------------------- END EMPLOYEE ---------------------
// ---------------------------- CONTRACT ---------------------
export function modifyContract(params) {
    return function(dispatch) {
        Contract.update(params).then(res => {
            if (res !== apiError) {
                dispatch({ type: "MODIFY_CONTRACT", res: res });
                if (params.protocol_id) dispatch({ type: "UPDATE_HISTORY", res: res });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                        ERROR_CODE.UpdateContractError +
                        ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätige", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}

export function downloadContracts(ids) {
   return function (dispatch) {
        Contract.download(ids).then(res => {
            // todo feedback?
        })

    }

    // return { type: "DOWNLOAD_CONTRACTS", ids: ids };
}






// ---------------------------- END CONTRACT ---------------------
// -------------------- PLATTFORM SETTINGS -----------------------
export function updateCustomizedLogin(params) {
    return function (dispatch) {
        Plattform.update_plattform(params).then(res => {
            if (res !== apiError) {
                if ( +params.logo_id !== +params.originial_logo_id) {
                    let params_company = {
                        logo_file_id: params.logo_id
                    };

                    Company.update(params_company).then(res => {
                        if (res !== apiError) {
                            dispatch({type: "UPDATE_COMPANY", res: res});
                        }else{
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displayErrorMessage(
                                    "Bei dem abspeichern des Logos ist ein Fehler aufgetreten.", // content
                                    "Fehler", //headline
                                    null, // url, // url
                                    false, //automatic redirect
                                    true,       //modal
                                    null, // primaryBtnText
                                    "Bestätige",// secondaryBtnText
                                    true, //allowToggle (user can close modal without pressing a btn)
                                    false,// successIcon
                                    false,// infoIcon
                                    "danger" //type - danger/success
                                ),
                            });
                        }
                    });
                }

                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "Ihre Daten wurden erfolgreich gespeichert."
                    )
                });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem abspeichern der aktualisierten Plattformdaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.PlattformCustomizeUpdateError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }

        });
    };
}

// -------------------- END PLATTFORM SETTINGS -----------------------


export function initializeCompany() { //Ehub
    return function (dispatch) {
        Company.instantiate().then(res => {
            if (res !== apiError) {
                dispatch({type: "INITIALIZE_COMPANY", res: res});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem laden der Firmendaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetCompanyError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function initializeCompanyEnermakler() {
    return function (dispatch) {
        CompanyEnermakler.instantiate().then(res => {
            if (res !== apiError) {
                dispatch({type: "INITIALIZE_COMPANY", res: res});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem laden der Firmendaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetCompanyError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function createInSignSession(customer_params) {
    return function (dispatch) {
        inSign.configureSession(customer_params).then(res => {
            if (res !== apiError) {
                dispatch({ type: "GET_INSIGN_SESSION_RESULT", res: res });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim erstellen der InSign Schnittstellen Session ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.CreateInSignSessionError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function loadAndCheckInsignSession(session_id, refresh_only = false, guestPage = false) {
    return function (dispatch) {
        inSign.loadAndCheckIn(session_id).then(res => {

            //TODO requiredSignatureFields ist ungetestet da der fehler nicht mehr auftritt
            if (res.requiredSignatureFieldsSigned === res.requiredSignatureFields) { //check if required signature fields are filled
                if (res !== apiError) {
                    if (!refresh_only) dispatch({type: "INITIALIZE_INSIGN", res: res});
                } else {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            "Bei dem laden der Daten von InSign ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.CreateInSignSessionError + ")", // content
                            "Fehler", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            'Bestätigen',// secondaryBtnText
                            false, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            false,// infoIcon
                            "danger" //type - danger/success
                        ),
                    });
                    dispatch({type: "INITIALIZE_INSIGN", res: apiError});
                }
            }else{
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Nicht alle Unterschriftsfelder wurden ausgefüllt. Überprüfen Sie bitte das Dokument noch einmal. (Fehlercode: " + ERROR_CODE.MissingInsignSignatures + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        'Bestätigen',// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
                dispatch({type: "INITIALIZE_INSIGN", res: 'ERR'});
            }
        });
    };
}


export function uploadInSignDoc(upload_params, file) {
    return function (dispatch) {

        inSign.loadAndCheckIn(upload_params.session_id).then(res => {
            if (res !== apiError){
                inSign.uploadDocument(upload_params, file).then(res => {
                    if (res !== apiError) {
                        if (upload_params.send_mail) { // if mail was send the offer should be saved
                            dispatch({ type: "MAIL_SEND_INSIGN", res: "SEND_INSIGN_MAIL" });
                        } else dispatch({ type: "CONTRACT_SIGNED_INSIGN", res: "CONTRACT_SIGNED" });
                    } else {
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displayErrorMessage(
                                "Bei dem Hochladen des Dokuments ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UploadInSignDoc + ")", // content
                                "Fehler", //headline
                                null, // url, // url
                                false, //automatic redirect
                                true,       //modal
                                null, // primaryBtnText
                                "Bestätige",// secondaryBtnText
                                true, //allowToggle (user can close modal without pressing a btn)
                                false,// successIcon
                                false,// infoIcon
                                "danger" //type - danger/success
                            ),
                        });
                    }
                });
            }else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim laden der InSign Schnittstelle ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetInSignError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        })
    };
}

export function downloadDocument(params) {
    return function (dispatch) {
        inSign.downloadDocument(params).then(res => {
            if (res !== apiError) {
                dispatch({type: 'CONTRACT_SIGNED_INSIGN', res: res});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Erstellen des unterschriebenen Dokuments ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DownloadInSignDoc + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
                dispatch({type: 'CONTRACT_SIGNED_INSIGN', res: apiError});
            }
        });
    }
}

export function createBlobFromURL(url) {
    return function (dispatch) {
        Blob.createBlobFromURL(url).then(res => {
            dispatch({type: "URL_TO_BLOB", res: res});
        });
    };
}


export function createURL(url) {
    return function (dispatch) {
        Blob.fileStreamToBlob(url).then(res => {
            dispatch({type: "URL_TO_BLOB", res: res});
        });
    };
}

//ehub
export function updateCompany(params) {
    return function (dispatch) {
        Company.update(params).then(res => {
            if (res !== "Api Error") {
                dispatch({type: "UPDATE_COMPANY", res: res});
            } else dispatch({type: "SET_USER_MESSAGE", res: displayErrorMessage()});
        });
    };
}

export function updateCompanyEnermakler(params) {
    return function (dispatch) {
        CompanyEnermakler.update(params).then(res => {
            if (res !== "Api Error") {
                dispatch({type: "UPDATE_COMPANY", res: res});
            } else dispatch({type: "SET_USER_MESSAGE", res: displayErrorMessage()});
        });
    };
}

export function updateCompanyAndPartner(provision_params, partner_params = null) {
    return function (dispatch) {
        CompanyEnermakler.update(provision_params).then(res_company => {
            if (res_company !== apiError ) {
                if (validateVar(partner_params)) { // second update for accepting suppliers if partner params is set
                    Supplier.update_partners(partner_params).then(res_parnter => {
                        dispatch({type: "UPDATE_COMPANY", res: res_company});
                        if (res_parnter !== apiError) {
                            dispatch({type: "UPDATE_PARTNERS", res: res_parnter});

                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displaySuccessMessage(
                                    "Ihre Daten wurden erfolgreich gespeichert."
                                )
                            });
                        } else {
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displayErrorMessage(
                                    "Bei dem aktualisieren der Partner ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UpdatePartnerError + ")", // content
                                    "Fehler", //headline
                                    null, // url, // url
                                    false, //automatic redirect
                                    true,       //modal
                                    null, // primaryBtnText
                                    "Bestätige",// secondaryBtnText
                                    true, //allowToggle (user can close modal without pressing a btn)
                                    false,// successIcon
                                    false,// infoIcon
                                    "danger" //type - danger/success
                                ),
                            });
                        }
                    });
                } else {
                    dispatch({type: "UPDATE_COMPANY", res: res_company});
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "Ihre Daten wurden erfolgreich gespeichert."
                        )
                    });
                }
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem aktualisieren der Firmendaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UpdateCompanyError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

// ----------------------- SUPPLIER -------------------------------


export function getAllSupplier() {
    return function (dispatch) {
        Supplier.getAllSupplier().then(res => {
            if (res !== apiError)
                dispatch({type: "GET_ALL_SUPPLIER", res: res});
            else {
                dispatch({type: "GET_ALL_SUPPLIER", res: []});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Laden der Energieversorgerliste ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetAllSupplierError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function update_partners(params) {
    return function (dispatch) {
        Supplier.update_partners(params).then(res => {
            if (res !== "ERR")
                dispatch({type: "UPDATE_PARTNERS", res: null});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Ihre . (Fehlercode: " + ERROR_CODE.GetConsumptionPointError + ")", // content
                        "Fehler", //headline
                        Contacts, // url,
                        false, //automatic redirect
                        true,       //modal
                        "Zurück zur Kontaktübersicht", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                    ),
                });
            }
        });

    };
}


// ------------------- END SUPPLIER -----------------------------------

// ------------------ START CONTRACTS ----------------------------------

export function initializeContract(params) {
    return function (dispatch) {
        params =  (validateVar(params)) ? params : {};
        Contract.instantiateList(params).then(res => {
            if (res !== apiError) {
                dispatch({type: "CONTRACT_LIST", res: res});
            } else {
                dispatch({type: "CONTRACT_LIST", res: apiError});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim Laden der Auftragsliste ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetContractListError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }

        });
    };
}


export function create_url(params, class_name, function_name) {
    return function(dispatch) {
        let class_object = callClassWithString(class_name, null);
        class_object.constructor[function_name](params).then(res => {
            let result = {
                file_name: res.file_name,
                property_name: params.property_name,
                url: res.url
            };
            if (res !== apiError) {
                dispatch({ type: "CREATE_URL", res: result });
            } else {
                dispatch({ type: "RESET_URL", res: res });
            }
        });
    };
}


export function reset_url() {
    return function(dispatch) {
        dispatch({ type: "RESET_URL", res: null });
    };
}

export function reset_uploaded_file() {
    return function(dispatch) {
        dispatch({ type: "RESET_UPLOADED_FILE", res: null });
    };
}


export function createChronik(params, file) {
    return function(dispatch) {
        Chronik.create(params, file).then(res => {
            if (res !== apiError) {
                if (validateVar(params.internal)) {
                    if (validateVar(params.internal.success_message)) dispatch({ type: "SET_USER_MESSAGE", res: displaySuccessMessage(params.internal.success_message, "Erfolg")});
                    if (validateVar(params.internal.success_message)) dispatch({ type: (validateVar(params.internal.dispatch_type)) ? params.internal.dispatch_type  : "SET_USER_MESSAGE", res: res});
                } else {
                    dispatch({ type: "ADD_CHRONIK", res: res });
                }
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim hinzufügen der Chronik ist etwas schief gelaufen. (Fehlercode: )", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}

export function initializeChronik(params, site_name = null, id = null) {
    return function(dispatch) {
        Chronik.init(params, site_name, id).then(res => {
            if (res !== apiError) {
                dispatch({ type: "INIT_CHRONIK", res: res });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim Laden der Chronik ist etwas schief gelaufen. (Fehlercode: ", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}


export function initializeSingleContract(contract_id) {
    return function (dispatch) {
        Contract.instantiateContract(contract_id).then(res => {
            if (res !== apiError)
                dispatch({type: "GET_SINGLE_CONTRACT", res: res});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Laden des Auftragsdaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetContractError + ")", // content
                        "Fehler", //headline
                        ContractsPath, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function upload_snigned_contract(params, file, hide_success_message = false) {
    return function (dispatch) {
        Contract.upload_snigned_contract(params, file).then(res => {
            if (res !== apiError) {
                if(params.clearing){
                    dispatch({type: "UPLOAD_SIGNED_CONTRACT_CLEARING", res: res});
                }else{
                    if(project_name === 'EVU_PROJECT'){
                        dispatch({type: "UPLOAD_SIGNED_CONTRACT_EHUB", res: res});
                    }else {
                        dispatch({type: "UPLOAD_SIGNED_CONTRACT_ENERMAKLER", res: res});
                    }
                    if(!hide_success_message) dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "Ihre Vertragsdatei wurde erfolgreich hochgeladen", // content
                            "Datei hochgeladen", //headline
                        ),
                    });
                }
            }
            else {
                dispatch({type: "UPLOAD_SIGNED_CONTRACT", res: res});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Hochladen des unterschriebenen Vertragsdokuments ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UploadSignedContractError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätigen",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function resetContractGenerator() {
    return function (dispatch) {
        dispatch({type: "RESET_GENERATE_CONTRACT", res: null})
    }
}


export function generateContract(params) {
    return function (dispatch) {
        Contract.generateContract(params).then(res_generated => {
            if (res_generated !== apiError) { // success
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "", //  content
                        "",  // headline
                        Calculator + Verification + '/' + params.token, // url
                        true, // automatic_redirect
                        false, // modal
                    ),
                });
            } else { // error in generate contract
                dispatch({type: "GENERATE_CONTRACT", res: apiError});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Generieren des Vertragsdokuments ist ein Fehler aufgetreten. Bitte wählen Sie einen anderen Tarif. (Fehlercode: " + ERROR_CODE.GenerateContractError + ")", // content
                        "Fehler", //headline
                        null,
                        false, //automatic redirect
                        true,       //modal
                    ),
                });
            }
        });
    };
}


export function displayContract(params, download_contract = false) {
    return function (dispatch) {
        Contract.displayContract(params, download_contract).then(res => {
            if (res !== apiError) dispatch({type: "GENERATE_CONTRACT", res: res.url});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Laden des Vertragsdokuments ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DisplayContractError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätigen",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }

        });
    };
}


export function getContractForOffer(params, guestPage = false) {

    //In case its guest page, display different error modal
    let url = Calculator + Data;
    let primaryBtnText = "Zurück";
    let secondaryBtnText = null;
    let allowToggle = false;
    if(guestPage){
        url = '';
        primaryBtnText = null;
        secondaryBtnText = "Bestätigen";
        allowToggle = true;
    }

    return function (dispatch) {
        Contract.getContractForOffer(params).then(res => {
            if (res !== apiError) {
                let {tariff, offer} = res;
                dispatch({type: "OFFER_BY_TOKEN", res: offer});
                dispatch({type: "TARIFF_GET", res: tariff});
            } else {
                console.log("error ")

                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim Laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                        // ERROR_CODE.GetConsumptionPoints +
                        ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });

                // dispatch({
                //     type: "SET_USER_MESSAGE",
                //     res: displayErrorMessage(
                //         "Bei dem laden der Vertragsdaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetContractForOfferError + ")", // content
                //         "Fehler", //headline
                //         url, // url
                //         false, //automatic redirect
                //         true,       //modal
                //         primaryBtnText, // primaryBtnText
                //         secondaryBtnText,// secondaryBtnText
                //         allowToggle, //allowToggle (user can close modal without pressing a btn)
                //         false,// successIcon
                //         false,// infoIcon
                //         "danger" //type - danger/success
                //     ),
                // });
                // dispatch({type: "TARIFF_GET", res: apiError});
            }

        });
    };
}


export function updateContract(params, updateFromGuestpage = false, initialize_response = false) {
    return function (dispatch) {
        Contract.update(params).then(res => {
            if (res !== apiError) {
                if(is_true(initialize_response)){
                    dispatch({type: "INITIALIZE_CONTRACT", res: res});
                }else{
                    if(!updateFromGuestpage){
                        let redirect_url = Contactoverview + "/" + params.customer_id;
                        if(project_name === project_names.wattline) redirect_url = CalculationUnits;
                        let new_date = new Date();
                        new_date = handleFormatDate(new_date, true); //convertig Date object to string for getGermanDateFormat to handle it
                        new_date = getGermanDateFormat(new_date, true);
                        if(params.status === 'customer_checkout'){
                            let message = <div>Vielen Dank, die Vertragsdokumente wurden per E-Mail versandt! Sobald der Vertragspartner diese bestätigt hat,
                                übertragen wir den Vertrag automatisch zur Überprüfung an den Energieversorger. <br/><br/> Wir leiten Sie nun zu Ihrem
                                Kontakt weiter. Dort können Sie den Status jederzeit einsehen. <br/><br/> <b>Versendet: {new_date} <br/> Gültig bis: {res.valid_until_display}</b></div>;
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displaySuccessMessage(
                                    message,
                                    "Vertragsdokumente versandt",
                                    redirect_url,
                                    false,
                                    true,
                                    "OK",
                                    null
                                ),
                            });
                        }else{
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displaySuccessMessage(
                                    "Der Auftrag wurde erfolgreich übertragen und wird vom Energieversorger innerhalb von zwei Werktagen bearbeitet. Wir leiten Sie nun zu Ihrem Kontakt weiter. Dort können Sie den Auftragsstatus jederzeit einsehen.",
                                    "Herzlichen Glückwunsch!",
                                    redirect_url,
                                    false,
                                    true,
                                    "OK",
                                    null
                                ),
                            });
                        }
                    }else{//On the GuestPage there is a different success message needed
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displaySuccessMessage(
                                "Der Vertrag wurde erfolgreich übertragen und wird vom Energieversorger innerhalb von zwei Werktagen bearbeitet. Wir benachrichtigen Sie sobald sich der Vertragsstatus geändert hat. Sie können die Seite nun schließen.",
                                "Herzlichen Glückwunsch!",
                                null,
                                false,
                                true,
                                null,
                                null,
                        )})
                    }
                }
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim aktualisieren der Auftragsdaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UpdateContractError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Ok",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
                if(updateFromGuestpage) { //Guest Page needs this error feedback
                    dispatch({type: "INITIALIZE_CONTRACT", res: apiError});
                }
            }
        });
    };
}


export function initConsumptionPoint(params, predicion = 1, extended = 1, cancellation = 1, memos = 0, predicted_consumption = 0) {
    params.options = {
        with_prediction: predicion,
        with_extended_information: extended,
        with_memos: memos,
        with_predicted_consumption: predicted_consumption,
        with_load_profile_name: 1,
    };
    //API throws an Error when with_cancellation is given as 0 at init consumptionpoint for edit in enermakler
    if(+cancellation === 1) params.options.with_cancellation = 1;

    return function(dispatch) {
        ConsumptionPoint.instantiate(params).then(res => {
            if (res !== apiError) {
                dispatch({ type: "INITIALIZE_CONSUMPTION_POINT", res: res });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim Laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                        // ERROR_CODE.GetConsumptionPoints +
                        ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}

export function modifyCustomer(params) {
    return function(dispatch) {
        Customer.updateCustomer(params).then(res => {
            if (res !== apiError) {
                dispatch({ type: "UPDATE_CUSTOMER", res: res });
                if (params.protocol_id) dispatch({ type: "UPDATE_HISTORY", res: res });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Fehler beim aktualisieren der Kundendaten",
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}



export function modifyConsumptionPoint(params, predicion = 1, extended = 1) {
    params.options = {
        with_prediction: predicion,
        with_extended_information: extended,
        with_load_profile_name: 1
    };
    return function(dispatch) {
        ConsumptionPoint.modifyConsumptionPoint(params).then(res => {
            if (res !== apiError && res !== zipNotFound) {
                dispatch({ type: "UPDATE_CONSUMPTION_POINTS", res: res });
            } else if (res === zipNotFound) {
                dispatch({ type: "UPDATE_CONSUMPTION_POINTS", res: apiError });
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die angegebene Postleitzahl gibt es nicht.", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            } else {
                dispatch({type: "CONSUMPTION_POINT_ERROR", res: apiError});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim Aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                        // ERROR_CODE.GetConsumptionPoints +
                        ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}




export function initializeConsumptionPointsForContract(
    id,
    predicion = 1,
    extended = 1,
) {
    let params = {
        contract_id: id,
        options: {
            with_prediction: predicion,
            with_extended_information: extended,
        },
        type: "extended",
    };
    return function (dispatch) {
        ConsumptionPoint.instantiateForContract(params).then(res => {
            if (res !== apiError)
                dispatch({type: "GET_CONSUMPTION_POINTS_FOR_CONTRACT", cps: res});
            else {
                dispatch({type: "GET_CONSUMPTION_POINTS_FOR_CONTRACT", cps: []});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim laden der Abnahmestellen des Auftrages ist ein Fehler aufgetreten. (Fehlercode: )", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Ok",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function notification_viewed(params, url = null) {
    return function(dispatch) {
        Notification.handle_viewed(params, "PUT").then(res => {
            if (res !== apiError) {
                if (validateVar(url)) {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "", // content
                            "", //headline
                            url, // url
                            true, //automatic redirect
                            false //modal
                        )
                    });
                } else {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "Der Status wurde erfolgreich bearbeitet", // content
                            "Erfolg", //headline
                            null, // url
                            false, //automatic redirect
                            false //modal
                        )
                    });
                    dispatch({ type: "NOTIFICATION_VIEWED_COMPANY", res: res });
                }
            } else {
                dispatch({ type: "NOTIFICATION_VIEWED", res: apiError });
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim dem Aufrufen der Benachrichtigungen ist ein Fehler aufgetreten.", // content
                        "Fehler", //headline
                        null,
                        false, //automatic redirect
                        true, //modal
                        null,
                        "Zurück", // secondaryBtnText
                        true //allowToggle (user can close modal without pressing a btn)
                    )
                });
            }
        });
    };
}

// ----------------------- CUSTOMER -------------------------------------


export function getValidCustomerName(params, url) {
    return function (dispatch) {
        Buergel.getValidCustomerName(params).then(res => {
            dispatch({type: "VALIDATE_CUSTOMER_NAME", res: res});
            // if (res !== apiError){
            //     dispatch({ type: "VALIDATE_CUSTOMER_NAME", res: res });
            //
            // } else{ // error
            //     dispatch({ type: "VALIDATE_CUSTOMER_NAME", res: res });
            //     dispatch({
            //         type: "SET_USER_MESSAGE",
            //         res: displayErrorMessage(
            //             "Bei dem validieren des Kundennamens ist ein Fehler aufgetreten, ggf. kann die Bonität des Kunden nicht ermittelt werden. (Fehlercode: " + ERROR_CODE.ValidateCustomerNameError + ")", // content
            //             "Fehler", //headline
            //             null, // url
            //             false, //automatic redirect
            //             false,       //modal
            //         ),
            //     });
            // }
        });
    };
}

export function createCustomer(params, url) {
    return function (dispatch) {
        Customer.createCustomer(params).then(res => {
            if (res !== apiError) {
                // no redirect
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "Der Kunde wurde erfolgreich angelegt.",
                        "Herzlichen Glückwunsch!",
                        Contactoverview + "/" + res.id,// url
                        false,//automatic redirect
                        true,   //modal
                        "Zum Kunden",
                        "Zur Kontaktübersicht", //  secondaryBtnText
                        false, //allowToggle (user can close mo0dal without pressing a btn)
                        true,// successIcon
                        false,// infoIcon
                        Contacts,// url
                    ),
                });

            } else { // error
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem anlegen des Kunden ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UpdateCustomerError + ")", // content
                        "Fehler", //headline
                        Contacts, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück zur Kundenübersicht", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                    ),
                });
            }
        });
    };
}

export function createCustomerAndBankAccount(params, url, bank_params) {
    return function (dispatch) {
        Customer.createCustomer(params).then(res => {
            if (res !== apiError) {
                let customer_id = res.id;
                bank_params.customer_id = customer_id;
                BankAccount.createBankAccount(bank_params).then(res => {
                    if (res !== apiError) {
                        dispatch({type: "CREATE_BANK_ACCOUNT", res: res});
                        if (validateVar(url)) { // redirect
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displaySuccessMessage(
                                    "Der Kunde wurden erfolgreich angelegt.",
                                    "Erfolg!",
                                    url + customer_id,
                                    true,
                                ),
                            });
                        } else { // no redirect
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displaySuccessMessage(
                                    "Der Kunde wurden erfolgreich angelegt.",
                                    "Erfolg!",
                                ),
                            });
                        }
                    } else {
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displayErrorMessage(
                                "Fehler beim anlegen des Bank Accounts."
                            )
                        });
                    }
                });
            } else { // error
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Der Kontakt konnte nicht angelegt werden."
                    )
                });
            }
        });
    };
}

export function deleteCustomer(selected_array) {
    // return { type: "DELETE_CUSTOMER", ids: ids };
    return function (dispatch) {
        Customer.deleteMultipleCustomers(selected_array).then(res => {
            if (res !== apiError) {
                let deleted_ids = res[0];// all deleted ids for updating redux state
                let deleted_count = 0;  // how many data obj were deleted? ( user feedback )
                if (validateVar(deleted_ids) && deleted_ids.length > 0) deleted_count = deleted_ids.length;
                let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
                let blocked_count = res[2]; // how many data obj could not be deleted because they are blocked by the API
                let total_count = selected_array.length; // how many data obj for delete in total

                let head_line = "";
                let content = "";
                let info_icon = false;
                let success_icon = true;

                if (error_count === 0 && blocked_count === 0) { // no delete request failed -> total success
                    content = deleted_count + " von " + total_count + " Kontakte wurden gelöscht"; // content
                    head_line = 'Erfolg';
                } else if (error_count === 0 && blocked_count === total_count) {
                    content = 'Kontakte die bereits abgeschlossene Verträge haben können nicht gelöscht werden. (Dies erkennen Sie am Kontaktstatus "Kunde")';
                    head_line = 'Geblockt';
                    info_icon = true;
                    success_icon = false;
                } else if (error_count > 0 || blocked_count > 0) {
                    let sum_failed = error_count + blocked_count;
                    content = deleted_count + " von " + total_count + " Kontakten wurden gelöscht. Bei " + sum_failed + " Kontakt(en) ist das löschen fehlgeschlagen, oder ist aufgrund eines abgeschlossenen Vertrages geblockt.";
                    info_icon = true;
                    success_icon = false;
                }
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        content,
                        head_line, //headline
                        null, // url,
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        success_icon,// successIcon
                        info_icon,// infoIcon
                    ),
                });
                // send deleted ids to redux to filter out the deleted data obj from state
                dispatch({
                    type: "DELETE_CUSTOMER",
                    ids: deleted_ids
                });
            } else { // Error feedback for customer -> could not delete data
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem löschen der Kontakte ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DeleteCustomerError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    }
}

export function updateCustomer(params, url = null) {
    return function (dispatch) {
        Customer.updateCustomer(params).then(res => {
            if (res !== apiError) { //success
                if (validateVar(url)) { // redirect
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "Die Kundendaten wurden erfolgreich aktualisiert.",
                            "Erfolg!",
                            url,
                            true,
                        ),
                    });
                } else { // no redirect just success message
                    return {type: "UPDATE_CUSTOMER", res: res};
                }
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem aktualisieren der Kundendaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UpdateCustomerError + ")", // content
                        "Fehler", //headline
                        Calculator + Data, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function getCustomerList(params = {}) {
    return function (dispatch) {
        Customer.instantiateList(params).then(res => {
            if (res !== apiError) dispatch({type: "CUSTOMER_LIST", res: res});
            else {
                dispatch({type: "CUSTOMER_LIST", res: []});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim laden der Kundenliste ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetCustomerListError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Schließen",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger", //type - danger/success
                        //type - danger/success
                    ),
                });
            }


        });
    };
}

export function getCustomer(id) {
    return function (dispatch) {
        Customer.instantiateCustomer(id).then(res => {
            if (res !== apiError)
                dispatch({type: "SINGLE_CUSTOMER", res: res});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim laden des Kunden ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UploadFilesForCustomerError + ")", // content
                        "Fehler", //headline
                        Contacts, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function getOffersForCustomer(customer_id) {

    return function (dispatch) {
        Offer.getOffersForCustomer(customer_id).then(res => {
            if (res !== apiError) {
                dispatch({type: "OFFER_FOR_CUSTOMER", res: res});
            } else {
                dispatch({type: "OFFER_FOR_CUSTOMER", res: []});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim laden der gespeicherten Angebote ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UploadFilesForCustomerError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Ok",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                    ),
                });
            }
        });
    };
}


export function deleteSavedOffers(selected_array) {
    return function (dispatch) {
        Offer.deleteMultipleOffers(selected_array).then(res => {
            if (res !== apiError) {
                let deleted_ids = res[0];// all deleted ids for updating redux state
                let deleted_count = 0;  // how many data obj were deleted? ( user feedback )
                if (validateVar(deleted_ids) && deleted_ids.length > 0) deleted_count = deleted_ids.length;
                let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
                let total_count = selected_array.length; // how many data obj for delete in total
                if (error_count === 0) { // no delete request failed -> total success
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count + " von " + total_count + " Angebote wurden gelöscht", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                        ),
                    });
                } else { // delete went through but some data threw a error message
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count + " von " + total_count + " Angebote wurden gelöscht, bei " + error_count + " von " + total_count + " ist das löschen Fehlgeschlagen", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            true,// infoIcon
                        ),
                    });
                }

                // send deleted ids to redux to filter out the deleted data obj from state
                dispatch({type: "DELETE_SAVED_OFFERS", ids: deleted_ids});
            } else { // Error feedback for customer -> could not delete data
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem löschen der Angebote ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DeleteEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    }
}

export function uploadFilesForCustomer(params, file) {
    return function (dispatch) {
        File.uploadFilesForCustomer(params, file).then(res => {
            if (res !== apiError) {
                dispatch({type: "UPLOAD_FILE_FOR_CUSTOMER", res: res});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displaySuccessMessage(
                        "Die Datei wurde erfolgreich hochgeladen.", // content
                        "Erfolg", //headline
                        null, // url, // url
                        false, //automatic redirect
                        false,       //modal
                    ),
                });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Hochladen der Datei ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UploadFilesForCustomerError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        "Ok", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function downloadFilesFromCustomer(ids, type = "application/pdf") {
    return function (dispatch) {
        ids.map((id) => {
            File.down_load_default_file(id, type).then(res => {
                if (res === apiError) {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            "Bei dem Exportieren der Datei ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DownLoadCustomerFileError + ")", // content
                            "Fehler", //headline
                            null, // url
                            false, //automatic redirect
                            false,       //modal
                        ),
                    });
                }
            });
        });

    };
}


export function deleteFilesFromCustomer(selected_array) {
    return function (dispatch) {
        File.deleteMultipleFiles(selected_array).then(res => {
            if (res !== apiError) {
                let deleted_ids = res[0];// all deleted ids for updating redux state
                let deleted_count = 0;  // how many data obj were deleted? ( user feedback )
                if (validateVar(deleted_ids) && deleted_ids.length > 0) deleted_count = deleted_ids.length;
                let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
                let total_count = selected_array.length; // how many data obj for delete in total
                if (error_count === 0) { // no delete request failed -> total success
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count + " von " + total_count + " Dateien wurden gelöscht", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                        ),
                    });
                } else { // delete went through but some data threw a error message
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            deleted_count + " von " + total_count + " Dateien wurden gelöscht, bei " + error_count + " von " + total_count + " ist das löschen Fehlgeschlagen", // content
                            "Erfolg", //headline
                            null, // url, // url
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            true,// infoIcon
                        ),
                    });
                }

                // send deleted ids to redux to filter out the deleted data obj from state
                dispatch({type: "DELETE_SAVED_FILES", ids: deleted_ids});
                // todo send redux request for state update
            } else { // Error feedback for customer -> could not delete data
                dispatch({type: "DELETE_SAVED_FILES_ERR", res: null});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem löschen der Dateien ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.DeleteEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    }
}


export function getContractsForCustomer(id) {
    return function (dispatch) {
        Contract.getAllContractsForUser(id).then(contract_res => {
            if (contract_res !== apiError) {
                dispatch({type: "CONTRACT_LIST", res: contract_res});
            } else {
                dispatch({type: "CONTRACT_LIST", res: []});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim laden der Aufträge zu diesem Kunden ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetContractsForCustomerError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Ok",// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function getFilesForCustomer(id) {
    return function (dispatch) {
        File.getFilesForCustomer(id).then(contract_res => {
            if (contract_res !== apiError) dispatch({type: "GET_FILES_FOR_CUSTOMER", res: contract_res});
            else {
                dispatch({type: "GET_FILES_FOR_CUSTOMER", res: []});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Laden der von Ihnen hinterlegten Kunden-Dateien ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetUploadedFilesForCustomerError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Ok",// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function upload_file_new(params, file, field_name) {
    return function(dispatch) {
        File.upload_file_new(params, file).then(res => {
            if (res !== apiError) {
                let result = res;
                if (validateVar(field_name)) result = { ...res, field_name };
                dispatch({ type: "FILE_UPLOADED", res: result });
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem Hochladen der Datei ist ein Fehler aufgetreten.",
                        "Fehler", //headline
                        null, // url
                        tariff_list, // url
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}

export function dynamic_action_call(
    class_name,
    function_name,
    dispatch_type,
    params,
    file = null,
    field_name = null
) {
    return function(dispatch) {
        let class_object = callClassWithString(class_name, null);
        class_object.constructor[function_name](params, file).then(res => {
            let result = res;
            if (validateVar(field_name)) result = { ...res, field_name };
            if (res !== apiError) {
                dispatch({ type: dispatch_type, res: result });
            } else {
                dispatch({ type: dispatch_type, res: apiError });
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Es ist ein Fehler aufgetreten.",
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}

export function getConsumptionPointsForCustomer(id) {
    return function (dispatch) {
        ConsumptionPoint.getAllConsumptionPointsForUser(id).then(consumption_point_res => {
            if (consumption_point_res !== apiError)
                dispatch({type: "GET_CONSUMPTION_POINT_FOR_CUSTOMER", res: consumption_point_res});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim laden der Abnahmestellen dieses Kunden ist ein Fehler aufgetreten.", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Ok",// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function modify_offer_res(params) {
    return function(dispatch) {
        Contract.modify_offer_res(params).then(res => {
            if (res !== "ERR") {
                if (validateVar(params.protocol_id)) dispatch({ type: "UPDATE_HISTORY", res: res });
                else {
                    let params_get = {
                        id: res.id,
                        employee_id: params.employee_id,
                        supplier_id: params.supplier_id,
                    }
                    Offer.handle_price_ranking(params_get, 'GET').then(res => {
                        if (res !== "ERR") {
                            dispatch({type: "INIT_OFFER", res: res});
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displaySuccessMessage(
                                    "Das Angebot wurde aktualisiert."
                                )
                            });
                        }else{
                            dispatch({
                                type: "SET_USER_MESSAGE",
                                res: displayErrorMessage(
                                    "Bei dem Laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                                    // ERROR_CODE.GetLoadProfile +
                                    ")", // content
                                    "Fehler", //headline
                                    null,
                                    false, //automatic redirect
                                    true, //modal
                                    null,
                                    "Zurück", // secondaryBtnText
                                    true //allowToggle (user can close modal without pressing a btn)
                                )
                            });
                        }
                    })
                }
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                        // ERROR_CODE.GetLoadProfile +
                        ")", // content
                        "Fehler", //headline
                        null,
                        false, //automatic redirect
                        true, //modal
                        null,
                        "Zurück", // secondaryBtnText
                        true //allowToggle (user can close modal without pressing a btn)
                    )
                });
            }
        });
    };
}


export function updateMultipleConsumptionPointsForContract(
    params,
    selected_array
) {
    return function(dispatch) {
        ConsumptionPoint.updateMultipleCpsForContract(params, selected_array).then(
            res => {
                if (res !== apiError) {
                    let updated_cps = res[0]; // all deleted ids for updating redux state
                    let updated_count = 0; // how many data obj were deleted? ( user feedback )
                    if (validateVar(updated_cps) && updated_cps.length > 0)
                        updated_count = updated_cps.length;
                    let error_count = res[1]; // how many data obj could not be deleted? (user feedback)
                    let total_count = selected_array.length; // how many data obj for delete in total
                    if (error_count === 0) {
                        // no update request failed -> total success
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displaySuccessMessage(
                                updated_count +
                                " von " +
                                total_count +
                                " Lieferstelle(n) wurde(n) aktualisiert", // content
                                "Erfolg", //headline
                                null, // url, // url
                                false, //automatic redirect
                                true, //modal
                                null, // primaryBtnText
                                "Bestätige", // secondaryBtnText
                                true //allowToggle (user can close modal without pressing a btn)
                            )
                        });
                    } else {
                        // update went through but some data threw a error message
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displaySuccessMessage(
                                updated_count +
                                " von " +
                                total_count +
                                " Lieferstelle(n) wurde(n) gelöscht, bei " +
                                error_count +
                                " von " +
                                total_count +
                                " ist das löschen Fehlgeschlagen. (Auf 'aktiv' gesetzte PFCs werden übersprungen)", // content
                                "Erfolg", //headline
                                null, // url, // url
                                false, //automatic redirect
                                true, //modal
                                null, // primaryBtnText
                                "Bestätige", // secondaryBtnText
                                true, //allowToggle (user can close modal without pressing a btn)
                                false, // successIcon
                                true // infoIcon
                            )
                        });
                    }
                    dispatch({
                        type: "UPDATE_MULTIPLE_CONSUMPTION_POINTS_FOR_CONTRACT",
                        res: res
                    });
                } else {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            "Bei dem laden der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                            // ERROR_CODE.GetConsumptionPoints +
                            ")", // content
                            "Fehler", //headline
                            null, // url
                            false, //automatic redirect
                            true, //modal
                            null, // primaryBtnText
                            "Bestätigen", // secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false, // successIcon
                            false, // infoIcon
                            "danger" //type - danger/success
                        )
                    });
                }
            }
        );
    };
}


export function updateTariff(params, url = null) {
    return function(dispatch) {
        Tariff.update(params).then(res => {
            if (res !== apiError) {
                if (validateVar(url)) {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "", //  content
                            "", // headline
                            url, // url
                            true, // automatic_redirect
                            false // modal
                        )
                    });
                } else {
                    dispatch({ type: "UPDATE_TARIFF", res: res });
                }
            } else {
                dispatch({ type: "UPDATE_TARIFF", res: apiError });
                // error
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim Aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                        // ERROR_CODE.UpdateTariff +
                        ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätige", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}

export function update_history_changes(params, function_url = null) {
    return function(dispatch) {
        History.update_history_changes(params, function_url).then(res => {
            if (res !== apiError) {
                dispatch({ type: "UPDATE_HISTORY", res: res });
            } else {
                dispatch({ type: "UPDATE_HISTORY", res: apiError });
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem aktualisieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " +
                        // ERROR_CODE.GetConsumptionPoints +
                        ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätigen", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}

export function reset_history() {
    return function(dispatch) {
        dispatch({ type: "RESET_HISTORY", res: null });
    };
}


export function uploadFile(params, file) {
    return function(dispatch) {
        File.addFile(params, file).then(res => {
            if (res !== apiError && validateVar(res.file_id)) {
                dispatch({ type: "FILE_UPLOADED", res: res.file_id });
            } else {
                //Errormessage is shown in updateSingleEmployee else case
            }
        });
    };
}

// ----------------------- CUSTOMER END ------------------------------------

// --------------------------- FILE --------------------------------------

export function addFile(upload_params, file) {
    return function (dispatch) {
        File.addFile(upload_params, file).then(upload_file_res => {
            if (upload_file_res !== apiError) {
                dispatch({type: "FILE_UPLOAD", res: upload_file_res});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim Hochladen der Datei ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.FileUploadError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Ok",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


// --------------------------- OFFER ----------------------------------------


export function predict_load_profile() {
    return function (dispatch) {
    }
}


export function createOfferAndCalculate(params) {
    return function (dispatch) {
        Offer.createOffer(params).then(res_offer => {
            if (res_offer !== apiError) {
                Calculation.calculate({offer_id: res_offer.offer_id, customer_id: res_offer.customer_id}).then(res_calc => {
                    if (res_calc !== apiError) {
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displaySuccessMessage(
                                "Die Kundendaten wurden erfolgreich aktualisiert.",
                                "Erfolg!",
                                Calculator + Compare + "/" + res_offer.token,
                                true,
                            ),
                        });
                    } else {
                        dispatch({type: "OFFER_CREATED", res: "ERR"});
                        dispatch({
                            type: "SET_USER_MESSAGE",
                            res: displayErrorMessage(
                                "Beim Kalkulieren Ihrer Daten ist etwas schief gelaufen.", // content
                                "Fehler", //headline
                                null, // url
                                true, //automatic redirect
                                true,       //modal
                                null, // primaryBtnText
                                "Zurück",// secondaryBtnText
                                false, //allowToggle (user can close modal without pressing a btn)
                                false,// successIcon
                                false,// infoIcon
                                "danger" //type - danger/success
                            ),
                        });
                    }
                });
            } else {
                dispatch({type: "OFFER_CREATED", res: "ERR"});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim anlegen Ihres Angebots ist etwas schief gelaufen. (Fehlercode: " + ERROR_CODE.CreateOfferError + ")", // content
                        "Fehler", //headline
                        null, // url
                        true, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Zurück",// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function save_offer_result(params, url = null) {
    return function (dispatch) {
        Offer.save_offer_result(params).then(res => {
            if (res !== apiError) {
                dispatch({type: "SAVE_RESULT", res: "SAVED"});
                if (validateVar(url)) { // if user wants return modal
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "Das Angebot wurde erfolgreich versendet und wird zum Kundendatensatz zugewiesen. Sie werden benachrichtigt sobald der Kunde auf das Angebot reagiert hat.", // content
                            "Erfolg", //headline
                            Contactoverview+'/'+params.customer_id, // url
                            false, //automatic redirect
                            true,       //modal
                            "Zum Kunden", // primaryBtnText
                            "Zum Kalkulator",// secondaryBtnText
                            false, //allowToggle (user can close modal without pressing a btn)
                            true,// successIcon
                            false,// infoIcon
                            Calculator + Data
                        ),
                    });

                }else {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "Das Angebot wurde erfolgreich gespeichert.", // content
                            "Erfolg!", //headline
                        ),
                    });
                }
            } else {
                if (validateVar(url)) { // if user wants return modal
                    dispatch({type: "SAVE_RESULT", res: "ERR"});
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            "Beim hinzufügen Ihres Angebots zum Kunden ist etwas schief gelaufen, das Angebot wurde jedoch wie beabsichtigt an den Kunden versandt und muss nicht erneut versendet werden." +
                            " Sie werden benachrichtigt sobald sich der Status des Angebots verändert. (Fehlercode: " + ERROR_CODE.SaveOfferErrorInSign + ")", // content
                            "Fehler", //headline
                            null, // url
                            true, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätigen",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            false,// infoIcon
                            "danger" //type - danger/success
                        ),
                    });
                }else {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            "Beim anlegen Ihres Angebots ist etwas schief gelaufen. (Fehlercode: " + ERROR_CODE.SaveOfferError + ")", // content
                            "Fehler", //headline
                            null, // url
                            true, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätigen",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            false,// infoIcon
                            "danger" //type - danger/success
                        ),
                    });
                }
            }
        });
    };
}

export function getOfferByToken(token, guestPage = false) {
    //if request called from guest page, prevent user from hiding the error modal
    let url = Calculator + Data;
    let primaryButtonText = "Zurück";
    let secondaryButtonText = null;
    if(guestPage){
        url = null;
        primaryButtonText = null;
        secondaryButtonText = "Bestätigen";
    }

    return function (dispatch) {
        Offer.getOfferByToken(token).then(res_offer => {
            if (res_offer !== apiError) dispatch({type: "OFFER_BY_TOKEN", res: res_offer});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim abfragen des Angebots ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetOfferByTokenError + ")", // content
                        "Fehler", //headline
                        url, // url
                        false, //automatic redirect
                        true,       //modal
                        primaryButtonText, // primaryBtnText
                        secondaryButtonText,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
                dispatch({type: "OFFER_BY_TOKEN", res: apiError});
            }

        });
    };
}

export function getOffer(params) {
    return function (dispatch) {
        Offer.getOffer(params).then(res_offer => {
            if (res_offer !== apiError) dispatch({type: "OFFER_BY_TOKEN", res: res_offer});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim abfragen des Angebots ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetOfferError + ")", // content
                        "Fehler", //headline
                        Calculator + Data, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }

        });
    };
}

export function update_offer(params) {
    return function (dispatch) {
        console.log("modify offer  params", params);
        Offer.updateOffer(params).then(res_offer => {
            if (res_offer !== apiError) {
                dispatch({type: "UPDATE_OFFER", res: res_offer});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem aktualisieren des Angebots ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UpdateOfferError + ")", // content
                        "Fehler", //headline
                        Calculator + Data, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function addComparison(params) {
    return function (dispatch) {
        Tariff.addComparisonTariff(params).then(res => {
            if (res.status === "ok") {
                let offerParams = {
                    offer_id: params.offer_id,
                    customer_id: params.customer_id,
                };
                Offer.getOffer(offerParams).then(res_offer => {
                    dispatch({type: "OFFER_BY_TOKEN", res: res_offer});
                });

            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Fehler bei dem hinzufügen eines Vergleichangebotes. (Fehlercode: " + ERROR_CODE.AddComparisonTariffToOfferError + ")", // content
                        "Fehler", //headline
                        Calculator + Data, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        "Abbrechen",// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function deleteComparisonTariff(params) {
    return function (dispatch) {
        Tariff.remove_comparison_result(params).then(res => {
            if (res.status === "ok") {
                dispatch({type: "REMOVE_COMPARISON_TARIFF", res: "TARIFF_REMOVED"});
                let offerParams = {
                    offer_id: params.offer_id,
                    customer_id: params.customer_id,
                };
                Offer.getOffer(offerParams).then(res_offer => {
                    dispatch({type: "OFFER_BY_TOKEN", res: res_offer});
                });

            } else {
                dispatch({type: "REMOVE_COMPARISON_TARIFF", res: apiError});

                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Fehler bei dem hinzufügen eines Vergleichangebotes. (Fehlercode: " + ERROR_CODE.AddComparisonTariffToOfferError + ")", // content
                        "Fehler", //headline
                        Calculator + Data, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        "Abbrechen",// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

// ------------------ CALCULATE ----------------------------


export function getCalculatedTariffs(offer_id) {
    return function (dispatch) {
        Tariff.getCalculatedTariffs(offer_id).then(res_tariffs => {
            if (res_tariffs !== apiError) dispatch({type: "CALCULATED_TARIFFS", res: res_tariffs});
            else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim abfragen der Tarife ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetCalculatedTariffsError + ")", // content
                        "Fehler", //headline
                        Calculator + Data, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }

        });
    };
}

export function calculateTariffs(params, url = null, refresh_offer = false) {
    return function (dispatch) {
        if(refresh_offer) dispatch({type: "CALCULATED_TARIFFS", res: null});
        Calculation.calculate(params).then(res_tariffs => {
            if (res_tariffs !== apiError) {
                if (!validateVar(url)) {
                    dispatch({type: "CALCULATED", res: res_tariffs});
                    if(refresh_offer) dispatch({type: "UPDATE_OFFER", res: null});
                } else {
                    //autoRidericet after calculating on refreshOffer
                    dispatch({
                        type: "SET_USER_MESSAGE", res: displaySuccessMessage(
                            "",
                            "",
                            url,
                            true,
                        )
                    });
                }
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim neukalkulieren der Daten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.ReCalculateTariffsError + ")", // content
                        "Fehler", //headline
                        Calculator + Data, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        null,// secondaryBtnText
                        false, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function getNetFee(params, contract_duration) {
    return function (dispatch) {
        Netfee.getNetFee(params, contract_duration).then(res_net_fee => {
            if (res_net_fee !== apiError) {
                dispatch({type: "GET_NET_FEE", res: res_net_fee});
            } else {
                dispatch({type: "GET_NET_FEE", res: res_net_fee});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei der Abfrage der Netzentgelte ist ein Fehler aufgetreten, dies nimmt jedoch keinen Einfluss auf Ihr Angebot. (Fehlercode: " + ERROR_CODE.GetNetFeeError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Fortfahren",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


// --------------------------- OFFER RES -----------------------------------


export function createComparisionFile(params, delete_params = null) {
    return function (dispatch) {
        ComparisonFile.createComparisionFile(params, delete_params).then(res => {
            if (res  !== apiError) {
                dispatch({ type: "CREATE_COMPARISON_FILE", res: res.url});
            }
            else{
                dispatch({ type: "CREATE_COMPARISON_FILE", res: "ERR"});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim erstellen des Vergleichsdokuments ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.CreateComparisonFileError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                    ),
                });
            }
        });
    };
}

//download csv from Contract
export function download_csv_contracts_export(params) {
    return function (dispatch) {
        let current_date = handleFormatDate(new Date());
        params.file_name = 'contract_export-'+current_date;
        Contract.download_csv_export(params).then(res => {
            if(!is_true(res)){
                dispatch({
                    type: "SET_USER_MESSAGE", res: displayErrorMessage("Beim Erzeugen der Export-CSV ist ein Fehler aufgetreten.")
                });
            }
            dispatch({
                type: "DOWNLOAD_FILE", res: true
            });
        })
    }
}

export function reset_download_file(){
    return function (dispatch){
        dispatch({type: 'RESET_DOWNLOAD_FILE', res: false});
    }
}

export function createContract(params) {
    return function (dispatch) {
        Contract.createContract(params).then(res => {
            if (res !== apiError && res !== "Bound") {
                if (is_true(params.generate_contract)) {
                    dispatch({type: "GENERATE_CONTRACT", res: res})
                }else {
                    dispatch({
                        type: "SET_USER_MESSAGE", res: displaySuccessMessage(
                            "",
                            "",
                            Calculator + ContractData + "/" + res.contract_token, // url
                            true,
                        )
                    });
                }
            } else if (res === "Bound") {
                dispatch({type: "CREATE_CONTRACT", res: apiError});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Diese Abnahmestelle ist für den von Ihnen gewählten Zeitraum bereits unter Vertrag. Bitte wählen Sie eine andere Abnahmestelle oder wechseln sie den Liferzeitraum auf eine valide Eingabe. (Fehlercode: " + ERROR_CODE.CreateContractDoublicatedError + ")", // content
                        "Fehler", //headline
                        Calculator + Data, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Zurück",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            } else {
                dispatch({type: "CREATE_CONTRACT", res: apiError});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim anlegen eines Vertragsdokuments ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.CreateContractError + ")", // content
                        "Fehler", //headline
                        Calculator + Data, // url
                        false, //automatic redirect
                        true,       //modal
                        "Zurück", // primaryBtnText
                        null,// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


// ----------------------- BANK ACCOUNT -------------------------
export function createBankAccount(params) {
    return function (dispatch) {
        BankAccount.createBankAccount(params).then(res => {
            if (res !== apiError) {
                dispatch({type: "CREATE_BANK_ACCOUNT", res: res});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim anlegen eines Bankaccounts ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.CreateBankAccountError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätigen",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function getBankAccount(id) {
    return function (dispatch) {
        BankAccount.getBankAccount(id).then(res => {
            if (res !== apiError) {
                dispatch({type: "GET_BANK_ACCOUNT", res: res});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim laden des Bankaccounts ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetBankAccountError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätigen",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function updateBankAccount(params) {
    return function (dispatch) {
        BankAccount.updateBankAccount(params).then(res => {
            if (res !== apiError) {
                dispatch({type: "UPDATE_BANK_ACCOUNT", res: res});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim aktualisieren des Bankaccounts ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.UpdateBankAccountError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        "Bestätigen", // primaryBtnText
                        null,// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function sendSupportFormular(id) {
    return function (dispatch) {
        Support.sendSupportFormular(id).then(res => {
            if (res !== apiError) {
                dispatch({type: "SEND_SUPPORT_FORMULAR", res: res});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Beim senden des Support-Formulars ist ein Fehleraufgetreten. (Fehlercode: " + ERROR_CODE.SendSupportFormularError + ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätigen",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function handle_employee_actions(function_name = null ,params = null, method = "GET", is_list = false,  url = null, file = null  ) {
    if (validateVar(function_name)){
        return function (dispatch) {
            dispatch(action_employee[function_name](function_name, params, method, is_list, url, file ))
        }
    }
}

export function handle_team_actions(function_name = null ,params = null, method = "GET",   url = null, file = null  ) {
    if (validateVar(function_name)){
        return function (dispatch) {
            dispatch(action_team[function_name](function_name ,params, method, url, file ))
        }
    }
}

export function handle_company_actions(function_name = null ,params = null, method = "GET",  url = null, file = null  ) {
    if (validateVar(function_name)){
        return function (dispatch) {
            dispatch(action_company[function_name](function_name, params,method, url, file))
        }
    }
}

///////////// RESET //////////////////////////////////////
export function resetCalculation() {
    return function (dispatch) {
        dispatch({type: "CALCULATE_RESET", res: null});
    };
}

export function resetSupport() {
    return function (dispatch) {
        dispatch({type: "SUPPORT_RESET", res: null});
    };
}

export function resetOffer() {
    return function (dispatch) {
        dispatch({type: "OFFER_RESET", res: null});
    };
}

export function resetEmployee() {
    return function (dispatch) {
        dispatch({type: "EMPLOYEE_RESET", res: null});
    };
}

export function resetEmployeeData() {
    return function (dispatch) {
        dispatch({type: "EMPLOYEE_DATA_RESET", res: null});
    };
}

export function resetTariff() {
    return function (dispatch) {
        dispatch({type: "TARIFF_RESET", res: null});
    };
}

export function resetCustomer() {
    return function (dispatch) {
        dispatch({type: "CUSTOMER_RESET", res: null});
    };
}

export function resetConsumptionPoint() {
    return function (dispatch) {
        dispatch({type: "CONSUMPTION_POINT_RESET", res: null});
    };
}

export function resetCompany() {
    return function (dispatch) {
        dispatch({type: "RESET_COMPANY", res: null});
    };
}

export function resetPartners() {
    return function (dispatch) {
        dispatch({type: "RESET_PARTNERS", res: null});
    };
}

export function resetPassword() {
    return function (dispatch) {
        dispatch({type: "RESET_PASSWORD", res: null});
    };
}

export function resetIban() {
    return function (dispatch) {
        dispatch({type: "RESET_IBAN", res: null});
    };
}

export function resetContract() {
    return function (dispatch) {
        dispatch({type: "RESET_CONTRACT", res: null});
    };
}

export function resetFile() {
    return function (dispatch) {
        dispatch({type: "RESET_FILE", res: null});
    };
}

export function resetComparisonFile() {
    return function (dispatch) {
        dispatch({type: "RESET_COMPARISON_FILE", res: null});
    };
}

export function resetComparisonTariff() {
    return function (dispatch) {
        dispatch({type: "RESET_COMPARISON_TARIFF", res: null});
    };
}


export function reset_load_profile() {
    return function (dispatch) {
        dispatch({type: "RESET_LOAD_PROFILE", res: null});
    };
}

export function resetBuergel() {
    return function (dispatch) {
        dispatch({type: "RESET_BUERGEL", res: null});
    };
}

export function resetInSign() {
    return function (dispatch) {
        dispatch({type: "RESET_INSIGN", res: null});
    };
}

export function resetOfferResult() {
    return function (dispatch) {
        dispatch({type: "OFFER_RESULT_RESET", res: null});
    };
}


export function redirect(url) {
    return function (dispatch) {
        if (validateVar(url)) {
            dispatch({
                type: "SET_USER_MESSAGE",
                res: displaySuccessMessage(
                    "", // content
                    "", //headline
                    url, // url
                    true, //automatic redirect
                    false,       //modal
                ),
            });
        } else {
            dispatch({
                type: "SET_USER_MESSAGE",
                res: displayErrorMessage(
                    "Beim Weiterleiten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.RedirectError + ")", // content
                    "Fehler", //headline
                    null, // url
                    false, //automatic redirect
                    true,       //modal
                    null, // primaryBtnText
                    "Zurück",// secondaryBtnText
                    true, //allowToggle (user can close modal without pressing a btn)
                    false,// successIcon
                    false,// infoIcon
                    "danger" //type - danger/success
                ),
            });
        }
    };
}

export function showRedirectModal(params) {
    return function (dispatch) {
        dispatch({
            type: "SET_USER_MESSAGE",
            res: displayErrorMessage(
                params.content,
                params.headline,//headline
                params.url,
                false, //automatic redirect
                true,       //modal
                'OK', // primaryBtnText
                null,// secondaryBtnText
                false, //allowToggle (user can close modal without pressing a btn)
                false,// successIcon
                false,// infoIcon
                "danger" //type - danger/success
            ),
        });
    }
}

export function showSuccessMessage(params) {

    return function (dispatch) {
        let is_modal = (validateVar(params.is_modal)) ? params.is_modal :  true;
        dispatch({
            type: "SET_USER_MESSAGE",
            res: displayErrorMessage(
                params.content,
                params.headline,//headline
                null,
                false, //automatic redirect
                is_modal,       //modal
                null, // primaryBtnText
                "Bestätigen",// secondaryBtnText
                true, //allowToggle (user can close modal without pressing a btn)
                false,// successIcon
                false,// infoIcon
                "success" //type - danger/success
            ),
        });
    }
}

export function showErrorMessage(params) {

    return function (dispatch) {
        let is_modal = (validateVar(params.is_modal)) ? params.is_modal :  true;
        dispatch({
            type: "SET_USER_MESSAGE",
            res: displayErrorMessage(
                params.content,
                params.headline,//headline
                null,
                false, //automatic redirect
                is_modal,       //modal
                null, // primaryBtnText
                "Bestätigen",// secondaryBtnText
                true, //allowToggle (user can close modal without pressing a btn)
                false,// successIcon
                false,// infoIcon
                "danger" //type - danger/success
            ),
        });
    }
}

// ---------------------- IBAN --------------------------
export function validateIban(iban) {
    return function (dispatch) {
        Iban.validateIban(iban).then(res => {
            dispatch({type: "VALIDATE_IBAN", res: res});
        });
    };
}

/*
 * Start PriceStrategy
 *
 * */

export {
    setListAttributes,
    setPriceStrategy,
    setPriceStrategyGlobal,
    sortBranches,
    resetPriceStrategy,
    initAttributs,
    getGlobalTreeBySupplier,
    getTreeIdByGetTariff,
    initPriceStrategy,
    submitNewTree,
    updateEditedTree,
    redirectUser,
    deleteOldGlobalAttributes,
    resetCleanTrees
} from "./pricestrategy";