import Communication from "./Components/Communication/communication";
import CommunicationNew from "./Components/Communication/communication_new";
import {
    apiCalculationManagementService,
    apiError,
    apiFileSystemService,
    apiFunctionActivateEmployee,
    apiFunctionCreateEmployee,
    apiFunctionDeleteEmployee,
    apiFunctionGetEmployee,
    apiFunctionListEmployees, apiFunctionLoginConsultant,
    apiFunctionPasswordForgotten,
    apiFunctionResetPassword,
    apiFunctionReturnResource,
    apiFunctionUpdateEmployee,
    apiPlatformService, emptyValue, project_name
} from "../Functions/global";
import { deHashVar, hashVar } from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import getBadgeColor from "../Functions/getBadgeColor";
import callComponentWithString from "../Functions/callComponentWithString";
import getAgencyID from "../Functions/getAgencyID";
import createFunctionName from "../Functions/createFunctionName";
import getCommunicationClass from "../Functions/getCommunication";
import getGenderDisplay from "../Functions/getGenderDisplay";

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));
const employee_id = deHashVar(localStorage.getItem("employeeSecurityToken"));
const user_role = deHashVar(localStorage.getItem("evuUserAccount"));

class Employee {
  constructor(data, status = "open") {
    /*
     *   default value for vars in this class
     *   add new vars here
     * */
    //basic
    this.id = "";
    this.employee_id = "";
    this.first_name = "";
    this.last_name = "";
    this.email = "";
    this.profile_img = "";


    this.is_b2c = false;
    this.is_b2b = false;
    this.profile_img = "";

    this.password = "";
    this.phone = "";
    this.gender = "";
    this.gender_display = "";
    this.picture_id = "";
    this.title = "";
    this.title_display = "";

    this.full_name = "";
    this.team_display = "";
    this.status = "Eingeladen";
    this.status_display = "";
    this.status_color = "gray";

    this.teams = [];
    this.team_ids = [];
    //load basic info
    if ((validateVar(data) && validateVar(data.id)) || (validateVar(data) && validateVar(data.agency_id))) {
      //if data found
        this.id = !validateVar( data.id ) ? "" : data.id;
        this.employee_id = !validateVar( data.id ) ? "" : data.id;

        this.agency_id =
            !validateVar( data.agency_id ) ? "" : data.agency_id;

        this.first_name =
            !validateVar( data.first_name ) ? "" : data.first_name;
        this.last_name =
            !validateVar( data.last_name ) ? "" : data.last_name;
        this.email = !validateVar( data.email ) ? "" : data.email;
        this.password = !validateVar( data.password ) ? "" : data.password;
        this.phone = !validateVar( data.phone ) ? "" : data.phone;
        this.gender = !validateVar( data.gender ) ? "" : data.gender;
        this.gender_display = !validateVar( data.gender ) ? "" : getGenderDisplay(data.gender);

      if (data.hasOwnProperty("teams") && data.teams.length > 0) {
        this.teams = data.teams;
        this.team_display  = data.teams[0].name;
        this.team_ids = this.get_team_ids(data.teams);
      }



      if (validateVar(data["title"]) && validateVar(data["title"][0])) {
            //TODO CHECK IF THIS OLD ENERMAKLER TITLE SETTER IS NEEDED:
          // let title_index = this.getTitleIndex(data["title"]);
          // if (!validateVar(title_index)) title_index = 0;
          // let title = data["title"][title_index];
          // if (title.hasOwnProperty("pivot")) {

          //TODO WAS ONLY USED FOR:

          // this.title =
          //     !validateVar( data["title"][title_index].name )
          //         ? ""
          //         : data["title"][title_index].name;
          // this.status =
          //     !validateVar( title.pivot.status )
          //         ? ""
          //         : title.pivot.status;
          //
          // this.status_display =
          //     !validateVar( title.pivot.status )
          //         ? ""
          //         : this.getEmployeeStatus(title.pivot.status);

        this.status = !validateVar(data["title"][0].pivot.status)
          ? ""
          : data["title"][0].pivot.status;

        this.title =
          typeof data["title"][0].name === "undefined"
            ? ""
            : data["title"][0].name;

        this.status = !validateVar(data["title"][0].pivot.status)
          ? ""
          : data["title"][0].pivot.status;

        this.status_display = !validateVar(this.status)
          ? ""
          : this.getEmployeeStatus(this.status);

        this.status_color = !validateVar(this.status)
          ? ""
          : getBadgeColor(this.status, ["active"], ["invited"], ["inactive"]);

        this.is_b2c =
            typeof data["title"][0].pivot.is_b2c === "undefined"
                ? ""
                : data["title"][0].pivot.is_b2c;
        this.is_b2b =
            typeof data["title"][0].pivot.is_b2b === "undefined"
                ? ""
                : data["title"][0].pivot.is_b2b;

      }

      this.team = this.getTeam(this.is_b2b, this.is_b2c);

      this.picture_id =
        typeof data.picture_id === "undefined" ? "" : data.picture_id;
      this.full_name =
        typeof data.first_name === "undefined" ||
        data.first_name === null ||
        typeof data.last_name === "undefined" ||
        data.last_name === null
          ? "-/-"
          : data.first_name + " " + data.last_name;

      this.title_display =
        typeof this.title === "undefined"
          ? ""
          : this.getDisplayTitle(this.title);

      this.title_id =
        typeof this.title === "undefined" ? "" : this.getTitleID(this.title);

      this.profile_img =
        typeof data.profile_img === "undefined" ? "" : data.profile_img;
    }
  }

  /*
        Instantiate a new Customer with its ID as Param
        Call Communication Class with matching global defined Vars for API Request
        return new Customer Clnass
     */
  static async instantiateEmployee() {
    let params = getAgencyID({});

    if (validateVar(params.employee_id)) {
      // this.c = new Communication(
      //   apiPlatformService,
      //   apiFunctionGetEmployee,
      //   apiFunctionUpdateEmployee,
      //   apiFunctionDeleteEmployee,
      //   apiFunctionCreateEmployee,
      //   "",
      //   { employee_id: employee_id }
      // );

        let communication_params = {
            service: apiPlatformService,
            get_function: apiFunctionGetEmployee,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params
        };
        let c = getCommunicationClass(communication_params);

      let res = await c.load();

      if (res.status === "ok") {
        let picture_id = res.result.picture_id;
        // add the logo url to the result obj
        // this.c = new Communication(
        //   apiFileSystemService,
        //   "",
        //   "",
        //   "",
        //   "",
        //   apiFunctionReturnResource,
        //   {}
        // );

          let communication_params = {
              service: apiFileSystemService,
              get_function: apiFunctionGetEmployee,
              update_function: "",
              delete_function: "",
              add_function: "",
              download_function: apiFunctionReturnResource,
              params: null,
          };
          let c = getCommunicationClass(communication_params);

        res.result.profile_img = await c.download(
          { id: picture_id },
          "image/png",
          true
        );

        let new_employee = new Employee(res.result);
        if (
          new_employee.title !== user_role &&
          +params.employee_id === +new_employee.id
        ) {
          localStorage.setItem("evuUserAccount", hashVar(new_employee.title));
        }
        return new_employee;
      } else return apiError;
    } else return apiError;
  }

  static async updateEmployee(params, with_employee_id = true) {
    params = getAgencyID(params, true, with_employee_id);

    // this.c = new Communication(
    //   apiPlatformService,
    //   apiFunctionListEmployees,
    //   apiFunctionUpdateEmployee,
    //   apiFunctionDeleteEmployee,
    //   apiFunctionCreateEmployee,
    //   "",
    //   {}
    // );

      let communication_params = {
          service: apiPlatformService,
          get_function: "",
          update_function: apiFunctionUpdateEmployee,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);


    var res = await c.update(params);
    if (res.status === "ok") {
        let picture_id = res.result.picture_id;
        if(validateVar(picture_id)){
            let communication_params = {
                service: apiFileSystemService,
                get_function: apiFunctionGetEmployee,
                update_function: "",
                delete_function: "",
                add_function: "",
                download_function: apiFunctionReturnResource,
                params: null,
            };
            let c = getCommunicationClass(communication_params);

            res.result.profile_img = await c.download(
                { id: picture_id },
                "image/png",
                true
            );
        }

        let new_employee = new Employee(res.result);
      if (
        new_employee.title !== user_role &&
        +employee_id === +new_employee.id
      ) {
        localStorage.setItem("evuUserAccount", hashVar(new_employee.title));
      }
      return new_employee;
    } else return apiError;
  }

  /*
            Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
            Call Communication Class with matching global defined Vars for API Request
            return Array with all TariffCalculatedContainer Obj
     */
  static async instantiateList() {
    let params = getAgencyID({}, true);
    // this.c = new Communication(
    //   apiPlatformService,
    //   apiFunctionListEmployees,
    //   apiFunctionUpdateEmployee,
    //   apiFunctionDeleteEmployee,
    //   apiFunctionCreateEmployee,
    //   "",
    //   params
    // );


      let communication_params = {
          service: apiPlatformService,
          get_function: apiFunctionListEmployees,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);



    var res = await c.load();

    if (res.status === "ok") {
      let listResults = [];
      for (var i = 0; i < res.result.length; i++) {
        listResults.push(new Employee(res.result[i]));
      }
      return listResults;
    } else return apiError;
  }

  /*
        update function - params must be a valid object
     */
  update(param) {
      let communication_params = {
          service: apiPlatformService,
          get_function: "",
          update_function: apiFunctionUpdateEmployee,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);
      return c.update(param).then(res => {
      return new Employee(res.result);
    });
  }

  static async updateEmployeeStatus(employees) {
    let error_count = 0;
    let new_employees = [];
    for (let employee of employees) {
      // loop through all values (objects or ids)
      let status = "active";
      if (employee.status === "active") status = "inactive";
      let params = {
        // get params for api request
        status: status,
        role_id: employee.title_id,
        old_role_id: employee.title_id
      };

      params = getAgencyID(params, true, false);
      params.employee_id = employee.id;

        // let c = new Communication( // define communication class for request
      //   apiPlatformService,
      //   apiFunctionListEmployees,
      //   apiFunctionUpdateEmployee,
      //   apiFunctionDeleteEmployee,
      //   apiFunctionCreateEmployee,
      //   "",
      //   {}
      // );


        let communication_params = {
            service: apiPlatformService,
            get_function: "",
            update_function: apiFunctionUpdateEmployee,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);



      let res = await c.update(params);
      if (res.status === "ok") {
        new_employees.push(new Employee(res.result));
      } else {
        error_count = error_count + 1; // get error count for user feedback
      }
    }
    if (new_employees.length === 0) return "ERR"; // if no data was set -> send ERR back
    return [new_employees, error_count]; // send updated Employees, and error quote back
  }

    static async changeLogoFile(params, file) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiFileSystemService,
            add_function: "saveFile"
        };
        let c = getCommunicationClass(communication_params);
        var res = await c.add(params, file);
        if (res.status === "ok") return res.result;
        else return apiError;
    }

    static async resetPassword(params) {
    // let c = new Communication(
    //   apiCalculationManagementService,
    //   "",
    //   apiFunctionPasswordForgotten,
    //   "",
    //   "",
    //   "",
    //   {}
    // );
      let communication_params = {
          service: apiCalculationManagementService,
          get_function: "",
          update_function: apiFunctionPasswordForgotten,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);

    return await c.update(params).then(res => {
      if (res.status === "ok") return res;
      else return apiError;
    });
  }

  static async setNewPassword(params) {
    // let c = new Communication(
    //   apiCalculationManagementService,
    //   "",
    //   apiFunctionResetPassword,
    //   "",
    //   "",
    //   "",
    //   {}
    // );

      let communication_params = {
          service: apiCalculationManagementService,
          get_function: "",
          update_function: apiFunctionResetPassword,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);

    let res = await c.update(params);
    if (res.status === "ok") return res.result;
    else return "ERR";
  }

  static async activate(params) {
    // let c = new Communication(
    //   apiPlatformService,
    //   "",
    //   apiFunctionActivateEmployee,
    //   "",
    //   "",
    //   "",
    //   {}
    // );


      let communication_params = {
          service: apiPlatformService,
          get_function: "",
          update_function: apiFunctionActivateEmployee,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);

    return await c.update(params).then(res => {
      if (res.status === "ok") {
          let communication_params = {
              service: apiPlatformService,
              get_function: "",
              update_function: apiFunctionUpdateEmployee,
              delete_function: "",
              add_function: "",
              download_function: "",
              params: null,
          };
          let c = getCommunicationClass(communication_params);
        params.agency_id = res.result.agency;
        params.employee_id = res.result.customer_id;
        return c.update(params).then(res => {
            return res.result;
        });
      } else return apiError;
    });
  }

  delete() {
      let communication_params = {
          service: apiPlatformService,
          get_function: "",
          update_function: "",
          delete_function: apiFunctionDeleteEmployee,
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);

      let params = {
          role_id: this.title_id,
          email: this.email
      };
      params = getAgencyID(params, true);
    return c.delete(params);
  }

  static async deleteMultiple(selected_array) {
    let error_count = 0;
    let deleted_ids = [];
    for (let employee of selected_array) {
      // loop through all values (objects or ids)
      let params = {
        // get params for api request
        role_id: employee.title_id,
        email: employee.email
      };

      params = getAgencyID(params, true);
      // let c = new Communication( // define communication class for request
      //   apiPlatformService,
      //   "",
      //   "",
      //   apiFunctionDeleteEmployee,
      //   "",
      //   "",
      //   {}
      // );

        let communication_params = {
            service: apiPlatformService,
            get_function: "",
            update_function: "",
            delete_function: apiFunctionDeleteEmployee,
            add_function: "",
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);


      let res = await c.delete_async(params); // start "async" (not normal delete!) delete request
      if (res.status === "ok") {
        deleted_ids.push(employee.id); // get ids for updating redux states later
      } else {
        error_count = error_count + 1; // get error count for user feedback
      }
    }
    if (deleted_ids.length === 0) return apiError; // if no data was deletet send ERR back
    return [deleted_ids, error_count]; // send deleted ids, and error quote back
  }

  static async addEmployee(params) {
    // this.c = new Communication(
    //   apiPlatformService,
    //   apiFunctionListEmployees,
    //   apiFunctionUpdateEmployee,
    //   apiFunctionDeleteEmployee,
    //   apiFunctionCreateEmployee,
    //   "",
    //   {}
    // );

      let communication_params = {
          service: apiPlatformService,
          get_function: "",
          update_function: "",
          delete_function: "",
          add_function: apiFunctionCreateEmployee,
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);


    let res = await c.add(params);
    if (
      validateVar(res.result) &&
      res.result === "Employee already registered for this level."
    )
      return "Used"; //if e mail already exists -> error
    if (res.status === "ok") {
        let employees = [];
        let all_employees = res.result;
        for (let employee of all_employees)  {
            employees.push(new Employee(employee))
        }
        return employees;
    }
    else return apiError;
  }

  download() { // todo??!
    // let res = c.download({ id: this.id, data_type: "download" });
    // return res;
  }


  // WATTLINE REQUESTS


  static async reset_password (function_name, params, method){
    let function_url = "customer/"+function_name;

    // let c = new CommunicationNew(
    //     method, // GET / POST / PUT / DELETE  -> method
    //     function_url,
    //     params
    // );
      let communication_params = {
          method: method, // GET / POST / PUT / DELETE  -> method
          function_url:  function_url,
          params: params
      };
      let c = getCommunicationClass(communication_params);
      return await c.request_handler();
  }

  static async password_forgotten (function_name, params, method){
    let function_url = "customer/"+function_name;

    // let c = new CommunicationNew(
    //     method, // GET / POST / PUT / DELETE  -> method
    //     function_url,
    //     params
    // );

      let communication_params = {
          method: method, // GET / POST / PUT / DELETE  -> method
          function_url:  function_url,
          params: params
      };
      let c = getCommunicationClass(communication_params);
      return await c.request_handler();
  }

  static async activate_employee (params){
    // let c = new CommunicationNew(
    //     'POST', // GET / POST / PUT / DELETE  -> method
    //     "platform/activate_employee",
    //     params
    // );


      let communication_params = {
          method: "POST", // GET / POST / PUT / DELETE  -> method
          function_url:   "platform/activate_employee",
          params: params
      };
      let c = getCommunicationClass(communication_params);
      return await c.request_handler();


  }

  /*
       Instantiate a new Customer with its ID as Param
       Call Communication Class with matching global defined Vars for API Request
       return new Customer Clnass
    */
  static async platform_employee(function_name ,params, method, url, file) {
    let is_list = false;
    let function_url = createFunctionName(function_name);
    if (validateVar(params) && method === "GET") { // get without params is requesting a list
      is_list = true;
    } else if (method === "GET"){ // get specific employee

      let get_agency = getAgencyID({});
      function_url = function_url + "/" + get_agency.employee_id;
      params = {
        options: {
          with_teams: 1
        },
      }
    }else if (method === "DELETE"){
      function_url = function_url + "/" + 0;
    }
    if (validateVar(params) && validateVar(params.is_list) && params.is_list === true)  is_list = true;

    let result = null;
    if (Array.isArray(params)) {

      result = [];
      for (let idx in params) {
        if (params.hasOwnProperty(idx)) {
          let param = params[idx];

            let communication_params = {
                method: method, // GET / POST / PUT / DELETE  -> method
                function_url:   function_url,
                params: param
            };
            let c = getCommunicationClass(communication_params);
            let res = await c.request_handler();
            result = res;

          // result.push(await this.start_request(new CommunicationNew(
          //     method, // GET / POST / PUT / DELETE  -> method
          //     function_url,
          //     param
          // )));
        }
      }
      if (result.includes(apiError)) result = apiError; // todo show specific error cases

    } else {

      // let c = new CommunicationNew(
      //     method, // GET / POST / PUT / DELETE  -> method
      //     function_url,
      //     params
      // );

        let communication_params = {
            method: method, // GET / POST / PUT / DELETE  -> method
            function_url:   function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
      result = await c.request_handler();
    }

    if (result !== apiError) {
      if (is_list){ // load employee list
        let listResults = [];
        for (var i = 0; i < result.length; i++) {
          listResults.push(new Employee(result[i]));
        }
        return listResults;
      }else { // single employee loaded
        if (validateVar(result.picture_id)) {

          // let c = new CommunicationNew(
          //     "GET", // GET / POST / PUT / DELETE  -> method
          //     "file_system/" + result.picture_id, // function call
          //     null, // params
          //     null, // file
          //     "return_link"
          // );

            let communication_params = {
                method:  "GET", // GET / POST / PUT / DELETE  -> method
                function_url:   "file_system/" + result.picture_id, // function call,
                params: {id: result.picture_id},
                is_download: "return_link",
            };
            let c = getCommunicationClass(communication_params);


          result.profile_img = await c.request_handler();

          let new_employee = new Employee(result);
          if (new_employee.title !== user_role && +employee_id === +new_employee.id) localStorage.setItem("consultantUserAccount", hashVar(new_employee.title));
          return new_employee;
        } else {
          if(Array.isArray(result) && Array.isArray(result[0])) result = result[0]; //Zumindest bei DELETE steht im result [[{}, {}, {}]] drin

          let new_employee = [];
          if (Array.isArray(result)) { //if result is an array, create a new result array of result objects
            if (result.length === 0) new_employee = [];
            else {
              for (let index in result) {
                if (result.hasOwnProperty(index)) {
                  let employee = new Employee(result[index]);
                  if (employee.title !== user_role && +employee_id === +employee.id) localStorage.setItem("consultantUserAccount", hashVar(new_employee.title));
                  new_employee.push(employee);
                }
              }
            }
          }else {
            new_employee = new Employee(result);
            if (new_employee.title !== user_role && +employee_id === +new_employee.id) localStorage.setItem("consultantUserAccount", hashVar(new_employee.title));
          }

          return new_employee;
        }
      }
    } else {
      return result;
    }
  }

  static async start_request(communication) {
    return await communication.request_handler();
  }

  static async affiliate_login(function_name, params) {
      if (validateVar(function_name)) {
          let function_url = createFunctionName(function_name);
          // let c = new CommunicationNew(
          //     "POST", // GET / POST / PUT / DELETE  -> method
          //     function_url,
          //     params
          // );

          // let result = await c.request_handler();

          let communication_params = {
              method: "POST", // GET / POST / PUT / DELETE  -> method
              function_url:  function_url,
              params: params
          };
          let c = getCommunicationClass(communication_params);
          let result = await c.request_handler();

          if ( result !== apiError) {
              return result;
          } else return apiError;
      } else {
          let communication_params = {
              service: apiCalculationManagementService,
              get_function: apiFunctionLoginConsultant,
              params: params
          };

          let c = getCommunicationClass(communication_params);
          let res = await c.load();
          if (res.status === "ok") {
              return res.result;
          } else if (validateVar(res) && validateVar(res.status)) {
              return apiError
          } else return "SERVER_DOWN";
      }

  }



  static async update_employee_status(employees) {
    let error_count = 0;
    let new_employees = [];
    for (let employee of employees) { // loop through all values (objects or ids)
      let status = "active";
      if (employee.status === "active") status = "inactive";

      let params = getAgencyID({}, true);
      params.employee_id = employee.id;
      params.role_id = employee.title_id;
      params.status = status;
      params.old_role_id = employee.title_id;


      //
      // let c = new CommunicationNew(
      //     "PUT", // GET / POST / PUT / DELETE  -> method
      //     'platform/employee',
      //     params
      // );
        let communication_params = {
            method: "PUT", // GET / POST / PUT / DELETE  -> method
            function_url:    'platform/employee',
            params: params
        };
        let c = getCommunicationClass(communication_params);
        let result = await c.request_handler();



      if (result === "Permission") {
        return "Permission"
      }else if ( result !== apiError) {
        new_employees.push(new Employee(result));
      }else error_count = error_count + 1; // get error count for user feedback
    }

    if (new_employees.length === 0) return "ERR";  // if no data was set -> send ERR back
    return [new_employees, error_count]; // send updated Employees, and error quote back
  }

  // class functions





  getTitleID(title) {
    if (title === "admin") return 1;
    else if (title === "manager") return 2;
    else return 3;
  }

  // example: admin to Admin
  getDisplayTitle(title) {
    if (title === "user") {
      return "Nutzer";
    } else {
      let first_char = title.substring(0, 1);
      return first_char.toUpperCase() + title.substring(1);
    }
  }

  getTeam(b2b, b2c) {
    if (b2b === 1 && b2c === 1) return "Gewerbe & Privat";
    else if (b2b === 1) return "Gewerbe";
    else if (b2c === 1) return "Privat";
    else return emptyValue;
  }

  getEmployeeStatus(status) {
    if (status === "invited") return "Eingeladen";
    else if (status === "active") return "Aktiv";
    else if (status === "inactive") return "Deaktiviert";
    else return "N/A";
  }

  get_team_ids(teams) {
    let ids = [];
    for(let index in teams) {
      if (teams.hasOwnProperty(index)){
        let team = teams[index];
        ids.push(team.id + "");
      }
    }
    return ids;
  }

  //TODO TITLE SETTER FUNCTION
    // getTitleIndex(title_array) {
    //     for (let title in title_array) {
    //         if (title_array.hasOwnProperty(title)) {
    //             let array = title_array[title];
    //             if (+array.pivot.agency_id === +consultant_id) return title;
    //         }
    //     }
    // }
}

export default Employee;
