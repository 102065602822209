import React from 'react';
import Slider from "nouislider";
import {Col, Row} from "reactstrap";
import validateVar from "../Functions/validation/validateVariable";

class CustomSlider extends React.Component {

    constructor(props) {
        super(props);
        this.slider = React.createRef();
    }

    componentDidMount() {
        let {step, min, max, value} = this.props;

        if(!validateVar(value)) value = 0;
        Slider.create(this.slider.current, {
            start: value.replace(',', '.'),
            connect: [true, false],
            step: step,
            range: {min: min, max: max},
            disabled: true
        });
        this.slider.current.setAttribute('disabled', true);
    }


    componentDidUpdate(prevProps) {
        let {value, min, max} = this.props;

        if(validateVar(max) && max !== prevProps.max){
            this.slider.current.noUiSlider.updateOptions({
                range: {
                    'min': min,
                    'max': max
                }
            });
        }

        if (validateVar(value) && value !== prevProps.value && typeof value === 'string') {
            this.slider.current.noUiSlider.set(value.replace(',', '.'));
        }
    }

    render() {
        let {unit, value, max} = this.props;
        if (unit === "EUR_PER_MONTH") unit = "€/Jahr";
        else if (unit === "EUR_PER_YEAR") unit = "€/Jahr";
        else if (unit === "CT_PER_KWH") unit = "ct/kWh";

        return (<div className="input-slider-container">
            <div className="input-slider" ref={this.slider}/>
            <Row className="mt-2">
                <Col lg={"12"}>
                    <span className="range-slider-value">
                      {value} {unit} (Max: {max} {unit})
                    </span>
                </Col>
            </Row>
        </div>);
    }
}

export default CustomSlider;