import React from 'react';
import {static_download_url} from "../../Functions/global";
import validateVar from "../../Functions/validation/validateVariable";

class RoundedImage extends React.Component {

    render() {
        let {id, image_id, size, url} = this.props;

        //Set circle size
        let circle_size = " avatar-sm";
        if (size === "md") circle_size = " avatar-md";
        else if (size === "lg") circle_size = " avatar-lg";

        //Set src url
        let src_url = url;
        if(validateVar(image_id)) src_url = static_download_url + image_id;
        //Set img id
        let logo_id = 'logo_image_display';
        if(validateVar(id)) logo_id = id;

        return <>
            {(validateVar(image_id) || validateVar(url)) && <span className={"avatar rounded-circle" + circle_size }>
                <img id={logo_id}
                    alt="..."
                    src={src_url} />
            </span>}
        </>
    }
}

export default RoundedImage;