import React from 'react'
import {Col, ListGroupItem, Row} from "reactstrap";
import Lottie from "react-lottie";
import SuccessAnimation from "../../../../module_components/Displays/lotties/1818-success-animation";
import validateVar from "../../../../module_components/Functions/validation/validateVariable";
import CredentialsModal from "../CredentialsModal";

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: SuccessAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const integrations_storage = JSON.parse(localStorage.getItem("integrations"));

class IntegrationsListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyStatus: 0,
        };

        this.credentialsModal = React.createRef();
    }

    handleOnAdd = (data) => {
        this.props.add(data, this.props.item);
    };

    toggleCredentialsModal = () => {
        this.credentialsModal.current.toggleModal(this.props.item);
    };

    trunOffCredentialsModal = () => {
        if(this.credentialsModal.current !== null){
            this.credentialsModal.current.turnOffModal();
        }
    };

    integrationAction = (status, id) => {
        if(status){
            return (
                <Lottie
                    options={defaultOptions}
                    height={50}
                    width={50}
                />
            );
        }else{
            // return(<i id={id} onClick={this.toggleCredentialsModal} className="fas fa-plus add-integration" />); // todo integrations
        }
    };

    is_disabled = (id) => {
        if (!validateVar(integrations_storage)) return false;
        return integrations_storage[id] !== null;
    };

    render() {
        this.trunOffCredentialsModal();
        let integration = this.props.item;
        if(this.props.toggleAnimation && !this.state.companyStatus){
            this.setState({companyStatus: 1});
        }

        if(this.props.item.hasOwnProperty('company')){
            return(
                <>
                    <CredentialsModal ref={this.credentialsModal} api_key={false} addIntegration={(data) => this.handleOnAdd(data)} />
                    <ListGroupItem className={this.is_disabled(integration.id) ? "pr-4 disabled-integration" : "pr-4"}>
                    <Row className="align-items-center position-relative">
                        <Col className='col-auto integration-logo-wrapper'>
                            <a
                                href={this.props.item.company_url}
                                target='_blank'
                            >
                                <img
                                    id={"logo_image_display"}
                                    alt="..."
                                    src={this.props.item.logo_url}
                                />
                            </a>
                        </Col>
                        <Col className="d-xs-none  d-sm-block d-md-block ml--2 pr-5">
                            <h4 className="mb-0">
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a href={this.props.item.company_url} target='_blank'>
                                    {this.props.item.company}
                                </a>
                            </h4>

                            <p>
                                {this.props.item.description}
                            </p>
                        </Col>
                        {this.is_disabled(integration.id) ? <></>
                         :
                            <Col className='col-auto'>
                        {this.integrationAction(this.state.companyStatus, this.props.item.id)}
                            </Col>
                          }
                    </Row>
                </ListGroupItem>
            </>
            )
        }else{
            return (<></>);
        }
    }
}

export default IntegrationsListItem;