import {
    apiCalculationManagementService,
    apiError,
    emptyValue,
    respond_to_history
} from "../../Functions/global";
import validateVar from "../../Functions/validation/validateVariable";
import callClassWithString from "../../Functions/callClassWithString";
import callFunctionWithString from "../../Functions/callFunctionWithString";
import CommunicationNew from "./Communication/communication_new";
import get_history_reference from "../../Functions/get_history_reference_type";
import getAgencyID from "../../Functions/getAgencyID";
import regexCheck from "../../Functions/validation/regexCheck";
import {ConsumptionpointPath, SingleContract} from "../../../globalNavigation";
import isEmptyObject from "../../Functions/isEmptyObject";
import getCommunicationClass from "../../Functions/getCommunication";

const invalid_values = ["-/-", "- / -", null, undefined, "undefined", "", []];
const invalid_names = ["params", "default_value", "function_name"];
const regex_int = /^[-+]?\d+$/;

class History {
    constructor(vars, data, history, path, accept_status = "open", log = false) {
        this.local_history = {};
        this.output_local_class_var = {};
        this.output_local_history = {};
        this.current_data = data;
        this.local_class_var = {};
        this.accept_status = accept_status;
        this.translation_object = null;
        this.test = [];
        this.path = path;
        this.log = log;
        let class_obj = this.get_class_obj(vars, data, history, path);
        return class_obj;
    }

    get_class_obj(vars, data, history, path) {
        for (let name in vars) {
            if (vars.hasOwnProperty(name)) {
                if (name !== "translation_object") {
                    this.path = path;
                    this.history = history;
                    this.default_name = name;

                    // OLD
                    let api_value = data[name];
                    // TEST
                    if (validateVar(vars[name]) && typeof vars[name] === "object" && vars[name].is_class_arr && validateVar(api_value) && path.length === 0) {
                        let class_name = vars[name].is_class_arr;
                        let tmp_history = {};
                        tmp_history = history;

                        for (let index in tmp_history) {
                            if (tmp_history.hasOwnProperty(index) && validateVar(tmp_history[index]) && validateVar(tmp_history[index].edited_data) && validateVar(tmp_history[index].edited_data[class_name])) {
                                if (Array.isArray(tmp_history[index].edited_data[class_name])) {
                                    //todo check if is array
                                } else {
                                    tmp_history[index].edited_data = {...tmp_history[index].edited_data, ...tmp_history[index].edited_data[class_name]};
                                }
                                if (Array.isArray(tmp_history[index].original_data[class_name])) {
                                    //todo check if is array
                                } else {
                                    tmp_history[index].original_data = {...tmp_history[index].original_data, ...tmp_history[index].original_data[class_name]};
                                }
                            }
                        }
                        if (api_value === emptyValue)
                            api_value = {};
                        if (!validateVar(api_value.history))
                            api_value.history = [];
                        if (validateVar(tmp_history))
                            api_value.history = api_value.history.concat(tmp_history);
                        if (validateVar(data.history))
                            api_value.history = api_value.history.concat(data.history);
                    }

                    let local_value = vars[name];
                    let history_obj = this.get_histroy_val(name);
                    if (validateVar(api_value)) {
                        // if (this.log && name === "price")
                        this.__set_value_for_api_value(
                            local_value,
                            api_value,
                            data,
                            name,
                            history,
                            history_obj,
                            vars,
                            path
                        );
                    } else {
                        this.__set_value_for_undefined(local_value, name, history_obj);
                    }
                } else {
                    this.translation_object = vars[name];
                }
            }
        }
        let return_val = this.local_class_var;
        return_val.history = this.local_history;

        return return_val;
    }

    loop_objects(local_value, api_value, history_obj) {

        for (let index in local_value) {

            // loop through new obj to execute functions or re_name the vars for the FE
            if (local_value.hasOwnProperty(index)) {
                let sub_arr_val = local_value[index]; // = function name

                if (validateVar(sub_arr_val)) {
                    // if no history found check in sub array with original api name for history (since they can be
                    if (!validateVar(history_obj) && validateVar(this.get_histroy_val(this.default_name))) history_obj = this.get_histroy_val(this.default_name);
                    if (
                        typeof sub_arr_val === "object" &&
                        validateVar(sub_arr_val.function_name)
                    ) {

                        // CHECK IF A FUNCTION CALL IS NEEDED TO FORMAT THE VALUE
                        let function_name = sub_arr_val.function_name; // FUNCTION NAME
                        let function_value = null;
                        [function_value, history_obj] = this.__evaluate_function_call(
                            function_name,
                            api_value,
                            history_obj,
                            sub_arr_val,
                            index
                        );
                        if (
                            !validateVar(function_value) &&
                            validateVar(sub_arr_val.default_value)
                        )
                            function_value = sub_arr_val.default_value; // todo

                        // save translation name for this var (history display)
                        if (validateVar(sub_arr_val.translation)) {
                            let translation_name = index + "_translation";
                            this.set_class_value(
                                // CALL EXTERNAL FUNCTION FOR CURRENT VALUE
                                // set data
                                translation_name,
                                sub_arr_val.translation,
                                null
                            );
                        }

                        this.set_class_value(
                            // CALL EXTERNAL FUNCTION FOR CURRENT VALUE
                            // set data
                            index,
                            function_value,
                            history_obj
                        );
                    } else if (
                        typeof sub_arr_val === "object" &&
                        validateVar(sub_arr_val.default_value) &&
                        invalid_values.includes(api_value)
                    ) {
                        // if (validateVar(history_obj) ) // todo check if needed
                        //   history_obj.value = sub_arr_val.default_value;
                        this.set_class_value(
                            // CALL EXTERNAL FUNCTION FOR CURRENT VALUE
                            // set data
                            index,
                            sub_arr_val.default_value,
                            history_obj
                        );
                    } else if (validateVar(api_value) && !regexCheck(index, regex_int)) { //This else case was included to fix the bug where properties with default values weren't displayed in Clearing
                        this.set_class_value(
                            // set data
                            index,
                            api_value,
                            history_obj
                        )
                    }
                } else {

                    // top level value with sub_arr name
                    this.set_class_value(
                        // set data
                        index,
                        api_value,
                        history_obj
                    ); // set value in class component
                }
            }
        }
    }

    get_histroy_val(name) {
        let historys = this.history;
        let path = this.path;
        if (isEmptyObject(historys)) { // fix if no history is found for this obj
            return historys;
        } else if (validateVar(historys) && historys.length > 0) {

            for (let history of historys) {
                let edited_data = history.edited_data;
                let original_data = history.original_data;
                // if a path exists, first traverse down the path, before searching for the actual param name.
                if (validateVar(path) && path.length > 0) {
                    for (let path_index in path) {
                        if (validateVar(edited_data) &&
                            path.hasOwnProperty(path_index) &&
                            edited_data.hasOwnProperty(path[path_index])
                        ) {
                            edited_data = edited_data[path[path_index]];
                            original_data = original_data[path[path_index]];
                        } else {
                            // if the path does not exist, there is no chance of finding the correct var.
                            edited_data = {};
                            original_data = {};
                            break;
                        }
                    }
                }

                // try to find the var name in the usual way by iterating through the (remaining) edited data
                if (history.status === "open" || this.accept_status === "all") {

                    for (let edited_name in edited_data) {
                        if (edited_data.hasOwnProperty(edited_name)) {
                            let history_value = edited_data[edited_name];
                            let history_original_value = original_data[edited_name];

                            if (edited_name === name || edited_name === this.default_name) {
                                let history_type = '';
                                if (validateVar(history.history_type)) history_type = get_history_reference(history.history_type);
                                else {
                                    if (window.location.href.includes(ConsumptionpointPath)) history_type = 'consumption_point';
                                    else if (window.location.href.includes(SingleContract)) history_type = 'contract';
                                }

                                // if (this.log && name === "price")

                                return {
                                    value: history_value,
                                    value_original: history_original_value,
                                    agency_id: history.requesting_agency,
                                    employee_id: history.requesting_employee,
                                    history_id: history.id,
                                    status: history.status,
                                    reference_id: history.reference_id,
                                    history_type: history_type
                                };
                            }
                        }
                    }
                }
            }
        }
        return null;
    }

    get_params(value, function_params, name = null) {
        let params = null;
        if (validateVar(function_params) && Array.isArray(function_params)) {
            params = validateVar(value) ? [value] : [];
            params = params.concat(function_params);
            params = params.map(param => {
                if (typeof param !== "boolean" && !Array.isArray(param)) {
                    param = param + "";
                    if (param.includes("%%")) {
                        param = (validateVar(this.local_class_var[param.replace("%%", "")])) ? this.local_class_var[param.replace("%%", "")] : (this.current_data[param.replace("%%", "")]) ? this.current_data[param.replace("%%", "")] : "";
                    }
                }
                return param;
            });
        } else params = [value]; // set api value as param (example: payment_methode var for function getPaymentMethodDisplay)
        return params;
    }

    static get_function_value(function_name, params, history_obj = null) {
        // EXTERNAL FUNCTION
        let history_value = null;
        let history_value_original = null;
        if (validateVar(history_obj)) {
            let history_params = [...params];
            history_params[0] = history_obj.value;
            history_value = callFunctionWithString(function_name, history_params);

            if (validateVar(history_obj.value_original)) {
                history_params[0] = history_obj.value_original;
                history_value_original = callFunctionWithString(function_name, history_params);
            }

        }
        let function_value = callFunctionWithString(function_name, params);
        return [function_value, history_value, history_value_original];
    }

    set_class_value(name, value, history_value = null) {
        if (
            validateVar(this.translation_object) &&
            validateVar(this.translation_object[name])
        ) {
            this.set_class_value(
                name + "_translation",
                this.translation_object[name],
                null
            );
        }
        if (name !== "is_sub_arr" && name !== "has_history") {
            // todo check if the if was needed here (!validateVar(this.local_class_var[name])
            // filter unnecessary vars
            this.local_class_var[name] = value; // declare class var
            if (validateVar(history_value)) {
                // if history was found declare history var
                this.local_history[name] = {...history_value}; // copy obj with no reference
            } else this.local_history[name] = null;
        }
    }

    /// SECOND LEVEL CALL
    __set_value_for_api_value(
        local_value,
        api_value,
        data,
        name,
        history,
        history_obj,
        vars,
        path
    ) {
        // if api object has matching name with value
        if (validateVar(local_value) && typeof local_value === "object") {

            // check if value is object
            if (validateVar(local_value.is_sub_arr)) {
                this.__set_value_for_api_value_is_sub_array(
                    data,
                    vars,
                    name,
                    local_value,
                    history,
                    path
                );
            } else if (local_value.is_class_arr) {
                this.__set_value_for_api_value_is_class_array(
                    name,
                    local_value,
                    api_value,
                    history_obj
                );
                // if (this.log && name === "price")
            } else {
                this.set_class_value(
                    // set data
                    name,
                    api_value,
                    history_obj
                );
                // let tmp_obj = {...local_value};
                //
                // if (validateVar(tmp_obj.default_value)) delete tmp_obj.default_value;  // or delete person["age"];
                // if (validateVar(tmp_obj[name])) delete tmp_obj[name];  // or delete person["age"];
                // local_value = {...tmp_obj};
                // tmp_obj = null;

                // function call or many vars to declare
                this.loop_objects(local_value, api_value, history_obj);
            }
        } else {
            this.set_class_value(
                // set data
                name,
                api_value,
                history_obj
            );
        }
    }

    __set_value_for_undefined(local_value, name, history_obj) {
        // no matching name in api found -> custom var or not defined
        if (validateVar(local_value) && typeof local_value === "object") {
            if (validateVar(local_value.function_name)) {
                this.__set_value_for_undefined_by_function(
                    name,
                    local_value,
                    history_obj
                );
            } else if (validateVar(local_value.default_value)) {
                this.set_class_value(name, local_value.default_value, history_obj);
            } else {
                this.loop_objects(local_value, null, history_obj);
            }
        } else {
            // not defined
            if (name !== "is_sub_arr" && name !== "has_history") {
                let value = validateVar(local_value) ? local_value : ""; // set default value
                if (validateVar(history_obj)) {
                    if (Array.isArray(history_obj) && history_obj.length > 0) {
                        value = history_obj;
                    }
                } // todo iban error hier denke ich
                this.set_class_value(name, value);
            }
        }
    }

    /// THIRD LEVEL CALL 1

    __set_value_for_api_value_is_sub_array(
        data,
        vars,
        name,
        local_value,
        history,
        path
    ) {
        // if is_sub_array is declared restart the loop with new inputfunction class
        // copy the current path to eliminate unexpected growth of path array. shallow copy fits as it is 1D
        let current_path = [...path];

        let next_history;
        // if the next level has its own history, take it for deeper nesting
        if (validateVar(local_value.has_history)) {
            next_history = data[name].history; // history from next level
            // if there is no specific history for the next level, stay with the current but expand the path
        } else {
            next_history = history;
            current_path.push(name);
        }
        this.get_class_obj(vars[name], data[name], next_history, current_path); // call function again to declare sub arr
    }

    setValuesForClass = (data, name) => {
    };

    __set_value_for_api_value_is_class_array(
        name,
        local_value,
        api_value,
        history_obj
    ) {
        //create new Object called with string give in "in_class_arr"
        let class_objects = [];
        if (Array.isArray(api_value) || typeof api_value === "object") {
            if (local_value.is_top_level) {
                for (let index in this.local_class_var) {
                    if (this.local_class_var.hasOwnProperty(index) && !api_value.hasOwnProperty(index))
                        api_value[index] = this.local_class_var[index];
                }
                // api_value = {...api_value, ...this.local_class_var};

                let get_top_level_vars = callClassWithString(local_value.is_class_arr, api_value, this.accept_status);

                if (validateVar(get_top_level_vars)) {
                    for (let index in get_top_level_vars) {
                        if (get_top_level_vars.hasOwnProperty(index) && index !== "history" && !this.local_class_var.hasOwnProperty(index)) {
                            this.local_class_var[index] = get_top_level_vars[index];
                        }
                    }

                    for (let index in get_top_level_vars.history) {
                        if (get_top_level_vars.history.hasOwnProperty(index) && !this.local_history.hasOwnProperty(index) && get_top_level_vars.history.hasOwnProperty(index)) {
                            this.local_history[index] = get_top_level_vars.history[index];
                        }
                    }
                }
            } else {
                for (let index in api_value) {
                    if (api_value.hasOwnProperty(index) && index !== "history") {
                        class_objects.push(callClassWithString(local_value.is_class_arr, api_value[index]));
                    }
                }
            }

        }
        if (class_objects.length > 0) {
            this.set_class_value(
                // set data
                name,
                class_objects,
                history_obj
            );
        }
    }

    /// THIRD LEVEL CALL 2

    __set_value_for_undefined_by_function(name, local_value, history_obj) {
        let function_name = local_value.function_name; // FUNCTION NAME

        let function_value = null;
        [function_value, history_obj] = this.__evaluate_function_call(
            function_name,
            null,
            history_obj,
            local_value,
        );

        this.set_class_value(
            // CALL EXTERNAL FUNCTION FOR CURRENT VALUE
            // set data
            name,
            function_value,
            history_obj
        );
    }

    /// INNER HELPERS

    __evaluate_function_call(function_name, api_value, history_obj, local_value, name) {
        let params = this.get_params(api_value, local_value.params, name); // GET FORMATED PARAMS FOR FUNCTION CALL
        let function_result = this.constructor.get_function_value(
            function_name,
            params,
            history_obj
        );

        let function_value =
            (!validateVar(function_result[0]) || function_result[0] === emptyValue) &&
            validateVar(local_value.default_value)
                ? local_value.default_value
                : function_result[0];
        let history_value =
            (!validateVar(function_result[1]) || function_result[1] === emptyValue) &&
            validateVar(local_value.default_value)
                ? local_value.default_value
                : function_result[1];

        let history_value_original =
            (!validateVar(function_result[2]) || function_result[2] === emptyValue) &&
            validateVar(local_value.default_value)
                ? local_value.default_value
                : function_result[2];

        if (validateVar(history_value) && history_value_original) {
            history_obj.value = history_value;
            history_obj.value_original = history_value_original;
        } else history_obj = null;

        return [function_value, history_obj];
    }

    static async update_history_changes(params, function_url) {
        params = getAgencyID(params, true);
        let communication_params = {
            method: "POST", // GET / POST / PUT / DELETE  -> method
            function_url: function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        let result = await c.request_handler();
        if (result !== apiError) {
            return result;
        } else return apiError;
    }
}

export default History;
