import validateVar from "../Functions/validation/validateVariable";

let initialState = null;

function calculation_unit_list(state = initialState, action) {
    if (action.type === "INITIALIZE_CALCULAION_UNIT_LIST") {
        let units_array = action.res.units;

        let combined_array = [];
        if (validateVar(state) && validateVar(state.units)) combined_array = state.units;
        if (validateVar(units_array) && units_array.length > 0) {
            combined_array = combined_array.concat(action.res.units);
        }

        return {
            units: combined_array,
            next_offset: action.res.next_offset,
            last_request: action.res.last_request,
            total_count: action.res.total_count
        }
    } else{
        return state;
    }
}

export default calculation_unit_list;
