let initialState = null;

function calculation_unit_list(state = initialState, action) {
    if (action.type === "INITIALIZE_CALCULAION_UNIT"){
        return action.res;
    } else{
        return state;
    }
}

export default calculation_unit_list;
