import getDurationTranslation from "./translateDuration";
import validateVar from "./validation/validateVariable";
let not_allowed = ["false", false, "-/-", "- / -"];
function handleCancellationPeriod(cancellation = "", reverse = false) {

    let translated;
    let split;
    if (not_allowed.includes(cancellation)) return "- / -";
    if (reverse == false){
        if (!validateVar(cancellation)) return "- / -";
        split = cancellation.split(' ');
        if(isNaN(split[0])) return "Festes Vertragsende";
        else {
            translated = getDurationTranslation(split[1]);
            return split[0] + " " + translated
        }
    } else{
        if (!validateVar(cancellation)) return null;
        split = cancellation.split(' ');
        if(isNaN(split[0]))return "none";
        else {
            translated = getDurationTranslation(split[1], true);
            return split[0] + " " + translated
        }
    }
}
export default handleCancellationPeriod;

