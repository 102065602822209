import validateVar from "./validation/validateVariable";
import {emptyValue, price_guarantee_type_options} from './global';

function get_price_guarantee_type_display(price_guarantee_type){
    if (validateVar(price_guarantee_type)) {
        let return_value = price_guarantee_type_options.find(x => x.key === price_guarantee_type);
        return validateVar(return_value) ? return_value.value : emptyValue;
    } return emptyValue;

}
export default get_price_guarantee_type_display;