let initialState = null;

function iban(state = initialState, action) {
  if (action.type === "INITIALIZE_CLEARING") {
    return action.res;
  } else if (action.type === "RESET_CLEARING") {
    return action.res;
  } else {
    return state;
  }
}

export default iban;
