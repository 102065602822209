let initialState = null;

function comparison_file(state = initialState, action) {
    if (action.type === "CREATE_COMPARISON_FILE") {
      return action.res;
    } else if (action.type === "RESET_COMPARISON_FILE"){
        return action.res;
    }
    else {
    return state;
  }
}
export default comparison_file;
