import {
    habitation_status_options, project_name,
    switching_date_status
} from "../../../Functions/global";

let consumption_profile = {
    api_call: "consumption_point",
        //TODO modal: "consumption_profile",
        modal: "static_consumption_profile",
        title: "Verbrauchsprofil",
        border_bottom: true,
        content: [
        {
            title: "Jahresverbrauch",
            name: "consumption_display",
            modal_name: "consumption_input_value",
            api_name: "mean_consumption",
            api_sub_structure: ["extended_information"],
            modal_input: true,
            frontend_display: true,
            type: "text",
            input_group_text: "kWh",
            function_name: "handleFormatNumber"
        },
        {
            title: "Zählertyp",
            type: "radio",
            name: "consumption_type",
            api_name: "consumption_type",
            modal_input: true,
            frontend_display: true,
            first_radio: { id: "slp", text: "SLP", value: "SLP" },
            second_radio: { id: "rlm", text: "RLM", value: "RLM" }
        },
        {
            title: "Profil", //this field is needed twice for modal to be able to use it with different select-options on SLP & RLM
            type: "select",
            name: "business_type_branche",
            modal_name: "business_type_branche",
            api_name: "business_type",
            modal_input: true,
            validation: { case_name: "select" },
            select_options: [],
            // display_requirements_frontend if only FE
            display_requirements: {
                collapse_index: 0,
                field: ["consumption_type"],
                value: ["SLP"]
            }
        },
        {
            title: "Profil",
            type: "select",
            name: "business_type_branche",
            modal_name: "business_type_branche",
            api_name: "business_type",
            modal_input: true,
            validation: { case_name: "select" },
            select_options: [],
            // display_requirements_frontend if only FE
            display_requirements: {
                collapse_index: 1,
                field: ["consumption_type"],
                value: ["RLM"]
            }
        },
        {
            title: "Profil",//to prevent displaying both modal business_types during the loading animation, here is a separate object for frontend_display
            name: "business_type_branche",
            frontend_display: true,
        },
        {
            title: "Spannungsebene",
            type: "select",
            name: "specification_display",
            modal_name: "specification",
            api_sub_structure: ["extended_information"],
            modal_input: true,
            frontend_display: true,
            remove_empty_option: true,
            select_options: [],
            display_requirements_frontend: {
                collapse_index: 2,
                field: ["consumption_type", 'medium_type'],
                value: ["RLM", 'ELECTRICITY']
            },
            display_requirements: {
                collapse_index: 2,
                field: ["consumption_type", 'medium_type'],
                value: ["RLM", 'ELECTRICITY']
            }
        },
        {
            title: "Druckstufe",
            type: "select",
            name: "specification_display",
            modal_name: "specification",
            api_sub_structure: ["extended_information"],
            modal_input: true,
            frontend_display: true,
            remove_empty_option: true,
            select_options: [],
            display_requirements_frontend: {
                collapse_index: 3,
                field: ["consumption_type", 'medium_type'],
                value: ["RLM", 'GAS']
            },
            display_requirements: {
                collapse_index: 3,
                field: ["consumption_type", 'medium_type'],
                value: ["RLM", 'GAS']
            }
        },
        {
          title: "Lastgang",//to prevent displaying both modal business_types during the loading animation, here is a separate object for frontend_display
          name: "load_profile_synthetic_component",
          frontend_display: true,
        },
    ]
};

if (project_name === "EVU_PROJECT") {
    consumption_profile.content.push({
        title: "Lastgang herunterladen",
        type: "file_upload",
        name: "load_profile_id",
        api_name: "load_profile_id",
        download: true,
        frontend_display: true,
        loading_display: false,
        display_requirements_frontend: {
            collapse_index: 1,
            field: ["consumption_type"],
            value: ["RLM"]
        }
    });
    consumption_profile.content.push({
        title: "Profil herunterladen",
        type: "file_upload",
        name: "load_profile_id",
        api_name: "load_profile_id",
        download: true,
        frontend_display: true,
        loading_display: false,
        display_requirements_frontend: {
            collapse_index: 1,
            field: ["consumption_type"],
            value: ["SLP"]
        }
    });
}

const consumption_point_data = {
  consumption_profile,
  delivery_address: {
    api_call: "consumption_point",
    api_sub_structure: ["consumption_point"],
    modal: "delivery_address",
    title: "Lieferadresse",
    border_bottom: true,
    content: [
      {
        title: "Postleitzahl",
        name: "postal_code",
        api_name: "postal_code",
        frontend_display: true,
        modal_input: true,
        type: "text",
        max_length: 5,
        function_name: "getNumericValue"
      },
      {
        title: "Ort",
        name: "city",
        api_name: "city",
        frontend_display: true,
        modal_input: true,
        type: "text"
      },
      {
        title: "Straße",
        name: "street",
        api_name: "street",
        frontend_display: true,
        modal_input: true,
        type: "text"
      },
      {
        title: "Hausnummer",
        name: "house_number",
        api_name: "house_number",
        frontend_display: true,
        modal_input: true,
        type: "text"
      }
    ]
  },
  invoice_address: {
    api_call: "invoice",
    modal: "invoice_address",
    title: "Rechnungsadresse",
    border_bottom: true,
    content: [
      {
        title: "Empfänger",
        name: "invoice_company",
        api_name: "company",
        frontend_display: true,
        api_sub_structure: ["invoice_address"],
        modal_input: true,
        type: "text",
        optional: true
      },
      {
        title: "Postleitzahl",
        name: "invoice_postal_code",
        api_sub_structure: ["invoice_address"],
        api_name: "postal_code",
        frontend_display: true,
        modal_input: true,
        type: "text",
        max_length: 5,
        function_name: "getNumericValue"
      },
      {
        title: "Ort",
        name: "invoice_city",
        api_name: "city",
        frontend_display: true,
        api_sub_structure: ["invoice_address"],
        modal_input: true,
        type: "text"
      },
      {
        title: "Straße",
        name: "invoice_street",
        api_name: "street",
        frontend_display: true,
        api_sub_structure: ["invoice_address"],
        modal_input: true,
        type: "text"
      },
      {
        title: "Hausnummer",
        name: "invoice_house_number",
        api_name: "house_number",
        api_sub_structure: ["invoice_address"],
        frontend_display: true,
        modal_input: true,
        type: "text"
      },
      {
        title: "Rechnungsmail",
        name: "invoice_email",
        api_name: "email",
        api_sub_structure: ["invoice_address"],
        frontend_display: true,
        modal_input: true,
        type: "text",
        optional: true,
        validation: {case_name: "email"},
      }
    ]
  },
  attached_data: {
    api_call: "extended_information",
    modal: "attached_data",
    title: "Anschlussdaten",
      border_bottom: true,
    content: [
      {
        title: "Marktlokation",
        name: "market_identifier",
        modal_name: "market_identifier",
        api_name: "market_identifier",
        api_sub_structure: ["extended_information"],
        frontend_display: true,
        modal_input: true,
        type: "text",
        validation: {case_name: "market_identifier", optional_if: {required_field: 'habitation_status', required_value: 'relocation'}},
        max_length: 11,
        optional: { //field is optional if ...
          dependency_fields: ['habitation_status'],
          values: ['relocation']
        }
      },
      {
        title: "Zählernummer / MeLo-Id",
        placeholder: "DE 000562 66802 AO6G56M11SN51G21M24S",
        tooltip: {
          id: "MeloID",
          text: "Zählpunktbezeichnung"
        },
        name: "meter_identifier",
        modal_name: "meter_identifier",
        api_name: "meter_identifier",
        api_sub_structure: ["extended_information"],
        frontend_display: true,
        modal_input: true,
        type: "text",
        max_length: 50,
        optional: true
      },
      {
        title: "Zählerstatus",
        name: "habitation_status_display",
        modal_name: "habitation_status",
        frontend_display: true,
        modal_input: true,
        type: "select",
        validation: { case_name: "select" },
        select_options: habitation_status_options
      }
    ]
  },
  cancellation: {
    api_call: "extended_information",
    modal: "cancellation",
    title: "Kündigung",
    content: [
      {
        title: "Altversorger",
        name: "pre_provider",
        api_name: "supplier_name",
        frontend_display: true,
        modal_input: true,
        type: "text",
      },
      {
        title: "Kundennummer",
        name: "pre_customer_number",
        api_name: "internal_customer_id",
        frontend_display: true,
        modal_input: true,
        type: "text",
      }
    ]
  },
  consumption_point_status: {
    api_call: "consumption_point_status",
    modal: "consumption_point_status",
    title: "Lieferstellenstatus bearbeiten",
    content: [
      {
        title: "Vorgang",
        name: "switching_status",
        api_name: "switching_status",
        modal_input: true,
        type: "select",
        validation: { case_name: "select" },
        select_options: switching_date_status
      },
      {
        title: "Bestätigter Lieferbeginn",
        name: "switching_date",
        api_name: "switching_date",
        modal_input: true,
        type: "date",
        validation: { case_name: "date" },
        display_requirements: {
          collapse_index: 0,
          field: ["switching_status"],
          value: ["successful"]
        }
      }
    ]
  }
};

export const nav_tabs = [
  {
    title: "Chronik",
    handler_name: "chronik",
    text:
      "Sie haben Fragen oder Anmerkungen zum Auftrag? Nehmen Sie hier direkt Kontakt\n" +
      "                auf, damit Ihr Anliegen bearbeitet werden kann!"
  }
];

export default consumption_point_data;
