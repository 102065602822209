import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";

let extended_information_vars = {
    translation_object: {
        business_type_branche: "Branche",
        market_identifier: "MaLo-Id",
        meter_identifier: "Zählernummer/MeLo-Id",
        consumption_display: "Verbrauch",
        specification_display: "Spannungsebene",
    },
    pre_customer_number_translation: 'Kundennummer',
    pre_provider_translation: 'Altversorger',
    is_sub_arr: "extended_information",
    has_history: true,
    contracts: {
        is_class_arr: "contract"
    },
    load_profile_synthetic: {
        load_profile_synthetic: null,
        load_profil_synthetic_color: {
            function_name: "getBadgeColor",
            params: [["0"], [], ["1"]]
        },
        load_profile_synthetic_display: {
            function_name: "getSyntheticDisplay"
        }
    }, // default_value: 1 breaks consumption point form page (load profile not getting displayed)
    load_profile_synthetic_component: {
        function_name: "callComponentWithString",
        params: ["%%load_profile_synthetic_display", "badge_dot", "%%load_profil_synthetic_color"]
    },
    load_profile_file_name: null,
    internal_consumption_point_id: null,
    manual_concession_fee_type: null,
    manual_peak: null,
    business_type: {
        business_type: null,

        business_type_branche: {
            function_name: "getBusinessTypeWithName"
        },
        business_type_display: {
            function_name: "getBusinessTypeDisplay",
            params: ["%%medium_type", "%%consumption_type"]
        },
    },

    net_operator_name: null,
    net_operator_code: null,
    control_area: null,
    market_identifier: {//KZ / 22.03.2022 / there is a bug in clearing if not using this syntax with a sub object for market & meter identifier
        market_identifier: null,
    },
    meter_identifier: {
        meter_identifier: null,
    },
    // meter_identifier: {
    //     default_value: "Nicht hinterlegt"
    // },
    market_identifier_display: {
        function_name: "getMarketIdentifier",
        params: ["%%market_identifier"]
    },
    identifier_name: {
        function_name: 'getIdentifierName',
        params: ['%%market_identifier', '%%meter_identifier']
    },
    malo: {
        function_name: 'getConsumptionPointIdentifier',
        params: ['%%market_identifier', '%%meter_identifier']
    },
    specification: {
        specification: null,
        specification_display: {
            function_name: "getSpecificationDisplay",
            params: ["%%medium_type"]
        }
    },
    mean_consumption: {
        mean_consumption: null,
        consumption: null,
        consumption_display: {
            function_name: "getDisplayConsumption",
            params: ["%%consumption"]
        },
        consumption_input_value: {
            function_name: "handleFormatNumber"
        }
    },
    cancellation: {
        translation_object: {
            pre_provider: "Altversorger",
            pre_customer_number: "Kundennummer",
        },
        is_sub_arr: "cancellation",
        has_history: true,
        internal_customer_id: {
            pre_customer_number: {
                default_value: "Keine Vorversorger (Kunden-)nummer hinterlegt"
            },
        },
        supplier_name: {
            pre_provider: {
                default_value: "Kein Vorversorger hinterlegt",
            },
        },
        has_pre_provider: {
            function_name: "checkIfHasPreProvider",
            params: ["%%pre_provider"],
        },
        cancellation_display: {
            function_name: "getCancellationDisplay",
            params: ["%%pre_provider"],
        },
    },
};


class ExtendedInformation {
    static default_values = extended_information_vars;
    constructor(data, status = "open") {
        if (validateVar(data)) {
            let class_content = new History(extended_information_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static dummy() {
        let output = {};
        output = this.build_dummy(extended_information_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {
                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr) || index === "translation_object") { // <-- always look for the child values
                        output[index] = this.build_dummy(vars[index]);
                    } else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default ExtendedInformation;
