import validateNumericAndCommas from "./validation/validateNumericAndCommas";
import validateVar from "./validation/validateVariable";

const max_values_before_comma = 3;

//This function is for validating user input PriceRate (ct/kWh)
export default function getValidPriceRate(newValue, oldValue = "", max_values_after_comma = 3){
    let lastAddedChar = newValue[newValue.length -1];
    let first_char = (validateVar(newValue)) ? newValue.substring(0, 1) : "";
    let is_negativ = (first_char === "-");
    if(lastAddedChar === '.') newValue = newValue.substring(0, newValue.length - 1) + ','; //Replace "." with "," for users who input it this way.
    newValue = newValue.replace(/-/g, "");
    let tmpValue = newValue;
    let validatedValue = oldValue;
    if(validateNumericAndCommas(newValue)){ //Allow only numeric and comma signs
        if(validatedValue !== "0," && newValue === '0'){ //Force a "," after 0 to prevent input with multiple "0" at the start
            return '0,';
        }
        if(newValue !== ','){ //Prevent "," as first char
            //Split value if it contains at least but not more than one ","
            if(newValue.includes(',')){
                if(!(newValue.replace(/[^,]/g, "").length > 1)){
                    tmpValue = newValue.split(',')[0];
                    if(!(tmpValue.length > max_values_before_comma)){ //Allow max characters before comma
                        if(newValue.split(',')[1].length > max_values_after_comma){//Allow max 3 characters after ","
                            validatedValue = tmpValue+','+newValue.split(',')[1].substring(0, newValue.split(',')[1].length-1);
                            validatedValue = get_minus_value(validatedValue, is_negativ)
                        }else{
                            validatedValue = tmpValue+','+newValue.split(',')[1];
                            validatedValue = get_minus_value(validatedValue, is_negativ)
                        }
                    }
                }else {
                    validatedValue = oldValue;
                    validatedValue = get_minus_value(validatedValue, is_negativ)
                }
            }else if(!(tmpValue.length > max_values_before_comma)) { //Allow max characters before comma
                validatedValue = get_minus_value(tmpValue, is_negativ)
            }
        }
    }


    return validatedValue;
}

function get_minus_value(value, is_negativ) {
    if (is_negativ) {
        value = "-" + value;
    }
    return value
}