import {DropdownToggle, UncontrolledDropdown} from "reactstrap";
import Skeleton from "react-loading-skeleton";
import React from "react";

function MessagesAndNotificationIconLoadingAnimation(){

    return (<UncontrolledDropdown nav>
            <DropdownToggle className="nav-link white-color position-relative notifications-toggler" color="" tag="a">
                <Skeleton width={30} height={20} />
            </DropdownToggle>
    </UncontrolledDropdown>);
}

export default MessagesAndNotificationIconLoadingAnimation;