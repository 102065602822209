import Communication from "./Components/Communication/communication";
import handleFormatNumber from "../Functions/handleFormatNumber";
import { deHashVar } from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import { Redirect } from "react-router";
import React from "react";
import {
    agencies,
    apiCalculationManagementService, apiError, apiFileSystemService,
    apiFunctiongetGetConsultant, apiFunctionModifyConsultant,
    apiFunctionModifySupplier, emptyValue, rheingas_planmargin_table, static_download_url
} from "../Functions/global";
import {SingleContract} from "../../globalNavigation";
import Notification from "./notification";
import handleFormatPrice from "../Functions/handleFormatPrice";
import Company from "./company";
const consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));
const employee_id = deHashVar(localStorage.getItem("consultantEmployeeSecurityToken"));

class Company_enermakler {
  constructor(data, communication = undefined) {
    /*
     *   default value for vars in this class
     *   add new vars here
     * */

    //basic
    this.first_name = "";
    this.last_name = "";
    this.company = "";
    this.email = "";
    this.phone = "";
    this.company = "";

    this.line1 = "";
    this.line2 = ""; //only customer who has been in contract before
    this.city = "";
    this.state = "";
    this.country = "";
    this.postal_code = "";

    this.logo_file_id = "";
    this.brochure_file_id = "";
    this.description = "";

    this.foundation_year = "";

    this.sales_revenue = "";
    this.status_as = "";
    this.logo_url = "";
    this.notifications = [];
    this.notifications_count = 0;

    this.cancellation_service = "YES";
    this.accept_differing_billing_address = "YES";
    this.accept_consultant_fee = "YES";
    this.accept_consultant_authority = "YES";
    this.global_tree_id = "";


    this.electricity_margin = 0;
    this.gas_margin = 0;


    this.plan_id = "";
    this.plan_price = "";
    this.max_customers = "";
    this.max_customers_addition = "";
    this.max_customers_sum = "";
    this.employee_count = "";
    this.employee_used_count = "";
    this.customer_count = "";
    this.customer_used_count = "";
    this.plan_id = "";
    //load basic info

    if (validateVar( data )) {
        if ( data.hasOwnProperty("customer_information") ){
            let customer_information = data.customer_information;

            this.first_name =
                !validateVar( customer_information.first_name )
                    ? ""
                    : customer_information.first_name;
            this.last_name =
                !validateVar( customer_information.last_name )
                    ? ""
                    : customer_information.last_name;
            this.company =
                !validateVar( customer_information.company )
                    ? ""
                    : customer_information.company;
            this.email =
                !validateVar( customer_information.email )
                    ? ""
                    : customer_information.email;
            this.phone =
                !validateVar( customer_information.phone )
                    ? ""
                    : customer_information.phone;
            this.company =
                !validateVar( customer_information.company )
                    ? ""
                    : customer_information.company;


            if (data.hasOwnProperty("billing_address")) {
                let billing_address = customer_information.billing_address;
                this.line1 =
                    !validateVar( billing_address.line1)
                        ? ""
                        : billing_address.line1;
                this.line2 =
                    !validateVar( billing_address.line2 )
                        ? ""
                        : billing_address.line2;
                this.city =
                    !validateVar( billing_address.city )
                        ? ""
                        : billing_address.city;
                this.state =
                    !validateVar( billing_address.state )
                        ? ""
                        : billing_address.state;
                this.country =
                    !validateVar( data.customer_information.billing_address.country )
                        ? ""
                        : data.customer_information.billing_address.country;
                this.zip =
                    !validateVar( data.customer_information.billing_address.zip )
                        ? ""
                        : data.customer_information.billing_address.zip;
            }
        }


        let notifications_arr = [];
        let count = 0;
        if (validateVar(data.notifications)) {
            for (let notifications of data.notifications) {
                if (notifications.status !== "viewed")  {
                    count = count +1;
                }
                notifications_arr.push(new Notification(notifications));
            }
        }
        this.notifications = notifications_arr;
        this.notifications_count = count;




        if ( data.hasOwnProperty("margins") ){
        let margins = data.margins;
        console.log("MARGIN", margins);
        this.electricity_margin = !validateVar( margins.electricity )
            ? 0
            : this.get_margins(margins.electricity);
          this.gas_margin = !validateVar( margins.gas )
              ? 0
              : this.get_margins(margins.gas);

          this.custom_margin_limit = !validateVar( margins.custom_margin_limit )
              ? 0
              : this.get_margins(margins.custom_margin_limit);
      } // todo replace . with , values

        if(data.hasOwnProperty('subscription_information')){
            let subscription_information = data.subscription_information;

            this.plan_id = !validateVar(subscription_information.plan_id) ? '' : subscription_information.plan_id;
        }

        if ( data.hasOwnProperty("subscription_information") ){
            let subscription_information = data.subscription_information;
            this.plan_id = validateVar(subscription_information.plan_id) ? subscription_information.plan_id : "";
            this.plan_price = validateVar(subscription_information.plan_price) ? subscription_information.plan_price : "";
            this.max_customers = validateVar(subscription_information.max_customers) ? subscription_information.max_customers : "";
            this.max_employees = validateVar(subscription_information.max_employees) ? subscription_information.max_employees : "";
            this.employee_used_count = validateVar(subscription_information.employees) ? subscription_information.employees : "";
            this.customer_used_count = validateVar(subscription_information.customers) ? subscription_information.customers : "";

            if ( subscription_information.hasOwnProperty("addons") ){
                 let addons = subscription_information.addons;
                if ( addons.hasOwnProperty("customers") ){
                    let addons_customer = addons.customers;
                    this.max_customers_addition = validateVar(addons_customer.max_customers) ? addons_customer.max_customers : "";
                    this.quantity_customers_addition = validateVar(addons_customer.quantity) ? addons_customer.quantity : "";
                    this.unit_customer_price = validateVar(addons_customer.unit_price) ? addons_customer.unit_price : "";
                    this.max_customers_sum = validateVar(addons_customer.unit_price) ? this.getMaxCustomerSum() : this.max_customers;
                }
                if ( addons.hasOwnProperty("employees") ){
                    let addons_employees = addons.employees;
                    this.max_employees_addition = validateVar(addons_employees.max_customers) ? addons_employees.max_customers : "";
                    this.quantity_employees_addition = validateVar(addons_employees.quantity) ? addons_employees.quantity : "";
                    this.unit_employees_price = validateVar(addons_employees.unit_price) ? addons_employees.unit_price : "";
                    this.max_employees_sum = validateVar(addons_employees.unit_price) ? this.getMaxEmployeeSum() : this.max_employees;
                }
            }
        }


      if (data.hasOwnProperty("calculation_settings")) {
        this.cancellation_service =
            !validateVar( data.calculation_settings.cancellation_service )
            ? ""
            : data.calculation_settings.cancellation_service;

        this.accept_differing_billing_address =
            !validateVar( data.calculation_settings.accept_differing_billing_address )
            ? ""
            : data.calculation_settings.accept_differing_billing_address;

        this.accept_consultant_fee =
          typeof data.calculation_settings.accept_consultant_fee === "undefined"
            ? ""
            : data.calculation_settings.accept_consultant_fee;

        this.global_tree_id =
            !validateVar( data.calculation_settings.global_tree_id )
            ? ""
            : data.calculation_settings.global_tree_id;

        this.accept_consultant_authority =
            !validateVar( data.calculation_settings.accept_consultant_authority )
            ? ""
            : data.calculation_settings.accept_consultant_authority;
      }


        if (data.hasOwnProperty("information")) {
            this.logo_file_id =
                validateVar(data.information.logo_file_id)
                    ?  data.information.logo_file_id
                    : "";
            this.brochure_file_id =
                !validateVar( data.information.brochure_file_id )
                    ? ""
                    : data.information.brochure_file_id;
            this.description =
                !validateVar( data.information.description )
                    ? ""
                    : data.information.description;
            this.foundation_year =
                !validateVar( data.information.foundation_year )
                    ? ""
                    : data.information.foundation_year + "";
            this.employee_count =
                !validateVar( data.information.employee_count )
                    ? ""
                    : handleFormatNumber(data.information.employee_count);
            this.customer_count =
                !validateVar( data.information.customer_count )
                    ? ""
                    : handleFormatNumber(data.information.customer_count);
            this.sales_revenue =
                !validateVar( data.information.sales_revenue )
                    ? ""
                    : handleFormatNumber(data.information.sales_revenue);
            this.status_as =
                !validateVar( data.information.status_as )
                    ? ""
                    : data.information.status_as + "";


        }

      //handle logo
      if (data.hasOwnProperty("logo_file")) {
        this.logo_url =
            !validateVar( data.logo_file.url ) ? "" : data.logo_file.url;
      }




        if (data.hasOwnProperty("messages")) {
            let messages_arr = [];
            let count = 0;
            if (validateVar(data.messages)) {
                for (let messages of data.messages) {
                    if (messages.status !== "viewed")  {
                        count = count +1;
                    }
                    messages_arr.push(new Notification(messages));
                }
            }
            this.messages = messages_arr;
            this.messages_count = count;
        }
    }
  }

    get_margins(margins) {
        for (let idx in margins) {
            if (margins.hasOwnProperty(idx)) {
                if (validateVar(margins[idx])) {
                    let margin = margins[idx];
                    let amount = 0;
                    if (validateVar(margins[idx].amount)) {
                        amount = margin.amount;
                        if (idx === "10000") {
                            amount = amount * 12;
                            amount = handleFormatPrice(amount, 2);
                        }
                        amount = amount.replace(".", ",");
                    }
                    margin.amount = amount;
                }
            }
        }
        return margins;
    }

  /*
        Instantiate a new Customer with its ID as Param
        Call Communication Class with matching global defined Vars for API Request
        return new Customer Clnass
     */
  static async instantiate() {
    let params = {
        consultant_id: consultant_id,
        options: {
          with_margins: 1,
          with_subscription_information: 1,
          with_messages: 1
        },
        employee_id: employee_id
    };
    this.c = new Communication(
      apiCalculationManagementService,
      apiFunctiongetGetConsultant,
      apiFunctionModifySupplier, //todo replace to consultant
      "",
      "",
      "",
      params
    );
    let res = await this.c.load();
    if (res.status === "ok"){
        let data = res.result;
        if (
            data.hasOwnProperty("information") &&
            validateVar(data.information.logo_file_id)
        ) {
            let logo_id = res.result.information.logo_file_id;
            res.result.logo_file = {
                url: static_download_url + logo_id
            };
            if(res.result.id == agencies.rheingas){ //TODO Sobald wir wissen wie die Migration funktioniert müssen features das in die DB gespeichert werden
                res.result.features = [
                    "team", //enables assigning user to a team
                    "custom_margin_limit", //adds on tariffComparison page in the CalculationSettingsModal a "margin in EUR input" + the limits definition is possible through a different page
                    "offer_price_type", //adds the feature to be able to switch between contract_price and price_rate in a offer
                ]
            }
        }
        return new Company(res.result);
    } else{
      return apiError;
    }
  }

  /*
          update function - params must be a valid object
       */
  static async update(params) {
      params.consultant_id = consultant_id;
      params.employee_id = employee_id;
      params.options =  {
          with_margins: 1,
          with_subscription_information: 1,
          with_messages: 1
      };

    this.c = new Communication(
      apiCalculationManagementService,
      apiFunctiongetGetConsultant,
      apiFunctionModifyConsultant,
      "",
      "",
      "",
      {}
    );
    var res = await this.c.update(params);
    if (res.status === "ok")  {
        let data = res.result;
        if (
            data.hasOwnProperty("information") &&
            validateVar(data.information.logo_file_id)
        ) {
            let logo_id = res.result.information.logo_file_id;
            let logo_url = static_download_url + logo_id;
            res.result.logo_file = {
                url: logo_url
            };
        }
      return new Company(res.result);
    }
    else return "ERR";
  }

  static async updateImg(params, file) {
    this.c = new Communication(
      apiFileSystemService,
      "",
      "",
      "",
      "saveFile",
      "",
      {}
    );
    var res = await this.c.add(params, file);
    return new Company(res.result);
  }

    getMaxEmployeeSum(){
        return (+this.max_employees_addition * +this.quantity_employees_addition) + +this.max_employees;
    }

    getMaxCustomerSum(){
        return (+this.max_customers_addition * +this.quantity_customers_addition) + +this.max_customers;
    }

  getStatusDisplay(status) {
    if (status === "push") return "Ausstehend";
    else if (status === "viewed") return "Gesehen";
    else if (status === "cancled") return "Abgebrochen";
    else return emptyValue;
  }

  translate_notentification_type(type) {
    if (type === "contract") return "Auftrag";
  }

  translate_notentification_url(type, id) {
    if (type === "contract")
      return (
        <Redirect push to={SingleContract + "/" + id} />
      );
  }
}

export default Company_enermakler;
