import { apiError } from "../Functions/global";

let initialState = [];
const default_layout = "Standardansicht";

function table(state = initialState, action) {
  if (action.res === apiError) return apiError;
  if (action.type === "INITIALIZE_TABLE") {
    let table = action.res;
    let state_tables = state;
    let new_state = [];
    let get_table_layouts = sort_function(table.layouts);
    for (let state_table of state_tables) {
      new_state.push(state_table);
    }
    new_state.push({
      layouts: get_table_layouts,
      options: table.options
    });

    return new_state;
  } else if (action.type === "CREATED_NEW_TABLE_LAYOUT") {
    let new_state_layout = [];

    let created_table = action.res;
    let created_table_name = action.res.layouts.table_name;
    let created_table_layouts = created_table.layouts;
    let state_layouts = null;
    let current_table_index = null;
    let state_tables = state;
    let new_state = [];

    //get current table layouts to update
    for (let single_table_index in state_tables) {
      if (
        state_tables[single_table_index].layouts[0].table_name ===
        created_table_name
      ) {
        state_layouts = state_tables[single_table_index].layouts;
        current_table_index = single_table_index;
      }
    }

    //add new layout to the layouts array
    for (let index in state_layouts) {
      if (state_layouts.hasOwnProperty(index)) {
        let old_state_entity = state_layouts[index];
        old_state_entity.is_selected = "NO";
        new_state_layout.push(old_state_entity);
      }
    }
    new_state_layout.push(created_table_layouts);
    new_state_layout = sort_function(new_state_layout);

    //set new state with updated values
    for (let table_index in state_tables) {
      if (state_tables.hasOwnProperty(table_index)) {
        if (table_index === current_table_index) {
          new_state.push({
            layouts: new_state_layout,
            options: created_table.options
          });
        } else {
          new_state.push(state_tables[table_index]);
        }
      }
    }

    return new_state;
  } else if (action.type === "UPDATE_TABLE_LAYOUT") {
    let new_state_layout = [];
    let updated_table = action.res;
    let created_table_name = action.res.layouts.table_name;
    let updated_table_layouts = updated_table.layouts;
    let state_layouts = null;
    let current_table_index = null;
    let state_tables = state;
    let new_state = [];

    //get current table layouts to update
    for (let single_table_index in state_tables) {
      if (
        state_tables[single_table_index].layouts[0].table_name ===
        created_table_name
      ) {
        state_layouts = state_tables[single_table_index].layouts;
        current_table_index = single_table_index;
      }
    }

    //update is_selected value on all layouts
    for (let index in state_layouts) {
      if (state_layouts.hasOwnProperty(index)) {
        let old_state_entity = state_layouts[index];
        if (old_state_entity.id === updated_table_layouts.id) {
          old_state_entity.is_selected = "YES";
          new_state_layout.push(updated_table_layouts);
        } else {
          old_state_entity.is_selected = "NO";
          new_state_layout.push(old_state_entity);
        }
      }
    }
    new_state_layout = sort_function(new_state_layout);

    //set new state with updated values
    for (let table_index in state_tables) {
      if (state_tables.hasOwnProperty(table_index)) {
        if (table_index === current_table_index) {
          new_state.push({
            layouts: new_state_layout,
            options: updated_table.options
          });
        } else {
          new_state.push(state_tables[table_index]);
        }
      }
    }

    return new_state;
  } else if (action.type === "DELETE_TABLE_LAYOUT") {
    let new_state = [];
    let new_state_layout = [];
    let deleted_table = action.res;
    let created_table_name = action.res.layouts.table_name;
    let deleted_table_layout = deleted_table.layouts;
    let set_default_layout = deleted_table_layout.is_selected === "YES";
    let state_tables = state;
    let state_layouts = null;
    let current_table_index = null;

    //get current table layouts to update
    for (let single_table_index in state_tables) {
      if (
        state_tables[single_table_index].layouts[0].table_name ===
        created_table_name
      ) {
        state_layouts = state_tables[single_table_index].layouts;
        current_table_index = single_table_index;
      }
    }

    //delete layout from layouts array at the current table
    for (let index in state_layouts) {
      if (state_layouts.hasOwnProperty(index)) {
        let old_state_entity = state_layouts[index];
        if (old_state_entity.id !== deleted_table_layout.id) {
          if (
            set_default_layout &&
            old_state_entity.layout_name === default_layout
          )
            old_state_entity.is_selected = "YES";
          new_state_layout.push(old_state_entity);
        }
      }
    }
    sort_function(new_state_layout);

    //set new state with updated values
    for (let table_index in state_tables) {
      if (state_tables.hasOwnProperty(table_index)) {
        if (table_index === current_table_index) {
          new_state.push({
            layouts: new_state_layout,
            options: deleted_table.options
          });
        } else {
          new_state.push(state_tables[table_index]);
        }
      }
    }

    return new_state;
  } else if (action.type === "RESET_TABLE") {
    return action.res;
  } else return state;
}

function sort_function(items, type = "numeric", key = "id") {
  if (type === "numeric") {
    return items.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  } else if (type === "string") {
    return items.sort((a, b) =>
      a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
    );
  } else return items;
}

export default table;
