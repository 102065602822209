/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {redirect} from '../../actions/index';
// reactstrap components
import {
    Card,
    Container,
    Row,
} from "reactstrap";
// core components
import SimpleHeader from "../../module_components/Displays/Headers/SimpleHeader";
// custom components
import validateVar from "../../module_components/Functions/validation/validateVariable";
import {initOfferList} from "../../actions";
import Table from "../../module_components/Displays/Tables/Table";
import Badge from "../../module_components/Displays/Badges/Badge";
import {OffersTablePath} from "../../globalNavigation";

const limit = 20;
const offset = 0;

const offer_columns = [
    {
        dataField: "id",
        text: 'Angebots-ID',
        sort: true,
        classes: 'linked-col eless-font-bold'
    },
    {
        dataField: "created_at_display",
        text: "Datum",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "ranking",
        text: "Platz",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "customer_type_tariff_display",
        text: 'Kundentyp',
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "medium_type_display",
        text: "Energie",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "consumption_display",
        text: "Verbrauch",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "last_type",
        text: "Typ",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "name",
        text: "Tarif",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "status_display",
        text: "Status",
        formatter: (cell, object) => {
            return <Badge value={cell} color={object.status_color} />
        },
        sort: true,
        classes: 'linked-col'
    }
];

/*
    START CLASS
 */
class OfferTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_load: false,
            next_offset: false,
            table_columns: offer_columns,
        };
    }

    componentDidMount() {
        this.props.initList({limit: limit, offset: offset});
    }

    componentDidUpdate(prevProps, prevState){
        let offer = this.props.offer;
        if (offer !== prevProps.offer && validateVar(offer)) {
            this.setState({
                offer: offer,
                total_count: offer.total_count,
                next_offset: offer.next_offset,
                is_load: true
            });
            if (validateVar(offer.next_offset)) {
                this.get_units(offer.next_offset);
            }
        }
    }

    get_units = (offset) => {
        let params =  { // set first pair of offset and limit
            limit: limit,
            offset: offset
        };
        this.props.initList(params);
    };

    render() {
        const  {offer, is_load, table_columns} = this.state;

        return (<>
                <SimpleHeader name={'Angebote'} headerText={''} />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <Table
                                    data={offer}
                                    data_arr_name={'offer'}
                                    columns={table_columns}
                                    linked_col_click={(row) => this.props.redirect( OffersTablePath+ "/" + row.id)}
                                    is_loaded={is_load}
                                    paginator_strings={['Angebote', 'Angeboten']}
                                    empty_table_message={'Es wurden noch keine Angebote abgegeben'}
                                    update_columns={(table_columns) => this.setState({table_columns: table_columns})}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

let mapStateToProps = function(state)   {
    return {
        offer: state.offer
    }
};

let mapDispatchToProps = {
    initList: initOfferList,
    redirect: redirect,
};

let OfferTableContainer = connect(mapStateToProps, mapDispatchToProps)(OfferTable);

export default OfferTableContainer;
