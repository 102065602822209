import React from 'react';
import BadgeDot from "../Displays/Badges/BadgeDot";
import Badge from "../Displays/Badges/Badge";

function callComponentWithString(value, component_name, ...params) {
    let color = params;
    if (Array.isArray(params))  color = params[0];
    switch (component_name) {
        case 'badge_dot':
            return <BadgeDot value={value} color={color} is_loaded={true} />;
        case 'badge':
            return <Badge value={value} color={color} is_loaded={true} />;
        default:
            return null;
    }
}

export default callComponentWithString;