import {connect} from "react-redux";
import React, {Component} from 'react';
import './FileUpload.scss';
import validateVar from "../../Functions/validation/validateVariable";
import Skeleton from "react-loading-skeleton";
import is_false from "../../Functions/is_false";
import getValidFileName from "../../Functions/validation/getValidFileName";
import isEmptyObject from "../../Functions/isEmptyObject";
import ReactBSAlert from "react-bootstrap-sweetalert";
import is_true from "../../Functions/is_true";
import cn from 'classnames';
import {static_download_url} from "../../Functions/global";
import AbstractDropdownButton from "../../Displays/Buttons/AbstractDropdownButton/AbstractDropdownButton";
import ProgressBar from "../../Displays/ProgressBar/ProgressBar";
import CropImageModal from "../../Cropper/CropImgModal";
import Lottie from "react-lottie";
import SuccessAnimation from "../../Displays/lotties/1818-success-animation.json";

const lottie_success_options = {
    loop: false,
    autoplay: true,
    animationData: SuccessAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

/*
    params:
    id: string | input field id
    value: string | file name
    name: string | input field name
    disabled: boolean | disables the input field
    file_type: string | one of ('pdf', 'image' or 'allow_all') xlsx is default
    file_id: int | wird benutzt um die Datei herunterzuladen -> benötigt auch allow_download
    public_static: 0 or 1 | param for the file upload. Creates automatically the download url the right way
    allow_download: boolean | setzt eine "Herunterladen" Option in die Dropdown options
    allow_reupload: boolean | setzt eine "Ersetzten" Option in die Dropdown options
    allow_delete: boolean | setzt eine "Löschen" Option in die Dropdown options
    return_file: func | returns the file to the parent component as soon as selected from the file explorer without uploading it
    return_file_id: func | returns the file id which is given from the api after uploading it when file reducer storage got changed
    dynamic_action_call: func | ist eine vordefinierte funktion in der (action) index.js, die eine Funktion dynamisch aus der angegebenen Klasse aufruft. Muss von der Parent-Component importiert werden (steht in Verbindung mit den Props: upload_function, delete_function, download_function)
    default_file_upload: func | funktion standardmäßig genutzt um eine Datei in das Filesystem hochzuladen. In der index.js "upload_file_new"
    upload_function: obj | used for dynamic function call {class: '', function_name: '', dispatch_type: '', params: {x: '', y: ''}}
    download_function: obj | used for dynamic function call {class: '', function_name: '', dispatch_type: '', params: {x: '', y: ''}}
    delete_function: obj | used for dynamic function call {class: '', function_name: '', dispatch_type: '', params: {x: '', y: ''}}
    upload_finished: boolean | set false to keep the loading animation going, set true to finish the loading animation (use with prop has_reset)
    has_reset: func | triggered when the upload animation has finished, allows to set upload_finished back to false to be able to trigger the loading animation again when the file got changed
    dont_submit_type: boolean | ob der angegebene file type (pdf/image/..) an die api gegeben werden soll. (meistens ausgelassen wenn allow_all als type übergeben wird)
    cropper: boolean | given when cropper has to be used for images
    aspect_ratio: int | (cropper) size of the cut window for the image
    cropper_headline: string | title of the image crop modal
    reset_file: boolean | when true, resets the input field to null value
*/

class FileUpload extends Component {
    constructor(props) {
        super(props);

        let dropdown_options = [];
        if(is_true(props.allow_download)) {
            if(validateVar(props.download_function) && validateVar(props.download_function.class)){
                dropdown_options.push({name: 'download_function', label: 'Herunterladen'});
            }else dropdown_options.push({name: 'download', label: 'Herunterladen'});
        }
        if(is_true(props.allow_reupload)) dropdown_options.push({name: 'reupload', label: 'Ersetzen'});
        if(is_true(props.allow_delete)) dropdown_options.push({name: 'delete', label: 'Löschen'})

        this.state = {
            file_local: {},
            start_loading: false,
            finish_loading: false,
            alert: null,
            is_loaded: true,
            file_uploaded: false,
            progress_bar_key: 0,
            dropdown_options: dropdown_options
        };

        this.fileUpload = React.createRef();
        this.crop_image_modal = React.createRef();
    }

    componentDidMount() {
        const {value, file_id, allow_download} = this.props;
        if(validateVar(value)){ //set the file name & keep the size for display if the file just got uploaded
            this.setState({file_local: {name: value}, file_uploaded: true});
        }
        
        if(validateVar(file_id) && is_true(allow_download)) {
            this.create_download_url();
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {file_local, dropdown_options} = this.state;
        const {file, return_file_id, file_id, value, url, upload_finished, allow_download, name} = this.props;

        //return file ID after update if return_file_id is defined in the parent component
        if(validateVar(file) && file !== prevProps.file && validateVar(return_file_id)){
            if(this.props_belong_to_component(file, name)) {
                //if upload_finished is not set but we got a response from upload request, finish loading
                if(!is_false(upload_finished)) this.setState({finish_loading: true, start_loading: false});

                return_file_id({
                    file_id: file.file_id,
                    file_name: file_local.name,
                    field_name: file.field_name
                });
            }
        }

        //if upload_finished was set to false and now its true, finish loading
        if(validateVar(upload_finished) && upload_finished !== prevProps.upload_finished && is_true(upload_finished)){
            this.setState({finish_loading: true, start_loading: false});
        }

        //when the file got deleted end loading animation
        if(!validateVar(value) && value !== prevProps.value) {
            this.setState({is_loaded: true});
        }

        // //File was returned to parent component but not uploaded yet
        // if(validateVar(value) && value !== prevProps.value){ //set the file name & keep the size for display if the file just got uploaded
        //     // this.setState({file_local: {name: value}});
        // }

        if(validateVar(url) && url !== prevProps.url && is_true(allow_download)){
            if(this.props_belong_to_component(url, name)){
                dropdown_options[0].download_url = url;
                if(typeof url === 'object') {
                    if(validateVar(url.file_name)) file_local.name = url.file_name;
                    if(validateVar(url.url)) dropdown_options[0].download_url = url.url
                }
                this.setState({dropdown_options: dropdown_options, file_local: file_local});
            }
        }

        if(validateVar(file_id) && file_id !== prevProps.file_id && is_true(allow_download)){
            this.create_download_url(); //if the file id changes, create a new download URL for it
        }
    };

    //In case where we have multiple fileuploads on a page which share the same reducer props,
    // we have to identify the component or else the result will be stored in the state of both components
    props_belong_to_component = (reducer_props, component_field_name) => {
        if(validateVar(reducer_props.field_name)){
            if(reducer_props.field_name === component_field_name) return true;
        }else return true;
        return false;
    }

    get_accepted_files = (file_type) =>{
        let acceptedFiles = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
        if(file_type === "images") acceptedFiles = 'image/x-png,image/jpeg';
        else if(file_type === "pdf") acceptedFiles = '.pdf';
        else if(file_type === "allow_all") acceptedFiles = '';
        return acceptedFiles;
    };

    get_aspect_ratio = () => {
        const {aspect_ratio} = this.props;
        if (validateVar(aspect_ratio)) return aspect_ratio;
        else return 1.5;
    };

    resetValue = () => {
        if(validateVar(this.fileUpload.current)){
            this.fileUpload.current.value = null;
            this.setState({file_local: {}});
            this.props.has_reset();
        }
    };

    get_id = () => {
        const {id} = this.props;
        if(validateVar(id)) return id;
        return 'fileInput';
    };

    file_changed = (e) => {
        let {file_type, setErrorMessage, name, aspect_ratio, cropper, return_file, has_reset} = this.props;
        setErrorMessage(false);
        let error = 0;
        if(validateVar(has_reset)) has_reset(); //set things like upload_finished back to false on reupload
        let file_tmp = e.target.files[0];
        if (validateVar(file_tmp)){
            let file = getValidFileName(file_tmp);

            let file_size = file.size;
            let filename = file.name;
            let message = "";
            //var checkFile = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined
            //check file type
            var endingCheck = filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
            endingCheck = endingCheck.toLowerCase();
            if(file_type === "images"){
                if(!endingCheck.includes("png") && !endingCheck.includes("jpg")){
                    message = "Bitte laden Sie eine Bilddatei in 'JPG' oder 'PNG' Format hoch";
                    error = 1;
                }
            }else if(file_type === "pdf"){
                if (!endingCheck.includes("pdf")) {
                    message = "Bitte wählen Sie ein .pdf Dateiformat";
                    error = 1;
                }
            }
            else if (file_type === "allow_all") console.log("all files allowed");
            else if(file_type === "xlsx"){
                if (!endingCheck.includes("csv") && !endingCheck.includes("xlsx") && !endingCheck.includes("xls")) {
                    message = "Bitte wählen Sie ein .CSV Dateiformat";
                    error = 1;
                }
            }
            //check file size (kb)
            if (file_size < 100) {
                message = "Die Datei ist leer.";
                error = 1;
            } else if (file_size > 5000000) {
                message = "Die Datei darf max. 5 MB groß sein.";
                error = 1;
            }

            if (error === 0){ //success
                if(validateVar(return_file)) {
                    this.setState({file_local: file});
                    return_file(file);
                } else if (is_true(cropper)) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.setState({
                            image_source_url: reader.result,
                            src: file
                        });
                    };
                    this.setState({logo_placeholder: file.name});
                    reader.readAsDataURL(file);
                    this.crop_image_modal.current.toggleModal(name, aspect_ratio);
                }  else {
                    this.upload_file(file);
                    setErrorMessage(false);
                }
            }else{ //error
                setErrorMessage(message);
                this.setState({
                    defaultValue: "Datei wählen oder hier ablegen",
                });
            }
        }
    };

    upload_file = (file) => {
        const {public_static, upload_function, file_type, name, dont_submit_type, default_file_upload, dynamic_action_call} = this.props;
        let {progress_bar_key} = this.state;

        let params = {};
        if(validateVar(upload_function)) params = upload_function.params;
        if(!validateVar(params.type) && !is_true(dont_submit_type)) params.type = file_type;
        if(validateVar(public_static)) params.public_static = public_static;

        if(validateVar(upload_function) && validateVar(upload_function.class)){//upload with custom class function
           dynamic_action_call(upload_function.class, upload_function.function_name, upload_function.dispatch_type, params, getValidFileName(file));
        }else{ //default file service upload
           default_file_upload(params, file, name);
        }

        this.setState({start_loading: true, finish_loading: false, file_uploaded: false, file_local: file, progress_bar_key: progress_bar_key+1});
    };

    remove_file = () => {
        let {return_file_id, delete_function, dynamic_action_call} = this.props;
        this.setState({
            file_local: {},
            is_loaded: false
        });
        if (validateVar(delete_function) && validateVar(delete_function.class)) {
            dynamic_action_call(delete_function.class, delete_function.function_name, delete_function.dispatch_type, delete_function.params);
        }else if (validateVar(return_file_id)) {
            return_file_id({
                file_id: null,
                file_name: null
            });
        }
    };

    create_download_url = () => {
        const {file_id, public_static, dynamic_action_call} = this.props;
        let {dropdown_options} = this.state;
        if(validateVar(file_id) && validateVar(public_static)){
            if(public_static === 0 || public_static === '0'){ //private download url
                let params = {
                    file_id: file_id,
                    return_url: true,
                }
                dynamic_action_call('url', 'createURL', 'CREATE_URL', params);
            }else if(public_static === 1 || public_static === '1'){ //public download url
                dropdown_options[0].download_url = static_download_url + file_id;
                this.setState({dropdown_options: dropdown_options})
            } else console.log('Wrong public static prop.');
        } else console.log('File ID or public static prop missing.');
    };

    dynamic_download_action_call = () => {
        const {download_function, dynamic_action_call} = this.props;
        dynamic_action_call(download_function.class, download_function.function_name, download_function.dispatch_type, download_function.params);
    }

    cropLogo = (url) => {
        let src = this.state.src; // original img data
        var blob = null;
        let file = null;
        var xhr = new XMLHttpRequest(); //http request to transform file url to blob
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = function () {
            blob = xhr.response; // blob response
            file = new File([blob], src.name, {  // blob to file for DB save
                type: blob.type,
            });

            this.upload_file(file);
        }.bind(this);
        xhr.send();
    };

    warning_alert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{button: {}}}
                    title="Achtung"
                    onConfirm={() => {
                        this.setState({alert: null});
                        this.remove_file();
                    }}
                    onCancel={() => this.setState({alert: null})}
                    cancelBtnText="Nein"
                    confirmBtnCssClass='primary-btn'
                    cancelBtnCssClass='btn-outline-eless'
                    confirmBtnText="Ja"
                    showCancel
                    btnSize=""
                >
                    Möchten Sie die Datei wirklich löschen?
                </ReactBSAlert>
            )
        });
    };

    render_upload_field = () => {
        const {file_type, name, cropperHeadline, disabled, error_state} = this.props;
        const {file_local} = this.state;

        return <div className={"file-upload-eless custom-file overflow-hidden" + cn({' d-none': !isEmptyObject(file_local), ' has-error': error_state})}>
            <input accept={this.get_accepted_files(file_type)}
                   className="custom-file-input cursor-pointer"
                   id={this.get_id()}
                   lang="de"
                   type="file"
                   name={name}
                   placeholder={''} //Placeholder geht hier nicht
                   onChange={(e) => this.file_changed(e)}
                   ref={this.fileUpload}
                   data-ratio={this.get_aspect_ratio}
                   data-headline={cropperHeadline}
                   disabled={disabled} />
            <label className="eless-upload" htmlFor={this.get_id()}>
                <span className='centered-upload-input-text'>Datei ablegen oder <span className='eless-link'>suchen</span></span>
            </label>
            {/*{validateVar(value) && allowDelete && <div className='delete-file noselect' onClick={() => !uploading && this.props.deleteFile()}>Aktuelle Datei löschen</div>}*/}
        </div>
    };

    render_uploaded_file = () => {
        const {error_state, return_file, url} = this.props;
        const {file_local, start_loading, finish_loading, alert, dropdown_options, file_uploaded, progress_bar_key} = this.state;

        return <>
            {alert}
            <div className={'eless-file-card' + cn({' d-none': isEmptyObject(file_local), ' has-error': error_state})}>
                <div className={'file-name'+cn({' file-without-size': !validateVar(file_local.size)})}><i className="straight-paperclip fas fa-paperclip" /> {file_local.name}</div>
                {validateVar(file_local.size) && <div className='file-size'>{file_local.size/1000} KB</div>}
                {!validateVar(return_file) && <div className='progress-bar-wrapper'><ProgressBar key={progress_bar_key} start_loading={start_loading} finish_loading={finish_loading} skip_loading={file_uploaded} />
                    {is_true(finish_loading) && <div className='lottie-wrapper'>
                        <Lottie options={lottie_success_options}
                                height={30}
                                width={30}/>
                    </div>}
                </div>}
                {dropdown_options.length > 0 && <div className='dropdown-options-wrapper'>
                    <AbstractDropdownButton options={dropdown_options}
                                            file_name={validateVar(url) ? url.file_name : null}
                                            delete={() => this.warning_alert()}
                                            reupload={() => this.fileUpload.current.click()}
                                            download_function={() => this.dynamic_download_action_call()}
                                            option_classes={' upload-field-dropdown-options'}>
                        <i className="fas fa-ellipsis-h cursor-pointer"/>
                    </AbstractDropdownButton>
                </div>}
            </div>
        </>
    };

    render() {
        const {reset_file, cropper, cropper_headline} = this.props;
        const {is_loaded} = this.state;

        if(reset_file) this.resetValue();

        if(!is_false(is_loaded)){//if is_loaded is undefined or true
            return <>
                {(validateVar(cropper) && is_true(cropper)) &&
                /* if cropper is true render cropper modal */
                <CropImageModal
                    cropper_headline={cropper_headline}
                    cropImg={(url) => this.cropLogo(url)}
                    img_url={this.state.image_source_url}
                    ref={this.crop_image_modal}
                />}
                {this.render_upload_field()}
                {this.render_uploaded_file()}
            </>;
        } else return <Skeleton width={'100%'} height={70} />
    }
}


let mapStateToProps = function(state)   {
    return {
        url: state.url,
        file: state.files,
    }
};

//mapDispatchToProps not working for some unknown reason
let FileUploadContainer = connect(mapStateToProps, null)(FileUpload);

export default FileUploadContainer;