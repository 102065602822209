let initialState = null;

function file_stream(state = initialState, action) {
  if (action.type === "GET_FILE_STREAM") {
    return action.res;
  } else {
    return state;
  }
}

export default file_stream;
