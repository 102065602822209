import { deHashVar } from "../Functions/handleHashedVariable";
import Tariff from "./tariff";
import validateVar from "../Functions/validation/validateVariable";
import {
    apiError,
    apiFileSystemService,
    apiFunctionCreateComparison, apiFunctionReturnResource,
    apiServiceContractGenerator
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";

class ComparisonFile {
     static async createComparisionFile(params, delete_params = null) {
         let delete_res = "NO_DELETE";
         if (validateVar(delete_params)) delete_res = await Tariff.remove_comparison_result(delete_params);

         if (delete_res !== apiError){
             params = getAgencyID(params);

             let communication_params = {
                 service: apiServiceContractGenerator,
                 get_function: apiFunctionCreateComparison,
                 update_function: "",
                 delete_function: "",
                 add_function: "",
                 download_function: "",
                 params: params,
             };
             let c = getCommunicationClass(communication_params);
             var res = await c.load();
             if (res.status === "ok") {

                 let communication_params = {
                     service: apiFileSystemService,
                     get_function: "",
                     update_function: "",
                     delete_function: "",
                     add_function: "",
                     download_function: apiFunctionReturnResource,
                 };
                 let c = getCommunicationClass(communication_params);
                 return await c.download( // file obj with name and url
                     {id: res.result.file_id},
                     "application/pdf",
                     true
                 );
             }
             else return apiError;
         } else return apiError;
    }
}

export default ComparisonFile;
