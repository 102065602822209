import {Card, CardBody, CardHeader, Col, Modal, Row} from "reactstrap";
import React from "react";
import Cropper from "react-cropper";
import PrimaryButton from "../Displays/Buttons/PrimaryButton";
import validateVar from "../Functions/validation/validateVariable";

class CropImgModal extends React.Component {
    state = {
        showModal: false
    };

    cropImage = () => {
        if (typeof this.cropper.getCroppedCanvas() !== "undefined") {
            let url = this.cropper.getCroppedCanvas().toDataURL();
            this.props.cropImg(url, this.state.name);
            this.toggleModal(this.state.name);
        }
    };

    toggleModal = (name, ratio, headline) => {
        this.setState({
            showModal: !this.state.showModal,
            name: name,
            ratio: ratio,
            headline: headline
        })
    };

    render(){
        let {headline, name, ratio} = this.state;
        if (!validateVar(headline)) headline = "Bild Zuschneiden";

         return(
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={this.state.showModal}
                toggle={() => this.toggleModal(name)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent modal-title">
                            <Row>
                                <Col lg={6}>
                                    <h2 className={'mb-0'}>{headline}</h2>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="px-lg-4 py-lg-4">
                            <Cropper
                                src={this.props.img_url}
                                // Cropper.js options

                                aspectRatio={ratio}
                                guides={true}
                                center={true}
                                autoCrop={true}
                                cropBoxResizable={false}
                                minCropBoxHeight={400}
                                minCropBoxWidth={500}
                                viewMode={0}
                                dragMode="move"
                                ref={cropper => {
                                    this.cropper = cropper;
                                }}
                                cropBoxMovable={false}
                                preview=".img-preview"
                            />
                            <div className='cropimg-button-wrapper'>
                                <PrimaryButton onClick={this.cropImage} value={'Zuschneiden'} />


                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        );
    }
}

export default CropImgModal;

