import React from 'react';
import cn from 'classnames';
import TooltipComponent from "../Displays/Basic/Tooltip";
import validateVar from "../Functions/validation/validateVariable";

function TitledContent(props) {

    let {classes, tooltip, id} = props;

    if(validateVar(classes)) classes = ' '+classes;
    else classes = '';

    return (<div>
        <div className={'eless-color text-left' + classes + cn({' mb-2': !props.no_margin})}>
            <b>{props.title}</b>
            {props.tooltip && <span className='ml-2'><TooltipComponent tooltip_data={{text: tooltip, id: validateVar(id) ? id : 'tooltip'}} /></span>}
            {props.optional && <span className='float-right'>(optional)</span>}</div>
        {props.children}
    </div>);
}

export default TitledContent;