let initialState = null;

function charge_bee(state = initialState, action) {
    if (action.type === "START_CHARGE_BEE_SESSION") {
      return action.res;
    } else if (action.type === "RESET_CHARGE_BEE") {
        return action.res;
    }
    else {
    return state;
  }
}

export default charge_bee;
