import validateVar from "./validation/validateVariable";

function check_option_values(option_values, data_object) {
    let result = true;
    for (let option in option_values) {
        if (option_values.hasOwnProperty(option)) {
            let {operator, value, name} = option;
            let value_from_data_object = data_object[name];
            if (validateVar(value_from_data_object)) {
                result = get_if_condition(operator, value_from_data_object, value);
            } else result = false;
        }
    }
  return result;
}


function get_if_condition (operator, current_value, conditional_value) {
    if (validateVar(operator)) {
        if (operator === "is_not") {
            return (current_value + "" !== conditional_value + "");
        } else if (operator === "is") {
            return (current_value + "" === conditional_value + "");
        }
    }  else return false;
}

export default check_option_values;
