import React from 'react';

class SettingsWheelBtn extends React.Component {
    state = {
        settings_btn_class: "fas fa-cog x2",
    }

    render() {
        const {on_click, classes} = this.props;
        const {settings_btn_class} = this.state;

        return(<div className={'settings-wheel-btn ' + classes}
            onClick={() => on_click()}
            onMouseEnter={() => this.setState({settings_btn_class: "fas fa-cog x2 fa-spin"})}
            onMouseOut={() => this.setState({settings_btn_class: "fas fa-cog x2"})}>
            <i onMouseEnter={() => this.setState({settings_btn_class: "fas fa-cog x2 fa-spin"})}
                className={settings_btn_class} />
        </div>)
    }
}

export default SettingsWheelBtn;