/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  Container,
} from "reactstrap";

import {default_logo_id, static_download_url} from "../../Functions/global";
import validateVar from "../../Functions/validation/validateVariable";
import Skeleton from "react-loading-skeleton";

class AuthNavbarContract extends React.Component {
  render() {
    const {plattform} = this.props;
    let img_src = null;

    if(validateVar(localStorage.getItem('logo_id'))) img_src = static_download_url + localStorage.getItem('logo_id');

    if(validateVar(plattform) && validateVar(plattform.logo_id)) img_src = static_download_url + plattform.logo_id;

    return (
      <>
        <Navbar
          className="auth-navnavbar-main background-white auth-navbar-contract navbar-horizontal navbar-dark pt-0"
          expand="lg"
          id="navbar-main"
        >
          <Container>

            <NavbarBrand to="#" tag={Link}>
              {validateVar(img_src) ? <img
                  alt="..."
                  className='navbar-brand-img'
                  src={img_src}
              /> : <Skeleton width={200} height={50}/> }
            </NavbarBrand>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AuthNavbarContract;
