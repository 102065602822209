import { deHashVar } from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";

let initialState = null;

function integration(state = initialState, action) {
  var integrations_storage = JSON.parse(localStorage.getItem("integrations"));
  if (
    typeof integrations_storage === "undefined" ||
    integrations_storage === null
  )
    integrations_storage = [];

  if (action.type === "INITIALIZE_INTEGRATIONS") {
    for (let property in action.res) {
      let result = action.res[property];
      let integration_id = parseInt(result.id);
      if (
        validateVar(integration_id) &&
        !has_id(integrations_storage, integration_id) &&
        !isNaN(integration_id)
      ) {
        integrations_storage.push(integration_id);
        integrations_storage[integration_id] = result.status;
        localStorage.setItem(
          "integrations",
          JSON.stringify(integrations_storage)
        );
      }
    }
    return action.res;
  } else if (action.type === "INITIALIZE_INTEGRATION_LIST") {
    return action.res;
  } else if (action.type === "ADD_INTEGRATION") {
    // password: "abc",
    // username: "abc2"
    return state;
  } else if (action.type === "DELETE_INTEGRATION") {
    return action.res;
  } else {
    return state;
  }
}

function has_id(integrations_storage, id) {
  return integrations_storage.includes(id);
}

export default integration;
