import validateVar from "../Functions/validation/validateVariable";

let initialState = null;

function clearing_list(state = initialState, action) {
    if (action.type === "INITIALIZE_CLEARING_LIST") {
        return action.res;
    } else if (action.type === "RESET_CLEARING_LIST") {
        return action.res;
    } else if (action.type === "CREATE_CLEARING") {
        let newState = [action.res];
        if(validateVar(state)) newState.push(...state);
        return newState;
    } else if (action.type === "DELETE_CLEARINGS_FROM_LIST") {
        let newState = {...state};
        newState.elements = [];
        let clearing_ids = action.ids;
        for (let clearing of state.elements) {
            if (!clearing_ids.includes(clearing.id)) newState.elements.push(clearing);
        }
        return newState;
    } else if (action.type === "EDIT_CLEARING_FROM_LIST") {
        let newState = JSON.parse(JSON.stringify(state));
        let all_elements = newState.elements;
        let edited_clearing = action.res;
        let edited_clearing_index = all_elements.findIndex(x => x.id === edited_clearing.id);
        if (edited_clearing_index > -1) {
            all_elements[edited_clearing_index] = edited_clearing;
        }
        newState.elements = all_elements;
        return newState;
    } else return state;
}

export default clearing_list;
