import React from 'react';
import validateVar from "../../Functions/validation/validateVariable";
import TitledContent from "../../PageContent/TitledContent";

class DownloadComponent extends React.Component {
    getButton = () => {
        let {disabled, download_url, text} = this.props;
        if (!disabled){
            return <a href={download_url} target={'_blank'}><button className='input-height download-icon-btn w-100'>
                <div className='float-left'>{text}</div>
                <div className='float-right'><i className="ml-1 fas fa-download"/></div>
            </button></a>
        }else {
            return <button disabled={true} className='input-height download-icon-btn disabled w-100'>
                <div className='float-left'>{text}</div>
                <div className='float-right'><i className="ml-1 fas fa-download"/></div>
            </button>
        }

    };

    render() {
        const {content_title} = this.props;

        if(validateVar(content_title)){
            return <TitledContent title={content_title} >
                {this.getButton()}
            </TitledContent>
        }

        return (this.getButton());
    }
}

export default DownloadComponent;