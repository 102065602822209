/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Collapse,
    Navbar,
    NavItem,
    Nav
} from "reactstrap";
import Skeleton from "react-loading-skeleton";
import getUserRole from "../../../Functions/getUserRole";
import {adminRole, managerRole} from "../../../Functions/global";

class SideBarLoadingAnimation extends React.Component {
    render() {
        let userRoleLocal = getUserRole();

        return (
            <Navbar
                className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
                onMouseEnter={this.onMouseEnterSidenav}
                onMouseLeave={this.onMouseLeaveSidenav}
            >
                <div className="scrollbar-inner">
                    <div className="sidenav-header d-flex align-items-center">
                        <Skeleton height={110} width={249}/>
                        <div className="ml-auto">
                            <div className='sidenav-toggler mr-4 d-lg-block d-xl-none active'>
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line" />
                                    <i className="sidenav-toggler-line" />
                                    <i className="sidenav-toggler-line" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbar-inner">
                        <Collapse navbar isOpen={true}>
                            <Nav className='pl-0' navbar>
                                <NavItem>
                                    <span className='nav-link'>
                                        <Skeleton height={24} width={24}/>
                                        <span className="nav-link-text ml-3"><Skeleton height={18} width={85}/></span>
                                    </span>
                                </NavItem>
                            </Nav>
                            <hr className='my-3'/>
                            <h6 className="navbar-heading pt-0 pl-0 pb-2 mb-2 text-muted"><Skeleton height={18} width={110}/></h6>
                            <Nav navbar>
                                <NavItem>
                                <span className='nav-link mb-1 pb-2'>
                                    <Skeleton height={24} width={24}/>
                                    <span className="nav-link-text ml-3"><Skeleton height={18} width={110}/></span>
                                </span>
                                </NavItem>
                                <NavItem>
                                <span className='nav-link pb-1 mb-1'>
                                    <Skeleton height={24} width={24}/>
                                    <span className="nav-link-text ml-3"><Skeleton height={18} width={80}/></span>
                                </span>
                                </NavItem>
                            </Nav>
                            <hr className='my-3'/>
                            <h6 className="navbar-heading pt-0 pl-0 pb-2 mb-2 text-muted"><Skeleton height={18} width={110}/></h6>
                            <Nav navbar>
                                <NavItem>
                            <span className='nav-link mb-1 pb-2'>
                                <Skeleton height={24} width={24}/>
                                <span className="nav-link-text ml-3"><Skeleton height={18} width={80}/></span>
                            </span>
                                </NavItem>
                                <NavItem>
                            <span className='nav-link pb-1 mb-1'>
                                <Skeleton height={24} width={24}/>
                                <span className="nav-link-text ml-3"><Skeleton height={18} width={80}/></span>
                            </span>
                                </NavItem>
                            </Nav>
                            {(userRoleLocal === adminRole) && <>
                                <hr className='my-3'/>
                                <h6 className="navbar-heading pt-0 pl-0 pb-2 mb-2 text-muted"><Skeleton height={18} width={110}/></h6>
                                <Nav navbar>
                                    <NavItem>
                                <span className='nav-link mb-1 pb-2'>
                                    <Skeleton height={24} width={24}/>
                                    <span className="nav-link-text ml-3"><Skeleton height={18} width={60}/></span>
                                </span>
                                    </NavItem>
                                    <NavItem>
                            <span className='nav-link mb-1 pb-2'>
                                <Skeleton height={24} width={24}/>
                                <span className="nav-link-text ml-3"><Skeleton height={18} width={110}/></span>
                            </span>
                                    </NavItem>
                                    <NavItem>
                            <span className='nav-link'>
                                <Skeleton height={24} width={24}/>
                                <span className="nav-link-text ml-3"><Skeleton height={18} width={80}/></span>
                            </span>
                                    </NavItem>
                                </Nav>
                            </>}
                        </Collapse>
                    </div>
                </div>
            </Navbar>
        );
    }
}

export default SideBarLoadingAnimation;
