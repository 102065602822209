import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";
import callClassWithString from "../../../Functions/callClassWithString";

let tariff_condition_vars = {
    is_sub_arr: "tariff_condition",
    translation_object: {
        contract_extension_display: "Vertragsverlängerung",
        cancellation_period_display: "Kündigungsfrist",
        quantity_tolerance: "Mehr/Mindermengen Toleranz",
        quantity_tolerance_display: "Mehr/Mindermengen Toleranz"
    },
    has_history: true,
    contract_extension: {
        contract_extension: null,
        contract_extension_display: {
            function_name: "handleExtentionPeriod",
            params: [false]
        }
    },
    cancellation_period: {
        cancellation_period: null,
        cancellation_period_display: {
            function_name: "handleCancellationPeriod",
            params: [false]
        }
    },
    energy_source: {
        energy_source: null,
    },
    price_guarantee_type: {
        price_guarantee_type: null,
        price_guarantee_type_display: {
            function_name: "get_price_guarantee_type_display",
        }
    },
    personal_support: null,
    additional_services: null,
    accepts_electronic_signature: null,
    sign_type: {
        sign_type: null,
        sign_type_filter_value: {
            function_name: "getSignType",
            params: ["%%accepts_electronic_signature"]
        },
        sign_type_display: {
            function_name: "getSignTypeDisplay"
        }
    },
    quantity_tolerance: {
        quantity_tolerance: null,
        quantity_tolerance_display: {
            function_name: "handleFormatQuantityTolerance",
        }
    },
    price_type: null,
    payment_sepa_direct_debit: null,
    payment_cash: null,
    payment_bank_transfer: null,
    payment_options: {
        function_name: "getPaymantOptions", // todo auslagern
        params: ["%%payment_sepa_direct_debit", "%%payment_bank_transfer", "%%payment_cash"]
    },
    netfee_type: null,
};

class Tariff_Condition {
    static default_values = tariff_condition_vars;

    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(tariff_condition_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static dummy() {
        let output = {};
        output = this.build_dummy(tariff_condition_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    } else if (validateVar(vars[index].is_class_arr)) {

                        let class_name = callClassWithString(index);
                        output[index] = class_name.constructor.dummy();
                    } else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default Tariff_Condition;
