

/* CALCULATION */
export const GetWattinlineData = "0001";
export const DownloadWattlineMandate = "0002";
export const UploadWattlineLoadProfile = "0003";
export const UploadWattlineLoadProfileELS = "0004";
export const GetCalculatedTariffsError = "0005";
export const GetNetFeeError = "0006";
export const GetWattinlineDataToken = "0007";
export const GetWattinlineDataCustomer = "0008";
export const GetWattinlineDataEls = "0009";
export const GetWattinlineMandateEls = "0010";
export const GetWattinlineMandateDocument = "0011";
export const GetWattinlineMandateFormat = "0012";
export const PutGroupingParamsError = "0013";
export const GetGroupingParamsError = "0014";





/* USER / EMPLOYEE / SUPPLIER*/
export const GetFileError = "4013";
export const GetTeamsError = "4014";
export const GetTasksListError = "4015";
export const GetLayoutError = "4016";
export const DeleteEmployee = "5005";



/* CALCULATION_UNITS */
export const GetCalculationsUnitsList = '9000';
export const GetCalculationsUnit = '9001';

/* CALCULATION */
export const CalculateOfferError = "0001";
export const ReCalculateTariffsError = "0002";



/* OFFER */
export const CreateOfferError = "1001";
export const UpdateOfferError = "1002";
export const GetOfferError = "1003";
export const GetOfferByTokenError = "1004";
export const SaveOfferError = "1005";
export const DeleteSavedOfferError = "1006";
export const AddComparisonTariffToOfferError = "1007";
export const SaveOfferErrorInSign = "1008";


/* CUSTOMER */
export const CreateCustomerError = "2001";
export const UpdateCustomerError = "2002";
export const GetCustomerError = "2003";
export const UploadFilesForCustomerError = "2004";
export const GetUploadedFilesForCustomerError = "2005";
export const GetContractsForCustomerError = "2006";
export const GetConsumptionPointsForCustomerError = "2007";
export const GetCustomerListError = "2008";
export const CreateBankAccountError = "2009";
export const UpdateBankAccountError = "2010";
export const GetBankAccountError = "2011";
export const DownLoadCustomerFileError = "2012";
export const DeleteCustomerError = "2013";
export const DeleteConsumptionpointError = "2014";
export const GetAnalyticsData = "2015";
export const GetOverviewTasks = "2016";

/* CONTRACT */
export const CreateContractError = "3000";
export const UpdateContractError = "3001";
export const GetConsumptionPointsForContract = "3002";
export const GetContractForOfferError = "3003";
export const GenerateContractError = "3004";
export const DisplayContractError = "3005";
export const UploadSignedContractError = "3006";
export const GetContractError = "3007";
export const GetContractListError = "3008";
export const CreateContractDoublicatedError = "3009";
export const CreateComparisonFileError = "3010";
export const CreateInSignSessionError = "3011";

/* USER / EMPLOYEE / SUPPLIER*/
export const GetAllSupplierError = "4000";
export const UpdateCompanyError = "4001";
export const GetCompanyError = "4002";
export const GetEmployeeError = "4003";
export const AddEmployeeDublicatedError = "4004";
export const AddEmployeeError = "4005";
export const UpdateEmployeeError = "4006";
export const AddLogoFileToEmployeeError = "4007";
export const GetEmployeeListError = "4008";
export const DeleteEmployeeError = "4009";
export const UpdatePartnerError = "4010";
export const LoginEmployeeError = "4011";
export const GetEmployeeColors = "4012";
export const GetInSignError = "4013";

export const CreateConsumptionPointError = "5000";
export const GetConsumptionPointError = "5001";
export const UpdateConsumptionPointError = "5002";
export const UpdateConsumptionPointsForOfferError = "5003";
export const GetConsumptionPointsForOfferError = "5004";
export const UpdateConsumptionPointForContractError = "5005";
export const GetConsumptionPointForContractError = "5006";

/* LOAD_PROFILE */
export const PredictDefaultLpError = "6000";
export const PredictDefaultLpUpdateError = "6001";
export const PredictLpError = "6002";
export const PredictLpUpdateError = "6003";
export const DownloadLpError = "6004";

/* CASUAL */
export const RedirectError = "7000";
export const FileUploadError = "7001";
export const ResetPasswordError = "7002";
export const SetNewPasswordError = "7003";
export const SendSupportFormularError = "7004";
export const ValidateCustomerNameError = "7005";

/* PLATTFORM */
export const PlattformCustomizeUpdateError = "8000";

/* InSign */
export const createInsignSession = "9000";
export const uploadInsignDoc = "9001";
export const checkInsignSession = "9003";
export const UploadInSignDoc = "9004";
export const DownloadInSignDoc = "9005";
export const SessionIDMissing = "9006";
export const AddAdditionalDocument = "9007";
export const MissingInsignSignatures = "9008";


export const AddGlobalTree = "4017";
export const CleanTree = "4018";
export const GetAttributes = "4019";
export const UpdateSupplierGlobalTree = "4020";