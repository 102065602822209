import Communication from "./Components/Communication/communication_new";
import Constants, {
    apiCalculationManagementService, apiError,
    apiFileSystemService,
    apiFunctionActivateEmployee,
    apiFunctionCreateEmployee,
    apiFunctionDeleteEmployee,
    apiFunctionGetEmployee, apiFunctiongetGetConsultant,
    apiFunctionListEmployees,
    apiFunctionLoginConsultant, apiFunctionModifySupplier,
    apiFunctionPasswordForgotten,
    apiFunctionResetPassword,
    apiFunctionReturnResource,
    apiFunctionUpdateEmployee,
    apiPlatformService,
    emptyValue
} from "../Functions/global";
import {deHashVar} from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import createFunctionName from "../Functions/createFunctionName";
import Employee from "./employees";
import getCommunicationClass from "../Functions/getCommunication";

const consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));
const employee_id = deHashVar(localStorage.getItem("consultantEmployeeSecurityToken"));
const user_role = deHashVar(localStorage.getItem('consultantUserAccount'));
class Team {
  constructor(data) {
    //basic
    this.id = "";
    this.name = "";
    this.updated_at = "";
    this.created_at = "";
    this.employees = [];

    //load basic info
    if ((validateVar(data) && validateVar(data.id)) || (validateVar(data) && validateVar(data.agency_id))) {
      //if data found
      this.id = validateVar(data.id) ? data.id : "";
      this.name = validateVar(data.name) ? data.name : "";
      this.updated_at = validateVar(data.updated_at) ? data.updated_at : "";
      this.created_at = validateVar(data.created_at) ? data.created_at : "";
      this.employee_count = validateVar(data.employee_count) ? data.employee_count : "";
        if (data.hasOwnProperty("employee")) {
            let employees = data.employee;
            for (let index in employees) {
                if (employees.hasOwnProperty(index)){
                    this.employees.push(new Employee(employees[index]));
                }
            }
        }
      }
  }

  /*
        Instantiate a new Customer with its ID as Param
        Call Communication Class with matching global defined Vars for API Request
        return new Customer Clnass
     */
  static async platform_team(function_name ,params, method , url, file) {
      let function_url = createFunctionName(function_name);

       if (method === "PUT"){
          function_url = function_url + "/" + 0;
      } else if (method === "POST" || method === "DELETE") {
           function_url = function_url + "/" + params.team_id;
       } else if (method === "GET") {
           if (validateVar(params.team_id))   function_url = function_url + "/" + params.team_id;
       }

      let result = null;
      if (Array.isArray(params)) {
          // result = [];
          let error = false;
          for (let idx in params) {
              if (params.hasOwnProperty(idx)) {
                  let param = params[idx];


                  let communication_params = {
                      method: method, // GET / POST / PUT / DELETE  -> method
                      function_url:  function_url,
                      params: params
                  };
                  let c = getCommunicationClass(communication_params);
                  let res = await c.request_handler();
                if (res !== apiError) result = new Team(res);
                else error = true;
              }
          }
          if (error) result = apiError; // todo show specific error cases
          else {
              return result;// if no error return added employees
          }

      } else {
          // let c = new Communication(
          //     method, // GET / POST / PUT / DELETE  -> method
          //     function_url,
          //     params
          // );


          let communication_params = {
              method: method, // GET / POST / PUT / DELETE  -> method
              function_url:  function_url,
              params: params
          };
          let c = getCommunicationClass(communication_params);
          result = await c.request_handler();
      }
      if (result !== apiError) {
          if (Array.isArray(result)){ // load employee list
              let listResults = [];
              for (var i = 0; i < result.length; i++) {
                  listResults.push(new Team(result[i]));
              }
              return listResults;
          }else { // single employee loaded
              if (validateVar(result.picture_id)) {

                  // let c = new Communication(
                  //     "GET", // GET / POST / PUT / DELETE  -> method
                  //     "file_system/" + result.picture_id, // function call
                  //     null, // params
                  //     null, // file
                  //     "return_link"
                  // );


                  let communication_params = {
                      method: "GET", // GET / POST / PUT / DELETE  -> method
                      function_url:  "file_system/" + result.picture_id,
                      params: null,
                      file: null,
                      file_type: "return_link"
                  };
                  let c = getCommunicationClass(communication_params);



                  result.profile_img = await c.request_handler();

                  let new_employee = new Team(result);
                  if (new_employee.title !== user_role && employee_id === new_employee.id) localStorage.setItem("consultantUserAccount", new_employee.title);
                  return new_employee;
              } else {
                  let new_employee = new Team(result);
                  if (new_employee.title !== user_role && employee_id === new_employee.id) localStorage.setItem("consultantUserAccount", new_employee.title);
                  return new_employee;
              }
          }
      } else return result;
  }


    // static async start_request(communication) {
    //     return await communication.request_handler();
    // }
}

export default Team;
