import validateVar from "../Functions/validation/validateVariable";
import {apiError, apiFunctionListContractTemplates, apiServiceContractGenerator} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import History from "./Components/history";

let contract_template_vars = {
    id: null,
    is_rlm: null,
    is_slp: null,
    is_electricity: null,
    is_gas: null,
    name: null,
};

class ContractTemplate {
    constructor(data) {
        if (validateVar(data)) {
            if (validateVar(data)) {
                this.history = {};
                let class_content = new History(contract_template_vars, data, data.history, []);
                for (let name in class_content) {
                    if (class_content.hasOwnProperty(name)) {
                        this[name] = class_content[name];
                    }
                }
            }
        }
    }

    static async instantiateList(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiServiceContractGenerator,
            get_function: apiFunctionListContractTemplates,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);
        var res = await c.load();
        if (res === apiError || res === "error" || res.result === "[]" || res.result === [])
            return [{}];
        else {
            let listResults = [];
            for (var i = 0; i < res.result.length; i++) {
                listResults.push(new ContractTemplate(res.result[i]));
            }
            return listResults;
        }
    }
}

export default ContractTemplate;
