export const SET_IS_NEW_TO_FALSE = "SET_IS_NEW_TO_FALSE";
export const SET_LIST_ATTRIBUTES = "SET_LIST_ATTRIBUTES";
export const SET_PRICE_STRATEGY = "SET_PRICE_STRATEGY";
export const SET_PRICE_STRATEGY_GLOBAL = "SET_PRICE_STRATEGY_GLOBAL";
export const SET_PRICE_STRATEGY_GLOBAL_ORIGINAL =
  "SET_PRICE_STRATEGY_GLOBAL_ORIGINAL";

export const SET_USER_MESSAGE = "SET_USER_MESSAGE";

export const SET_TARIFF = "SET_TARIFF";

export const SORT_BRANCHES = "SORT_BRANCHES";

export const RESET_PRICE_STRATEGY = "RESET_PRICE_STRATEGY";

/*export const ADD_BRANCH = 'ADD_BRANCH';*/
