import handleFormatPrice from "./handleFormatPrice";
import is_false from "./is_false";
import validateVar from "./validation/validateVariable";
function handleFormatSocket(socket, yearly = false){
    socket = (''+socket).replace(',', '.');
    if (!validateVar(socket)) return "Keine Grundgebühr";
    else if (socket === "" || socket === 0 || socket === "0.000" || socket === "0") return "Keine Grundgebühr";
    else if (+socket > 0) {
        if (is_false(yearly))  return handleFormatPrice(socket, 2, " € / Monat");
        else {
            let socket_yearly = socket * 12;
            return handleFormatPrice(socket_yearly, 2, " € / Jahr");
        }

    }
    else return "- / -";
}
export default handleFormatSocket;