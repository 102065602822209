import validateVar from "../Functions/validation/validateVariable";
import CalculationPackage from "../Objects/calculation_packet";
import ConsumptionPoint from "../Objects/consumption_point";
import {apiError} from "../Functions/global";

let initialState = {};

function calculation_packet(state = initialState, action) {
    let new_state = {...state};
    if (action.type === "GET_PACKET") {
        return get_return_state("packet", new CalculationPackage(action.res), new_state);
    }else if (action.type === "GET_PACKET_CONSUMPTION_POINTS") {
        console.log("action.res.consumption_points", action.res.consumption_points);
        let consumption_points_array = [];
        if (validateVar(action.res) && validateVar(action.res.consumption_points))  {
            for(let consumption_point of action.res.consumption_points){
                consumption_points_array.push(new ConsumptionPoint(consumption_point));
            }
        }
        return get_return_state("consumption_points", consumption_points_array, new_state);
    }else if (action.type === "GET_PACKET_LIST") {
        let packets = [];
        if(validateVar(action.res.calculation_packets) && action.res.calculation_packets !== apiError){
            for (let packet of action.res.calculation_packets) {
                packets.push(new CalculationPackage(packet));
            }
            let combined_array = [];
            if (validateVar(new_state) && validateVar(new_state.calculation_packet_list)) combined_array = new_state.calculation_packet_list.calculation_packets;
            if (packets.length > 0) {
                combined_array = combined_array.concat(packets);
            }
            action.res.calculation_packets = combined_array;
            return get_return_state("packet_list", action.res, new_state);
        }else return apiError;
    } else {
        return state;
    }
}

function get_return_state(name, result, state) {
    return {
        calculation_packet: name === "packet" ? result : state.calculation_packet,
        calculation_packet_list: name === "packet_list" ? result : state.calculation_packet_list,
        consumption_point_list: name === "consumption_points" ? result : state.consumption_point_list,
    };
}

export default calculation_packet;
