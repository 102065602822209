import {consumption_point_object_types} from "./global";


function get_history_reference(history_type) {
    if (consumption_point_object_types.includes(history_type))
        return "consumption_point";
    // else if(history_type === "offer_result")
    //     return "offer_result";
    else return "contract";
}
export default get_history_reference;
