import React from 'react';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import TableLoadingAnimation from "../../LoadingAnimations/TableLoadingAnimation";
import TableCheckbox from "../../Inputs/Checkbox/TableCheckbox";
import PropTypes from "prop-types";
import validateVar from "../../Functions/validation/validateVariable";
import cn from 'classnames';
import is_true from "../../Functions/is_true";
//Table without Predefined SettingsWheelBtn for tablelayouts and without footer (pagination) just to display some data. Example OfferView Tables (Ehub) or Clearing ConsumptionPointsTable
class SimpleTable extends React.Component {

    rowSelected = (data, isSelected) => { //3rd parameter could be "rowIndex"
        if (!this.props.disable_all) {
            let selected_ids = this.props.selected_ids;
            var id = data.id;
            if (isSelected === true){
                selected_ids.push(id)
            }else{
                let index = selected_ids.indexOf(id);
                selected_ids.splice(index, 1);
            }

            this.props.row_select_change(selected_ids);
        }

    };

    allRowsSelected = (isSelect, rows, e) => {
        if (!this.props.disable_all) {
            let selected_ids = this.props.selected_ids;

            if (isSelect === true) {
                var i;
                for (i = 0; i < rows.length; i++) {
                    selected_ids.push(rows[i].id)
                }
            } else {
                selected_ids = [];
            }

            this.props.row_select_change(selected_ids);
        }
    };

    render() {
        const {is_loaded, data, no_padding, columns, key_field, on_column_click, selected_ids, children, disabled_rows, disable_all, no_checkboxes, loading_animation_rows,loading_without_footer} = this.props;
        let {error_row_indexes} = this.props;

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.classList.contains('linked-col')) {
                    let row_clicked = data.find(x => x.id === row.id);
                    on_column_click(row_clicked);
                }
            }
        };

        const selectRowProp = {
            mode: 'checkbox',
            bgColor: '#f8f9fe',
            onSelect: this.rowSelected,
            onSelectAll: this.allRowsSelected,
            selected: selected_ids,
            nonSelectable: disabled_rows,
            selectionRenderer: ({mode, checked, disabled}) => (<TableCheckbox checked={checked} disabled={disabled || is_true(disable_all)}/>),
            selectionHeaderRenderer: ({mode, checked, disabled}) => (
                <TableCheckbox checked={checked} disabled={disabled || is_true(disable_all)}/>)
        };

        const rowStyle = (row, rowIndex) => {
            error_row_indexes = validateVar(error_row_indexes) ? error_row_indexes : [];
            if (validateVar(key_field)) { // use key field to mark rows cause if you filter the index will change
                if(error_row_indexes.includes(row[key_field])) return {
                    // border: 'solid 3px #ffcccc',
                    backgroundColor: "#ffcccc",
                }
            }
        };


        return(<>
            {(is_loaded) ? <ToolkitProvider
                data={data}
                keyField={key_field}
                columns={columns}
                data-checkbox-enabled={false}
                search>
                {props => (
                    <div className={"pb-0 consumption-points-table" + cn({' pt-4': !no_padding})}>
                        <div className="container-fluid">
                            {children}
                        </div>
                        {(validateVar(no_checkboxes) && no_checkboxes) ?
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                rowEvents={rowEvents}
                                bordered={false}
                                rowStyle= {rowStyle}
                                wrapperClasses="table-responsive mb-0"
                            />
                        :
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                selectRow={selectRowProp}
                                rowEvents={rowEvents}
                                bordered={false}
                                rowStyle= {rowStyle}
                                wrapperClasses="table-responsive mb-0"
                            />
                        }

                    </div>
                )}
            </ToolkitProvider> : <TableLoadingAnimation simple_table={true} showRows={loading_animation_rows}/>}
        </>)
    }
}

SimpleTable.defaultProps = {
    key_field: 'id'
};

SimpleTable.propTypes = {
    is_loaded: PropTypes.bool,
    data: PropTypes.array,
    columns: PropTypes.array,
    key_field: PropTypes.string,
    on_column_click: PropTypes.func,
    selected_ids: PropTypes.array,
    row_select_change: PropTypes.func,
};

export default SimpleTable;
