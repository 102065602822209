import History from "./Components/history";
const recommended_action_vars = {
    translation_object: {
        offer_begin_display: "Lieferbegin",
        offer_end_display: "Lieferende"
    },
    calculation_unit_id: null,
    created_at: {
        created_at: null,
        created_at_display: {
            function_name: 'getGermanDateFormat'
        },
        created_at_timestamp: {
            function_name: 'getTimestamp'
        },
    },
    deleted_at: null,
    expires_at: {
        expires_at: null,
        expires_at_display: {
            function_name: 'getGermanDateFormat'
        },
        expires_at_timestamp: {
            function_name: 'getTimestamp'
        },
    },
    id: {
        id: null,
        id_display: {
            function_name: 'handleFormatWattlineId',
            params: ['HAE']
        }
    },
    offer_id: null,
    offer_duration: null,
    min_margin: null,
    max_margin: null,
    offer_begin: {
        offer_begin: null,
        offer_begin_display: {
            function_name: "getGermanDateFormat"
        }
    },
    offer_end: {
        offer_end: null,
        offer_end_display: {
            function_name: "getGermanDateFormat"
        }
    },
    offer_begin_and_end: {
        function_name: 'combine_strings',
        params: ['%%offer_begin_display', ' - ', '%%offer_end_display']
    },
    offer_token: null,
    recommended_action_id: null,
    updated_at: null,
    offer_result_ids: null,
    token: null,
    requested_margin: {
        is_sub_arr: "requested_margin",
        unit: {
            margin_unit: null,
        },
        amount: {
            margin_amount: null,
            margin_amount_display: {
                function_name: "handleFormatPrice",
                params: ["3", "ct / kWh"]
            },
        }
    },
    status: {
        status: null,
        status_display: {
            function_name: "translateRecommendedActionStatus",
        },
    },
    translated_status: {
        function_name: "translateRecommendedActionStatus",
        params: ["%%status"]
    },
    status_color: {
        function_name: "getBadgeColor",
        params: [
            "%%status",
            ["submitted"],
            ["open", "draft"],
            ["expired", "failed", "cancelled"],
            ["active"],
        ]
    },
};

class RecommendedAction {
    constructor(data, status = "open") {
        let class_content = new History(
            recommended_action_vars,
            data,
            null,
            []
        );
        for (let name in class_content) {
            if (class_content.hasOwnProperty(name)) {
                this[name] = class_content[name];
            }
        }
    }
}

export default RecommendedAction;