import React from 'react';
import SimpleHeader from "../../module_components/Displays/Headers/SimpleHeader";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import SaveFormData from "../../module_components/Displays/Footers/SaveFormData";
import {initializeEmployee, updateSingleEmployee, uploadFile, resetFiles} from "../../actions";
import connect from "react-redux/es/connect/connect";
import validateVar from "../../module_components/Functions/validation/validateVariable";
import hasChanged from "../../module_components/Functions/hasChanged";
import {deHashVar} from "../../module_components/Functions/handleHashedVariable";
import InputHandler from "../../module_components/Inputs/InputHandler";
import Validation from "../../module_components/Objects/Components/validation";
import CardHeaderText from "../../module_components/PageContent/CardHeaderText";
import ChangePasswordModal from "./Modals/ChangePasswordModal";
import CustomAlert from "../../module_components/CustomAlert/CustomAlert";
import {dynamic_action_call, upload_file_new} from "../../module_components/Index";
const employee_id = deHashVar(localStorage.getItem("employeeSecurityToken"));
const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));
class UserProfile extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            profile_picture_name: '',
            contract_completion: true,
            has_changed: false,
            logo_placeholder: 'Bild wählen oder hier ablegen',
            error_messages: {},
            employee_data: {},
            is_loaded: false,
            password_reset_mode: false,
        };

        this.changePasswordModal = React.createRef();
        this.deleteAccountWarning = React.createRef();
    };

    componentDidMount() {
        const {employee} = this.props;
        if (validateVar(employee)) {
            this.setCompanyData(employee);
        }else this.props.initialize();
    }

    componentDidUpdate(prevProps, prevState) {
        const {employee, files} = this.props;

        if (employee !== prevProps.employee) {
            if (validateVar(employee) && !this.state.is_loaded) {
                this.setState({
                    props_employee: employee
                });
                this.setEmployeeData(employee);
            }
        }

        // if (validateVar(files) && files !== prevProps.files) {
        //     let params = {
        //         picture_id: files,
        //     };
        //     this.props.onUpdate({picture_id: files});
        //     this.props.resetFiles();
        // }
    }

    updateEmployee = (params) => {
        this.props.onUpdate(params);
    };

    setEmployeeData = (employee) => {
        this.setState({
            employee_data: {
                email: employee.email,
                first_name: employee.first_name,
                last_name: employee.last_name,
                phone: employee.phone,
                old_password: "",
                new_password: "",
                confirm_password: "",
            },
            props_employee: employee,
            is_loaded: true
        });

    };

    cancel_form_changes = () => {
        //TODO set previous employee data
        this.setState({has_changed: false});
    };

    update_state = (state_name, value) => {
        const {props_employee} = this.state;
        let {employee_data} = this.state;

        let has_changed = hasChanged(value, state_name, props_employee, employee_data);

        employee_data[state_name] = value;
        this.setState({
            employee_data: employee_data,
            has_changed: has_changed
        });
    };

    save_form_changes = () => {
        const {employee_data} = this.state;
        let data = {};
        let reset_password = validateVar(employee_data.old_password) || validateVar(employee_data.new_password) || validateVar(employee_data.confirm_password)

        // validation start
        // define special cases for error validation
        let special_cases = {
            phone: {
                case_name: "phone"
            },
            old_password: {
                case_name: !reset_password && "can_be_empty"
            },
            new_password: {
                case_name: reset_password ? "new_password" : "can_be_empty"
            },
            confirm_password: [{
                case_name: reset_password ? "compare_values" : "can_be_empty",
                compare_value: {confirm_password: employee_data.new_password},
                error_message: "Die Passwörter stimmen nicht miteinander überein.",
                is_equal: true
            }],
        };
        // call abstract error validation and set values in state
        let validation_result = new Validation(employee_data, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        if(!validation_result.has_error){
            data.employee_id =  employee_id;
            data.agency_id =  supplier_id;
            data.email =  employee_data.email;
            data.first_name =  employee_data.first_name;
            data.last_name =  employee_data.last_name;
            data.phone =   employee_data.phone;
            data.old_password = reset_password ? employee_data.old_password : null;
            data.password = reset_password ? employee_data.new_password : null;

            this.props.onUpdate(data);
            this.setState({
                has_changed: false,
                // is_loaded: false //todo loading animation
            })
        } else this.setState({error_messages: validation_result.error_messages});
    };

    render() {
        const {dynamic_action_call, default_file_upload} = this.props;
        const {is_loaded, logo_placeholder, error_messages, employee_data} = this.state;
        const {first_name, last_name, email, title_id} = employee_data;

        return(
            <>
                <CustomAlert
                    title='Achtung'
                    text='Sind sie Sicher, dass Sie Ihren Account edgültig löschen möchten?'
                    primaryBtnText='Nein, doch nicht'
                    secondaryBtnText='Ja, bitte löschen'
                    primaryBtnClicked={() => this.deleteAccountWarning.current.turnOffModal()}
                    secondaryBtnClicked={() => this.props.deleteAccount({
                        role_id: title_id,
                        email: email
                    })}
                    ref={this.deleteAccountWarning}
                    allowToggle
                />
                <ChangePasswordModal onSubmit={this.updateEmployee} callback={this.handleChangePasswordSubmit} ref={this.changePasswordModal} />
                <SimpleHeader name="Nutzerprofil" headerText="" />
                <Container className="mt--6" fluid>
                    <Row className="justify-content-center">
                        <Col className="card-wrapper" lg="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <CardHeaderText title='Einstellungen' />
                                        </Col>
                                        <Col className='text-right'>
                                            <div className='settings-wheel-btn' onClick={() => this.changePasswordModal.current.toggle_modal()}><b>Passwort ändern</b></div>
                                            <div className='settings-wheel-btn ml-3' onClick={() => this.deleteAccountWarning.current.toggleModal()}><i className="fas fa-trash-alt" /></div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className='custom-border-bottom'>
                                        <Row className='mb-2'>
                                            <Col xl={12}>
                                                <h3 className='text-uppercase text-muted'>Profilbild</h3>
                                            </Col>
                                        </Row>
                                        <Row className='px-3'>
                                            <Col xl={12}>
                                                <InputHandler content_title='Profilbild hochladen (optional)'
                                                              type="file_upload_new"
                                                              name='logo_id'
                                                              value={logo_placeholder}
                                                              file_type="images" // images / pdf / xlsx -> default
                                                              cropper={true}
                                                              cropper_headline="Logo zuschneiden"
                                                              aspect_ratio={1}
                                                              is_loaded={is_loaded}
                                                              public_static={1}
                                                              dynamic_action_call={dynamic_action_call}
                                                              default_file_upload={default_file_upload}
                                                              return_file_id={(file_object) => this.props.onUpdate({picture_id: file_object.file_id})}
                                                              allow_reupload={true}
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className='custom-border-bottom'>
                                        <Row className={'mb-3'}>
                                            <Col xl={12}>
                                                <h3 className='text-uppercase text-muted'>Nutzerdaten</h3>
                                            </Col>
                                        </Row>
                                        <Row className='px-3 pb-2'>
                                            <Col className='px-3' xl={6}>
                                                <InputHandler
                                                    content_title="E-Mail"
                                                    type="text"
                                                    name="email"
                                                    value={email}
                                                    disabled={true}
                                                    on_change={(name, value) => this.update_state(name, value)}
                                                    is_loaded={is_loaded}
                                                    error_state={error_messages.email_error}
                                                />
                                            </Col>
                                            <Col className='px-3' xl={6}>
                                                <InputHandler
                                                    content_title="Telefonnummer"
                                                    type="text"
                                                    name="phone"
                                                    value={employee_data.phone}
                                                    disabled={false}
                                                    on_change={(name, value) => this.update_state(name, value)}
                                                    is_loaded={is_loaded}
                                                    error_state={error_messages.phone_error}
                                                />
                                            </Col>
                                            <Col className='px-3' xl={6}>
                                                <InputHandler
                                                    content_title="Vorname"
                                                    type="text"
                                                    name="first_name"
                                                    value={first_name}
                                                    disabled={false}
                                                    on_change={(name, value) => this.update_state(name, value)}
                                                    is_loaded={is_loaded}
                                                    error_state={error_messages.first_name_error}
                                                />
                                            </Col>
                                            <Col className={'px-3'} xl={6}>
                                                <InputHandler
                                                    content_title="Nachname"
                                                    type="text"
                                                    name="last_name"
                                                    value={last_name}
                                                    disabled={false}
                                                    on_change={(name, value) => this.update_state(name, value)}
                                                    is_loaded={is_loaded}
                                                    error_state={error_messages.last_name_error}
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div>
                                        <Row className='mb-3'>
                                            <Col xl={12}>
                                                <h3 className='text-uppercase text-muted'>E-Mail Benachrichtigungen</h3>
                                            </Col>
                                        </Row>
                                        <Row className='px-3 pb-2 mb-4'>
                                            <Col className='px-3' xl={12}>
                                                <InputHandler
                                                    text="Vertragsabschlüsse"
                                                    type="checkbox"
                                                    name="contract_completion"
                                                    value={true}
                                                    disabled={true}
                                                    skeleton={[200, 46]}
                                                    on_change={(name, value) => this.update_state(name, value)}
                                                    is_loaded={is_loaded}
                                                />
                                           </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <SaveFormData submitCallback={this.save_form_changes} cancelCallback={this.cancel_form_changes} display={this.state.has_changed} text={'Sie haben Einstellungen auf dieser Seite geändert. Wollen Sie die Änderungen speichern?'} />
            </>
        );
    }
}

let mapStateToProps = function(state)   {
    return {
        employee: state.employee_data,
        files: state.files
    }
};

let mapDispatchToProps = {
    onUpdate: updateSingleEmployee,
    initialize: initializeEmployee,
    uploadFile: uploadFile,
    resetFiles: resetFiles,
    //FileUpload requests
    dynamic_action_call: dynamic_action_call,
    default_file_upload: upload_file_new,
};

let UserProfilContainer = connect(mapStateToProps, mapDispatchToProps)(UserProfile);

export default UserProfilContainer;