import React from 'react';
import {Col, Row, Button} from 'reactstrap';
import validateVar from "../../../Functions/validation/validateVariable";
import Validation from "../../../Objects/Components/validation";
import InputHandler from "../../../Inputs/InputHandler";
import isEmptyObject from "../../../Functions/isEmptyObject";
import TimelineLoadingAnimation from "./TimelineLoadingAnimation";
import get_history_reference from "../../../Functions/get_history_reference_type";
import {project_name, project_names} from "../../../Functions/global";
import getAgencyID from "../../../Functions/getAgencyID";
import scrollToElement from "../../../Functions/scrollTo";
import is_true from "../../../Functions/is_true";
import Badge from "../../../Displays/Badges/Badge";
import RoundedImage from "../../../Displays/Images/RoundedImage";

const contract_display_values = [ //when values in contract are changed with clearing, these property names define which ones are beeing displayed in the timeline
    "cancellation_period_display",
    "contract_extension_display",
    "quantity_tolerance",
    "price_rate_full_display",
    "contract_price_net_full_display",
    "contract_price_gross_full_display",
    "socket_rate_formated",
    "socket_gross_rate_formated", // PRIVATE CUSTOMER
    "customer_company",
    "billing_zip",
    "billing_city",
    "billing_street",
    "billing_house_number",
    "customer_first_name",
    "customer_last_name",
    "customer_phone",
    "customer_email",
    "contract_begin_display",
    "contract_end_display",
    "payment_method_display",
    "name_on_account",
    "iban",
    "bic",
    "bank_name",
    "agb_file_id",
    "signed_id",
    "signed_contract",
    "authority_file",
    "switching_status",
    "edit_begin",
    "switching_date",
];

const consumption_point_display_values = [ //same thing for consumption point as above
    "consumption_display",
    "consumption_type",
    "business_type_display",
    "specification_display",
    "business_type_branche",
    "postal_code_time_line",
    "city_time_line",
    "street_time_line",
    "house_number_time_line",
    "invoice_company",
    "invoice_postal_code",
    "invoice_city",
    "invoice_street",
    "invoice_house_number",
    "invoice_email",
    "market_identifier",
    "meter_identifier",
    "habitation_status_display",
    "pre_provider",
    "pre_customer_number",
    "switching_status",
    "switching_date"
];

class Timeline extends React.Component {
    constructor(props) {
        super(props);
        let agency_id_obj = getAgencyID({}, true);
        this.state = {
            agency_id: agency_id_obj.agency_id,
            current_message: '',
            file: null,
            error_messages: {},
            timeline_loaded: true,
            filter: "all",
        };
        this.filter_values = this.get_filter_values();
    }

    componentDidUpdate(prevProps) {
        const {company, chronik, site_name, message_id} = this.props;
        if (validateVar(company) && company !== prevProps.company) {
            this.setState({
                agency_logo: (validateVar(company) && company.logo_file_id > 0 && validateVar(company.logo_file_id)) ? company.logo_file_id : null,
            })
        }
        if (validateVar(chronik) && chronik !== prevProps.chronik && site_name === "contract") {
            if (!validateVar(prevProps.chronik)) {
                scrollToElement(document.getElementById(message_id+ ""));
            } else {
                scrollToElement(document.getElementById('message_input'));
            }
        }
    }

    get_filter_values = () => {
        if (project_name === project_names.wattline) {
            return [
                {key: 'all' , value: 'Alles'},
                {key: 'Notiz angelegt', value: 'Notizen'},
                {key: 'E-Mail', value: 'E-Mails'},
                {key: 'Aufgabe Kalkulationstermin Handlungsempfehlung', value: 'Prozessschritte'},
            ];
        } else {
            return [
                {key: 'all' , value: 'Alles'},
                {key: 'messages' , value: 'Nur Nachrichten'},
                {key: 'files', value: 'Nur Dateien'},
                {key: 'contracts', value: 'Auftrags�nderungen'},
                {key: 'consumption_points', value: 'Lieferstellen�nderungen'}
            ];
        }
    };

    myChangeHandler = (name, value) => {
        this.setState({[name]: value});
    };

    updateMessages = () => {//add new Message from the input text field + files upload
        let {current_message, file} = this.state;
        let special_cases = {};
        // call abstract error validation and set values in state
        let validation_result = new Validation({current_message}, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });

        if(!validation_result.has_error){
            let subject = (isEmptyObject(file) || !validateVar(file)) ? "Nachricht gesendet" : "Datei hochgeladen";
            let params = {
                message: {
                    message: current_message,
                    subject: subject,
                },
            };
            this.props.create_time_line(params, (isEmptyObject(file) || !validateVar(file)) ? null : file);
            this.setState({
                current_message: "",
                file: null
            });
        }
    };

    handle_key_press = (e) => {//Allow submit on "Enter" press
        if (e.key === 'Enter') {
            this.updateMessages();
        }
    };

    on_change = (name, value) => {
        this.setState({[name]: value});
    };

    get_history_content = (chronik) => {
        let {contract, consumption_points} = this.props;
        let site_object = null;
        let reference_id = null;
        let chronik_type = null;
        let status = null;
        let history_id = null;
        let consumption_point = (Array.isArray(consumption_points))  ? consumption_points[0] : consumption_points;
        let content = Object.keys(chronik.history).map(function(key) {
            let current_obj = chronik.history[key];
            history_id = current_obj.history_id;


            let translation_name = key + "_translation";
            status = (validateVar(current_obj.status)) ? current_obj.status : status;
            let reference_type = get_history_reference(chronik.history_type);
            if(reference_type === "contract") {
                site_object = contract;
                chronik_type = "contract";
            } else {
                site_object = consumption_point;
                chronik_type = "consumption_point"
            }

            reference_id = (validateVar(current_obj.reference_id)) ? current_obj.reference_id : null;
            if (!isEmptyObject(current_obj) && validateVar(site_object) && validateVar(site_object[translation_name]) && (contract_display_values.includes(key) || consumption_point_display_values.includes(key))) {
                return <Row key={key} className={"hover"}>
                    <Col sm={12} md={12} lg={4}>
                        <span className={"eless-font-semi-bold"}>{site_object[translation_name]}</span>:
                    </Col>
                    <Col sm={12} md={12} lg={3}>
                        <span className='d-lg-none eless-font-semi-bold'>Von: </span>{current_obj.value_original}
                    </Col>
                    <Col  sm={12} md={12} lg={2} className='d-none d-lg-flex'>
                        <i className="fas fa-long-arrow-alt-right" />
                    </Col>
                    <Col sm={12} md={12} lg={3} className='mb-3 mb-lg-0'>
                        <span className='d-lg-none eless-font-semi-bold'>Zu: </span>{current_obj.value}
                    </Col>
                </Row>
            } else return null;
        });
        return {
            history_content: content,
            status: status,
            site_object: chronik_type,
            reference_id: reference_id,
            history_id: history_id
        };
    };

    get_message_background = (status, agency_id, chronik) => {
        let {message_id} = this.props;
        let chronik_agency_id = chronik.agency_id;
        let class_name = "message-background"; // -> message from partner
        if(+agency_id === +chronik_agency_id){
            class_name = "message-background message-sent"; // -> own message
        }
        if (+chronik.message_id === +message_id) {
            class_name = "message-background message-selected";
        }
        return class_name;
    };

    get_status_translated = (status) => {
        if (!validateVar(status)) return null;
        else if (status === "accepted") return <Badge value="Akzeptiert" color='green'/>;
        else if (status === "open") return <Badge value="Ausstehend" color='orange'/>;
        else if (status === "declined") return <Badge value="Abgelehnt" color='red'/>;
        return null;
    };

    click_on_chronik = (site_object, reference_id, history_id) => {
        if (validateVar(site_object) && validateVar(reference_id)) {
            let {site_name} = this.props;
            if (site_object !== site_name ) { // no redirect if your already on the page
                // this.props.redirect(ConsumptionpointPath + '/' + contract_id + '/' + reference_id + "/" + protocol_id); // todo use local storage
            } else {
                //todo change tab and scroll to change
                // let element = document.getElementById(history_id + "");
                // this.props.change_tab_and_scroll(element);
            }
        } else {

        }
    };

    get_history_title = (name = "Bezeichnung", from = "Von", to = "Zu", icon = false) => {
        return <Row className='d-none d-lg-flex custom-border-bottom mb-1 eless-font-bold'>
            <Col  sm={12} md={12} lg={4}>
                {name}
            </Col>
            <Col sm={12} md={12} lg={3}>
                {from}
            </Col>
            <Col sm={12} md={12} lg={2}>
            </Col>
            <Col sm={12} md={12} lg={3}>
                {to}
            </Col>
        </Row>
    };

    get_chronik_content = (chronik, agency_logo, agency_id) => {
        let return_arr = (validateVar(chronik.history)) ? this.get_history_content(chronik) : {} ;
        let {history_content, status, site_object, reference_id, history_id} = return_arr;
        if (validateVar(history_content)) history_content.unshift(this.get_history_title());
        let background_color = this.get_message_background(status, +agency_id, chronik);
        let status_translated = this.get_status_translated(status);
        if (project_name === project_names.enermakler && +agency_id === +chronik.agency_id)  chronik.sender_name = "enermakler";
        else if (project_name === project_names.ehub && +agency_id !== +chronik.agency_id)  chronik.sender_name = "enermakler";
        return (
            (validateVar(chronik.message)) &&
            <div className={"timeline-block"} id={chronik.message_id}>
                                  <span className={'timeline-step'}>
                                     <RoundedImage image_id={chronik.sender_logo_id} size='sm' />
                                  </span>
                <div onClick={() => this.click_on_chronik(site_object, reference_id, history_id)} className={"timeline-content " + background_color}>
                    <div className="text-muted font-weight-bold">
                        {chronik.created_at_display} - {chronik.sender_name} {(validateVar(status_translated)) && <div className='timeline-header-boxes'><div className='d-inline-block text-right'>{status_translated}</div></div>}
                    </div>
                    <div className="history-subject eless-font-bold mt-3 mb-0">{chronik.subject}</div>

                    {validateVar(chronik.message) && chronik.message ? <div className="mt-1 mb-0">
                        {chronik.message}
                    </div> : <div className='mt-3'/> }
                    {validateVar(chronik.file_id) && <div className='timeline-attachment'>
                        <span className='attachments'>
                            <i className="fas fa-paperclip" /><span className='cursor-pointer' onClick={() => this.props.timeline_download(chronik.file_id)}>Anhang herunterladen</span>
                        </span>
                    </div>}
                    {history_content}
                </div>
            </div>
        )
    };

    get_filter_indicator = (item) => {
        if (project_name === project_names.wattline) {
            return null;
        }else {
            return (validateVar(item.history_type)) ? get_history_reference(item.history_type) : null;
        }
    };


    filter_content = (item) => {
        let {filter} = this.state;
        let {subject} = item;
        let filter_indicator = this.get_filter_indicator(item);
        let is_wattline = (project_names.wattline === project_name);
        if (filter === "all" || filter === "0") return true;
        // e hub / enermakler filter
        else if (!validateVar(filter_indicator) && subject === "Nachricht gesendet" && filter === "messages") return true;
        else if (!validateVar(filter_indicator) && subject === "Datei hochgeladen" && filter === "files") return true;
        else if (filter_indicator === "contract" && filter === "contracts") return true;
        else if (filter_indicator === "consumption_point" && filter === "consumption_points") return true;
        //wattline filter
        else if (is_wattline && filter.includes(subject)) return true;
        else if (is_wattline && filter === 'clearing' && validateVar(item.clearing)) return true;
        else return false;
    };

    handle_children = (message) => {
        let subject = message.subject;
        return (subject !== "Nachricht gesendet");
    };


    render() {
        let {current_message, file, agency_id, error_messages, agency_logo, filter} = this.state;
        let {current_message_error} = error_messages;
        let {chronik, is_loaded_chronik, site_name, disable_chat, chronik_disabled_message} = this.props;
        let file_name = null;
        if (validateVar(file)) {
            file_name = file.name;
        }
        let show_children_info = true;

        if(validateVar(chronik_disabled_message)) return <div className='mb-3 mx-3 static-warning-notification grey'>
            <b>HINWEIS!</b> {chronik_disabled_message}
        </div>

        return (
            <>
                {(is_loaded_chronik) ?
                <div className="m-4">
                <div>
                    {(chronik.length > 0 && ((validateVar(site_name) && (site_name === "contract")) || project_name === project_names.wattline)) && <InputHandler
                        content_title={"Filter"}
                        type={"select"}
                        select_options={this.filter_values}
                        name={"filter"}
                        on_change={(name, value) => this.on_change(name, value)}
                        value={filter}
                    />}
                    <div className="timeline timeline-one-side"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis">
                        {chronik.map((item) => {
                            if (is_true(show_children_info)) show_children_info = this.handle_children(item);
                            if (this.filter_content(item))  return this.get_chronik_content(item, agency_logo, agency_id);
                            else return null;
                        })}
                    </div>
                    {(is_true(show_children_info)) && this.props.children}
                </div>
                {(validateVar(site_name) && (site_name === "contract")) && <div className='pb-0 custom-border-bottom'/>}
                {(validateVar(site_name) && (site_name === "contract")) && <Row>
                    <Col className='chat-actions'>
                        <RoundedImage image_id={agency_logo} size='md'/>
                        <div className='flex-fill mx-3 position-relative'>
                            <InputHandler
                                on_change={(name, value) => this.myChangeHandler(name, value)}
                                is_loaded={true}
                                error_state={current_message_error}
                                type={'text'}
                                id={"message_input"}
                                value={current_message}
                                name={"current_message"}
                                on_key_down={this.handle_key_press}
                                placeholder={'Ihre Nachricht'}
                                disabled={disable_chat}
                                no_margin
                                no_padding
                                auto_complete={"new-password"}
                                classes={'message-input'}
                            />
                        </div>
                        <div className='responsive-line-break'/>

                        <InputHandler
                            type={"file_upload_button"}
                            input_wrapper_classes='file-upload-wrapper'
                            name={"file"}
                            value={file_name}
                            upload_file={(value) => this.myChangeHandler("file", value)}
                            file_type={"allow_all"}
                            no_margin
                            no_padding
                            disabled={disable_chat}
                            delete_file={() => this.myChangeHandler("file", null)}
                        />

                        <Button onClick={() => this.updateMessages()} className='btn primary-btn'>
                            Senden
                        </Button>
                    </Col>
                </Row> }

            </div> : <TimelineLoadingAnimation />}
        </>

        )
    }
}

export default Timeline;