import React from 'react';
import {Row, Col} from "reactstrap";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import PropTypes from 'prop-types';

class SaveFormData extends React.Component {

    render() {
        const {submit_disabled} = this.props;

        let displayClass = ' hide';
        if(this.props.display){
            displayClass = ' display';
        }

        return(
            <div className={'save-form-data-wrapper fixed-bottom'+displayClass}>
                <Row>
                    <Col className='text' xs={12} sm={12} md={6} lg={8}>
                        {this.props.text}
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4} className='col-auto'>
                        <PrimaryButton disabled={submit_disabled} onClick={this.props.submitCallback} value='Speichern' />
                        <SecondaryButton onClick={this.props.cancelCallback} value='Abbrechen' />
                    </Col>
                </Row>
            </div>
        );
    }
}

SaveFormData.propTypes = {
    text: PropTypes.string.isRequired,
    submitCallback: PropTypes.func.isRequired,
    cancelCallback: PropTypes.func.isRequired,
    display: PropTypes.bool.isRequired,
};

export default SaveFormData;