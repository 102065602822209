import React from 'react';
import {redirect} from "../../Index";
import connect from "react-redux/es/connect/connect";

class ReturnButton extends React.Component {

    redirect_user = () => {
        if(this.props.redirect && {}.toString.call(this.props.redirect) === '[object Function]'){
            this.props.redirect(this.props.prevPage);
        } else window.location = this.props.prevPage; //on stage & live this.props.redirect is not a function
    }

    render() {
        return (<div onClick={() => this.redirect_user()} className='secondary-text-color cursor-pointer float-right'>
            <i className='ni ni-curved-next cursor-pointer' /> {this.props.text}
        </div>)
    }
}

let mapStateToProps = function(state)   {
    return {}
};

let mapDispatchToProps = {
    redirect: redirect,
};

let ReturnButtonContainer = connect(mapStateToProps, mapDispatchToProps)(ReturnButton);

export default ReturnButtonContainer;
