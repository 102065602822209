import Employee from "../Objects/employees";
import validateVar from "../Functions/validation/validateVariable";
import { hashVar } from "../Functions/handleHashedVariable";
import {ContractsPath, DashboardPath} from "../../globalNavigation";
import logoutEmployee from "../Functions/supplierActions";

let initialState = null;

function employee(state = initialState, action) {
  if (action.type === "INITIALIZE_EMPLOYEE_LIST") {
    return action.res;
  } else if (action.type === "ADD_EMPLOYEE") {



    let listResults = [];
    let employees = action.res;
    for (var i = 0; i < employees.length; i++) {
      listResults.push(new Employee(employees[i]));
    }
    return listResults;
  } else if (action.type === "UPDATE_EMPLOYEE") {
    let changedEmployee = action.res;
    let listResults = [];
    if (validateVar(action.res)) {
      for (let employee of state) {
        if (changedEmployee.id === employee.id) {
          listResults.push(changedEmployee);
        } else {
          listResults.push(employee);
        }
      }
      return listResults;
    } else {
      //todo error message
      return state;
    }
  } else if (action.type === "UPDATE_EMPLOYEE_ARRAY") {
    let changed_employees = action.res;
    let listResults = [];
    let selected_employee = null;
    if (validateVar(action.res)) {
      for (let employee of state) {
          selected_employee = null;
          for (let changed_employee of changed_employees) {
              if (+changed_employee.id === +employee.id) {
                selected_employee = changed_employee;
              }
              if (validateVar(selected_employee)) {
                listResults.push(selected_employee);
                break;
              }
          }
          if (!validateVar(selected_employee)) listResults.push(employee);
      }
      return listResults;
    }
  } else if (action.type === "DELETE_EMPLOYEE") {
    let newState = [];

    let employee_id = action.ids;

    for (let employee of state) {
      if (!employee_id.includes(employee.id)) newState.push(employee);
    }
    return newState;
  } else if (action.type === "LOG_IN_EMPLOYEE") {
    logoutEmployee(false);
    let data = action.res;
    let userRole = data.title[0].name;
    let hashedID = hashVar(data.agency_id);
    let hashedCustomerID = hashVar(data.id);
    let hashedRole = hashVar(userRole);
    localStorage.setItem("employeeSecurityToken", hashedCustomerID);
    localStorage.setItem("evuSecurityToken", hashedID);
    localStorage.setItem("evuUserAccount", hashedRole);
    window.location.href = DashboardPath;
    return state;
  } else if (action.type === "FEEDBACK_EMPLOYEE") {
    return action.res;
  } else if (action.type === "EMPLOYEE_RESET") {
    return action.res;
  } else if (action.type === "LOGIN_EMPLOYEE") {
    return action.res;
  } else if (action.type === "ACTIVATE_EMPLOYEE") {
    return action.res;
  } else if(action.type === "RETURN_EMPLOYEE_STATE"){
    return state;
  } else if (action.type === "ERROR_EMPLOYEE") {
    return "ERR";
  } else {
    return state;
  }
}

export default employee;
