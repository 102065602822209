/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  Container,
} from "reactstrap";
import cn from 'classnames'

import {default_logo_id, project_name, project_names, static_download_url} from "../../Functions/global";
import validateVar from "../../Functions/validation/validateVariable";

class AuthNavbar extends React.Component {
  render() {
    const {plattform} = this.props;
    let img_src = '';

    img_src = static_download_url + default_logo_id;

    if(validateVar(localStorage.getItem('logo_id'))) img_src = static_download_url + localStorage.getItem('logo_id');

    if(validateVar(plattform) && validateVar(plattform.logo_id)) img_src = static_download_url + plattform.logo_id;

    return (
      <>
        <Navbar
          className="auth-nav navbar-horizontal navbar-main navbar-dark navbar-transparent pt-0"
          expand="lg"
          id="navbar-main"
        >
          <div className={cn({'container': project_names.wattline !== project_name})}>
            <div className='px-5 pt-4'>
              <NavbarBrand to="#" tag={Link}>
                {validateVar(img_src) && <img
                    alt="..."
                    className='navbar-brand-img'
                    src={img_src}
                />}
              </NavbarBrand>
            </div>
          </div>
        </Navbar>
      </>
    );
  }
}

export default AuthNavbar;
