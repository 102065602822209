import React from 'react';
import {Card, CardBody, Row, Col} from "reactstrap";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import Skeleton from "react-loading-skeleton";
import cn from 'classnames';

const HeaderInfoCard = (props) => {

    let growthClass = '';
    if(validateVar(props.growth)){
        if(props.growth.includes('+')) growthClass = 'plus';
        if(props.growth.includes('-')) growthClass = 'minus';
    }

    return(<Card className='info-card'>
       <CardBody>
           <Row className={cn({'mb-4': validateVar(growthClass)})}>
               <Col sm={12} md={7}>
                   <h2 className='mb-0'>{props.title}</h2>
                   {props.is_loaded ? <div className='current-count'>{props.count}</div> : <Skeleton height={25} width={100} />}
               </Col>
               <Col sm={12} md={5} className='d-none d-md-block text-right'>
                   {props.is_loaded ? <div className='icon-wrapper'>
                        <i className={"ni ni-"+props.icon} />
                   </div>: props.icon }
               </Col>
           </Row>
           {validateVar(growthClass) && <Row>
               <Col sm={12} md={6} lg={12} className='growth-info d-none d-lg-inline-block pr-0'>
                   {props.is_loaded ? <>
                       <b className={'pr-3 ' + growthClass}>{props.growth}</b>
                       <span>Seit letzten Monat</span>
                   </> : <Skeleton height={20} width={230}/>}
               </Col>
               <Col sm={12} md={6} className='d-md-block d-lg-none growth-info pr-0'>
                   {props.is_loaded ? <>Seit letzten Monat</> : <Skeleton height={20} width={100}/>}
               </Col>
           </Row>}
       </CardBody>
    </Card>)
};

export default HeaderInfoCard;