import * as actionTypes from "../Index/actionTypes";
import * as operatorTypes from "../AttributeFields/Enums/Operator.enum";
import { multiselectAttributes } from "../Functions/global";
const initialState = {
  isNew: true,
  listAttributes: [],
  priceStrategy: [
    {
      name: "Tarif Kriterien",
      tree_attributes: []
    }
  ]
};

const price_strategy = (state = { ...initialState }, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_NEW_TO_FALSE:
      return {
        ...state,
        isNew: false
      };
    case actionTypes.SET_LIST_ATTRIBUTES:
      return {
        ...state,
        listAttributes: action.listAttributes
      };
    case actionTypes.SET_TARIFF:
      return {
        ...state,
        tariff: action.tariff
      };
    case actionTypes.SET_PRICE_STRATEGY:
      return {
        ...state,
        priceStrategy: action.priceStrategy
      };
    case actionTypes.SET_PRICE_STRATEGY_GLOBAL:
      return {
        ...state,
        priceStrategyGlobal: action.priceStrategy
      };
    case actionTypes.SET_PRICE_STRATEGY_GLOBAL_ORIGINAL:
      return {
        ...state,
        priceStrategyGlobalOriginal: action.priceStrategy
      };
    case actionTypes.SORT_BRANCHES:
      const updatedPricestrategy = getSortedPriceStrategy(state.priceStrategy);

      return {
        ...state,
        priceStrategy: updatedPricestrategy
      };
    case actionTypes.RESET_PRICE_STRATEGY:
      // FIXME State.priceStrategy wird nicht zurückgesetzt
      // Schon beim AddBranch wird der initialState referenziert und geändert
      console.log(" priceStrategy: [...initialState.priceStrategy]", [
        ...state.priceStrategy
      ]);
      return {
        ...state,
        priceStrategy: [...state.priceStrategy] // pre code (check if error appears):  [...initialState.priceStrategy]
      };

    // TODO Frage: Soll die gesamte Logik von priceStrategy-Service in Redux verlagert werden?

    /*
        case actionTypes.ADD_BRANCH:
                    const updatedPriceStrategy = [
                        ...state.priceStrategy
                    ];
                    const updatedBranch = [];
                    for (let key in updatedPriceStrategy[action.priceStrategyIndex].tree_attributes[action.listStructIndex].branches) {
                        updatedBranch.push(updatedPriceStrategy[action.priceStrategyIndex].tree_attributes[action.listStructIndex].branches[key]);
                    }
                    updatedBranch.push(newBranch);
                    updatedPriceStrategy[action.priceStrategyIndex].tree_attributes[action.listStructIndex].branches = updatedBranch;
                    return {
                        ...state,
                        priceStrategy: updatedPriceStrategy
                    };*/

    default:
      return state;
  }
};

export default price_strategy;
let has_error = false;

// TODO evtl. Logik für Sortierung verlagern
const getSortedPriceStrategy = currentPriceStrategy => {
  const updatedPriceStrategy = [...currentPriceStrategy];
  let has_error = false;
  function selectFieldHasError(attribute) {
    let branches = attribute.branches;

    for (let index in branches) {
      let errorSpan = document.getElementById(
        "errorSpan" + attribute.attribute_id + "-" + index
      );

      if (branches[index].value.length === 0 || branches[index].value === "0") {
        //Fresh added branch has value 0, selected value is always in array so we can check here if value is 0 to display error
        has_error = true;
        errorSpan.classList.remove("d-none");
      } else {
        if (errorSpan !== null && typeof errorSpan !== "undefined") {
          //When multiselect turns to textForm, the errorSpan disappears as well
          errorSpan.classList.add("d-none");
        }
      }
    }
  }

  updatedPriceStrategy.map(priceStrategy => {
    priceStrategy.tree_attributes.map(listStruct => {
      // listStruct.branches = sortByOperator(listStruct.branches); //This line is causing a bug and sort function is trash anyways so disabled for now. The bug is that after tariffTree submit the branches of a select attribute disappear
      if (multiselectAttributes.includes(listStruct.attribute_id))
        selectFieldHasError(listStruct);
      listStruct.branches.map(branch => {
        //Check every branch if its value is Invalid
        if (branch.isValueInvalid) has_error = true;
        else if (branch.isSurchargeInvalid) has_error = true;
      });
    });
    priceStrategy.has_error = has_error;
  });

  return updatedPriceStrategy;
};

const sortByOperator = oldBranch => {
  const sortedByOperatorBranch = [];

  for (let condition of oldBranch) {
    if (condition.operator === operatorTypes.COMP_OP_LE) {
      sortedByOperatorBranch.push(condition);
    }
  }

  const liesBetweenConditions = [];
  for (let condition of oldBranch) {
    if (condition.operator === operatorTypes.LOGIC_OP_AND) {
      liesBetweenConditions.push(condition);
    }
  }
  sortedByOperatorBranch.push(
    ...liesBetweenConditions.sort((a, b) => a.value1 - b.value1)
  );

  const equalsConditions = [];
  for (let condition of oldBranch) {
    if (condition.operator === operatorTypes.COMP_OP_EQ) {
      equalsConditions.push(condition);
    }
  }
  sortedByOperatorBranch.push(
    ...equalsConditions.sort((a, b) => a.value - b.value)
  );

  for (let condition of oldBranch) {
    if (condition.operator === operatorTypes.COMP_OP_GE) {
      sortedByOperatorBranch.push(condition);
    }
  }

  return sortedByOperatorBranch;
};
