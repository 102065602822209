import Communication from "./Components/Communication/communication";
import validateVar from "../Functions/validation/validateVariable";
import {
    apiElectricityProfileService,
    apiError,
    apiFunctionGetProfiles,
    apiGasProfileService
} from "../Functions/global";
import getCommunicationClass from "../Functions/getCommunication";


class defaultLoadProfiles {
    constructor(data, communication = undefined) {
        /*
         *   default value for vars in this class
         *   add new vars here
         * */
        //basic
        this.key = "";
        this.value = "";

        //load basic info

        if (validateVar( data ) && validateVar( data.id )) {
            //if data found
            this.key = !validateVar( data.code ) ? "" : data.code;
            this.value =
                !validateVar( data.name )
                    ? ""
                    : data.name + " (" + data.code + ")";
        }
    }


    /*
              Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
              Call Communication Class with matching global defined Vars for API Request
              return Array with all TariffCalculatedContainer Obj
       */
    static async getAllProfiles() {
        let energy_types = ["ELECTRICITY", "GAS"];
        let consumption_types = ["SLP", "RLM"];
        let service = null;

        let lps = {};
        for (let energy_index in energy_types) {
            if (energy_types.hasOwnProperty(energy_index)) {
                let energy_type = energy_types[energy_index];
                lps[energy_type] = {};
                for (let consumption_index in consumption_types) {
                    if (consumption_types.hasOwnProperty(consumption_index)) {
                        let consumption_type = consumption_types[consumption_index];
                        lps[energy_type][consumption_type] = await this.requestLps(energy_type, consumption_type);
                    }
                }
            }
        }
        return lps;
    }


    static async requestLps(energy_type, consumption_type) {
        let service = null;

        if (energy_type === 'ELECTRICITY') {
            service = apiElectricityProfileService;
        } else {
            service = apiGasProfileService;
        }

        // //todo request
        // let c = new Communication(
        //     service,
        //     apiFunctionGetProfiles,
        //     "",
        //     "",
        //     "",
        //     "",
        //     {consumption_type: consumption_type}
        // );
        let communication_params = {
            service: service,
            get_function: apiFunctionGetProfiles,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: {consumption_type: consumption_type},
        };
        let c = getCommunicationClass(communication_params);


        var res = await c.load();
        if (res === apiError || res.result === "[]" || res.result === [])
            return [{}];
        else {
            // return new defaultLoadProfiles(res.result);
            let listResults = [];
            for (var i = 0; i < res.result.length; i++) {
                listResults.push(new defaultLoadProfiles(res.result[i]));
            }
            return listResults;
        }
    }
}

export default defaultLoadProfiles;
