import validateVar from "./validation/validateVariable";
import callClassWithString from "./callClassWithString";
import check_option_values from "./check_option_values";
import is_true from "./is_true";

function loop_and_push (array = [], data_object_name = null, get_var = null, options) {
    let array_tmp = [];
    for (let index in array) {
        if (array.hasOwnProperty(index)) {
            let arr_value = array[index];
            if (validateVar(data_object_name)) {
                let data_object_value = callClassWithString(data_object_name, arr_value); // data_object with filled values
                if (is_true(check_option_values(options, data_object_value)) || !validateVar(options)) {
                    array_tmp.push(data_object_value);
                }
            }
            else if (validateVar(get_var)) {
                if (validateVar(arr_value[get_var]))
                array_tmp.push(arr_value[get_var]);
            }
            else {
                array_tmp.push(arr_value);
            }
        }
    }
    return array_tmp;
}

export default loop_and_push;