import callFunctionWithString from "../Functions/callFunctionWithString";

let initialState = null;

function contract(state = initialState, action) {
  if(action.type === "CONTRACT_LIST"){
      return callFunctionWithString("combine_states", [
          action.res,
          state,
          "contracts"
      ]);
  }
  else {
    return state;
  }
}

export default contract;
