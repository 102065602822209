let initialState = null;

function upload_file(state = initialState, action) {
  if (action.type === "UPLOAD_SIGNED_CONTRACT_EHUB") {
    let res = action.res;
    res.contract_upload = true;
    return res;
  } else if (action.type === "RESET_UPLOADED_FILE") {
      return null;
  }else if (action.type === "UPLOAD_SIGNED_CONTRACT_ENERMAKLER") {
    return action.res;
  } else {
    return state;
  }
}


export default upload_file;