let initialState = null;

function external_offer(state = initialState, action) {
  if (action.type === "INITIALIZE_EXTERNAL_OFFER") {
    return action.res;
  } else if (action.type === "UPDATE_EXTRENAL_OFFER") {
    return action.res;
  }else if (action.type === "RESET_EXTRENAL_OFFER") {
    return action.res;
  }else if (action.type === "EXTRENAL_OFFER_SENT") {
    return action.res;
  } else {
    return state;
  }
}

export default external_offer;
