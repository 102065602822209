import React, {useState} from 'react';
import PrimaryButton from "../../../../Displays/Buttons/PrimaryButton";
import {Card, Row, Col, Button, Collapse} from "reactstrap";
import TariffPricedetailsPanel from "./TariffPricedetailsPanel";
import cn from 'classnames';
import validateVar from "../../../../Functions/validation/validateVariable";
import InputHandler from "../../../../Inputs/InputHandler";
import {project_name, project_names, static_download_url, tariff_information_vars} from "../../../../Functions/global";
import is_false from "../../../../Functions/is_false";
import is_true from "../../../../Functions/is_true";

export default function TariffCalculatedContainer(props) {
    const [show_tariff_info, set_show_tariff_info] = useState(false);
    const [offer_save_disabled, set_offer_save_disabled] = useState(false);

    const toggleShowMoreInfo = () => {
        set_show_tariff_info(!show_tariff_info);
    };
    const onSave = () => {
        set_offer_save_disabled(true);
        props.handleDownload(tariff)
    };

    let is_private_price_type = false;
    let is_private_customer = false;
    let tmp_tariff_vars = {};
    let price_type = (validateVar(props.tariff) && validateVar(props.tariff.price_type)) ? props.tariff.price_type : "energy_price";
    if(validateVar(props.tariff.customer_type)){
        is_private_price_type = price_type === 'PRIVATE';
        is_private_customer = props.tariff.customer_type === 'PRIVATE';
        tmp_tariff_vars = tariff_information_vars[props.tariff.customer_type];

        //KZ / 01022022 / price_type on enermakler needs to be changed from PRIVATE/BUSINESS to contract_price/energy_price
        if(is_false(is_private_customer) && (price_type === 'PRIVATE' || price_type === 'contract_price')) tmp_tariff_vars = tmp_tariff_vars['contract_price'];
        if(is_false(is_private_customer) && (price_type === 'BUSINESS' || price_type === 'energy_price')) tmp_tariff_vars = tmp_tariff_vars['energy_price'];
    }

    let tariff_information = {};
    for (let index in tmp_tariff_vars) {
        if (tmp_tariff_vars.hasOwnProperty(index)) {
            let price_value = tmp_tariff_vars[index];
            if (typeof price_value === "object") {
                let object = props[index];
                if (validateVar(object)) {
                    for (let price_index in price_value) {
                        if (price_value.hasOwnProperty(price_index)) {
                            let object_index = price_value[price_index];
                            tariff_information[price_index] = object[object_index];
                        }
                    }
                }
            }else {
                tariff_information[index] = price_value;
            }
        }
    }

    let showElectricityType = false;
    let tariff = props.tariff;
    let spot = false;
    let selected = false;
    let show_pdf_comparison = false;
    if (validateVar(props.tariff_comparison_ids)) {
        if (props.tariff_comparison_ids.includes(tariff.offer_result_id)) selected = true;
        show_pdf_comparison = true; // true = show checkbox / false = hide checkbox
    }
    let is_wattline = (project_name === "WATTLINE_PROJECT");
    let show_other_tax_type = false; //if customer is business but using contract prices like private customer, display net prices anyway with this condition
    if(is_false(is_private_customer) && is_true(is_private_price_type)) show_other_tax_type = true;
    if (is_wattline) show_pdf_comparison = false;
    if (tariff.tariff_type === "SPOT") spot = true;
    if (tariff.energy_source === "green") showElectricityType = true;

    let logo_url = tariff.logo_url;
    if(project_name === project_names.ehub) { //get variable names for test calculation
        logo_url = tariff.logo_url.url;
    }

    return (<>
        <Card className={'calculated-tariff '+cn({'mb-4': props.mb5, 'mb-5': !props.mb5})}>
            <div className='tariff-header custom-border-bottom position-relative pb-0'>
                <Row>
                    <Col />
                    <Col sm={12} md={6} className='text-right pr-5'>
                        <div className='tariff-header-boxes'>
                            {spot && <div className='d-inline-block'><b className='digital-contract'>Spotpreis-Angebot</b></div>}
                            {tariff.sign_type !== 'OFFLINE' && <div className='d-inline-block ml-3'><b className='digital-contract'>{tariff.sign_type_display}</b></div>}
                            {tariff.accepts_electronic_signature === 'YES' && tariff.sign_type === 'OFFLINE' && <div className='d-inline-block ml-3'><b>E-Signatur</b></div>}
                            {showElectricityType &&
                                <div className={'ml-3 d-inline-block green electricity-type'}>
                                    <b>
                                        <i className="fas fa-leaf mr-1"/>
                                        <span className=' d-none d-sm-inline-block'>{tariff.energy_source_display}</span>
                                    </b>
                                </div>}
                        </div>
                    </Col>
                </Row>
                <div className='card-body'>
                    <b>{tariff.name}</b> - {tariff.description}
                </div>
            </div>
            <div className='tariff-body custom-border-bottom mb-0'>
                <div className='card-body'>
                    <Row className='tariff-content'>
                        <Col md={6} lg={5} className='mb-3'>
                            <div className="labelling-file-wrapper">
                                <img
                                    id={"logo_image_display"}
                                    alt="..."
                                    src={logo_url}
                                />
                            </div>
                        </Col>
                        <Col md={6} lg={2} className='mb-3'>
                            <div className='vertical-top-wrapper'>
                                <h4 className='tariff-subtitle'>{tariff_information.price_title} <span className='gray-hint-text'>{spot ? '(Indikation)' : show_other_tax_type ? tariff_information.other_sale_tax_type : tariff_information.sale_tax_type}</span></h4>
                                <span className='tariff-subtitle-data text-nowrap'>{(spot) && tariff_information.spot} {tariff_information.price_display}</span>
                                {spot && <div>
                                    Spotpreis der letzten 12 Monate inkl. Service
                                </div>}
                            </div>
                        </Col>
                        <Col md={6} lg={2} className='mb-3'>
                            <div className='vertical-top-wrapper'>
                                <h4 className='tariff-subtitle'>Grundgebühr <span className='gray-hint-text'>{show_other_tax_type ? tariff_information.other_sale_tax_type : tariff_information.sale_tax_type}</span></h4>
                                <span className='tariff-subtitle-data text-nowrap overflow-auto'>
                                    {tariff_information.socket_display}
                                </span>
                            </div>
                        </Col>
                        <Col md={6} lg={3} className='mb-3'>
                            <div className='vertical-top-wrapper'>
                                <h4 className='tariff-subtitle text-nowrap'>Betrag <span className='gray-hint-text'>{show_other_tax_type ? tariff_information.other_sale_tax_type : tariff_information.sale_tax_type}</span></h4>
                                <span className='tariff-subtitle-data tariff-responsive-right lg-text-left'>
                                    <span className='white-bg'>{is_false(is_private_customer) ? tariff_information.yearly_price_rate_display : tariff_information.yearly_price_display + ' / Jahr'}</span>
                                </span>
                                <div>
                                    <span>{tariff_information.price_hint}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Collapse isOpen={show_tariff_info}>
                <TariffPricedetailsPanel
                    company={props.company}
                    tariff_information={tariff_information}
                    testCalculation={props.testCalculation}
                    // bundle_calculation={props.bundle_calculation}
                    offer={props.offer}
                    // medium_type={props.medium_type}
                    showTariffInfo={props.showTariffInfo}
                    tariff={props.tariff}
                    net_fee={props.net_fee}
                    // duration={props.duration}
                />
            </Collapse>

            <div className='tariff-footer'>
                {show_pdf_comparison &&
                    <div className='mr-2 comparision-checkbox-wrapper'>
                        <InputHandler
                              id={'calculatedTariff' + tariff.offer_result_id}
                              type='checkbox'
                              name='choose_tariff'
                              value={selected}
                              text='Auswählen'
                              on_change={() => props.handleTariffComparisonSelect(tariff.offer_result_id)}
                              no_margin
                        />
                    </div>
                }

                <div className='text-right w-100'>
                    <Button className='eless-btn price-detail-btn mr-0 tariff-responsive-btn'
                            onClick={() => toggleShowMoreInfo()}>Tarif- & Preisdetails <i
                        className={'fas '+cn({"fa-angle-down": !show_tariff_info,"fa-angle-up": show_tariff_info})} /></Button>
                    {!validateVar(props.hideSubmit) && <PrimaryButton
                        classes='ml-2'
                        disabled={props.submitted}
                        onClick={() => props.handleSubmit(tariff.offer_result_id)}
                        value={'Weiter mit dem Angebot'}/>}
                    {(validateVar(props.showDownload) && !is_wattline) && <PrimaryButton classes='ml-2'
                        onClick={() => onSave()}
                        disabled={offer_save_disabled}
                        value={<span><i className="fas fa-download"/> Angebot speichern</span>} />}
                </div>
            </div>
        </Card>
    </>);
}
