import {
    apiFileSystemService, apiFunctionReturnResource,
} from "../Functions/global";
import getCommunicationClass from "../Functions/getCommunication";


class Url {
    static async createURL(params) {
        let communication_params = {
            service: apiFileSystemService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionReturnResource,
            params: null,
        };
        let c = getCommunicationClass(communication_params);
      return await c.download({id: params.file_id}, params.type, params.return_url);
    }
}

export default Url;
