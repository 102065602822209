/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import {Route, Switch} from "react-router-dom";
// core components
import AdminNavbar from "./NavBars/AdminNavbar.jsx";
import Sidebar from "./Sidebar/Sidebar.jsx";

import {
    get_plattform_settings,
    handle_employee_actions,
    handle_company_actions, //wattline //TODO abstrakt machen
    resetNotification,
    redirect,
    initializeCompany, //ehub //TODO abstrakt machen
    initializeCompanyEnermakler, //TODO abstrakt machen
} from "../Index";

import {logoutUser} from "../Functions/consultantActions";
import {connect} from "react-redux";
import validateVar from "../Functions/validation/validateVariable";
import CustomAlert from "../CustomAlert/CustomAlert";
import NotificationAlert from "react-notification-alert";
import {deHashVar} from "../Functions/handleHashedVariable";
import handleCustomData from "../Functions/setPlattformColor";
import AlertBanner from "../CustomAlert/AlertBanner";
import importedComponent from 'react-imported-component';
import {
    apiBearer,
    apiCalculationManagementService,
    apiContentType,
    apiFunctionCreatePortalSession,
    apiURL, emptyValue, get_logo_from_company,
    project_name,
    project_names,
    static_download_url
} from "../Functions/global";
import {all_urls} from "../../globalNavigation";
import getUserRole from "../Functions/getUserRole";
import SideBarLoadingAnimation from "./Sidebar/LoadingAnimations/SideBarLoadingAnimation";
import {handle_overview_tasks} from "../Index/new_index";
import PermissionDenied from "./PermissionDenied";
import setFavIcon from "../Functions/setFavIcon";

const consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidenavOpen: true,
            under_maintainace_warning: <></>,
            modal_open: false,
            is_loaded: false,
            message_feedback: {
                title: "",
                text: "",
                primaryBtnText: "",
                secondaryBtnText: "",
                successIcon: "",
                infoIcon: "",
                primaryBtnClicked: "",
                secondaryBtnClicked: "",
                url: "",
                allowToggle: "",
                is_loaded_info_count: false,
            }
        };

        this.instance = {};
        if(project_name === project_names.enermakler){
            this.instance = window.Chargebee.init({
                site: 'eless-consulting',
                publishableKey: 'live_UrJ4jFQSed7ccdQwxcuqK0bk3n1kRDOSqk'
            });
        }
        this.feedback_modal = React.createRef();
    }

    componentDidMount() {
        this.updateSidebar();
        window.addEventListener('resize', this.updateSidebar);

        if(project_name === project_names.ehub) this.props.initializeCompanyEhub(); //TODO abstrakt machen
        if(project_name === project_names.enermakler) this.props.initializeCompanyEnermakler(); //TODO abstrakt machen
        if(project_name === project_names.wattline) this.props.handle_company_actions("affiliate"); //TODO abstrakt machen

        //get all routes where the current user has permissions on
        let urls_with_permission = [];
        for(let url of all_urls){
            if(url.permissions.includes(getUserRole())) {
                if(url.component === "collapse"){
                    let undercomponents = [];

                    for(let undercomponent of url.undercomponents){
                        if(undercomponent.permissions.includes(getUserRole())) undercomponents.push(undercomponent);
                    }
                    url.undercomponents = undercomponents;
                    urls_with_permission.push(url);
                }else {
                    urls_with_permission.push(url);
                }
            }
        }

        //create routes to be able to access the page
        let routes = urls_with_permission.map((url, index) => {
            if (url.component === "collapse") {
                return url.undercomponents.map((undercomponent, index) => {
                    return this.create_route(undercomponent, index);
                })
            } else {
                return this.create_route(url, index);
            }
        });

        this.setState({routes: routes});

        if(project_name === project_names.enermakler) {
            this.instance.setPortalSession(function () {
                return fetch(apiURL, {
                    method: "POST",
                    headers: {
                        Authorization: apiBearer,
                        "Content-Type": apiContentType
                    },
                    http_errors: false,
                    body: JSON.stringify({
                        service: apiCalculationManagementService,
                        function: apiFunctionCreatePortalSession,
                        param: {
                            customer_id: consultant_id
                        }
                    })
                }).then((res) => res.json()).then(res => {
                    if (res.status === "ok") {
                        return res.result;
                    } else {
                        return null; // error token expired will be shown in modal -> no feedback needed
                    }
                });
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {modal_open} = this.state;
        const {company, analytics, plattform, employee, message_feedback} = this.props;
        const {overview_tasks} = analytics;
        let headline_banner_text = "";
        let headline_banner_icon = "";
        let headline_banner_subject = "";
        let color = "";

        if (validateVar(company) && company !== prevProps.company) {
            if(validateVar(prevProps.company) && (company.logo_file_id !== prevProps.company.logo_file_id)) {
                this.props.getPlattformSettings(); //if logo has changed, load the plattform settings again
            }else if(!validateVar(employee)) {//if not beeing loaded, init load the employee
                this.props.handle_employee_actions("platform_employee"); // get employee
            }

            if(get_logo_from_company){//if this project has no plattform settings load the company logo
                this.setState({
                    custom_logo: static_download_url + company.logo_file_id,
                });
                setFavIcon(company.favicon_id);
            }

            this.setState({
                company: company,
            });
        }

        if(validateVar(employee) && employee !== prevProps.employee){
            if(analytics.overview_tasks.open_contracts === emptyValue) this.props.initializeOverviewTasks({}, 'GET'); // request to see open contracts and tasks
            if (employee.status.toLowerCase() !== "active") { // automatic logout and redirect to login if user is set to deactivated
                // logoutUser(true);
            }
        }

        if (validateVar(plattform) && plattform !== prevProps.plattform) {
            //set the loaded logo
            if(!get_logo_from_company){
                if(validateVar(plattform.logo_id)){
                    this.setState({
                        custom_logo: static_download_url + plattform.logo_id,
                    });
                }

                //set the loaded favicon
                if(validateVar(plattform.favicon_id)){
                    setFavIcon(plattform.favicon_id);
                }
            }

            if(+plattform.maintenance === 1){
                headline_banner_text = "Wir führen gerade Systemwartungen aus. Einzelne Funktionen sind temporär nicht verfügbar";
                headline_banner_icon = "fa fa-wrench";
                headline_banner_subject = "Achtung!";
                color = "error-background-color";
                this.setHeadlineBanner(headline_banner_icon, headline_banner_subject, headline_banner_text, color);
            }
        }

        if(validateVar(message_feedback) && validateVar(message_feedback.url) && message_feedback.automatic_redirect){
            window.location = message_feedback.url //changed from "window.location =" because wattline intern external offers redirects were buggy (the new path name was beeing attached to the current url isnstead of replacing it)
        }else if(validateVar(message_feedback)) {
            if(message_feedback.modal && !modal_open){ //Only display modal when there is no modal open already
                this.toggleCustomAlert();
                this.setState({
                    message_feedback: message_feedback,
                    modal_open: true
                });
            }else{ // user will get a push message
                this.notify({headline: message_feedback.headline, type: message_feedback.type, message: message_feedback.message});
                // if(message_feedback.type === 'danger') window.scrollTo({top: 0, behavior: 'smooth'});
            }
            this.props.onResetNotification();
        }

        if(validateVar(overview_tasks) && overview_tasks.open_contracts !== prevProps.analytics.overview_tasks.open_contracts){
            this.setState({
                overview_tasks: overview_tasks,
                is_loaded_info_count: true,
                is_loaded: true
            });
        }
    }

    toggleCustomAlert = () => {
        this.feedback_modal.current.toggle_modal();
    };

    UNSAFE_componentWillMount() {
        handleCustomData(); //use colors & images from local storage if saved
        this.props.getPlattformSettings(); //load the customized plattform settings on page pload

        // const script_live_chatt = document.createElement("script");
        // script_live_chatt.src = "https://js.hs-scripts.com/5044491.js";
        // script_live_chatt.type = "text/javascript";
        // script_live_chatt.id = "hs-script-loader";
        // script_live_chatt.async = true;
        // script_live_chatt.defer = true;

        //defines surfly to use functions
        // const surfly_index = document.createElement("script");
        // surfly_index.type = "text/javascript";
        // surfly_index.async = true;
        // surfly_index.innerHTML = '(function(s,u,r,f,l,y){s[f]=s[f]||{init:function(){s[f].q=arguments}};\n' +
        //     'l=u.createElement(r);y=u.getElementsByTagName(r)[0];l.async=1;\n' +
        //     'l.src=\'https://surfly.com/surfly.js\';y.parentNode.insertBefore(l,y);})\n' +
        //     '(window,document,\'script\',\'Surfly\');';

        //execute functions
        // const script_surfly_executiv = document.createElement("script");
        // script_surfly_executiv.type = "text/javascript";
        // script_surfly_executiv.async = true;
        // script_surfly_executiv.innerHTML = '  var settings = {\n' +
        //     '    widget_key:\'47a1cba6192b417495452dde97024962\',\n' +
        //     '    private_session: true,\n' +
        //     '    require_password: false,\n' +
        //     '  };\n' +
        //     '  Surfly.init(settings, function(initResult) {\n' +
        //     '    if (initResult.success) {\n' +
        //     '      console.log(\'All good for happy cobrowsing!\');\n' +
        //     '      // it is now safe to use API\n' +
        //     '      if (!Surfly.isInsideSession) {\n' +
        //     '        Surfly.button();\n' +
        //     '      }\n' +
        //     '    } else {\n' +
        //     '      console.log(\'Your browser lacks features required by Surfly\');\n' +
        //     '    }\n' +
        //     '  });';

        // document.body.appendChild(script_live_chatt);
        // document.body.appendChild(surfly_index);
        // document.body.appendChild(script_surfly_executiv);
    }

    notify = (props) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {props.headline}!
          </span>
                    <span data-notify="message">
                {props.message}
          </span>
                </div>),
            type: props.type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    create_route = (url, index) => {
        const Component = importedComponent(() => url.component);
        return <Route
            path={url.path}
            component={Component}
            key={index}
        />
    };

    // htodo hier on click function
    openChargeBeeModal = () => {
        let cbPortal = this.instance.createChargebeePortal();
        cbPortal.open({
            close() {
            }
        });
    };


    /*
      set local valiable to check if user wants to see banner news or not -> will be resetted on logout / login
     */
    deaktivateBanner = () => {
        localStorage.setItem("show_banner", 0);
    };

    setHeadlineBanner = (icon, subject, text, color) => {
        let banner_check = localStorage.getItem("show_banner"); //local storage item to check if user wants to see banner or not
        if (!validateVar(banner_check)) {
            this.setState({
                under_maintainance_warning: <AlertBanner deaktivateBanner={() => this.deaktivateBanner()}
                                                         color="warning"
                                                         className={'mb-0  border-radius-none h2-description ' + color}>
                <span className="mr-3">
                  <i className={icon}/>
                </span>
                    <span className="alert-text">
                      <strong>{subject}</strong> {text}.
                </span>
                </AlertBanner>
            });
        }
    };

    updateSidebar = () => {
        if (window.innerWidth < 1201) {
            if (document.body.classList.contains("g-sidenav-pinned")) {
                document.body.classList.remove("g-sidenav-pinned");
                document.body.classList.add("g-sidenav-hidden");
                this.setState({sidenavOpen: false});
            }
        } else {
            if (document.body.classList.contains("g-sidenav-hidden")) {
                document.body.classList.add("g-sidenav-pinned");
                document.body.classList.remove("g-sidenav-hidden");
                this.setState({sidenavOpen: true});
            }
        }
    };

    // toggles collapse between mini sidenav and normal
    toggleSidenav = e => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        this.setState({
            sidenavOpen: !this.state.sidenavOpen
        });
    };
    getNavbarTheme = () => {
        return this.props.location.pathname.indexOf(
            "admin/alternative-dashboard"
        ) === -1
            ? "dark"
            : "light";
    };

    toggleConsumptionPointMessage = () => {
        this.feedback_modal.current.toggleModal();
    };

    redirect = (url) => {
        this.props.redirect(url);
    };

    secondBtnFunction = (secondaryBtnFunction) => {
        //run button action
        if (!validateVar(secondaryBtnFunction)) this.feedback_modal.current.turnOffModal();
        else {
            window.location = secondaryBtnFunction;
        }
        this.setState({modal_open: false});
    };

    primaryBtnFunction = () => {
        const {url} = this.state.message_feedback;
        if (validateVar(url)) this.redirect(url);
        else this.feedback_modal.current.toggleModal();
        this.setState({modal_open: false});
    };

    render() {
        let message_feedback = this.state.message_feedback;
        const {custom_logo, under_maintainance_warning, is_loaded_info_count, overview_tasks, routes, is_loaded} = this.state;
        const {headline, message, primaryBtnText, secondaryBtnText, successIcon, infoIcon, allowToggle, secondaryBtnFunction} = message_feedback;

        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
                <CustomAlert
                    title={headline}
                    text={message}
                    modal_closed={() => this.setState({modal_open: false})}
                    primaryBtnText={primaryBtnText}
                    secondaryBtnText={secondaryBtnText}
                    primaryBtnClicked={() => this.primaryBtnFunction()}
                    secondaryBtnClicked={() => this.secondBtnFunction(secondaryBtnFunction)}
                    ref={this.feedback_modal}
                    successIcon={successIcon}
                    infoIcon={infoIcon}
                    allowToggle={allowToggle}
                />
                {is_loaded ?
                    <Sidebar
                        {...this.props}
                        routes={routes}
                        toggleSidenav={this.toggleSidenav}
                        sidenavOpen={this.state.sidenavOpen}
                        is_loaded_info_count={is_loaded_info_count}
                        overview_tasks={overview_tasks}
                        logo={{
                            imgSrc: custom_logo,
                            imgAlt: "..."
                        }}
                    /> : <SideBarLoadingAnimation />}
                <div
                    className="main-content"
                    ref="mainContent"
                    onClick={this.closeSidenav}
                >
                    {under_maintainance_warning}
                    <AdminNavbar
                        onRedirect={(url) => this.redirect(url)}
                        {...this.props}
                        theme={this.getNavbarTheme()}
                        toggleSidenav={this.toggleSidenav}
                        sidenavOpen={this.state.sidenavOpen}
                        chargeBeeModalOpen={this.openChargeBeeModal}
                    />
                    <Switch>
                        {this.state.routes}
                        <Route component={PermissionDenied}/> {/* If page not found redirect here */}
                    </Switch>
                </div>
                {this.state.sidenavOpen ? (
                    <div className="backdrop d-xl-none" onClick={this.toggleSidenav}/>
                ) : null}
            </>
        );
    }
}

let mapStateToProps = function (state) {
    return {
        message_feedback: state.message_feedback,
        company: state.company,
        employee: state.employee_data,
        plattform: state.plattform,
        analytics: state.analytics,
    }
};

let mapDispatchToProps = {
    onResetNotification: resetNotification,
    handle_employee_actions: handle_employee_actions,
    handle_company_actions: handle_company_actions,
    getPlattformSettings: get_plattform_settings,
    initializeOverviewTasks: handle_overview_tasks,
    redirect: redirect,
    initializeCompanyEnermakler: initializeCompanyEnermakler, //TODO Abstrakt gestalten
    initializeCompanyEhub: initializeCompany, //TODO Abstrakt gestalten

};

let AdminContainer = connect(mapStateToProps, mapDispatchToProps)(Admin);

export default AdminContainer;

