let initialState = null;

function customer(state = initialState, action) {
  if (action.type === "CREATE_CUSTOMER") {
    return action.res;
  } else if (action.type === "CUSTOMER_LIST") {
      return action.res;
  }else if (action.type === "CUSTOMER_OBJEKT") {
      return action.res;
  } else if (action.type === "SINGLE_CUSTOMER") {
      return action.res;
  }else if (action.type === "UPDATE_CUSTOMER_NO_DATA") {
      return "UPDATED";
  }else if(action.type === "UPDATE_CUSTOMER") {
      return action.res;
  }else if(action.type === "CUSTOMER_RESET") {
      return action.res;
  }
  else if (action.type === "DELETE_CUSTOMER") {
      let newState = [];
      let customer_ids = action.ids;

      for (let customer of state) {
          if (!customer_ids.includes(customer.id))
              newState.push(customer);
      }
      return newState;
  }
  else if (action.type === "ALL_CUSTOMER_DATA") {
      return action.res;
  } else if (action.type === "LP_UPLOADED") {
      //Update usages left on consumption point for frontend to display success message after loading
      let new_state = [...state];
      for(let index in state){
          if(state.hasOwnProperty(index)){
              let customer = new_state[index];
              let updated_consumption_point = customer.consumption_points.find(csp => csp.internal_consumption_point_id+'' === action.res.els_key);
              updated_consumption_point.usages_left = action.res.usages_left;
          }
      }
      return new_state;
  }
  else {
    return state;
  }
}

export default customer;
