import React from 'react';

function SizePerPageSelector (props) {

    if(props.totalSize > 0){
        return (<div className="dataTables_length pl-0" id="datatable-basic_length">
            <label className='d-flex dataTables-perpage-label'>
                Zeige{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm size-per-page-select"
                        onChange={e => {
                            props.onSizePerPageChange(parseInt(e.target.value));
                            props.onPageChange(1);
                        }}
                    >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                }{" "+props.dataName}
            </label>
        </div>)
    }else return null;
}

export default SizePerPageSelector;