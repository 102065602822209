import React from 'react';
import {Col, Row} from "reactstrap";
import TitledContent from "../PageContent/TitledContent";
import DownloadComponent from "../Displays/Buttons/DownloadComponent";
import InputHandler from "./InputHandler";
import Skeleton from "react-loading-skeleton";

class DownloadAndUploadField extends React.Component {
    render() {
        const {uploading, download_url, upload, file_name, file_type, palceholder, name, title, is_loaded, disable_upload, disabled, delete_file} = this.props;
        return(
                <TitledContent title={title}>
                    <Row className='down-up-load-contract flex-row d-flex'>
                        <Col sm={12} md={5} lg={4}>
                            {is_loaded ? <DownloadComponent text='Herunterladen' download_url={download_url}
                                                disabled={!is_loaded || disabled}/> : <Skeleton width={'100%'} height={46}/> }
                        </Col>
                        {disable_upload === false &&
                        <Col>
                            <InputHandler
                                type="file_upload"
                                name={name}
                                placeholder={palceholder}
                                file_type={file_type}
                                value={file_name}
                                upload_file={(file) => upload(file, name)}
                                is_loaded={is_loaded}
                                disabled={!is_loaded}
                                uploading={uploading}
                                delete_file={delete_file}
                            />
                        </Col>
                        }
                    </Row>
                </TitledContent>
            )
    }
}

export default DownloadAndUploadField;