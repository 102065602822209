import "whatwg-fetch";
import {LoginPath} from "../../globalNavigation";
import {project_name, project_names} from "./global";
export function logoutUser(redirect = true) {
    if (project_name === project_names.ehub) {
        localStorage.removeItem("evuUserAccount");
        localStorage.removeItem("employeeSecurityToken");
        localStorage.removeItem("evuSecurityToken");
        localStorage.removeItem("show_banner");
    } else {
        localStorage.removeItem("consultantSecurityToken");
        localStorage.removeItem("consultantUserAccount");
        localStorage.removeItem("consultantEmployeeSecurityToken");
        localStorage.removeItem("show_banner");
    }
    if (redirect === true) redirectToLogin();
}

function redirectToLogin() {
    window.location.href = LoginPath;
}
