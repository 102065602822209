import Communication from "./Components/Communication/communication";
import validateVar from "../Functions/validation/validateVariable";
import {
    apiCalculationManagementService,
    apiFunctionDeleteConsumptionPoint, apiFunctionDeletePfc,
    apiFunctionGetPfcList,
    apiFunctionModifyConsumptionPoint, apiFunctionSavePfc,
    apiFunctionUpdateProviderPfc,
    apiFunctionValidateIban,
    apiPartnerService,
    apiServicePfc
} from "../Functions/global";
import getCommunicationClass from "../Functions/getCommunication";


class Iban {
    constructor(data, communication = undefined) {
        if (typeof communication === "undefined")
            this.c = new Communication(
                apiCalculationManagementService,
                "",
                apiFunctionModifyConsumptionPoint,
                apiFunctionDeleteConsumptionPoint,
                "",
                "",
                {}
            );
        else this.c = communication;
        /*
         *   default value for vars in this class
         *   add new vars here
         * */

        // BASIC
        this.bic = "";
        this.name = "";
        this.status = "";
        this.iban = "";

        if (validateVar(data) && validateVar(data.iban)) {
          this.iban = !validateVar(data.iban) ? "" : data.iban;
          this.status = !validateVar(data.status) ? "" : data.status;
            if (data.hasOwnProperty("bank_information")){
                let bank_info = data.bank_information;
                this.name = !validateVar(bank_info.name) ? "" : bank_info.name;
                this.bic = !validateVar(bank_info.bic) ? "" : bank_info.bic;
            }
        }
    }

  static async validateIban(iban) {
    //   let c = new Communication(
    //   apiPartnerService,
    //   apiFunctionValidateIban,
    //   "",
    //   "",
    //   "",
    //   "",
    //   {iban: iban},
    // );


      let communication_params = {
          service: apiPartnerService,
          get_function: apiFunctionValidateIban,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: {iban: iban},
      };
      let c = getCommunicationClass(communication_params);


    var res = await c.load();
    if (res.status === "ok"){
      let data = res.result;
      if (data.status === "invalid") return data.status;
      else {
          return new Iban(data);
      }
    }
  }
}

export default Iban;
