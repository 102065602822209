import React, {useState} from 'react';
import DnDCard from "./Cards/DragAndDropCard";

const DragAndDrop = (props) => {
    const [cards, setCards] = useState(props.items);

    if (cards !== props.items) {
        setCards(props.items);
    }

    const renderCard = (card, index) => {
        return (<DnDCard
            disabled={props.disabled}
            key={card[props.key]}
            index={index}
            id={card[props.key]}
            text={index + 1 + ") " + card[props.value]}
            moveCard={(drag_index, hover_index) => props.on_change_dnd(drag_index, hover_index, props.name, props.items)}
        />);
    };

    return (<div>
        {cards.map((card, i) => renderCard(card, i))}
    </div>);
};

export default DragAndDrop;
