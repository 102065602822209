import {
    apiCalculationAnalyticsService,
    apiCalculationManagementService,
    apiError, apiFunctionActivateEmployee, apiFunctionCreateCustomer,
    apiFunctionDeleteCustomer, apiFunctionGetCustomer,
    apiFunctionListCustomer,
    apiFunctionModifyCustomer, apiFunctionResetPassword,
    apiFunctionUpdateCustomer, apiPlatformService
} from "../Functions/global";
import validateVar from "../Functions/validation/validateVariable";
import isEmptyObject from "../Functions/isEmptyObject";
import History from "./Components/history";
import ConsumptionPoint from "./consumption_point";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import callClassWithString from "../Functions/callClassWithString";

let customer_vars = {
    id: {
        customer_id: null
    },
    has_history: true,
    created_at: {
        created_at: null,
        created_at_display: {
            function_name: 'getGermanDateFormat',
            params: [true]
        }
    },
    arranger: {
        default_value: '-/-'
    },
    is_lead: {
        lead_status: null,
        lead_status_display: {
            function_name: 'getLeadStatus'
        },
    },
    customer_information: {
        is_class_arr: "customer_information",
        is_top_level: true,
    },
    customer_type: {
        customer_type: null,
        customer_type_display: {
            function_name: "getCustomerType",
            params: ['%%lead_status_display', ['Kunde'], ['Lead']]
        },
    },
    status_color: {
        function_name: "getBadgeColor",
        params: ['%%lead_status_display', ['Kunde'], ['Lead']]
    },
    employee_name: null,
    solvency_information: {
        is_sub_arr: "solvency_information",
        translation_object: {
            solvency_score_display: "Bonität",
            solvency_score_full_display: "Bonität"
        },
        date: {
            solvency_date: null,
            solvency_date_display: {
                function_name: "handleFormatDateForFrontend"
            }
        },
        score: {
            solvency_score: null,
            solvency_score_display: {
                function_name: "getSolvencyDisplay",
                params: ["%%solvency_date_display"]
            },
            solvency: {
                function_name: 'getSolvencyDisplay',
                params: ['%%solvency_date_display']
            }
        },
        solvency_score_full_display: {
            function_name: "solvency_score_full_display",
            params: [
                "%%solvency_score_display",
                "%%solvencyProvider",
                "%%solvency_date_display"
            ]
        }
    },
};

class Customer {
  static default_values = customer_vars;
  constructor(data, status = "open") {

    if (validateVar(data) && !isEmptyObject(data)) {
      this.history = {};
      // this.get_contract(contract_vars, data, data.history, []);
      let class_content = new History(customer_vars, data, data.history, [], status);
      for (let name in class_content) {
        if (class_content.hasOwnProperty(name)) {
          this[name] = class_content[name];
        }
      }
    }
  }

    /*
        Instantiate a new Customer with its ID as Param
        Call Communication Class with matching global defined Vars for API Request
        return new Customer Class
     */
    static async instantiateCustomer(id) {
        let request_params = (typeof id === 'object' && id !== null) ? id : {customer_id: id}; // todo quickfix need to be replaced with object
        request_params = getAgencyID(request_params);

        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetCustomer,
            params: request_params
        };

        this.c = getCommunicationClass(communication_params);
        let res = await this.c.load();
        if (res.status === "ok"){
            return new Customer(res.result);
        } else return "ERR";
    }

  /*
    This call is beeing used in Wattline project for the Index page where we need
    the Customer data and his consumptionpoints to be able to upload loadprofiles
     */
  static async instantiateCustomerWithConsumptionPoints(token) {
    let function_url = "mock/info/" + token;

    // let c = new Communication(
      //     //     'GET',
      //     //     function_url,
      //     //     {}
      //     // );

      let communication_params = {
          method: "GET", // GET / POST / PUT / DELETE  -> method
          function_url:  function_url,
      };
      let c = getCommunicationClass(communication_params);
      let res = await c.request_handler();

    if (validateVar(res) && res !== apiError){
      let customer_data_container = [];
      let expires_at = res.expires_at;
      let result = res.list;
      if (validateVar(result)) {
        for(let index in result){
          let data_obj = {};
          if (result.hasOwnProperty(index)){
            let customer_data = result[index];
            let customer = new Customer(customer_data.customer);
            let consumption_points = customer_data.consumption_points;
            let consumption_pints_array = [];
            for (let consumption_point in consumption_points) {
              if (consumption_points.hasOwnProperty(consumption_point)) {
                consumption_pints_array.push(new ConsumptionPoint(consumption_points[consumption_point]));
              }
            }
            data_obj = {
              customer: customer,
              consumption_points: consumption_pints_array,
            }
          }
          customer_data_container.push(data_obj);
        }
        return [customer_data_container, expires_at];
      }
    }
    else if(res === 'token_invalid')  return res;
    else if(res === 'customer_invalid')  return res;
    else if(res === 'els_key_invalid')  return res;
    else return apiError;
  }

  /*
          Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
          Call Communication Class with matching global defined Vars for API Request
          return Array with all TariffCalculatedContainer Obj
   */
  static async instantiateList(params) {
    params = getAgencyID(params);

    // this.c = new Communication(
    //     apiCalculationManagementService,
    //     apiFunctionListCustomer,
    //     apiFunctionModifyCustomer,
    //     apiFunctionDeleteCustomer,
    //     apiFunctionDeleteCustomer,
    //     "",
    //     params
    // );



      let communication_params = {
          service: apiCalculationManagementService,
          get_function: apiFunctionListCustomer,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);


    var res = await c.load();
    if (res.status === "ok"){
      let listResults = [];
      if (res.result === "[]")return listResults;
      let data = res.result;
      for (let index in data) {
        if (data.hasOwnProperty(index))
          listResults.push(new Customer(data[index]));
      }
      return listResults;
    } else return "ERR"
  }



  /*
        update function - params must be a valid object
     */

  static async updateCustomer(params) {
    params = getAgencyID(params);

    // this.c = new Communication(
    //     apiCalculationManagementService,
    //     apiFunctionModifyCustomer,
    //     apiFunctionModifyCustomer,
    //     apiFunctionDeleteCustomer,
    //     "",
    //     "",
    //     params
    // );

      let communication_params = {
          service: apiCalculationManagementService,
          get_function: "",
          update_function: apiFunctionModifyCustomer,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);

    let res = await c.update(params);
    if (res.status === "ok"){
      return new Customer(res.result);
    } else return apiError;
  }

  static async deleteMultipleCustomers(selected_array){
    let error_count = 0;
    let blocked_count = 0;
    let deleted_ids = [];
    for (let customer of selected_array) { // loop through all values (objects or ids)
      let params = {   // get params for api request
        customer_id: customer.id,
      };
      params = getAgencyID(params);

      // let c = new Communication(  // define communication class for request
      //     apiCalculationManagementService,
      //     apiFunctionListCustomer,
      //     apiFunctionUpdateCustomer,
      //     apiFunctionDeleteCustomer,
      //     apiFunctionCreateCustomer,
      //     "",
      //     {}
      // );

        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "",
            delete_function: apiFunctionDeleteCustomer,
            add_function: "",
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);


      let res = await c.delete_async(params);  // start "async" (not normal delete!) delete request
      if (res.status === "ok"){
        deleted_ids.push(customer.id); // get ids for updating redux states later
      }
      else if (res.result === "Permission denied."){
        blocked_count = blocked_count +1;
      }
      else {
        error_count = error_count +1; // get error count for user feedback
      }
    }
    if (deleted_ids.length === 0 && blocked_count === 0) return "ERR";  // if no data was deletet send ERR back
    return [deleted_ids, error_count, blocked_count]; // send deleted ids, and error quote back
  }

  setNewPassword(params) {
    params = getAgencyID(params);

    // let c = new Communication(
    //     apiCalculationManagementService,
    //     "",
    //     apiFunctionResetPassword,
    //     "",
    //     apiFunctionDeleteCustomer,
    //     "",
    //     {}
    // );

      let communication_params = {
          service: apiCalculationManagementService,
          get_function: "",
          update_function: apiFunctionResetPassword,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);


    return c.update(params).then(res => {
      return res;
    });
  }

  activate(params) {
    params = getAgencyID(params);
      let communication_params = {
          service: apiPlatformService,
          get_function: "",
          update_function: apiFunctionActivateEmployee,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);

    return c.update(params).then(res => {
      params = getAgencyID(params);

      if (res.status === "ok") {
        let employee = new Customer();
        params.agency_id = res.result.agency;
        params.employee_id = res.result.customer_id;
        return this.updateCustomer(params).then(res => {
          return res;
        });
      } else {
      }
    });
  }

  delete(id) { // todo give id
    let params = {
      customer_id: this.id,
    };
    params = getAgencyID(params);


      let communication_params = {
          service: apiCalculationAnalyticsService,
          get_function: "",
          update_function: "",
          delete_function: apiFunctionDeleteCustomer,
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);


    let res = c.delete();


    return res;
  }

  static async deleteCustomer(id) {
    let params = {
      customer_id: id
    };
    params = getAgencyID(params);

    // let c = new Communication(
    //     apiCalculationManagementService,
    //     "",
    //     "",
    //     apiFunctionDeleteCustomer,
    //     apiFunctionDeleteCustomer,
    //     "",
    //     {}
    // );


      let communication_params = {
          service: apiCalculationManagementService,
          get_function: "",
          update_function: "",
          delete_function: apiFunctionDeleteCustomer,
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);

    let res = await c.delete(params);
    return res;
  }

  static async createCustomer(params) {
    params = getAgencyID(params);

    // this.c = new Communication(
    //     apiCalculationManagementService,
    //     "",
    //     "",
    //     "",
    //     apiFunctionCreateCustomer,
    //     "",
    //     {}
    // );

      let communication_params = {
          service: apiCalculationManagementService,
          get_function: "",
          update_function: "",
          delete_function: "",
          add_function: apiFunctionCreateCustomer,
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);


    let res = await c.add(params);
    if (res.status === "ok") return res.result;
    else return apiError;
  }

  static async modifyCustomer(params) {
    params = getAgencyID(params);
    // let c = new Communication(
    //   apiCalculationManagementService,
    //   "",
    //   apiFunctionUpdateCustomer,
    //   "",
    //   "",
    //   "",
    //   {}
    // );
      let communication_params = {
          service: apiCalculationManagementService,
          get_function: "",
          update_function: apiFunctionUpdateCustomer,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);

    var res = await c.update(params);
    if (res.status === "ok") {
      res.result.state_name = params.state_name;
      return res.result;
    } else return apiError;
  }

    static dummy() {
        let output = {};
        output = this.build_dummy(customer_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    }else if (validateVar(vars[index].is_class_arr)) {
                        let class_name = callClassWithString(vars[index].is_class_arr);
                        output[index] = class_name.constructor.dummy();
                    }else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default Customer;
