let initialState = null;

function files(state = initialState, action) {
  if (action.type === "FILE_UPLOADED") {
    return action.res;
  } else if (action.type === "FILE") {
    return action.res;
  } else if (action.type === "RESET_FILES") {
    return action.res;
  } else if (action.type === "GET_FILES_FOR_CUSTOMER") {
    return action.res;
  } else if (action.type === "FILE_UPLOAD") {
    return action.res;
  } else if (action.type === "RESET_FILE") {
    return action.res;
  } else if (action.type === "UPLOAD_FILE_FOR_CUSTOMER"){
    let newState = [];
    newState.push(action.res);
    for (let file of state) {
      newState.push(file);
    }
    return newState;
  } else if (action.type === "DELETE_SAVED_FILES"){
    let newState = [];
    let selected_ids = action.ids;
    for (let file of state) {
      if (selected_ids.includes(file.id)) {
        file.delete(file.id, file.key).then(res => {
        });
      } else {
        newState.push(file);
      }
    }
    return newState;
  }else if (action.type === "DELETE_SAVED_FILES_ERR") {
    return state;
  } else {
    return state;
  }
}

export default files;
