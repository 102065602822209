import validateVar from "../Functions/validation/validateVariable";
import {
    apiCalculationManagementService,
    apiError,
    apiFunctionCreateConsumptionPoint,
    apiFunctionCreateMemo,
    apiFunctionDeleteConsumptionPoint,
    apiFunctionDeleteCustomer,
    apiFunctionGetConsumptionPoint,
    apiFunctionGetConsumptionPointsByCustomer,
    apiFunctionGetConsumptionPointsByOffer,
    apiFunctionListConsumptionPoint,
    apiFunctionModifyConsumptionPoint,
    apiFunctionUpdateMemo,
    apiInformationManagementService,
    electricityVoltageLevel, emptyValue,
    gasVoltageLevel, project_name,
    zipNotFound
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import History from "./Components/history";
import getCommunicationClass from "../Functions/getCommunication";


let consumption_point_vars = {
    translation_object: { //if translation object is on the very top except here, the habitation_status disappears in clearing timeline (weird histroy.js logic)
        consumption_type: "Zählertyp",
        medium_type_display: "Energieträger",
        switching_status_display: "Wechselstatus",
        switching_date: "Wechseldatum",
    },
    habitation_status_display_translation: "Belieferungsstatus", // quickfix todo
    consumption_type_translation: "Zählertyp", // quickfix todo
    id: {
        id: null,
        consumption_point_id: null
    },
    invoice_address: {
        translation_object: {
            invoice_house_number: "Hausnummer (Rechnungsadresse)",
            invoice_city: "Stadt (Rechnungsadresse)",
            invoice_postal_code: "Postleitzahl (Rechnungsadresse)",
            invoice_street: "Strasse (Rechnungsadresse)",
            invoice_company: "Ansprechpartner (Rechnungsadresse)",
            invoice_email: "Rechnungsmail",
            street_time_line: "Strasse",
            house_number_time_line: "Hausnummer",
            city_time_line: "Stadt",
            postal_code_time_line: "Postleitzahl",
        },
        has_history: true,
        is_sub_arr: "invoice_address",
        invoice_address: null,
        email: {
            invoice_email: null
        },
        invoice_recipient: null,
        city: {
            invoice_city: null
        },
        company: {
            invoice_company: null
        },
        country: {
            invoice_country: null
        },
        house_number: {
            invoice_house_number: null
        },
        postal_code: {
            invoice_postal_code: null
        },
        state: {
            invoice_state: null
        },
        street: {
            invoice_street: null
        },
        invoice_full_address: {
            function_name: "getFullAddress",
            params: [
                "%%invoice_postal_code",
                "%%invoice_city",
                "%%invoice_street",
                "%%invoice_house_number"
            ]
        },
        has_invoice: {
            function_name: "hasInvoiceAddress",
            params: ["%%invoice_full_address"]
        }
    },
    customer_id: null,
    blocked_intervals: null,
    lp_download_url: null,
    city: {
        city: null,
        city_time_line: null,  // cant name it housenumber because it would be redundant in timeline
    },
    postal_code: {
        postal_code: null,
        postal_code_time_line: null, // cant name it postal code because it would be redundant in timeline
    },
    street: {
        translation_object: {
            consumption_point_street: 'Strasse'
        },
        street: null,
        consumption_point_street: null,
        street_time_line : null, // cant name it street because it would be redundant in timeline
    },
    house_number: {
        house_number: null,
        house_number_time_line : null,  // cant name it housenumber because it would be redundant in timeline
    },
    street_and_house_number: {
        function_name: 'combine_strings',
        params: ['%%street', ' ', '%%house_number'],
    },
    hausNrZusatz: {
        house_number_addition: null,
    },
    state: {
        state: null,
        state_display: {
            function_name: "getStateTranslation"
        }
    },
    country: null,
    is_synthetic: null,
    load_profile_id: null,
    prediction_id: null,
    lst_key: {
        lsTechnicalKey: null,
    },
    els_key: {
        elsTechnicalKey: null
    },
    usages_left: null,
    belieferungStatus: null,
    branche_wz08: null,
    spE_DS: null,
    mitsprache: null,
    oekoBio: null,
    consumption_type: {
        consumption_type: null,
        last_type_display: null,
        consumption_type_display: {
            function_name: 'getConsumptionType'
        }
    },
    medium_type: {
        medium_type: null,
        medium_type_display: {
            function_name: "getMediumType"
        }
    },
    memos: null,
    reference: {
        function_name: 'getMemoDisplay',
        params: ['%%memos', 'location']
    },
    updated_at: null,
    switching_status: {
        switching_status: null,
        status_color: {
            function_name: "getBadgeColor",
            params: [["successful", "billed"], ["announced", "open"], ["error"], ['pending']]
        },
        switching_status_display: {
            function_name: "getDisplaySwitchingStatus"
        }
    },
    switching_status_boolen: {
        function_name: "checkStatusCp",
        params: ["%%switching_status"]
    },
    switching_date: {
        switching_date_display: {
            function_name: "get_switching_date_display",
            params: ["%%switching_status"]
        },
        switching_date: {
            function_name: "get_switching_date", //getDefaultDateObject
            params: ["%%switching_status"]
        },

    },

    habitation_status: {
        habitation_status: null,
        habitation_status_display: {
            function_name: "getHabitationStatusDisplay"
        }
    },

    prediction: {
        is_class_arr: "prediction",
        is_top_level: true,
    },
    extended_information: {
        is_class_arr: "extended_information",
        is_top_level: true,
    },
    load_profile_synthetic_text: {
        function_name: "get_load_profile_synthetic_info",
        params: ["%%load_profile_synthetic", "text"]
    },
    load_profile_synthetic_bade_color: {
        function_name: "get_load_profile_synthetic_info",
        params: ["%%load_profile_synthetic", "color"]
    },
    full_address: {
        function_name: "getFullAddress",
        params: ["%%postal_code", "%%city", "%%street", "%%house_number", '%%house_number_addition'],
        translation: "Adresse"
    },
    address: {
        function_name: "getFullAddress",
        params: ["%%postal_code", "%%city", "%%street", "%%house_number"],
        translation: "Adresse"
    },

    price_rate_plain: null,
    contract_price_net_plain: null ,
    socket_plain: null
};

class ConsumptionPoint {
    static default_values = consumption_point_vars;

    constructor(data, status = "open") {
        this.history = {};
        if (!validateVar(data.invoice_address)) data.invoice_address = {};
        let class_content = new History(
            consumption_point_vars,
            data,
            data.history,
            [],
            status
        );
        for (let name in class_content) {
            if (class_content.hasOwnProperty(name)) {
                this[name] = class_content[name];
            }
        }
    }

    static async instantiate(params) {
        if(project_name === "EVU_PROJECT") params.data_type = "overview";
        else params.type = "extended";
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetConsumptionPoint,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);
        let res = await c.load();
        if (res.status === "ok") {
            let cp_result = res.result;
            return new ConsumptionPoint(cp_result);
        } else return apiError;
    }

    static async get_mandate(key) {
        let function_url = "mock/info/mandate/" + key;
        let communication_params = {
            method: "GET", // GET / POST / PUT / DELETE  -> method
            function_url:  function_url,
            params: null,
            file: null,
            is_download: true,
            type: "application/pdf",
        };
        let c = getCommunicationClass(communication_params);
        let res = await c.request_handler();
        if (res !== apiError)
            return true; // no api error
        else
            return res; // api error
    }

    static async updateMultipleCpsForContract(params, selected_array) {
        let error_count = 0;
        let updated_cps = [];
        for (let id of selected_array) {
            params.options = {
                with_prediction: 1,
                with_extended_information: 1
            };
            params.consumption_point_id = id;
            let communication_params = {
                service: apiCalculationManagementService,
                get_function: "",
                update_function: apiFunctionModifyConsumptionPoint,
                delete_function: "",
                add_function: "",
                download_function: "",
            };
            let c = getCommunicationClass(communication_params);
            var res = await c.update(params);
            if (res.status === "ok") {
                updated_cps.push(new ConsumptionPoint(res.result));
            } else error_count = error_count + 1;
        }

        if (updated_cps.length === 0) return apiError; // if no data was updated send ERR back
        return [updated_cps, error_count]; // send updated ids, and error quote back
    }




    static async getConsumptionPointForOffer(offer_id, customer_id) {
        let params = {
            offer_id: offer_id,
            customer_id: customer_id,
            type: "extended",
            options:
                {
                    with_load_profile_name: 1,
                    with_prediction: 1
                }
        };

        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetConsumptionPointsByOffer,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);

        var res = await c.load();
        let listResults = [];
        if (res.status === "ok") {
            if (res.result === "[]") return listResults;
            for (var i = 0; i < res.result.length; i++) {
                listResults.push(new ConsumptionPoint(res.result[i]));
            }
            return listResults;
        } else return apiError;
    }

    static async modifyConsumptionPoint(params) {
        params = getAgencyID(params);
         let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: apiFunctionModifyConsumptionPoint,
            delete_function: "",
            add_function: "",
            download_function: "",
        };
        let c = getCommunicationClass(communication_params);

        var res = await c.update(params);
        if (res.status !== "API ERROR" && res.status !== apiError) {
            return new ConsumptionPoint(res.result);
        } else if (
            validateVar(res.result) &&
            res.result[0] === "postal_code does not exist."
        ) {
            return zipNotFound;
        } else return apiError;
    }


    static async getAllConsumptionPointsForUser(id) {
        let params = {
            customer_id: id,
            type: "extended",
            options:
                {
                    with_load_profile_name: 1,
                    with_prediction: 1,
                    with_blocked: 1,
                    with_memos: 1
                }
        };

        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetConsumptionPointsByCustomer,
            update_function: apiFunctionModifyConsumptionPoint,
            delete_function: apiFunctionDeleteCustomer,
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);
        var res = await c.load();
        let listResults = [];
        if (res.status === "ok") {
            if (res.result === "[]" || res.result === []) return listResults;
            for (var i = 0; i < res.result.length; i++) {
                listResults.push(new ConsumptionPoint(res.result[i]));
            }
            return listResults;
        } else return apiError;
    }

    static async instantiateForContract(params) {
        if (typeof params !== 'object') params = {
            contract_id: params
        };
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionListConsumptionPoint,
            update_function: apiFunctionModifyConsumptionPoint,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);
        let res = await c.load();
        if (res.status === "ok") {
            let listResults = [];
            if (res.result === "[]") return listResults;

            for (var i = 0; i < res.result.length; i++) {
                listResults.push(new ConsumptionPoint(res.result[i]));
            }

            return listResults;
        } else {
            return apiError;
        }
    }

    /*
              Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
              Call Communication Class with matching global defined Vars for API Request
              return Array with all TariffCalculatedContainer Obj
       */
    static async instantiateList() {

        let params = getAgencyID({});
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionListConsumptionPoint,
            update_function: apiFunctionModifyConsumptionPoint,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);

        var res = await c.load();
        if (res === "error" || res.result === "[]" || res.result === [])
            return [{}];
        else {
            let listResults = [];
            for (var i = 0; i < res.result.length; i++) {
                listResults.push(new ConsumptionPoint(res.result[i]));
            }
            return listResults;
        }
    }

    /*
          update function - params must be a valid object
       */
    static async updateCpForContract(params) {
        params.options = {
            with_prediction: 1,
            with_extended_information: 1
        };
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionListConsumptionPoint,
            update_function: apiFunctionModifyConsumptionPoint,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);
        var res = await c.update(params);
        if (res.status === "ok") return new ConsumptionPoint(res.result);
        else return apiError;
    }

    static async deleteMultipleConsumptionPoints(selected_array) {
        let error_count = 0;
        let deleted_ids = [];
        for (let cspoint of selected_array) { // loop through all values (objects or ids)
            let params = {   // get params for api request
                consumption_point_id: cspoint.id,
                customer_id: cspoint.customer_id,
            };
            params = getAgencyID(params);
            let communication_params = {
                service: apiCalculationManagementService,
                get_function: apiFunctionGetConsumptionPointsByCustomer,
                update_function: apiFunctionModifyConsumptionPoint,
                delete_function: apiFunctionDeleteConsumptionPoint,
                add_function: apiFunctionCreateConsumptionPoint,
                download_function: "",
            };
            let c = getCommunicationClass(communication_params);
            var res = await c.delete_async(params);  // start "async" (not normal delete!) delete request
            if (res.status === "ok") {
                deleted_ids.push(cspoint.id); // get ids for updating redux states later
            } else {
                error_count = error_count + 1; // get error count for user feedback
            }
        }
        if(res.hasOwnProperty('result') && res.result === 'Cannot delete consumption point due to contract connected with it.') {
            return 'Under a contract';
        }
        if (deleted_ids.length === 0) return "ERR";  // if no data was deletet send ERR back
        return [deleted_ids, error_count]; // send deleted ids, and error quote back
    }


    static async handle_consumption_point(params = {}, method, call_function_name, file){
        params = getAgencyID(params);
        let communication_params = {
            method: method,
            params: params,
            function_url: call_function_name,
        };
        console.log("GET", method);

        if(method === 'GET'){
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }if(method === 'PUT'){
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }
        let c = getCommunicationClass(communication_params, file);
        let res = await c.request_handler();
        if (res !== apiError) {
            return new ConsumptionPoint(res);
        } else return apiError;
    }


    static async updateConsumptionPoint(params, with_memo = false) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetConsumptionPointsByCustomer,
            update_function: apiFunctionModifyConsumptionPoint,
            delete_function: apiFunctionDeleteConsumptionPoint,
            add_function: apiFunctionCreateConsumptionPoint,
            download_function: "",
        };
        let c = getCommunicationClass(communication_params);
        let res = await c.update(params);
        if (res.status === "ok") {
            // create memo to cp
            if (with_memo){
                let memo_params = {
                    memo: params.reference,
                    category: "consumption_point",
                    reference_id: res.result.id,           // consumption_point_id
                    subcategory: "location"
                };
                communication_params = {
                    service: apiInformationManagementService,
                    get_function: "",
                    update_function: apiFunctionUpdateMemo,
                    delete_function: "",
                    add_function: "",
                    download_function: "",
                };
                c = getCommunicationClass(communication_params);
                let memo_res = await c.update(memo_params);
                if (memo_res.status !== "ok") {
                    return apiError;
                } else return res;
            } else return res;
        }
        else return apiError;
    }


    static async updateConsumptionPointsInOffer(data, offer_id, customer_id) {
        let has_error = false;
        let listResults = [];
        for (let index in data) {
            if (data.hasOwnProperty(index)) {
                let cp = data[index];
                if (!cp.has_pre_provider) {
                    cp.pre_provider = "";
                    cp.pre_customer_number = "";
                }
                let params = {
                    market_identifier: cp.market_identifier === 'Nachzutragen' ? '' : cp.market_identifier,
                    meter_identifier: cp.meter_identifier === 'Nicht hinterlegt' ? '' : cp.meter_identifier,
                    offer_id: offer_id,
                    customer_id: customer_id,
                    consumption_point_id: cp.id,
                    habitation_status: cp.habitation_status,
                    supplier_name: cp.pre_provider,
                    internal_customer_id: cp.pre_customer_number,
                };
                params = getAgencyID(params);

                if(validateVar(cp.invoice_city)){//only submit invoice_adress if given. Empty values cause an error from the API
                    params.invoice_address = {
                        //todo invoice_recipient: invoice_address.invoice_recipient,
                        city: cp.invoice_city,
                        company: cp.invoice_company,
                        country: cp.invoice_country,
                        house_number: cp.invoice_house_number,
                        postal_code: cp.invoice_postal_code,
                        street: cp.invoice_street,
                        email: cp.invoice_email,
                    }
                }

                let res = await this.updateConsumptionPoint(params);
                if (res.status !== "ok") has_error = true;
                else {
                    listResults.push(new ConsumptionPoint(res.result));
                }
            }
        }
        if (has_error === false) return listResults;
        else return "ERR";
    }


    static async createConsumptionPoint(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: apiFunctionCreateConsumptionPoint,
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);
        let cp_res = await c.add(params);
        if (cp_res.status === "ok") {
            // create memo to cp
            if (!validateVar(params.reference))  params.reference = "- / -";

            let memo_params = {
                memo: params.reference,
                category: "consumption_point",
                reference_id: cp_res.result.id,           // consumption_point_id
                subcategory: "location"
            };


            let communication_params = {
                service: apiInformationManagementService,
                get_function: "",
                update_function: "",
                delete_function: "",
                add_function: apiFunctionCreateMemo,
                download_function: "",
                params: memo_params,
            };
            let c = getCommunicationClass(communication_params);
            let memo_res = await c.add(memo_params);
            if (memo_res.status !== "ok") {
                return apiError;
            } else  return new ConsumptionPoint(cp_res.result);
        }
        else return apiError;
    }


    download(params) {
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionListConsumptionPoint,
            update_function: apiFunctionModifyConsumptionPoint,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);
        let res = c.download({id: this.id, data_type: "download"});
        return res;
    }

    getSpecificationDisplay(specification_index, medium_type) {
        if (medium_type === "ELECTRICITY") return electricityVoltageLevel[specification_index].value;
        else return gasVoltageLevel[specification_index].value;
    }

    getHabitationStatusDisplay(habitation_status) {
        /*
        relocation                   => neueinzug
        supplier_change        => versorgerwechsel
        already_cancelled     => vorab gekündigt (wird glaub nirgends verwendet)
        default_provider       => Grundversorgung
         */
        if (habitation_status === "supplier_change") return "Lieferantenwechsel";
        else if (habitation_status === "relocation") return "Neueinzug";
        else if (habitation_status === "already_cancelled") return "Vorab Gekündigt";
        else if (habitation_status === "default_supplier") return "Grundversorgung";
        else return "-/-";
    }

    static dummy() {

        let output = {};
        output = this.build_dummy(consumption_point_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {
                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr) || index === "translation_object") { // <-- always look for the child values
                        output[index] = this.build_dummy(vars[index]);
                    } else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }

    delete() {
        let res = this.c.delete({
            pfc_id: this.id,
            supplier_id: this.foreign_user_id
        });
        return res;
    }

    addPfc(params, file) {
        let res = this.c.add(params, file);
        return res;
    }

}

export default ConsumptionPoint;
