function VisibleEntriesCount (props) {
    const {sizePerPage, totalSize, dataName, page} = props;
    let current = sizePerPage;

    //current viewed entries if there are less than 10
    if(sizePerPage > totalSize) current = totalSize;

    //current viewed entries if on the last page
    let lastPage = Math.ceil(totalSize/sizePerPage);
    if(page === lastPage) current = totalSize - ((lastPage - 1) * sizePerPage);

    if(totalSize > 0) return 'Zeigt '+current+' von '+totalSize+' '+dataName;
    else return null
}

export default VisibleEntriesCount;