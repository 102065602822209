import handleFormatNumber from "./handleFormatNumber";
import is_true from "./is_true";

function getDisplayConsumption(consumption, unlimited_when_empty = false) {
    //cut out decimal value for handleFormatNumber if exists and insert it again to the result after comma
    let consumption_string  = consumption+'';
    let consumption_before_comma = consumption_string;
    let consumption_after_comma = 0;
    //when there is no consumption beeing passed from object with callFunctionsWithString, the true property is beeing set inside consumption instead of unlimited_when_empty
    if((unlimited_when_empty || is_true(consumption)) && Number.isNaN(parseInt(consumption))) return 'unbegrenzt';
    if(consumption_before_comma.includes('.')) {
        let consumption_array = [];
        consumption_array = consumption_string.split('.');
        consumption_before_comma = consumption_array[0];
        consumption_after_comma = consumption_array[1];
        return handleFormatNumber(consumption_before_comma) +','+ consumption_after_comma + ' kWh';

    }else return handleFormatNumber(consumption) + ' kWh';
}

export default getDisplayConsumption;