import React from 'react';
import {
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    ListGroup,

    UncontrolledDropdown, ListGroupItem,
} from 'reactstrap';
import NotificationsCount from "../Notifications/NotificationsCount";
import {Messages, notentifications, SingleContract} from "../../globalNavigation";
import {notification_viewed, redirect} from "../Index/index";
import connect from "react-redux/es/connect/connect";
import validateVar from "../Functions/validation/validateVariable";
import {project_name, static_download_url} from "../Functions/global";
import MessagesAndNotificationIconLoadingAnimation from "./LoadingAnimations/MessagesAndNotificationIconLoadingAnimation";
import SubmitLoadingAnimationModal from "../LoadingAnimations/SubmitLoadingAnimationModal";

const show_max_message_count = 5;


class MessagesAndNotifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_loaded: false,
            message_content: null,
            site_name: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let {company, notification} = this.props;
        if (validateVar(company) && company !== prevProps.company) {
            let message_content_obj = (validateVar(company.messages)) ? this.getMessagesList(company.messages) : {};
            let notifications_content_obj = (validateVar(company.notifications)) ? this.getNotentificationList(company.notifications) : {};
            this.setState({
                company_data: company,
                message_content: message_content_obj.message_content,
                notifications_content: notifications_content_obj.notifications_content,
                message_open_count: message_content_obj.message_open_count,
                notifications_open_count: notifications_content_obj.notifications_open_count,
                is_loaded: true
            });
        }

        if (validateVar(notification) && notification !== prevProps.notification) {

        }
    }

    get_logo_col = (file_id = null) => {
        if (validateVar(file_id)) {
            let file_url = static_download_url + file_id;
            return <Col  sm={12} md={2}>
                <img
                    alt="..."
                    className='navbar-brand-img'
                    src={file_url}
                />
            </Col>
        } else return null;
    };

    getNotentificationList = (notifications) => {
        let count = 0;
        let test_arr = [];
        test_arr.push(notifications.map((notification) => {
            if (notification.status !== "viewed"){ //show this much messages before cutting
                count = count +1;
                if (count < show_max_message_count) {
                    return  <ListGroupItem
                        key={notification.id}
                        className="list-group-item-action cursor-pointer"
                        onClick={() => this.on_click_item(notification, "notification")}
                    >
                        <Row className="align-items-center">
                            {/*{project_name === "EVU_PROJECT" &&*/}
                            {this.get_logo_col(notification.sender_logo_id)}
                            {/*}*/}
                            <Col  sm={12} md={6} className="ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h5 className="mb-0 text-sm pt-0">{notification.type_display}</h5>
                                    </div>
                                </div>
                                <span className="text-sm mb-0">
                                {notification.message_short}
                            </span>
                            </Col>
                            <Col sm={12} md={4}>
                                {notification.created_at_display}
                            </Col>
                        </Row>
                    </ListGroupItem>
                }
            }
        }));
        return {
            notifications_content: test_arr,
            notifications_open_count: count
        };
    };

    getMessagesList = (messages) => {
        let count = 0;
        let message_obj = {};

        for (let message of messages){
            if (validateVar(message.linked_object) && message.status !== "viewed") {
                count = count +1;
                if (count < show_max_message_count) {
                    if (!(message.linked_object + "" in message_obj)) {
                        message_obj[message.linked_object + ""] = {
                            count: 1,
                            protocol_id: message.protocol_id,
                            linked_object: message.linked_object,
                            message_id: message.message_id,
                            id: message.id,
                            status: message.status,
                            sender_logo_id: message.sender_logo_id,
                            subject: message.subject,
                            message: message.message_short,
                            created_at: message.created_at,
                            url: message.url,
                            url_with_mesage_id: message.url_with_mesage_id,
                        };
                    } else {
                        let obj_count = message_obj[message.linked_object + ""].count;
                        message_obj[message.linked_object + ""].count = obj_count + 1;
                    }
                }
            }
        }
        let message_open_count = count;
        let arr = [];
        count = 0;
        for (var index in message_obj) {
            if (message_obj.hasOwnProperty(index)) {
                let message = message_obj[index];
                if (count < show_max_message_count && message.status !== "viewed") { //show this much messages before cutting
                    count = count + 1;
                    arr.push(<ListGroupItem
                        key={message.id}
                        className="list-group-item-action cursor-pointer"
                        onClick={() => this.on_click_item(message, "message")}
                    >
                        <Row className="align-items-center">
                            {/*{project_name === "EVU_PROJECT" &&*/}
                            {this.get_logo_col(message.sender_logo_id)}
                            {/*}*/}
                            <Col sm={12} md={6} className="ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h5 className="mb-0 text-sm pt-0">{message.subject} ({message.count})</h5>
                                    </div>
                                </div>
                                <span className="text-sm mb-0">
                                {message.message}
                            </span>
                            </Col>
                            <Col sm={12} md={4}>
                                {message.created_at_display}
                            </Col>
                        </Row>
                    </ListGroupItem>)
                }
            }
        }
        return {
            message_content: arr,
            message_open_count: message_open_count
        };
    };

    on_click_item = (object, type) => {
        let params = {
            type: type,
            linked_object: [object.message_id],
            status: "viewed"
        };
        let url = null;
        if (validateVar(object.url) || validateVar(object.url)) {
            if (type === "message" && validateVar(object.url_with_mesage_id)) {
                url = object.url_with_mesage_id;
            }
            else if (validateVar(object.url)) url = object.url
        }
        this.props.notification_viewed(params, url);
    };


    render() {
        let {redirect} = this.props;
        let {company_data, is_loaded, message_content, display_modal, message_open_count, notifications_content, notifications_open_count} = this.state;

        return (
            <>
                {is_loaded ?
                <UncontrolledDropdown nav>
                    <DropdownToggle className="nav-link white-color position-relative notifications-toggler" color="" tag="a">
                        <i className="fas fa-envelope" /><NotificationsCount count={message_open_count} />
                    </DropdownToggle>
                    <DropdownMenu
                        className="dropdown-menu-xl py-0 overflow-hidden"
                        right
                    >
                        <div className="px-3 py-3">
                            <h6 className="text-sm text-muted m-0">
                                Sie haben <strong className="eless-text-orange">{message_open_count}</strong>{" "} offene Nachrichten.
                            </h6>
                        </div>

                        <ListGroup flush>
                            {message_content}
                        </ListGroup>

                        <DropdownItem
                            className="text-center eless-text-orange font-weight-bold py-3"
                            onClick={() => redirect(Messages)}
                        >
                            Alle ansehen
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown> : <MessagesAndNotificationIconLoadingAnimation />}

                {is_loaded ?
                    <UncontrolledDropdown nav>
                    <DropdownToggle className="nav-link white-color position-relative notifications-toggler" color="" tag="a">
                        <i className="ni ni-bell-55" /><NotificationsCount count={notifications_open_count} />
                    </DropdownToggle>
                    <DropdownMenu
                        className="dropdown-menu-xl py-0 overflow-hidden"
                        right
                    >
                        <div className="px-3 py-3">
                            <h6 className="text-sm text-muted m-0">
                                Sie haben <strong className="eless-text-orange">{notifications_open_count}</strong>{" "} Benachrichtigungen.
                            </h6>
                        </div>

                        <ListGroup flush>
                            {notifications_content}
                            {/*{this.getNotentificationList(company_data.notifications)}*/}
                        </ListGroup>

                        <DropdownItem
                            className="text-center eless-text-orange font-weight-bold py-3"
                            onClick={() => redirect(notentifications)}
                        >
                            Alle ansehen
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown> : <MessagesAndNotificationIconLoadingAnimation />}
            </>
        );
    };
}



let mapStateToProps = function(state)   {
    return {
        company: state.company,
        notification: state.notification,
    }
};

// todo branchen profile abfragen damit man auf jeder seite zugriff hat

let mapDispatchToProps = {
    redirect: redirect,
    notification_viewed: notification_viewed
};

let MessagesAndNotificationsContainer = connect(mapStateToProps, mapDispatchToProps)(MessagesAndNotifications);

export default MessagesAndNotificationsContainer;