import "whatwg-fetch";
import {
    apiBearer, apiContentType,
    apiFunctionModifyTree,
    apiServicePricing,
    apiURL,
    apiUser,
    apiUserAuthToken, socketAttribute, socketAttributeSelect
} from "./global";
import * as operatorTypes from "../AttributeFields/Enums/Operator.enum";


export default async function updateTree(
  currentPriceStrategy,
  supplierId,
  tariffId,
  treeId
) {
  let data = {
    user: apiUser,
    auth_token: apiUserAuthToken,
    service: apiServicePricing,
    function: apiFunctionModifyTree
  };

  const param = getParam(currentPriceStrategy, supplierId, tariffId, treeId);

  data = { ...data, ...param };
  return await fetch(apiURL, {
    method: "POST",
    headers: {
      Authorization: apiBearer,
      "Content-Type": apiContentType
    },
    http_errors: false,
    body: JSON.stringify(data)
  })
    .then(response => {
      console.log("update tree response", response)
      return response;
    })
    .catch(rejected => {
      console.log("Request failed", rejected);
    });
}

const getParam = (currentPriceStrategy, supplierId, tariffId, treeId) => {;
  console.log("getParams currentPriceStratgy", currentPriceStrategy)
  let param = {
    param: {
      type: "list",
      is_slp: "1",
      is_rlm: "1",
      is_electricity: "1",
      is_gas: "0",
      groups: [],
      tariff_id: tariffId,
      supplier_id: supplierId.toString(),
      tree_id: treeId
    }
  };

  const tree_attributes = [];
  currentPriceStrategy.tree_attributes.map((attribute, lsIndex) => {
    const branches = [];
    var is_socket = false;
    var is_socket_select = false;
    if (socketAttribute.includes(attribute.attribute_id)) is_socket = true;
    if (socketAttributeSelect.includes(attribute.attribute_id))
      is_socket_select = true;
    attribute.branches.map((branch, bIndex) => {
      let constraints = {};
      if (branch.operator === operatorTypes.LOGIC_OP_AND) {
        let value1 = branch.value1;
        let value2 = branch.value2;
        value1 = value1.replace(/\./g, "");
        value2 = value2.replace(/\./g, "");
        value1 = value1.replace(",", ".");
        value2 = value2.replace(",", ".");
        constraints = {
          logic_op: operatorTypes.LOGIC_OP_AND,
          cond_list: [
            {
              comp_op: operatorTypes.COMP_OP_GE,
              expr_a: "VARIABLE",
              expr_b: { VALUE: value1 }
            },
            {
              comp_op: operatorTypes.COMP_OP_LE,
              expr_a: "VARIABLE",
              expr_b: { VALUE: value2 }
            }
          ]
        };
      } else {
        if (Array.isArray(branch.value)) {
          let cond_list = [];
          for (let index in branch.value) {
            if (branch.value.hasOwnProperty(index)) {
              let arrayVal = branch.value[index];
              cond_list.push({
                comp_op: branch.operator,
                expr_a: "VARIABLE",
                expr_b: { VALUE: arrayVal }
              });
            }
          }
          constraints = {
            logic_op: operatorTypes.LOGIC_OP_OR,
            cond_list: cond_list
          };
        } else {
          let value = branch.value;
          value = value.replace(/\./g, "");
          value = value.replace(",", ".");
          constraints = {
            comp_op: branch.operator,
            expr_a: "VARIABLE",
            expr_b: { VALUE: value }
          };
        }
      }
      if (is_socket) {
        let base_price = branch.surcharge.replace(",", ".");
        if (
          is_socket_select &&
          branch.value[0] == 2 &&
          base_price.split("c").length - 1 == 0
        )
          base_price = base_price + " * c";

        branches.push({
          kwh_rate: "0",
          base_price: base_price,
          constraints: constraints
        });
      } else {
        branches.push({
          kwh_rate: branch.surcharge.replace(",", "."),
          base_price: "0",
          constraints: constraints
        });
      }
    });

    tree_attributes.push({
      attribute_id: attribute.attribute_id,
      branches: branches
    });
  });
  let attributes = tree_attributes;
  let groups = [];
  groups.push({attributes: attributes});
  param.param.groups = groups;
  return param;
};
