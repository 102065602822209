import regexCheck from "./regexCheck";
import {numeric_regex} from "../global";
import validateVar from "./validateVariable";
import remove_last_char from "../remove_last_char";

function validateIntOnChange(int) {
    if(validateVar(int)) {
        int = int + "";
        if(int.charAt(0) === '0' && int.length > 1) return "0";
        let lastChar = int.substr(-1); //get last character to check user input
        if (!regexCheck(lastChar, numeric_regex)) { //check if value is invalid and
            int = remove_last_char(int); //remove invalid value
        }
    }
    return int;
  }



export default validateIntOnChange;
