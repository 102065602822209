import validateVar from "./validation/validateVariable";

function getValidPercentage(value, old_value) {
    let return_old_val = false;
    var regex_before_comma_test = /(\b(0*(?:[0-9][0-9]?|100))\b)$/;
    var regex_after_comma = /(^\d{1,2}?)$/;
    value = value + '';
    if (validateVar(value)) {
        value = value.replace(/\./g, ",");
        if (value.charAt(0) === "0" && validateVar(value.charAt(1)) && value.charAt(1) !== ",") {
            value = value.substring(1);
        }
        if (value.includes(",")) {
            let comma_count = value.match(/,/g);
            if (comma_count.length === 1) {
                let value_tmp = value.split(",");
                if(value_tmp[0] === '100') return_old_val = true;
                if (!validateVar(value_tmp[0])) return_old_val = true;
                if (validateVar(value_tmp[1])) {
                    if (!regex_after_comma.test(value_tmp[0]) || (!regex_after_comma.test(value_tmp[1]))) return_old_val = true;
                }
            } else return_old_val = true;
        } else {
            if (!regex_before_comma_test.test(value)) return_old_val = true;
            if(!return_old_val && value === '00') return_old_val = true;
            if(value.charAt(0) === '0' && value.length === 3) return_old_val = true
        }
    }
    return (return_old_val === true) ? old_value : value;
}

export default getValidPercentage;