/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {redirect} from '../../actions/index';
// reactstrap components
import {
    Card,
    Container,
    Row,
} from "reactstrap";
// core components
import SimpleHeader from "../../module_components/Displays/Headers/SimpleHeader";
// custom components
import validateVar from "../../module_components/Functions/validation/validateVariable";
import {initClearingList} from "../../actions/new_index";
import {ConsumptionPoint, SingleContract} from "../../globalNavigation";
import Table from "../../module_components/Displays/Tables/Table";

const offer_columns = [
    {
        dataField: "id",
        text: 'Klärfall-ID',
        sort: true,
    },
    {
        dataField: "customer_company",
        text: "Auftrag",
        sort: true,
        classes: 'linked-col eless-font-bold'
    },
    {
        dataField: "created_at_display",
        text: "Erstellt am",
        sort: true,
        classes: 'linked-col'
    },
    // {
    //     dataField: "created_from",
    //     text: "Erstellt von",
    //     sort: true,
    //     classes: 'linked-col'
    // },
];

const limit = 20;
const offset = 0;

/*
    START CLASS
 */
class ClearingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_load: false,
            table_columns: offer_columns,
        };
    }

    componentDidMount() {
        this.props.initList({
            limit: limit,
            offset: offset,
            is_list: true
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let clearing_list = this.props.clearing_list;
        if (clearing_list !== prevProps.clearing_list && validateVar(clearing_list)) {
            this.setState({
                clearing_list: clearing_list.clearing,
                is_load: true
            });
            if (validateVar(clearing_list.next_offset)) {
                this.get_units(clearing_list.next_offset);
            }
        }
    }

    get_units = (offset) => {
        let params =  { // set first pair of offset and limit
            limit: limit,
            offset: offset
        };
        this.props.initList(params);
    };

    get_redirect_link = (reference_type) => {
        if (reference_type === "contract") {
            return SingleContract;
        } else if (reference_type === "consumption_point") {
            return ConsumptionPoint;
        } else return null;

    };

    render() {
        let  {clearing_list, is_load, table_columns} = this.state;

        return (
            <>
                <SimpleHeader name="Klärfälle" headerText={''} />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <Table data={clearing_list}
                                    columns={table_columns}
                                    linked_col_click={(row) => this.props.redirect(this.get_redirect_link(row.reference_type)+ "/" + row.reference_id + "/" + row.protocol_id)}
                                    is_loaded={is_load}
                                    empty_table_message={'Es gibt noch keine Klärfälle'}
                                    paginator_strings={['Klärfälle', 'Klärfällen']}
                                    update_columns={(table_columns) => this.setState({table_columns: table_columns})}
                                />
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}





let mapStateToProps = function(state)   {
    return {
        clearing_list: state.clearing_list
    }
};

let mapDispatchToProps = {
    initList: initClearingList,
    redirect: redirect,
};

let ClearingTableContainer = connect(mapStateToProps, mapDispatchToProps)(ClearingTable);

export default ClearingTableContainer;
