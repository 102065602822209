import React, { Component } from 'react';
import {Tooltip, Collapse} from "reactstrap";
import Condition from "./Condition";
import {tree_operators, one_time_use_operator} from "../../../Functions/global";
import is_false from "../../../Functions/is_false";
import {check_valid_input_current_branch} from "../../../Functions/validation/validate_tree_branch";
import compare_and_validate_tree_branches from "../../../Functions/validation/validate_tree_branch";
import validateVar from "../../../Functions/validation/validateVariable";
import is_true from "../../../Functions/is_true";

class AttributeField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_expanded: (is_true(props.tree_attribute.is_expanded)),
            is_open_tooltip: false,
            is_submit: false,
        };
    }

    componentDidUpdate(prevProps) {
        let {attibute_error_array, tree_attribute} = this.props;
        if (validateVar(attibute_error_array) && attibute_error_array !== prevProps.attibute_error_array) {
            if (attibute_error_array.includes(tree_attribute.attribute_id)) {
                this.setState({
                    is_expanded: true,
                    is_submit: true
                })
            } else {
                this.setState({
                    is_submit: false
                })
            }
        }
    }

    expandPanel = () => {
        this.setState((prevState) => {
            return {is_expanded: !prevState.is_expanded}
        });
    };

    toggleTooltip = () => {
        let {is_open_tooltip} = this.state;
        this.setState({
            is_open_tooltip: !is_open_tooltip
        })
    };

    getValidTreeOperators = (used_operators, type = null) => {
       let operator_options = [];
            for (let operator of tree_operators) {
                if ((used_operators.includes(operator.key) && one_time_use_operator.includes(operator.key) && type !== "multiselect") || (type === "multiselect" && operator.key !== "EQ")) { // check if key is already in used array and can only be used once
                    operator.class = "d-none"; // hide class so it can not be selected anymore but dont remove them from option so the selected value can still be seen
                    operator_options.push({...operator});
                } else {
                    operator.class = null;
                    operator_options.push({...operator});
                }
            }
            return operator_options;
    };

    edit_branch_operator = (operator, index)  => {
        let {unique_id, is_global} = this.props;
        let data = {
            global: is_global,
            operator: operator,
            condition_index: index,
            unique_id: unique_id
        };
        this.props.edit_branch_operator(data);
    };

    on_blur_branch = (branch, index) => { // set current branch in conditions so the validation will be triggered in render
        let {unique_id, is_global} = this.props;
        let data = {
            global: is_global,
            condition: branch,
            condition_index: index,
            unique_id: unique_id
        };
        this.props.edit_branch(data);
    };


    get_valid_options = (used_options, options) => {
        let un_used_options = [];
        for (let option of options) {
            if (!used_options.includes(option.value)) un_used_options.push(option);
        }
       return un_used_options;
    };

     add_branch = () => {
        let {is_global} = this.props;
        let data = {
            global: is_global
        };
        this.props.add_branch(data);
     };

    set_text_form = (index, text_form) => {
        let {unique_id, is_global} = this.props;
        let data = {
            unique_id: unique_id,
            index: index,
            text_form: text_form,
            global: is_global
        };
        this.props.set_text_form(data);
    };

     delete_branch = (index) => {
        let {is_global} = this.props;
        let data = {
            condition_index: index,
            global: is_global
        };
        this.props.delete_branch(data);
     };

    get_conditions = () => {
        let {tree_attribute, group_id, is_global, is_global_edit_able, is_global_page} = this.props;
        let {is_submit} = this.state;
        let conditions = tree_attribute.branches;
        let condition_length = conditions.length;
        let valid_operators = this.getValidTreeOperators(tree_attribute.used_operators, tree_attribute.type);
        let input_validation_res = {};
        let compare_object = {};
        let attribute_has_error_class = "";
        let conditions_content = [];
        conditions_content.push(conditions.map((condition, index)=> {
            let options = [...tree_attribute.options];
            let unique_condition_id = "tooptipp_wrapper_" + group_id  + "_" + tree_attribute.attribute_id + "_" + index;
            input_validation_res = check_valid_input_current_branch(condition);
            compare_object = compare_and_validate_tree_branches(index, conditions, is_submit, tree_attribute.type);
            let used_options = [...compare_object.used_options];
            if (validateVar(used_options) && used_options.length > 0 && tree_attribute.type === "multiselect") {
                options = this.get_valid_options(used_options, options);
            }
            condition.has_error_price = input_validation_res.has_error_price;
            condition.has_error =  (!is_false(compare_object.has_error) || !is_false(input_validation_res.has_error)) ? "Bitte tätigen Sie eine valide Eingabe" : false;
            if (!is_false(condition.has_error) || !is_false(condition.has_error_price)) {
                attribute_has_error_class = "alert-border"
            }
            return <Condition
                attribute={tree_attribute}
                is_global_page={is_global_page}
                options={options}
                is_global={is_global}
                is_global_edit_able={is_global_edit_able}
                condition={condition}
                show_add={(+condition_length-1 === +index)}
                valid_operators={valid_operators}
                unique_condition_id={unique_condition_id}
                delete_branch={() => this.delete_branch(index)}
                add_branch={this.add_branch}
                set_text_form={(text_form) => this.set_text_form(index, text_form)}
                edit_branch_operator={(operator) => this.edit_branch_operator(operator, index)}
                validate_tree_branch={() => this.validate_tree_branches(index, tree_attribute.branches)}
                on_blur_branch={(branch) => this.on_blur_branch(branch, index)}
            />
        }));
        return {
            attribute_has_error_class: attribute_has_error_class,
            conditions_content: conditions_content
        }
    };


    render() {
        let {tree_attribute, bg_color_class, margin_bottom, unique_id} = this.props;
        let {is_expanded, is_open_tooltip} = this.state;
        let {attribute_has_error_class, conditions_content} = this.get_conditions(tree_attribute);
        return <div className={"ExpansionPanel" + margin_bottom}>
            <div id={"group-" + unique_id} className={attribute_has_error_class + ' expansion-panel-header Header bg_color_class ' + bg_color_class} onClick={() => this.expandPanel()}>
                    <span className='position-relative'>
                        {tree_attribute.name}
                        {tree_attribute.short_info === '' ? null :
                            <>
                                <div id={'tooltipWrapper-'+unique_id} className='pricestrategy-info-icon-wrapper'>
                                    <i className="fas fa-info-circle" />
                                </div>
                                <Tooltip trigger='hover' placement='right' isOpen={is_open_tooltip} target={'tooltipWrapper-'+unique_id} toggle={this.toggleTooltip}>
                                    {tree_attribute.description}
                                </Tooltip>
                            </>
                        }
                    </span>
                <div className={is_expanded ? "PullRight" : "RotateIcon"}>
                    <i className='fas fa-angle-down'/>
                </div>
            </div>
            <Collapse isOpen={is_expanded} className={"Content"}>
                {conditions_content}
            </Collapse>
        </div>
    }

}

export default AttributeField;