import {
    apiCalculationManagementService,
    apiError, apiFileSystemService, apiFunctionAddComparison,
    apiFunctionAddTariff,
    apiFunctionCleanTrees,
    apiFunctionCopyTariff,
    apiFunctionDeleteTariff, apiFunctionGetCalculatedTariffs,
    apiFunctionGetTariff,
    apiFunctionGetTariffList,
    apiFunctionModifyTariff, apiFunctionRemoveComparison, apiFunctionReturnResource,
    apiFunctionUploadTariffFile,
    apiPricingService, static_download_url
} from "../Functions/global";
import validateVar from "../Functions/validation/validateVariable";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import dataToSelectField from "../Functions/dataToSelectField";
import History from "./Components/history";
import callClassWithString from "../Functions/callClassWithString";
import handleFormatDateForFrontend from "../Functions/handleFormatDateForFrontend";

let tariff_vars = {
    translation_object: {
        offer_begin_display: "Lieferbeginn",
        offer_end_display: "Lieferende",
        energy_source_certificate_url: "Öko Zertifikat",
        supplier_name: "Energieversorger",
        bonus_amount_display: "Bonus / Laufzeit",
    },
    id: {
        tariff_id: null,
        id: null
    },
    bonus_amount: {
        bonus_amount: null,
        bonus_amount_plain: {
            function_name: "handleFormatPrice",
            params: ["2", ""]
        },
        bonus_amount_display: {
            function_name: "handleFormatPrice",
            params: ["2", "€"]
        }
    },
    authority_file: null,
    consumption: null,
    consultant_employee: null,
    contract_begin: {
        contract_begin: null,
        contract_begin_display: {
            function_name: "handleFormatDateForFrontend"
        }
    },
    contract_end: {
        contract_end:null,
        contract_end_display: {
            function_name: "handleFormatDateForFrontend"
        }
    },
    contract_id: null,
    offer_duration: null,
    offer_begin: {
        offer_begin: null,
        offer_begin_display: {
            function_name: "getGermanDateFormat"
        }
    },
    offer_end: {
        offer_end: null,
        offer_end_display: {
            function_name: "getGermanDateFormat"
        }
    },
    supplier_name: null,
    offer_result_id: null,
    tree_id: null,
    contract_status: null,
    customer_id: null,
    offer_result: null,
    payment_method: {
        payment_method: null,
        payment_method_display: {
            function_name: "getPaymentMethod"
        }
    },
    contract_file: {
        contract_file_id: null,
    },
    name: {
        name: null,
        tariff_name: null,
    },
    customer_type: {
        customer_type: null,
        customer_type_tariff: null,
        customer_type_tariff_display: {
            function_name: "getCustomerTypeDisplay"
        },
        customer_type_display: {
            function_name: "getCustomerTypeDisplay"
        }
    },
    description: null,
    existing_customer_only: null,
    is_slp: null,
    is_rlm: null,
    last_type: {
        last_type: {
            function_name: "getLastType",
            params: ["%%is_slp", "%%is_rlm"]
        }
    },
    medium_type: {
        medium_type: null,
        medium_type_display: {
            function_name: "getMediumType",
        },
        label_file_display: {
            function_name: "getLabelFile",
            params: ["%%medium_type"]
        }
    },
    tariff_type: {
        tariff_type: null,
        is_spot: {
            function_name: "is_spot"
        }
    },
    lifecycle_status: {
        lifecycle_status: {
            function_name: "getLifecycleStatus"
        }
    },
    accept_differing_billing_address: null,
    cancellation_service: null,
    status_color: {
        status_color: {
            function_name: "getBadgeColor",
            params: ["%%lifecycle_status", ["Online"], ["Ausstehend"], ["Offline"]]
        }
    },
    price: {
        is_class_arr: "price",
        is_top_level: true
    },
    tariff_condition: { // tariff condition subarray start
        is_class_arr: "tariff_condition",
        is_top_level: true,
    }, // tariff condition subarray end
    tariff_information: { // tariff_information subarray start
        is_class_arr: "tariff_information",
        is_top_level: true,
    },
    supplier: {
        is_class_arr: "company",
        is_top_level: true,
    },

    files: {
        is_sub_arr: "files",
        energy_source_certificate_url: null,
        logo_url: null,
        label_url: null,
        brochure_url: null
    },
    price_matrix: {
        is_sub_arr: "price_matrix",
        template_id: {
            price_template_id: null,
        },
        valid_until: {
            price_valid_until: null
        },
        file_name: {
            price_table_file_name: null
        },
        type: {
            price_type_matrix: null
        },
        file_id: {
            price_file_id: null
        }
    },
    energy_source_display: {
        function_name: "getEnergySource",
        params: ["%%energy_source", "%%medium_type"]
    }
};

class Tariff {
    static default_values = tariff_vars;

    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(tariff_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static async instantiate(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetTariff,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        this.c = getCommunicationClass(communication_params);
        let res = await this.c.load();

        if (res.status === "ok") {
            return new Tariff(res.result);
        } else return apiError;
    }

    static async init_tariff_new_request(params) {
        let function_url = "tariff/" + params.tariff_id;
        params = getAgencyID(params);
        let communication_params = {
            method: "GET", // GET / POST / PUT / DELETE  -> method
            function_url: function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        let result = await c.request_handler();

        if (result !== apiError) {
            return new Tariff(result);
        } else return apiError;

    };

    static async instantiateList() {
        let params = getAgencyID({});
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetTariffList,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        this.c = getCommunicationClass(communication_params);

        var res = await this.c.load();
        if (res.status === "ok") {
            if (res.result === "[]") return [];
            else {
                let listResults = [];
                for (var i = 0; i < res.result.length; i++) {
                    listResults.push(new Tariff(res.result[i]));
                }
                return listResults;
            }
        } else {
            return apiError;
        }
    }

    static async add(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: apiFunctionAddTariff,
            download_function: "",
            params: null,
        };
        this.c = getCommunicationClass(communication_params);
        var res = await this.c.add(params);
        if (res.status === "ok") {
            res.result.is_added = 1;
            return res.result;
        } else return apiError;
    }


    static async get_price_table_templates(params) {
        let function_url = "import/pricing_templates";
        params = getAgencyID(params);
        let communication_params = {
            method: "GET", // GET / POST / PUT / DELETE  -> method
            function_url: function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        let result = await c.request_handler();
        if (result !== apiError) {
            return dataToSelectField("id", "name", result, null, true);
        } else return apiError;
    }

    static async addFile(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: apiFunctionAddTariff,
            download_function: "",
            params: null,
        };
        this.c = getCommunicationClass(communication_params);

        var res = await this.c.add(params);
        if (res.status !== apiError) return res.result;
        return apiError;
    }

    static async update(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: apiFunctionModifyTariff,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: null,
        };
        this.c = getCommunicationClass(communication_params);

        var res = await this.c.update(params);
        if (res.status === "ok") return new Tariff(res.result);
        return apiError;
    }

    static async getCalculatedTariffs(offer_id, additional_options = null, append_to_tariff = null) {
        let params = {
            offer_id: offer_id,
        };
        params = getAgencyID(params);
        params.options = additional_options;
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetCalculatedTariffs,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        this.c = getCommunicationClass(communication_params);
        let res = await this.c.load();
        if (res.status === "ok") {
            if (res.result === "[]") return []; // no tariffs found
            else {
                let tariffs = [];
                for (let index in res.result) {
                    if (res.result.hasOwnProperty(index)) {
                        let tariff = res.result[index];
                        if (validateVar(append_to_tariff)) {
                            tariff = {...tariff, ...append_to_tariff};
                        }
                        if (tariff.hasOwnProperty("supplier")) {
                            if (
                                tariff.supplier.hasOwnProperty("information") &&
                                typeof validateVar(tariff.supplier.information.logo_file_id)
                            ) {
                                let logo_id = tariff.supplier.information.logo_file_id;
                                let brochure_file_id = tariff.supplier.information.brochure_file_id;
                                var brochure_url = null;
                                if (validateVar(brochure_file_id))
                                    brochure_url = static_download_url + brochure_file_id;
                                // add the logo url to the result obj
                                var logo_url = static_download_url + logo_id;
                            }
                        }
                        if (
                            tariff.hasOwnProperty("tariff_information") &&
                            typeof validateVar(tariff.tariff_information.labelling_file_id)
                        ) {
                            let label_id = tariff.tariff_information.labelling_file_id;
                            let energy_source_certificate_id = tariff.tariff_information.energy_source_certificate_id;

                            var label_url = null;
                            var energy_source_certificate_url = null;
                            if (validateVar(label_id))
                                label_url = static_download_url + label_id;
                            if (validateVar(energy_source_certificate_id))
                                energy_source_certificate_url = static_download_url + energy_source_certificate_id;
                        }
                        //add all files for this tariff
                        tariff.files = {
                            logo_url: logo_url,
                            label_url: label_url,
                            brochure_url: brochure_url,
                            energy_source_certificate_url: energy_source_certificate_url
                        };
                        tariffs.push(new Tariff(tariff)); // show tariffs
                    }
                }
                return tariffs;
            }
        } else {
            if (res.result === "Offer expired.") return "expired";
            return apiError;
        }
    }

    static async getDownloadUrl(logo_id, type = "image/png") {
        let communication_params = {
            service: apiFileSystemService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionReturnResource,
            params: null,
        };
        this.c = getCommunicationClass(communication_params);
        return await this.c.download({id: logo_id}, type, true);
    }

    static async addComparisonTariff(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionAddComparison,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);


        let res = await c.load();
        if (res.status === "ok") return res;
        else return apiError;

    }

    static async remove_comparison_result(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionRemoveComparison,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);


        let res = await c.load();
        if (res.status === "ok") return res;
        else return apiError;

    }

    static async updateTable(params, ids) {
        let error_count = 0;
        let updated_ids = [];

        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: apiFunctionModifyTariff,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: null,
        };
        this.c = getCommunicationClass(communication_params);
        for(let id of ids){
            params.tariff_id = id;
            var res = await this.c.update(params);
            if (res.status === "ok") {
                updated_ids.push(res); // get ids for updating redux states later
            } else {
                error_count = error_count + 1; // get error count for user feedback
            }
        }

        if (updated_ids.length === 0) return apiError; // if no data was deletet send ERR back
        return await this.instantiateList();
    }

    static async deleteOldGlobalAttributes(params) {
        let communication_params = {
            service: apiPricingService,
            get_function: apiFunctionCleanTrees,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        this.c = getCommunicationClass(communication_params);


        var res = await this.c.load();
        if (res.status === "ok") return res;
        return apiError;
    }

    static async copyTariff(params) {
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: apiFunctionCopyTariff,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        this.c = getCommunicationClass(communication_params);

        let res = await this.c.update(params);
        if (res.status === "ok") return await this.instantiateList();
        return apiError;
    }

    static async uploadFile(params, file) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: apiFunctionUploadTariffFile,
            download_function: "",
            params: params,
        };
        this.c = getCommunicationClass(communication_params);


        var res = await this.c.add(params, file);
        if (res.status === "ok") return new Tariff(res.result);
        return apiError;
    }

    static async import_price_table(params) {
        let function_url = "import/pricing";
        params = getAgencyID(params);
        let communication_params = {
            method: "POST", // GET / POST / PUT / DELETE  -> method
            function_url: function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        let result = await c.request_handler();
        if (result !== apiError) {
            return result;
        } else return apiError;
    }

    static async deleteMultipleTariffs(selected_array) {
        let error_count = 0;
        let deleted_ids = [];
        for (let tariff of selected_array) {
            // loop through all values (objects or ids)
            let params = {
                // get params for api request
                tariff_id: tariff,
            };
            params = getAgencyID(params);
            let communication_params = {
                service: apiCalculationManagementService,
                get_function: "",
                update_function: "",
                delete_function: apiFunctionDeleteTariff,
                add_function: "",
                download_function: "",
                params: null,
            };
            this.c = getCommunicationClass(communication_params);

            let res = await this.c.delete_async(params); // start "async" (not normal delete!) delete request
            if (res.status === "ok") {
                deleted_ids.push(tariff); // get ids for updating redux states later
            } else {
                error_count = error_count + 1; // get error count for user feedback
            }
        }
        if (deleted_ids.length === 0) return apiError; // if no data was deletet send ERR back
        return [deleted_ids, error_count]; // send deleted ids, and error quote back
    }

    delete() {
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "",
            delete_function: apiFunctionDeleteTariff,
            add_function: "",
            download_function: "",
            params: null,
        };
        this.c = getCommunicationClass(communication_params);
        let res = this.c.delete({
            tariff_id: this.id,
            supplier_id: this.customer_id
        });
        return res;
    }

    static dummy() {
        let output = {};
        output = this.build_dummy(tariff_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    }else if (validateVar(vars[index].is_class_arr)) {
                        let class_name = callClassWithString(vars[index].is_class_arr);
                        this.default_values[index] = class_name.default_values;
                        output[index] = class_name.constructor.dummy();
                    }else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default Tariff;
