import convertToIECompatibleDate from "./convertToIECompatibleDate";

function getExpiredInSeconds(expired_at) {
    var now = new Date();
    expired_at = convertToIECompatibleDate(expired_at, 'time');
    let new_month = +expired_at[1] -1;//subtract one month since january starts with a 0 instead of a 1
    var expired = new Date(expired_at[0], new_month, expired_at[2], expired_at[3], expired_at[4], expired_at[5]);
    var dif = expired.getTime() - now.getTime();
    var Seconds_from_NOW_to_EXPIRED = dif / 1000;
    Seconds_from_NOW_to_EXPIRED = Seconds_from_NOW_to_EXPIRED + "";
    if (Seconds_from_NOW_to_EXPIRED <= 0) return 0;
    else if (Seconds_from_NOW_to_EXPIRED.includes(".")){
        let tmpDiff = Seconds_from_NOW_to_EXPIRED.split(".");
        return Seconds_from_NOW_to_EXPIRED = tmpDiff[0];
    } else return Seconds_from_NOW_to_EXPIRED;
}

export default getExpiredInSeconds;