import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

class MoreOptions extends React.Component {
    state = {
      dropdown: ''
    };

    handleClick = (e) => {//Hier wird der Fetch ausgeführt
        var action = e.target.getAttribute("data-action");
        var id = e.target.id;
        this.props.optionClicked({action: action, integration_id: id})
    };

    dropdownOptions = () => {//Dropdown Optionen generieren
        //Bei mehreren Implementierungen von MoreOptions werden zukünftig vielleicht mehr Optionen benötigt
        return(<span onClick={this.setDropdown} className='position-absolute dropdown-options left noselect'>
        {(typeof this.props.exclude !== 'undefined' && this.props.exclude.includes('delete')) ? '' : <div data-action={"delete"} id={this.props.integration_id} onClick={(e) => this.handleClick(e)}>Entfernen</div>}
    </span>)};

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => { //Dropdown Schliessen beim Klick außerhalb
        if(!this.wrapperRef.contains(event.target)){
            this.setState({dropdown: ''});
        }
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    setDropdown = () => {
        if(this.state.dropdown === ''){
            this.setState({dropdown: this.dropdownOptions()})
        }else{
            this.setState({dropdown: ''})
        }
    };

    render() {
        return(<span ref={this.setWrapperRef} className='position-relative more-options-wrapper'>
                <i onClick={this.setDropdown} className="fas fa-ellipsis-v more-options" />
                <ReactCSSTransitionGroup
                    transitionName="fade"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}>
                {this.state.dropdown}
                </ReactCSSTransitionGroup>
            </span>
        );
    }
}

export default MoreOptions;