import {
  apiCalculationManagementService,
  apiError,
  apiFileSystemService,
  apiFunctionReturnResource,
  consumption_point_object_types,
  list_timeline
} from "../Functions/global";
import History from "./Components/history";
import validateVar from "../Functions/validation/validateVariable";
import isEmptyObject from "../Functions/isEmptyObject";
import Contract from "./contract";
import ConsumptionPoint from "./consumption_point";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import ExtendedInformation from "./Components/ConsumptionPoint/extended_information";
import Customer from "./customer";
import Tariff_Information from "./Components/Tariff/tariff_information";

let chronik_vars = {
  id: null,
  agency_id: null,
  clearing: null,
  clearing_id: null,
  download_url: null, //TODO is not beeing used anymore but its still beeing set in case the add blocker issue appears within the current download process
  created_at: {
    created_at: null,
    created_at_display: {
      function_name: "handleFormatDateForFrontend",
      params: ["true"]
    }
  },
  sender_name: null,
  employee_id: null,
  file_id: null,
  message: {
    is_sub_arr: "message",
    message: {
      message: null
    },
    subject: null,
    sender_agency_id: null,
    sender_employee_id: null
  },
  message_id: null,
  sender_logo_id: null,
  protocol_id: null,
  history: {
    is_sub_arr: "history",
    history_type: null,
    status: null
  },
  status_color: {
      status_color: {
          function_name: "getBadgeColor",
          params: ["%%status", ["Online"], ["open"], ["declined"]]
      }
  },
  updated_at: {
    updated_at: null,
    updated_at_dispaly: {
      function_name: "handleFormatDateForFrontend",
      params: ["true"]
    }
  },
  has_history: true
};

class Chronik {
  constructor(data) {
    let default_structure, compare_obj;
    [default_structure, compare_obj] = this.reference_object(data);
    let class_content = new History(chronik_vars, data, data.history, [], "all");
    for (let name in { ...class_content }) {
      if (class_content.hasOwnProperty(name)) {
        this[name] = class_content[name];
      }
    }
    if (!isEmptyObject(data.history)) {
        let time_line = new History(
        default_structure,
        compare_obj,
        [data.history],
        [],
        "all",
        true
      );
      let relevant_history = {};

      for (let changed_index in time_line.history) {
        if (
          time_line.history.hasOwnProperty(changed_index) &&
          validateVar(time_line.history[changed_index])
        ) {
          relevant_history[changed_index] = time_line.history[changed_index];
        }
      }
      this.history = relevant_history;
    } else this.history = null;
  }

  reference_object(data) {
    let default_structure = null;
    let compare_obj = null;
    if(validateVar(data.history)){
      if (
          data.history.history_type === "consumption_point"
      ) {
        default_structure = ConsumptionPoint.default_values;
        compare_obj = ConsumptionPoint.dummy();
      } else if (
          data.history.history_type === "consumption_point_information"
      ) {
        default_structure = ExtendedInformation.default_values;
        compare_obj = ExtendedInformation.dummy();
      }else if (
          data.history.history_type === "cancellation"
      ) {
        default_structure = ExtendedInformation.default_values.cancellation;
        compare_obj = ExtendedInformation.dummy().cancellation;
      } else if (
          data.history.history_type === "invoice_address"
      ) {
        default_structure = ConsumptionPoint.default_values.invoice_address;
        compare_obj = ConsumptionPoint.dummy().invoice_address;
      } else if (
          data.history.history_type === "contract"
      ) {
        default_structure = Contract.default_values;
        compare_obj = Contract.dummy();
      } else if (
          data.history.history_type === "customer"
      ) {
        default_structure = Customer.default_values;
        compare_obj = Customer.dummy();
      } else if (
          data.history.history_type === "tariff_information"
      ) {
        default_structure = Tariff_Information.default_values;
        compare_obj = Tariff_Information.dummy();
      }else if (
          data.history.history_type === "contract_document"
      ) {
        default_structure =
            Contract.default_values.documents;
        compare_obj = Contract.dummy().documents;
      }

          //todo athi muss tariff_condition für extention und cancelation von contract einbauen (nicht keyfacts)
          // else if (
          //   validateVar(data.history) &&
          //   data.history.history_type === "tariff_condition"
          // ) {
          //   default_structure = Contract.default_values.tariff_condition;
          //   compare_obj = Contract.dummy().tariff_condition;
      // }
      else if (
          data.history.history_type === "offer_result"
      ) {
        default_structure = Contract.default_values.offer_result;
        compare_obj = Contract.dummy().offer_result;
      }
      // else if (
      //   validateVar(data.history) &&
      //   data.history.history_type === "price"
      // ) {
      //   default_structure = Price.default_values;
      //   compare_obj = Price.dummy().price;
      // }
    }

    return [default_structure, compare_obj];
  }

  static async init(params, site_name, id) {
    params = getAgencyID(params, true);
    // let c = new Communication(
    //   apiCalculationManagementService,
    //   list_timeline,
    //   "",
    //   "",
    //   "",
    //   "",
    //   params
    // );


      let communication_params = {
          service: apiCalculationManagementService,
          get_function: list_timeline,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);

    var res = await c.load();
    if (res.status === "ok") {
      let chronik_res_arr = res.result;
      let chronik_class_arr = [];
      for (let single_result of chronik_res_arr) {
        if (site_name === "consumption_point") {
          if ( // consumption point values in timeline
            validateVar(single_result.history) &&
            validateVar(single_result.history.history_type)
          ) {
              let reference_consumption_point_id_arr =  single_result.history.reference_consumption_point_id;
            if ((+single_result.history.reference_id === +id  && consumption_point_object_types.includes(single_result.history.history_type)) ||
                (Array.isArray(reference_consumption_point_id_arr) && reference_consumption_point_id_arr.includes(+id) && consumption_point_object_types.includes(single_result.history.history_type))
            ) {
                if (validateVar(single_result.file_id)) {
                    single_result.download_url = await this.get_download_url(
                        single_result.file_id
                    );
                }
                chronik_class_arr.push(new Chronik(single_result, site_name));
            }
          }
        } else {
          // contract values in timeline
          if (validateVar(single_result.file_id)) {
            single_result.download_url = await this.get_download_url(
              single_result.file_id
            );
          }
          chronik_class_arr.push(new Chronik(single_result, site_name));
        }
      }
      return chronik_class_arr;
    } else return apiError;
  }

  static async create(params, file) {
    params = getAgencyID(params, true);

    // let c = new Communication(
    //   apiCalculationManagementService,
    //   "",
    //   "",
    //   "",
    //   "create_timeline",
    //   "",
    //   null
    // );
      let communication_params = {
          service: apiCalculationManagementService,
          get_function: list_timeline,
          update_function: "",
          delete_function: "",
          add_function: "create_timeline",
          download_function: "",
      };
      let c = getCommunicationClass(communication_params);
    let res = null;
    if (validateVar(file)) {
      res = await c.add(params, file);
    } else {
      res = await c.add(params);
    }

    let res_obj = res.result;
    if (validateVar(res_obj.file_id)) {
      res_obj.download_url = await this.get_download_url(res_obj.file_id);
    }

    if (res.status === "ok") {
      return new Chronik(res_obj);
    } else return apiError;
  }

  static async get_download_url(file_id) {
      let communication_params = {
          service: apiFileSystemService,
          get_function: "",
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: apiFunctionReturnResource,
      };
      let c = getCommunicationClass(communication_params);
     return await c.download(
      { id: file_id },
      "application/pdf",
      true
    );
  }

  static async download_file(file_id) {
    // this.c = new Communication(
    //   apiFileSystemService,
    //   "",
    //   "",
    //   "",
    //   "",
    //   apiFunctionReturnResource,
    //   {}
    // );
    let communication_params = {
      service: apiFileSystemService,
      get_function: "",
      update_function: "",
      delete_function: "",
      add_function: "",
      download_function: apiFunctionReturnResource,
    };
    let c = getCommunicationClass(communication_params);
    return await c.download(
        { id: file_id },
        null,
        false
    );
  }
}

export default Chronik;
