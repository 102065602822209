import "whatwg-fetch";
import {apiBearer, apiFunctionsGetCity, apiServiceNetfee, apiURL, apiUser, apiUserAuthToken} from "./global";
import validateVar from "./validation/validateVariable";
async function getCities(postalCode) {
  postalCode = postalCode + "";
  return await fetch(apiURL, {
    method: "POST",
    headers: {
      Authorization: apiBearer,
      "Content-Type": "application/json"
    },
    http_errors: false,
    body: JSON.stringify({
      user: apiUser,
      auth_token: apiUserAuthToken,
      service: apiServiceNetfee,
      function: apiFunctionsGetCity,
      param: {
        postal_code: postalCode
      }
    })
  })
    .then(res => res.json())
    .then(result => {
      if (
        result.status === "ok" &&
        validateVar(result.result) &&
        result.result !== "[]"
      ) {
        return result.result;
      } else {
        return "ERR";
      }
    })
    .catch(function(error) {
      // catch
      console.log("Request failed", error);
    });
}

export default getCities;
