/*
    function to check if a value in a document has changed
    usecase: for example display a submitBtn if something has changed
    state_value = value of the field u just changed
    state_property = state name of the field u just changed
    data_props_original = redux data (on load data / original data)
    all_states_now = obj with all states you can change in it to check if something was changed before your change now
     (for this to work u need a state with a obj with all states you want to check for in it)
 */

function hasChanged(
  state_value,
  state_property,
  data_props_original,
  all_states_now
) {
  //return value defined as false from the start
  let has_changed = false;
  //check if value you just updated has changed or its back in its original definition

  if (state_value+"".toLowerCase() !== data_props_original[state_property]+"".toLowerCase())
    has_changed = true; //if changed set return value to true;
  //loop through all states to check if another state was already changed withour hitting the submitBtn
  for (let property in all_states_now) {
    if (all_states_now.hasOwnProperty(property)) {
        // check for changes / skip the state you just changed because you checked it above already
        if (
            (all_states_now[property] + "").toLowerCase() + "" !== (data_props_original[property] + "").toLowerCase() + "" &&
            state_property.toLowerCase() !== property.toLowerCase()
        ) {
            has_changed = true; //if changed set return value to true;
        }
    }
  }
  return has_changed;
}
export default hasChanged;
