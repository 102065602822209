import React from 'react';
import validateVar from "../../../Functions/validation/validateVariable";
import SimpleHeader from "../../../Displays/Headers/SimpleHeader";
import {SingleContract} from "../../../../globalNavigation";
import {Card, CardBody, Col, Container, Row, TabPane} from "reactstrap";
import Timeline from "./Timeline";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {emptyValue, project_name} from "../../../Functions/global";
import PrimaryButton from "../../../Displays/Buttons/PrimaryButton";
import BootstrapTable from "react-bootstrap-table-next";
import SecondaryButton from "../../../Displays/Buttons/SecondaryButton";
import NavTabs from "../../../Displays/Basic/NavTabs";
import BasicValueDisplay from "../../../Displays/Clearing/BasicValueDisplay";
import Badge from "../../../Displays/Badges/Badge";

const is_ehub = project_name === 'EVU_PROJECT';

class ConsumptionPointPage extends React.Component {
    state = {
        consumption_point_options: ['change_status'],
    };

    render() {
        const {is_loaded_consumption_point, consumption_point, contract_id, content, chronik, disable_switching_status_btn, protocol_id} = this.props;
        return (<>
            <div className='position-absolute'>{alert}</div>
            <SimpleHeader name="Lieferstellen-Detailansicht" returnButton={'Zurück zum Auftrag'}
                          prevPage={SingleContract + '/' + contract_id + "/" + protocol_id}/>
            <Container className="mt--6" fluid>
                <Card>
                    <CardBody>
                        <Row className='consumption-point-header'>
                            <Col sm={8} md={8} lg={10}>
                                <ul className='list-middot mb-3 mb-sm-0 pr-md-0'>
                                    <li><h2 className='d-inline'>
                                        <BasicValueDisplay
                                            is_loaded={is_loaded_consumption_point}
                                            value={"id"}
                                            text={"Lieferstelle "}
                                            object={consumption_point}
                                        />
                                    </h2>
                                    </li>
                                    <li className='d-none d-lg-inline-block'>
                                        <BasicValueDisplay
                                            is_loaded={is_loaded_consumption_point}
                                            object={consumption_point}
                                            value={"full_address"}
                                        />
                                    </li>
                                    <li className='d-none d-lg-inline-block'>
                                        <BasicValueDisplay
                                            is_loaded={is_loaded_consumption_point}
                                            value={"medium_type_display"}
                                            object={consumption_point}
                                        />
                                    </li>
                                    <li className='d-none d-lg-inline-block'>
                                        <BasicValueDisplay
                                            is_loaded={is_loaded_consumption_point}
                                            value={"consumption_type"}
                                            object={consumption_point}
                                        />
                                    </li>
                                    <li>
                                        <Badge is_loaded={is_loaded_consumption_point}
                                            type={"badge"}
                                            value={consumption_point.switching_status_display}
                                            color={consumption_point.status_color}
                                        />
                                    </li>
                                </ul>
                            </Col>
                            <Col sm={4} md={4} lg={2} className='buttons-wrapper pl-md-0'>
                                {/*<DropdownButton*/}
                                {/*clickChangeStatusBtn={() => this.toggleModal('consumption_point_status')}*/}
                                {/*clickExportBtn={this.exportConsumptionPointData} //TODO Laut Alex kommt lieferstellen export iwann dazu*/}
                                {/*disabled={consumption_point.switching_status === 'successful' || consumption_point.switching_status === 'announced'}*/}
                                {/*hideTooltip*/}
                                {/*include={consumption_point_options}/>*/}

                                {is_ehub && <SecondaryButton is_loaded={is_loaded_consumption_point}
                                                  disabled={disable_switching_status_btn}
                                                  onClick={() => this.props.toggleModal('consumption_point_status', consumption_point.id)}
                                                  value={'Status ändern'}/>}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <NavTabs tab_names={['Chronik', 'Lieferstellenprofil']}>
                        <TabPane tabId="1">
                            <Timeline
                                {...this.props}
                                create_time_line={(params, file) => this.props.create_time_line(params, file)}
                                timeline_download={function() {}} //no download needed here
                                showErrorMessage={function () {}}
                            >
                                {validateVar(chronik) && chronik.length === 0 &&
                                <div className='static-warning-notification more-padding grey mb-2'>
                                    Es gab bis jetzt keine Änderungen bezüglich dieser Lieferstelle.
                                </div>}
                            </Timeline>
                        </TabPane>
                        <TabPane tabId="2">
                            {content}
                        </TabPane>
                        <TabPane tabId="3">
                            <ToolkitProvider
                                data={[{
                                    duration: '01.01.2019 - 31.12.2019',
                                    initial_consumption: '30.000 kWh',
                                    final_consumption: '80.000 kWh',
                                    consumption: '50.000 kWh',
                                    loadprofile: 'Lastgang'
                                }]}
                                keyField="id"
                                columns={[
                                    {
                                        dataField: "duration",
                                        text: "Erfasster Lieferzeitraum",
                                        sort: true,
                                    },
                                    {
                                        dataField: "initial_consumption",
                                        text: "Initialer Zählerstand",
                                        sort: true,
                                    },
                                    {
                                        dataField: "final_consumption",
                                        text: "Finaler Zählerstand",
                                        sort: true,
                                    },
                                    {
                                        dataField: "consumption",
                                        text: "Verbrauchte Menge",
                                        sort: true,
                                    },
                                    {
                                        dataField: "loadprofile",
                                        text: "Lastgang",
                                        formatter: (cell) => validateVar(cell.load_profile_id) ? 'Link' : emptyValue,
                                        sort: true,
                                    },
                                ]}
                                search
                            >
                                {props => (
                                    <div className="pt-4 pb-0 consumption-points-table">
                                        <div className="container-fluid">
                                            <Row className='mb-3'>
                                                <Col>
                                                    <h2>Verbrauchshistorie</h2>
                                                </Col>
                                                <Col className='text-right'>
                                                    <PrimaryButton
                                                        onClick={() => this.toggleModal('create_consumption_history')}
                                                        value={'Verbrauch erfassen'}/>
                                                </Col>
                                            </Row>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                            wrapperClasses="table-responsive mb-0"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </TabPane>
                    </NavTabs>
                </Card>
            </Container>
        </>);
    }
}

export default ConsumptionPointPage;