import React from 'react';
import "./Tree.css";
import {Card, CardBody, CardHeader, Col, Modal, Row} from "reactstrap";
import validateVar from "../../../Functions/validation/validateVariable";
import InputHandler from "../../../Inputs/InputHandler";
import PrimaryButton from "../../../Displays/Buttons/PrimaryButton";
import Validation from "../../../Objects/Components/validation";


class GroupsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_open: false,
            submit_disabled: false,
            error_messages: {},
            group: {}
        };
    }

    toggleModal = (type = "add", group = null) => { // add, copy, edit
        let group_with_no_ref = {...group};
        if (type === "copy" && validateVar(group_with_no_ref)) {
            group_with_no_ref.name = group_with_no_ref.name + " (Kopie)";
            group_with_no_ref.description = ""
        }
        let value_extension = this.get_value_extension(type);
        this.setState((prevState) => {
            return {
                is_open: !prevState.is_open,
                type: type,
                value_extension: value_extension,
                group: (validateVar(group_with_no_ref)) ? group_with_no_ref : {}
            }
        })
    };

    get_value_extension = (type) => {
        let value_extension = "anlegen";
        if (type === "copy")  value_extension = "kopieren";
        if (type === "edit")  value_extension = "bearbeiten";
        return value_extension;
    };

    update_group = (name, value) => {
        let {group} = this.state;
        group[name] = value;
        this.setState({group: group});
    };

    submit = () => {
        let {type, group} = this.state;
        let {name, description} = group;

        this.setState({submit_disabled: true});

        /* special cases for validation */
        let special_cases = {
            description: {case_name: "can_be_empty"},
        };
        let data = {
            name: name,
            description: description,
        };
        let validation_result = new Validation(data, special_cases);
        data.group_id  = (validateVar(group.id)) ? group.id : null;
        this.setState({
            error_messages: validation_result.error_messages
        });
        if (!validation_result.has_error) { // if no error
            data.type = type;
            data.group = group;
            this.props.submit(data);
            this.setState({submit_disabled: false});
            this.toggleModal();
        } else {
            this.setState({submit_disabled: false});
        }
    };

    render() {
        let {value_extension, group, is_open, error_messages, submit_disabled} = this.state;
        let {name, description} = group;
        let {name_error, description_error} = error_messages;

        return (

                <Modal
                    className={'modal-dialog-centered ModalDialogWidth'}
                    isOpen={is_open}
                    toggle={() => this.toggleModal()}>
                    <div className={['modal-body', 'p-0'].join(' ')}>
                        <Card className="bg-neutral border-0 mb-0">
                            <CardHeader className="modal-title">
                                <Row>
                                    <Col xs={6}>
                                        <h2 className={'mb-0'}>{"Gruppierung " + value_extension}</h2>
                                    </Col>
                                    <Col xs={6}>
                                        <button onClick={() => this.toggleModal()} className={'close'}><i
                                            className={'ni ni-fat-remove close-button'}/></button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className={"CardBody"}>
                                <Row>
                                    <Col md={12}>
                                        <InputHandler
                                            content_title="Name"
                                            type="text"
                                            name="name"
                                            value={name}
                                            on_change={(name, value) => this.update_group(name, value)}
                                            is_loaded={true}
                                            optional={false}
                                            error_state={name_error}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <InputHandler
                                            content_title="Beschreibung"
                                            type="text"
                                            name="description"
                                            value={description}
                                            on_change={(name, value) => this.update_group(name, value)}
                                            is_loaded={true}
                                            optional={true}
                                            error_state={description_error}
                                        />
                                    </Col>
                                    <Col md={12} className={"text-right"}>
                                        <PrimaryButton disabled={submit_disabled} onClick={this.submit} value={'Gruppierung ' + value_extension} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </div>
                </Modal>
        );
    }
}

export default GroupsModal;
