import Contract from "../Objects/contract";
import Reporting from "../Objects/reporting";
import ConsumptionPoint from "../Objects/consumption_point";
import Tariff from "../Objects/tariff";
import Url from "../Objects/url";
import Employee from "../Objects/employees";
import CalculationUnit from "../Objects/calculation_unit";
import RecommendedAction from "../Objects/recommended_action";
import UploadLoadProfile from "../Objects/upload_load_profile";
import File from "../Objects/files";
import Tariff_Condition from "../Objects/Components/Tariff/tariff_condition";
import Price from "../Objects/price";
import Tariff_Information from "../Objects/Components/Tariff/tariff_information";
import Margins from "../Objects/Components/Company/margins";
import Subscription_Information from "../Objects/Components/Company/subscription_information";
import Customer_Information from "../Objects/Components/Company/customer_information";
import Add_Ons from "../Objects/Components/Company/add_ons";
import Billing_Address from "../Objects/Components/Company/billing_address";
import Notification from "../Objects/notification";
import Company_Information from "../Objects/Components/Company/company_information";
import Delta from "../Objects/Components/Offer/delta";
import BankAccount from "../Objects/bankAccount";
import Prediction from "../Objects/Components/ConsumptionPoint/prediction";
import ExtendedInformation from "../Objects/Components/ConsumptionPoint/extended_information";
import Customer from "../Objects/customer";
import Company from "../Objects/company";
import Key_Facts from "../Objects/key_facts";
import ExternalOffer from "../Objects/external_offer";
import Offer from "../Objects/offer";
import ConsultantEmployeeData from "../Objects/Components/Employee/consultant_employee_data";

function callClassWithString(class_name, data = {}, status = "open"){
        switch(class_name){
            case 'contract':
                return new Contract(data, status);
            case 'reporting':
                return new Reporting(data, status);
            case 'consumption_point':
               return new ConsumptionPoint(data, status);
            case 'tariff':
               return new Tariff(data, status);
            case 'url':
               return new Url(data, status);
           case 'offer':
               return new Offer(data, status);
           case 'external_offer':
               return new ExternalOffer(data, status);
            case 'employee':
               return new Employee(data, status);
            case 'calculation_units':
               return new CalculationUnit(data, status);
            case 'recommended_action':
               return new RecommendedAction(data, status);
            case 'upload_load_profile':
               return new UploadLoadProfile(data, status);
            case 'file':
               return new File(data, status);
            case 'tariff_condition':
               return new Tariff_Condition(data, status);
            case 'tariff_information':
               return new Tariff_Information(data, status);
            case 'bank_account':
               return new BankAccount(data, status);
            case 'price':
               return new Price(data, status);
            case 'margins':
               return new Margins(data, status);
            case 'customer_information':
                return new Customer_Information(data, status);
            case 'subscription_information':
               return new Subscription_Information(data, status);
            case 'customer':
               return new Customer(data, status);
            case 'add_ons':
               return new Add_Ons(data, status);
            case 'billing_address':
               return new Billing_Address(data, status);
            case 'notifications':
               return new Notification(data, status);
            case 'company':
               return new Company(data, status);
            case 'deltas':
               return new Delta(data, status);
            case 'company_information':
               return new Company_Information(data, status);
            case 'prediction':
               return new Prediction(data, status);
            case 'extended_information':
               return new ExtendedInformation(data, status); // FIXME: Hier kommts her
            case 'keyfacts':
               return new Key_Facts(data, status);
           case 'calculation_unit':
               return new CalculationUnit(data, status);
           case 'consultant_employee_data':
               return new ConsultantEmployeeData(data, status);
            default:
                console.log("missing class_name: ", class_name);
            // alert("call Class", class_name + " is missing");
        }



}

export default callClassWithString;