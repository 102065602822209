import Communication from "./Components/Communication/communication";
import {
  apiError,
  apiFunctionDeletePfc,
  apiFunctionGetPfc,
  apiFunctionGetPfcList,
  apiFunctionSavePfc,
  apiFunctionUpdateProviderPfc,
  apiServicePfc,
  emptyValue
} from "../Functions/global";
import handleFormatPrice from "../Functions/handleFormatPrice";
import { deHashVar } from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";

class HPFC {
  constructor(data, communication = undefined) {
    if (typeof communication === "undefined")
      this.c = new Communication(
        apiServicePfc,
        apiFunctionGetPfc,
        apiFunctionUpdateProviderPfc,
        apiFunctionDeletePfc,
        apiFunctionSavePfc,
        apiFunctionGetPfc,
        {}
      );
    else this.c = communication;
    /*
     *   default value for vars in this class
     *   add new vars here
     * */
    //basic
    this.id = "";
    this.foreign_user_id = ""; //provderID
    this.owner_id = "";

    this.file_name = "";
    this.foreign_id = "";
    this.is_default = "";
    this.is_default_translated = "";
    this.valid_from = ""; //only customer who has been in contract before
    this.valid_until = "";
    this.quarters = "";
    this.keep_updated = "";
    this.keep_updated_translated = "";

    this.pfc_type = ""; //0 = energy / 1 = gas
    this.medium_type = "";
    this.medium_type_translated = "";

    this.created_at = "";
    this.updated_at = "";

    this.foreign_raw_id = "";

    //list items custom
    this.custom_medium_type = "";
    this.average_price = "";
    this.average_price_formatted = "";
    this.date_custom_created = "";
    this.date_custom_from = "";
    this.date_custom_until = "";
    this.display_custom_file_name = "";

    //load basic info

    if (validateVar(data) && validateVar(data.pfc_id)) {
      //if data found
      this.id = !validateVar(data.pfc_id) ? "" : data.pfc_id;
      this.pfc_id = !validateVar(data.pfc_id) ? "" : data.pfc_id;
      this.foreign_user_id = !validateVar(data.foreign_user_id)
        ? ""
        : data.foreign_user_id;
      this.owner_id = !validateVar(data.owner_id) ? "" : data.owner_id;

      this.file_name = !validateVar(data.file_name) ? "" : data.file_name;
      this.foreign_id = !validateVar(data.foreign_id) ? "" : data.foreign_id;

      this.valid_from = !validateVar(data.valid_from) ? "" : data.valid_from;
      this.valid_until = !validateVar(data.valid_until) ? "" : data.valid_until;
      this.quarters = !validateVar(data.quarters) ? "" : data.quarters;

      this.pfc_type = !validateVar(data.pfc_type) ? "" : data.pfc_type;

      this.created_at = !validateVar(data.created_at) ? "" : data.created_at;
      this.updated_at = !validateVar(data.updated_at) ? "" : data.updated_at;

      this.foreign_raw_id = !validateVar(data.foreign_raw_id)
        ? ""
        : data.foreign_raw_id;
      this.average_price = !validateVar(data.average_price)
        ? ""
        : data.average_price;
      this.average_price_formatted = !validateVar(data.average_price)
        ? ""
        : handleFormatPrice(data.average_price, 3, "€");
      //translate able
      this.medium_type = !validateVar(data.medium_type) ? "" : data.medium_type;
      this.medium_type_translated = !validateVar(data.medium_type)
        ? ""
        : this.getMediumType(data.medium_type); //translated
      this.keep_updated = !validateVar(data.keep_updated)
        ? ""
        : data.keep_updated;
      this.keep_updated_translated = !validateVar(data.keep_updated)
        ? ""
        : this.getKeepUpdated(data.keep_updated); //translated
      this.is_default = !validateVar(data.is_default) ? "" : data.is_default;

      //list items custom
      this.custom_medium_type = this.getEnergyDescription();
      this.display_custom_file_name = this.getDisplayFileName();
      this.date_custom_from = this.germanDateFormat(this.valid_from);
      this.date_custom_until = this.germanDateFormat(this.valid_until);

      if (this.created_at !== "") {
        let created_at = this.created_at.split("T");
        created_at[0] = this.germanDateFormat(created_at[0]);
        created_at[1] = this.timeFormat(created_at[1]);
        let unit = (validateVar(created_at[1])) ? " Uhr" : "";

        this.date_custom_created = created_at[0] + " " + created_at[1] + unit;
      }
    }
  }

  /*
        Instantiate a new Customer with its ID as Param
        Call Communication Class with matching global defined Vars for API Request
        return new Customer Clnass
     */
  static async instantiate(id) {
    // this.c = new Communication(
    //   apiServicePfc,
    //   apiFunctionGetPfc,
    //   apiFunctionUpdateProviderPfc,
    //   apiFunctionDeletePfc,
    //   apiFunctionSavePfc,
    //   apiFunctionGetPfc,
    //   { id: id, data_type: "overview" }
    // );

    let params = { id: id, data_type: "overview" }
    params = getAgencyID(params);
      let communication_params = {
          service: apiServicePfc,
          get_function: apiFunctionGetPfc,
          update_function: apiFunctionUpdateProviderPfc,
          delete_function: apiFunctionDeletePfc,
          add_function: apiFunctionSavePfc,
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);


      let res = await c.load();

    if (res.statusText === "Internal Server Error") {
      return "Api Error";
    } else {
      if (res.status === "ok") return new HPFC(res.result);
      else return apiError;
    }
  }

  /*
      Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
      Call Communication Class with matching global defined Vars for API Request
      return Array with all TariffCalculatedContainer Obj
   */
  static async instantiateList() {
    // this.c = new Communication(
    //   apiServicePfc,
    //   apiFunctionGetPfcList,
    //   apiFunctionUpdateProviderPfc,
    //   apiFunctionDeletePfc,
    //   apiFunctionSavePfc,
    //   apiFunctionGetPfc,
    //   { supplier_id: supplier_id }
    // );
    let params = getAgencyID({});

      let communication_params = {
          service: apiServicePfc,
          get_function: apiFunctionGetPfcList,
          update_function: apiFunctionUpdateProviderPfc,
          delete_function: apiFunctionDeletePfc,
          add_function: apiFunctionSavePfc,
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);


    var res = await c.load();
    if (res.status === "ok") {
      if (res.result === "[]") return [];
      let listResults = [];
      for (var i = 0; i < res.result.length; i++) {
        listResults.push(new HPFC(res.result[i]));
      }
      return listResults;
    } else return apiError;
  }

  /*
        update function - params must be a valid object
     */
  static async update(param) {
      let communication_params = {
          service: apiServicePfc,
          get_function: apiFunctionGetPfcList,
          update_function: apiFunctionUpdateProviderPfc,
          delete_function: apiFunctionDeletePfc,
          add_function: apiFunctionSavePfc,
          download_function: "",
          params: param,
      };
      let c = getCommunicationClass(communication_params);

    return c.update(param).then(res => {
      if (res.status === "ok") return true;
      return apiError;
    });
  }

  delete() {

      let communication_params = {
          service: apiServicePfc,
          get_function: apiFunctionGetPfcList,
          update_function: apiFunctionUpdateProviderPfc,
          delete_function: apiFunctionDeletePfc,
          add_function: apiFunctionSavePfc,
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);

    let res = c.delete({
      pfc_id: this.id,
      supplier_id: this.foreign_user_id
    });
    return res;
  }

  static async deleteMultiple(selected_array) {
    let error_count = 0;
    let deleted_ids = [];
    for (let id of selected_array) {
      // loop through all values (objects or ids)
      let params = {
        // get params for api request
        pfc_id: id,
      };

      params = getAgencyID(params);

      // let c = new Communication( // define communication class for request
      //   apiServicePfc,
      //   apiFunctionGetPfcList,
      //   apiFunctionUpdateProviderPfc,
      //   apiFunctionDeletePfc,
      //   apiFunctionSavePfc,
      //   apiFunctionGetPfc,
      //   {}
      // );

        let communication_params = {
            service: apiServicePfc,
            get_function: apiFunctionGetPfcList,
            update_function: apiFunctionUpdateProviderPfc,
            delete_function: apiFunctionDeletePfc,
            add_function: apiFunctionSavePfc,
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);


      let res = await c.delete_async(params); // start "async" (not normal delete!) delete request
      if (res.status === "ok") {
        deleted_ids.push(id); // get ids for updating redux states later
      } else {
        error_count = error_count + 1; // get error count for user feedback
      }
    }
    if (deleted_ids.length === 0) return apiError; // if no data was deletet send ERR back
    return [deleted_ids, error_count]; // send deleted ids, and error quote back
  }

  static async addPfc(params, file) {
    params.data_type = "overview";

    params = getAgencyID(params);


      let communication_params = {
          service: apiServicePfc,
          get_function: apiFunctionGetPfcList,
          update_function: apiFunctionUpdateProviderPfc,
          delete_function: apiFunctionDeletePfc,
          add_function: apiFunctionSavePfc,
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);


    var res = await c.add(params, file);
    if (res.status === "ok") return new HPFC(res.result[0]);
    return apiError;
  }

  download(download_function, params) {
    // this.c = new Communication(
    //   apiServicePfc,
    //   apiFunctionGetPfc,
    //   apiFunctionUpdateProviderPfc,
    //   apiFunctionDeletePfc,
    //   apiFunctionSavePfc,
    //   download_function,
    //   {}
    // );

      let communication_params = {
          service: apiServicePfc,
          get_function: apiFunctionGetPfcList,
          update_function: apiFunctionUpdateProviderPfc,
          delete_function: apiFunctionDeletePfc,
          add_function: apiFunctionSavePfc,
          download_function: download_function,
          params: null,
      };
      let c = getCommunicationClass(communication_params);
    let res = c.download(params);
    return res;
  }

  getMediumType(medium_type) {
    if (medium_type === 1) return "Gas";
    else return "Strom";
  }

  getEnergyDescription() {
    if (this.medium_type === 0) return "Strom";
    if (this.medium_type === 1) return "Gas";
    else return emptyValue;
  }

  getIsDefault(is_default) {
    if (is_default === 0) return "Inaktiv";
    else return "Aktiv";
  }

  getKeepUpdated(keep_updated) {
    if (keep_updated === 0) return "Nein";
    else return "Ja";
  }

  germanDateFormat(date) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    date = new Date(date);
    let res = date.toLocaleDateString("de-DE", options);
    if (res === "01.01.1970") return emptyValue;
    return res;
  }

  timeFormat(time) {
      if (validateVar(time)) {
          let time_split = time.split(":");
          if (time_split.length > 0) {
              if (time_split.length === 1) return time_split[0] + ":00";
              return time_split[0] + ":" + time_split[1];
          }
          return "";
      }
    return "";
  }

  getDisplayFileName() {
    if (this.file_name.length > 0)
      return this.file_name.substring(0, 18) + "..";
    return "";
  }
}

export default HPFC;
