import getDurationTranslation from "./translateDuration";
import validateVar from "./validation/validateVariable";


let not_allowed = ["false", false, "-/-", "- / -"];

function handleExtentionPeriod(extention, reverse = false) {
    let split;
    let translated;

    if (not_allowed.includes(extention)) return "- / -";
    if (reverse == false){
        if (!validateVar(extention)) return "- / -";
        if(extention === "none") return "Keine Verlängerung";
        split = extention.split(' ');
        if(validateVar(split[1])) translated = getDurationTranslation(split[1]);
        return split[0] + " " + translated
    } else {
        if (!validateVar(extention)) return null;

        split = extention.split(' ');
        if(isNaN(split[0])) return "none";
        else {
            if(validateVar(split[1])) translated = getDurationTranslation(split[1], true);
            return split[0] + " " + translated
        }
    }

}
export default handleExtentionPeriod;