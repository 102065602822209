import validateVar from "./validation/validateVariable";
import {deHashVar} from "./handleHashedVariable";
import {apiUrlNew, project_name, project_names} from "./global";
import is_true from "./is_true";

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));
const employee_id = deHashVar(localStorage.getItem("employeeSecurityToken"));
let consultant_employee_id = deHashVar(localStorage.getItem("consultantEmployeeSecurityToken"));
let consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));


function getAgencyID(params, agency_id = false, with_employee_id = true){
    if (!validateVar(params)) params = {};


    if (project_name === project_names.enermakler) {
        if(apiUrlNew === 'https://api-test.b2b-energievertrieb.de/') params.overwrite_owner_id = 12;
        if (validateVar(consultant_employee_id) && is_true(with_employee_id)) params.employee_id = consultant_employee_id;
        //if on guest page, get IDs from URL
        // if(!validateVar(consultant_id)){
        //     consultant_id = 7;
        // }
        //
        // //if on guest page, get IDs from URL
        // if(!validateVar(consultant_employee_id) && is_true(with_employee_id)){
        //     params.employee_id = 9;
        // }


        if (validateVar(consultant_id)) {
            if (agency_id) params.agency_id = consultant_id;
            else params.consultant_id = consultant_id;
        }
    } else if (project_name === project_names.wattline) {
        if (validateVar(consultant_employee_id) && is_true(with_employee_id)) params.employee_id = consultant_employee_id;
        if (validateVar(consultant_id)) {
            if (agency_id) params.agency_id = consultant_id;
            else params.affiliate_id = consultant_id;
        }
    } else { // EVU PROJECT
        if(apiUrlNew === 'https://api-test.b2b-energievertrieb.de/') params.overwrite_owner_id = 10;
        if (validateVar(employee_id) && is_true(with_employee_id)) params.employee_id = employee_id; //
        if (validateVar(supplier_id)) {
            if (agency_id) params.agency_id = supplier_id;
            else params.supplier_id = supplier_id;
        }
    }
    return params;
}
export default getAgencyID;