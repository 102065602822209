import React from 'react';
import is_true from "../../Functions/is_true";

function GreyLinkButton(props)  {
    let {disabled, onClick, text, classes} = props;
    let on_click_event = (is_true(disabled)) ? null : onClick;
    return(<div className={'text-center ' + classes}>
        <span className='grey-link-button' onClick={on_click_event}>{text}</span>
    </div>);
}

export default GreyLinkButton;