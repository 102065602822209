import "whatwg-fetch";
import {
    apiBearer,
    apiContentType,
    apiFunctionGetStreets,
    apiServiceNetfee,
    apiURL,
    apiUser,
    apiUserAuthToken
} from "./global";
async function getStreets(postalCode, city) {
  return await fetch(apiURL, {
    method: "POST",
    headers: {
      Authorization: apiBearer,
      "Content-Type": apiContentType
    },
    http_errors: false,
    body: JSON.stringify({
      user: apiUser,
      auth_token: apiUserAuthToken,
      service: apiServiceNetfee,
      function: apiFunctionGetStreets,
      param: {
        postal_code: postalCode,
        city: city
      }
    })
  })
    .then(res => res.json())
    .then(result => {
      if (result.status === "ok") {
        let streets_result = result.result;
        var streets = [];
        for (var i = 0; i < streets_result.length; i++) {
          // streets to array
          if (streets.indexOf(streets_result[i].streetName) === -1) {
            streets.push(streets_result[i].streetName);
          }
        }
        return streets;
      } else {
        return "ERR";
      }
    })
    .catch(function(error) {
      // catch
      console.log("Request failed", error);
    });
}

export default getStreets;
