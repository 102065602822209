import React from 'react';
import validateVar from "../../Functions/validation/validateVariable";
import cn from "classnames";

function GreyLinkFeedbackModalButton(props) {
    let on_click = props.onClick;
    let is_disabled = (validateVar(props.disabled) && props.disabled);
    if (!is_disabled) is_disabled = (props.is_loaded === false);
    if (is_disabled) on_click = null;
    return (<div className={props.classes}>

        <span className={'grey-link-button' + cn({' disabled-row noselect': is_disabled, ' cursor-pointer': !is_disabled})}
              onClick={on_click}>{(props.icon) && props.icon}{props.text}</span>
    </div>);
}

export default GreyLinkFeedbackModalButton;