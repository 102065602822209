import React from 'react';
import {Card, CardBody, CardHeader, Col, Modal, Row} from "reactstrap";
import validateVar from "../../../Functions/validation/validateVariable";
import handleFormatPrice from "../../../Functions/handleFormatPrice";
import PrimaryButton from "../../../Displays/Buttons/PrimaryButton";
import {
    appURL,
    dev_url, dev_urls, live_urls,
    project_name,
    project_names,
    stage_url, stage_urls,
    tarif_optimum_url,
    teag_optimum_url,
    user_roles
} from "../../../Functions/global";
import {RegisterPath} from '../../../../globalNavigation';
import InputHandler from "../../../Inputs/InputHandler";
import Validation from "../../../Objects/Components/validation";
import getAgencyID from "../../../Functions/getAgencyID";
import dataToSelectField from "../../../Functions/dataToSelectField";
import cn from 'classnames';

let inital_form_data = {
    user_role: '',
    email: '',
    teams_multiselect: [],
    team_select: '',
}

class CreateUserModal extends React.Component {
    state = {
        form_data: inital_form_data,
        error_messages: {},
        show_modal: false,
        loading: false,
    };

    change_form_data = (name, value) => {
        let {company, team_list} = this.props;
        let {form_data} = this.state;
        if(validateVar(company) && company.features.includes('team') && name === 'user_role') form_data['team_select'] = team_list[0].id; //set default to "Intern" team
        form_data[name] = value;
        this.setState({form_data: form_data});
    }

    toggle_modal = () => {
        let {team_list} = this.props;
        let {show_modal} = this.state;
        if (!show_modal) {
            //inital_form_data gets overriden by values so on toggle modal it has to be reset
            inital_form_data.email = '';
            inital_form_data.user_role = '';
            inital_form_data.teams_multiselect = [];
            if(validateVar(team_list)){
                inital_form_data.team_select = team_list[0].id; //set default to "Intern" team
            }
            this.setState({
                form_data: inital_form_data,
                error_messages: {},
                loading: false,
            });
        }
        this.setState({show_modal: !show_modal})
    };

    turn_off_modal() {
        this.setState({show_modal: false});
    };

    submit = () => {
        const {employees, company} = this.props;
        const {form_data} = this.state;
        const {email, user_role, teams_multiselect, team_select} = form_data;

        // validation starts
        let special_cases = {// define special cases for error validation
            email: [{case_name: "compare_values", compare_value: employees}, {case_name: "email"}],
            user_role: {case_name: "select"},
        };

        if(project_name === project_names.wattline) {
            special_cases.teams_multiselect = {can_be_empty: true}
        }

        if(!(validateVar(company.features) && company.features.includes('team'))){//if feature is not avaliable, make teams not required for validation
            special_cases.team_select = {can_be_empty: true}
        }

        // call abstract error validation and set values in state
        let validation_result = new Validation(form_data, special_cases);
        this.setState({error_messages: validation_result.error_messages});
        // validation end
        if (!validation_result.has_error) {
            let url = window.location.href;
            let register_url = appURL;
            if (url.includes("tarifoptimum")) { // tarif optimum // todo abstract
                register_url = tarif_optimum_url;
            } else if (url.includes("teag")) {
                register_url = teag_optimum_url;
            } else if (url.includes("dev") || url.includes("localhost")) { // dev version or local
                register_url = dev_urls[project_name];
                // activatio-n_url = dev_url + registration_page;
            } else if (url.includes("stage")) { // stage version
                register_url = stage_urls[project_name];
                // activation_url = stage_url + registration_page;
            } else { // live version
                register_url = live_urls[project_name];
            }
            register_url = register_url + RegisterPath;
            let data = {
                email: email,
                role_id: user_role,
                activation_url: register_url
            };
            if(project_name === project_names.wattline) {
                data.teams_multiselect = teams_multiselect;
            }else if(validateVar(company) && company.features.includes('team')){
                data.teams = [team_select];
            }
            data = getAgencyID(data, true);
            this.props.add_employee(data);
            this.setState({loading: true});
        }
    };

    render() {
        const {company, team_list} = this.props;
        const {form_data, loading, show_modal, error_messages} = this.state;
        const {email, user_role, teams_multiselect, team_select} = form_data;
        let submit_disabled_ = loading;
        let submit_btn_text = "Nutzer hinzufügen";
        let warning_text = "";

        if(project_name === project_names.enermakler){
            if (validateVar(company) && company.plan_id === "standard") { // cant add any user
                submit_disabled_ = true;
                submit_btn_text = "Keine weiteren Nutzer möglich";
                warning_text = "Mit Ihrem Paket können keine neuen Nutzer angelegt werden. Bitte melden Sie sich bei uns um Ihr Paket zu upgraden"
            } else if (validateVar(company)) {
                warning_text = "Sie haben bereits " + company.employee_used_count + " von " + company.max_employees_sum + " Nutzern angelegt. Jeder weitere Nutzer wird mit monatlich " + handleFormatPrice(company.unit_employees_price + "", 2, "€") + " berechnet.";
            } else submit_disabled_ = true;
        }

        return (<Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={show_modal}
            toggle={() => this.toggle_modal()}>
            <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent modal-title mb-0">
                        <Row>
                            <Col lg={6}>
                                <h2 className={'mb-0'}>Nutzer hinzufügen</h2>
                            </Col>
                            <Col lg={6}>
                                <button onClick={() => this.toggle_modal()} className={'close'}><i
                                    className={'ni ni-fat-remove close-button'}/></button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody className="px-lg-4 py-lg-4">
                        <InputHandler
                            content_title='E-Mail'
                            type='text'
                            name='email'
                            value={email}
                            placeholder='E-Mail'
                            on_change={this.change_form_data}
                            error_state={error_messages.email_error}
                        />

                        <InputHandler
                            content_title="Nutzer Rolle"
                            type="select"
                            name="user_role"
                            value={user_role}
                            on_change={this.change_form_data}
                            is_loaded={true}
                            select_options={user_roles}
                            error_state={error_messages.user_role_error}
                            input_wrapper_classes={cn({'pb-3': project_name === project_names.ehub})}
                        />

                        {project_name === project_names.wattline && validateVar(team_list) && <InputHandler
                            type='multiselect'
                            content_title='Teams'
                            id={"teams_multiselect"}
                            name='teams_multiselect'
                            on_change={(name, selected) => this.change_form_data(name, selected)}
                            disabled={false}
                            multiselect_options={dataToSelectField("id", "name", team_list)}
                            value={teams_multiselect}
                            input_wrapper_classes={'pb-3'}
                        />}

                        {validateVar(company) && company.features.includes('team') && <InputHandler
                            type='select'
                            content_title='Team'
                            id="team_select"
                            name='team_select'
                            on_change={(name, selected) => this.change_form_data(name, selected)}
                            disabled={user_role !== 3 && user_role !== '3'} //only enable when the user role === user (not admin, not manager)
                            select_options={dataToSelectField("id", "name", team_list, null, true)}
                            value={team_select}
                            input_wrapper_classes={'pb-3'}
                            remove_empty_option
                        />}

                        {/*TODO Alex sagt: "Den Text erstmal auskommentieren, Er will dafür was anderes schreiben weil der bisheriger Text immer Probleme mit der Variablen darstellung hatte */}
                        {/*{project_name === project_names.enermakler && <div className='static-warning-notification grey mb-3'>*/}
                        {/*    <b>Achtung!</b> <br/> Durch das hinzufügen von Nutzern können zusätzliche Kosten*/}
                        {/*    anfallen.<br/>*/}
                        {/*    {warning_text}*/}
                        {/*</div>}*/}

                        <div className="text-center">
                            <PrimaryButton id='createUserSubmit'
                               onClick={() => this.submit()}
                               disabled={submit_disabled_}
                               value={submit_btn_text}
                               loading={loading}
                               classes='btn-modal'/>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Modal>)
    }
}

export default CreateUserModal;