import React from 'react';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import Skeleton from "react-loading-skeleton";

class LineGraphLoadingAnimation extends React.Component {

    render() {
        return <Card>
            <CardHeader>
                <Row className="align-items-center">
                    <Col>
                        <h2 className="mb-1">
                            Auftragseingang
                        </h2>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Skeleton width={'100'} height={452} />
            </CardBody>
        </Card>
    }
}

export default LineGraphLoadingAnimation;