
let initialState = null;

function team(state = initialState, action) {
    if (action.type === "INITIALIZE_TEAM") {
        return action.res;
    } else if (action.type === "RESET_TEAM") {
        return action.res;
    }
    else return state;
}

export default team;
