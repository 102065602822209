/*
    default for multiselect -> if is normal select set param
 */
function objectToArray(object, key) {

    let array = [];
    for (let index in object) {
        if (object.hasOwnProperty(index)){
            array.push(object[index][key]);
        }
    }
    return array;
}

export default objectToArray;
