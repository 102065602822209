import React from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    Row
} from "reactstrap";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import classnames from "classnames";
import validateEmail from "../../../module_components/Functions/validation/validateEmail";

class CredentialsModal extends React.Component {
    state = {
        show_modal: false,
        api_key: '',
        user: '',
        password: '',
        alert_border: '',
        show_feedback: '',
        integration: {company_url: '', logo_url: ''}
    };

    handleSubmit = () => {
        this.resetErrors();

        let user = this.state.user;
        let password = this.state.password;
        let api_key = this.state.api_key;

        if(this.props.api_key){
            if(api_key.length > 0){
                let accessApproved = true;

                if(accessApproved){//TODO if api key correct
                    this.props.addIntegration(this.state.integration.id)
                }
            }else{
                this.setState({alert_border: 'alert-border', show_feedback: 'd-block'});
            }
        }else{
            if (user.length > 0 && password.length >= 2){
                let accessApproved = validateEmail(user);

                if(accessApproved){//TODO if login correct
                    let data = {
                        id: this.state.integration.id,
                        password: password,
                        user: user
                    };
                    this.props.addIntegration(data)
                }else{
                    this.setState({alert_border: 'alert-border', show_feedback: 'd-block'});
                }
            }else{
                this.setState({alert_border: 'alert-border', show_feedback: 'd-block'});
            }
        }
    };

    toggleModal = (integration) => {
        this.resetErrors();
        if(typeof integration !== 'undefined') this.setState({integration: integration});
        this.setState({show_modal: !this.state.show_modal})
    };

    turnOffModal(){
        this.resetErrors();
        this.setState({show_modal: false});
    }

    resetErrors = () => {
        //Fehlermeldungen zurücksetzen falls es welche gibt
        if(this.state.alert_border === 'alert-border') this.setState({alert_border: '', show_feedback: ''});
    };

    render(){
        return(
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={this.state.show_modal}
                toggle={() => this.toggleModal()}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent modal-title">
                            <Row>
                                <Col lg={6}>
                                    <h2 className={'mb-0'}>Verbinden</h2>
                                </Col>
                                <Col lg={6}>
                                    <button onClick={() => this.toggleModal()} className={'close'} ><i className={'ni ni-fat-remove close-button'} /></button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="px-lg-4 py-lg-4">
                            <div className='integration-logo-wrapper pb-5 text-center'>
                                <a
                                    href={this.state.integration.company_url}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        id={"logo_image_display"}
                                        alt="..."
                                        src={this.state.integration.logo_url}
                                    />
                                </a>
                            </div>
                            <Form role="form">
                                {this.props.api_key ?
                                    <span id='userApiKey'>
                                        <label className="form-control-label">
                                            API Schlüssel
                                        </label>
                                        <FormGroup className={classnames({focused: this.state.focusedPassword})}>
                                          <InputGroup className={"input-group-merge input-group-alternative "+this.state.alert_border}>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id="api_key"
                                                placeholder="API Schlüssel"
                                                type="text"
                                                onFocus={() => this.setState({ focusedPassword: true })}
                                                onBlur={() => this.setState({ focusedPassword: false })}
                                                onChange={(e) => this.setState({api_key: e.target.value})}
                                            />
                                          </InputGroup>
                                          <div className={'fontsize-100 invalid-feedback '+this.state.show_feedback}>
                                              Der API Schlüssel ist ungültig. Überprüfen Sie bitte Ihre Eingaben.
                                          </div>
                                        </FormGroup>
                                    </span>

                                    :

                                    <span id='userLogin'>
                                        <FormGroup className={classnames("mb-3", {focused: this.state.focusedEmail})}
                                        >
                                          <InputGroup className={"input-group-merge input-group-alternative "+this.state.alert_border}>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                <i className="ni ni-email-83" />
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id="user"
                                                placeholder="E-Mail"
                                                type="text"
                                                onFocus={() => this.setState({ focusedEmail: true })}
                                                onBlur={() => this.setState({ focusedEmail: false })}
                                                onChange={(e) => this.setState({user: e.target.value})}
                                            />
                                          </InputGroup>
                                        </FormGroup>
                                        <FormGroup className={classnames({ focused: this.state.focusedPassword })}>
                                          <InputGroup className={"mb-2 input-group-merge input-group-alternative "+this.state.alert_border}>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                              </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id="password"
                                                placeholder="Passwort"
                                                type="password"
                                                onFocus={() => this.setState({ focusedPassword: true })}
                                                onBlur={() => this.setState({ focusedPassword: false })}
                                                onChange={(e) => this.setState({password: e.target.value})}
                                            />
                                          </InputGroup>
                                          <div className={'fontsize-100 invalid-feedback '+this.state.show_feedback}>
                                              Die Kombination aus E-Mail und Passwort ist falsch. Überprüfen Sie bitte Ihre Eingaben.
                                          </div>   
                                        </FormGroup>
                                    </span>
                                }
                                <div className="text-center">
                                    <PrimaryButton id='submitCredentialsModal' disabled={this.state.isDisabled} onClick={() => this.handleSubmit()} value='Verbinden' classes='btn-modal' />
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        );
    }
}

export default CredentialsModal;