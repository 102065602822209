/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';
import {
    deleteTariffs,
    initializeTariffs,
    updateTariffTable,
    copyTariff, redirect,
} from '../../../actions';
import { connect } from 'react-redux';
// reactstrap components
import {
    Card,
    Container,
    Row,
} from "reactstrap";
// core components
import SimpleHeader from "../../../module_components/Displays/Headers/SimpleHeader";
// custom components
import DropdownButton from "../../../module_components/Displays/Buttons/DropdownButton";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import {TariffStatus, handleTariff, newTariff} from "../../../globalNavigation";
import {deHashVar} from "../../../module_components/Functions/handleHashedVariable";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import Table from "../../../module_components/Displays/Tables/Table";

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));

const tariff_list = [
    {
        dataField: "tariff_id",
        text: "ID",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "name",
        text: "Name",
        sort: true,
        classes: 'linked-col eless-font-bold',
    },
    {
        dataField: "customer_type_display",
        text: "Kundentyp",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "medium_type_display",
        text: "Energie",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "last_type",
        text: "Zählertyp",
        sort: true,
        classes: 'linked-col'
    },
    {
        dataField: "lifecycle_status",
        text: "Status",
        sort: true,
        classes: 'linked-col'
    }
];

/*
    START CLASS
 */
class TariffsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownDisabled: true,
            dropdownOptions: ['delete', 'deactivate', 'activate', 'copy'],
            selectedIds: [],
            tariffs: [],
            is_loaded: false,
            table_columns: tariff_list
        };
    }

    componentDidMount() {
        this.props.initialize();
    }

    componentDidUpdate(prevProps, prevState) {
        const {tariffs} = this.props;
        if (validateVar(tariffs) && tariffs !== prevProps.tariffs) {
            this.setState({
                tariffs: tariffs,
                is_loaded: true
            })
        }
    }

    table_selection_change = (selected_ids) => { //3 parameter could be "rowIndex"
        let {dropdownDisabled, dropdownOptions} = this.state;
        if(selected_ids.length > 1) this.setState({dropdownOptions: ['delete']});
        else {
            let id = selected_ids[0];
            if(validateVar(id)){
                let selectedTariff = this.props.tariffs.find(x => x.id === id);
                    dropdownOptions = ['delete', 'activate', 'copy'];
                if(selectedTariff.lifecycle_status === "Online"){
                    dropdownOptions = ['delete', 'deactivate', 'copy']
                }
            }
        }
        //Enable / Disable options dropdown Button
        if(dropdownDisabled && selected_ids.length > 0) dropdownDisabled = false;
        else if(!dropdownDisabled && selected_ids.length === 0) dropdownDisabled = true;

        this.setState({dropdownOptions: dropdownOptions, selectedIds: selected_ids, dropdownDisabled: dropdownDisabled});
    };

    deleteFromTable = () => {
        let _selectedIDs = this.state.selectedIds;
        this.props.onDelete(_selectedIDs);
        this.table_selection_change([]);
    };

    copySelected = () => {
        let _selectedIDs = this.state.selectedIds;
        let data = {
            supplier_id: supplier_id,
            tariff_id: _selectedIDs[0]
        };
        this.props.onCopy(data);
        this.table_selection_change([]);
    };

    activateSelected = () => {
        let _selectedIDs = this.state.selectedIds;
        let tariff_id = _selectedIDs[0];
        window.location.href = TariffStatus + "/" + tariff_id;
        this.table_selection_change([]);
    };

    deactivateSelected = () => {
        let _selectedIDs = this.state.selectedIds;
        let data = {
            supplier_id: supplier_id,
            lifecycle_status: 0
        };
        this.props.onUpdate(data, _selectedIDs);
        this.table_selection_change([]);
    };

    render() {
        const {table_columns, is_loaded, tariffs, selectedIds} = this.state;

        return (
            <>
                <SimpleHeader name="Tarife" headerText="" />
                <Container className="mt--6 tariffs-table-wrapper" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <Table table_name={'ehub_tariffs'}
                                    data={tariffs}
                                    key_field={'tariff_id'}
                                    columns={table_columns}
                                    linked_col_click={(row) => this.props.redirect(handleTariff + '/' + row.id)}
                                    is_loaded={is_loaded}
                                    on_checkbox_click={this.table_selection_change}
                                    selected_ids={selectedIds}
                                    paginator_strings={['Tarife', 'Tarifen']}
                                    break_width='700'
                                    update_columns={(table_columns) => this.setState({table_columns: table_columns})}>
                                    <span className='responsive-button-wrapper'>
                                            <DropdownButton
                                                tooltipProp={"Tarife"}
                                                wrapper_classes='mr-3'
                                                disabled={this.state.dropdownDisabled}
                                                include={this.state.dropdownOptions}
                                                deleteSelected={() => this.deleteFromTable()}
                                                deactivateSelected={() => this.deactivateSelected()}
                                                activateSelected={() => this.activateSelected()}
                                                copySelected={() => this.copySelected()}
                                                classes={'table-header-options'} /></span>
                                    <span className='responsive-button-wrapper'>
                                            <PrimaryButton onClick={() => this.props.redirect(handleTariff+ newTariff)} disabled={false} value='Tarif hinzufügen' classes='mr-3' />
                                    </span>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

let mapStateToProps = function(state)   {
    return {
        tariffs: state.tariffs.tariff_list,
    }
};

let mapDispatchToProps = {
    initialize: initializeTariffs,
    onDelete: deleteTariffs,
    onUpdate: updateTariffTable,
    onCopy: copyTariff,
    redirect: redirect
};

let TariffContainer = connect(mapStateToProps, mapDispatchToProps)(TariffsTable);

export default TariffContainer;
