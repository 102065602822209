import {emptyValue} from "./global";
import isValidDateCustom from "./validation/validateDate";
import getTimeDiffInMinutes from "./getTimeDiffInMinutes";
import get_standardised_date from "./get_standardised_date";


export default function getTimeDiff(status, valid_till) {
    if (
        typeof valid_till === "undefined" ||
        valid_till === null ||
        status === "confirmed" ||
        status === "cancelled" ||
        status === "revoked"
    )
        return emptyValue;
    var dateNow = new Date();
    let validTillDate = new Date(get_standardised_date(valid_till));
    if (!isValidDateCustom(validTillDate)) return emptyValue;

    return getTimeDiffInMinutes(validTillDate, dateNow);
}
