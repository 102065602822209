import {emptyValue} from "./global";

function getClearingTypeDisplay(clearing_type) {
    if (clearing_type === "data_maintenance") return "Datenpflege";
    else if (clearing_type === "eeindividual_calculation") return "Kalkulation planen";
    else if (clearing_type === "eeindividual_result") return "Kalkulationsergebnisse prüfen";
    else if (clearing_type === "reminder") return "Erinnerung";
    else if (clearing_type === "contract_adjustment") return "Vertragsanpassung";
    else return emptyValue;
}

export default getClearingTypeDisplay;
