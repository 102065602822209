export const typeError = "danger";
export const typeSuccess = "success";

export const headlineError = "Achtung";
export const headlineSuccess = "Erfolg";

/* Inizialize data error*/
export const initializeDataError =
  "Es ist ein Fehler beim Laden der Daten aufgetreten. Versuchen Sie es bitte später erneut.";

/* Create User */
export const activateEmployeeError =
  "Der Registrierungstoken konnte nicht identifiziert werden.";
export const activateEmployeeSuccess =
  "Ihr Benutzerkonto wurde erfolgreich angelegt.";

/* Pfc */
export const addPfcError =
  "Die Pfc konnte nicht hochgeladen werden werden. Versuchen Sie es bitte später nocheinmal.";
export const addPfcSuccess = "Die Pfc(s) wurde(n) erfolgreich hochgeladen.";
export const removePfcSuccess = "Die Pfc(s) wurde(n) erfolgreich gelöscht.";
export const removePfcError = "Die Pfc konnte nicht gelöscht werden.";

/* Integrations */
export const removeIntegrationError =
  "Die Integration konnte nicht entfernt werden. Versuchen Sie es bitte später nocheinmal.";
export const removeIntegrationSuccess =
  "Die Integration wurde erfolgreich entfernt.";
export const addIntegrationSuccess =
  "Die Integration wurde erfolgreich hinzugefügt.";
export const addIntegrationError =
  "Die Integration konnte nicht hinzugefügt werden. Bitte Überprüfen Sie Ihre Eingaben nocheinmal.";

/* Api Internal Server Error */
export const internalServerError =
  "Die Verbindung zum Server ist fehlgeschlagen. Einige Funktionalitäten der Seite könnten eingeschränkt sein.";

/* Default */
export const messageChangesSaved = "Ihre Änderungen wurden gespeichert";
export const messageChangesNotSaved =
  "Es ist ein Fehler aufgetreten. Ihre Änderungen wurden nicht gespeichert";
