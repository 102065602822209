import Tariff from "./tariff";
import validateVar from "../Functions/validation/validateVariable";
import {
    apiCalculationManagementService,
    apiFunctionCalculateAllTariffs, apiFunctionCalculateNetFee, apiFunctionDownloadCalculation,
    apiPricingService, apiServiceNetfee
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import isEmptyObject from "../Functions/isEmptyObject";
import History from "./Components/history";

let calculation_vars = {
    coop: null,
    country: null,
    created_at: null,
    customer_segment: null,
    id: {
        margin_id: null,
    },
    max_margin: null,
    max_mean_consumption: {
        max_mean_consumption: null,
        max_mean_consumption_display: {
            function_name: 'getDisplayConsumption',
            params: [true]
        }
    },
    medium_type: null,
    min_margin: null,
    min_mean_consumption: {
        min_mean_consumption: null,
        min_mean_consumption_display: {
            function_name: 'getDisplayConsumption',
            params: [true]
        }
    },
    min_saving: null,
    updated_at: null,
};

class Calculation {
  /*
              Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
              Call Communication Class with matching global defined Vars for API Request
              return Array with all TariffCalculatedContainer Obj
         */
    constructor(data) {
        if (validateVar(data) && !isEmptyObject(data)) {
            // this.get_contract(contract_vars, data, data.history, []);
            let class_content = new History(calculation_vars, data, null, []);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

  static async calculate(params) {
     params = getAgencyID(params);
      let communication_params = {
          service: apiCalculationManagementService,
          get_function: apiFunctionCalculateAllTariffs,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);

    var res = await c.load();
      if (res.status === "ok")  {
          if (res.result === "[]") return []; // no tariffs found
          else{
              let tariffs = [];
              for (let index in res.result) {
                  if(res.result.hasOwnProperty(index)){
                      let tariff = res.result[index];

                      if (tariff.hasOwnProperty("supplier")) {
                          if (
                              tariff.supplier.hasOwnProperty("information") &&
                              typeof validateVar(tariff.supplier.information.logo_file_id)
                          ) {
                              let logo_id = tariff.supplier.information.logo_file_id;
                              // add the logo url to the result obj
                              var logo_url = await Tariff.getDownloadUrl(logo_id);
                          }
                      }
                      if (
                          tariff.hasOwnProperty("tariff_information") &&
                          typeof validateVar( tariff.tariff_information.labelling_file_id)
                      ) {
                          let label_id =  tariff.tariff_information.labelling_file_id;
                          var label_url = await Tariff.getDownloadUrl(label_id);
                      }
                      //add all files for this tariff
                      tariff.files = {
                          logo_url: logo_url,
                          label_url: label_url
                      };
                      tariffs.push(new Tariff(tariff)); // show tariffs
                  }
              }

              return tariffs;
          }
      }else{
          if (res.result === "Offer expired.") return "expired";
          return "ERR";
      }
  }

  static async getDownloadUrl(logo_id) {
      //todo?
    var file_url = await this.c.download({ id: logo_id }, "image/png", true);
    return file_url;
  }

  download(res_id) {
    // this.c = new Communication(
    //   apiPricingService,
    //   apiFunctionDownloadCalculation,
    //   apiFunctionDownloadCalculation,
    //   apiFunctionDownloadCalculation,
    //   apiFunctionDownloadCalculation,
    //   apiFunctionDownloadCalculation,
    //   {}
    // );
      let communication_params = {
          service: apiPricingService,
          get_function: "",
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: apiFunctionDownloadCalculation,
      };
      let c = getCommunicationClass(communication_params);
      let params = {
          offer_result_id: res_id,
          data_type: "download"
      };
      return c.download(params);
  }


    static async getNetFee(params){

        // let c = new Communication(
        //     apiServiceNetfee,
        //     apiFunctionCalculateNetFee,
        //     "",
        //     "",
        //     "",
        //     "",
        //     params
        // );
        params = getAgencyID(params);
        let communication_params = {
            service: apiServiceNetfee,
            get_function: apiFunctionCalculateNetFee,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionDownloadCalculation,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        return await c.load();
    }

    static async handle_calculation_actions(params, method, function_name, use_agency_id){
        params = getAgencyID(params, use_agency_id);

        let communication_params = {
            method: method, // GET / POST / PUT / DELETE  -> method
            function_url:  'wattline/calculation/'+function_name,
            params: params
        };

        let c = getCommunicationClass(communication_params);
        let res = await c.request_handler();
        if(validateVar(res.result)) return res.result;
        else return res;
    }
}

export default Calculation;
