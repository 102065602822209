import validateVar from "./validation/validateVariable";

export default function scrollToElement(element, delay = null, behavior = "smooth") {
    if (validateVar(element) && validateVar(window)) {
        window.requestAnimationFrame(() => {
            let offset = element.offsetTop;
            try {
                let bodyRect = document.body.getBoundingClientRect();
                let bodyStyle = window.getComputedStyle(document.body, null);

                // need to handle the padding for the top of the body
                let paddingTop = parseFloat(bodyStyle.getPropertyValue("padding-top"));

                let elementRect = element.getBoundingClientRect();
                offset = elementRect.top - paddingTop - bodyRect.top;
            } catch (err) {
                console.log("Beim Scrollen ist ein Fehler aufgetreten");
            }
            if (delay) {
                setTimeout(() => {
                    window.scrollTo({ top: offset, left: 0, behavior });
                }, delay);
            } else {
                window.scrollTo({ top: offset, left: 0, behavior });
            }
        });
    }
}