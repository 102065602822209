import React from 'react';
import {Modal} from "reactstrap";
import validateVar from "../Functions/validation/validateVariable";
import LoadingWheel from "./LoadingWheel";
import is_true from "../Functions/is_true";
import is_false from "../Functions/is_false";

class SubmitLoadingAnimationModal extends React.Component {
    render() {
        const {display, id} = this.props; //proeprty display: bool or string (message)
        let textDisplay = '';
        let show = false;
        if(validateVar(display) && !is_false(display)) {
            if(is_true(display)) textDisplay = 'Bitte warten Sie...';
            else textDisplay = display;
            show = true;
        }
        return (
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={show}
                id={id}
            >
                <div className="text-center py-5">
                    <LoadingWheel />
                    <h2 className='mb-0'>{textDisplay}</h2>
                </div>
            </Modal>
        );
    }
}

export default SubmitLoadingAnimationModal;