let initialState = null;

function redirect(state = initialState, action) {
  if (action.type === "REDIRECT_USER") {
    return action.res;
  } else {
    return state;
  }
}

export default redirect;
