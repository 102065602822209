let initialState = null;

function component_feedback(state = initialState, action) {
  if (action.type === "REQUEST_FINISHED") {
    return action.res;
  } else {
    return state;
  }
}

export default component_feedback;
