import React from 'react';
import {Row, Col} from "reactstrap";
import validateVar from "../../../../../Functions/validation/validateVariable";
class ProviderInformation extends React.Component {
    render() {
        let {tariff} = this.props;
        let {provider_information_company, company_description, provider_information_street, provider_information_house_number, provider_information_zip, provider_information_city} = tariff;
        let hasAddress = false;
        if(validateVar(provider_information_street) && validateVar(provider_information_house_number) && validateVar(provider_information_zip) && validateVar(provider_information_city)) hasAddress = true;
        return(
            <div className={"pt-4 px-4"}>
                <Row className='custom-border-bottom tariff-price-details-row'>
                    <Col className='pl-0'>
                        <b>Anbieter</b>
                    </Col>
                    <Col md={10}>
                        {validateVar(provider_information_company) ? provider_information_company : tariff.supplier_name} {hasAddress && '- ' + provider_information_street + ' ' + provider_information_house_number + ', ' + provider_information_zip + ' ' + provider_information_city}
                    </Col>
                </Row>

                <Row className='custom-border-bottom tariff-price-details-row'>
                    <Col className='pl-0'>
                        <b>Beschreibung</b>
                    </Col>
                    <Col md={10}>
                        {company_description}
                    </Col>
                </Row>

                {(validateVar(tariff.employee_count) && tariff.employee_count > 0) && <Row className='custom-border-bottom tariff-price-details-row'>
                    <Col className='pl-0'>
                        <b>Anzahl Mitarbeiter</b>
                    </Col>
                    <Col md={10}>
                        {tariff.employee_count_display}
                    </Col>
                </Row>}

                {(validateVar(tariff.customer_count) && tariff.customer_count > 0) && <Row className='custom-border-bottom tariff-price-details-row'>
                    <Col className='pl-0'>
                        <b>Anzahl Kunden</b>
                    </Col>
                    <Col md={10}>
                        {tariff.customer_count_display}
                    </Col>
                </Row>}

                {(validateVar(tariff.sales_revenue) && tariff.sales_revenue > 0) && <Row className='custom-border-bottom tariff-price-details-row'>
                    <Col className='pl-0'>
                        <b>Umsatz</b>
                    </Col>
                    <Col md={10}>
                        {tariff.sales_revenue_display} €/Jahr
                    </Col>
                </Row>}

                <Row className='tariff-price-details-row'>
                    <Col className='pl-0'>
                        <b>Broschüre</b>
                    </Col>
                    <Col md={10}>
                        {(validateVar(tariff.brochure_file_id) && validateVar(tariff.brochure_url)) ? <a target={"_blank"} className='eless-text-orange link'
                            href={tariff.brochure_url}>
                            <span className='black-link-button'>Download</span>
                        </a> : <span>Keine Datei hinterlegt</span>}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ProviderInformation;