let initialState = null;
function offer_result(state = initialState, action) {
  if (action.type === "UPDATE_OFFER_RES") {
    return action.res;
  } else if (action.type === "SAVE_RESULT") {
    return action.res;
  }else if(action.type === "OFFER_RESULT_RESET") {
    return action.res;
  } else {
    return state;
  }
}

export default offer_result;
