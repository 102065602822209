let initialState = null;

function calculated_tariffs(state = initialState, action) {
  if (action.type === "GET_TEST_CALCULATION_RESULT") {
    return action.res;
  } else {
    return state;
  }
}

export default calculated_tariffs;
