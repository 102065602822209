function handleFormatWattlineId(id, padding_left){
    let id_ = id + '';
    let insert_zeros = 8 - id_.length;
    let translated_id = padding_left;

    for(let zeros_added = 0; zeros_added < insert_zeros; zeros_added++){
        translated_id = translated_id + '0';
    }

    return translated_id + id_;
}

export default handleFormatWattlineId;