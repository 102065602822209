/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AuthHeader from "../../../module_components/Displays/Headers/AuthHeader";
import {apiError} from "../../../module_components/Functions/global";
import {LoginPath} from "../../../globalNavigation";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import {redirect, resetEmployee, resetPasswordEmployee} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import Validation from "../../../module_components/Objects/Components/validation";

class PasswordRecovery extends React.Component {
    state = {
        form_data: {
            email: ''
        },
        error_messages: {},
        disabled_submit: false,
    };

    componentDidMount() {
        document.body.classList.add("eless-bg");
    }

    componentWillUnmount() {
        document.body.classList.remove("eless-bg");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const employee = this.props.employee;
        if (employee !== prevProps.employee && employee === apiError) {
            this.props.resetEmployee();
            this.setState({disabled_submit: false});
        }
    }

    handle_key_press = (e) => {//Allow submit on "Enter" press
        if (e.key === 'Enter') {
            this.handle_submit();
        }
    };

    go_to_login = () => {
        this.props.redirect(LoginPath)
    };

    change_form_data = (name, value) => {
        let {form_data} = this.state;
        form_data[name] = value;
        this.setState({form_data: form_data});
    }

    handle_submit = () => {
        const {form_data} = this.state;

        // validation start
        // define special cases for error validation
        let special_cases = {
            email: {case_name: "email"},
        };
        // call abstract error validation and set values in state
        let validation_result = new Validation(form_data, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        if (!validation_result.has_error) {
            let data = {
                reset_url: window.location.origin+"/neues-passwort",
                email: form_data.email
            };
            this.setState({disabled_submit: true});
            this.props.onSubmit(data);
        }
    };

    render() {
        const {error_messages, form_data, disabled_submit} = this.state;
        const {email} = form_data;

        return (
            <>
                <div className="main-content" ref="mainContent">
                    <AuthHeader
                        title="Passwort zurücksetzen"
                        lead=""
                    />
                    <Container className="mt--8 pb-5">
                        <Row className="justify-content-center">
                            <Col lg="5" md="7">
                                <Card className="bg-secondary border-0 mb-0">
                                    <CardBody className="px-lg-5 py-lg-5">
                                        <div className="text-center text-muted mb-4">
                                            <small>Geben Sie bitte Ihre E-Mail Adresse ein.</small>
                                        </div>
                                        <InputHandler
                                            type='text'
                                            name='email'
                                            value={email}
                                            placeholder='E-Mail'
                                            on_change={this.change_form_data}
                                            prefix_addon_class='email-83'
                                            on_key_down={this.handle_key_press}
                                            error_state={error_messages.email_error}
                                        />
                                        <div className="text-center">
                                            <PrimaryButton disabled={disabled_submit}
                                                           onClick={() => this.handle_submit()}
                                                           value='Senden'
                                                           classes='btn-modal w-100'/>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Row className="mt-3">
                                    <Col xs="6">
                                        <span className="text-light cursor-pointer"
                                              onClick={() => this.go_to_login()}>
                                            <small>Zum Login</small>
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}


let mapStateToProps = function (state) {
    return {
        employee: state.employee
    }
};

let mapDispatchToProps = {
    onSubmit: resetPasswordEmployee,
    resetEmployee: resetEmployee,
    redirect: redirect
};

let PasswordRecoveryContainer = connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);

export default PasswordRecoveryContainer;


