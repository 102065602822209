function copy_with_no_reference(value) {
    if (typeof value === "object") { // works for array and objects
        // delete ref for objects and arrays
        return (JSON.parse(JSON.stringify(value)));
    } else if (typeof value === 'string' || value instanceof String || Number.isInteger(value)) {
        // delete ref for strings
        return (' ' + value).slice(1);
    } else {
        // return with no copy
        return value;
    }
}
export default copy_with_no_reference;
