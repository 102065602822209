import Communication from "./Components/Communication/communication";
import { deHashVar } from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import {
    apiError,
    apiFunctionGetPlattformSettings,
    apiFunctionUpdatePlattformSettings, apiPlatformService, project_name, project_names
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
const consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));

class Plattform {
    static async update_plattform(params) {
        params = getAgencyID(params, true);
        // this.c = new Communication(
        //     apiPlatformService,
        //     apiFunctionGetPlattformSettings,
        //     apiFunctionUpdatePlattformSettings,
        //     "",
        //     "",
        //     "",
        //     {}
        // );
        let communication_params = {
            service: apiPlatformService,
            get_function: apiFunctionGetPlattformSettings,
            update_function: apiFunctionUpdatePlattformSettings,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: null,
        };
        this.c = getCommunicationClass(communication_params);

        let res = await this.c.update(params);
        if (res.status === "ok") {
            return res;
        }
        else return apiError;
    }


    static async get_plattform_settings(ids) {
        let params = {};
        ids = (validateVar(ids)) ? ids :  getAgencyID({}, true);
        if (validateVar(ids.agency_id) && project_name !== project_names.ehub && project_name !== project_names.wattline) {
            params.agency_id = ids.agency_id;
        }else {
            params.domain = window.location.hostname;
        }
        let function_url = "platform/settings/0";
        let communication_params = {
            method: "GET", // GET / POST / PUT / DELETE  -> method
            function_url:  function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        let res =  await c.request_handler();
        if(res !== apiError) return res;
        else return apiError;
    }


    static async start_portal_session(agency_id) {
        let params = {};
        if (validateVar(agency_id)) {
            agency_id = deHashVar(agency_id);
            params.agency_id =  agency_id;
        }else {
            params.domain = window.location.hostname;
        }

        // this.c = new Communication(
        //     apiPlatformService,
        //     apiFunctionGetPlattformSettings,
        //     apiFunctionUpdatePlattformSettings,
        //     "",
        //     "",
        //     "",
        //     params
        // );
        let communication_params = {
            service: apiPlatformService,
            get_function: apiFunctionGetPlattformSettings,
            update_function: apiFunctionUpdatePlattformSettings,
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        this.c = getCommunicationClass(communication_params);


        let res = await this.c.load();
        if (res.status === "ok") {
            return res.result;
        }
        else return apiError;
    }
}

export default Plattform;
