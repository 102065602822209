import {
    apiElectricityProfileService, apiError,
    apiFunctionDownLoadLp,
    apiFunctionPredictionUpdate,
    apiFunctionPredictLoadProfile, apiFunctionPredictStandardProfile, apiGasProfileService, apiServiceLoadProfile,
    apiServicePrediction
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
class uploadLoadProfile {
  /*
              Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
              Call Communication Class with matching global defined Vars for API Request
              return Array with all TariffCalculatedContainer Obj
         */
  static async predict_lp(params, file) {
        params = getAgencyID(params);
        let communication_params = {
              service: apiServicePrediction,
              get_function: "",
              update_function: "",
              delete_function: "",
              add_function: apiFunctionPredictLoadProfile,
              download_function: "",
              params: null,
        };
        let c = getCommunicationClass(communication_params);
        var res = await c.add(params, file);
        if (res.status === "ok")  return res;
        else return apiError;
  }

    static async receive_load_profile(params, file){
        let function_url = "mock/receive_load_profiles";
        let communication_params = {
            method: 'POST',
            function_url: function_url,
            params: params,
        };
        let c = getCommunicationClass(communication_params, file);
        let result = await c.request_handler();
        if(result === 'token_invalid')  return result;
        else if(result === 'customer_invalid')  return result;
        else if(result === 'els_key_invalid')  return result;
        else if(result === 'format_invalid')  return result;
        else if (result !== apiError) return result;
        else return apiError;
    }


    static async update_prediction(params, file = null) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiServicePrediction,
            get_function: apiFunctionPredictionUpdate,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);

        var res = null;
        if (file === null) res = await c.load();
        else  res = await c.add(params, file);
        if (res.status === "ok") return res;
        else return apiError;
    }



    static async download_load_profile(params) {
        params.data_type = "download";
        params.type = "raw";
        let communication_params = {
            service: apiServiceLoadProfile,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionDownLoadLp,
            params: null,
            version:  "2.1",
        };
        let c = getCommunicationClass(communication_params);
       return await c.download(params);
    }

    static async predict_default_lp(params) {
        params  = getAgencyID(params);
        let service_name = apiElectricityProfileService;
        if (params.medium_type === "GAS") service_name = apiGasProfileService;
            let communication_params = {
                service: service_name,
                get_function: apiFunctionPredictStandardProfile,
                update_function: "",
                delete_function: "",
                add_function: "",
                download_function: "",
                params: params,
            };
        let c = getCommunicationClass(communication_params);
        var res = await c.load(params);
        if (res.status === "ok") return res;
        else return apiError;
    }
}

export default uploadLoadProfile;
