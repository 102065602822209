import React from "react";
import Skeleton from 'react-loading-skeleton';
import validateVar from "../Functions/validation/validateVariable";
import cn from 'classnames';

const TableLoadingAnimation = (props) => {

    let showRows = 4;
    if(validateVar(props.showRows) && props.showRows > 0) showRows = props.showRows;

    let tableRows = [];

    for (let i = 0; i < showRows; ++i) {
        tableRows.push(<tr key={i} className="custom-table-row">
            <td className="checkbox-column">
                <Skeleton height={20} width={20} />
            </td>
            <td ><Skeleton height={20} width={100} /></td>
            <td><Skeleton height={20} width={100}/></td>
            <td><Skeleton height={20} width={100}/></td>
            <td><Skeleton height={20} width={100}/></td>
            <td><Skeleton height={20} width={100}/></td>
            <td><Skeleton height={20} width={100}/></td>
        </tr>)
    }

    return(
        <div className='loading-animation-wrapper'>
            <div className="card mb-0">
                <div className={"table-responsive custom-table"+cn({'py-4': !props.simple_table, 'pt-4': !props.simple_table})}>
                    {!props.simple_table && <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-5">
                                <div className="table-title card-title"><Skeleton /></div>
                            </div>
                            <div className="text-right col-sm-12 col-md-9 col-lg-7">
                                <Skeleton />
                            </div>
                        </div>
                    </div>}
                    <div className="react-bootstrap-table">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>
                                    <Skeleton height={20} width={20} />
                                </th>
                                <th className="sortable"><Skeleton height={20} width={100} /><span className="order-4" /></th>
                                <th className="sortable"><Skeleton height={20} width={100}/><span className="order-4" /></th>
                                <th className="sortable"><Skeleton height={20} width={100}/><span className="order-4" /></th>
                                <th className="sortable"><Skeleton height={20} width={100}/><span className="order-4" /></th>
                                <th className="sortable"><Skeleton height={20} width={100}/><span className="order-4" /></th>
                                <th className="sortable"><Skeleton height={20} width={100}/><span className="order-4" /></th>
                            </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                            </tbody>
                        </table>
                    </div>
                    {!props.simple_table && <div className="row react-bootstrap-table-pagination m-0">
                        <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                            <div className="dataTables_length" id="datatable-basic_length"><Skeleton height={30}
                                                                                                     width={200}/></div>
                        </div>
                        <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                            <ul className="pagination react-bootstrap-table-page-btns-ul">
                                <li className="disabled page-item" title="previous page"><Skeleton circle={true}
                                                                                                   height={30}
                                                                                                   width={30}/>
                                </li>
                                <li className="active page-item" title="1"><Skeleton circle={true} height={30}
                                                                                     width={30}/></li>
                                <li className="disabled page-item" title="next page"><Skeleton circle={true} height={30}
                                                                                               width={30}/>
                                </li>
                            </ul>
                        </div>
                    </div>}
                </div>
            </div>

        </div>)
};

export default TableLoadingAnimation;