import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";

let margin_vars = {
    custom_margin_limits: null,
    custom_margin_limit: {
        function_name: "get_margins",
        params: ["%%custom_margin_limits"]
    },
    electricity: null,
    electricity_margin: {
        function_name: "get_margins",
        params: ["%%electricity"]
    },
    custom_margin_negativ_limit: {
        function_name: "get_margins",
        params: ["%%electricity"]
    },
    gas: null,
    gas_margin: {
        function_name: "get_margins",
        params: ["%%gas"]
    },
    custom_provision_limit: {
        function_name: "get_margins",
    },
};


class Margins {
    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(margin_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }
}

export default Margins;
