import { deHashVar } from "../Functions/handleHashedVariable";
import {
    apiBearer,
    apiCalculationManagementService,
    apiContentType,
    apiFunctionCreatePortalSession
} from "../Functions/global";
const consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));

class ChargeBee {
    static async start_portal_session() {
        return fetch('https://api2.eless.de', {
            method: "POST",
            headers: {
                Authorization: apiBearer,
                "Content-Type": apiContentType
            },
            http_errors: false,
            body: JSON.stringify({
                service: apiCalculationManagementService,
                function: apiFunctionCreatePortalSession,
                param: {
                    customer_id: consultant_id
                }
            })
        }).then((res) => res.json()).then(res => {
            if (res.status === "ok"){
                return res.result;
            } else {
                return "ERR"; // error token expired will be shown in modal -> no feedback needed
            }
        });
    }
}

export default ChargeBee;
