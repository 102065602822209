import {get_logo_from_company, plattform_data_array} from "./global";
import validateVar from "./validation/validateVariable";
import setFavIcon from "./setFavIcon";
import handleCssVariable from "./handleVarNameCss";

/*
   setCustomerDataToCssFromLocalStorage or GetDataFromLocalStorage
 */
function handleCustomData(get_data = false, set_storage_data = null) {
    let data = {};
    let set = false;
    if (validateVar(set_storage_data)) set = true;
    plattform_data_array.map((item) => {
        let storage_item = localStorage.getItem(item);
        if (set) { // setting the colors
            let defaultName = handleCssVariable(item, false, true);
            storage_item = set_storage_data[defaultName] + "";
            if (!storage_item.includes("#") && item.includes("color")) { // if the color has no #
                storage_item = "#" + storage_item;
            }
            if (validateVar(storage_item))localStorage.setItem(item, storage_item); // set localStrorage if valid var
        }
        if (validateVar(storage_item)) {
            data[item] = storage_item;
            if (item.includes("color")){ // set colors vars in css file
                document.documentElement.style.setProperty(item, storage_item);
            }else {
                if (item === "favicon_id" && !get_logo_from_company) { // set fav icon
                    setFavIcon(storage_item)
                } else if (item === "name") { // set title of the page
                    document.title = storage_item;
                }
            }
        }
    });
    if (get_data) return data;
}

export default handleCustomData;
