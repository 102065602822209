import React from 'react';
import "./Tree.css";
import {Card, CardBody, CardHeader, Col, Modal, Row} from "reactstrap";
import {globalAttributes} from "../../../Functions/global";
import validateVar from "../../../Functions/validation/validateVariable";
import is_true from "../../../Functions/is_true";

class AllAttributeModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_open: false,
            is_global: false,
            used_ids: []
        };
    }

    componentDidMount() {
        if (this.props.collapsed) {
            this.setState({is_open: false})
        }
    }

    get_used_attribute_ids = (tree_attributes) => {
        let used_ids = [];
        for (let attribute of tree_attributes) {
            used_ids.push(attribute.attribute_id);
        }
        return used_ids;
    };

    toggleModal = (group = null, is_global = false) => {
        let used_ids = [];
        let group_id = null;
        if (validateVar(group)) {
            used_ids = this.get_used_attribute_ids(group.tree_attributes);
            group_id = group.id;
        }

        this.setState((prevState) => {
            return {
                is_open: !prevState.is_open,
                used_ids: used_ids,
                group_id: group_id,
                is_global: is_global
            }
        })
    };

    createAttributeAddField(attribute) {
        return (
            <Row className={'bg-secondary AddAttributField'}
                 onClick={() => {
                     this.add_attribute_to_group(attribute);
                     this.toggleModal();
                 }}>
                <Col xs="10" md="11" className={"InfoAttributField"}>
                    <div>
                        <h2>{attribute.name}</h2>
                        <div>{attribute.description}</div>
                    </div>
                </Col>
                <Col xs="2" md="1" className={"IconAddVerticalAlignment"}>
                    <div>+</div>
                </Col>
            </Row>
        )
    }

    createAttributeDisabledField(attribute) {
        return (
            <Row className={"bg-lighter DisableAttributField"}>
                <Col xs="10" md="11" className={"InfoAttributField"}>
                    <div>
                        <h2>{attribute.name}</h2>
                        <div>{attribute.description}</div>
                    </div>
                </Col>
            </Row>
        )
    }

    add_attribute_to_group = (attribute) => {
        let {group_id, is_global} = this.state;
        let data = {
            attribute: attribute,
            group_id: group_id,
            global: is_global
        };
        this.props.add_attribute(data);
    };

    render() {
        let {listAttributes} = this.props;
        let {is_open, used_ids, is_global} = this.state;
        let attribute_type = (is_true(is_global)) ? "global_attributes" : "attributes";
        let attributes = listAttributes[attribute_type];
        const listAttributesArr = [];
        for (let key in attributes) {
            if (attributes.hasOwnProperty(key)){
                let attribute = attributes[key];
                listAttributesArr.push(attribute);
            }
        }
        return (
            <div className={"AttributModal"}>
                <Modal
                    className={'modal-dialog-centered ModalDialogWidth'}
                    isOpen={is_open}
                    toggle={() => this.toggleModal()}>
                    <div className={['modal-body', 'p-0'].join(' ')}>
                        <Card className="bg-neutral border-0 mb-0">
                            <CardHeader className="modal-title">
                                <Row>
                                    <Col xs={6}>
                                        <h2 className={'mb-0'}>Kriterium hinzufügen</h2>
                                    </Col>
                                    <Col xs={6}>
                                        <button onClick={() => this.toggleModal()} className={'close'}><i
                                            className={'ni ni-fat-remove close-button'}/></button>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <CardBody className={"CardBody"}>
                                {
                                    listAttributesArr.map((attribute, index) => {
                                        if (used_ids.includes(attribute.attribute_id)) {
                                            return (
                                                <div key={index}>
                                                    {this.createAttributeDisabledField(attribute)}
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={index}>
                                                    {this.createAttributeAddField(attribute)}
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default AllAttributeModal;
