import React from "react";

class toggleButton extends React.Component {
    render() {
        const {id, disabled, checked, clicked} = this.props;
        return (<label className="custom-toggle">
            <input type="checkbox" disabled={disabled} id={id} defaultChecked={checked} className='toggle-button' onClick={() => clicked(!checked)}/>
            <span className="custom-toggle-slider rounded-circle" />
        </label>)
    }
}

export default toggleButton;