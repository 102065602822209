import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";

let subscription_information_vars = {
    plan_id: null,
    plan_price: null,
    addons: {
        is_class_arr: "add_ons"
    }
};

class Subscription_Information {
    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(subscription_information_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }
}

export default Subscription_Information;
