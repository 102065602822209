let initialState = null;

function password(state = initialState, action) {
    if (action.type === "SET_PASSWORD") {
      return action.res;
    }
    else if (action.type === "RESET_PASSWORD") {
        return action.res;
    }

    else {
    return state;
  }
}

export default password;
