import validateVar from "../Functions/validation/validateVariable";
import {
  apiError,
  inSignName,
  inSignPw,
  inSignUrl
} from "../Functions/global";

const controllerName = inSignName;
const controllerPassword = inSignPw;
const baseURL = inSignUrl;
let buffer = new Buffer(controllerName + ":" + controllerPassword);
const authorization = "Basic " + buffer.toString("base64");

class inSign {
  constructor(data) {
    this.requiredSignatureFields = "";
    this.requiredSignatureFieldsSigned = "";

    if (validateVar(data)) {
      if (data.hasOwnProperty("numberOfMandatorySignatureFields")) {
        this.requiredSignatureFields = validateVar(
          data.numberOfMandatorySignatureFields
        )
          ? data.numberOfMandatorySignatureFields
          : "";
      }
      if (data.hasOwnProperty("numberOfMandatorySignatures")) {
        this.requiredSignatureFieldsSigned = validateVar(
          data.numberOfMandatorySignatures
        )
          ? data.numberOfMandatorySignatures
          : "";
      }
    }
  }

  static async configureSession(customer_params) {
    let url = baseURL + "/configure/session";
    let file_id = customer_params.file_id;
    let body = {
      foruser: customer_params.customer_id,
      displayname:
        "Vertragsunterzeichnung bei " + customer_params.supplier_name,
      customerFocusSigField: true, //instantly focus sign field as the file opens
      externLoginRequired: false, //disable password for accessing the signing process
      upadSupport: false, //disable upad signature
      applosPerEmail: false, //disable signing with email,
      appSupport: false, //disabe insign APP
      callbackURL: customer_params.call_back_url,
      documents: [
        {
          displayname: "contract_" + file_id,
          id: "" + file_id
        }
      ],
      guiProperties: {
        guiFertigbuttonSkipModalDialog: true,
        guiHintAllsigned: false,
        burgerAvailable: false,
        exitAvailable: false,
        externAvailable: false
      }
    };

    try {
      let request = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
          "Content-Length": Buffer.byteLength(JSON.stringify(body))
        },
        json: true,
        body: JSON.stringify(body)
      });

      if (request.status === 200) {
        let response_json = await request.json();
        if (response_json.error > 0) return apiError;
        else return response_json.sessionid;
      } else {
        request = await request.json();
        console.log("Err message: ", request.message);
        return apiError;
      }
    } catch (error) {
      console.log("catch: ", error);
      return apiError;
    }
  }

  static async uploadDocument(upload_params, file) {
    let file_name = "contract" + upload_params.file_id + ".pdf";
    let url =
      baseURL +
      "/configure/uploaddocument?filename=" +
      encodeURIComponent(file_name) +
      "&filesize=" +
      Buffer.byteLength(file) +
      "&sessionid=" +
      upload_params.session_id +
      "&docid=" +
      upload_params.file_id;

    file = new File([file], "contract_" + upload_params.file_id, {
      type: "application/pdf"
    });
    let form = new FormData();
    form.append("file", file, {
      filename: file_name,
      contentType: "application/pdf"
    });

    let request_upload_doc = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: authorization
      },
      json: true,
      body: form
    });

    if (request_upload_doc.status === 200) {
      let response_json = await request_upload_doc.json();
      if (response_json.error > 0) return apiError;
      // todo check if add_blocker
      window.location.href =
        baseURL + "/index?sessionid=" + upload_params.session_id;
      // return "Uploaded doc without sending mail";
    } else return apiError;
  }

  static async loadAndCheckIn(session_id) {
    let url =
      baseURL + "/persistence/loadsession?sessionidtoload=" + session_id;

    try {
      let load_session_request = await fetch(url, {
        // load session
        method: "POST",
        headers: {
          Authorization: authorization
        },
        json: true
      });
      if (load_session_request.status === 200) {
        // if status === ok
        let response_json_load_session = await load_session_request.json();
        if (response_json_load_session.error > 0) return apiError;
        else {
          // check in session if no error was thrown
          let url = baseURL + "/get/status?sessionid=" + session_id; // check in session
          let check_in_requst = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: authorization
            },
            json: true
          });
          if (check_in_requst.status === 200) {
            let response_json_check_in = await check_in_requst.json();
            if (response_json_check_in.error > 0) return apiError;
            else {
              return new inSign(response_json_check_in);
            }
          } else {
            check_in_requst = await check_in_requst.json();
            console.log("Err message: ", check_in_requst.message);
            return apiError;
          }
        }
      } else {
        load_session_request = await load_session_request.json();
        console.log("Err message: ", load_session_request.message);
        return apiError;
      }
    } catch (error) {
      console.log("Catch loadAndCheckInSession: ", error);
      return apiError;
    }
  }

  static async downloadDocument(params) {
    let url =
      baseURL +
      "/get/document?sessionid=" +
      params.session_id +
      "&docid=" +
      params.docid +
      "&includebiodata=" +
      false;

    try {
      let request = await fetch(url, {
        headers: {
          Authorization: authorization
        },
        encoding: "latin1"
      });

      if (request.status === 200) {
        let blob = await request.blob();
        return new Blob([blob], { type: "application/pdf" });
      } else {
        request = await request.json();
        console.log("Err message: ", request.message);
        return apiError;
      }
    } catch (error) {
      console.log("Catch downloadDocument: ", error);
      return apiError;
    }
  }
}

export default inSign;
