let initialState = null;

function consumption_point(state = initialState, action) {
  if (action.type === "GET_CONSUMPTION_POINT_FOR_CUSTOMER") {
    return action.res;
  } else if (action.type === "DELETE_CONSUMPTION_POINT_FROM_LIST"){
      let newState = [];
      let consumption_ids = action.ids;

      for (let consumption_point of state) {
          if (consumption_ids.includes(consumption_point.id)) {
              //this consumptionpoint is not going to be pushed to the new Array
          } else {
              newState.push(consumption_point);
          }
      }
      return newState;
  }

  else  if (action.type === "GET_CONSUMPTION_POINT_FOR_OFFER") {
      return action.res;
  }
  else {
    return state;
  }
}

export default consumption_point;
