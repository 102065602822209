import validateVar from "./validation/validateVariable";
import checkStatusCpDate from "./checkStatusCpDate";
import get_standardised_date from "./get_standardised_date";

function getSwitchingDate(switching_date, switching_status, id){
    return !validateVar(switching_date)
        ? [new Date(), id, checkStatusCpDate(switching_status)]
        : [new Date(get_standardised_date(switching_date)), id, checkStatusCpDate(switching_status)];
}

export default getSwitchingDate;