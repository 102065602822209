import * as actionTypes from "./actionTypes";
import getListAttributes from "../module_components/Functions/getListAttributes";

import createTree from "../module_components/Functions/createTree";
import updateTree from "../module_components/Functions/updateTree";
import getSupplierInformation from "../module_components/Functions/getSupplierInformation";
import modifySupplierGlobalTree from "../module_components/Functions/modifySupplierGlobalTree";
import getTree, {
  transfromTreeToPriceStrategy
} from "../module_components/Functions/getTree";
import getTariff from "../module_components/Functions/getTariff";
import * as ERROR_CODE from "../error_codes";
import { apiError } from "../module_components/Functions/global";
import Tariff from "../module_components/Objects/tariff";
import { deHashVar } from "../module_components/Functions/handleHashedVariable";

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));
export const setIsNewToFalse = () => {
  return {
    type: actionTypes.SET_IS_NEW_TO_FALSE
  };
};

export const setTariff = tariff => {
  return {
    type: actionTypes.SET_TARIFF,
    tariff: tariff
  };
};

export const setListAttributes = listAttributes => {
  return {
    type: actionTypes.SET_LIST_ATTRIBUTES,
    listAttributes: listAttributes
  };
};

export const setPriceStrategy = priceStrategy => {
  return {
    type: actionTypes.SET_PRICE_STRATEGY,
    priceStrategy: priceStrategy
  };
};

export const setPriceStrategyGlobal = priceStrategy => {
  return {
    type: actionTypes.SET_PRICE_STRATEGY_GLOBAL,
    priceStrategy: priceStrategy
  };
};

export const setPriceStrategyGlobalOriginal = priceStrategy => {
  return {
    type: actionTypes.SET_PRICE_STRATEGY_GLOBAL_ORIGINAL,
    priceStrategy: priceStrategy
  };
};

export const setMessageFeedback = priceStrategyMessage => {
  return {
    type: actionTypes.SET_USER_MESSAGE,
    res: priceStrategyMessage
  };
};

export const redirectUser = redirect => {
  return {
    type: "REDIRECT_USER",
    res: redirect
  };
};

export const sortBranches = () => {
  return {
    type: actionTypes.SORT_BRANCHES
  };
};

export const resetPriceStrategy = () => {
  return {
    type: actionTypes.RESET_PRICE_STRATEGY
  };
};

export const initAttributs = (supplierId, tariffId, global = false) => {
  return dispatch => {
    getListAttributes()
      .then(res => {
        if (res.statusText !== "Internal Server Error") {
          res.json().then(listAttributesJson => {
            dispatch(setListAttributes(listAttributesJson.result)); //dispatch tariffAttributes
            if (global) {
              dispatch(getGlobalTreeBySupplier(listAttributesJson.result));
            } else {
              getTariff(supplierId, tariffId).then(res => {
                res.json().then(result => {
                  dispatch(setTariff(result.result)); //dispatch tariff for isSLP/isRLM check for TreeAttributes
                });
              });

              dispatch(
                getTreeIdByGetTariff(
                  supplierId,
                  tariffId,
                  listAttributesJson.result
                )
              );
            }
          });
        } else {
          dispatch({
            type: "SET_USER_MESSAGE",
            res: displayErrorMessage(
              "Beim laden der Globalen Kriterien ist ein Fehler aufgetreten. (Fehlercode: " +
                ERROR_CODE.GetAttributes +
                ")", // content
              "Fehler", //headline
              null, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätige", // secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              false, // infoIcon
              "danger" //type - danger/success
            )
          });
        }
      })
      .catch(err => {
        console.log("[priceStrategy][initAttributes] Error: ", err);
      });
  };
};

export const deleteOldGlobalAttributes = attributes_arr => {
  return dispatch => {
    let params = {
      supplier_id: supplier_id,
      attributes: attributes_arr
    };
    Tariff.deleteOldGlobalAttributes(params).then(res => {
      if (res !== apiError) {
        dispatch({ type: "CLEAN_TREES", res: res });
      } else {
        dispatch({ type: "CLEAN_TREES", res: apiError });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Das löschen der alten Globalen Attribute ist fehlgeschlagen. (Fehlercode: " +
              ERROR_CODE.CleanTree +
              ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätigen", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
};

export const resetCleanTrees = () => {
  return dispatch => {
    dispatch({ type: "RESET_CLEAN_TREES", res: null });
  };
};

export const getGlobalTreeBySupplier = currentListAttributes => {
  return dispatch => {
    getSupplierInformation().then(res => {
      if (res.hasOwnProperty("settings")) {
        if (res.settings.hasOwnProperty("global_tree_id")) {
          let treeId = res.settings.global_tree_id;
          if (typeof treeId !== "undefined" && treeId > 0) {
            dispatch(initPriceStrategy(treeId, currentListAttributes, true));
          } else if (treeId === null) {
            dispatch(
              setPriceStrategyGlobal([
                {
                  name: "Globale Kriterien",
                  tree_attributes: []
                }
              ])
            );
          }
        } else console.log("Property global_tree_id missing!");
      } else console.log("Property settings missing!");
    });
  };
};

export const getTreeIdByGetTariff = (
  supplierId,
  tariffId,
  currentListAttributes
) => {
  return dispatch => {
    getTariff(supplierId, tariffId)
      .then(res => {
        res.json().then(tariffJson => {
          console.log("tree_id", tariffJson.result.tree_id);
          if (
            tariffJson.result.tree_id !== undefined &&
            tariffJson.result.tree_id > 0
          ) {
            dispatch(
              initPriceStrategy(
                tariffJson.result.tree_id,
                currentListAttributes
              )
            );
          }
        });
      })
      .catch(err => {
        console.log("[priceStrategy][initAttributes] Error: ", err);
      });

    // dispatch(initPriceStrategy(690, currentListAttributes));
  };
};

export const initPriceStrategy = (
  treeId,
  currentListAttributes,
  global = false
) => {
  return dispatch => {
    getTree(treeId)
      .then(res => {
        res.json().then(treeJson => {
          if (global) {
            dispatch(
              setPriceStrategyGlobal(
                transfromTreeToPriceStrategy(
                  treeJson.result,
                  currentListAttributes
                )
              )
            );
            dispatch(
              setPriceStrategyGlobalOriginal(
                transfromTreeToPriceStrategy(
                  treeJson.result,
                  currentListAttributes
                )
              )
            );
          } else {
            dispatch(
              setPriceStrategy(
                transfromTreeToPriceStrategy(
                  treeJson.result,
                  currentListAttributes
                )
              )
            );
            dispatch(setIsNewToFalse());
          }
        });
      })
      .catch(err => {
        console.log("[priceStrategy][initAttributes] Error: ", err);
      });
  };
};

export const submitNewTree = (
  priceStrategy,
  supplierId,
  tariffId,
  treeType,
  url
) => {
  return dispatch => {
    createTree(priceStrategy[0], supplierId, tariffId)
      .then(res => {
        res.json().then(resJson => {
          if (treeType === "global") {
            if (resJson.status === "ok") {
              if (resJson.result.hasOwnProperty("id")) {
                modifySupplierGlobalTree(resJson.result.id).then(res => {
                  if (res.status === "ok") {
                    dispatch({
                      type: "SET_USER_MESSAGE",
                      res: displaySuccessMessage(
                        "Die Globalen Kriterien wurden gespeichert.", //  content
                        "Erfolg", // headline
                        null, // url
                        true, // automatic_redirect
                        false // modal
                      )
                    });
                  } else {
                    dispatch({
                      type: "SET_USER_MESSAGE",
                      res: displayErrorMessage(
                        "Beim erstellen der Globalen Kriterien ist ein Fehler aufgetreten. (Fehlercode: " +
                          ERROR_CODE.UpdateSupplierGlobalTree +
                          ")", // content
                        "Fehler", //headline
                        null, // url
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätige", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                      )
                    });
                  }
                  dispatch(resetPriceStrategy());
                });
              }
            } else {
              dispatch({
                type: "SET_USER_MESSAGE",
                res: displayErrorMessage(
                  "Beim erstellen der Globalen Kriterien ist ein Fehler aufgetreten. (Fehlercode: " +
                    ERROR_CODE.AddGlobalTree +
                    ")", // content
                  "Fehler", //headline
                  null, // url
                  false, //automatic redirect
                  true, //modal
                  null, // primaryBtnText
                  "Bestätige", // secondaryBtnText
                  true, //allowToggle (user can close modal without pressing a btn)
                  false, // successIcon
                  false, // infoIcon
                  "danger" //type - danger/success
                )
              });
            }
          } else {
            if (resJson.status === "ok") {
              dispatch({
                type: "SET_USER_MESSAGE",
                res: displaySuccessMessage(
                  "Die Tarif Kriterien wurden gespeichert.", //  content
                  "Erfolg", // headline
                  url, // url
                  true, // automatic_redirect
                  false // modal
                )
              });
            } else {
              dispatch({
                type: "SET_USER_MESSAGE",
                res: displayErrorMessage(
                  "Beim erstellen der Tarif Kriterien ist ein Fehler aufgetreten. (Fehlercode: " +
                    ERROR_CODE.AddGlobalTree +
                    ")", // content
                  "Fehler", //headline
                  null, // url
                  false, //automatic redirect
                  true, //modal
                  null, // primaryBtnText
                  "Bestätige", // secondaryBtnText
                  true, //allowToggle (user can close modal without pressing a btn)
                  false, // successIcon
                  false, // infoIcon
                  "danger" //type - danger/success
                )
              });
            }
          }
          dispatch({ type: "REQUEST_FINISHED", res: "End" });
        });
      })
      .catch(err => {
        console.log("[priceStrategy][submitNewTree] Error: ", err);
        dispatch(resetPriceStrategy());
      });
  };
};

export const updateEditedTree = (
  priceStrategy,
  supplierId,
  tariffId,
  treeId,
  url = null
) => {
  return dispatch => {
    updateTree(priceStrategy[0], supplierId, tariffId, treeId)
      .then(res => {
        res.json().then(resJson => {
          if (resJson.status === "ok") {
            dispatch({
              type: "SET_USER_MESSAGE",
              res: displaySuccessMessage(
                "Die Kriterien wurden gespeichert.", //  content
                "Erfolg", // headline
                url, // url
                true, // automatic_redirect
                false // modal
              )
            });
            dispatch({ type: "REQUEST_FINISHED", res: "End" });
          } else {
            dispatch(displayErrorMessage());
            dispatch({ type: "REQUEST_FINISHED", res: "End" });
          }

          dispatch(resetPriceStrategy());
        });
      })
      .catch(err => {
        console.log("[priceStrategy][updateTree] Error: ", err);
        dispatch(displayErrorMessage());
        dispatch(resetPriceStrategy());
      });
  };
};

/* Messages */
const displayErrorMessage = (
  message = "Eine schlechte Nachricht",
  headline = "Es ist ein Fehler aufgetreten",
  url = null,
  automatic_redirect = false,
  modal = false,
  primaryBtnText = "OK",
  secondaryBtnText = "Abbrechen",
  allowToggle = false,
  successIcon = false,
  infoIcon = false,
  type = "danger",
  secondaryBtnFunction = null
) => {
  return {
    type: type,
    headline: headline,
    message: message,
    url: url,
    automatic_redirect: automatic_redirect,
    modal: modal,
    primaryBtnText: primaryBtnText,
    secondaryBtnText: secondaryBtnText,
    successIcon: successIcon,
    infoIcon: infoIcon,
    allowToggle: allowToggle,
    secondaryBtnFunction: secondaryBtnFunction
  };
};

const displaySuccessMessage = (
  message = "Eine gute Nachricht",
  headline = "Erfolg",
  url = null,
  automatic_redirect = false,
  modal = null,
  primaryBtnText = "OK",
  secondaryBtnText = "Abbrechen",
  allowToggle = false,
  successIcon = true,
  infoIcon = false,
  secondaryBtnFunction = null,
  type = "success"
) => {
  return {
    type: type,
    headline: headline,
    message: message,
    url: url,
    automatic_redirect: automatic_redirect,
    modal: modal,
    primaryBtnText: primaryBtnText,
    secondaryBtnText: secondaryBtnText,
    successIcon: successIcon,
    infoIcon: infoIcon,
    allowToggle: allowToggle,
    secondaryBtnFunction: secondaryBtnFunction
  };
};
