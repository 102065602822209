/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from "reactstrap";
// core components
// custom components
import {
    handle_price_ranking,
} from "../../actions/new_index";

import {
    redirect
} from "../../actions";
import connect from "react-redux/es/connect/connect";
import validateVar from "../../module_components/Functions/validation/validateVariable";
import SimpleHeader from "../../module_components/Displays/Headers/SimpleHeader";
import cn from "classnames";
import {apiError} from "../../module_components/Functions/global";
import ColoredSpans from "../../module_components/Displays/Basic/ColoredSpans";
import BadgeDot from "../../module_components/Displays/Badges/BadgeDot";
import BasicListDisplay from "../../module_components/Displays/Clearing/BasicListDisplay";
import BasicValueDisplay from "../../module_components/Displays/Clearing/BasicValueDisplay";
import Badge from "../../module_components/Displays/Badges/Badge";
import Table from "../../module_components/Displays/Tables/Table";
import SimpleTable from "../../module_components/Displays/Tables/SimpleTable";
import PrimaryButton from "../../module_components/Displays/Buttons/PrimaryButton";
import ChangeOfferPriceModal from "./Components/ChangeOfferPriceModal";
import {modify_offer_res} from "../../module_components/Index";
import is_true from "../../module_components/Functions/is_true";
import isEmptyObject from "../../module_components/Functions/isEmptyObject";

const consumption_point_columns = [
    {
        dataField: "consumption_display",
        text: 'Verbrauch',
        sort: true,
    },
    {
        dataField: "last_type_display",
        text: "Typ",
        sort: true,
    },
    {
        dataField: "business_type_display",
        text: "Verbrauchsprofil",
        sort: true,
    },
    {
        dataField: "load_profile_synthetic_text",
        text: "Lastgang",
        formatter: (cell, object) => {
            return <BadgeDot value={cell} color={object.load_profile_synthetic_bade_color} />
        },
        sort: true,
    },
    {
        dataField: "postal_code",
        text: "PLZ",
        sort: true,
    },
    {
        dataField: "city",
        text: "Ort",
        sort: true,
    },
    {
        dataField: "state_display",
        text: "Bundesland",
        sort: true,
    },
];




class OfferView extends Component {
    constructor(props) {
        super(props);
        this.offer_id = parseInt(props.match.params.offer_id);

        this.state = {
            is_loaded_offer: false,
            disable_submit: true,
            content_offer_data: null,
            offer: {},
        }

        this.change_offer_price_modal = React.createRef();
    }

    componentDidMount(){
        let params = {
            options: {
                with_prediction: 1,
            },
            id: this.offer_id
        };
        this.props.init(params);
    }

    componentDidUpdate(prevProps, prevState) {
        const {offer} = this.props;
        if (validateVar(offer) && offer !== prevProps.offer && offer !== apiError) {
            let price_ranking = [];
            let content_offer_data = <span>Keine Angebotsdaten verfügbar.</span>;
            let consumption_points = [];

            if (!Array.isArray(offer)) { //wenn hier ein Api Fehler auftritt bekommen wir ein leeres Array als Ergebnis zurück
                content_offer_data = this.get_offer_data(offer);
                price_ranking = this.append_current_offer_to_reporting(offer);
                consumption_points = offer.consumption_points;
            }

            this.setState({
                offer: {...offer},
                price_ranking: price_ranking,
                consumption_points: consumption_points,
                is_loaded_offer: true,
                activeTab: "1",
                content_offer_data: content_offer_data
            });
        }
    }

    get_offer_data_display = (offer) => {
        let netto_prices = offer.price_type === 'energy_price';
        let business = offer.customer_type_tariff === 'BUSINESS';

        return [
            {
                text: "Tarif",
                value: "name"
            }, {
                text: "Angebots-ID",
                value: "id"
            },{
                text: (is_true(netto_prices)) ? "Energiepreis (Netto)" : is_true(business) ?  "Arbeitspreis (Netto)" : "Arbeitspreis (Brutto)",
                value: (is_true(netto_prices)) ? "price_rate_full_display" : is_true(business) ? "contract_price_net_full_display" : "contract_price_gross_full_display",
            },{
                text: (is_true(netto_prices) || is_true(business)) ? "Grundgebühr (Netto)" : "Grundgebühr (Brutto)",
                value: (is_true(netto_prices) || is_true(business)) ? "socket_rate_formated" : "socket_gross_rate_formated",
            },{
                text: "Provision",
                value: "summarized_provision_supplier"
            },{
                text: "Lieferbegin",
                value: "offer_begin_display"
            },{
                text: "Lieferende",
                value: "offer_end_display"
            },{
                text: "Kündigungsfrist",
                value: "cancellation_period_display"
            },{
                text: "Autom. Verlängerung",
                value: "contract_extension_display"
            },
        ];
    };

    append_current_offer_to_reporting = (offer) => {
        let reporting = validateVar(offer.reporting) ? offer.reporting : [];
        let fake_reporting = {
            ranking: reporting.length +1,
            price_rate_full_display: offer.price_rate_full_display,
            price_rate_delta_display: "0,000 ct / kWh",
            yearly_price_display: offer.yearly_price_display,
            socket_gross_display_plain: offer.socket_gross_display_plain,
            socket_display_plain: offer.socket_display_plain,
            socket_net_delta_display: "0,00 € / Jahr",
            socket_gross_delta_display: "0,00 € / Jahr",
            yearly_contract_price_net_display: offer.yearly_contract_price_net_display,
            yearly_price_gross_display: offer.yearly_price_gross_display,
            contract_price_gross_full_display: offer.contract_price_gross_full_display,
            yearly_price_delta_display: "0,00 € / Jahr",
            contract_price_gross_delta_display: "0,00 € / Jahr",
            yearly_price_gross_delta_display: "0,00 € / Jahr",
        };
        return  reporting.concat(fake_reporting);
    };

    get_offer_data = (offer) => {
        let offer_data_display = this.get_offer_data_display(offer);
        return offer_data_display.map((row) => {
            return <BasicListDisplay
                text={row.text}
                value={offer[row.value]}
                is_loaded={true}
            />
        });
    };

    get_offer_column = (offer) => {
        let netto_prices = offer.price_type === 'energy_price';

        return [
            {
                dataField: "ranking",
                text: 'Platz',
                sort: true,
            },
            {
                dataField: (is_true(netto_prices)) ? "price_rate_full_display" : "contract_price_gross_full_display",
                text: (is_true(netto_prices)) ? "Energiepreis (Netto)" : "Arbeitspreis (Brutto)",
                sort: true,
            },
            {
                dataField: (is_true(netto_prices)) ? "price_rate_delta_display" : "contract_price_gross_delta_display",
                text: "Delta",
                formatter: (cell, object) => {
                    return <ColoredSpans value={cell} color={object.price_rate_color} />
                },
                sort: true,
            },
            {
                dataField: (is_true(netto_prices)) ? "socket_display_plain" : "socket_gross_display_plain",
                text: (is_true(netto_prices)) ? "Grundgebühr (Netto)" : "Grundgebühr (Brutto)",
                sort: true,
            },
            {
                dataField: (is_true(netto_prices)) ? "socket_net_delta_display" : "socket_gross_delta_display",
                text: "Delta",
                formatter: (cell, object) => {
                    return <ColoredSpans value={cell} color={object.socket_color} />
                },
                sort: true,
            },
            {
                dataField: (is_true(netto_prices)) ? "yearly_price_display" : "yearly_price_gross_display",
                text: (is_true(netto_prices)) ? "Energiekosten (Netto)" : "Gesamtkosten (Brutto)",
                sort: true,
            },
            {
                dataField: (is_true(netto_prices)) ? "yearly_price_delta_display" : "yearly_price_gross_delta_display",
                text: "Delta",
                formatter: (cell, object) => {
                    return <ColoredSpans value={cell} color={object.yearly_price_color} />
                },
                sort: true,
            },
        ];
    };

    render() {
        const {is_loaded_offer, offer, activeTab, content_offer_data, price_ranking, consumption_points} = this.state;
        let offer_column = {};
        if(!isEmptyObject(offer)) offer_column = this.get_offer_column(offer);

        let consumption_points_title = 'Lieferstellen';
        if(validateVar(consumption_points) && Array.isArray(consumption_points)){
            consumption_points_title = consumption_points.length === 1 ? consumption_points.length + ' Lieferstelle' : consumption_points.length + ' Lieferstellen'
        }

        return (
            <>
                <SimpleHeader name="Angebots-Detailansicht"/>
                <ChangeOfferPriceModal ref={this.change_offer_price_modal} offer={offer} on_submit={(params) => this.props.offer_result_update(params)} />
                <Container className="mt--6" fluid>
                    <Card className='contract-header'>
                        <CardBody>
                            <Row>
                                <Col xs={12} className='column mb-3 mb-sm-0 pr-md-0'>
                                    <ul className='d-inline-block list-middot mb-0'>
                                        <li>
                                            <BasicValueDisplay
                                                is_loaded={true}
                                                value={this.offer_id}
                                                text={"Angebot "}
                                            />
                                        </li>
                                        <li className='d-none d-lg-inline-block'>
                                            <BasicValueDisplay
                                                is_loaded={is_loaded_offer}
                                                value={"medium_type_display"}
                                                object={offer}
                                                max_length={9}
                                            />
                                        </li>
                                        <li className='d-none d-lg-inline-block'>
                                            <BasicValueDisplay
                                                is_loaded={is_loaded_offer}
                                                value={"created_at_display"}
                                                object={offer}
                                            />
                                        </li>
                                        <li className='d-none d-lg-inline-block'>
                                            <BasicValueDisplay
                                                is_loaded={is_loaded_offer}
                                                value={"agency_type_display"}
                                                object={offer}
                                            />
                                        </li>
                                        <li>
                                            <Badge is_loaded={is_loaded_offer}
                                                type={"badge"}
                                                value={offer.status_display}
                                                color={offer.status_color} />
                                        </li>
                                    </ul>
                                    <span><PrimaryButton disabled={offer.status !== 'open'} value={'Angebot bearbeiten'} classes='edit-offer' onClick={() => this.change_offer_price_modal.current.toggle_modal()} /></span>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader className='p-0'>
                            <Nav className='customer-nav-tabs five-tabs' tabs>
                                <NavItem>
                                    <NavLink
                                        className={cn({active: activeTab === '1'})}
                                        onClick={() => {
                                            this.setState({activeTab: '1'})
                                        }}
                                    >
                                        <h2>Angebotsdaten</h2>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={cn({active: activeTab === '2'})}
                                        onClick={() => {
                                            this.setState({activeTab: '2'})
                                        }}
                                    >
                                        <h2>Lieferstellen</h2>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={cn({active: activeTab === '3'})}
                                        onClick={() => {
                                            this.setState({activeTab: '3'})
                                        }}
                                    >
                                        <h2>Preisranking</h2>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>

                        <CardBody className='p-0'>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="info-section pl-2">
                                        {content_offer_data}
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <SimpleTable
                                        data={consumption_points}
                                        columns={consumption_point_columns}
                                        on_column_click={() => console.log()}
                                        selected_ids={[]}
                                        row_select_change={() => function () {}}
                                        disabled_rows={[]}
                                        no_checkboxes={true}
                                        is_loaded={is_loaded_offer}>
                                        <Row className='mb-3'>
                                            <Col md={4}>
                                                <h2>{consumption_points_title}</h2>
                                            </Col>
                                            <Col md={8} className='text-right'>
                                            </Col>
                                        </Row>
                                    </SimpleTable>
                                </TabPane>
                                <TabPane tabId="3">
                                    <SimpleTable
                                            data={price_ranking}
                                            columns={offer_column}
                                            on_column_click={() => console.log()}
                                            selected_ids={[]}
                                            row_select_change={() => function () {}}
                                            disabled_rows={[]}
                                            no_checkboxes={true}
                                            is_loaded={is_loaded_offer}>
                                        <Row className='mb-3'>
                                            <Col md={4}>
                                                {validateVar(price_ranking) && <h2>{price_ranking.length === 1 ? price_ranking.length + ' Angebot' : price_ranking.length + ' Angebote'}</h2>}
                                            </Col>
                                            <Col md={8} className='text-right'>
                                            </Col>
                                        </Row>
                                    </SimpleTable>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

let mapStateToProps = function(state) {
    return {
        offer: state.offer,
        redirect: state.redirect
    }
};

let mapDispatchToProps = {
    init: handle_price_ranking,
    redirect: redirect,
    offer_result_update: modify_offer_res,
};

let OfferViewContainer = connect(mapStateToProps, mapDispatchToProps)(OfferView);

export default OfferViewContainer;

