import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";

let consultant_employee_data_vars = {
    email: {
        consultant_employee_email: null,
    },
    first_name: {
        consultant_employee_first_name: null,
    },
    last_name: {
        consultant_employee_last_name: null,
    },
    notification_settings: {
        consultant_employee_notification_settings: null,
    },
    phone: {
        consultant_employee_phone: null,
    },
    picture_id: {
        consultant_employee_picture_id: null,
    },
    title: {
        consultant_employee_title: null,
    },
    consultant_employee_full_name: {
        function_name: 'combine_strings',
        params: ['%%first_name', ' ', '%%last_name']
    }
};

class ConsultantEmployeeData {
    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(consultant_employee_data_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static dummy() {
        let output = {};
        output = this.build_dummy(consultant_employee_data_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {
                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr) || index === "translation_object") { // <-- always look for the child values
                        output[index] = this.build_dummy(vars[index]);
                    } else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default ConsultantEmployeeData;
