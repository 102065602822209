import React from 'react';
import Skeleton from "react-loading-skeleton"
import {Col, Row} from "reactstrap";
import isValidDateCustom from "../../Functions/validation/isValidDate";
import handleFormatDate from "../../Functions/handleFormatDate";

class BasicListDisplay extends React.Component {

    render() {
        let {value ,text, is_loaded, id} = this.props;
        if (isValidDateCustom(value)) {
            value = handleFormatDate(value);
        }
        return (is_loaded) ? <Row id={id}>
                    <Col sm={12} md={6} lg={5} className='left-info-col title'>
                <b>{text}:</b>
            </Col>
            <Col sm={12} md={6} lg={7} className='value'>
               <span>{value}</span>
            </Col>
        </Row> : <Skeleton width={250} height={20}/>
    }
}


export default BasicListDisplay;