import React, {useEffect} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {handleTariff, Pricestrategy, TariffDocs} from "../../../../globalNavigation";
import validateVar from "../../../../module_components/Functions/validation/validateVariable";
function getSteps() {
    return ['Klassifizierung', 'Preisstrategie', 'Dokumente', 'Test/Veröffentlichen'];
}

export default function HorizontalLabelPositionBelowStepper(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    useEffect(() => {
        setActiveStep(props.active); //Set the current active step
    });

    const redirectUser = (redirect_page, id, treeId) => {
        treeId = (validateVar(treeId)) ? treeId : 0;
        props.onRedirect(redirect_page + "/" + id + "/" +  treeId);
    };

    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
                let currentStep = <StepLabel>{label}</StepLabel>;
                if(props.active > index) { //If the current label has a link add the proper one to it
                    let redirectFunction;
                    if(index === 0){
                        redirectFunction = () => redirectUser(handleTariff, props.tariffId);
                    }else if(index === 1){
                        redirectFunction = () => redirectUser(Pricestrategy, props.tariffId, props.treeId);
                    }else if(index === 2){
                        redirectFunction = () => redirectUser(TariffDocs, props.tariffId);
                    }else{
                        redirectFunction = function(){};
                    }
                    currentStep = <StepLabel><span onClick={redirectFunction} className='linked-label'>{label}</span></StepLabel>;
                }

                return(
                    <Step key={label}>
                        {currentStep}
                    </Step>
                )})}
        </Stepper>
    );
}