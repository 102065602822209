import React from "react";
import PropTypes from "prop-types";

const NoTableDataIndication = (props) => (
    <span className='empty-table-text'>{props.text}</span>
);

NoTableDataIndication.propTypes = {
    text: PropTypes.string.isRequired
};

export default NoTableDataIndication;