
let initialState = null;

function grouping(state = initialState, action) {
    if (action.type === "INITIALIZE_GROUPING") {
        return action.res;
    } else if (action.type === "RESET_GROUPING") {
        return action.res;
    }
    else return state;
}

export default grouping;
