import React from 'react';
import TitledContent from "../PageContent/TitledContent";
import validateVar from "../Functions/validation/validateVariable";
import cn from "classnames";
import {Input, InputGroup, InputGroupAddon, InputGroupText, Tooltip} from "reactstrap";
import PropTypes from 'prop-types';
import Select from "./Select";
import Checkbox from "./Checkbox/Checkbox";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import FileUploadExperimental from "./FileUploadExperimental";
import CropImageModal from "../Cropper/CropImgModal";
import RadiosBoolean from "./RadiosBoolean";
import Multiselect from "./Multiselect/Multiselect";
import Skeleton from "react-loading-skeleton";
import validateDate from "../Functions/validation/validateDate";
import regexCheck from "../Functions/validation/regexCheck";
import handleFormatRawDatepickerValue from "../Functions/handleFormatRawDatepickerValue";
import handleFormatDateForFrontend from "../Functions/handleFormatDateForFrontend";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import DragAndDrop from "./DragAndDrop";
import SearchableSelect from "./SearchableSelect";
import Slider from "./Slider";
import callFunctionWithString from "../Functions/callFunctionWithString";
import is_false from "../Functions/is_false";
import FileUpload from "./FileUpload/FileUpload";
import TwoCheckboxes from './TwoCheckboxes';
import remove_last_char from "../Functions/remove_last_char";
import isEmptyObject from "../Functions/isEmptyObject";

class InputHandler extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: (validateVar(props.id)) ? props.id : props.name,
            error_state: (validateVar(props.error_state)) ? props.error_state : false,
            unchecked_value: (validateVar(props.unchecked_value)) ? props.unchecked_value : false,
            checked_value: (validateVar(props.checked_value)) ? props.checked_value : true,
            auto_complete: (validateVar(props.auto_complete)) ? props.auto_complete : null,
            show_tool_tipp: false,
            tool_tipp_value: "",
            has_changed: false,
        };

        this.last_valid_value = {};
        this.crop_image_modal = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {error_state} = this.props;
        const error_state_ = this.state.error_state;

        if (error_state !== error_state_ && error_state !== prevProps.error_state) { //validateVar(error_state) removed because the value couldnt be reset with an undefined value
            this.setState({error_state: error_state})
        }
    }

    set_last_valid_value = (name, value) => {
        this.last_valid_value[name] = value;
    };



    check_for_min_max_values = (value, return_value, attribute) => {
        let {range_min, range_max, type} = attribute;
        if (type !== "date")  {
            if (validateVar(range_max)) {
                if (+value > +range_max ) {
                    return_value = remove_last_char(return_value);
                    this.show_tool_tipp("Der Wert darf maximal " + range_max + " betragen.");
                }
            }
            if (validateVar(range_min))  {
                if (+value < +range_min) {
                    return_value = remove_last_char(return_value);
                    this.show_tool_tipp("Der Wert muss größer " + range_min + " betragen");
                }
            }
        }
        return return_value;
    };


    get_min_max_check_value = (value, attribute) => {
        value = value + "";
        let {type} = attribute;
        let return_value = value;
        if ((validateVar(value) && type === "int")) {
            return_value = value.replace(/\./g, ""); //remove "." which was causing and error
        } else if (validateVar(value) && type.includes("float")) {
            return_value = value.replace(",", "."); //remove "." which was causing and error
        }
        return return_value;
    };

    on_change = (name, value, event = null, function_name = null, prev_value = '') => {
        const {function_params, min_max_attribute} = this.props; // is_array
        if (validateVar(function_name)) {
            if (validateVar(function_params)) {
                const params = [value].concat(function_params); // add value to first index in array
                value = callFunctionWithString(function_name, params);
            } // call function with custom params
            else {
                value = callFunctionWithString(function_name, [value, prev_value]);
            } // call default functions (->clearing)
        }
        if (validateVar(min_max_attribute) && !isEmptyObject(min_max_attribute)) {
            let check_value = this.get_min_max_check_value(value, min_max_attribute);
            value = this.check_for_min_max_values(check_value, value, min_max_attribute);
        }
        this.props.on_change(name, value, event);
        this.setState({has_changed: true})
    };


    show_tool_tipp = (value) => {
        let {show_tool_tipp} = this.state;
        if (!show_tool_tipp) {
            this.setState({
                tool_tipp_value: (validateVar(value)) ? value : "Bitte geben Sie einen validen Wert an",
                show_tool_tipp: true,
            });
            this.reset_tool_tipp();
        }
    };

    reset_tool_tipp = () => {
        setTimeout(function(){
            this.setState({
                show_tool_tipp: false
            })
        }.bind(this), 4000);
    };

    on_change_dnd = (dragIndex, hoverIndex, name, value) => {
        let dragCard = value.splice(dragIndex, 1)[0];
        value.splice(hoverIndex, 0, dragCard);
        this.props.on_change(name, value);
    };

    on_change_save_last_valid_value = (name, value, type, show_time) => {
        if (type === "date" && !validateDate(value)) {
            value = this.last_valid_value[name];
        }else if(!validateVar(show_time) || is_false(show_time)){
            //set default date time 02:00 to keep the timestamps the same when no time is needed to save (api default)
            value.setHours(2);
            value.setMinutes(0);
            value.setSeconds(0);
        }
        this.setState({error_state: false}); //removes error_message from datepicker on calendar click
        this.props.on_change(name, value);
    };

    on_date_change_raw = (name, event) => { //validate keyboard input date
        const {min_date, max_date, date_type} = this.props;
        let date = event.target.value;
        let errorMessage = false;
        let replaceThis = ["-", "/"];
        let seperateValue = ".";
        let len = date.length;
        let lastChar = date.substr(-1); //get last character to check user input

        //replace values which can be mistaken - example: 02.03.2020 to 02/03/2020
        if (replaceThis.includes(lastChar)) { //check if input must be replaced
            for (let index in replaceThis) { //loop for all replace able items and replace them
                date = date.replace(replaceThis[index], seperateValue);
                event.target.value = date; //set value with the right separators into inputfield
            }
            lastChar = seperateValue;
        }

        let is_numeric_regex = /^\d+$/; //regex for numeric string
        if (!regexCheck(lastChar, is_numeric_regex) && lastChar !== seperateValue) { //check if value is invalid and
            date = date.substr(0, (len - 1)); //remove invalid value
            event.target.value = date;
        }
        if (len === 10) { // 10 chars are the right length
            if (validateDate(date)) { //check if it is a valid date
                date = handleFormatRawDatepickerValue(date);
                if (validateVar(min_date) && validateVar(max_date)) {
                    if (new Date(max_date) < new Date(date)) {
                        if (date_type === 'start') errorMessage = 'Ihr Lieferbeginn liegt zu weit in der Zukunft. Der späteste Lieferbeginn ist am ' + handleFormatDateForFrontend(max_date);
                        else errorMessage = 'Ihr Lieferende liegt zu weit in der Zukunft. Das spätest mögliche Datum ist am ' + handleFormatDateForFrontend(max_date);
                    } else if (new Date(min_date) > new Date(date)) {
                        if (date_type === 'start') errorMessage = 'Der frühste Lieferbeginn ist am ' + handleFormatDateForFrontend(min_date);
                        else errorMessage = 'Das frühste mögliche Datum ist am ' + handleFormatDateForFrontend(min_date);
                    } //Date in the past or date too late in the future
                }
                date = new Date(date); //format date for future checking reasons
                if (date !== "Invalid Date") {
                    this.set_last_valid_value(name, date);
                    this.props.on_change(name, date);
                } else {
                    this.props.on_change(name, this.last_valid_value[name]); // todo error check
                    errorMessage = 'Bitte geben Sie ein valides Datum im Format TT.MM.JJJJ an.';
                }
            } else errorMessage = 'Bitte geben Sie ein valides Datum im Format TT.MM.JJJJ an.'; //Invalid date due to regex
            this.setState({error_state: errorMessage});
        } else if (len > 10) {//disable more input than 10 character
            date = date.substr(0, 10);
            event.target.value = date; //input value must be set this way since datepicker works this way
            this.props.on_change(name, date);
        }
        this.props.on_change(name, this.props.value);
    };

    on_file_change = (e) => {
        const {cropper, name, aspect_ratio} = this.props; // file_upload
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        let file = files[0];
        // this.props.on_change(e.target.name, file);
        if (cropper) {
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    image_source_url: reader.result,
                    src: file
                });
            };
            this.setState({logo_placeholder: file.name});
            reader.readAsDataURL(file);
            this.crop_image_modal.current.toggleModal(name, aspect_ratio);
        } else {
            this.props.upload_file(file);
        }
    };

    cropLogo = (url) => {
        let src = this.state.src; // original img data
        var blob = null;
        let file = null;
        var xhr = new XMLHttpRequest(); //http request to transform file url to blob
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = function () {
            blob = xhr.response; // blob response
            file = new File([blob], src.name, {  // blob to file for DB save
                type: blob.type,
            });

            this.props.upload_file(file);
        }.bind(this);
        xhr.send();
    };

    /*
        if error_state not empty -> show error (error_state is error message)
        input group text can be empty -> if not empty show text behind input field
     */
    get_struct_content = () => {
        const {input_group_text, input_group_on_click, classes, type, prefix_addon_class, suffix_addon_class} = this.props;
        const {error_state} = this.state;
        let struct_content = <></>;

        if (validateVar(input_group_text)) {//Input field with appended unit text
            let on_click_func = validateVar(input_group_on_click) ? input_group_on_click : () => function (){};
            let classes_ = validateVar(input_group_on_click) ? classes + ' cursor-pointer' : classes;

            struct_content = <InputGroup className={cn({'alert-border': error_state})}>
                {this.get_input_field()}
                <InputGroupAddon onClick={on_click_func} addonType="append" className={classes_}>
                    <InputGroupText>{input_group_text}</InputGroupText>
                </InputGroupAddon>
            </InputGroup>;
        }
        if (validateVar(prefix_addon_class)) {//Input field with Icon in the front
            struct_content = <InputGroup
                className={"input-group-merge input-group-alternative" + cn({' alert-border': error_state})}>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className={"ni ni-" + prefix_addon_class}/>
                    </InputGroupText>
                </InputGroupAddon>
                {this.get_input_field()}
            </InputGroup>
        } else if (validateVar(suffix_addon_class)) {

        } else if (!validateVar(input_group_text) && !validateVar(prefix_addon_class) && !validateVar(suffix_addon_class)) {
            struct_content = this.get_input_field();
        }

        return <>
            {struct_content}
            {error_state && <div
                className={'argon-error-message' + cn({' file-upload-error-message': type === 'file_upload' || type === 'file_upload_new'})}>{error_state}</div>}
        </>
    };

    on_focus = (e) => {
        const {on_focus, remove_zero_on_focus} = this.props;
        if(remove_zero_on_focus && (e.target.value === '0' || e.target.value === 0)){
            e.target.value = '';
        }
        if(validateVar(on_focus)) on_focus(e);
    }

    on_blur = (e) => {
        const {on_blur, remove_zero_on_focus} = this.props;
        if(remove_zero_on_focus && !validateVar(e.target.value)){
            e.target.value = '0';
        }
        if(validateVar(on_blur)) on_blur(e.target.name, e.target.value);
    }

    get_input_field = () => {
        let {type, name, value, disabled, classes, placeholder, prefix_addon_class, suffix_addon_class, function_name, prev_value, on_focus, on_blur, is_loaded} = this.props; //global props
        const {input_group_text, max_length, on_key_down} = this.props; //text / number
        const {cropper, file_type, delete_file, uploading, resetValue, reset_file, has_reset, upload_finished, public_static, return_file_id,
            download_function, upload_function, delete_function, file_id, allow_delete, allow_download, allow_reupload, // file_upload
            aspect_ratio, return_file, dont_submit_type, dynamic_action_call, default_file_upload} = this.props; // file_upload
        const {text, label_classes} = this.props; //checkbox
        const {first_radio, second_radio} = this.props; //radio
        const {select_options, remove_empty_option} = this.props; //select
        const {multiselect_options} = this.props; //multiselect
        const {seachable_select_options, default_value} = this.props; //seachable_select
        const {popper_placement, dropdown_mode, max_date, min_date, min_time, max_time, show_time, default_empty, format, show_year_dropdown} = this.props; //date
        const {key, key_value} = this.props; //drag and drop
        const {step, min, max, unit} = this.props; //slider
        const {first_checkbox, second_checkbox} = this.props; //two_checkboxes
        if (!validateVar(on_blur))  on_blur = function(){};
        let {error_state, id, unchecked_value, checked_value, auto_complete} = this.state; //date
        let {tool_tipp_value, show_tool_tipp, has_changed} = this.state; //tool_tipp
        //if its an InputGroup field, alert-border needs to be set on InputGroup component except on the input itself
        let is_input_group = input_group_text || prefix_addon_class || suffix_addon_class;
        switch (type) {
            case 'text':
            case 'number':
                return <><Input
                    id={id}
                    name={name}
                    className={classes + cn({' alert-border': error_state && !is_input_group})}
                    value={value}
                    placeholder={placeholder}
                    auto_complete={auto_complete}
                    disabled={disabled}
                    onChange={(e) => this.on_change(e.target.name, e.target.value, e, function_name, prev_value)}
                    onKeyDown={validateVar(on_key_down) ? on_key_down : null}
                    maxLength={max_length}
                    onFocus={this.on_focus}
                    onBlur={this.on_blur}
                />
                    {has_changed && <Tooltip trigger='hover' placement='top' isOpen={show_tool_tipp} target={id}>
                        {tool_tipp_value}
                    </Tooltip>}
                </>;
            case 'password' :
                return <Input
                    type={type}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    className={classes + cn({' alert-border': error_state && !is_input_group})}
                    value={value}
                    auto_complete={auto_complete}
                    disabled={disabled}
                    onChange={(e) => this.on_change(e.target.name, e.target.value, e, function_name)}
                    onKeyDown={validateVar(on_key_down) ? on_key_down : null}
                    maxLength={max_length}
                />;
            case 'select':
                return <Select
                    id={validateVar(id) ? id : name}
                    name={name}
                    classes={classes + cn({' alert-border': error_state})}
                    disabled={disabled}
                    value={value}
                    on_change={(name, value) => this.on_change(name, value, null, function_name)}
                    options={select_options}
                    remove_empty_option={remove_empty_option}
                />;
            case 'multiselect':
                return <Multiselect
                    disabled={disabled}
                    id={id}
                    name={name}
                    options={multiselect_options}
                    classes={classes + cn({' alert-border-child': error_state})}
                    label='Bitte auswählen ...'
                    selected={value}
                    handleChange={(selected) => this.on_change(name, selected, null, function_name)}
                    onBlur={(e) => function(){}}
                />;
            case 'date':
                if (validateDate(value)) this.set_last_valid_value(name, value);
                let date_value = value;
                if(!validateVar(default_empty) || is_false(default_empty)){ //empty_props can be undefined or false, if its true dont set default date to keep the input field empty
                    if(!validateVar(date_value))  date_value = new Date();
                }
                if(validateVar(date_value) && date_value.toString() === 'Invalid Date') date_value = new Date();
                if(validateVar(date_value)) date_value = new Date(date_value); //creating valid date object in case the string is formatted differently like in the newTree.jsx (this prevents errors on other future pages)
                let date_format = 'dd.MM.yyyy';
                if(show_time) date_format = 'dd.MM.yyyy HH:mm';
                if(validateVar(format)) date_format = format;
                let showYearDropdown = true;
                if(validateVar(show_year_dropdown)) showYearDropdown = show_year_dropdown;
                return <div className='full-width-datepicker'>
                    <DatePicker
                        id={id}
                        className={'form-control w-100 ' + classes + cn({' alert-border': error_state})}
                        selected={date_value}
                        disabled={disabled}
                        dateFormat={date_format}
                        auto_complete={auto_complete}
                        showTimeSelect={show_time}
                        timeIntervals={15}
                        timeCaption="Uhrzeit"
                        locale={de}
                        name={name}
                        minDate={min_date}
                        maxDate={max_date}
                        minTime={min_time}
                        maxTime={max_time}
                        showMonthDropdown={true}
                        showYearDropdown={show_year_dropdown}
                        // disabled={cell[2]}
                        popperPlacement={popper_placement}
                        dropdownMode={dropdown_mode}
                        onChange={(date) => this.on_change_save_last_valid_value(name, date, type, show_time)}
                        onBlur={() => on_blur(name, date_value)}
                        onChangeRaw={event => this.on_date_change_raw(name, event)}
                        // onBlur={(e) => this.handleChangeDate(e, cell[1])}
                    />
                </div>;
            case 'radio':
                return <RadiosBoolean on_change={(name, value) => this.on_change(name, value, null, function_name)}
                                      name={name}
                                      value={value}
                                      first_radio={first_radio}
                                      second_radio={second_radio}
                                      disabled={disabled} />;
            case 'two_checkboxes':
                return <TwoCheckboxes on_change={(name, value) => this.on_change(name, value, null, function_name)}
                                      name={name}
                                      value={value}
                                      first_checkbox={first_checkbox}
                                      second_checkbox={second_checkbox}
                                      disabled={disabled} />;
            case 'checkbox':
                return <Checkbox
                    id={id}
                    name={name}
                    classes={classes}
                    label_classes={label_classes}
                    value={value}
                    disabled={disabled}
                    on_click={(name, value) => this.on_change(name, value, null, function_name)}
                    text={text}
                    unchecked_value={unchecked_value}
                    checked_value={checked_value}
                />;
            case 'slider':
                return <Slider value={value}
                        step={step}
                        min={min}
                        max={max}
                        unit={unit}
                        onChange={(value) => this.on_change(name, value, null, function_name)}/>;
            case 'file_upload':
            case "file_upload_button":
            case "file_upload_text":
                //if only file_id is give, display the message instead of the id as value
                let value_ = value;
                if(parseInt(value)) value_ = 'Datei vorhanden';
                return <FileUploadExperimental
                    fileChanged={(e) => this.on_file_change(e)}
                    value={value_}
                    id={id}
                    name={name}
                    uploading={uploading}
                    setErrorMessage={(message) => this.setState({error_state: message})}
                    file_type={file_type}
                    cropper={cropper}
                    disabled={disabled}
                    text={text}
                    classes={classes + cn({' alert-border': error_state})}
                    allowDelete={validateVar(delete_file)}
                    deleteFile={validateVar(delete_file) ? delete_file : function () {}}
                    upload_type={type}
                    resetValue={resetValue}
                />;
            case "file_upload_new":
                return <FileUpload
                    value={value}
                    id={id}
                    name={name}
                    file_id={file_id}
                    upload_finished={upload_finished}
                    setErrorMessage={(message) => this.setState({error_state: message})}
                    file_type={file_type}
                    cropper={cropper}
                    disabled={disabled}
                    reset_file={reset_file}
                    has_reset={has_reset}
                    public_static={public_static}
                    upload_function={upload_function}
                    delete_function={delete_function}
                    download_function={download_function}
                    dont_submit_type={dont_submit_type}
                    error_state={error_state}
                    allow_delete={allow_delete}
                    allow_download={allow_download}
                    allow_reupload={allow_reupload}
                    dynamic_action_call={dynamic_action_call}
                    default_file_upload={default_file_upload}
                    return_file_id={return_file_id}
                    return_file={return_file}
                    aspect_ratio={aspect_ratio}
                />;
            case 'drag_and_drop':
                return <DndProvider backend={HTML5Backend}>
                    <DragAndDrop
                        classes={classes}
                        items={value}
                        name={name}
                        disabled={disabled}
                        id={id}
                        key={key}
                        value={key_value}
                        on_change_dnd={(drag_index, hover_index, name, value) => this.on_change_dnd(drag_index, hover_index, name, value)}
                    />
                </DndProvider>;
            case 'textarea':
                return <Input
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    id={id}
                    name={name}
                    disabled={disabled}
                    maxLength={max_length}
                    className={classes + cn({' alert-border': error_state})}
                    onChange={(e) => this.on_change(e.target.name, e.target.value, e, function_name)}
                />;
            case 'color':
                return <Input
                    onBlur={(e) => this.on_change(e.target.name, e.target.value.toLowerCase(), e, function_name)}
                    type={type}
                    name={name}
                    id={id}
                    defaultValue={value}
                />;
            case 'searchable_select':
                return <>
                    {disabled ? <div className='search-select-loading'>
                        <Input type='text' placeholder='Bitte warten...' disabled/>
                        <i className="fas fa-angle-down"/>
                    </div> :
                        <SearchableSelect //Darf beim laden nicht auf disabled gesetzt werden sonst gehen die Options nicht mehr zu
                            name={'customer'}
                            options={seachable_select_options}
                            onChange={(value) => this.on_change(name, value, null, function_name)}
                            classes={classes + cn({' alert-border': error_state})}
                            value={value}
                            defaultValue={default_value}
                        />}
                </>;
            default :
                return null;
        }
    };

    render() {
        const {cropper_headline, cropper, optional, input_wrapper_classes} = this.props;
        const {is_loaded, skeleton, content_title, no_margin, no_padding, tooltip, id} = this.props;
        let skeleton_loading = (validateVar(skeleton)) ? skeleton : ['100%', 46]; //standard input field loading
        let is_loaded_ = (validateVar(is_loaded)) ? is_loaded : true;
        let content_title_ = (validateVar(content_title)) ? content_title : "";
        let default_margin = (no_margin) ? false : "mb-4";
        let default_padding = (no_padding) ? false : "pb-2";

        return (
            <>
                {(validateVar(cropper) && cropper + "" !== false + "") &&
                /* if cropper is true render cropper modal */
                <CropImageModal
                    cropper_headline={cropper_headline}
                    cropImg={(url) => this.cropLogo(url)}
                    img_url={this.state.image_source_url}
                    ref={this.crop_image_modal}
                />}
                {(validateVar(content_title_))
                    ?
                    /* get content with title  */
                    <TitledContent title={content_title_} optional={optional} tooltip={tooltip} id={validateVar(id) ? id+'_tooltip' : null}>
                        <div className={input_wrapper_classes + " " + cn({[default_margin]: default_margin})}>
                            {(is_loaded_) ?
                                this.get_struct_content()
                                :
                                <Skeleton width={skeleton_loading[0]} height={skeleton_loading[1]}/>}
                        </div>
                    </TitledContent>
                    :
                    <div
                        className={input_wrapper_classes +  cn({[" " +default_margin]: default_margin}) + cn({[' ' +default_padding]: default_padding})}> {/* pb-2 for inputs without content title for extra separation space to next input Example: Login.jsx */}
                        {(is_loaded_) ?
                            /* get content without title  */
                            this.get_struct_content()
                            :
                            <Skeleton width={skeleton_loading[0]} height={skeleton_loading[1]}/>}
                    </div>
                }
            </>
        )
    }
}

InputHandler.defaultProps = {
    input_wrapper_classes: 'input-handler-wrapper'
}

InputHandler.propTypes = {
    //global props
    type: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.object.isRequired //date is an object
    ]),
    prev_value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object //date is an object
    ]),
    disabled: PropTypes.bool,
    content_title: PropTypes.string,
    on_change: PropTypes.func,
    error_state: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    prefix_addon_class: PropTypes.string,
    suffix_addon_class: PropTypes.string,
    function_name: PropTypes.string,
    optional: PropTypes.bool,
    on_focus: PropTypes.func,
    on_blur: PropTypes.func,
    is_loaded: PropTypes.bool,
    no_padding: PropTypes.bool, //removes padding bottom
    no_margin: PropTypes.bool, //removes margin bottom

    //text / number props
    input_group_text: PropTypes.string,
    max_length: PropTypes.number,
    placeholder: PropTypes.string,
    input_wrapper_classes: PropTypes.string,
    remove_zero_on_focus: PropTypes.bool,

    //select props
    select_options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            text: PropTypes.string
        })
    ),
    remove_empty_option: PropTypes.bool,

    //multiselect props
    multiselect_options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            text: PropTypes.string
        })
    ),

    //searchable select props
    seachable_select_options: PropTypes.arrayOf(
        PropTypes.shape({

        })
    ),

    //checkbox props
    text: PropTypes.string,
    label_classes: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    unchecked_value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    checked_value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    //radio props
    first_radio: PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,  // Displayed Radio-Text
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
    }),
    second_radio: PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string, // Displayed Radio-Text
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
    }),

    //file upload/logoutEmployee
    allow_delete: PropTypes.bool,
    allow_download: PropTypes.bool,
    allow_reupload: PropTypes.bool,
    upload_function: PropTypes.func,
    download_function: PropTypes.func,
    delete_function: PropTypes.func,
    return_file_id: PropTypes.func,
    return_file: PropTypes.func,
    public_static: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    file_id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    aspect_ratio: PropTypes.number,
    file_type: PropTypes.oneOf('pdf', 'image', 'csv', 'allow_all'),
    uploading: PropTypes.bool,
    upload_finished: PropTypes.bool,

    //date
    date_type: PropTypes.oneOf(['start', 'end']),
    min_date: PropTypes.object,
    max_date: PropTypes.object,
    show_time: PropTypes.bool,
    default_empty: PropTypes.bool,
};

export default InputHandler;