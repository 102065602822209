export default function get_agency_type(type) {
    if (type === "direct") return "Direkt";
    else if (type === "consultant") return "enermakler";
    else if (type === "supplier") return "Versorger";
    else if (type === "affiliate") return "Affiliate ";
    else if (type === "market_place") return "Marktplatz";
    else if (type === "wattline") return "Wattline";
    else if (type === "e.hub") return type;
    else return "N/A"

}
