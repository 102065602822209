let initialState = null;

function consultants(state = initialState, action) {
  if (action.type === "GET_ALL_CONSULTANT") {
    return action.res;
  } else if (action.type === "UPDATE_PARTNERS") {
    return action.res;
  } else {
    return state;
  }
}

export default consultants;
