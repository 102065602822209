let initialState = null;

function contract_templates(state = initialState, action) {
  if (action.type === "GET_CONTRACT_TEMPLATE_LIST") {
    return action.res;
  } else {
    return state;
  }
}

export default contract_templates;
