import CommunicationNew from "../Objects/Components/Communication/communication_new";
import Communication from "../Objects/Components/Communication/communication";
import validateVar from "./validation/validateVariable";

function getCommunicationClass(params, file = null) {
    if (validateVar(params.service)) {
        //alter request
        return new Communication(
            params.service, // url with function call (if mothode: "GET" with get params in URL)
            params.get_function,
            params.update_function,
            params.delete_function,
            params.add_function,
            params.download_function,
            validateVar(params.params) ? params.params : null,
            validateVar(file) ? file : null,
            validateVar(params.version) ? params.version : null,
        );
    } else if (validateVar(params.function_url) && validateVar(params.method)) {
        return new CommunicationNew(
            params.method, // call mothods (GET / PUT / POST / DELETE)
            params.function_url, // url with function call (if mothode: "GET" with get params in URL)
            validateVar(params.params) ? params.params : null,
            validateVar(file) ? file : null,
            validateVar(params.is_download) ? params.is_download : null,
            validateVar(params.file_type) ? params.file_type : null,
            validateVar(params.version) ? params.version : null,
        )
    } else alert("No Function Found")
}
export default getCommunicationClass;