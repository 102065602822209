function isValidDateCustom(dateObj){
    if (Object.prototype.toString.call(dateObj) === "[object Date]") {     // is it a date
        if (isNaN(dateObj.getTime())) {  // d.valueOf() could also work
            return false;   // date is not valid
        } else {
            return true;  // date is valid
        }
    } else {
        return false;  // not a date
    }
}
export default isValidDateCustom;