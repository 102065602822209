let initialState = null;

function employee(state = initialState, action) {
  if (action.type === "GET_BANK_ACCOUNT") {
    return action.res;
  } else if (action.type === "UPDATE_BANK_ACCOUNT") {
    return action.res;
  } else if (action.type === "CREATE_BANK_ACCOUNT") {
    return action.res;
  } else {
    return state;
  }
}

export default employee;
