import is_true from "./is_true";
import is_false from "./is_false";
import get_standardised_date from "./get_standardised_date";

function handleFormatDate(date, with_time = false, without_year = false, without_month = false, without_day = false) {
  var dt = new Date(get_standardised_date(date));
  let return_date = '';
  if(is_false(without_year)) return_date = dt.getFullYear()+'-';
  if(is_false(without_month)) return_date = return_date+("0" + (dt.getMonth() + 1)).slice(-2)+'-';
  if(is_false(without_day)) return_date = return_date+("0" + dt.getDate()).slice(-2);

  if (is_true(with_time)) {
      return_date = return_date + ' '+ ('0' + dt.getHours()).slice(-2) + ":" + ('0' + dt.getMinutes()).slice(-2) + ":" + ('0' + dt.getSeconds()).slice(-2);
  }
  return return_date;
}
export default handleFormatDate;
