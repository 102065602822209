import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";
import callClassWithString from "../../../Functions/callClassWithString";

let customer_information_vars = {
    // class_name: "customer_information",
    // is_sub_arr: "customer_information",
    translation_object: {
        customer_company: "Firma (Kunde)",
        customer_last_name: "Nachname (Kunde)",
        customer_first_name: "Vorname (Kunde)",
        customer_email: "E-Mail (Kunde)",
        customer_phone: "Tel. (Kunde)",
    },
    first_name: {
        customer_first_name: null,
    },
    last_name: {
        customer_last_name: null
    },
    email: {
        customer_email: null
    },
    phone: {
        customer_phone: null
    },
    company: {
        company: null,
        customer_company: {
            function_name: "get_customer_company_name",
            params: ["%%company", "%%customer_first_name", "%%customer_last_name"]
        },
    },
    full_name: {
        function_name: 'combine_strings',
        params: ['%%first_name', ' ', '%%last_name']
    },
    company_display: {
        function_name: 'get_customer_company_name',
        params: ['%%company', '%%first_name', '%%last_name']
    },
    contacts: {
        pfc_email: {
            function_name: "loop_and_push",
            params: ["", "email"]
        }
    },
    date_of_birth: null,
    gender: {
        gender: null,
        gender_display: {
            function_name: 'getGenderDisplay',
        }
    },
    billing_address: {
        is_class_arr: "billing_address",
        is_top_level: true
    },
    bank_account: {
        is_class_arr: "bank_account",
        is_top_level: true
    },
};

class Customer_Information {
    static default_values = customer_information_vars;

    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(customer_information_vars, data, data.history, [], status, (status === "all"));
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static dummy() {
        let output = {};
        output = this.build_dummy(customer_information_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    } else if (validateVar(vars[index].is_class_arr)) {

                        let class_name = callClassWithString(index);
                        output[index] = class_name.constructor.dummy();
                    } else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default Customer_Information;
