import {Input} from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import validateVar from '../Functions/validation/validateVariable';
import cn from 'classnames';
import {empty_select_value} from "../Functions/global";

const Select = (props) => {
    function internalChange(e) {
        props.on_change(e.target.name, e.target.options[e.target.selectedIndex].value);
    }

    let options = (validateVar(props.options)) ? props.options : [];
    let defaultValue = validateVar(props.defaultValueText) ? props.defaultValueText : 'Bitte wählen ...';

    return(<div className='w-100 position-relative custom-select-arrow'>
        <Input
            name={props.name}
            onChange={(e) => internalChange(e)}
            disabled={props.disabled}
            id={props.id}
            value={props.value+''}
            defaultValue={props.value+''}
            className={props.classes}
            type='select'>
            {!props.remove_empty_option &&
            <option key='empty' value={empty_select_value}>{defaultValue}</option>}
                {options.map((option) => {
                    let key = option.key+'';
                    if (props.value === key) return <option selected={true} key={key} disabled={option.disabled} value={key}>{option.value}</option>;
                    else return <option key={key}
                            className={" "+cn(option.class ,{'disabled_select': option.disabled})}
                            disabled={option.disabled}
                            value={key}>{option.value}
                        </option>
                })}
    </Input>
    </div>)
};

Select.propTypes = {
    id: PropTypes.string.isRequired,
    on_change: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
    ]),
    label: PropTypes.string,
    classes: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            text: PropTypes.string
        })
    ),
};

export default Select;
