import React from 'react';
import {Card, CardBody} from "reactstrap";

class NoTariffsFound extends React.Component {
    render() {
        return(<Card className='calculated-tariff mb-0'>
                <CardBody className='text-center'>
                    <div className="mb-3 swal2-icon swal2-warning swal2-animate-warning-icon d-flex"><span
                        className="swal2-icon-text">!</span></div>
                    <h2>Keine Angebote gefunden</h2>
                    <div className='pt-2 pl-5 pr-5 pb-3 max-text-width'>
                        Leider konnten wir keine Angebote finden. Die Ursache hierfür kann häufig am gewählten Lieferzeitraum, der Bonität des Vertragpartners oder
                        den Lieferstellen liegen. Bitte passen Sie die Kalkulationsdaten an oder kontaktieren Sie unseren Support, um Angebote zu erhalten.
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default NoTariffsFound;