import validateVar from "./validation/validateVariable";

export function combine_strings_from_array (array){
    let return_string = "";
    array.map((string) => {
        if(validateVar(string)) return_string = return_string + string;
    });

    //if string contains only spaces return empty string
    if(!return_string.replace(/\s/g, '').length) return '';
    return return_string;
}



