import React from 'react';
import validateVar from "../Functions/validation/validateVariable";
import PropTypes from "prop-types";
import createStaticURL from "../Functions/createStaticURL";
import {
    create_url, reset_url
} from "../Index/index";
import connect from "react-redux/es/connect/connect";
import is_true from "../Functions/is_true";
import isEmptyObject from "../Functions/isEmptyObject";
import DownloadAndUploadField from "./DownloadAndUploadField";
import ContractDocumentComponent from "./ContractDocumentComponent";

//TODO Das wird nur für clearing benutzt, es sollte demnächst auseinander genommen werden und über DownloadAndUploadField oder Direkt über InputHandler laufen
class DownloadUploadHandler extends React.Component {
    constructor(props) {
        super(props);

        this.title = props.title;
        this.state = {
            no_values_found: false, // if no values for showing the component was found -> return nothing
            check_for_history: false, // if no values for showing the component was found -> return nothing
            is_loaded_internal: false, // if no values for showing the component was found -> return nothing
            is_loaded: false, // if no values for showing the component was found -> return nothing
            history: {},
            file_name: "default",
        };
    }

    componentDidMount() {
        const {is_loaded} = this.props;
        if (is_true(is_loaded)) {
            this.init_data(is_loaded);
        }
    }

    componentDidUpdate(prevProps) {
        let {url_obj, is_loaded, property_name} = this.props;
        let {check_for_history} = this.state;
        if (validateVar(url_obj) && url_obj.url !== prevProps.url_obj.url && url_obj.property_name === property_name) {
            let state = (is_true(check_for_history)) ? "file_url" : "file_url_history";
            this.setState({
                [state]: url_obj.url,
                file_name: (validateVar(url_obj.file_name)) ? url_obj.file_name : property_name
            });
            this.props.reset_url();
            if (is_true(check_for_history)) this.create_url(check_for_history);
        }
        if (validateVar(is_loaded) && is_loaded !== prevProps.is_loaded) {
            if (is_true(is_loaded)) this.init_data(is_loaded);
            else this.setState({is_loaded: false})
        }
    }

    init_data = (is_loaded) => {
        const {border_bottom, property_name, object, is_static, disable_upload, download_params, params_options} = this.props;
        this.border_bottom = border_bottom;
        this.property_name = property_name; // var name
        this.object = object; // class object
        this.is_static = (validateVar(is_static)) ? is_static : false; // static = can be displayed / if not create url via request
        this.download_params = download_params; // get (getURL) function
        this.params_options = params_options; // get (getURL) function

        this.setState({
            is_loaded: (validateVar(is_loaded)) ? is_loaded : false,
            disable_upload: (validateVar(disable_upload)) ? disable_upload : false,
        });
        if(validateVar(property_name) &&  validateVar(object) && validateVar(object[property_name])){ // check if all needed data is given
            let download_urls_obj = this.get_download_urls(object); // check if public/static and return new urls / else trigger request for downloading new urls from api
            if (validateVar(download_urls_obj)) { // if not null -> public static urls are returned
                let {file_url, file_url_history} = download_urls_obj;
                this.setState({
                    file_url: file_url,
                    file_url_history: file_url_history
                })
            }
            this.setState({
                history: (validateVar(this.object.history[this.property_name])) ? this.object.history[this.property_name] : {}
            })
        } else {
            this.setState({
                no_values_found: true // return no value in render (no valid data found)
            })
        }
    };


    get_history_value() {
        let object = this.object;
        if(validateVar(object) && validateVar(object.history) && validateVar(object.history[this.property_name])) {
            let history = object.history;
            return history[this.property_name];
        } else return null;
    }

    get_download_urls = (object) => {
        let history_obj = object.history;
        if (is_true(this.is_static)) {
            let file_url = createStaticURL(object[this.property_name]);
            let file_url_history = (validateVar(this.get_history_value())) ? createStaticURL(history_obj[this.property_name]) : null;
            return {
                file_url: file_url,
                file_url_history: file_url_history,
            };
        } else {
            this.create_url();
            return null;
        }
    };

    define_download_params = (params, params_object, from_object = false, is_history) => {
        let object = this.object;
        if (validateVar(params_object) && !isEmptyObject(params_object)) {
            for(let param_name in params_object) {
                if (params_object.hasOwnProperty(param_name)) {
                    if (param_name !== "object") {
                        let param_value = params_object[param_name];
                        if (is_true(from_object))  {

                            if (is_history ) {
                                param_value = (validateVar(object.history) && validateVar(object.history[param_value] && validateVar(object.history[param_value].value))) ? object.history[param_value].value :  param_value;
                            } else{
                                param_value = validateVar(object[param_value]) ? object[param_value] :  param_value;
                            }
                        }
                        params[param_name] = param_value;
                    }
                }
            }
        }
        return params;
    };

    get_download_request_params = (is_history = false) => {
        let {download_params, params_options} = this;
        let params = {};
        params = this.define_download_params(params, download_params, false);
        params = this.define_download_params(params, download_params.object, true, is_history);
        if (validateVar(params_options)) {
            params.options = params_options;
        }
        params.property_name = this.props.property_name;
        return params;
    };

    create_url = (is_history = false) => {
        let {function_name, class_object, property_name} = this.props;

        class_object = (validateVar(class_object)) ? class_object : "url"; // get class object for (getURL) function
        function_name = validateVar(function_name) ? function_name : "createURL"; // get (getURL) function

        let file_id = this.object[this.property_name];
        if (is_true(is_history) && validateVar(this.get_history_value())) {
            let history = this.get_history_value();
            file_id = (validateVar(history.value)) ? history.value : file_id;
        }
        if (file_id) {
            let params = (validateVar(this.download_params) && !isEmptyObject(this.download_params)) ? this.get_download_request_params(is_history) :  {
                file_id: file_id,
                return_url: true, // todo default true -> needs to be in props
                property_name: property_name
            };
            this.setState({
                check_for_history: !is_history
            });
            this.props.create_url(params, class_object, function_name);
        }
    };

    is_value_edited = (history) => {
        return (validateVar(history) && !isEmptyObject(history))
    };

    get_content = () => {
        const {title, border_bottom} = this;
        const {type, upload} = this.props;
        const {edit_changes, api_call} = this.props; //functions
        const {file_type, palceholder, property_name} = this.props; // download_and_upload_field
        const {dynamic_action_call, default_file_upload} = this.props; // contract_document_component
        const {history, is_loaded, disable_upload, file_url_history, file_url, no_values_found, file_name} = this.state;

        switch(type){
            case 'download_and_upload_field':
                return <DownloadAndUploadField download_url={file_url}
                    upload={upload}
                    file_name={file_name}
                    file_type={file_type}
                    palceholder={palceholder}
                    name={property_name}
                    title={title}
                    disable_upload={disable_upload}
                    is_loaded={is_loaded} />;
            case 'contract_document_component':
                return <ContractDocumentComponent no_values_found={no_values_found}
                                                  history={history}
                                                  border_bottom={border_bottom}
                                                  file_url_history={file_url_history}
                                                  file_name={file_name}
                                                  title={title}
                                                  is_loaded={is_loaded}
                                                  file_url={file_url}
                                                  edit_changes={edit_changes}
                                                  api_call={api_call}
                                                  disable_upload={disable_upload}
                                                  dynamic_action_call={dynamic_action_call}
                                                  default_file_upload={default_file_upload}
                                                  upload={upload} />;
            default:
                return <></>
        }
    };


    render() {
        return this.get_content();
    }
}

DownloadUploadHandler.propTypes = {
    title: PropTypes.string.isRequired,
    file_name: PropTypes.string.isRequired,
    property_name: PropTypes.string.isRequired,
    object: PropTypes.object,
    object_history: PropTypes.object,
    border_bottom: PropTypes.bool,
    upload_file: PropTypes.func.isRequired,
    accept_changes: PropTypes.func,
    discard_changes: PropTypes.func,
};

let mapStateToProps = function (state) {
    return {
        url_obj: state.url,
    }
};

let mapDispatchToProps = {
    create_url: create_url,
    reset_url: reset_url
};

let DownloadUploadHandlerContainer = connect(mapStateToProps, mapDispatchToProps)(DownloadUploadHandler);

export default DownloadUploadHandlerContainer;