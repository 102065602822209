import React from 'react';
import {Card, CardBody, CardHeader, Modal} from "reactstrap";
import CardHeaderText from "../../../module_components/PageContent/CardHeaderText";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import Validation from "../../../module_components/Objects/Components/validation";

class ChangePasswordModal extends React.Component {
    state = {
        form_data: {
            old_password: '',
            new_password: '',
            confirm_password: ''
        },
        error_messages: {},
        show_modal: false,
    };


    toggle_modal = () => {
        this.setState({show_modal: !this.state.show_modal})
    };

    turn_off_modal(){
        this.setState({show_modal: false});
    };

    update_state = (state_name, value) => {
        let {form_data} = this.state;
        form_data[state_name] = value;
        this.setState({form_data: form_data});
    };

    handleSubmit = () => {
        const {form_data} = this.state;
        // validation start
        // define special cases for error validation
        let special_cases = {
            new_password: {case_name: "new_password"},
            confirm_password: [{
                case_name: "compare_values",
                compare_value: {confirm_password: form_data.new_password},
                error_message: "Die Passwörter stimmen nicht miteinander überein.",
                is_equal: true
            }],
        };
        // call abstract error validation and set values in state
        let validation_result = new Validation(form_data, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        if(!validation_result.has_error){
            let params = {
                old_password: form_data.old_password,
                password: form_data.new_password,
            };
            this.props.onSubmit(params);
            this.turn_off_modal();
        }
    };

    render() {
        const {form_data, error_messages, show_modal} = this.state;
        const {old_password, new_password, confirm_password} = form_data;

        return(<Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={show_modal}
            toggle={() => this.toggle_modal()}
        >
            <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent modal-title">
                        <CardHeaderText title='Passwort ändern' />
                    </CardHeader>
                    <CardBody className="px-lg-4 py-lg-4">
                        <InputHandler
                            content_title="Altes Passwort"
                            type="password"
                            name="old_password"
                            placeholder={"Geben Sie Ihr altes Passwort ein"}
                            value={old_password}
                            disabled={false}
                            on_change={(name, value) => this.update_state(name, value)}
                            error_state={error_messages.old_password_error}
                        />

                        <InputHandler
                            content_title="Neues Passwort"
                            type="password"
                            name="new_password"
                            placeholder={"Neues Passwort (mind. 6 Ziffern)"}
                            value={new_password}
                            disabled={false}
                            on_change={(name, value) => this.update_state(name, value)}
                            error_state={error_messages.new_password_error}
                        />

                        <InputHandler
                            content_title="Passwort wiederholen"
                            type="password"
                            name="confirm_password"
                            placeholder="Neues Passwort wiederholen"
                            value={confirm_password}
                            disabled={false}
                            on_change={(name, value) => this.update_state(name, value)}
                            error_state={error_messages.confirm_password_error}
                        />

                        <PrimaryButton id='saveSettings' onClick={() => this.handleSubmit()} value='Passwort ändern' classes='btn-modal w-100 mt-3' />
                    </CardBody>
                </Card>
            </div>
        </Modal>)
    }
}

export default ChangePasswordModal;