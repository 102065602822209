import React from 'react';
import Skeleton from "react-loading-skeleton";

const IntegrationsTableLoading = () => {

    return(
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="pt-4 custom-table">
                        <div className="container-fluid">
                            <div className="mb-4 row">
                                <div className="table-title-wrapper pb-2 col-6 col-lg-3"><Skeleton height={27} width={'100%'} /></div>
                                <div className="text-right col-6 col-lg-9">
                                    <span><Skeleton height={27} width={200} /></span></div>
                            </div>
                        </div>
                        <div className="react-bootstrap-table table-responsive mb-0">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th />
                                    <th className="sortable"><Skeleton height={27} width={200} /></th>
                                    <th className="sortable"><Skeleton height={27} width={200} /></th>
                                    <th />
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <Skeleton height={27} width={200} />
                                    </td>
                                    <td><Skeleton height={27} width={200} /></td>
                                    <td>
                                        <Skeleton height={27} width={200} />
                                    </td>
                                    <td className="linked-col"><Skeleton height={27} width={200} /></td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton height={27} width={200} />
                                    </td>
                                    <td><Skeleton height={27} width={200} /></td>
                                    <td>
                                        <Skeleton height={27} width={200} />
                                    </td>
                                    <td className="linked-col"><Skeleton height={27} width={200} /></td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton height={27} width={200} />
                                    </td>
                                    <td><Skeleton height={27} width={200} /></td>
                                    <td>
                                        <Skeleton height={27} width={200} />
                                    </td>
                                    <td className="linked-col"><Skeleton height={27} width={200} /></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntegrationsTableLoading;