import React, {useState} from 'react';
import {Nav, NavItem, NavLink, TabContent} from "reactstrap";
import PropTypes from 'prop-types';
import classnames from "classnames";

function NavTabs (props) {
    const [active_tab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(active_tab !== tab) setActiveTab(tab);
    };

    const {tab_names, children} = props;

    let tabs_class = 'five-tabs';
    if(Array.isArray(tab_names)){
        if(tab_names.length === 4) tabs_class = 'four-tabs';
        else if(tab_names.length === 3) tabs_class = 'three-tabs';
        else if(tab_names.length === 2) tabs_class = 'two-tabs';
    }

    function get_index_name(loop_name) {
        if (Number.isInteger(loop_name)) {

        } else return loop_name
    }

    return(<>
        <Nav className={tabs_class} tabs>
            {tab_names.map((name, index) => {
                return (<NavItem key={index}>
                        <NavLink
                            className={classnames({ active: active_tab === index+1+'' })}
                            onClick={() => { toggle(index+1+''); }}
                        >
                            {name}
                        </NavLink>
                </NavItem>)
            })}
        </Nav>
        <TabContent activeTab={active_tab}>
            {children}
        </TabContent>
    </>)
}

NavTabs.propTypes = {
    tab_names: PropTypes.arrayOf(PropTypes.string)
}

export default NavTabs;