import validateVar from "../../Functions/validation/validateVariable";
import validateDate from "../../Functions/validation/validateDate";
import validateEmail from "../../Functions/validation/validateEmail";
import handleFormatIBAN from "../../Functions/handleFormatIBAN";
import validatePhoneNumber from "../../Functions/validation/valiatePhoneNumber";
import validateInteger from "../../Functions/validation/validateInteger";
import isEmptyObject from "../../Functions/isEmptyObject";
import validateMaloId from "../../Functions/validation/validateMaloId";
import is_false from "../../Functions/is_false";
import is_true from "../../Functions/is_true";

class Validation {
  /*
      all_values: object of values to validate
      special_cases: object of special validation cases
      Examples: {email: [{case_name: "compare_values", compare_value: employees}, {case_name: "email" validate_if:/optional_if: {required_field: 'email_checkbox', required_value: ture}}]},
      special_case.validate_if (validate if) is required in cases where the field is *visible* under some special condition referenced with other input
      special_case.optional_if (don't validate if) is required in cases where the field is *optional* under some special condition referenced with other input
      repeatable_values: array of repeatable objects to validate
   */
  constructor(all_values = null, special_cases = null, repeatable_values = null) {
    this.error_messages = {};
    this.has_error = false;

    if(validateVar(all_values)){
      this.all_values = all_values;
      for (let name in all_values) {
        if (all_values.hasOwnProperty(name)) {

          let value = all_values[name];

          this.validate_value(value, name, special_cases);
        }
      }
    }

    if(validateVar(repeatable_values)){
      for (let index in repeatable_values) {
        if (repeatable_values.hasOwnProperty(index)) {
          let all_values = repeatable_values[index];
          this.all_values = all_values;
          for (let name in all_values) {
            if (all_values.hasOwnProperty(name)) {

              let value = all_values[name];
              this.validate_value(value, name, special_cases, index);
            }
          }
        }


      }
    }
  }

  /*
     check if the conditions for the optional field are fulfilled
   */
  check_if_field_is_optional = (special_case_optional_if) => {
    let skip_validation = false;

    if (Array.isArray(special_case_optional_if.required_value)) {
      if (special_case_optional_if.required_value.includes(this.all_values[special_case_optional_if.required_field]))
        skip_validation = true; // skip validation -> value can be empty
    } else if (special_case_optional_if.required_value === this.all_values[special_case_optional_if.required_field]) {
      skip_validation = true; // skip validation -> value can be empty
    }

    return skip_validation;
  }

  validate_value = (value, name, special_cases, index = null) => {
    const error_state_name = name + "_error";
    let error_state_value = false;
    let skip_validation = false;
    let special_cases_ = validateVar(special_cases) ? special_cases[name] : null; // get special case if given
    if (!Array.isArray(special_cases_)) special_cases_ = [special_cases_]; // must be array to loop though in special cases (else it would just loop the propertys of obj)

    //check if empty && if can_be_empty
    if (!validateVar(value)) {
      if(validateVar(special_cases_) && validateVar(special_cases_[0])){
        let special_case_optional_if = special_cases_[0].optional_if;
        let special_case_validate_if = special_cases_[0].validate_if;

        //if field value is empty, check if its allowed to be empty
        if (special_cases_[0].case_name === "can_be_empty" || is_true(special_cases_[0].can_be_empty)){ //check if value can_be_empty
          skip_validation = true; // skip validation -> value can be empty
          this.error_messages[error_state_name] = error_state_value; //If field can be empty and it is empty, remove the error_message
        }
        else if(validateVar(special_case_optional_if)){ //check optional_if requirements if defined
          if(is_false(skip_validation)) skip_validation = this.check_if_field_is_optional(special_case_optional_if);
        }
        else if(validateVar(special_case_validate_if)){ //check validate_if requirements if defined
          if(Array.isArray(special_case_validate_if.required_value)){
            if(special_case_validate_if.required_value.includes(this.all_values[special_case_validate_if.required_field]))
              skip_validation = true; // skip validation -> value can be empty
          }else if(special_case_validate_if.required_value !== this.all_values[special_case_validate_if.required_field]){
            skip_validation = true // skip validation -> value can be empty
          }
        }
      }

      if(!skip_validation) { // field cannot be empty but it is!
        error_state_value = "Bitte tätigen Sie eine valide Eingabe.";
        this.set_error_value(error_state_name, error_state_value, index);
        this.has_error = true;
      }
    } else this.set_error_value(error_state_name, error_state_value, index);
    //set false if value given but go for further validation if case_name is given for current field

    if (
        !error_state_value &&
        validateVar(special_cases_[0]) &&
        special_cases_.length > 0 &&
        !skip_validation
    ) {
      // loop through all special cases
      for (let special_case of special_cases_) {
        if (!error_state_value) {
          if (special_case.case_name === "date_compare") {
            error_state_value = this.special_case_date_compare(value, special_case);
          } else if (special_case.case_name === "date") {
            if (!validateDate(value)) error_state_value = "Bitte geben Sie ein valides Datum an";
          } else if (special_case.case_name === "multi_select") {
            if (!validateVar(value) || value.length === 0) error_state_value = "Bitte wählen Sie eine Option.";
          } else if (special_case.case_name === "select") {
            if (value + "" === 0 + "" || !validateVar(value)) error_state_value = "Bitte wählen Sie eine Option";
          } else if (special_case.case_name === "iban") {
            error_state_value = this.special_case_iban(value);
          } else if (special_case.case_name === "email") {
            error_state_value = this.special_case_email(value);
          } else if (special_case.case_name === "phone") {
            error_state_value = this.special_case_phone(value);
          } else if (special_case.case_name === "postal_code") {
              error_state_value = this.special_case_postal_code(value);
          } else if (special_case.case_name === "float") {
            error_state_value = this.special_case_float(value);
          } else if (special_case.case_name === "new_password") {
            error_state_value = this.special_case_new_password(value, special_case);
          } else if (special_case.case_name === "compare_values") {
            error_state_value = this.special_case_value_compare(value, name, special_case);
          } else if (special_case.case_name === "value_smaller_then") {
            error_state_value = this.special_case_value_smaller_then(value, special_case);
          } else if (special_case.case_name === "file") {
            if (isEmptyObject(value)) error_state_value = "Bitte laden Sie eine Datei hoch";
          }else if (special_case.case_name === 'mean_consumption'){
            error_state_value = this.special_case_mean_consumption(value);
          }else if(special_case.case_name === 'market_identifier'){
            error_state_value = this.special_case_market_identifier(value, special_case);
          }else if(special_case.case_name === 'meter_identifier'){
            error_state_value = this.special_case_meter_identifier(value, special_case);
          }else if(special_case.case_name === 'checkbox'){
            if(is_false(value)) error_state_value = "Bitte bestätigen Sie diese Bedingung"
          }

          // if error message was set -> error was found
          if (error_state_value) this.has_error = true;
        }

        this.set_error_value(error_state_name, error_state_value, index);
      }
    }

    if(validateVar(index) && !validateVar(this.error_messages[index])) this.error_messages[index] = {}; //set empty object if not set yet to prevent undefined error messages on index
  };

  //This function sets the error state string or the "false" value correctly on the validation object result
  set_error_value = (name, value, index) => {
    if(validateVar(index)) {
      if(!validateVar(this.error_messages[index])) this.error_messages[index] = {};
      this.error_messages[index][name] = value; //in case repetable_values set the error_message on the right index
    }else this.error_messages[name] = value;
  }

  special_case_market_identifier(value, special_case) {
    let special_case_optional_if = special_case.optional_if;
    let skip_validation = false;
    if(validateVar(special_case_optional_if)){ //check optional_if requirements if defined
      skip_validation = this.check_if_field_is_optional(special_case_optional_if);
    }

    // if(skip_validation && value === 'Nachzutragen') return false;
    if(!validateMaloId(value)){
      return 'Die angegebene Marktlokations-ID ist ungültig.';
    }else return false;
  }

  special_case_meter_identifier(value, special_case) {
    let special_case_optional_if = special_case.optional_if;
    let skip_validation = false;
    if(validateVar(special_case_optional_if)){ //check optional_if requirements if defined
      skip_validation = this.check_if_field_is_optional(special_case_optional_if);
    }

    if(skip_validation && value === 'Nicht hinterlegt') return false;
    if(value === 'Nicht hinterlegt') return 'Geben Sie bitte die Zählernummer ein.';
  }

  special_case_mean_consumption(value) {
    value = value.replace(/\./g, '');
    if(value === '0' || !validateVar(value)) {
      return 'Geben Sie bitte den Jahresverbrauch ein.';
    }else if(+value < 1000){
      return 'Geben Sie bitte mindestens ein Wert von 1000 ein.'
    }else return false;
  }

  special_case_value_smaller_then(value, special_case) {
    if (validateVar(value)) {
      if (
          validateVar(special_case.compare_value) &&
          validateVar(this.all_values[special_case.compare_value])
      ) {
        if (+value > +this.all_values[special_case.compare_value])
          return "Der erste Wert darf nicht größer als der zweite Wert sein";
      }
    }
  }

  special_case_postal_code(postal_code) {
    if (postal_code.length !== 5 || !validateInteger(postal_code)) {
      return "Geben Sie bitte eine valide Postleitzahl ein.";
    } else return false;
  }

  special_case_new_password(password) {
    if (password.length < 6) {
      return "Das Passwort muss mindestens sechs Zeichen lang sein.";
    } else return false;
  }



  special_case_float(float_value) {
    if (float_value+"".slice(-1) === ",") {
      return "Bitte tätigen Sie eine valide Eingabe.";
    } else return false;
  }

  /*
            compares start date with end date and checks if start date is before end date
       */
  special_case_date_compare(value, special_case) {
    // value === start date
    if (validateDate(value)) {
      if (
          validateVar(special_case.compare_value) &&
          validateVar(this.all_values[special_case.compare_value])
      ) {
        let compare_value = this.all_values[special_case.compare_value];
        if (validateDate(compare_value)) {
          if (value > compare_value)
            return "Lieferbeginn kann nicht nach dem Lieferende liegen.";
          else if (value === compare_value)
            return "Lieferbeginn kann nicht Lieferende sein.";
        } else return "Es muss ein valides Lieferende ausgewählt sein.";
      } else alert("kein vergleichswert gefunden!");
    } else return "Bitte geben Sie ein valides Datum an.";
    return false;
  }

  /*
          expects a compare value object with key to check if the value which is validated was already in use of the compare object
       */
  special_case_value_compare(value, name, special_case) {
    // value === start date
    if (validateVar(value)) {
      if (
          validateVar(special_case) &&
          validateVar(special_case.compare_value) &&
          validateVar(name)
      ) {
        let compare_values = special_case.compare_value;
        let error_message = special_case.error_message
            ? special_case.error_message
            : "Es besteht bereits ein Datensatz mit dieser Eingabe.";
        let is_equal = special_case.is_equal ? special_case.is_equal : false;

        for (let key in compare_values) {
          if (compare_values.hasOwnProperty(key)) {
            let compare_value = compare_values[key];
            if (
                validateVar(compare_value) &&
                typeof compare_value === "object" &&
                compare_value !== null
            ) {
              // check in obj
              if (!is_equal && value + "" === compare_value[name] + "") {
                // get var with name out of the object ( no loop needed this way)
                return error_message;
              } else if (is_equal && value + "" !== compare_value[name] + "") {
                // get var with name out of the object ( no loop needed this way)
                return error_message;
              }
            } else {
              if (!is_equal && value + "" === compare_value + "") {
                return error_message;
              } else if (is_equal && value + "" !== compare_value + "") {
                return error_message;
              }
            }
          }
        }
      } else alert("kein vergleichswert gefunden!");
    } else
      return "Es ist ein Fehler bei dem validieren des Feldes aufgetreten.";
    return false;
  }

  special_case_iban(value) {
    if (handleFormatIBAN(value, "length") !== 22 || value === "invalid")
      return "Geben Sie bitte eine valide IBAN ein.";
    else return false;
  }

  special_case_email(value) {
    if (!validateEmail(value))
      return "Geben Sie bitte eine gültige E-Mail Adresse ein.";
    else return false;
  }

  special_case_phone(value) {
    if (!validatePhoneNumber(value))
      return "Geben Sie bitte eine gültige Telefonnummer ein.";
    else return false;
  }
}

export default Validation;
