import {deHashVar} from "./handleHashedVariable";
import validateVar from "./validation/validateVariable";
import {project_name} from "./global";

function getUserRole(){
    
    let account_title = "";
    if (project_name === "EVU_PROJECT") account_title = "evuUserAccount";
    else account_title = "consultantUserAccount";
    
    
    let role = deHashVar(localStorage.getItem(account_title));
    if (validateVar(role))
        return role;
    return "default";
}
export default getUserRole;