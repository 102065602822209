import React from 'react';
import {Card, CardBody, CardHeader, Col, Modal, Row} from "reactstrap";
import InputHandler from "../../../Inputs/InputHandler";
import PrimaryButton from "../../../Displays/Buttons/PrimaryButton";
import Validation from "../../../Objects/Components/validation";
import is_false from "../../../Functions/is_false";
import is_true from "../../../Functions/is_true";
import validateVar from "../../../Functions/validation/validateVariable";

class FileUploadModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            error_messages: {},
            file: {},
            loading: false,
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {is_loaded} = this.props;

        if(is_true(is_loaded) && is_loaded !== prevProps.is_loaded){
            this.setState({loading: false});
        }
    }

    toggleModal = () => {
        this.setState({loading: false, showModal: !this.state.showModal,})
    };

    turnOffModal = () => {
        this.setState({loading: false, showModal: false, file: {},});
    };

    update_state = (name, value) => {
        this.setState({[name]: value});
    };

    submit = () => {
        const {name, is_loaded} = this.props;
        const {file} = this.state;

        let validation_params = {
            [name]: file
        };

        let special_cases = {
            [name]: {case_name: 'file'}
        };

        let validation_result = new Validation(validation_params, special_cases);
        this.setState({error_messages: validation_result.error_messages});

        if(is_false(validation_result.has_error)){
            this.props.submit(file)
            if(validateVar(is_loaded)) this.setState({loading: true})
            else this.turnOffModal();
        }
    };

    render(){
        const {modal_title, field_title, value, name, file_type, file_id, public_static, upload_function} = this.props;
        const {dynamic_action_call, default_file_upload} = this.props;
        const {loading, showModal, error_messages} = this.state;

        return(
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={showModal}
                toggle={() => this.toggleModal()}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent modal-title">
                            <Row>
                                <Col xs={10}>
                                    <h2 className={'mb-0'}>{modal_title}</h2>
                                </Col>
                                <Col xs={2}>
                                    <button onClick={() => this.toggleModal()} className={'close'} ><i className={'ni ni-fat-remove close-button'} /></button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="px-lg-4 py-lg-4">
                            <div>
                                <InputHandler content_title={field_title}
                                              type="file_upload_new"
                                              name={name}
                                              value={value}
                                              file_type={file_type} // images / pdf / xlsx -> default
                                              file_id={file_id}
                                              public_static={public_static}
                                              upload_function={upload_function}
                                              return_file={(file) => this.update_state("file", file)}
                                              error_state={error_messages[name+'_error']}
                                              allow_reupload={true}
                                              dynamic_action_call={dynamic_action_call}
                                              default_file_upload={default_file_upload}
                                              no_padding
                                />
                            </div>
                            <div className="text-center">
                                <PrimaryButton loading={loading} disabled={loading} onClick={() => this.submit()} value='Änderungen bestätigen' classes='btn-modal full-width-button mt-3' />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        );
    };
}

export default FileUploadModal;