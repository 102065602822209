import { emptyValue, shortMonthTranslations } from "../Functions/global";

let initialState = {
  chart_data: null,
  overview_tasks: { open_contracts: emptyValue, open_clearings: emptyValue, all_open_clearings: emptyValue }
};

function analytics(state = initialState, action) {
    let new_state = JSON.parse(JSON.stringify(state));
  if (action.type === "INITIALIZE_CHART_DATA") {
    let analytics = action.res;
    let months_eng = Object.keys(analytics);
    let months_ger = [];
    for (let month of months_eng) {
      let translation = shortMonthTranslations.filter(obj => {
        return obj.eng === month;
      });
      if (translation.length === 1) months_ger.push(translation[0].ger);
      else months_ger.push(month);
    }

    let result = {
      months: months_ger,
      GAS_B2B: [],
      GAS_B2C: [],
      ELECTRICITY_B2B: [],
      ELECTRICITY_B2C: []
    };

    let analytics_array = Object.entries(analytics);
    for (let month of analytics_array) {
      result.GAS_B2B.push(month[1].GAS.B2B);
      result.GAS_B2C.push(month[1].GAS.B2C);
      result.ELECTRICITY_B2B.push(month[1].ELECTRICITY.B2B);
      result.ELECTRICITY_B2C.push(month[1].ELECTRICITY.B2C);
    }

    new_state.chart_data = result;
    return new_state;
  } else if (action.type === "INITIALIZE_OVERVIEW_TASKS") {
    let res = action.res;
    let open_clearings_sum = 0;
    if (res.all_open_clearings !== emptyValue && res.all_open_clearings > 0){
        open_clearings_sum = +res.all_open_clearings;
    }
    if (res.open_clearings !== emptyValue && res.open_clearings > 0) {
        open_clearings_sum = open_clearings_sum  + +res.open_clearings;
    }
    action.res.open_clearings_sum = open_clearings_sum;
    new_state.overview_tasks = action.res;
    return new_state;
  } else {
    return state;
  }
}

export default analytics;
