/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// reactstrap components
import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AuthHeader from "../../../module_components/Displays/Headers/AuthHeader";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import {resetNotification, activateNewPasswordEmployee, redirect} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import getUrlParam from "../../../module_components/Functions/getUrlParams";
import {LoginPath} from "../../../globalNavigation";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import Validation from "../../../module_components/Objects/Components/validation";

class SetNewPassword extends React.Component {
    state = {
        form_data: {
            password: '',
            password_repeat: '',
        },
        error_messages: {},
        loading: false,
        token: "",
    };

    componentDidMount() {
        let url_params = getUrlParam();
        this.setState({
            token: url_params["reset"],
        });
        document.body.classList.add("eless-bg");
    }

    componentWillUnmount() {
        document.body.classList.remove("eless-bg");
    }

    handle_key_press = (e) => {//Allow submit on "Enter" press
        if (e.key === 'Enter') {
            this.handle_submit();
        }
    };

    change_form_data = (name, value) => {
        let {form_data} = this.state;
        form_data[name] = value;
        this.setState({form_data: form_data});
    };

    go_to_login = () => {
        this.props.redirect(LoginPath)
    };

    handle_submit = () => {
        const {form_data, token} = this.state;

        // validation start
        // define special cases for error validation
        let special_cases = {
            password: {case_name: "new_password"},
            password_repeat: [{
                case_name: "compare_values",
                compare_value: {password: form_data.password},
                error_message: "Die Passwörter stimmen nicht miteinander überein.",
                is_equal: true
            }],
        };
        // call abstract error validation and set values in state
        let validation_result = new Validation(form_data, special_cases);
        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        if(!validation_result.has_error){
            this.setState({loading: true});
            let data = {
                password: form_data.password,
                reset_token: token
            };
            this.props.onSubmit(data);
        }
    };

    render() {
        const {error_messages, form_data, loading} = this.state;
        const {password, password_repeat} = form_data;

        return (<div className="main-content" ref="mainContent">
            <AuthHeader title="Neues Passwort setzen" lead=""/>
            <Container className="mt--8 pb-5">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                        <Card className="bg-secondary border-0 mb-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <div className="text-center text-muted mb-4">
                                    <small>Geben Sie bitte das neue Passwort ein.</small>
                                </div>
                                <InputHandler
                                    type='password'
                                    name='password'
                                    value={password}
                                    placeholder='Passwort'
                                    on_change={this.change_form_data}
                                    prefix_addon_class='lock-circle-open'
                                    on_key_down={this.handle_key_press}
                                    error_state={error_messages.password_error}
                                />

                                <InputHandler
                                    type='password'
                                    name='password_repeat'
                                    value={password_repeat}
                                    placeholder='Passwort Widerholen'
                                    on_change={this.change_form_data}
                                    prefix_addon_class='lock-circle-open'
                                    on_key_down={this.handle_key_press}
                                    error_state={error_messages.password_repeat_error}
                                />

                                <div className="text-center">
                                    <PrimaryButton disabled={loading} onClick={() => this.handle_submit()} value='Senden' classes='btn-modal w-100' />
                                </div>
                            </CardBody>
                        </Card>
                        <Row className="mt-3">
                            <Col xs="6">
                              <span className="text-light cursor-pointer"
                                  onClick={() => this.go_to_login()}>
                                <small>Zum Login</small>
                              </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>);
    }
}

let mapStateToProps = function(state)   {
    return {
        message_feedback: state.message_feedback,
    }
};

let mapDispatchToProps = {
    onSubmit: activateNewPasswordEmployee,
    onResetNotification: resetNotification,
    redirect: redirect
};

let SetNewPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(SetNewPassword);

export default SetNewPasswordContainer;


