import React from 'react';
import SelectSearch from 'react-select-search';
import validateVar from "../Functions/validation/validateVariable";
//https://www.npmjs.com/package/react-select-search
class SearchableSelect extends React.Component{

    render() {
        let {name, options, disabled, value} = this.props;

        if(!validateVar(options) || options.length === 0) options = [{name: 'Keine Daten vorhanden', value: null, disabled: true}];
        return(<SelectSearch
            className='select-search eless-select-search'
            options={options}
            printOptions={'on-focus'}
            value={value}
            defaultValue={value}
            onChange={this.props.onChange}
            name={name}
            placeholder="Bitte auswählen..."
            disabled={disabled}
            search/>)
    }
}

export default SearchableSelect;