import is_true from "../Functions/is_true";
import validateVar from "../Functions/validation/validateVariable";

let initialState = {};

function tariff(state = initialState, action) {
  let new_state = {...state};
  if (action.type === "CALCULATED_TARIFFS") {
      return get_return_state("tariff", action.res, new_state);
  } else if (action.type === "INITIALIZE_TARIFF") {
    return get_return_state("tariff", action.res, new_state);
  } else if (action.type === "INITIALIZE_TARIFF_LIST") {
    return get_return_state("tariff_list", action.res, new_state);
  } else if (action.type === "UPDATE_TARIFF") {
      return get_return_state("tariff", action.res, new_state);
  } else if (action.type === "DELETE_TARIFF") {
    let tariffs = [];
    let tariff_ids = action.ids; // deleted object ids
    for (let tariff of state.tariff_list) {
      // loop through current state
      if (!tariff_ids.includes(tariff.id)) {
        // check if delted ids are matchting current id
          tariffs.push(tariff); // only push not deleted objects into the new state
      }
    }
      return get_return_state("tariff_list", tariffs, new_state);
  } else if (action.type === "INITIALIZE_SINGLE_TARIFF") {
      return get_return_state("tariff", action.res, new_state);
  } else if (action.type === "APPEND_TO_TARIFF_LIST") {
      let tariff_list = action.res; // deleted object ids
      let tariffs = (validateVar(new_state.tariff_list)) ? new_state.tariff_list : [];
      tariffs = [...tariffs, ...tariff_list];
      return get_return_state("tariff_list", tariffs, new_state);
  } else if (action.type === "ADD_TARIFF") {
      return get_return_state("tariff", action.res, new_state);
  } else if (action.type === "UPDATE_TARIFF_TABLE") {
      return get_return_state("tariff_list", action.res, new_state);
  } else if (action.type === "TARIFF_GET") {
      return get_return_state("tariff", action.res, new_state);
  } else if (action.type === "TARIFF_RESET") {
      return get_return_state("tariff", action.res, new_state);
  }else if (action.type === "TARIFF_RESET_LIST") {
      return get_return_state("tariff_list", action.res, new_state);
  }else if (action.type === "INIT_PRICE_TEMPLATES") {
      return get_return_state("pricing_templates", action.res, new_state);
  }else if (action.type === "UPDATE_PRICE_VALUES") {
      return get_return_state("pricing_values", action.res, new_state);
  }else if (action.type === "INIT_PRICE_VALUES") {
      return get_return_state("pricing_values", action.res, new_state);
  } else {
    return state;
  }
}


function get_return_state(name, result, state) {
     return {
         tariff: name === "tariff" ? result : state.tariff,
         tariff_list: name === "tariff_list" ? result : state.tariff_list,
         contract_templates: name === "contract_templates" ? result : state.contract_templates,
         pricing_templates: name === "pricing_templates" ? result : state.pricing_templates,
         pricing_values: name === "pricing_values" ? result : state.pricing_values,
     };
}

export default tariff;
