import validateVar from "../Functions/validation/validateVariable";
import {
    apiFunctionAnalyticsRankingTariffs,
    apiFunctionDeleteMemo,
    apiInformationManagementService
} from "../Functions/global";
import isEmptyObject from "../Functions/isEmptyObject";
import History from "./Components/history";
import getCommunicationClass from "../Functions/getCommunication";

let offer_result_vars = {
    has_history: true,
    id: null,
    consultant_employee: null,
    authority_file: null,
    consultant_id: null,
    contract_begin: {
        contract_begin: null,
        contract_begin_display: {
            function_name: "getGermanDateFormat"
        },
        contract_begin_date_format: {
            function_name: "createNewDateObject"
        }
    },
    contract_end: {
        contract_end: null,
        contract_end_display: {
            function_name: "getGermanDateFormat"
        },
        contract_end_date_format: {
            function_name: "createNewDateObject"
        }
    },
    customer_id: null,
    contract_file_name: null,
    expires_in: {
        expires_in: null,
        expires_in_display: {
            function_name: "getExpiresDisplay"
        }
    },
    internal_contract_id: null,
    internal_customer_id: null,
    link: null,
    invoice_type: null,
    memo_id: null,
    payment_method: {
        payment_method: null,
    },
    supplier_name: null,
    customer_name: null,
    process_until: null,
    protocol_id: null,
    status: null,
    submitted_at: null,
    supplier_employee: null,
    supplier_id: null,
    customer: {
        is_class_arr: "customer",
        is_top_level: true
    },
    offer_result: {
        is_sub_arr: "offer_result",
        id: {
            offer_result_id: null
        },
        calculation_information: {
            is_sub_arr: 'calculation_information',
            consumption: null,
        },
        tariff: {
            is_class_arr: "tariff",
            is_top_level: true,
        }
    }
};


class OfferResult {
    static default_values = offer_result_vars;
  constructor(data) {
          if (validateVar(data) && !isEmptyObject(data)) {
              this.history = {};
              let class_content = new History(offer_result_vars, data, data.history, []);
              for (let name in class_content) {
                  if (class_content.hasOwnProperty(name)) {
                      this[name] = class_content[name];
                  }
              }
          }
      //load basic info
    // if (typeof data !== "undefined" && typeof data.id !== "undefined") {
    //     if(data.hasOwnProperty("customer") && validateVar(data.customer)) {
    //         let customer = data.customer;
    //         if(customer.hasOwnProperty("customer_information") && validateVar(customer.customer_information)) {
    //             let customer_information = customer.customer_information;
    //             // this.company = !validateVar(customer_information.company) ? "" : customer_information.company;
    //         }
    //     }
    // }
  }

    deleteOffer() {
        let communication_params = {
            service: apiInformationManagementService,
            get_function: apiFunctionAnalyticsRankingTariffs,
            update_function: "",
            delete_function: apiFunctionDeleteMemo,
            add_function: "",
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);
        return c.delete({
            id: this.memo_id, // todo need params here
        });
    }
}

export default OfferResult;
