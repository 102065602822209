import History from "./Components/history";
import getAgencyID from "../Functions/getAgencyID";
import validateVar from "../Functions/validation/validateVariable";
import getCommunicationClass from "../Functions/getCommunication";
import {apiError} from "../Functions/global";
import Employee from "./employees";
import Price from "./price";
import ConsumptionPoint from "./consumption_point";
const textToImage = require('text-to-image');

const recommended_action_information_vars = {
    id: {
        recommended_id: null,
    },
    calculation_unit: {
        is_class_arr: "calculation_unit",
        is_top_level: true
    },
    offer_recommendations: {
        is_class_arr: "recommended_action"
    },
    employee: {
        is_class_arr: "employee",
        is_top_level: true
    },
    best_offer_result: {
      is_class_arr: 'offer',
      is_top_level: true
    },
    best_offer_result_id: null,
    offer_duration: null,
    agency_id: null,
    calculation_date_id: null,
    expires_at: {
        expires_at: null,
        expires_at_display: {
            function_name: "getGermanDateFormat",
            params: [true]
        },
        expires_at_seconds: {
            function_name: 'getExpiredInSeconds',
        }
    },
    external_expiration_date: {
        external_expiration_date: null,
        external_expiration_date_display: {
            function_name: "getGermanDateFormat",
            params: [true]
        }
    },
    status: {
        status: null,
        recommended_action_status: null,
        recommended_action_status_display: {
            function_name: "translateRecommendedActionStatus",
        },
    },
    recommended_action_status_color: {
        function_name: "getBadgeColor",
        params: [
            "%%recommended_action_status",
            ["submitted"],
            ["open", "draft"],
            ["expired", "failed", "cancelled"],
            ["active"],
        ]
    },
    external_offers: {
        is_class_arr: "external_offer",
    },
    token: null,
    protocol_id: null,
    comparison_offer_result_id: null,
    end_of_terms: null,
};

class RecommendedActionInformation {
    constructor(data, status = "open") {
        let class_content = new History(
            recommended_action_information_vars,
            data,
            null,
            []
        );
        for (let name in class_content) {
            if (class_content.hasOwnProperty(name)) {
                this[name] = class_content[name];
            }
        }
    }

    static async text_to_image(text = "No Name", styling = {}) {
        // https://www.npmjs.com/package/text-to-image -> doku

        return await textToImage.generate(text, styling)
    }


    static async handle_recommended_actions(params = {}, method, call_function_name, file){
        params = getAgencyID(params, true);
        let communication_params = {
            method: method,
            params: params,
            function_url: 'wattline/recommended_action',
        };
        if(method === 'GET'){
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }else if(validateVar(call_function_name)){ //so far only used for send_mail
            communication_params.function_url = communication_params.function_url+ "/" + call_function_name;
        } else if (method === "PUT") {
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }
        let c = getCommunicationClass(communication_params, file);
        let res = await c.request_handler();
        if (res !== apiError) {
            if (validateVar(params.internal) && params.internal.dispatch_type === 'GET_RECOMMENDED_ACTION') {
                let recommended_action_information = new RecommendedActionInformation(res);

                let external_offers = recommended_action_information.external_offers;
                if (validateVar(external_offers)) {
                    for (let external_offer_index in external_offers) {
                        if (external_offers.hasOwnProperty(external_offer_index)) {
                            let external_offer = external_offers[external_offer_index];
                            if (validateVar(external_offer)) {
                                let styling = {
                                    width: "100%",
                                    textColor: "#6b1d71",
                                    textAlign: "center",
                                    verticalAlign: "center",
                                    margin: "20",
                                    fontWeight: "bold",
                                    fontSize: "36",
                                };
                                external_offers[external_offer_index].logo_url = await this.text_to_image(external_offer.supplier, styling);
                            }
                        }
                    }
                }
                return recommended_action_information;
            }else if (validateVar(params.internal) && params.internal.dispatch_type === 'GET_RECOMMENDED_ACTION_PACKET') {
                let recommended_actions = res.recommended_actions;

                let tmp_recommended_actions = [];
                let tmp_consumption_points = [];
                for (let recommended_action of recommended_actions) {
                    let formatted_recommended_action = new RecommendedActionInformation(recommended_action);
                    tmp_recommended_actions.push(formatted_recommended_action);
                    tmp_consumption_points.push(...formatted_recommended_action.consumption_points);
                }

                return {
                    employee: new Employee(res.employee),
                    price: new Price(res.recommendation),
                    recommended_actions: tmp_recommended_actions,
                    consumption_points: tmp_consumption_points
                }
            }else if(validateVar(params.internal) && params.internal.dispatch_type === 'GET_RECOMMENDED_ACTION_FOR_OFFER'){
                let recommended_action =  new RecommendedActionInformation(res);
                let current_external_offer = recommended_action.external_offers[params.external_offer_index];
                let slp_consumption_points = [];
                let rlm_consumption_points = [];

                if(validateVar(res.calculation_unit) && validateVar(res.calculation_unit.consumption_points)){
                    let consumption_points = res.calculation_unit.consumption_points;
                    if(Array.isArray(consumption_points) && consumption_points.length > 0){
                        let external_price_obj = null;
                        if (current_external_offer.price_per_cp === 0 || !validateVar(current_external_offer.price_per_cp)) {
                            external_price_obj = current_external_offer;
                            // external_price_obj = (validateVar(current_external_offer) && validateVar(current_external_offer.external_prices) && current_external_offer.external_prices.length > 0) ? current_external_offer.external_prices : null
                        }

                        consumption_points.map((consumption_point) => {
                            if (+current_external_offer.price_per_cp === 1) {
                                let external_prices = (validateVar(current_external_offer) && validateVar(current_external_offer.external_prices)) ? current_external_offer.external_prices : null;

                                external_price_obj = (validateVar(external_prices)) ? external_prices.find(x => x.consumption_point_id === consumption_point.id) : null;
                            }
                            external_price_obj = (validateVar(external_price_obj)) ? external_price_obj : {};
                            let {price_rate_plain, contract_price_net_plain, socket_plain, socket_net, bonus_amount_plain, price_rate, contract_price_net, bonus_amount} = external_price_obj;
                            consumption_point.price_rate_plain = validateVar(price_rate) ? price_rate_plain : 0;
                            consumption_point.contract_price_net_plain = validateVar(contract_price_net) ? contract_price_net_plain : 0;
                            consumption_point.socket_plain = validateVar(socket_net) ? socket_plain : 0;
                            consumption_point.bonus = validateVar(bonus_amount) ? bonus_amount_plain : 0;
                            consumption_point = new ConsumptionPoint(consumption_point);
                            if(consumption_point.consumption_type === 'SLP') slp_consumption_points.push(consumption_point);
                            else if(consumption_point.consumption_type === 'RLM') rlm_consumption_points.push(consumption_point);
                        });
                    }
                }

                return {
                    recommended_action: recommended_action,
                    slp_consumption_points: slp_consumption_points,
                    rlm_consumption_points: rlm_consumption_points
                }
            } else {
                return new RecommendedActionInformation(res);
            }
        } else return apiError;
    }
}

export default RecommendedActionInformation;