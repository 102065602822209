let initialState = null;

function plattform(state = initialState, action) {
    if (action.type === "LOGIN_PLATTFORM") {
      return action.res;
    } else if (action.type === "CHANGE_PLATTFORM") {
        return action.res;
    }
    else {
    return state;
  }
}

export default plattform;
