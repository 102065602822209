import React from 'react';
import {Card, CardBody, CardHeader, Col, Modal, Row} from "reactstrap";
import validateVar from "../../../Functions/validation/validateVariable";
import PrimaryButton from "../../../Displays/Buttons/PrimaryButton";
import getUserRole from "../../../Functions/getUserRole";
import {adminRole, project_name, project_names, user_roles} from "../../../Functions/global";
import Validation from "../../../Objects/Components/validation";
import InputHandler from "../../../Inputs/InputHandler";
import dataToSelectField from "../../../Functions/dataToSelectField";

class EditUserModal extends React.Component {
    state = {
        show_modal: false,
        form_data: {},
        error_messages: {},
        disable_email: true,
        old_user_role: '',
        id: null,
    };

    change_form_data = (name, value) => {
        let {company, team_list} = this.props;
        let {form_data} = this.state;
        if(validateVar(company) && company.features.includes('team') && name === 'user_role') form_data['teams'] = team_list[0].id; //set team back to "Intern" (only "user" can be "Extern")
        form_data[name] = value;
        this.setState({form_data: form_data});
    }

    toggle_modal = (data) => {
        let {company, team_list} = this.props;
        let {show_modal} = this.state;
        if (!show_modal){
            let initial_form_data = {
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                user_role: data.title_id,
                teams: ''
            }
            if(project_name === project_names.wattline){
                initial_form_data.teams = data.team_ids;
            }else if(company.features.includes('team')){
                if(validateVar(data.team_ids) && data.team_ids.length > 0){
                    initial_form_data.teams = data.team_ids[0];
                }else if(validateVar(team_list) && team_list.length > 0){ //this case happens only when user without a team got somehow created
                    initial_form_data.teams = team_list[0].id;
                }
            }
            if (validateVar(data)) {
                this.setState({
                    form_data: initial_form_data,
                    error_messages: {},
                    old_user_role: data.title_id,
                    disable_email: true,
                    id: data.id,
                });
            }
        }

        this.setState({show_modal: !show_modal})
    };

    turn_off_modal(){
        this.setState({show_modal: false});
    };

    submit = () => {
        const {company} = this.props;
        const {form_data, old_user_role, id} = this.state;

        // validation start
        let special_cases = {user_role: {case_name: 'select'}};

        if(project_name === project_names.wattline || !(validateVar(company.features) && company.features.includes('team'))) {
            special_cases.teams = {can_be_empty: true}
        }

        // call abstract error validation and set values in state
        let validation_result = new Validation(form_data, special_cases);

        this.setState({
            error_messages: validation_result.error_messages
        });
        // validation end

        //if i mail is valid and not used
        if(!validation_result.has_error){
            let data = {
                email: form_data.email,
                last_name: form_data.last_name,
                first_name: form_data.first_name,
                role_id: form_data.user_role,
                old_role_id: old_user_role,
                employee_id: id
            };
            if(project_name === project_names.wattline) {
                data.teams = form_data.teams;
            }else if(company.features.includes('team')){
                data.teams = [form_data.teams];
            }
            this.props.update_employee(data);
            this.setState({submit_disabled: true});
        }
    };

    render() {
        const {team_list, company} = this.props;
        const {form_data, show_modal, disable_email, old_user_role, error_messages} = this.state;
        const {first_name, last_name, user_role, email, teams} = form_data;

        let user_roles_ = user_roles;
        let current_user_role = getUserRole();
        let disable_role_select = false;

        //Disable Admin role for Managers
        if(current_user_role !== adminRole) user_roles_ = [{key: 2, value: 'Manager'}, {key: 3, value: 'Nutzer'}];

        if(old_user_role === 1 && current_user_role !== adminRole) {
            disable_role_select = true;
        }

        return(<Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={show_modal}
            toggle={() => this.toggle_modal()}>
            <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent modal-title mb-0">
                        <Row>
                            <Col lg={6}>
                                <h2 className={'mb-0'}>Nutzer bearbeiten</h2>
                            </Col>
                            <Col lg={6}>
                                <button onClick={this.toggle_modal} className={'close'} ><i className={'ni ni-fat-remove close-button'} /></button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-lg-4 py-lg-4">
                        <div className='modal-content-end-margin'>
                            <InputHandler
                                content_title='Vorname'
                                type='text'
                                name='first_name'
                                value={first_name}
                                placeholder='Vorname'
                                on_change={this.change_form_data}
                                error_state={error_messages.first_name_error}
                            />

                            <InputHandler
                                content_title='Nachname'
                                type='text'
                                name='last_name'
                                value={last_name}
                                placeholder='Nachname'
                                on_change={this.change_form_data}
                                error_state={error_messages.last_name_error}
                            />

                            <InputHandler
                                content_title='E-Mail'
                                type='text'
                                name='email'
                                value={email}
                                placeholder='E-Mail'
                                on_change={this.change_form_data}
                                disabled={disable_email}
                                error_state={error_messages.email_error}
                            />

                            <InputHandler
                                content_title="Nutzer Rolle"
                                type="select"
                                name="user_role"
                                value={user_role}
                                on_change={this.change_form_data}
                                is_loaded={true}
                                select_options={user_roles_}
                                disabled={disable_role_select}
                                error_state={error_messages.user_role_error}
                            />

                            {project_name === project_names.wattline && <InputHandler
                                type='multiselect'
                                content_title='Teams'
                                id={"teams"}
                                name='teams'
                                on_change={(name, selected) => this.change_form_data(name, selected)}
                                disabled={false}
                                multiselect_options={dataToSelectField("id", "name", team_list)}
                                value={teams}
                                input_wrapper_classes={'pb-3'}
                            />}

                            {validateVar(company) && company.features.includes('team') && <InputHandler
                                type='select'
                                content_title='Team'
                                id="teams"
                                name='teams'
                                on_change={(name, selected) => this.change_form_data(name, selected)}
                                disabled={user_role !== 3 && user_role !== '3'} //only enable when the user role === user (not admin, not manager)
                                select_options={dataToSelectField("id", "name", team_list, null, true)}
                                value={teams}
                                input_wrapper_classes={'pb-3'}
                                remove_empty_option
                            />}
                        </div>
                        <div className="text-center">
                            <PrimaryButton id='editUserSubmit' onClick={this.submit} disabled={false} value='Nutzer bearbeiten' classes='btn-modal' />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Modal>)
    }
}

export default EditUserModal;