let initialState = {};

function url(state = initialState, action) {
  if (action.type === "CREATE_URL") {
    return action.res;
  } else if (action.type === "RESET_URL") {
    return initialState;
  } else {
    return state;
  }
}

export default url;
