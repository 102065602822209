// USER FEEDBACK
export const displaySuccessMessage = (
  message = "Eine gute Nachricht",
  headline = "Erfolg",
  url = null,
  automatic_redirect = false,
  modal = null,
  primaryBtnText = "OK",
  secondaryBtnText = "Abbrechen",
  allowToggle = false,
  successIcon = true,
  infoIcon = false,
  secondaryBtnFunction = null,
  type = "success"
) => {
  return {
    type: type,
    headline: headline,
    message: message,
    url: url,
    automatic_redirect: automatic_redirect,
    modal: modal,
    primaryBtnText: primaryBtnText,
    secondaryBtnText: secondaryBtnText,
    successIcon: successIcon,
    infoIcon: infoIcon,
    allowToggle: allowToggle,
    secondaryBtnFunction: secondaryBtnFunction
  };
};

export const displayErrorMessage = (
  message = "Eine schlechte Nachricht",
  headline = "Es ist ein Fehler aufgetreten",
  url = null,
  automatic_redirect = false,
  modal = null,
  primaryBtnText = "OK",
  secondaryBtnText = "Abbrechen",
  allowToggle = false,
  successIcon = false,
  infoIcon = false,
  type = "danger",
  secondaryBtnFunction = null
) => {
  return {
    type: type,
    headline: headline,
    message: message,
    url: url,
    automatic_redirect: automatic_redirect,
    modal: modal,
    primaryBtnText: primaryBtnText,
    secondaryBtnText: secondaryBtnText,
    successIcon: successIcon,
    infoIcon: infoIcon,
    allowToggle: allowToggle,
    secondaryBtnFunction: secondaryBtnFunction
  };
};
