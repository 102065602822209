function getHabitationStatusDisplay(habitation_status){
    if (habitation_status === "supplier_change") return "Lieferantenwechsel";
    if (habitation_status === "continued_delivery") return "Folgebelieferung";
    else if (habitation_status === "relocation") return "Neueinzug";
    else if (habitation_status === "already_cancelled")
        return "Vorab Gekündigt";
    else if (habitation_status === "default_supplier") return "Grundversorgung";
    else return "-/-";
}

export default getHabitationStatusDisplay;