/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {downloadContracts, initializeContract, redirect} from '../../Index/index';
import {
    Card,
    Container,
    Row,
} from "reactstrap";
// core components
import SimpleHeader from "../../Displays/Headers/SimpleHeader.jsx";
// custom components
import DropdownButton from "../../Displays/Buttons/DropdownButton";
import validateVar from "../../Functions/validation/validateVariable";
import {SingleContract} from "../../../globalNavigation";
import {apiError, project_name} from "../../Functions/global";
import SubmitLoadingAnimationModal from "../../LoadingAnimations/SubmitLoadingAnimationModal";
import Badge from "../../Displays/Badges/Badge";
import Table from "../../Displays/Tables/Table";
import create_table_content_from_options from "../../Functions/createTableContentFromOptions";
import is_true from "../../Functions/is_true";
import {download_csv_contracts_export, reset_download_file} from "../../Index";

let _selectedIDs = [];
const is_ehub = project_name === 'EVU_PROJECT';
const limit = 12;
const offset = 0;

const default_table_columns = [
    {
        value: "contract_id",
        label: 'ID',
        sort: true,
    },
    {
        value: "customer_company",
        label: "Kunde",
        sort: true,
        style: {fontWeight: 'bold'},
        classes: 'linked-col'
    },
    {
        value: "customer_type_display",
        label: "Kundentyp",
        sort: true,
        classes: 'linked-col'
    },
    {
        value: "display_consumption",
        label: "Verbrauch",
        sort: true,
        classes: 'linked-col'
    },
    {
        value: "display_medium_type",
        label: "Energie",
        sort: true,
        classes: 'linked-col'
    },
    {
        value: "contract_begin_timestamp",
        label: "Lieferbeginn",
        sort: true,
        formatter_func: "handleFormatDateForFrontend",
        classes: 'linked-col'
    },
    {
        value: "display_created_timestamp",
        label: "Datum",
        sort: true,
        formatter_func: "handleFormatDateForFrontend",
        classes: 'linked-col'
    },
    {
        value: "time_diff",
        label: "Frist",
        sort: true,
        classes: 'linked-col'
    },
    {
        value: "translated_status",
        label: "Status",
        formatter: (cell, object) => {
            return <Badge value={cell} color={object.status_color}/>
        },
        sort: true,
        classes: 'linked-col'
    }
];

/* START CLASS */
class ContractsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownDisabled: true,
            is_loaded: false,
            loading_modal: "Aufträge werden gesammelt",
            next_offset: false,
            contracts: {contracts: []},
            table_columns: create_table_content_from_options(default_table_columns),
            no_header: props.no_header,
            no_title_row: props.no_title_row,

        };
        this.table_input_modal = React.createRef();
    }

    componentDidMount() {
        let {contracts_prev_loaded} = this.props;
        if (!contracts_prev_loaded) this.props.initialize({limit: limit, offset: offset}); // if not pre loaded init all contracts
    }

    componentDidUpdate(prevProps, prevState) {
        let {contracts, download_file} = this.props;
        let {loading_modal} = this.state;

        if (contracts !== prevProps.contracts && validateVar(contracts)) {
            if (contracts !== apiError) {
                if (validateVar(contracts.next_offset)) {
                    this.get_units(contracts.next_offset);
                }
            } else contracts = {contracts: []};

            //Stop the loading animation modal if as soon as first chunk of contracts got loaded
            if(loading_modal === "Aufträge werden gesammelt") loading_modal = false;

            this.setState({
                contracts: contracts,
                is_loaded: true,
                loading_modal: loading_modal,
            });
        }

        //After csv got downloaded, turn off loading and reset the prop download_file to enable another download possibility
        if(validateVar(download_file) && is_true(download_file) && prevProps.download_file !== download_file){
            this.rowSelected([]);
            this.props.reset_download_file();
            this.setState({loading_modal: false});
        }
    }

    rowSelected = (selected_ids) => { //3 parameter could be "rowIndex"
        _selectedIDs = selected_ids;
        if(this.state.dropdownDisabled && _selectedIDs.length > 0) this.setState({dropdownDisabled: false});
        if(!this.state.dropdownDisabled && _selectedIDs.length === 0)this.setState({dropdownDisabled: true});
    };

    get_units = (offset) => {
        let params = { // set first pair of offset and limit
            limit: limit,
            offset: offset
        };
        this.props.initialize(params);
    };


    get_table = () => {
        let {contracts, is_loaded, table_columns, dropdownDisabled, no_title_row} = this.state;
        return <Table
            table_name={is_ehub ? "ehub_contracts" : "enermakler_contracts"}
               data={contracts}
               data_arr_name={'contracts'}
               columns={table_columns}
               linked_col_click={(row) => this.props.redirect(SingleContract + "/" + row.contract_id + "/" + row.protocol_id)}
               is_loaded={is_loaded}
               key_field={"contract_id"}
               paginator_strings={['Aufträge', 'Aufträgen']}
               empty_table_message={'Es gibt noch keine Aufträge'}
               selected_ids={_selectedIDs}
               on_checkbox_click={this.rowSelected}
               update_columns={(table_columns) => this.setState({table_columns: table_columns})}>
                                         <span className='responsive-button-wrapper'>
                                                <DropdownButton
                                                    tooltipProp={"Aufträge"}
                                                    wrapper_classes='mr-3'
                                                    disableTooltip={true}
                                                    disabled={dropdownDisabled}
                                                    include={is_ehub ? ['export', 'generate_csv'] : ['export']}
                                                    downloadFromTable={() => this.props.downloadContracts(_selectedIDs)}
                                                    clickGenerateCsvBtn={() => this.download_csv_test(_selectedIDs)}
                                                />
                                        </span>
        </Table>
    };

    download_csv_test = (_selectedIDs) => {
        const {contracts} = this.props;

        let params = {
            customer_ids: [],
            contract_ids: []
        };

        _selectedIDs.map((id, index) => {
            let current_contract = contracts.contracts.find(x => +x.contract_id === +id);

            params.customer_ids.push(current_contract.customer_id);
            params.contract_ids.push(current_contract.contract_id);
        });

        this.setState({loading_modal: 'Die CSV wird generiert...'});
        this.props.download_csv_test(params);
    };

    render() {
        let {no_header, loading_modal} = this.state;
        let table = this.get_table();

        return (
            <>
                {(no_header) ? table : <>
                <SubmitLoadingAnimationModal display={loading_modal}/>
                <SimpleHeader name="Aufträge" headerText={''}/>
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                {table}
                            </Card>
                        </div>
                    </Row>
                </Container></>}
            </>
        );
    }
}


let mapStateToProps = function (state) {
    return {
        contracts: state.contract_list,
        download_file: state.download_file
    }
};

let mapDispatchToProps = {
    initialize: initializeContract,
    download_csv_test: download_csv_contracts_export,
    redirect: redirect,
    downloadContracts: downloadContracts,
    reset_download_file: reset_download_file
};

let ContractContainer = connect(mapStateToProps, mapDispatchToProps)(ContractsTable);

export default ContractContainer;
