import React from 'react'
import SimpleHeader from "../../../module_components/Displays/Headers/SimpleHeader";
import {Card, CardHeader, CardBody, Container, Row, Col} from "reactstrap";
import {
    deleteCompanyFile,
    initializeCompany, showErrorMessage,
    updateCompany,
} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import SaveFormData from "../../../module_components/Displays/Footers/SaveFormData";
import {deHashVar} from "../../../module_components/Functions/handleHashedVariable";
import handleFormatNumber from "../../../module_components/Functions/handleFormatNumber";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import hasChanged from "../../../module_components/Functions/hasChanged";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import Validation from "../../../module_components/Objects/Components/validation";
import SecondaryButton from "../../../module_components/Displays/Buttons/SecondaryButton";
import Skeleton from "react-loading-skeleton";
import TooltipComponent from "../../../module_components/Displays/Basic/Tooltip";
import {upload_file_new} from "../../../module_components/Index";
import replace_all_dots from "../../../module_components/Functions/replace_all_dots";
import callFunctionWithString from "../../../module_components/Functions/callFunctionWithString";

class CompanyData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: '',
            brochure_id: '',
            logo_name: "",
            brochure_name: "",
            is_loaded: false,
            has_changed: false,
            company_data: {},
            error_messages: {},
            supplier_id: deHashVar(localStorage.getItem('evuSecurityToken')),
            src: null,
            new_logo_image_source_url: null,
            input_disabled: false,
            inputs: [0],
            updating_brochure: false,
            updating_logo: false,
            upload_brochure_finished: false,
            upload_logo_finished: false,
        };
    }

    componentDidMount() {
        let company = this.props.company;
        if (validateVar(company)) {
            this.set_company_data(company);
        }
    };

    /* ON LOAD / UPDATE
    *  function is triggered on load or after redux request
    *  load redux data in states
    * */
    componentDidUpdate(prevProps, prevState) {
        let {company} = {...this.props};
        let {is_loaded, input_disabled, updating_brochure, updating_logo} = this.state;
        if (validateVar(company) && company !== prevProps.company) {
            if (validateVar(company) && !this.state.is_loaded) {

                let default_pfc = [...company.pfc_email];
                this.setState({
                    default_pfc: default_pfc
                });
                this.set_company_data({...company});
            }

            if (validateVar(company.brochure_file_id) && company.brochure_file_id !== this.state.brochure_id) {
                this.setState({
                    brochure_name: "Firmenbroschüre",
                    brochure_id: company.brochure_file_id,
                });
            } else if (!validateVar(company.brochure_file_id)) {
                this.setState({
                    brochure_name: "",
                    brochure_id: null,
                });
            }

            if (!is_loaded) this.setState({is_loaded: true});
            if (input_disabled) this.setState({input_disabled: false});
            //If brochure was updated in Company, finish loading bar animation
            if(updating_brochure) this.setState({updating_brochure: false, upload_brochure_finished: true});
            if(updating_logo) this.setState({updating_logo: false, upload_logo_finished: true});
        }
    };

    set_company_data = (company) => {
        this.setState({
            company_data: {
                company: company.company,
                description: company.description,
                line1: company.line1,
                line2: company.line2,
                zip: company.zip,
                city: company.city,
                email: company.email,
                phone: validateVar(company.phone) ? company.phone : '',
                foundation_year: company.foundation_year,
                sales_revenue: company.sales_revenue,
                employee_count: company.employee_count,
                customer_count: company.customer_count,
                status_as: company.status_as,
                pfc_email: (validateVar(this.state.default_pfc)) ? [...this.state.default_pfc] : company.pfc_email,
            },

        });

        // BROCHURE FILE
        if (validateVar(company.brochure_file_id) && company.brochure_file_id !== this.state.brochure_id) {
            this.setState({
                brochure_name: "Firmenbroschüre",
                brochure_id: company.brochure_file_id,
            })
        }

        //LOGO FILE ID
        if (company.logo_file_id > 0 && validateVar(company.logo_file_id)) {
            this.setState({
                logo: company.logo_file_id,
                logo_image_source_url: company.logo_url,
                logo_name: "Firmenlogo"
            })
        }

        if (company.logo_url !== "") { //todo könnte sein das hier ein fehler auftritt wenn kein logo für den versorger hinterlegt ist
            this.setState({
                is_loaded: true
            })
        }
    };

    /* CLOSE SAVE BUTTON */
    cancel_form_changes = () => {
        this.set_company_data(this.props.company);
        this.setState({
            has_changed: false,
        });
    };

    delete_company_file = (name) => {
        let params = {};
        if (name === 'brochure') params.type = "pdf";
        else params.type = "images";
        this.props.deleteCompanyFile(params);
    };

    appendInput() {
        let {company_data} = this.state;
        let pfc_email = company_data.pfc_email;
        pfc_email.push("");
        company_data[pfc_email] = pfc_email;
        this.setState({
            company_data: company_data
        });
    }

    removeInput(index) {
        let {company_data} = {...this.state};
        const {company} = {...this.props};
        let pfc_email = [...company_data.pfc_email];
        pfc_email.splice(index, 1);


        let has_changed = hasChanged(pfc_email, "pfc_email", company, company_data);

        company_data.pfc_email = pfc_email;
        this.setState({
            has_changed: has_changed,
            company_data: company_data,
        });
    }

    /*
        ON CHANGE FUNCTION
        formating values of changed state
        checks if states have changed (if yes display submit btn)
        updating states abstract
    */
    update_state = (state_name, value, function_name = null) => {
        const {company} = this.props;
        let {company_data} = this.state;
        let has_changed = hasChanged(value, state_name, company, company_data);
        if (state_name.includes("pfc_email")) {
            let split_name = state_name.split("-");
            state_name = split_name[0];
            let current_array = company_data[state_name];
            current_array[split_name[1]] = value; // split_name === index
            value = current_array;
        }

        if(validateVar(function_name) && validateVar(value)){ //KZ / 31012022 / function only used for "getNumericValue" so far
            value = callFunctionWithString(function_name, [value, company_data[state_name]])
        }

        company_data[state_name] = value;
        this.setState({
            company_data: company_data, //you can update a specific var in a obj.
            has_changed: has_changed
        });
    };


    remove_pfc_params = (company_data) => {
        for (let index in company_data.pfc_email) {
            if (company_data.pfc_email.hasOwnProperty(index)) {
                let name = "pfc_email-" + index;
                delete company_data[name];  // or delete person["age"];
            }
        }
        return company_data;
    };

    /*
        ON SUBMIT FUNCTION
        validate vars / trigger redux request
    */
    save_form_changes = () => {
        //check required fields
        let {company_data} = this.state;
        company_data.billing_address = {
            line1: company_data.line1,
            line2: company_data.line2,
            city: company_data.city,
            zip: company_data.zip
        };


        //remove dots from numbers
        company_data.sales_revenue = replace_all_dots(company_data.sales_revenue, "");
        company_data.employee_count = replace_all_dots(company_data.employee_count, "");
        company_data.customer_count = replace_all_dots(company_data.customer_count, "");

        // ['company', 'description', 'line1', 'line2', 'zip', 'city', 'email']; are required!

        // validation start
        // define special cases for error validation
        let special_cases = {
            phone: {case_name: company_data.phone.length > 0 ? "phone" : "can_be_empty"},
            customer_count: {case_name: "can_be_empty"},
            employee_count: {case_name: "can_be_empty"},
            foundation_year: {case_name: "can_be_empty"},
            sales_revenue: {case_name: "can_be_empty"},
            status_as: {case_name: "can_be_empty"},
            email: {case_name: "email"},
            zip: {case_name: "postal_code"},
        };


        // add tmp pfc emails for validation
        for (let index in company_data.pfc_email) {
            if (company_data.pfc_email.hasOwnProperty(index)) {
                let count = company_data.pfc_email.filter(x => x === company_data.pfc_email[index]).length; // check if the e mail is already in array
                if (count > 1) {
                    company_data.pfc_email.splice(index, 1);
                } else {
                    let name = "pfc_email-" + index;
                    special_cases[name] = {case_name: "email"};
                    company_data[name] = company_data.pfc_email[index];
                }
            }
        }
        // call abstract error validation and set values in state
        let validation_result = new Validation(company_data, special_cases);
        this.setState({error_messages: validation_result.error_messages});
        // validation end
        if (!validation_result.has_error) {
            company_data = this.remove_pfc_params(company_data);
            //KZ / 31012022 / values which are defined as int in the DB cant be empty
            const int_values = ['sales_revenue', 'foundation_year', 'employee_count', 'customer_count', 'status_as'];
            for(let int_value of int_values){
                if(!validateVar(company_data[int_value])) delete company_data[int_value];
            }
            this.props.onUpdate(company_data);
            this.setState({
                input_disabled: true
            });
        } else { //display error
            this.props.showErrorMessage("Bei dem Speichern Ihrer Daten ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben");
        }

        this.setState({
            has_changed: false,
        });
    };

    render() {
        const {brochure_name, brochure_id, is_loaded, company_data, has_changed, logo_name, input_disabled, error_messages, alert, upload_brochure_finished, upload_logo_finished} = this.state;
        const {default_file_upload} = this.props;

        let display_index = 0;
        return (<>
            {alert}
            <SimpleHeader name="Firmenprofil" headerText=""/>
            <Container className="mt--6 company-data-wrapper" fluid>
                <Row className="justify-content-center">
                    <Col className="card-wrapper" lg="12">
                        <Card className='mb-5'>
                            <CardHeader className={'pb-2'}>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2 className='d-inline-block pr-3'>Einstellungen</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className={'company-data-body px-4'}>
                                <div className='custom-border-bottom'>
                                    <Row className='mb-2'>
                                        <Col xl={12}>
                                            <h3 className='text-uppercase text-muted'>Allgemein</h3>
                                        </Col>
                                    </Row>

                                    <Row className='px-3'>
                                        <Col className={'px-3'} xl={12}>
                                            <InputHandler
                                                content_title="Name"
                                                type="text"
                                                name="company"
                                                value={company_data.company}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.company_error}
                                            />
                                        </Col>

                                        <Col className={'px-3'} xl={12}>
                                            <InputHandler content_title='Firmenlogo'
                                                  type="file_upload_new"
                                                  name='logo_name'
                                                  value={logo_name}
                                                  file_type="images" // images / pdf / xlsx -> default
                                                  cropper={true}
                                                  cropper_headline="Logo zuschneiden"
                                                  is_loaded={is_loaded}
                                                  upload_finished={upload_logo_finished}
                                                  public_static={1}
                                                  default_file_upload={default_file_upload}
                                                  return_file_id={(file_object) => {
                                                      this.props.onUpdate({logo_file_id: file_object.file_id});
                                                      this.setState({upload_logo_finished: false, upload_brochure_finished: false, updating_logo: true})
                                                  }}
                                                  allow_reupload={true}
                                            />
                                        </Col>
                                        <Col className={'px-3'} xl={12}>
                                            <InputHandler
                                                content_title="Beschreibung"
                                                type="textarea"
                                                name="description"
                                                placeholder='Beschreibung des Unternehmens'
                                                value={company_data.description}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.description_error}
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                <div className='custom-border-bottom'>
                                    <Row className={'mb-2'}>
                                        <Col xl={12}>
                                            <h3 className='text-uppercase text-muted'>Anschrift</h3>
                                        </Col>
                                    </Row>
                                    <Row className='px-3'>
                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Straße"
                                                type="text"
                                                name="line1"
                                                placeholder='Straße'
                                                value={company_data.line1}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.line1_error}
                                            />
                                        </Col>
                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Hausnummer"
                                                type="text"
                                                name="line2"
                                                placeholder='Hausnummer'
                                                value={company_data.line2}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.line2_error}
                                            />
                                        </Col>
                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Postleitzahl"
                                                type="text"
                                                name="zip"
                                                placeholder='Postleitzahl'
                                                value={company_data.zip}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                max_length={5}
                                                error_state={error_messages.zip_error}
                                            />
                                        </Col>
                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Ort"
                                                type="text"
                                                name="city"
                                                placeholder='Ort'
                                                value={company_data.city}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.city_error}
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                <div className='custom-border-bottom'>
                                    <Row className={'mb-2'}>
                                        <Col xl={12}>
                                            <h3 className='text-uppercase text-muted'>Kontakt</h3>
                                        </Col>
                                    </Row>
                                    <Row className='px-3'>
                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="E-Mail"
                                                type="text"
                                                name="email"
                                                placeholder='E-Mail'
                                                value={company_data.email}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.email_error}
                                            />
                                        </Col>

                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Telefon"
                                                type="text"
                                                name="phone"
                                                placeholder='Telefonnummer'
                                                value={company_data.phone}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.phone_error}
                                                optional
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                <div className='custom-border-bottom'>
                                    <Row className='mb-2'>
                                        <Col xl={12}>
                                            <h3 className='text-uppercase text-muted'>Optionale Informationen</h3>
                                        </Col>
                                    </Row>
                                    <Row className='px-3'>
                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Gründungsjahr"
                                                type="number"
                                                name="foundation_year"
                                                max_length={4}
                                                placeholder='Gründungsjahr'
                                                value={company_data.foundation_year}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value, 'getNumericValue')}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.foundation_year_error}
                                                prev_value={company_data.foundation_year}
                                                remove_zero_on_focus
                                                optional
                                            />
                                        </Col>

                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Umsatz"
                                                type="text"
                                                name="sales_revenue"
                                                max_length={11} // KZ / 31012022 / database input fields max size is 2147483647, with dot separators it makes max 11 length
                                                placeholder='Umsatz'
                                                value={handleFormatNumber(company_data.sales_revenue)}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value, 'getNumericValue')}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.sales_revenue_error}
                                                remove_zero_on_focus
                                                optional
                                            />
                                        </Col>

                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Mitarbeiteranzahl"
                                                type="text"
                                                name="employee_count"
                                                max_length={11} // KZ / 31012022 / database input fields max size is 2147483647, with dot separators it makes max 11 length
                                                placeholder='Mitarbeiteranzahl'
                                                value={handleFormatNumber(company_data.employee_count)}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value, 'getNumericValue')}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.employee_count_error}
                                                remove_zero_on_focus
                                                optional
                                            />
                                        </Col>

                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Kundenanzahl"
                                                type="text"
                                                name="customer_count"
                                                max_length={11} // KZ / 31012022 / database input fields max size is 2147483647, with dot separators it makes max 11 length
                                                placeholder='Kundenanzahl'
                                                value={handleFormatNumber(company_data.customer_count)}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value, 'getNumericValue')}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.customer_count_error}
                                                remove_zero_on_focus
                                                optional
                                            />
                                        </Col>

                                        <Col className='px-3' xl={6}>
                                            <InputHandler content_title='Firmenbroschüre'
                                                type="file_upload_new"
                                                name='brochure'
                                                value={brochure_name}
                                                file_type="pdf" // images / pdf / xlsx -> default
                                                file_id={brochure_id}
                                                is_loaded={is_loaded}
                                                public_static={1}
                                                upload_finished={upload_brochure_finished}
                                                return_file_id={(file_object) => {
                                                    this.props.onUpdate({brochure_file_id: file_object.file_id});
                                                    this.setState({upload_logo_finished: false, upload_brochure_finished: false, updating_brochure: true})
                                                }}
                                                default_file_upload={default_file_upload}
                                                allow_delete={true}
                                                allow_download={true}
                                                allow_reupload={true} />
                                        </Col>
                                        <Col className='px-3' xl={6}>
                                            <InputHandler
                                                content_title="Stand der Angaben"
                                                type="number"
                                                max_length={4}
                                                name="status_as"
                                                placeholder='Jahr'
                                                value={company_data.status_as}
                                                disabled={input_disabled}
                                                on_change={(name, value) => this.update_state(name, value, 'getNumericValue')}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.status_as_error}
                                                prev_value={company_data.status_as}
                                                remove_zero_on_focus
                                                optional
                                            />
                                        </Col>
                                    </Row>
                                </div>


                                <div>
                                    <Row className='mb-2'>
                                        <Col xl={12}>
                                            <h3 className='text-uppercase'>
                                                <span className='text-muted mr-2'>PFC</span>
                                                <TooltipComponent tooltip_data={{
                                                    id: 'pfcMails',
                                                    text: 'Durch die Eintragung ihrer E-Mail Adresse, wird diese E-Mail Adresse auf unseren Servern white gelistet.\n' +
                                                        'Bitte senden Sie uns Ihre PFC Dateien an: robot@eless.de'
                                                }}/>
                                            </h3>
                                        </Col>
                                    </Row>
                                    {is_loaded ? <Row className='px-3'>
                                        <Col md={12} id="dynamicInput">
                                            {validateVar(company_data.pfc_email) && company_data.pfc_email.map((mail, index) => {
                                                display_index = index + 1;
                                                let name = "pfc_email-" + index;
                                                let error_name = name + "_error";
                                                return <InputHandler
                                                    content_title={display_index + ". PFC E-Mail"}
                                                    type="text"
                                                    id={name}
                                                    name={name}
                                                    placeholder='max@muster.de'
                                                    value={mail}
                                                    disabled={input_disabled}
                                                    on_change={(name, value) => this.update_state(name, value)}
                                                    is_loaded={is_loaded}
                                                    error_state={error_messages[error_name]}
                                                    input_group_text={index > 0 ?
                                                        <i className='fas fa-trash-alt font-size-20'/> : null}
                                                    input_group_on_click={index > 0 ? () => this.removeInput(index) : null}
                                                    optional
                                                />
                                            })}
                                        </Col>
                                        {(display_index <= 3) && <Col md={12}>
                                            <SecondaryButton className='px-3'
                                                             value={'PFC E-Mail hinzufügen ' + display_index + '/4'}
                                                             onClick={() => this.appendInput()}/>
                                        </Col>}
                                    </Row> : <Row className='px-3'><Col><Skeleton width='100%' height={58}/></Col></Row>}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <SaveFormData
                submitCallback={this.save_form_changes}
                cancelCallback={this.cancel_form_changes}
                display={has_changed}
                text={'Sie haben Einstellungen auf dieser Seite geändert. Wollen Sie die Änderungen speichern?'}/>
        </>)
    }
}

let mapStateToProps = function (state) {
    return {
        company: state.company,
    }
};

let mapDispatchToProps = {
    initialize: initializeCompany,
    onUpdate: updateCompany,
    showErrorMessage: showErrorMessage,
    deleteCompanyFile: deleteCompanyFile,
    //FileUpload requests
    default_file_upload: upload_file_new,
};

let CompanyDataContainer = connect(mapStateToProps, mapDispatchToProps)(CompanyData);

export default CompanyDataContainer;
