function getDurationTranslation(translate, reverse = false){
    if (!reverse){
        if (translate.includes("months"))return "Monate";
        else if (translate.includes("weeks"))return "Wochen";
        else if (translate.includes("month"))return "Monat";
        else if (translate.includes("week"))return "Woche";
        else return "N/A";
    } else{
        if (translate.includes("Monate"))return "months";
        else if (translate.includes("Wochen"))return "weeks";
        else if (translate.includes("Monat"))return "month";
        else if (translate.includes("Woche"))return "week";
        else return "N/A";
    }

}
export default getDurationTranslation;