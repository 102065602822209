/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import {Container, Row, Col } from "reactstrap";

class AuthFooter extends React.Component {
  render() {
    return (
        <div className='footer background-primary mt-5'>
            <Container>
                <Row className="align-items-center justify-content-lg-between">
                  <Col className='white-color' lg="12">
                  <div><b>Der Service wird betrieben von:</b></div>
                    <div>Enermakler Operations GmbH | Leopoldstr. 31 | 80802 München</div>
                    <div>AG München | HRB 282978 | Geschäftsführer: A. Schießl</div>
                  </Col>
                </Row>
            </Container>
        </div>
    );
  }
}

export default AuthFooter;
