//This function updates the formular inputs of a consumption point object with valid data.
// For Example: high consumption -> disable SLP and set RLM
import {loadProfileOptionalMaxConsumption, rlmMinConsumption, slpMaxConsumption} from "./global";
import handleFormatNumber from "./handleFormatNumber";
import getSpecificationDisplay from "./getSpecificationDisplay";

function handleConsumptionPointFormChange(consumption_point_form, value, name) {
    switch (name){
        case 'consumption_input_value':
        case 'mean_consumption':
        case 'consumption':
            return handleConsumptionChange(consumption_point_form, value, name);
        case 'consumption_type':
            return handleConsumptionTypeChange(consumption_point_form, value);
    }
}

/* Consumption Change */
function handleConsumptionChange (consumption_point_obj, value, name) {
    let medium_type = consumption_point_obj.medium_type;
    let consumption_point_obj_old = {...consumption_point_obj};
    let tmpVal = value.replace(/\./g, "");

    if(tmpVal.length >= 1 && tmpVal <= 0){
        value = '';
    }
    consumption_point_obj.disable_rlm = false;
    consumption_point_obj.disable_slp = false;

    if (tmpVal < rlmMinConsumption[medium_type]) {
        consumption_point_obj.disable_rlm = true;
        consumption_point_obj.consumption_type = "SLP";
        if (consumption_point_obj_old.consumption_type !== consumption_point_obj.consumption_type) {
            if (consumption_point_obj.consumption_type === "SLP") {
                consumption_point_obj.specification = 0;
                // consumption_point_obj.specification_display = getSpecificationDisplay(consumption_point_obj.specification);
            }
            consumption_point_obj.business_type = null;
        } // reset if consumption_type changed
    }else if (tmpVal > slpMaxConsumption[medium_type]) {
        consumption_point_obj.disable_slp = true;
        consumption_point_obj.consumption_type = "RLM";
        if (consumption_point_obj_old.consumption_type !== consumption_point_obj.consumption_type) {
            consumption_point_obj.business_type = null;
        }// reset if consumption_type changed
    }else if (tmpVal > loadProfileOptionalMaxConsumption) {
        consumption_point_obj.loadprofile_check = true;
    }
    if(medium_type === 'GAS' && consumption_point_obj.consumption_type === 'SLP'){
        consumption_point_obj.loadprofile_check = false;
    }
    consumption_point_obj[name] = handleFormatNumber(value);
    return consumption_point_obj;
}

/* Consumption Type Change */
function handleConsumptionTypeChange(consumption_point, value) {
    consumption_point.consumption_type = value;
    consumption_point.business_type = null;
    if(value === 'SLP') {
        consumption_point.loadprofile_check = false;
        consumption_point.specification = 0;
    }
    return consumption_point;
}

export default handleConsumptionPointFormChange;