import handleFormatNumber from "./handleFormatNumber";
import validateNumericAndCommas from "./validation/validateNumericAndCommas";
import validateVar from "./validation/validateVariable";

//This function is for validating user input BasePrice (€/Year)
//max length is set because database can handle prices with that max size
export default function getValidBasePrice(newValue, oldValue, max_length = 6){
    let lastAddedChar = newValue[newValue.length -1];
    if(lastAddedChar === '.') newValue = newValue.substring(0, newValue.length - 1) + ','; //Replace "." with "," for users who input it this way.
    let dotlessValue = newValue.replace(/\./g, ""); //This var is needed because "tmpValue" is beeing overriden.
    let tmpValue = newValue.replace(/\./g, ""); //Temporary remove all "." for proper further validation
    let validatedValue = oldValue;

    if(validateNumericAndCommas(tmpValue)) { //Allow only numeric and comma signs
        if (tmpValue !== ',') {//Prevent "," as first char
            if (dotlessValue.includes(',')) {//Split value if it contains a ","
                if(!(dotlessValue.replace(/[^,]/g, "").length > 1)){//Prevent User from inserting multiple ","
                    tmpValue = dotlessValue.split(',')[0]
                    if(validateVar(max_length) && max_length < tmpValue.length) return validatedValue;

                    // if (tmpValue.length >= 1 && tmpValue <= 0) {//Prevent input starting with a "0"
                    //     tmpValue = '';
                    // } // causes a bug where the 0 is beeing removed before the comma
                    if(!(tmpValue.length > 15)) { //Allow max 15 characters before comma
                        if (dotlessValue.split(',')[1].length > 2) {//Allow max 2 characters after ","
                            validatedValue = handleFormatNumber(tmpValue) + ',' + dotlessValue.split(',')[1].substring(0, dotlessValue.split(',')[1].length - 1);
                        } else {
                            validatedValue = handleFormatNumber(tmpValue) + ',' + dotlessValue.split(',')[1];
                        }
                    }
                }else validatedValue = oldValue;
            }else if(!(tmpValue.length > 12)) {
                if(validateVar(max_length) && max_length < tmpValue.length) return oldValue;
                if((tmpValue.charAt(0) === '0' && tmpValue.length === 2)) return oldValue;
                validatedValue = handleFormatNumber(tmpValue);//Allow max 15 characters before comma
            }
        }
    }

    return validatedValue;
}