import {rlmProfiles, slpProfiles} from "./global";

function getBusinessTypeWithName(business_type) {
    for (let key in slpProfiles.ELECTRICITY) {
      if (slpProfiles.ELECTRICITY.hasOwnProperty(key) && key === business_type) return business_type + " (" + slpProfiles.ELECTRICITY[key] + ")";
    }
    for (let key in slpProfiles.GAS) {
        if (slpProfiles.GAS.hasOwnProperty(key) && key === business_type) return business_type + " (" + slpProfiles.GAS[key] + ")";
    }
    for (let key in rlmProfiles.ELECTRICITY) {
        if (rlmProfiles.ELECTRICITY.hasOwnProperty(key) && key === business_type) return business_type + " (" + rlmProfiles.ELECTRICITY[key] + ")";
    }
    for (let key in rlmProfiles.GAS) {
        if (rlmProfiles.GAS.hasOwnProperty(key) && key === business_type) return business_type + " (" + rlmProfiles.GAS[key] + ")";
    }
    return business_type;
}
export default getBusinessTypeWithName;
