/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Row,
    Col,
    Container,
} from "reactstrap";
// core components
import DashboardHeader from "../../module_components/Displays/Headers/DashboardHeader";
import connect from "react-redux/es/connect/connect";
import LineGraph from "./Components/LineGraph";
import HeaderInfoCard from "./Components/HeaderInfoCard";
import {handle_chart_data} from "../../actions/new_index";
import validateVar from "../../module_components/Functions/validation/validateVariable";
import LineGraphLoadingAnimation from "./Components/LineGraphLoadingAnimation";
import isEmptyObject from "../../module_components/Functions/isEmptyObject";


class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chart_data: {},
            overview_tasks: {},
            is_loaded_chart_data: false,
            is_loaded_overview_tasks: false,
        };
    }

    componentDidMount() {
        this.props.initializeChartData({}, 'GET');
    }

    componentDidUpdate(prevProps, prevStat) {//chartOptions werden neugeladen
        const {chart_data, overview_tasks} = this.props.analytics;

        if(validateVar(chart_data) && chart_data !== prevProps.analytics.chart_data && !isEmptyObject(chart_data)){
            this.setState({
                chart_data: chart_data,
                is_loaded_chart_data: true,
            });
        }

        if(validateVar(overview_tasks) && overview_tasks !== prevProps.analytics.overview_tasks){
            this.setState({
                overview_tasks: overview_tasks,
                is_loaded_overview_tasks: true,
            });
        }
    }

    render() {
        const {is_loaded_chart_data, is_loaded_overview_tasks, overview_tasks, chart_data} = this.state;
        return (
            <>
                <DashboardHeader name="Dashboard" />
                <Container className="mt--6 dashboard-content" fluid>
                    <Row>
                        <Col md={6}>
                            <HeaderInfoCard is_loaded={is_loaded_overview_tasks} title='Offene Aufträge' count={overview_tasks.open_contracts}
                                            icon='single-copy-04'/>
                        </Col>
                        <Col md={6}>
                            <HeaderInfoCard is_loaded={is_loaded_overview_tasks} title='Offene Klärfälle' count={overview_tasks.open_clearings}
                                            icon='notification-70'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {is_loaded_chart_data ? <LineGraph chart_data={chart_data} is_loaded_chart_data={is_loaded_chart_data} /> : <LineGraphLoadingAnimation />}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

let mapStateToProps = function(state)   {
    return {
        analytics: state.analytics,
    }
};

let mapDispatchToProps = {
    initializeChartData: handle_chart_data,
};

let DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardContainer;