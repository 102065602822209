import Communication from "./Components/Communication/communication";
import { deHashVar } from "../Functions/handleHashedVariable";
import {
    apiCalculationManagementService, apiError,
    apiFunctionDeleteCustomer,
    apiFunctionGetCustomer
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
const consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));

class Support {


    static async sendSupportFormular(id) {
        // this.c = new Communication( //todo service
        //     apiCalculationManagementService,
        //     apiFunctionGetCustomer,
        //     "",
        //     apiFunctionDeleteCustomer,
        //     "",
        //     "",
        //     {
        //         consultant_id: consultant_id
        //     }
        // );


        let params = getAgencyID({});
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetCustomer,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);



        let res = await c.load();
        if (res.status === "ok"){
            return res.result;
        } else return apiError;
    }
}

export default Support;
