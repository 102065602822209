import validateVar from "./validation/validateVariable";
import {emptyValue} from "./global";

/*
    default for multiselect -> if is normal select set param
 */
function getNotificationStatus(status) {

    let translated_status = emptyValue;
   if (validateVar(status)) {
       if (status === "viewed")  translated_status = "Gesehen";
       else translated_status = "Ungesehen"
   }

    return translated_status;
}

export default getNotificationStatus;
