import {
  adminRole,
  managerRole,
  userRole
} from "./module_components/Functions/global";
import UserManagementTable from "./module_components/Pages/UserManagement/UserManagementTable";
import DashboardContainer from "./pages/Dashboard/Dashboard";
import GuideLinesContainer from "./pages/CalculationManagement/Guidelines/Guidelines";
import TariffContainer from "./pages/CalculationManagement/Tariffs/TariffsTable";
import PFCsContainer from "./pages/CalculationManagement/PFCs/PfcTable";
import CompanyDataContainer from "./pages/Administration/CompanyData/CompanyData";
import AddTariffContainer from "./pages/CalculationManagement/Tariffs/TariffHandleBasicData";
import TariffFilesContainer from "./pages/CalculationManagement/Tariffs/TariffHandleFileData";
import ContractHandlerContainer from "./module_components/Pages/Contracts/ContractHandler";
import IntegrationsTableContainer from "./pages/Administration/Integrations/IntegrationsTable";
import LoginContainer from "./pages/Guest/Login/Login";
import ContractContainer from "./module_components/Pages/Contracts/ContractsTable";
import UserProfilContainer from "./pages/UserProfile/UserProfile";
import SetNewPasswordContainer from "./pages/Guest/SetNewPassword/SetNewPassword";
import PasswordRecoveryContainer from "./pages/Guest/PasswordRecovery/PasswordRecovery";
import UserManagementRegisterUserContainer from "./pages/Administration/UserManagement/UserManagementRegisterUser";
import TariffStatusContainer from "./pages/CalculationManagement/Tariffs/TariffHandleStatus";
import OfferTableContainer from "./pages/Offer/OfferTable";
import IntegrationsListContainer from "./pages/Administration/Integrations/IntegrationsList";
import OfferViewContainer from "./pages/Offer/OfferView";
import ClearingTableContainer from "./pages/Clearing/ClearingTable";
import NewTree from "./module_components/Pages/NewTree/NewTree";
import TariffPriceTable from "./module_components/Pages/NewTree/Components/TariffPriceTable";

/* URLS Consultant Project */
export const CalculatorData = "/kalkulator/daten";
export const CalculatorDataCustomer = "/kalkulator/daten/:customer_id";
export const CalculatorComparision = "/kalkulator/vergleich/:token";
export const CalculatorContractData = "/kalkulator/vertragsdaten/:token";
export const CalculatorAttachedData = "/kalkulator/anschlussdaten/:token";
export const CalculatorVerification = "/kalkulator/ueberpruefung/:token";

/* URLS EVU-Login */
export const DashboardPath = "/dashboard";
export const ContractsPath = "/auftraege";
export const SingleContract = "/auftrag";
export const OfferView = "/ranking-details";
export const OffersTablePath = "/angebote";
export const ClearingList = "/aufgaben-liste";
export const Account = "/konto";
export const LoginPath = "/login";
export const PasswordRecoveryPath = "/passwort-zuruecksetzen";
export const SetNewPasswordPath = "/neues-passwort";
export const RegisterPath = "/registrieren";
export const UserManagement = "/nutzerverwaltung";
export const Integrations = "/integrationen";
export const notentifications = "/benachrichtigungen";
export const ActiveIntegrations = "/aktive-integrationen";
export const ConsumptionpointPath = "/lieferstelle";
export const tariff_list = "/tarife";
export const pfc_list = "/pfcs";
export const global_criteria = "/globale-preisstrategie";
export const guide_lines = "/richtlinien";
export const handleTariff = "/tarif";
export const newTariff = "/0";
export const Pricestrategy = "/preisstrategie";
export const tree = "/preisstrategie";
export const TariffDocs = "/tarif-dokumente";
export const TariffStatus = "/tarif-status";
export const CompanyData = "/firmenprofil";
export const UserProfile = "/nutzerprofil";
export const firstPageUrl = "/auftraege";
export const ConsumptionPointClearing = "/lieferstelle-vertrag";
export const TariffPriceTablePath = "/tpt";

/* URLS */
export const Contacts = "/kontakte";
export const Contact = "/kontakt/:id";
export const Contactoverview = "/kontaktansicht/:id";
export const ConsumptionPoint = "/lieferstelle/";
export const Provisions = "/provisionen";
export const Calculation = "/kalkulation";
export const Calculator = "/kalkulator";
export const Data = "/daten";
export const Compare = "/vergleich";
export const ContractData = "/vertragsdaten";
export const Verification = "/ueberpruefung";
export const Billing = "/abrechnung";
export const Profile = "/profil";
export const Customized = "/customize";
export const Support = "/support";
export const SuccessPage = "/vertragsabschluss";
export const Notifications = "/benachrichtigungen";
export const Messages = "/nachrichten";

/* Wattline */
export const rootUrl = "/";
export const Index = "/lastgang-hochladen";
export const Teams = "/teams";
export const TeamView = "/team";
export const Bundling = {
  url: "/buendelung",
  team: "Vertrieb"
};

export const Global = "/global";
export const Electricity = "/strom";
export const Gas = "/gas";
export const Customers = "/kunden";
export const CalculationUnits = "/kalkulationseinheiten";
export const CalculationUnit = "/kalkulationseinheit";
export const Tasks = "/aufgaben";
export const MyTasks = "/meine-aufgaben";
export const Task = "/aufgabe";
export const CustomerView = "/kunde";

export const sidebar_sections = {
  default: {
    title: "", // title
    permissions: [adminRole, managerRole, userRole]
  },
  distribution: {
    title: "Vertrieb", // title
    separator: "my-3",
    permissions: [adminRole, managerRole, userRole]
  },
  calculation: {
    title: "Kalkulation", // title
    separator: "my-3",
    permissions: [adminRole, managerRole]
  },
  administration: {
    title: "Administration", // title
    separator: "my-3",
    permissions: [adminRole]
  }
};

export const auth_urls = [
  /*
       GUEST URLS
 */
  {
    title: "",
    path: LoginPath,
    component: LoginContainer
  },
  {
    title: "",
    path: SetNewPasswordPath,
    component: SetNewPasswordContainer
  },
  {
    title: "",
    path: PasswordRecoveryPath,
    component: PasswordRecoveryContainer
  },
  {
    title: "",
    path: RegisterPath,
    component: UserManagementRegisterUserContainer
  }
];

export const all_urls = [
  // {
  //   title: "Dashboard",
  //   path: DashboardPath,
  //   component: DashboardContainer,
  //   section: "default",
  //   category: "sidebar",
  //   icon: "ni ni-spaceship eless-color",
  //   permissions: [adminRole, managerRole, userRole]
  // },
  // {
  //   title: "Kalkulation",
  //   path: "",
  //   component: "collapse",
  //   section: "calculation",
  //   category: "sidebar",
  //   icon: "ni ni-chart-bar-32 eless-color",
  //   permissions: [adminRole, managerRole],
  //   undercomponents: [
  //     {
  //       title: "Richtlinien",
  //       path: guide_lines,
  //       component: GuideLinesContainer,
  //       permissions: [adminRole, managerRole]
  //     },
  //     {
  //       title: "Globale Kriterien",
  //       path: global_criteria,
  //       component: GlobalPricestrategy,
  //       permissions: [adminRole, managerRole]
  //     },
  //     {
  //       title: "Tarife",
  //       path: tariff_list,
  //       component: TariffContainer,
  //       permissions: [adminRole, managerRole]
  //     },
  //     {
  //       title: "PFCs",
  //       path: pfc_list,
  //       component: PFCsContainer,
  //       permissions: [adminRole, managerRole]
  //     }
  //   ]
  // },
  // // Dynamic Routes
  // {
  //     path: "/tarif/:id",
  //         name: "invisible",
  //     component: AddTariffClassify,
  //     layout: "/admin"
  // },
  // {
  //     path: Consumptionpoint + "/:id",
  //         name: "invisible",
  //     component: ConsumptionPointPage,
  //     layout: "/admin"
  // },
  // {
  //     path: "/preisstrategie/:id/:tree_id",
  //         name: "invisible",
  //     component: TariffHandlePricestrategy,
  //     layout: "/admin"
  // },
  // {
  //     path: "/tarif-dokumente/:id",
  //         name: "invisible",
  //     component: TariffFiles,
  //     layout: "/admin"
  // },
  // {
  //     path: "/tarif-status/:id",
  //         name: "invisible",
  //     component: TariffHandleStatus,
  //     layout: "/admin"
  // },
  // {
  //     path: "/auftrag/:id",
  //         name: "invisible",
  //     component: ContractNavsPage,
  //     layout: "/admin"
  // },

  //Priceranking View Pages need to be defined at the very top to be able to access them since the route is very similar to the Priceranking Table
  {
    path: OffersTablePath + "/:offer_id",
    component: OfferViewContainer,
    permissions: [adminRole]
  },
  {
    title: "Dashboard",
    path: DashboardPath,
    component: DashboardContainer,
    section: "default",
    category: "sidebar",
    icon: "ni ni-spaceship eless-color",
    permissions: [adminRole, managerRole, userRole]
  },
  {
    title: "Aufträge",
    path: ContractsPath,
    component: ContractContainer,
    section: "distribution",
    show_data_count: "open_contracts",
    category: "sidebar",
    icon: "ni ni-single-copy-04 eless-color",
    permissions: [adminRole, managerRole, userRole]
  },
  {
    title: "Angebote",
    path: OffersTablePath,
    component: OfferTableContainer,
    section: "distribution",
    category: "sidebar",
    icon: "ni ni-chart-pie-35 eless-color",
    permissions: [adminRole]
  },
  {
    title: "Klärfälle",
    path: ClearingList,
    component: ClearingTableContainer,
    section: "distribution",
    show_data_count: "open_clearings",
    category: "sidebar",
    icon: "ni ni-chat-round eless-color",
    permissions: [adminRole, managerRole, userRole]
  },

  {
    title: "Richtlinien",
    path: guide_lines,
    component: GuideLinesContainer,
    section: "calculation",
    category: "sidebar",
    icon: "ni ni-briefcase-24 eless-color",
    permissions: [adminRole, managerRole]
  },
  {
    title: "Globale Kriterien",
    path: global_criteria,
    component: NewTree,
    section: "calculation",
    category: "sidebar",
    icon: "ni ni-bulb-61 eless-color",
    permissions: [adminRole, managerRole]
  },
  {
    title: "Tarife",
    path: tariff_list,
    component: TariffContainer,
    section: "calculation",
    category: "sidebar",
    icon: "ni ni-tag eless-color",
    permissions: [adminRole, managerRole]
  },
  {
    title: "PFC",
    path: pfc_list,
    component: PFCsContainer,
    section: "calculation",
    category: "sidebar",
    icon: "ni ni-chart-bar-32 eless-color",
    permissions: [adminRole, managerRole]
  },
  {
    title: "Firmenprofil",
    path: "/firmenprofil",
    component: CompanyDataContainer,
    section: "administration",
    category: "sidebar",
    icon: "ni ni-archive-2 eless-color",
    permissions: [adminRole]
  },

  // {
  //   title: "Erweiterungen",
  //   path: "",
  //   component: "collapse",
  //   section: "administration",
  //   category: "sidebar",
  //   icon: "ni ni-chart-bar-32 eless-color",
  //   permissions: [adminRole],
  //   undercomponents: [
  //     {
  //       title: "Integrationen",
  //       path: "/aktive-integrationen",
  //       component: IntegrationsTableContainer,
  //       permissions: [adminRole]
  //     }
  //     // {
  //     //   title: "Add-Ons",
  //     //   path: "/add-ons",
  //     //   component: {},
  //     //   permissions: [adminRole]
  //     // }
  //   ]
  // },
  {
    title: "Nutzerverwaltung",
    path: UserManagement,
    component: UserManagementTable,
    section: "administration",
    category: "sidebar",
    icon: "ni ni-single-02 eless-color",
    permissions: [adminRole]
  },
  /* END NEW SIDEBAR URLS */

  /*
        SIDEBAR URLS
     */
  // {
  //   title: "Dashboard",
  //   path: DashboardPath,
  //   component: DashboardContainer,
  //   section: "default",
  //   category: "sidebar",
  //   icon: "ni ni-spaceship eless-color",
  //   permissions: [adminRole, managerRole, userRole]
  // },
  // {
  //   title: "Aufträge",
  //   path: ContractsPath,
  //   component: ContractContainer,
  //   section: "default",
  //   category: "sidebar",
  //   icon: "ni ni-single-copy-04 eless-color",
  //   permissions: [adminRole, managerRole, userRole]
  // },
  // {
  //   title: "Kalkulation",
  //   path: "",
  //   component: "collapse",
  //   section: "default",
  //   category: "sidebar",
  //   icon: "ni ni-chart-bar-32 eless-color",
  //   permissions: [adminRole, managerRole],
  //   undercomponents: [
  //     {
  //       title: "Richtlinien",
  //       path: guide_lines,
  //       component: GuideLinesContainer,
  //       permissions: [adminRole, managerRole]
  //     },
  //     {
  //       title: "Globale Kriterien",
  //       path: global_criteria,
  //       component: GlobalPricestrategy,
  //       permissions: [adminRole, managerRole]
  //     },
  //     {
  //       title: "Tarife",
  //       path: tariff_list,
  //       component: TariffContainer,
  //       permissions: [adminRole, managerRole]
  //     },
  //     {
  //       title: "PFCs",
  //       path: pfc_list,
  //       component: PFCsContainer,
  //       permissions: [adminRole, managerRole]
  //     }
  //   ]
  // },
  // {
  //   title: "Firmenprofil",
  //   path: "/firmenprofil",
  //   component: CompanyDataContainer,
  //   section: "administration",
  //   category: "sidebar",
  //   icon: "ni ni-archive-2 eless-color",
  //   permissions: [adminRole, managerRole]
  // },

  // {
  //   title: "Nutzerverwaltung",
  //   path: UserManagement,
  //   component: UserManagementTable,
  //   section: "administration",
  //   category: "sidebar",
  //   icon: "ni ni-single-02 eless-color",
  //   permissions: [adminRole, managerRole]
  // },
  /* END SIDEBAR URLS */

  /* HEADER NAV */
  {
    title: "Profil",
    path: UserProfile,
    component: UserProfilContainer,
    category: "header",
    icon: "ni ni-spaceship eless-color",
    permissions: [adminRole, managerRole, userRole]
  },

  /*
        DEFAULT URLS
     */
  {
    path: handleTariff + "/:id",
    component: AddTariffContainer,
    permissions: [adminRole, managerRole]
  },
  {
    path: SingleContract + "/:contract_id/:protocol_id?/:message_id?",
    component: ContractHandlerContainer,
    permissions: [adminRole, managerRole, userRole]
  },
  {
    path: TariffPriceTablePath,
    component: TariffPriceTable,
    permissions: [adminRole, managerRole, userRole]
  },
  {
    path:
      ConsumptionPointClearing +
      "/:contract_id/:consumption_point_id/:protocol_id?",
    component: ContractHandlerContainer,
    permissions: [adminRole, managerRole, userRole]
  },
  {
    path: Pricestrategy + "/:id/:tree_id",
    component: NewTree,
    permissions: [adminRole, managerRole]
  },
  {
    path: "/tarif-dokumente/:id",
    component: TariffFilesContainer,
    permissions: [adminRole, managerRole]
  },
  {
    path: TariffStatus + "/:id",
    component: TariffStatusContainer,
    permissions: [adminRole, managerRole]
  },
  {
    title: "Integrationen-aktivieren",
    path: "/integrationen",
    component: IntegrationsListContainer,
    permissions: [adminRole]
  },
  {
    title: "Benachrichtigungen",
    path: Notifications,
    component: import(
      "module_components/Pages/Notifications/NotificationTable"
    ),
    permissions: [adminRole, managerRole, userRole]
  },
  {
    title: "Nachrichten",
    path: Messages,
    component: import(
      "module_components/Pages/Notifications/NotificationTable"
    ),
    permissions: [adminRole, managerRole, userRole]
  },
  {
    path: "/globale-preisstrategie",
    component: NewTree,
    permissions: [adminRole, managerRole]
  }
];

export const guestUrls = [
  LoginPath,
  RegisterPath,
  PasswordRecoveryPath,
  SetNewPasswordPath
];
