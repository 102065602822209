import React, { Component } from 'react';
import {Alert} from 'reactstrap';

class AlertBanner extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: true };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.props.deaktivateBanner();
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return <Alert isOpen={this.state.isOpen} toggle={this.toggle} {...this.props} />;
    }
}

export default AlertBanner;
