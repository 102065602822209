import "whatwg-fetch";
import {
    apiBearer,
    apiContentType, apiFunctionGetTree,
    apiServicePricing,
    apiURL,
    apiUser,
    apiUserAuthToken, socketAttribute
} from "./global";


export default async function getTree(treeId) {
  // console.log(
  //   "Data",
  //   JSON.stringify({
  //     user: apiUser,
  //     auth_token: apiUserAuthToken,
  //     service: apiServicePricing,
  //     function: apiFunctionGetTree,
  //     tree_id: treeId.toString()
  //   })
  // );
  return await fetch(apiURL, {
    method: "POST",
    headers: {
      Authorization: apiBearer,
      "Content-Type": apiContentType
    },
    http_errors: false,
    body: JSON.stringify({
      user: apiUser,
      auth_token: apiUserAuthToken,
      service: apiServicePricing,
      function: apiFunctionGetTree,
      param: {
        tree_id: treeId
      }
    })
  })
    .then(response => {
      return response;
    })
    .catch(rejected => {
      console.log("Request failed", rejected);
    });
}

export const transfromTreeToPriceStrategy = (
  currentTree,
  currentListAttributes
) => {
  let newPriceStrategy = [];

  const tree_attributes = [];
  currentTree = currentTree.groups[0];
  currentTree.tree_attributes.map(attribute => {
    const branches = [];
    attribute.branches.map(branch => {
      let currentBranch = {};
      if (socketAttribute.includes(attribute.attribute_id)) {
        let base_price = branch.base_price;
        base_price = base_price.replace(/ /g, "");
        branch.kwh_rate = base_price.replace("*c", "");
      }
      if (branch.constraints.logic_op === "OR") {
        //Multiselect fields are the only with logic_op "OR"
        //the value needs to be converted to an array for the multiselect input
        let valuesArray = branch.constraints.cond_list;
        let value = [];
        for (let index in valuesArray) {
          if (valuesArray.hasOwnProperty(index)) {
            value.push(valuesArray[index].expr_b.VALUE);
          }
        }

        currentBranch = {
          operator: branch.constraints.cond_list[0].comp_op, //comp_op is always the same in multiselect field = "EQ"
          value: value,
          surcharge: branch.kwh_rate,

          isOperatorInvalid: false,
          isValueInvalid: false,
          isSurchargeInvalid: false,
          valueTouched: false,
          surchargeTouched: false,
          inTextform: true
        };
      } else if (branch.constraints.comp_op === undefined) {
        let value1 = branch.constraints.cond_list[0].expr_b.VALUE;
        let value2 = branch.constraints.cond_list[1].expr_b.VALUE;
        currentBranch = {
          operator: branch.constraints.logic_op,
          value1: value1.replace(".", ","),
          value2: value2.replace(".", ","),
          surcharge: branch.kwh_rate,
          isOperatorInvalid: false,
          isValueInvalid: false,
          value1Touched: false,
          value2Touched: false,
          isSurchargeInvalid: false,
          surchargeTouched: false,
          inTextform: true
        };
      } else {
        let value = branch.constraints.expr_b.VALUE;
        currentBranch = {
          operator: branch.constraints.comp_op,
          value: value.replace(".", ","),
          surcharge: branch.kwh_rate,

          isOperatorInvalid: false,
          isValueInvalid: false,
          isSurchargeInvalid: false,
          valueTouched: false,
          surchargeTouched: false,
          inTextform: true
        };
      }

      branches.push(currentBranch);
    });

    let currentAttribute = {};
    currentListAttributes.map(list_attribute => {
      if (list_attribute.attribute_id === attribute.attribute_id) {
        currentAttribute = {
          attribute_id: attribute.attribute_id,
          name: list_attribute.name,
          branches: branches
        };
      }
    });

    tree_attributes.push(currentAttribute);
  });

  newPriceStrategy.push({
    name: "Tarif Kriterien",
    tree_attributes: tree_attributes
  });

  return newPriceStrategy;
};
