import React from 'react';
import cn from "classnames";
import Skeleton from "react-loading-skeleton";
import validateVar from "../../Functions/validation/validateVariable";
import isObjectEmpty from "../../Functions/validation/validateObject";

class PrevStateValue extends React.Component {

    render() {
        const {is_loaded, object, name} = this.props;
        let {history_obj} = this.props;

        let changed = (!isObjectEmpty(history_obj) && validateVar(history_obj) && history_obj.value);
            return(<>{is_loaded ?
                    <span className={cn({'changes-detected': changed})}
                          data-tooltip={(changed) ? history_obj.value : null}>
            {validateVar(object[name]) && validateVar(object[name].value) ? '' : object[name]} {/* There was an error where Iban had the history object instead of a value in its content */}
        </span> : <Skeleton width={250} height={20}/>}</>)
    }
}

export default PrevStateValue;