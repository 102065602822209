function translateRecommendedActionStatus (status) {
    if(status === 'expired') return 'Abgelaufen';
    else if(status === 'failed') return 'Fehlgeschlagen';
    else if(status === 'open') return 'Ausstehend';
    else if(status === 'active') return 'Freigegeben';
    else if(status === 'cancelled') return 'Abgelehnt';
    else if(status === 'submitted') return 'Angenommen';
    else if(status === 'draft') return 'in Prüfung';
    else return status;
}

export default translateRecommendedActionStatus;