/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from 'react';
import {addPfc, deleteMutliplePfc, downloadPfc, initializePfcs, downloadGlobalPfc, toggle_pfc} from '../../../actions';
import {connect} from 'react-redux';
// reactstrap components
import {
    Card,
    Container,
    Row,
} from "reactstrap";
// core components
import SimpleHeader from "../../../module_components/Displays/Headers/SimpleHeader";
// custom components
import DropdownButton from "../../../module_components/Displays/Buttons/DropdownButton";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import ToggleButton from '../../../module_components/Displays/Buttons/ToggleButton';
import PfcCreateModal from './PfcCreateModal';
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import {emptyValue} from "../../../module_components/Functions/global";
import Table from "../../../module_components/Displays/Tables/Table";
import {dynamic_action_call, upload_file_new} from "../../../module_components/Index";

const hpfc_list_default = [
    {
        dataField: "pfc_id",
        text: "ID",
        sort: true,
        style: {fontWeight: 'bold'}
    },
    {
        dataField: "display_custom_file_name",
        text: "name",
        sort: true,
    },
    {
        dataField: "custom_medium_type",
        text: "Energie",
        sort: true,
    },

    {
        dataField: "date_custom_created",
        text: "Hochgeladen am",
        sort: true,
    },
    {
        dataField: "date_custom_from",
        text: "PFC BEGINN",
        sort: true,
    },
    {
        dataField: "date_custom_until",
        text: "PFC Ende",
        sort: true,
    },
    {
        dataField: "average_price_formatted",
        text: "ø Preis",
        sort: true,
    },
];

/*
    START CLASS
 */
class PfcTable extends Component {
    constructor(props) {
        super(props);
        this.firstUpdate = "09:00";
        this.secondUpdate = "13:00";

        this.state = {
            is_loaded: false,
            dropdown_disabled: true,
            reinitialize: false,
            selected_ids: [],
            table_columns: hpfc_list_default,
            pfcs: [],
        };

        this.createPFCModal = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        let {pfcs} = this.props;
        let {reinitialize} = this.state;

        if (validateVar(pfcs) && pfcs !== prevProps.pfcs) {
            let pfcs_ = [...pfcs]

            pfcs_ = pfcs_.map((pfc, index) => {
                pfc.disabled = pfc.average_price === emptyValue;
                return pfc;
            })

            if (reinitialize) {
                //pfc of the same energy_type gets automatically disabled in the api, we need to initialize again right after updating the turned on pfc to get the automatically toggled off value
                this.setState({reinitialize: false, is_loaded: false}); //TODO dieses is_loaded verursacht kurzes blinken der Seite beim toggeln aber sonst updaten sich die togglers in der tabelle nicht nach dem initialize, muss man rausfinden warum
                this.props.initialize();
                // var allButtons = document.getElementsByClassName('toggle-button');
                // for (var button of allButtons) {//TODO Check if its possible to disable them all via state
                //     button.disabled = false;
                // }
            } else {
                this.setState({pfcs: pfcs_, is_loaded: true});
            }

            this.trun_off_pfc_modal();
        }
    }

    componentDidMount() {
        const {table_columns} = this.state;
        this.update_table_columns(table_columns);

        this.messageDisplayed = false;
        this.props.initialize();
    }

    update_table_columns(columns) {
        columns.push({
            dataField: "is_default_translated",
            type: 'bool',
            formatter: (cell, row) => {
                return <ToggleButton id={row.id}
                                     checked={row.is_default === 1}
                                     disabled={row.disabled}
                                     clicked={() => this.update_pfc(row.id, row.is_default === 1 ? 0 : 1)}/>
            },
            text: "Aktiv",
            sort: true,
        })

        this.setState({table_columns: columns});
    }

    update_pfc = (id, active) => {
        //TODO Schauen vllt kann man die togglers doch irgendwie kurzzeitig disablen anstatt die ganze Seite neu zu laden während der request läuft
        //TODO mit jetzigen wissensstand ist klar dass die tabelle sich nicht rerendert wenn man die pfcs state nochmal neu state, bzw
        // let {pfcs} = this.state;
        //
        // for(let index in pfcs){
        //     if(pfcs.hasOwnProperty(index))
        //     pfcs[index].disabled = true;
        // }
        //
        // this.setState({pfcs: pfcs});

        var allButtons = document.getElementsByClassName('toggle-button');
        for (var button of allButtons) {//TODO Check if its possible to disable them all via state
            button.disabled = true;
        }

        this.setState({reinitialize: true});
        this.props.toggle_pfc({pfc_id: id, active: active})
    }

    row_selected = (selected) => { //3 parameter could be "rowIndex"
        this.setState({selected_ids: selected});
        if (this.state.dropdown_disabled && selected.length > 0) this.setState({dropdown_disabled: false});
        if (!this.state.dropdown_disabled && selected.length === 0) this.setState({dropdown_disabled: true});
    };

    delete_from_table = () => {
        let selected = this.state.selected_ids;

        this.messageDisplayed = false;


        let pfcs = this.props.pfcs;
        let delete_ids = [];

        for (let index in pfcs) {
            if (pfcs.hasOwnProperty(index)) {
                let pfc = pfcs[index];
                if (selected.includes(pfc.id) && +pfc.is_default === 0) delete_ids.push(pfc.id);
            }
        }

        this.props.onDelete(delete_ids);
        this.setState({selected_ids: []});
    };

    download_fromtable = () => {
        this.props.onDownload(this.state.selected_ids);
        this.setState({selected_ids: []});
        this.uncheck_all_checkboxes();
    };

    // just like the function name says - unchecks all checkboxes in data table
    uncheck_all_checkboxes = () => {
        let checkboxes = document.getElementsByClassName('table-checkbox');
        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].type === 'checkbox')
                checkboxes[i].checked = false;
        }
    };

    toggle_create_pfc_modal = () => {
        this.createPFCModal.current.toggleModal();
    };

    trun_off_pfc_modal = () => {
        if (this.createPFCModal.current !== null) {
            this.createPFCModal.current.turnOffModal();
        }
    };

    add_pfc_trigger = (params, file) => {
        this.messageDisplayed = false;
        this.props.onAdd(params, file);
    };

    render() {
        const {table_columns, pfcs, is_loaded, selected_ids} = this.state;
        const {dynamic_action_call, default_file_upload} = this.props;

        return (<>
            <PfcCreateModal addPFC={this.add_pfc_trigger}
                            ref={this.createPFCModal}
                            dynamic_action_call={dynamic_action_call}
                            default_file_upload={default_file_upload} />
            <SimpleHeader name="PFC" headerText={''}/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <Table table_name={"ehub_hpfcs"}
                                   data={pfcs}
                                   key_field={'pfc_id'}
                                   columns={table_columns}
                                   is_loaded={is_loaded}
                                   paginator_strings={["PFCs", "PFCs"]}
                                   empty_table_message={"Es wurden keine PFCs hochgeladen"}
                                   selected_ids={selected_ids}
                                   on_checkbox_click={this.row_selected}
                                   break_width='700'
                                   update_columns={(table_columns) => this.update_table_columns(table_columns)}>
                                     <span className='responsive-button-wrapper'>
                                        <PrimaryButton disabled={false}
                                                       onClick={() => this.props.onDownloadGlobal()}
                                                       value='Globale PFCs Herunterladen'
                                                       classes='mr-3'/>
                                    </span>
                                    <span className='responsive-button-wrapper'>
                                        <DropdownButton wrapper_classes='mr-3'
                                                        tooltipProp="PFC's"
                                                        disabled={this.state.dropdown_disabled}
                                                        downloadFromTable={() => this.download_fromtable()}
                                                        deleteSelected={() => this.delete_from_table()}
                                                        include={['delete', 'export']}/>
                                    </span>
                                    <span className='responsive-button-wrapper'>
                                        <PrimaryButton disabled={false}
                                                       onClick={this.toggle_create_pfc_modal}
                                                       value={'PFC hinzufügen'} classes='mr-3'/>
                                    </span>
                            </Table>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>);
    }
}

let mapStateToProps = function (state) {
    return {
        pfcs: state.pfcs,
    }
};

let mapDispatchToProps = {
    initialize: initializePfcs,
    onDelete: deleteMutliplePfc,
    onAdd: addPfc,
    onDownload: downloadPfc,
    onDownloadGlobal: downloadGlobalPfc,
    toggle_pfc: toggle_pfc,
    //FileUpload requests
    dynamic_action_call: dynamic_action_call,
    default_file_upload: upload_file_new,
};

let PFCsContainer = connect(mapStateToProps, mapDispatchToProps)(PfcTable);

export default PFCsContainer;
