let initialState = null;

function download_file(state = initialState, action) {
    if (action.type === "DOWNLOAD_FILE") {
        return action.res;
    } else if (action.type === "RESET_DOWNLOAD_FILE") {
        return action.res;
    }
    else return state;
}

export default download_file;
