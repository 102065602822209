/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
// core components
import SimpleHeader from "../../../module_components/Displays/Headers/SimpleHeader.jsx";
import hasChanged from "../../../module_components/Functions/hasChanged";
import SaveFormData from "../../../module_components/Displays/Footers/SaveFormData";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import {updateCompanyAndParnter} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import InputHandler from "../../../module_components/Inputs/InputHandler";

class Guidelines extends React.Component {
    state = {
        has_changed: false,
        is_loaded: false,
        company_data: {}
    };

    componentDidMount(){
        let {company} = this.props;
        let {is_loaded} = this.state;
        if (validateVar(company) && !is_loaded){
            this.setState({
                company_prev_data: {
                    cancellation_service: company.cancellation_service,
                    accept_differing_billing_address: company.accept_differing_billing_address,
                },
                company_data: {
                    cancellation_service: company.cancellation_service,
                    accept_differing_billing_address: company.accept_differing_billing_address,
                },
                is_loaded: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {company} = this.props;
        if (company !== prevProps.company) {
            if (validateVar(company) && !this.state.is_loaded) {
                this.setState({
                    company_prev_data: {
                        cancellation_service: company.cancellation_service,
                        accept_differing_billing_address: company.accept_differing_billing_address,
                    },
                    company_data: {
                        cancellation_service: company.cancellation_service,
                        accept_differing_billing_address: company.accept_differing_billing_address,
                    },
                    is_loaded: true
                });
            }
        }
    }

    /* CLOSE SAVE BUTTON */
    cancelFormChanges = () => {
        let {company_prev_data} = this.state;
        this.setState({
            company_data: {...company_prev_data}, //reset input values
            has_changed: false
        });
    };

    /*
        ON CHANGE FUNCTION
        formating values of changed state
        checks if states have changed (if yes display submit btn)
        updating states abstract
    */
    updateState = (name, value) => {
        let {company_data, company_prev_data} = this.state;
        let has_changed = false;
        company_data[name] = value; // set new value in current state
        if (!has_changed) // must be checked or has_changed will be overwritten and not work
            has_changed = hasChanged(company_data[name], name, company_prev_data, company_data);
        // let has_changed = hasChanged(value, state_name, this.state.company_prev_data, this.state.company_data);
        this.setState({
            company_data: company_data,
            has_changed: has_changed,
        });
    };

    saveFormChanges = (data) => {
        let params = {};
        params.cancellation_service = data.company_data.cancellation_service;
        params.accept_differing_billing_address = data.company_data.accept_differing_billing_address;
        this.props.onUpdate(params);
        this.setState({
            is_loaded: false,
            has_changed: false
        });
    };

    render() {
        const {is_loaded, company_data, has_changed} = this.state;

        return (<>
            <SimpleHeader name="Richtlinien" headerText="" />
            <Container className="mt--6" fluid>
                <Row className="justify-content-center">
                    <Col className="card-wrapper" lg="12">
                        <Card>
                            <CardHeader className={'pb-2'}>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2 className='d-inline-block pr-3'>Einstellungen</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                    <Row className={'px-3 pb-2 mb-4'}>
                                        <Col className={'px-3'} xl={6}>
                                            <InputHandler content_title='Kündigung des Altversorgers anbieten'
                                                          type='radio'
                                                          name='cancellation_service'
                                                          first_radio={{id: 'cancel', text: 'Ja', value: "YES"}}
                                                          second_radio={{id: 'dontCancel', text: 'Nein', value: "NO"}}
                                                          value={company_data.cancellation_service}
                                                          is_loaded={is_loaded}
                                                          skeleton={['100', 20]}
                                                          on_change={(name, value) => this.updateState(name, value)}
                                            />
                                        </Col>
                                        <Col className={'px-3'} xl={6}>
                                            <InputHandler content_title='Abweichende Rechnungsanschriften akzeptieren'
                                                          type='radio'
                                                          name='accept_differing_billing_address'
                                                          first_radio={{id: 'isDifferent', text: 'Ja', value: "YES"}}
                                                          second_radio={{id: 'isNotDifferent', text: 'Nein', value: "NO"}}
                                                          value={company_data.accept_differing_billing_address}
                                                          is_loaded={is_loaded}
                                                          skeleton={['100', 20]}
                                                          on_change={(name, value) => this.updateState(name, value)}
                                            />
                                        </Col>
                                    </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <SaveFormData submitCallback={() => this.saveFormChanges({...this.state})} cancelCallback={() => this.cancelFormChanges()} display={has_changed} text={'Sie haben Einstellungen auf dieser Seite geändert. Wollen Sie die Änderungen speichern?'} />
            </>
        );
    }
}


let mapStateToProps = function(state)   {
    return {
        company: state.company,
    }
};

let mapDispatchToProps = {
    onUpdate: updateCompanyAndParnter,
};

let GuideLinesContainer = connect(mapStateToProps, mapDispatchToProps)(Guidelines);

export default GuideLinesContainer;