import Communication from "./Components/Communication/communication";
import { deHashVar } from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import handleFormatPrice from "../Functions/handleFormatPrice";
import {
    apiError,
    apiFunctionCalculateNetFee,
    apiServiceNetfee,
    arrayWithElectricityTranslationsTest, arrayWithGasTranslationsTest
} from "../Functions/global";
import getCommunicationClass from "../Functions/getCommunication";

const consultant_id = deHashVar(localStorage.getItem("consultantSecurityToken"));
const employee_id = deHashVar(localStorage.getItem("consultantEmployeeSecurityToken"));
const hasDifferentAmountThanKWh = ['peak', 'socket', 'fee_meter_reading', 'fee_measurement_reading', 'fee_install_operate'];

class Netfee {
    constructor(data, communication = undefined, contract_duration) {
        this.name = "";
        this.name_translated = "";
        this.value_euro = "";
        this.value_euro_display = '';
        this.value_ct = "";
        this.value_ct_display = '';
        this.amount = false; //information for frontend to display different amount value than kWh. F.e. Months
        if(contract_duration > 12) contract_duration = 12;

        let translationArr = arrayWithElectricityTranslationsTest[data.type];
        if (data.medium_type === "GAS")  translationArr = arrayWithGasTranslationsTest[data.type];

        if (validateVar(data) && validateVar(data.name) && validateVar(translationArr)) {
            this.name = !validateVar(data.name) ? "" : data.name;
            this.name_translated =
                !validateVar(data.name) ? "" : translationArr[data.name];
            this.value_euro = !validateVar( data.value_euro ) ? "" : data.value_euro;
            this.value_euro_display = !validateVar( data.value_euro ) ? "" : handleFormatPrice(data.value_euro + "", 2, "€/Jahr");
            if(hasDifferentAmountThanKWh.includes(data.name)){
                if(data.name === 'peak'){ //peak (Leistungspreis) is shown in kW
                    if(data.value_euro == 0 && data.netfee.peak_load == 0) this.value_ct = 0;
                    else this.value_ct = data.value_euro / data.netfee.peak_load;
                    this.value_ct_display = !validateVar( data.value_euro ) ? "" : handleFormatPrice(this.value_ct, "2", "€/kW");
                    this.amount = handleFormatPrice(data.netfee.peak_load, "2", " kW");
                }else{ //socket and fee_meter_reading is shown in €/month
                    this.value_ct = !validateVar( data.value_euro ) ? "" : this.getPricePerMonth(data.value_euro, contract_duration);
                    this.value_ct_display = !validateVar( data.value_euro ) ? "" : handleFormatPrice(this.value_ct, "2", "€/Monat");
                    this.amount = contract_duration + ' Monate';
                }
            }else{
                this.value_ct = !validateVar( data.value_euro )  ? "" : this.getPriceInCt(data.value_euro, data.consumption);
                this.value_ct_display = !validateVar( data.value_euro ) ? "" : handleFormatPrice(this.value_ct, "3", "ct/kWh");
            }
        }
    }

    getPricePerMonth(val, months){
        let res = val / months;
        return res.toFixed(2);
    }

    getPriceInCt(val, consumption){
        let res = val * 100 / consumption;
        return res.toFixed(3);
    }

    static async getNetFee(params, contract_duration = null){
        if(!validateVar(params.consultant_id) && validateVar(consultant_id)) params.consultant_id = consultant_id; // KZ / 24012022 / On Guestpage consultant_id can't be set through local storage
        if(!validateVar(params.employee_id) && validateVar(employee_id)) params.employee_id = employee_id; // KZ / 24012022 / On Guestpage employee_id can't be set through local storage
        params.options = {
            with_hidden: 1
        };
        let type = "SLP";
        if (params.is_rlm === "YES" && params.is_slp === "YES") type = "SLP + RLM";
        else if (params.is_rlm === "YES") type = "RLM";
        let communication_params = {
            service: apiServiceNetfee,
            get_function: apiFunctionCalculateNetFee,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);

        var res = await c.load();
        if (res.status === "ok"){
            let netfee_object = [];
            let netfee = res.result;

            for (let [key, value] of Object.entries(netfee)) {
                if ((validateVar(arrayWithElectricityTranslationsTest[type]) && (arrayWithElectricityTranslationsTest[type].hasOwnProperty(key) && params.medium_type === "ELECTRICITY")) || (validateVar(arrayWithElectricityTranslationsTest[type]) && (arrayWithGasTranslationsTest[type].hasOwnProperty(key) && params.medium_type === "GAS")))
                netfee_object.push(new Netfee({name: key, value_euro: value, medium_type: params.medium_type, consumption: params.consumption, type: type, netfee: netfee}, undefined, contract_duration));
            }
            return netfee_object;
        } else{
            return apiError;
        }
    }
}

export default Netfee;
