import validateVar from "./validation/validateVariable";
import is_true from "./is_true";

export default function getConsultantChargeAmount(amount, unit, consumption, yearly = false) {
    if (!validateVar(amount) || amount + "" === "0.00") {
        return "0.000";
    }
    if (is_true(yearly) && unit === "EUR_PER_MONTH")  amount = ((+amount * 12));
    if (unit === "EUR_PER_MONTH") amount = ((+amount * 12) / consumption) * 100;
    return Number.parseFloat(amount).toFixed(3);
}
