function getCancellationReasonDisplay(value) {
    switch (value) {
        case 'calculation': return 'Kalkulation';
        case 'missing_solvency': return 'Keine Bonität';
        case 'bad_solvency': return 'Abweichende Bonität';
        case 'business_type': return 'Abweichende Branche';
        case 'else': return 'Sonstiges';
    }
}

export default getCancellationReasonDisplay;