import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";
import callClassWithString from "../../../Functions/callClassWithString";

let tariff_information_vars = {
    is_sub_arr: "tariff_information",
    translation_object: {
        agb_file_id: "AGB",
        labelling_file_id: "Kennzeichnungsdatei",
    },
    has_history: true,
    agb_file_id: null,
    labelling_file_id: null,
    contract_template_id: null
};

class Tariff_Information {
    static default_values = tariff_information_vars;

    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(tariff_information_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static dummy() {
        let output = {};
        output = this.build_dummy(tariff_information_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    } else if (validateVar(vars[index].is_class_arr)) {

                        let class_name = callClassWithString(index);
                        output[index] = class_name.constructor.dummy();
                    } else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default Tariff_Information;
