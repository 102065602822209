import validateVar from "./validation/validateVariable";

function isEmptyObject(obj) {
    for(var key in obj) {
        if(validateVar(obj[key]))
            return false;
    }
    return true;
}

export default isEmptyObject;