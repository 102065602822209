import validateVar from "./validation/validateVariable";
import {emptyValue} from "./global";

function getFullAddress(zip, city, street, house_number, addition = null){
    if(validateVar(zip) && validateVar(city) && validateVar(street) && validateVar(house_number)) {
        let address =  zip + ' ' + city + ', ' + street + ' ' + house_number;
        if (validateVar(addition)) address = address + " " + addition;
        return address;
    } else return emptyValue;
}
export default getFullAddress;