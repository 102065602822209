import React from 'react';
import Skeleton from "react-loading-skeleton";
import {Col, Row} from "reactstrap";

class TimelineLoadingAnimation extends React.Component {

    render() {
        return(<div className="info-section">
            <div className="custom-border-bottom">
                <div className="timeline-block">
                    <Skeleton width={'100%'} height={90} />
                </div>
                <div className="timeline-block">
                    <Skeleton width={'100%'} height={90} />
                </div>
                <div className="timeline-block">
                    <Skeleton width={'100%'} height={90} />
                </div>
                <div className='bg-color-transparent p-0 mb-2'>
                    <Skeleton width={'100%'} height={45} />
                </div>
            </div>
            <Row>
                <Col>
                    <Skeleton width={'100%'} height={45} />
                </Col>
            </Row>
        </div>)
    }
}

export default TimelineLoadingAnimation;