import React from 'react';
import {Tooltip} from 'reactstrap';
import {emptyValue} from "../../Functions/global";
import validateVar from "../../Functions/validation/validateVariable";

class TooltipComponent extends React.Component {

    state = {
        tooltipOpen: false,
    };

    render() {
        const {tooltip_data} = this.props;
        const {tooltipOpen} = this.state;
        //TODO check if tooltip_data.text is '- / -' should not be needed, remove that string from the software by replacing it with global variable emptyValue
        return <>
            {validateVar(tooltip_data.text) && tooltip_data.text !== emptyValue && tooltip_data.text !== '- / -' ? <>
            <div id={tooltip_data.id} className={'d-inline-block ' + tooltip_data.class_names}>
                <i className="fas fa-info-circle" />
            </div>
            <Tooltip trigger='hover' placement='right' isOpen={tooltipOpen} target={tooltip_data.id} toggle={() => this.setState({tooltipOpen: !this.state.tooltipOpen})}>
                {tooltip_data.text}
            </Tooltip>
            </> : emptyValue}
        </>
    }
}

export default TooltipComponent;