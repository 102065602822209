import {Row, Col} from "reactstrap";
import React, {useState} from "react";
import PropTypes from 'prop-types';

const RadiosBoolean = (props) => {
    const [ first_radio, set_first_radio] = useState(false);
    const [ second_radio, set_second_radio] = useState(false);

    function radioBtnChange(e){ //On change, pass data to parent
        var value = e.target.getAttribute("data-value");
        props.on_change(props.name, value);
    }

    if (props.value === props.first_radio.value && first_radio === false) {
        set_first_radio(true);
        set_second_radio(false);
    }else if(props.value === props.second_radio.value && second_radio === false) {
        set_first_radio(false);
        set_second_radio(true);
    }

    let firstRadioDisabled = props.first_radio.disabled;
    let secondRadioDisabled = props.second_radio.disabled;

    if (props.disabled === true) {
        firstRadioDisabled = true;
        secondRadioDisabled = true;
    }

    return(
        <div className='max-form-width'>
            <Row>
                <Col xl={6}>
                    <div className="custom-control custom-radio">
                        <input
                            className="custom-control-input"
                            id={props.first_radio.id}
                            data-value={props.first_radio.value}
                            name={props.name}
                            type="radio"
                            checked={first_radio}
                            disabled={firstRadioDisabled}
                            onChange={() => function (){}}
                            onClick={(e) => radioBtnChange(e)}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor={props.first_radio.id}
                        >
                            {props.first_radio.text}
                        </label>
                    </div>
                </Col>
                <Col xl={6}>
                    <div className="custom-control custom-radio">
                        <input
                            className="custom-control-input"
                            id={props.second_radio.id}
                            name={props.name}
                            data-value={props.second_radio.value}
                            type="radio"
                            checked={second_radio}
                            disabled={secondRadioDisabled}
                            onChange={() => function (){}}
                            onClick={(e) => radioBtnChange(e)}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor={props.second_radio.id}
                        >
                            {props.second_radio.text}
                        </label>
                    </div>
                </Col>
            </Row>
        </div>
    )
};


RadiosBoolean.propTypes = {
    name: PropTypes.string.isRequired, //Name of the Radios (ideally same as the state name)
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([ // default value
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
    ]),
    first_radio: PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,  // Displayed Radio-Text
        value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
        ]),  // The value which will be passed to parent from the selected Radio
    }),
    second_radio: PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired, // Displayed Radio-Text
        value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
        ]), // The value which will be passed to parent from the selected Radio
    }),
};

export default RadiosBoolean;
