/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";

// core components
import AuthNavbar from "./NavBars/AuthNavbar.jsx";
import AuthNavbarContract from "./NavBars/AuthNavbarContract.jsx";

import validateVar from "../Functions/validation/validateVariable";
import NotificationAlert from "react-notification-alert";
import {
    get_plattform_settings,
    resetNotification,
    redirect,
} from "../Index";
import connect from "react-redux/es/connect/connect";
import CustomAlert from "../CustomAlert/CustomAlert";
import handleCustomData from "../Functions/setPlattformColor";
import LoginContainer from "../../pages/Guest/Login/Login";
import importedComponent from "react-imported-component";
import {auth_urls} from "../../globalNavigation";
import cn from 'classnames';
import AuthFooter from "./Footer/AuthFooter";

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message_feedback: {
                title: "",
                text: "",
                primaryBtnText: "",
                secondaryBtnText: "",
                successIcon: "",
                infoIcon: "",
                primaryBtnClicked: "",
                secondaryBtnClicked: "",
                url: "",
                allowToggle: "",
            },
            is_loaded_plattform: false,
            plattform: null,
        };
        this.feedback_modal = React.createRef();
    }


    UNSAFE_componentWillMount() {
        handleCustomData(); // set page color from localstorage
        //if there is no logo saved in the local storage, get the standard logo
        if(!localStorage.getItem("logo_id")) this.props.getPlattformSettings();
    }

  componentDidMount() {
    const {contract_guest_page} = this.props;

    if(!validateVar(contract_guest_page)) document.body.classList.add("eless-bg");

      let routes = auth_urls.map((url, index) => {
          if (url.component === "collapse") {
              return url.undercomponents.map((undercomponent, index) => {
                  return this.create_route(undercomponent, index);
              })
          } else {
              return this.create_route(url, index);
          }
      });

      //plattform is not beeing requested on Ehub so set loaded to true
      this.setState({routes: routes});
  }

  componentWillUnmount() {
    document.body.classList.remove("eless-bg");
  }


  componentDidUpdate(prevProps, prevState) {
    const {plattform, message_feedback, offer} = this.props;
    const {is_loaded_plattform} = this.state;

    //cant check with validateVar because the response is empty string in case where no settings were found
    if(plattform !== null && plattform !== prevProps.plattform){
        this.setState({plattform: plattform})
    }

    if (prevProps.history.pathname !== prevProps.location.pathname) {
      if(validateVar(this.refs.mainContent)){
        this.refs.mainContent.scrollTop = 0;
      }
      if(validateVar(document.scrollingElement)){
        document.scrollingElement.scrollTop = 0;
      }
      document.documentElement.scrollTop = 0;
    }

      if(prevProps.message_feedback !== message_feedback){//this is needed or there wont any other message ever appear
          this.setState({messageDisplayed: false});
      }

      if(validateVar(message_feedback) && prevProps.message_feedback !== message_feedback){
          if(validateVar(message_feedback.url) && validateVar(message_feedback.automatic_redirect) && message_feedback.automatic_redirect){
              window.location = message_feedback.url
          }else if(!this.state.messageDisplayed){
              if(message_feedback.modal){ //user will get a modal
                  this.toggle_feedback_modal();
              }else{ // user will get a push message
                  this.setState({messageDisplayed: true});
                  this.notify({headline: message_feedback.headline, type: message_feedback.type, message: message_feedback.message});
              }
          }
          if(!this.state.messageDisplayed){
              this.setState({
                  message_feedback: message_feedback
              });
              this.props.onResetNotification();
          }
      }

      if(validateVar(offer) && offer !== prevState.offer && !is_loaded_plattform) {
          if(validateVar(offer.consultant_id)) {
              this.props.getPlattformSettings(false, {agency_id: offer.consultant_id});
              this.setState({is_loaded_plattform: true});
          }
      }
  };

    create_route = (url, index) => {
        const Component = importedComponent(() => url.component);
        return <Route
            path={url.path}
            component={Component}
            key={index}
        />
    };

    notify = (props) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {props.headline}!
          </span>
                    <span data-notify="message">
                {props.message}
          </span>
                </div>),
            type: props.type,
            icon: "ni ni-bell-55",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    secondBtnFunction = (secondaryBtnFunction) => {
        if (!validateVar(secondaryBtnFunction))  {
            this.feedback_modal.current.turnOffModal();
        } else {
            window.location = secondaryBtnFunction;
        }
    };

    toggle_feedback_modal = () => {
        this.feedback_modal.current.toggle_modal();
    };

    redirect = (url) => {
        window.location.href = url
    };
  render() {
      const {routes, message_feedback} = this.state;
      const {contract_guest_page} = this.props;
      const {headline, plattform, message, primaryBtnText, secondaryBtnText, successIcon, infoIcon, url, allowToggle, secondaryBtnFunction} = message_feedback;
      let feedback = false;
      if (validateVar(message_feedback)){
          feedback = true;
      }
      let redirectOnSubmit = false;
      if(validateVar(url)) redirectOnSubmit = true;
    return (
        <>
            {validateVar(contract_guest_page) && <AuthNavbarContract plattform={plattform} />}
          <div className={cn({'container': contract_guest_page})}>
              <div className="rna-wrapper">
                  <NotificationAlert ref="notificationAlert" />
              </div>
              {feedback &&
              <CustomAlert
                  title={headline}
                  text={message}
                  primaryBtnText={primaryBtnText}
                  secondaryBtnText={secondaryBtnText}
                  primaryBtnClicked={redirectOnSubmit ? () => this.redirect(url) : () => this.feedback_modal.current.toggle_modal()}
                  secondaryBtnClicked={() => this.secondBtnFunction(secondaryBtnFunction)}
                  ref={this.feedback_modal}
                  successIcon={successIcon}
                  infoIcon={infoIcon}
                  allowToggle={allowToggle}
              />}
              <div className="main-content" ref="mainContent">
              {!validateVar(contract_guest_page) && <AuthNavbar plattform={plattform} />}
              <Switch>
                  {routes}
                  <Route component={LoginContainer}/> {/* If page not found redirect here */}
              </Switch>
            </div>
          </div>
            {validateVar(contract_guest_page) && <AuthFooter />}
        </>
    );
  }
}


let mapStateToProps = function(state)   {
    return {
        plattform: state.plattform,
        message_feedback: state.message_feedback,
        offer: state.offer,
    }
};

let mapDispatchToProps = {
    getPlattformSettings: get_plattform_settings,
    onResetNotification: resetNotification,
    redirect: redirect,
};

let AuthContainer = connect(mapStateToProps, mapDispatchToProps)(Auth);

export default AuthContainer;
