/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from 'react';
// react component for creating dynamic tables
// react component used to create sweet alerts

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AddTariffHeader from "./components/AddTariffHeader";
// custom components
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import SecondaryButton from "../../../module_components/Displays/Buttons/SecondaryButton";
import {TariffDocs} from "../../../globalNavigation";
import {getLoadProfiles,
initializeTariff,
testCalculation,
updateTariff,
resetRedirect,
redirect} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import {deHashVar} from "../../../module_components/Functions/handleHashedVariable";
import handleFormatDurationDate from "../../../module_components/Functions/handleFormatDurationDate";
import MaterialUIStepper from "./components/MaterialUIStepper";
import TariffCalculatedContainer from "../../../module_components/Pages/Calculation/Components/CalculatedTariff/TariffCalculatedContainer";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import monthDiff from "../../../module_components/Functions/getMonthDiff";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import Validation from "../../../module_components/Objects/Components/validation";
import Skeleton from "react-loading-skeleton";
import GreyLinkButton from "../../../module_components/Displays/Buttons/GreyLinkButton";
import Badge from "../../../module_components/Displays/Badges/Badge";
import NoTariffsFound from "../../../module_components/Pages/Calculation/Components/NoTariffsFound";
import ConsumptionPointForm from "./components/ConsumptionPointForm";
import is_false from "../../../module_components/Functions/is_false";
import {apiError} from "../../../module_components/Functions/global";
import is_true from "../../../module_components/Functions/is_true";
import handleFormatDate from "../../../module_components/Functions/handleFormatDate";
import SubmitLoadingAnimationModal from "../../../module_components/LoadingAnimations/SubmitLoadingAnimationModal";
import '../../../module_components/Pages/Calculation/Components/Assets/Calculation.scss'

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));

var minDateStart = new Date(handleFormatDurationDate('getMinDurationDate', new Date()));
var maxDateStart = new Date(handleFormatDurationDate('getMaxDurationDate'));
maxDateStart = new Date(maxDateStart.setMonth(maxDateStart.getMonth() - 2));

var minDateEnd = new Date(handleFormatDurationDate('getMinDurationDate', new Date()));
minDateEnd = new Date(minDateEnd.setMonth(minDateEnd.getMonth() + 1));
var maxDateEnd = new Date(handleFormatDurationDate('getMaxDurationDate'));

/* START CLASS */
class TariffHandleStatus extends Component {
    constructor(props) {
        super(props);
        this.id = parseInt(props.match.params.id);
        this.state = {
            tariff: {},
            calculated_tariffs: null,
            error_messages: {0: {}, 1: {}},
            load_profiles: [],
            is_calculating: false,
            disable_activate_tariff: true,
            show_default_profiles: false,
            company: null,
            submit_value: 'Aktivieren',
            is_loaded: false,
            input_disabled: false,
            submit_loading: false,
            tariff_type: '', //SLP / RLM / Bundle
            offer_begin: handleFormatDurationDate('getDefaultStartDate'),
            offer_end: handleFormatDurationDate('getDefaultEndDate', new Date()),
            consumption_points: [{
                predicted_consumption: "",
                last_profile: "G0",
                last_type: '',
                state: "",
                address: {
                    postal_code: '',
                    city: '',
                    street: '',
                    house_number: ''
                },
            }]
        };
    }

    componentDidMount() {
        this.props.onResetRedirect();
        if (this.id > 0) {
            this.props.onInitialize({
                supplier_id: supplier_id,
                tariff_id: this.id
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {tariff, calculated_tariffs, load_profiles} = this.props;
        const {disable_activate_tariff, is_calculating} = this.state;
        let {consumption_points, tariff_type} = this.state;

        if (validateVar(tariff) && tariff !== prevProps.tariff && tariff !== apiError) {
            if (!validateVar(prevProps.tariff)) { // first time loaded
                if (tariff.is_slp === "NO" && tariff.is_rlm === "YES") { //CASE RLM TARIFF
                    tariff_type = 'RLM';
                    consumption_points[0].last_type = "RLM";
                    if (tariff.medium_type === "ELECTRICITY") {
                        consumption_points[0].last_profile = "R0";
                    } else { // SLP
                        consumption_points[0].last_profile = "1D3/1D4";
                    }
                    this.props.getLoadProfiles(tariff.medium_type, 'RLM', true);
                } else if(tariff.is_slp === "YES" && tariff.is_rlm === "YES") { //CASE BUNDLE TARIFF
                    consumption_points[1] = {...consumption_points[0]};
                    consumption_points[1].address = {...consumption_points[0].address};
                    consumption_points[0].last_type = 'SLP';
                    consumption_points[1].last_type = 'RLM';
                    if (tariff.medium_type === "ELECTRICITY") {
                        consumption_points[0].last_profile = 'G0';
                        consumption_points[1].last_profile = 'R0';
                    }else{
                        consumption_points[0].last_profile = '1D3/1D4';
                        consumption_points[1].last_profile = '1D3/1D4';
                    }
                    tariff_type = 'Bundle';
                    this.props.getLoadProfiles(tariff.medium_type, 'SLP', true);
                } else { //CASE SLP TARIFF
                    tariff_type = 'SLP';
                    consumption_points[0].last_type = "SLP";
                    if (tariff.customer_type === "PRIVATE") {
                        consumption_points[0].last_profile = "H0";
                    }else consumption_points[0].last_profile = "G0";

                    if (tariff.medium_type === "GAS") {
                        consumption_points[0].last_profile = "1D3/1D4";
                    }
                    this.props.getLoadProfiles(tariff.medium_type, 'SLP', true);
                }

                this.setState({
                    consumption_points: consumption_points,
                    tree_id: tariff.tree_id,
                    tariff_type: tariff_type,
                    submit_loading: false,
                });
            }

            this.setState({tariff: {...tariff}});
        }

        if (validateVar(calculated_tariffs) && calculated_tariffs !== prevProps.calculated_tariffs) {
            if (calculated_tariffs !== "ERR") {
                this.setState({
                    disable_activate_tariff: false,
                    calculated_tariffs: calculated_tariffs
                })
            }
            this.setState({
                is_calculating: false,
                input_disabled: false
            })
        } else if (validateVar(calculated_tariffs) && ((is_calculating && calculated_tariffs === apiError) || (is_calculating && calculated_tariffs === "No result"))) {
            this.setState({
                is_calculating: false,
            })
        }

        //disable activate button if the tariff is already active
        if (validateVar(tariff) && is_false(disable_activate_tariff)) {
            if (tariff.lifecycle_status === 'Online' || tariff.lifecycle_status === '')
                this.setState({
                    disable_activate_tariff: true,
                    submit_value: 'Dieser Tarif ist Aktiv',
                    submit_loading: false
                });
        }

        if (validateVar(load_profiles) && load_profiles !== prevProps.load_profiles) {
            let is_loaded = false;
            if(tariff_type !== 'Bundle') is_loaded = true;
            else {
                //If its bundle tariff, check if RLM profiles got loaded, if yes then the page is loaded
                if(validateVar(load_profiles.RLM))  is_loaded = true;
                else this.props.getLoadProfiles(tariff.medium_type, 'RLM', true);
            }
            this.setState({load_profiles: load_profiles, is_loaded: is_loaded})
        }
    }

    handle_retrun = () => {
        const {tariff} = this.state;
        this.props.redirect(TariffDocs + '/'+ this.id + '/' + tariff.tree_id);
    };

    cancel_form_changes = () => {
        let tariff = this.props.tariff;
        this.setState({
            tariff: {...tariff},
        });
    };

    handle_set_end_date_on_start_date_change(start_date) {
        //When start date changes, adjust the end Date automatically on +1 year -1 day
        let endDate = new Date(start_date);
        endDate.setMonth(endDate.getMonth() + 12);
        endDate.setDate(endDate.getDate() - 1);

        //If maxEndDate is before start_date + 1 year, set end date to maxEndDate
        if (endDate > maxDateEnd) {
            this.update_state('offer_end', maxDateEnd);
            return start_date;
        } else {
            this.update_state('offer_end', endDate);
            return start_date;
        }
    }

    handle_return = () => {
        this.props.redirect(TariffDocs + '/' + this.id);
    };

    get_consumption_point_values(index, for_validation = false, for_api = false){
        const {consumption_points} = this.state;

        if(is_true(for_validation)){
            return {
                predicted_consumption: consumption_points[index].predicted_consumption,
                city: consumption_points[index].address.city,
                street: consumption_points[index].address.street,
                house_number: consumption_points[index].address.house_number,
                postal_code: consumption_points[index].address.postal_code,
            }
        }

        if(is_true(for_api)){
            return {
                predicted_consumption: consumption_points[index].predicted_consumption,
                city: consumption_points[index].address.city,
                street: consumption_points[index].address.street,
                house_number: consumption_points[index].address.house_number,
                postal_code: consumption_points[index].address.postal_code,
                state: consumption_points[index].address.state,
                business_type: consumption_points[index].last_profile,
                consumption_type: consumption_points[index].last_type,
                characteristics: "03",
            }
        }
    }

    start_calculation = () => {
        let {tariff, tariff_type, offer_begin, offer_end} = this.state;

        let repeatable_data = [this.get_consumption_point_values(0, true)];

        if(tariff_type === 'Bundle'){
            repeatable_data.push(this.get_consumption_point_values(1, true));
        }

        let special_cases = {
            predicted_consumption: {case_name: 'mean_consumption'}
        };
        // call abstract error validation and set values in state
        let validation_result = new Validation(null, special_cases, repeatable_data);
        this.setState({
            error_messages: validation_result.error_messages,
        });
        // validation end

        //Change the date format to be able to save it into the database correctly
        if (!validation_result.has_error) {
            let submitted_params = {
                offer_begin: handleFormatDate(offer_begin),
                offer_end: handleFormatDate(offer_end),
                tariff_id: this.id,
                medium_type: tariff.medium_type,
                consumption_points: [this.get_consumption_point_values(0,false, true)]
            };
            if(tariff_type === 'Bundle'){
                submitted_params.consumption_points.push(this.get_consumption_point_values(1,false, true));
            }
            this.setState({is_calculating: true, input_disabled: true});
            this.props.onSubmit(submitted_params);
        }
    };

    activate_tariff = () => {
        let data = {
            supplier_id: supplier_id,
            tariff_id: this.id,
            lifecycle_status: 1
        };
        this.setState({submit_loading: true});
        this.props.onUpdateTariff(data)
    };

    update_state = (state_name, value, index = null) => {
        let {consumption_points} = this.state;

        if (state_name === 'predicted_consumption') value = value.replace(/\./g, '');
        if (state_name === 'offer_begin') value = this.handle_set_end_date_on_start_date_change(value);

        //update constumption_point OR delivery date
        if(validateVar(index)) {
            consumption_points[index][state_name] = value;
            this.setState({consumption_points: consumption_points});
        } else this.setState({[state_name]: value});
    };

    render() {
        const {error_messages, offer_begin, offer_end, disable_activate_tariff, is_calculating, submit_value, is_loaded,
            tariff, input_disabled, submit_loading, tariff_type, consumption_points, calculated_tariffs} = this.state;

        let predicted_consumption = 0;
        for(let csp of consumption_points){
            if(validateVar(csp.predicted_consumption)) predicted_consumption = predicted_consumption + +csp.predicted_consumption;
        }

        let duration_in_months = monthDiff(new Date(offer_begin), new Date(offer_end));
        return (<>
            <SubmitLoadingAnimationModal id={'please-wait'} display={is_calculating} />
            <AddTariffHeader>
                <div className='table-centered'>
                    {validateVar(tariff.name) ? <h2 className='light mb-0 d-inline-block mr-3'>Tarif bearbeiten - {tariff.name}</h2> : <span className='pr-2'><Skeleton width={320} height={30}/></span>}
                    {validateVar(tariff.lifecycle_status) ? <Badge value={tariff.lifecycle_status} color={tariff.status_color} /> : <Skeleton width={160} height={30}/>}
                </div>
            </AddTariffHeader>
            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody className='py-1'>
                                <MaterialUIStepper onRedirect={(url) => this.props.redirect(url)} active={3}
                                                   tariffId={this.id} treeId={tariff.tree_id}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h3 className='mb-0'>Daten für Testkalkulation</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <InputHandler
                                            content_title="Lieferbeginn"
                                            type="date"
                                            id='offer_begin'
                                            name='offer_begin'
                                            date_type='start'
                                            value={offer_begin}
                                            disabled={input_disabled}
                                            on_change={(name, date) => this.update_state(name, date)}
                                            error_state={error_messages.offer_begin_error}
                                            show_month_dropdown={true}
                                            show_year_dropdown={true}
                                            dropdown_mode='select'
                                            classes='form-control w-100'
                                            min_date={minDateStart}
                                            max_date={maxDateStart}
                                            is_loaded={is_loaded}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputHandler
                                            content_title="Lieferende"
                                            type="date"
                                            id='offer_end'
                                            name='offer_end'
                                            date_type='start'
                                            value={offer_end}
                                            disabled={input_disabled}
                                            on_change={(name, date) => this.update_state(name, date)}
                                            error_state={error_messages.offer_end_error}
                                            show_month_dropdown={true}
                                            show_year_dropdown={true}
                                            dropdown_mode='select'
                                            classes='form-control w-100'
                                            min_date={minDateEnd}
                                            max_date={maxDateEnd}
                                            is_loaded={is_loaded}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <ConsumptionPointForm {...this.state} update_state={this.update_state} index={0} />
                        {(tariff_type === 'Bundle') && <ConsumptionPointForm {...this.state} update_state={this.update_state} index={1} />}

                        <Row>
                            <Col md={12}>
                                <SecondaryButton onClick={(e) => this.start_calculation(e)}
                                                 disabled={(is_calculating)} value='Kalkulieren'
                                                 classes='w-100 form-field-height'/>
                            </Col>
                        </Row>

                        <div className='mt-5 mb-3'>
                            {(validateVar(calculated_tariffs) && calculated_tariffs !== "No result") && <><TariffCalculatedContainer
                                tariff={calculated_tariffs}
                                price_type={calculated_tariffs.price_type}
                                offer={{offer_begin: offer_begin, offer_end: offer_end, offer_result_id: null}}
                                handleDownload={() => console.log("no download")}
                                consumption={predicted_consumption+''}
                                bundle_calculation={false}
                                netFeeData={null}
                                testCalculation
                                hideSubmit
                                duration={duration_in_months}
                                medium_type={tariff.medium_type}
                            />
                            <span id='tooltipWrapper'>
                                <PrimaryButton id='primary_button'
                                       onClick={this.activate_tariff}
                                       disabled={disable_activate_tariff || !is_loaded || submit_loading}
                                       loading={submit_loading}
                                       value={submit_value}
                                       classes={'full-page-submit'} />
                            </span>
                            </>}
                            {calculated_tariffs === "No result"  && <NoTariffsFound />}
                        </div>
                        <GreyLinkButton text='Zurück' classes='mt-4 mb-3 pb-2 eless-font-bold' onClick={this.handle_retrun} />
                    </div>
                </Row>
            </Container>
        </>);
    }
}

let mapStateToProps = function (state) {
    return {
        tariff: state.tariffs.tariff,
        load_profiles: state.load_profiles,
        calculated_tariffs: state.calculated_tariffs,
        company: state.company
    }
};

let mapDispatchToProps = {
    onInitialize: initializeTariff,
    getLoadProfiles: getLoadProfiles,
    onSubmit: testCalculation,
    onUpdateTariff: updateTariff,
    onResetRedirect: resetRedirect,
    redirect: redirect,
};

let TariffStatusContainer = connect(mapStateToProps, mapDispatchToProps)(TariffHandleStatus);

export default TariffStatusContainer;
