import validateVar from "../Functions/validation/validateVariable";
import isEmptyObject from "../Functions/isEmptyObject";
import handleFormatPrice from "../Functions/handleFormatPrice";
import regexCheck from "../Functions/validation/regexCheck";
import handleFormatNumber from "../Functions/handleFormatNumber";
import {numeric_regex} from "../Functions/global";
import handleFormatDateForFrontend from "../Functions/handleFormatDateForFrontend";
import get_standardised_date from "../Functions/get_standardised_date";

class TreeConditions {
    constructor(condition) {
        this.base_price = "";
        this.price = "";
        this.type = "";
        this.price_display = "";
        this.is_base_price = false;
        this.comparison_operator = "";
        this.comparison_value_1 = "";
        this.comparison_value_1_display = "";
        this.comparison_value_2 = ""; // used when "and" operator
        this.comparison_value_2_display = ""; // used when "and" operator
        this.text_form = ""; // used when "and" operator
        if (validateVar(condition) && !isEmptyObject(condition)) {
            this.base_price = condition.base_price;
            this.base_price_display = (validateVar(condition.base_price)) ? handleFormatPrice(condition.base_price, "2") : "0";
            this.price = condition.kwh_rate;
            this.type = condition.type;
            this.text_form = validateVar(condition.text_form) ? condition.text_form : true;
            this.is_base_price = (validateVar(condition.is_base_price) && +condition.is_base_price === 1);
            this.price_display = (validateVar(condition.kwh_rate)) ? handleFormatPrice(condition.kwh_rate, 3) : "";
            let constraints = condition.constraints;
            this.comparison_operator = validateVar(constraints.comp_op) ? constraints.comp_op : validateVar(constraints.logic_op) ? constraints.logic_op : ""; //logic_op
            if (constraints.hasOwnProperty("cond_list")) { // and operator
                let condition_list = constraints.cond_list;
                let comparison_value_1_OR_OP_value = [];
                for (let index in condition_list) {
                    if (condition_list.hasOwnProperty(index)) {
                        let value_tmp = condition_list[index];
                        if (this.comparison_operator === "OR") { // special case for multiselect fields (all values must be in one array for the branch to be displayed correct)
                            if (value_tmp.hasOwnProperty("expr_b")) {
                                let value = (validateVar(value_tmp.expr_b.VALUE)) ? value_tmp.expr_b.VALUE : null;
                                if (validateVar(value)) comparison_value_1_OR_OP_value.push(value);
                            }
                        } else { // all types except for multiselect
                            if (value_tmp.hasOwnProperty("expr_b")) {
                                let name_idx = +index + 1;
                                let class_value_name = "comparison_value_" + name_idx;
                                let class_value_name_display = class_value_name + "_display";
                                if(this.type === 'date') {
                                    this[class_value_name] = (validateVar(value_tmp.expr_b.VALUE)) ? new Date(get_standardised_date(value_tmp.expr_b.VALUE)).getTime() : "";
                                    this[class_value_name_display] = handleFormatDateForFrontend(this[class_value_name]);
                                }else{
                                    this[class_value_name] = (validateVar(value_tmp.expr_b.VALUE)) ? value_tmp.expr_b.VALUE : "";
                                    this[class_value_name_display] = this.handle_format_input(this[class_value_name]);
                                }
                            }
                        }
                    }
                }
                if (this.comparison_operator === "OR") {
                    this.comparison_value_1 = comparison_value_1_OR_OP_value;
                    this.comparison_value_1_display = comparison_value_1_OR_OP_value;
                    this.comparison_value_2 = [];
                    this.comparison_value_2_display = [];
                }
            }
            else if (constraints.hasOwnProperty("expr_b")) { // any other operator
                this.comparison_value_1 = validateVar(constraints.expr_b.VALUE) ? constraints.expr_b.VALUE : "";
                this.comparison_value_1_display = this.handle_format_input(this.comparison_value_1);

                if(this.type === 'date') {
                    if(validateVar(this.comparison_value_1)) {
                        this.comparison_value_1 = new Date(get_standardised_date(this.comparison_value_1)).getTime();
                        this.comparison_value_1_display = handleFormatDateForFrontend(this.comparison_value_1);
                    }
                }
            }
        }
    }

    handle_format_input(value) {
        if (!validateVar(value)) return "";
        let return_val = "";
        if (this.type === "int") {
            return_val = handleFormatNumber(value);
        } else if (this.type.includes("float")) {
            let splitted = this.type.split(":");
            let decimals = validateVar(splitted[1]) ? splitted[1] : "2";
            let tmp_val = value.replace(".", "");
            if (regexCheck(tmp_val, numeric_regex)) {  //check if value is a number value
                return_val = handleFormatPrice(value, decimals, "");
            } else return_val = value;
        } else if (this.type === "multiselect") {
            return value;
        }else if (this.type === "string") {
            return_val = value;
        }
        return return_val;
    }


}

export default TreeConditions;
