import validateVar from "./validation/validateVariable";
import copy_with_no_reference from "./copy_with_no_reference";

function get_standardised_date(date) {
    if(validateVar(date)){
        var tmp_date = copy_with_no_reference(date);
        if (tmp_date.includes(" ") && !tmp_date.includes("T"))
            return tmp_date.replace(" ", "T");
        else return date;
    }else return date;
}

export default get_standardised_date;