import React from 'react';
import paginationFactory, {PaginationListStandalone, PaginationProvider} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {Col, Row} from "reactstrap";
import SizePerPageSelector from "./Components/SizePerPageSelector";
import BootstrapTable from "react-bootstrap-table-next";
import NoTableDataIndication from "../../PageContent/NoTableDataIndication";
import VisibleEntriesCount from "./Components/VisibleEntriesCount";
import TableCheckbox from "../../Inputs/Checkbox/TableCheckbox";
import PropTypes from 'prop-types';
import TableLoadingAnimation from "../../LoadingAnimations/TableLoadingAnimation";
import TableInputModal from "../../Modals/TableInputModal";
import validateVar from "../../Functions/validation/validateVariable";
import LoadingAnimationDotted from "../../LoadingAnimations/LoadingAnimationDotted";
import is_true from "../../Functions/is_true";
import SettingsWheelBtn from "../Buttons/SettingsWheelBtn";

const { SearchBar } = Search;

const default_title = "Übersicht";

class Table extends React.Component {
    table_input_modal = React.createRef();
    state = {
        force_reload: false,
        is_loaded: this.props.is_loaded,
        search_text_state: '',
        data_length: 0,
        expanded: []
    };

    componentDidUpdate(prevProps) {
        let {is_loaded} = this.props;
        if (validateVar(is_loaded) && is_loaded !== prevProps.is_loaded) {
            if (is_loaded === "reload") {
                this.setState({
                    force_reload: true
                });
                this.props.set_is_loaded("true");
            }  else  this.setState({is_loaded: is_loaded})
        }
    }

    update_colums_function = (new_table_value) =>  {
        const {update_columns} = this.props; //table
        this.setState({
            force_reload: true,
            data: [{}]
        });
        update_columns(new_table_value);
    };

    force_refresh = () => {
        this.setState({
            force_reload: false,
        });

    };

    rowSelected = (data, isSelected) => { //3 parameter could be "rowIndex"
        const {key_field} = this.props;
        var id = data[key_field];
        let selected_ids = this.props.selected_ids;
        if (isSelected === true){
            selected_ids.push(id)
        }else{
            let index = selected_ids.indexOf(id);
            selected_ids.splice(index, 1);
        }
        this.props.on_checkbox_click(selected_ids);
    };

    allRowsSelected = (isSelect, rows, e) => {
        const {key_field} = this.props;
        let {selected_ids} = this.props;

        if (isSelect === true){
            var i;
            for (i = 0; i < rows.length; i++) {
                selected_ids.push(rows[i][key_field])
            }
            this.props.on_checkbox_click(selected_ids);
        }else this.props.on_checkbox_click([]);
    };

    //here we save the search_text to state everytime it changed to be able to compare if it changed.
    //when change has happend, we need to be redirected to page 1 of the table or else the page will crash
    switch_to_first_page_conditions(pagination_props, table_props, data){
        const {search_text_state, data_length} = this.state;

        if(data_length !== data.length) {//in case a row is deleted
            this.setState({data_length: data.length});
            pagination_props.page = 1;
        }

        if(table_props.searchProps.searchText !== search_text_state) {//in case search field is triggered
            this.setState({search_text_state: table_props.searchProps.searchText});
            pagination_props.page = 1;
        }
    }

    collapse = (e, row) => {
        let {key_field} = this.props;
        let {expanded} = this.state;
        let index = expanded.indexOf(row[key_field]);
        if(index > -1) expanded = expanded.filter(x => x !== row[key_field]);
        else expanded.push(row[key_field]);
        this.setState({expanded: expanded});
    };

    render() {
        const {title, no_title_row, loading_title,  data,  id, columns, key_field, selected_ids, empty_table_message,
            break_width, data_arr_name, paginator_strings, custom_col_click, custom_click_class, table_name,
            default_col_click, linked_col_click, on_checkbox_click, expanded_row, non_select_able_ids} = this.props;
        const {force_reload, is_loaded, expanded} = this.state;
        if (is_true(force_reload)) this.force_refresh();
        let data_local = [];
        if(is_loaded){ //get data from object if offset_loading is enabled, if not just take the array with data
            if(validateVar(data) && !validateVar(data_arr_name)) data_local = data;
            if(validateVar(data) && validateVar(data_arr_name)) data_local = data[data_arr_name];
        }
        let options; //set options for pagination
        if (is_loaded) options = {custom: true};
        let selectRowProp = undefined;
        if(validateVar(on_checkbox_click)) selectRowProp = {
            mode: 'checkbox',
            bgColor: '#f8f9fe',
            selected: selected_ids,
            nonSelectable: non_select_able_ids,
            onSelect: this.rowSelected,
            onSelectAll: this.allRowsSelected,
            selectionRenderer: ({ mode, checked, disabled }) => (<TableCheckbox checked={checked} disabled={disabled} />),
            selectionHeaderRenderer: ({ mode, checked, disabled }) => (<TableCheckbox checked={checked} disabled={disabled} />)
        };

        let rowEvents = undefined;
        rowEvents = {
            onClick: (e, row, rowIndex) => {
                if(validateVar(linked_col_click) && e.target.classList.contains('linked-col')) {
                    linked_col_click(row);
                }
                else if(validateVar(custom_click_class) && e.target.classList.contains(custom_click_class)) {
                    custom_col_click(row);
                }
                else if(validateVar(expanded_row) && e.target.classList.contains('collapse-col')) {
                    this.collapse(e, row, rowIndex);
                }
                else if (validateVar(default_col_click)) {
                    default_col_click(row);
                }
            }
        };

        let expandRow = undefined; // must be set to undefined when expanded_row is not given
        if(validateVar(expanded_row)){
            expandRow = {
                renderer: (row) => {
                    return expanded_row(row);
                },
                expanded: expanded,
                //example of custom column indicator
                // showExpandColumn: true https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Expand&selectedStory=Custom%20Expand%20Indicator&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
            };
        }

        return <>
            {table_name && <TableInputModal table_input_options={columns}
                              change_table_input={(new_table_value) => this.update_colums_function(new_table_value)}
                              table_name={table_name}
                              ref={this.table_input_modal}
                              key_field={key_field}
            />}
            {is_loaded && !force_reload ? <PaginationProvider
            pagination={paginationFactory(options)}
        >
            {
                ({
                     paginationProps,
                     paginationTableProps
                 }) => (<ToolkitProvider
                    data={data_local}
                    keyField={key_field}
                    columns={columns}
                    search>
                    {props => (
                        <div className="py-4 custom-table">
                            {this.switch_to_first_page_conditions(paginationProps, props, data_local)}
                            {!no_title_row && <Row className='custom-border-bottom mx-0 mb-3'>
                                {validateVar(data) ? <Col className='pb-2' sm={12}>
                                    <h2>{!validateVar(data.last_request) || is_true(data.last_request) ? title : <><LoadingAnimationDotted
                                        loading_spinner={true} deplay={400}
                                        text={loading_title + " werden geladen (" + data_local.length + "/" + data.total_count + ")"}/> </>}</h2>
                                </Col> : <Col className='pb-2' sm={12}><h2>{title}</h2></Col>}
                            </Row>}
                            <Row className='mb-1 pb-2 px-3'>
                                <Col className='mb-3 mb-lg-0' sm={12} md={12} lg={2}>
                                    <SizePerPageSelector {...paginationProps} dataName={paginator_strings[0]}/>
                                </Col>
                                <Col sm={12} md={12} lg={10} className='text-right'>
                                    <span className={'table-header-buttons break-width-'+break_width}>
                                        {table_name && <SettingsWheelBtn classes='absolute-positioning mr-lg-3' on_click={() => this.table_input_modal.current.toggleModal()} />}
                                        {this.props.children}
                                        <span className='responsive-button-wrapper'>
                                            <div id="datatable-basic_filter" className="dataTables_filter">
                                                <label>
                                                    <span className='search-label'>Suche:</span>
                                                    <SearchBar
                                                        className="form-control-sm"
                                                        placeholder="Suche"
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                            </div>
                                        </span>
                                    </span>
                                </Col>
                            </Row>
                            <BootstrapTable {...props.baseProps}
                                bootstrap4={true}
                                selectRow={selectRowProp}
                                bordered={false}
                                rowEvents={rowEvents}
                                rowClasses={'custom-table-row'}
                                deleteRow={ true }
                                responsive={'md'}
                                id={id}
                                wrapperClasses="table-responsive"
                                noDataIndication={ () => <NoTableDataIndication text={empty_table_message} /> }
                                {...paginationTableProps}
                                expandRow={ expandRow } />
                            <Row className='px-3'>
                                <Col>
                                    <VisibleEntriesCount {...paginationProps} dataName={paginator_strings[1]}/>
                                </Col>
                                <Col>
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </ToolkitProvider>)}
        </PaginationProvider> : <TableLoadingAnimation />}</>
    }
}

Table.defaultProps = {
    title: 'Übersicht',
    loading_title: 'Daten',
    empty_table_message: 'Es gibt noch keine Daten',
    key_field: 'id',
    break_width: '600', //max width of the window where buttons start being displayed at width 100%
    paginator_strings: ['Datensätze', 'Datensätzen'],
};

Table.propTypes = {
    title: PropTypes.string,
    key_field: PropTypes.string,
    table_name: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.object,
    ),
    is_loaded: PropTypes.bool,
    columns: PropTypes.arrayOf(),
    enable_row_select: PropTypes.bool,
    enable_search: PropTypes.bool,
    selected_ids: PropTypes.arrayOf(PropTypes.number),
    empty_table_message: PropTypes.string,
    on_checkbox_click: PropTypes.func,
    linked_col_click: PropTypes.func,
    paginator_strings: PropTypes.arrayOf(PropTypes.string),
    data_arr_name: PropTypes.string,
    break_width: PropTypes.string,
    no_title_row: PropTypes.bool,
};

export default Table;