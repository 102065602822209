import React from "react";
import Button from "reactstrap/lib/Button";
import validateVar from "../../Functions/validation/validateVariable";

class GreyButton extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            alert: null,
        };
    }

    render(){
        let {classes} = this.props;
        if(validateVar(classes)) classes = ' '+classes;
        else classes = '';
        return(
            <Button disabled={this.props.disabled} onClick={this.props.on_click} className={'eless-btn grey-btn' + classes}>
                {this.props.value}
            </Button>
        )}
}

export default GreyButton;
