import validateVar from "./validation/validateVariable";
import is_true from "./is_true";

function checkDisplayRequirements(object, property, is_frontend = false){
    let fields = (is_true(is_frontend) && validateVar(property.display_requirements_frontend)) ? property.display_requirements_frontend.field : null;
    let values = (is_true(is_frontend) && validateVar(property.display_requirements_frontend)) ? property.display_requirements_frontend.value : null;
    if (!is_frontend && validateVar(property.display_requirements))  {
        fields = property.display_requirements.field;
        values = property.display_requirements.value;
    }
    if(!validateVar(fields) || !validateVar(values)) return true;
    let field_is_array = Array.isArray(fields);
    let value_is_array = Array.isArray(values);

    if(field_is_array && value_is_array){
        if(fields.length === 1 && values.length > 1){//check if the field has one of the given values
            let current_object_value = object[fields[0]];// current value from object
            for(let index in values) {
                if(values.hasOwnProperty(index)){
                    if(current_object_value === values[index]) return true;
                }
            }
            return false;
        }else{
            for(let index in fields) { //loop the field names if the array to check if it should be displayed
                if(fields.hasOwnProperty(index) && values.hasOwnProperty(index)){ //index must be in both arrays (if array1 has 2 entrances -> array2 must have 2 as well)
                    let field_name = fields[index]; // field name from object to get current value
                    let needed_value_for_display = values[index]; // needed value for display
                    let current_object_value = object[field_name]; // current value from object
                    if (!validateVar(current_object_value)) { // if field is included in api object
                        current_object_value = property.default_value;
                    }
                    if(Array.isArray(needed_value_for_display) && !needed_value_for_display.includes(current_object_value)) {
                        return false;// if value is not included in value -> value will not be displayed
                    }
                    else if(!Array.isArray(needed_value_for_display) && current_object_value !== needed_value_for_display) {
                        return false; // if value is not matching return false -> value will not be displayed
                    }
                }
            }
        }
    }else alert ('display_requirements value and field has to be an array');

    return true;
}

export default checkDisplayRequirements;