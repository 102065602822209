import validateVar from "./validation/validateVariable";
import React from "react";
import BadgeDot from "../Displays/Badges/BadgeDot";
import Badge from "../Displays/Badges/Badge";
import TooltipComponent from "../Displays/Basic/Tooltip";
import callFunctionWithString from "./callFunctionWithString";

export function create_table_content_from_options(input_options) {
    let table_content = [];
    for (let index in input_options) {
        if (input_options.hasOwnProperty(index)) {
            let option = input_options[index];
            let table_content_obj = {};
            if (validateVar(option.type)) table_content_obj.type = option.type;
            if (validateVar(option.value)) table_content_obj.dataField = option.value;
            if (validateVar(option.label)) table_content_obj.text = option.label;
            if (validateVar(option.sort)) table_content_obj.sort = option.sort;
            if (validateVar(option.classes)) table_content_obj.classes = option.classes;
            if (validateVar(option.style)) table_content_obj.style = option.style;
            if (validateVar(option.formatter)) table_content_obj.formatter = option.formatter;

            if (validateVar(option.component)) {
                switch (option.component) {
                    case "badge":
                        table_content_obj.formatter = (cell, object) => <Badge value={cell} color={object[option.badge_color]}/>;
                        break;
                    case "badge_dot":
                        table_content_obj.formatter = (cell, object) => <BadgeDot type='badge_dot' value={cell} color={object[option.badge_color]}/>;
                        break;
                    case "tooltip":
                        table_content_obj.formatter = (cell, object) => {
                           return <TooltipComponent tooltip_data={{id: 'tooltip_' + object.id, text: cell}}/>
                        };
                        break;
                    default:
                        console.log("COMPONENTE WURDE NICHT GEFUNDEN");
                        return "N/A";
                }
            }

            if(validateVar(option.formatter_func)) {
                table_content_obj.formatter = (cell, object) => callFunctionWithString(option.formatter_func, [cell]);
            }

            table_content.push(table_content_obj);
        }
    }
    return table_content;
}

export default create_table_content_from_options;
