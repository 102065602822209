import { apiError } from "../Functions/global";
import validateVar from "../Functions/validation/validateVariable";
import History from "./Components/history";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";

let contract_vars = {
  id: null,
  clearing_type: {
    clearing_type: null,
    clearing_type_display: {
       function_name: "getClearingTypeDisplay"
    }
  },
  is_individual: {
      is_individual: null,
      is_individual_display: {
          function_name: "getYesOrNoDisplay"
      }
  },
  description: null,
  first_name: null,
  last_name: null,
  company: {
    customer_company: {
      function_name: "get_customer_company_name",
      params: ["%%company", "%%first_name", "%%last_name"]
    },
    company: {
      default_value: "Keine Firma hinterlegt"
    },
  },
  created_at: {
    created_at: null,
    created_at_display: {
        function_name: "handleFormatDateForFrontend"
    },
    created_at_timestamp: {
        function_name: "getTimestamp"
    }
  },
  protocol_id: null,
  team_id: null,
  creator_name: null,
  employee_name: null,
  reference_id: null,
  reference_type: {
    reference_type: null,
    reference_type_display: {
      function_name: "get_reference_type_display"
      }
  },
  subject: null,
  employee_id: null,
  employee: {
    is_class_arr: "employee"
  },
  process_until: {
    process_until: null,
    process_until_display: {
      function_name: "handleFormatDateForFrontend", // todo check if valid
    },
    process_until_day: {
      function_name: "getDayDiff"
    }
  },
  updated_at: {
      updated_at: null,
      updated_at_display: {
          function_name: "handleFormatDateForFrontend"
      }
  },
  status: {
    time_diff: {
      function_name: "getTimeDiff",
      params: ["%%process_until"]
    },
    status: {
      function_name: "getClearingStatus",
      params: ['%%time_diff']
    },
  },
  status_display: {
    function_name: "getClearingTranslatedStatus",
    params: ['%%status']
  },
  status_color: {
    function_name: "getBadgeColor",
    params: ['%%status', ["closed"], ["on_hold", "processing"], ["overdue", "terminated"], ["open"]]
  },
};

class Clearing {
  static default_values = contract_vars;
  constructor(data) {
    if (validateVar(data)) {
      this.history = {};
      // this.get_contract(contract_vars, data, data.history, []);
      let class_content = new History(contract_vars, data, data.history, []);
      for (let name in class_content) {
        if (class_content.hasOwnProperty(name)) {
          this[name] = class_content[name];
        }
      }
    }
  }

  static async start_request(communication) {
    return await communication.request_handler();
  }

  static async get_clearing(params) {
    let function_name = "clearing";
    if (Array.isArray(params)) {
      if (validateVar(params.task_id))  function_name = function_name + "/" + params.task_id;
    }

    // let res = await this.start_request(new Communication(
    //     "GET", // GET / POST / PUT / DELETE  -> method
    //     function_name,
    //     params
    // ));

    let communication_params = {
        method: 'GET', // GET / POST / PUT / DELETE  -> method
        function_url:  function_name,
        params: params
    };
    let c = getCommunicationClass(communication_params);
    let res = await c.request_handler();

    if (res !== apiError) {
      if (params.is_list) {
        if (res.elements.length === 0) {
          return res;
        }
        let list_arr = [];
        let elements = res;
        if(validateVar(res.elements)) elements = res.elements;
        for (let index in elements) {
          if (elements.hasOwnProperty(index)) {
            list_arr.push(new Clearing(elements[index]))
          }
        }
        res.elements = list_arr;
        return res;
      } else if(validateVar(res.elements)) return res.elements;
      else return new Clearing(res);
    } else return apiError;

  }

  static async handle_clearing(params = {}, methode = "GET") {
    let function_url = "clearing";
    if (methode === "PUT" || methode === "DELETE") {
      function_url = function_url + "/" + params.id;
    }
    params.options = { all_tickets: 1 };
    params = getAgencyID(params, true);

      let communication_params = {
          method: methode, // GET / POST / PUT / DELETE  -> method
          function_url:  function_url,
          params: params
      };
      let c = getCommunicationClass(communication_params);
      let result = await c.request_handler();

    if (result !== apiError) {
      let elements = result.elements;
      if (validateVar(params.is_list)) {
        let result_list = [];

        for (let index in elements) {
          if (elements.hasOwnProperty(index)) {
            result_list.push(new Clearing(elements[index]));
          }
        }
        return {
          clearing: result_list,
          total_count: 0,
          next_offset: null
        };
      } else {
        return {
          clearing: new Clearing(elements),
          total_count: result.total_count,
          next_offset: result.next_offset
        };
      }
    } else return apiError;
  }

  static async handle_assignments(props, params = {}){//taks assignment handler for wattline
    let modified_ids = [];
    let error_count = 0;
    let result = null;
    let function_url = 'clearing';

    if(props.type === 'create'){
      let communication_params = {
        method: 'POST', // GET / POST / PUT / DELETE  -> method
        function_url:  function_url,
        params: params
      };
      let c = getCommunicationClass(communication_params);
      result = await c.request_handler();
      if(result !== apiError){
        return new Clearing(result);
      }else return apiError;
    }

    for(let id of props.ids){
      let function_url = 'clearing';
      if(validateVar(id)) {  //id is undefined when its "create" function and options are not needed there neither
        if(!validateVar(params.options)) params.options = {};
        function_url = function_url + '/' + id
      }

      if(props.type === 'assign'){
          params.options.modify_employee = 1;
      } else if(props.type === 'unassign') {
          params.options.clear_employee = 1;
      } else if (props.type === 'activate') {
          params.status = "open";
          params.options.all_tickets = 1;
          params.options.clear_employee = 1;
      } else if (props.type === 'deactivate') {
          params.status = "closed";
          params.options.all_tickets = 1;
      } else if (props.type === "edit") {
        if (validateVar(params.assigned_employee_id)) params.options.modify_employee = 1;
      }
      params = getAgencyID(params, true);
      let communication_params = {
        method: validateVar(props.method) ? props.method : 'PUT', // GET / POST / PUT / DELETE  -> method
        function_url:  function_url,
        params: params
      };
      let c = getCommunicationClass(communication_params);
      result = await c.request_handler();
      if(result !== apiError){
        modified_ids.push(id);
      }else error_count = error_count + 1;
    }

    if (props.type === "edit") { // need to return the new data to replace old data with this id in list
      return new Clearing(result);
    }

    if(modified_ids.length === 0) return apiError;
    else return {
      modified_ids: modified_ids,
      error_count:  error_count
    };
  }

  static dummy() {
    let output = {};
    output = this.build_dummy(contract_vars);
    return output;
  }

  static build_dummy(vars) {
    let output = {};
    for (let index in vars) {
      if (vars.hasOwnProperty(index)) {
        if (!validateVar(vars[index])) {
          output[index] = "-/-";
        } else if (typeof vars[index] === "object") {
          if (validateVar(vars[index].is_sub_arr)) {
            output[index] = this.build_dummy(vars[index]);
          } else {
            output[index] = "-/-";
          }
        }
      }
    }
    return output;
  }
}

export default Clearing;
