function validateDate(date_value, regex) {
  let germanDateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g; // example: 02/03/2020
  let formatedDateRegex = /^\s*((?:19|20)\d{2})-(1[012]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])\s*$/g; // example: 2000-03-02

  //.test function changes the string variable to something invalid so creating a copy of the string for the first .test function

  if (germanDateRegex.test(date_value)) {
    return true;
  } else if (Object.prototype.toString.call(date_value) === "[object Date]") {
    // is it a date
    if (isNaN(date_value.getTime())) {
      // d.valueOf() could also work
      return false; // date is not valid
    } else {
      return true; // date is valid
    }
  } else {
    return formatedDateRegex.test(date_value);
  }
}
export default validateDate;
