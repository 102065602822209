import React, {Component} from 'react';
import validateVar from "../../../Functions/validation/validateVariable";
import {Col, Row, Tooltip} from "reactstrap";
import DropdownButton from "../../../Displays/Buttons/DropdownButton";
import PrimaryButton from "../../../Displays/Buttons/PrimaryButton";
import {description_display_length} from "../../../Functions/global";
import is_false from "../../../Functions/is_false";
import is_true from "../../../Functions/is_true";
import cn from 'classnames';

class GroupHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tool_tip_is_open: false,
        }
    };

    componentDidUpdate(prevProps) {
        let {attribute, condition} = this.props;
        if (validateVar(attribute) && attribute !== prevProps.attribute) {
            this.handle_condition_update(attribute, condition);
        }
    }

    toggleTooltipDescription = () => {
        let {tool_tip_is_open} = this.state;
        this.setState({
            tool_tip_is_open: !tool_tip_is_open
        })
    };

    get_global_tree_options_value = (is_global_edit_able, is_original_global_tree, is_global) => {
        let return_array = [];
        if (is_global) {
            if (is_true(is_global_edit_able)) return_array.push(...["disable_edit_global_tree"]);
            else  return_array.push(...["edit_global_tree"]);
            if (is_false(is_original_global_tree))  return_array.push(...["reset_global_tree"]);
        }
        return return_array;
    };


    render() {
        const {group, dropdown_options_group, disable_all_dropdown_group_btn, index, dropdown_btn_sort_value, classes, is_global, is_global_edit_able, is_original_global_tree, is_global_page} = this.props;
        let global_option_btn_values = this.get_global_tree_options_value(is_global_edit_able, is_original_global_tree, is_global);
        let {tool_tip_is_open} = this.state;
        let dropdown_btn_sort_disabled = (dropdown_btn_sort_value.length === 0);
        let description_short_display = (validateVar(group.description_short)) ? " - " + group.description_short : "";
        let name_display = (validateVar(group.name)) ? group.name : "Gruppe " + (+index +1);
        name_display = (is_true(is_global)) ? "" : name_display;
        let tool_tip_id = "wrapper" + group.id + "";
        return <div id={"group_header_" + group.id} className="ExpansionPanel">
            <div className={'Header bg_color_class GroupHeader ' + classes}>
                <Row>
                    <Col className={cn({'sm-margin-bottom': is_false(is_global)})} sm={12} md={4} lg={5}>
                        {/*{name_display}{description_short_display}*/}
                        {/*{(validateVar(group.description) && +group.description.length > +description_display_length) &&*/}
                        {/*<span className='pricestrategy-info-icon-wrapper position-relative'>*/}
                        {/*    <i id={tool_tip_id} className="fas fa-info-circle"/>*/}
                        {/*</span>}*/}
                    </Col>
                    <Col className='buttons' sm={12} md={8} lg={7}>
                        {/*{(is_false(is_global)) && <div className='mb-2'><DropdownButton*/}
                        {/*    tooltipProp={"Option"}*/}
                        {/*    disabled={dropdown_btn_sort_disabled}*/}
                        {/*    include={dropdown_btn_sort_value}*/}
                        {/*    clickMoveGroupIndexUpBtn={() => this.props.sort_tree_groups(index, "move_up")}*/}
                        {/*    clickMoveGroupIndexDownBtn={() => this.props.sort_tree_groups(index, "move_down")}*/}
                        {/*    classes={'table-header-options'}*/}
                        {/*    id={'orderButton'+index}*/}
                        {/*    button_name={'Anordnen'}*/}
                        {/*/></div>}*/}
                        {/*{(is_false(is_global)) && <div className='mb-2'><DropdownButton*/}
                        {/*    tooltipProp={"Was möchten Sie tuen?"}*/}
                        {/*    disabled={((disable_all_dropdown_group_btn))}*/}
                        {/*    include={dropdown_options_group}*/}
                        {/*    clickDeleteGroupBtn={() => this.props.toggle_warning_modal("group", group.id)}*/}
                        {/*    clickCopyGroupBtn={() => this.props.toggle_handle_groups_modal("copy", group)}*/}
                        {/*    clickRenameGroupBtn={() => this.props.toggle_handle_groups_modal("edit", group)}*/}
                        {/*    clickAddGroupBtn={() => this.props.toggle_handle_groups_modal("add")}*/}
                        {/*    classes={'table-header-options'}*/}
                        {/*    id={'groupButton'+index}*/}
                        {/*    button_name={'Gruppierung'}*/}
                        {/*/></div>}*/}
                        {(is_true(is_global) && is_false(is_global_page)) && <DropdownButton
                            tooltipProp={"Was möchten Sie tuen?"}
                            wrapper_classes='mr-3'
                            disabled={false}
                            include={global_option_btn_values}
                            clickResetGobalTree={() => this.props.toggle_warning_modal("reset_global_tree")}
                            clickEditGlobalTree={this.props.handle_global_tree_editable}
                            classes={'table-header-options'}
                            button_name={'Optionen'}
                        />}
                        <div><PrimaryButton disabled={((!is_global_edit_able && is_true(is_global)) && (is_false(this.props.is_global_page)))} onClick={() => this.props.toggle_all_attribute_list_modal(group, is_global)}
                                       classes='add-btn' value={<i className={'ni ni-fat-add'}/>}/></div>
                    </Col>
                </Row>
            </div>
            {(validateVar(group.description) && +group.description.length > +description_display_length) &&
            <Tooltip trigger='hover' placement='top' isOpen={tool_tip_is_open} target={tool_tip_id}
                     toggle={this.toggleTooltipDescription}>
                {group.description}
            </Tooltip>}
        </div>
    }
}

export default GroupHeader;