import {
    apiBearer,
    apiContentType, apiFunctionListAttributes,
    apiServicePricing,
    apiURL,
    apiUser,
    apiUserAuthToken} from "./global";


export default async function getListAttributes() {
  return await fetch(apiURL, {
    method: "POST",
    headers: {
      Authorization: apiBearer,
      "Content-Type": apiContentType
    },
    http_errors: false,
    body: JSON.stringify({
      user: apiUser,
      auth_token: apiUserAuthToken,
      service: apiServicePricing,
      function: apiFunctionListAttributes
    })
  })
    .then(response => {
      return response;
    })
    .catch(rejected => {
      console.log("Request failed", rejected);
    });
}
