/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import {Link} from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import {PropTypes} from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav
} from "reactstrap";

import getUserRole from "../../Functions/getUserRole";
import {all_urls, sidebar_sections} from "../../../globalNavigation";
import validateVar from "../../Functions/validation/validateVariable";
import NotificationsCount from "../../Displays/Basic/NotificationsCount";
import is_false from "../../Functions/is_false";
import getAgencyID from "../../Functions/getAgencyID";
import is_true from "../../Functions/is_true";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        const open_sidebar_areas =  validateVar(localStorage.getItem('open_sidebar_areas')) ? JSON.parse(localStorage.getItem('open_sidebar_areas')) : {};
        {all_urls.map((url_obj) => {
            if (url_obj.category === "sidebar" && // if displayed
                url_obj.permissions.includes(getUserRole()) && validateVar(url_obj.collapse_state_name)) { // && has rights to see this navigation section
                if (is_false(validateVar(open_sidebar_areas[url_obj.collapse_state_name]))) { // if not included in object yet
                    open_sidebar_areas[url_obj.collapse_state_name] = this.setInitialCollapseState(url_obj); // check if current path is a subcomponent of a section
                }
            }
        })}
        this.state = {
            open_sidebar_areas: open_sidebar_areas,
        };
    }

    expand_sidebar_areas = (url_obj, collapsed) => {
        let {open_sidebar_areas} = this.state;
        open_sidebar_areas[url_obj.collapse_state_name]  = validateVar(collapsed) ? !collapsed : true;
        this.setState({open_sidebar_areas: open_sidebar_areas});
        localStorage.setItem("open_sidebar_areas", JSON.stringify(open_sidebar_areas));
    };


    append_content_count = (sidebar_obj) => {
        let {is_loaded_info_count, overview_tasks} = this.props;
        let {title} = sidebar_obj;
        if (validateVar(overview_tasks) && validateVar(overview_tasks[sidebar_obj.show_data_count]) && validateVar(sidebar_obj) && validateVar(sidebar_obj.show_data_count) && overview_tasks[sidebar_obj.show_data_count]) {
            return  <NotificationsCount text={title} value={overview_tasks[sidebar_obj.show_data_count]} is_loaded={is_loaded_info_count}/>;
        }else return title;
    };

    get_section_content = (url_obj, index) => {
        const {employee} = this.props;

        let agency_id = getAgencyID(null, true).agency_id;
        let hide_for_current_agency = validateVar(url_obj.exclude_agencies) && url_obj.exclude_agencies.includes(agency_id);

        let show_for_current_agency = true;
        if(validateVar(url_obj.required_agency)) show_for_current_agency = url_obj.required_agency.includes(agency_id);

        let permited_team_access = true;
        if(validateVar(url_obj.team_permissions)) {
            permited_team_access = url_obj.team_permissions.length > 0 &&
                validateVar(employee.team_display) &&
                url_obj.team_permissions.includes(employee.team_display);
        }

        if (validateVar(url_obj) &&
            validateVar(url_obj.component) &&
            is_false(hide_for_current_agency) &&
            is_true(show_for_current_agency) &&
            is_true(permited_team_access)) {
            if (url_obj.component === 'collapse') {
                let {open_sidebar_areas} = this.state; // state with all url_names opened included
                let collapsed = open_sidebar_areas[url_obj.collapse_state_name]; // check if name is in open areas state
                return (
                    <NavItem key={index}>
                        <NavLink
                            data-toggle="collapse"
                            aria-expanded={this.state[url_obj.collapse_state_name]}
                            className={'cursor-pointer ' + classnames({
                                active: validateVar(collapsed) ? collapsed : false
                            })}
                            onClick={() => this.expand_sidebar_areas(url_obj, collapsed)}
                        >
                            {url_obj.icon ? <i className={url_obj.icon}/> : null}
                            <span className="nav-link-text">{(url_obj.show_data_count) ? this.append_content_count(url_obj) : url_obj.title}</span>
                        </NavLink>
                        <Collapse isOpen={collapsed}>
                            <Nav className="nav-sm flex-column">
                                {url_obj.undercomponents.map((component, index) => {
                                    return this.get_section_content(component, index)
                                })}
                            </Nav>
                        </Collapse>
                    </NavItem>
                );
            } else {
                let href = (validateVar(url_obj.href)) ? url_obj.href : url_obj.path;
                return <NavItem
                    className={this.activeRoute(href)}
                    key={index}
                >
                    <a
                        className='nav-link'
                        href={href}
                        onClick={this.closeSidenav}
                    >
                        {url_obj.icon !== undefined ? (
                            <>
                                <i className={url_obj.icon}/>
                                <span className="nav-link-text">{(url_obj.show_data_count) ? this.append_content_count(url_obj) : url_obj.title}</span>
                            </>
                        ) : (
                            (url_obj.show_data_count) ? this.append_content_count(url_obj) : url_obj.title
                        )}
                    </a>
                </NavItem>
            }
        } else {
            return null;
        }
    };

    setInitialCollapseState = (url_obj) => {
        let collapse_open = false;
        let undercomponents = url_obj.undercomponents;
        if(validateVar(undercomponents) && Array.isArray(undercomponents) && undercomponents){
            for(let url_obj_undercomponent of undercomponents){
                if(window.location.pathname === url_obj_undercomponent.path || window.location.pathname === url_obj_undercomponent.href) collapse_open = true;
            }
        }
        return collapse_open;
    };

    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    // makes the sidenav normal on hover (actually when mouse enters on it)
    onMouseEnterSidenav = () => {
        if (!document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.add("g-sidenav-show");
        }
    };
    // makes the sidenav mini on hover (actually when mouse leaves from it)
    onMouseLeaveSidenav = () => {
        if (!document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
        }
    };

    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    closeSidenav = () => {
        if (window.innerWidth < 1200) {
            this.props.toggleSidenav();
        }
    };

    render() {
        const {logo} = this.props;
        let navbarBrandProps;
        if (logo && logo.innerLink) {
            navbarBrandProps = {
                to: logo.innerLink,
                tag: Link
            };
        } else if (logo && logo.outterLink) {
            navbarBrandProps = {
                href: logo.outterLink,
                target: "_blank"
            };
        }
        const scrollBarInner = (
            <div className="scrollbar-inner">
                <div className="sidenav-header d-flex align-items-center">
                    {logo ? (
                        <NavbarBrand {...navbarBrandProps}>
                            <img
                                alt={logo.imgAlt}
                                className="navbar-brand-img"
                                src={logo.imgSrc}
                            />
                        </NavbarBrand>
                    ) : null}
                    <div className="ml-auto">
                        <div
                            className={classnames("sidenav-toggler mr-4 d-lg-block d-xl-none ", {
                                active: this.props.sidenavOpen
                            })}
                            onClick={this.props.toggleSidenav}
                        >
                            <div className="sidenav-toggler-inner">
                                <i className="sidenav-toggler-line"/>
                                <i className="sidenav-toggler-line"/>
                                <i className="sidenav-toggler-line"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="navbar-inner">
                    <Collapse navbar isOpen={true}>
                        {Object.keys(sidebar_sections).map((key) => {
                            let section = sidebar_sections[key];
                            return <>{(section.permissions.includes(getUserRole()) && <>
                                {validateVar(section.separator) && <hr className={section.separator}/>}
                                {validateVar(section.title) &&
                                <h6 className="navbar-heading p-0 text-muted">{section.title}</h6>}
                                <Nav className={classnames({'pl-0': key === 'default'})} navbar>
                                    {all_urls.map((url_obj, index) => {
                                        if (url_obj.category === "sidebar" &&
                                            url_obj.permissions.includes(getUserRole()) &&
                                            key === url_obj.section) {
                                            return this.get_section_content(url_obj, index)
                                        }
                                    })}
                                </Nav>
                            </>)}</>
                        })}
                    </Collapse>
                </div>
            </div>
        );
        return (
            <Navbar
                className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
                onMouseEnter={this.onMouseEnterSidenav}
                onMouseLeave={this.onMouseLeaveSidenav}
            >
                {navigator.platform.indexOf("Win") > -1 ? (
                    <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
                ) : (
                    scrollBarInner
                )}
            </Navbar>
        );
    }
}

Sidebar.defaultProps = {
    routes: [{}],
    toggleSidenav: () => {
    },
    sidenavOpen: false
};

Sidebar.propTypes = {
    // function used to make sidenav mini or normal
    toggleSidenav: PropTypes.func,
    // prop to know if the sidenav is mini or normal
    sidenavOpen: PropTypes.bool,
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
    // logo
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the image src of the logo
        imgSrc: PropTypes.string.isRequired,
        // the alt for the img
        imgAlt: PropTypes.string.isRequired
    })
};

export default Sidebar;
