import React from "react";
import Button from "reactstrap/lib/Button";
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import Skeleton from 'react-loading-skeleton';

const DownloadButton = (props) => {
    return (
        <>{props.is_loaded ?
            <Button onClick={() => download(props.file_url, props.file_name)} disabled={props.disabled}
                    className='eless-btn download-btn grey-btn'>
                Herunterladen <span className='ni ni-cloud-download-95 download-icon'/>
            </Button> : <Skeleton width={100} height={'30'}/>}
        </>)
};


function download(url, file_name) {
    FileSaver.saveAs(url, file_name)
}

DownloadButton.defaultProps = {
    disabled: false,
};

DownloadButton.propTypes = {
    disabled: PropTypes.bool,
    file_url: PropTypes.string,
    file_name: PropTypes.string
};

export default DownloadButton;