import Communication from "./Components/Communication/communication";
import {
  apiError,
  apiFileSystemService,
  apiFunctionConnectInterations,
  apiFunctionDisconnectConnectedInterations,
  apiFunctionGetConnectedInterations,
  apiFunctionGetDisconnectedInterations,
  apiFunctionReturnResource,
  apiPlatformService
} from "../Functions/global";
import React from "react";
import MoreOptions from "../Displays/Buttons/MoreOptions";
import { deHashVar } from "../Functions/handleHashedVariable";
import validateVar from "../Functions/validation/validateVariable";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import getBadgeColor from "../Functions/getBadgeColor";

const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));

class Integration {
  constructor(data) {

    /*
     *   default value for vars in this class
     *   add new vars here
     * */
    //basic
    this.id = "";
    this.platform_id = "";
    this.company = "";
    this.description = "";
    this.has_username = "";

    this.has_password = "";
    this.has_api_key = ""; //only customer who has been in contract before
    this.logo_id = "";
    this.created_at = "";
    this.updated_at = "";

    this.status = "";

    this.show_logo = "";
    this.options_list = "";

    this.show_status = "";
    this.logo_url = "";

    //load basic info

    if (typeof data !== "undefined" && typeof data.id !== "undefined") {
      //if data found
      this.id = typeof data.id === "undefined" ? "" : data.id;
      this.platform_id =
        typeof data.platform_id === "undefined" ? "" : data.platform_id;
      this.company = typeof data.company === "undefined" ? "" : data.company;
      this.description =
        typeof data.description === "undefined" ? "" : data.description;
      this.has_username =
        typeof data.has_username === "undefined" ? "" : data.has_username;
      this.has_password =
        typeof data.has_password === "undefined" ? "" : data.has_password;
      this.has_api_key =
        typeof data.has_api_key === "undefined" ? "" : data.has_api_key;
      this.logo_id = typeof data.logo_id === "undefined" ? "" : data.logo_id;

      this.company_url =
        typeof data.company_url === "undefined" ? "" : data.company_url;

      if (data.hasOwnProperty("pivot")) {
        this.status =
          typeof data.pivot.status === "undefined" ? "" : data.pivot.status;
        this.status_display =
          typeof data.pivot.status === "undefined"
            ? ""
            : this.getStatus(this.status);
          this.status_color = getBadgeColor(this.status_display, ['Verbunden'], ['In Prüfung'], ['Fehler'])
      }

      //show
      this.options_list = <MoreOptions integration_id={this.id} />;

      //handle logo
      if (data.hasOwnProperty("logo_file")) {
        this.logo_url =
          typeof data.logo_file.url === "undefined" ? "" : data.logo_file.url;

        this.show_logo =
          typeof data.logo_file.url === "undefined" ? (
            ""
          ) : (
            <img
              className={"integration-table-logo"}
              src={this.logo_url}
              alt={"tender-logo"}
            />
          );
      }
    }
  }

  /*
        Instantiate a new Customer with its ID as Param
        Call Communication Class with matching global defined Vars for API Request
        return new Customer Clnass
     */
  static async instantiate(id) {
    // id = supplier_id;
    // this.c = new Communication(
    //   apiPlatformService,
    //   apiFunctionGetConnectedInterations,
    //   "",
    //   apiFunctionDisconnectConnectedInterations,
    //   apiFunctionConnectInterations,
    //   "",
    //   { agency_id: id }
    // );


    let params = getAgencyID({}, true);
      let communication_params = {
          service: apiPlatformService,
          get_function: apiFunctionGetConnectedInterations,
          update_function: "",
          delete_function: apiFunctionDisconnectConnectedInterations,
          add_function: apiFunctionConnectInterations,
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);


    let res = await c.load();

    return new Integration(res.result);
  }

  static async delete(integrations, id) {
    let new_state = [];
    id = parseInt(id);

    for (let integration of integrations) {
      if (id === integration.id) {
        //remove id from localsctorage if id is stored
        let integrations_storage = JSON.parse(
          localStorage.getItem("integrations")
        );
        if (!validateVar(integrations_storage)) integrations_storage = [];
        if (integrations_storage.hasOwnProperty(id)) {
          integrations_storage[id] = null;
          localStorage.setItem(
            "integrations",
            JSON.stringify(integrations_storage)
          );
        } else {
          new_state.push(integration);
        }
      }
    }
      let communication_params = {
          service: apiPlatformService,
          get_function: apiFunctionGetConnectedInterations,
          update_function: "",
          delete_function: apiFunctionDisconnectConnectedInterations,
          add_function: apiFunctionConnectInterations,
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);
    let result = await c.delete({
      integration_id: id,
      agency_id: supplier_id
    });
    result.new_state = new_state;
    return result;
  }

  /*
            Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
            Call Communication Class with matching global defined Vars for API Request
            return Array with all TariffCalculatedContainer Obj
     */
  static async instantiateList(id) {
    // this.c = new Communication(
    //   apiPlatformService,
    //   apiFunctionGetConnectedInterations,
    //   "",
    //   apiFunctionDisconnectConnectedInterations,
    //   apiFunctionConnectInterations,
    //   "",
    //   { agency_id: id }
    // );

    let params = getAgencyID({}, true);
    let communication_params = {
        service: apiPlatformService,
        get_function: apiFunctionGetConnectedInterations,
        update_function: "",
        delete_function: apiFunctionDisconnectConnectedInterations,
        add_function: apiFunctionConnectInterations,
        download_function: "",
        params: params,
    };
    let c = getCommunicationClass(communication_params);

    var res = await c.load();
    if (res.status === "ok") {
      if (res === "error" || res.result === "[]" || res.result === [])
        return [];
      else {
        let listResults = [];
        for (var i = 0; i < res.result.length; i++) {
          listResults.push(new Integration(res.result[i]));
        }
        return listResults;
      }
    }
  }

  /*
           Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
           Call Communication Class with matching global defined Vars for API Request
           return Array with all TariffCalculatedContainer Obj
    */
  static async instantiateListDisconnected() {
    // this.c = new Communication(
    //   apiPlatformService,
    //   apiFunctionGetDisconnectedInterations,
    //   "",
    //   apiFunctionDisconnectConnectedInterations,
    //   "",
    //   "",
    //   { agency_id: supplier_id }
    // );

      let params = getAgencyID({}, true);
      let communication_params = {
          service: apiPlatformService,
          get_function: apiFunctionGetDisconnectedInterations,
          update_function: "",
          delete_function: apiFunctionDisconnectConnectedInterations,
          add_function: apiFunctionConnectInterations,
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);

    var res = await c.load();

    if (res.status === "ok") {
      if (res.result === "[]") return [];
      else {
        this.c = new Communication(
          apiFileSystemService,
          "",
          "",
          "",
          "",
          apiFunctionReturnResource,
          {}
        );

          let communication_params = {
              service: apiFileSystemService,
              get_function: "",
              update_function: "",
              delete_function: "",
              add_function: "",
              download_function: apiFunctionReturnResource,
              params: null,
          };
          let c = getCommunicationClass(communication_params);


        let listResults = [];
        //create integration classes for all results (with logos)
        for (var i = 0; i < res.result.length; i++) {
          //get logo from id request
          let logo_url = await c.download(
            { id: res.result[i].logo_id },
            "image/png",
            true
          );
          res.result[i].logo_file = {
            url: logo_url
          };
          listResults.push(new Integration(res.result[i]));
        }
        return listResults;
      }
    } else return apiError;
  }

  /*
        update function - params must be a valid object
     */
  update(param) {
    if (typeof param !== "object") throw "Daneben!";
    return this.c.update(param).then(res => {
      if (res.status === "ok") return res.result;
      return apiError;
    });
  }

  getStatus(status) {
    if (status === "active") return "Verbunden";
    else if (status === "pending") return "In Prüfung";
    else if (status === "error") return "Fehler";
    else return "-/-";
  }
}

export default Integration;
