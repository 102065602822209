import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from "@khanacademy/react-multi-select";
import validateVar from "../../Functions/validation/validateVariable";
import cn from 'classnames';
import is_true from "../../Functions/is_true";
import './Multiselect.scss';

class CustomMultiselect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            all_items_selected_label: (validateVar(props.all_items_selected_label)) ? props.all_items_selected_label  : "Alle"
        };
    }

    handleChange = (selected) => {
        this.props.handleChange(selected); //Parent-Component ist der Single source of truth
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        //this functions runs on every click on the page
        let dropdownContent = document.getElementsByClassName('dropdown-content');
        if(event.target.classList.contains('dropdown-heading-value')){
            //this case runs the "onFocusOut/onBlur" funtion when clicked on the Multiselect-wrapper with the class "dropdown-heading-value" while dropdown was open
            if(dropdownContent.length !== 0){
                this.props.onBlur();
            }
        }else{ //this case runs the same function as above when clicked outside the multiselect while it was open
            if(dropdownContent.length !== 0){
                if (this.wrapperRef && !this.wrapperRef.contains(event.target)) { //close the button on click outside
                    this.props.onBlur(event);
                }
            }
        }
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    render() {
        const {all_items_selected_label} = this.state;
        const {selected, options, label, disabled, classes, disableSearch, id, name} = this.props;
        let searchDisabled = ((is_true(disabled)) || (is_true(disableSearch)));

        return (
            <div ref={this.setWrapperRef} id={id} className={'custom-select-arrow custom-multiselect' + cn({[' '+classes]: validateVar(classes)})}>
                <MultiSelect
                    options={options}
                    selected={selected}
                    onSelectedChanged={selected => this.handleChange(selected)}
                    hasSelectAll={false}
                    selectAllLabel={false}
                    overrideStrings={{
                        selectSomeItems: label,
                        allItemsAreSelected: all_items_selected_label,
                        search: "Suchen",
                    }}
                    disabled={disabled}
                    disableSearch={searchDisabled}
                />
            </div>
        );
    }
}

CustomMultiselect.propTypes = {
    options: PropTypes.arrayOf(
    PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })).isRequired,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    classes: PropTypes.string,
    label: PropTypes.string,
    disableSearch: PropTypes.bool,
};

export default CustomMultiselect;

