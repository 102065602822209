import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";

let delta_vars = {
    price_rate_delta: {
        price_rate_delta: null,
        price_rate_delta_display: {
            function_name: "handleFormatPrice",
            params: ["3", "ct / kWh"]
        }
    },
    contract_price_gross_delta: {
        contract_price_gross_delta: null,
        contract_price_gross_delta_display: {
            function_name: "handleFormatPrice",
            params: ["3", "ct / kWh"]
        },
        price_rate_color: {
            function_name: "priceDiffColor",
            params: ["%%price_rate"]
        },
    },
    socket_net_delta: {
        socket_net_delta: null,
        socket_net_delta_display: {
            function_name: "handleFormatPrice",
            params: [2, "€ / Jahr"]
        }
    },
    socket_gross_delta: {
        socket_gross_delta: null,
        socket_gross_delta_display: {
            function_name: "handleFormatPrice",
            params: [2, "€ / Jahr"]
        },
        socket_color: {
            function_name: "priceDiffColor",
            params: ["%%socket", true]
        },
    },
    yearly_price_net_delta: {
        yearly_price_delta: null,
        yearly_price_delta_display: {
            function_name: "handleFormatPrice",
            params: ["2", "€ / Jahr"]
        }
    },
    yearly_price_gross_delta: {
        yearly_price_gross_delta: null,
        yearly_price_gross_delta_display: {
            function_name: "handleFormatPrice",
            params: ["2", "€ / Jahr"]
        },
        yearly_price_color: {
            function_name: "priceDiffColor",
            params: ["%%yearly_price"]
        }
    },






};


class Delta {
    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(delta_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }
}

export default Delta;
