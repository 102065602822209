import validateVar from "./validation/validateVariable";
import handleFormatPrice from "./handleFormatPrice";

function get_margins(margins){
    for (let idx in margins) {
        if (margins.hasOwnProperty(idx)) {
            if (validateVar(margins[idx])) {
                let margin = margins[idx];
                let amount = 0;
                if (validateVar(margins[idx].amount)) {
                    amount = JSON.parse(JSON.stringify(margin.amount));
                    if (idx === "10000") {
                        amount = +amount * 12;
                        amount = handleFormatPrice(amount, 2);
                    }
                }
            //
                margin.amount = amount+"".replace(".", ",");
            }
        }
    }
    return margins;
}
export default get_margins;