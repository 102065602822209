import {emptyValue} from "./global";


export default function getTimeDiffInMinutes(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    if (diff < 0) {
        // this.status = "cancelled";
        // this.display_status_badge = this.getDisplayStatus();
        return emptyValue;
    }
    diff /= 60;
    let diff_in_minutes = Math.abs(Math.round(diff));
    return getTimeDiffAsString(diff_in_minutes);
}

function getTimeDiffAsString(num) {
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rminutes < 10) rminutes = "0" + rminutes;
    if (rhours < 10) rhours = "0" + rhours;
    return rhours + "h:" + rminutes + "m";
}
