import React from 'react';
import {Row, Col, Collapse} from "reactstrap";
import validateVar from "../../../../../Functions/validation/validateVariable";
import handleFormatNumber from "../../../../../Functions/handleFormatNumber";
import handleFormatPrice from "../../../../../Functions/handleFormatPrice";
import {
    apiError,
    electricityNetfeeNotDisplay,
    gasNetfeeNotDisplay,
} from "../../../../../Functions/global";
import is_false from "../../../../../Functions/is_false";
import is_true from "../../../../../Functions/is_true";
import monthDiff from "../../../../../Functions/getMonthDiff";
import get_standardised_date from "../../../../../Functions/get_standardised_date";

class PriceInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTbody: false,
            netFeeTableData: [],
        };
    }

    componentDidMount(){
        this.getTableContent();
    }

    getTableContent = () =>{
        let {offer, net_fee} = this.props;

        const consumption = offer.predicted_consumption;
        if (validateVar(net_fee) && net_fee !== apiError){
            let sumNetfee = 0;
            let medium_type = offer.medium_type;
            let exclude = electricityNetfeeNotDisplay;
            if (medium_type === "GAS") exclude = gasNetfeeNotDisplay;
            let netFeeTableData =  net_fee.map((netFeeObject, index) => {
                if (!exclude.includes(netFeeObject.name)){
                    let thicBorder = '';
                    if(net_fee.length - 1 === index) thicBorder = 'border-3';
                    sumNetfee = netFeeObject.value_euro + sumNetfee;
                    return <Row className={'custom-border-bottom tariff-price-details-row ' + thicBorder}>
                        <Col sm={6}>
                            <b>{netFeeObject.name_translated}</b>
                        </Col>
                        <Col sm={2}>
                            {netFeeObject.value_ct_display}
                        </Col>
                        <Col sm={2}>
                            {netFeeObject.amount ? netFeeObject.amount : offer.predicted_consumption_display}
                        </Col>
                        <Col sm={2}>
                            {netFeeObject.value_euro_display}
                        </Col>
                    </Row>
                }
            });

            let netFeeSumInCt = sumNetfee * 100 /  consumption;
            this.setState({
                netFeeSumInCt: netFeeSumInCt,
                sumNetfee: sumNetfee,
                netFeeTableData: netFeeTableData,
            })
        }
    };

    get_html_attribute = (value, options) => {
        let return_val = null;
        let classes = validateVar(options.classes) ? options.classes : "";
        if (options.attribute === "h2")  return_val = <h2 className={classes}>{value}</h2>;
        else if (options.attribute === "span")  return_val = <span className={classes}>{value}</span>;
        return return_val;
    };

    get_table = (tariff, is_private, show_table_body, is_spot, netfee_obj) => {
        let {offer, tariff_information} = this.props;
        let net = <span className='gray-hint-text'> (Netto)</span>;
        let gross = <span className='gray-hint-text'> (Brutto)</span>;
        let spot_symbol = (is_spot) ? "Ø " : "";
        let values = [
            { // Preisbestandteil <SimpleTable
                label: show_table_body ? "Kosten der Energie" : "Preisbestandteil",
                value_1: (tariff_information.is_private ? <>Preis {net}</> : (show_table_body) ? <>Preis {net}</> : ""),
                value_2: (tariff_information.is_private ? <>Preis {gross}</> : (show_table_body) ? "Menge" : <>Preis {net}</>),
                sum: <>Betrag {(tariff_information.is_private ? gross : net)}</>,
                options: {attribute: "h2", classes: 'mb-0'}},
            { //Arbeitspreis
                label: (tariff_information.show_netfee ? <b>Energiepreis</b> : <b>Arbeitspreis</b>),
                value_1: (tariff_information.is_private ? spot_symbol + tariff_information.price_net_display : (show_table_body) ? spot_symbol + tariff_information.price_display : ""),
                value_2: (tariff_information.is_private ? spot_symbol + tariff_information.price_display : (show_table_body) ? offer.predicted_consumption_display : spot_symbol + tariff_information.price_display),
                sum: handleFormatPrice(tariff_information.yearly_price - tariff_information.yearly_socket_gross, 2, '€') + ' / Jahr',
                options: {attribute: "span", classes: 'mb-0'}
            },
            {
                label: <b>Grundgebühr</b>,
                value_1: (tariff_information.is_private ? tariff_information.socket_net_display : (show_table_body) ? tariff_information.socket_net_display : ""),
                value_2: ((show_table_body) ? tariff.duration + " Monate" : tariff_information.socket_display),
                sum: tariff_information.yearly_socket_display,
                options: {attribute: "span", classes: 'mb-0'}
            },
        ];

        is_true(tariff_information.show_netfee) && values.push({
            label: (show_table_body) ?  null : <b>Netzentgelte, Umlagen, Steuern & Abgaben</b>,
            value_1: (show_table_body) ? null : "",
            value_2: (show_table_body) ? null : netfee_obj.sum_net_fee_in_ct,
            sum: (tariff_information.is_private ? null : (show_table_body) ? null : netfee_obj.sum_net_fee_in_euro),
            options: {attribute: "span", classes: 'mb-0'}
        });
        values.push({
                label: <b>Summe</b>,
                value_1: (tariff_information.is_private ? "" : ""),
                value_2: (tariff_information.is_private ? "" : ""),
                sum: ((show_table_body) ? tariff_information.yearly_price_display : tariff_information.yearly_price_sum_display),
                options: {attribute: "span", classes: 'mb-0'}
        });

        let return_values_arr = [];
        for (let index in values) {
            if (values.hasOwnProperty(index)) {
                let value = values[index];
                if (value.value_1 !== null && value.value_2 !== null && value.sum !== null) {
                    return_values_arr.push(<Row className='custom-border-bottom tariff-price-details-row'>
                        <Col md={value.value_1 !== null && value.value_2 !== null ? 6 : 8}>
                            {this.get_html_attribute(value.label, value.options)}
                        </Col>
                        {(value.value_1 !== null) && <Col md={2}>
                            {this.get_html_attribute(value.value_1, value.options)}
                        </Col>}

                        {(value.value_2 !== null) && <Col md={2}>
                            {this.get_html_attribute(value.value_2, value.options)}
                        </Col>}

                        <Col md={2}>
                            {this.get_html_attribute(value.sum, value.options)}
                        </Col>
                    </Row>)
                }
            }
        }
        return return_values_arr;
    };

    render() {
        let {netFeeTableData, sumNetfee, netFeeSumInCt, showTbody} = {...this.state};
        let {offer, tariff, tariff_information} = {...this.props};
        let is_private_tariff = (tariff.customer_type === "PRIVATE");
        let bundle_calculation = (tariff.is_slp === "YES" && tariff.is_rlm === "YES");
        let duration = monthDiff(new Date(get_standardised_date(offer.offer_begin)), new Date(get_standardised_date(offer.offer_end)));
        // let tariff = this.props.calculatedTariffData;
        let yearly_price = tariff.yearly_price;
        duration = duration > 12 ? 12 : duration;
        let spot = false;
        let sumInEuroAll;

        if (tariff.tariff_type === "SPOT") {
            spot = true;
        }
        sumInEuroAll = yearly_price + sumNetfee;
        let unformated_net_fee_sum = sumNetfee;
        // let yearly_price_with_socket = handleFormatPrice((socket_yearly + yearly_    price), 2, "€/Jahr");
        let sumInEuroDisplayAll = handleFormatPrice(sumInEuroAll, 2, "€");
        let sumInEuroDisplayNetfee = handleFormatPrice(sumNetfee, 2, "€");
        sumNetfee = handleFormatPrice(sumNetfee, 2, "€/Jahr");
        netFeeSumInCt = handleFormatPrice(netFeeSumInCt, 3, "ct/kWh");
        tariff.duration = duration;
        let price_details_table_content = this.get_table(tariff, is_private_tariff, showTbody, spot, {sum_net_fee_in_euro: sumNetfee, sum_net_fee_in_ct: netFeeSumInCt, sum_net_fee_in_euro_unformated: unformated_net_fee_sum});

        return(
            <div>
                {validateVar(netFeeTableData) ? <div className={"pt-4 px-4"}>
                    {price_details_table_content}
                    <Collapse isOpen={showTbody}>
                        <Row className={'mb-2 mt-4 custom-border-bottom tariff-price-details-row'}>
                            <Col md={6}>
                                <h2>Netzentgelte, Umlagen, Steuern & Abgaben</h2>
                            </Col>
                            <Col md={2}>
                                <h2>Preis</h2>
                            </Col>
                            <Col md={2}>
                                <h2>Menge</h2>
                            </Col>
                            <Col md={2}>
                                <h2>Betrag</h2>
                            </Col>
                        </Row>
                        {netFeeTableData}
                        <Row className='mb-4 pb-3 tariff-price-details-row'>
                            <Col md={8}>
                                <b>Summe (netto)</b>
                            </Col>
                            <Col md={2}>
                                {/*<b>{sumInCt}</b>*/}
                            </Col>
                            <Col md={2}>
                                <b>{sumInEuroDisplayNetfee+'/Jahr'}</b>
                            </Col>
                        </Row>
                    </Collapse>
                    {(is_false(bundle_calculation) && is_true(tariff_information.show_netfee)) &&
                    <Row className='tariff-price-details-row pt-0 mb-0' >
                        <Col>
                        {showTbody ?
                            <span onClick={() => this.setState({showTbody: !showTbody})} className='black-link-button'>Erweiterte Detailansicht der Preisbestandteile schliessen</span> :
                            <span onClick={() => this.setState({showTbody: !showTbody})} className='black-link-button'>Erweiterte Detailansicht der Preisbestandteile öffnen</span>
                        }
                        </Col>
                    </Row>}

                    {is_true(tariff_information.show_netfee) && <Row className='tariff-price-details-row mt-0 pt-0'>
                        <Col>
                            <div className='static-warning-notification grey'>
                                <b>HINWEIS!</b> Bei einem Jahresverbrauch von {offer.predicted_consumption_display} kWh ergeben sich Gesamtkosten in Höhe von {sumInEuroDisplayAll} / Jahr.
                                Die Gesamtkosten setzen sich dabei aus zwei Komponenten zusammen. Die erste Komponente sind die Kosten der Energie,
                                die auf dem Energiepreis und der Grundgebühr basieren und je nach Anbieter unterschiedlich ausfallen. Die zweite
                                Komponente sind Netzentgelte, Umlagen, Steuern & Abgaben, die für den Energiepreisvergleich aber nicht relevant sind,
                                da sie für alle Anbieter gleich hoch sind. Der Ausweis der Netzentgelte, Umlagen, Steuern & Abgaben ist daher auch
                                nur rein informativ und ohne Gewähr. Es werden die tatsächlichen Konditionen weiterverrechnet.
                                Sämtliche Preise verstehen sich zzgl. ges. MwSt..
                            </div>
                        </Col>
                    </Row>}
                </div> :
                <div className={"pt-4 px-4"}>
                    <h4>Es tut uns leid, leider liegen unserem Dienstleister zu dieser Postleitzahl keine Preisbestandteile vor.</h4>
                </div>
                }
            </div>
        );
    }
}

export default PriceInformation;