import React from 'react';
import {Progress} from 'reactstrap';
import './ProgressBar.scss';
import is_true from "../../Functions/is_true";

class ProgressBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: 0,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {skip_loading, start_loading} = this.props;
        const {progress} = this.state;

        if(is_true(skip_loading) && progress !== 100){
            this.setState({progress: 100});
        }

        if(is_true(start_loading) && start_loading !== prevProps.start_loading){
            this.setState({progress: 0});
        }
    }

    load_progress = (start_loading, finish_loading, progress) => {
        if(start_loading && progress < 80 && !finish_loading) {
            setTimeout(function() {
                this.setState({progress: progress + 1});
            }.bind(this), 25);
        }

        if(start_loading && progress >= 80 && progress <= 95 && !finish_loading) {
            setTimeout(function() {
                this.setState({progress: progress + 1});
            }.bind(this), 500);
        }

        if(finish_loading && progress !== 100) {
            setTimeout(function() {
                this.setState({progress: 100});
            }.bind(this), 25);
        }
    }

    render() {
        const {start_loading, finish_loading} = this.props;
        const {progress} = this.state;

        if((start_loading || finish_loading) && progress !== 100) this.load_progress(start_loading, finish_loading, progress);

        return <Progress className='eless-progress-bar' value={progress} />
    }
}

export default ProgressBar;