import React from 'react';
import Skeleton from "react-loading-skeleton";
import is_false from "../Functions/is_false";
class CardHeaderText extends React.Component {

    render() {
        return (
            <div>
                {is_false(this.props.is_loaded) ? <Skeleton width='100%' height={30}/> : <h2 className='mb-0'>{this.props.title}</h2>}
                <span className='h2-description'>{this.props.description}</span>
            </div>
        );
    }
}

export default CardHeaderText;