import React from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import InputHandler from "../../../../module_components/Inputs/InputHandler";
import handleFormatNumber from "../../../../module_components/Functions/handleFormatNumber";
import AddressForm from "../../../../module_components/PageContent/AddressForm";
import Skeleton from "react-loading-skeleton";
import {slpMaxConsumption} from "../../../../module_components/Functions/global";

class ConsumptionPointForm extends React.Component {
    render() {
        const {input_disabled, consumption_points, error_messages, is_loaded, tariff, load_profiles, index} = this.props;

        let consumption_min_max = null
        if(consumption_points[index].last_type === 'SLP'){
            consumption_min_max = {
                range_min: 0,
                range_max: slpMaxConsumption[tariff.medium_type],
                type: "int",
            };
        }

        return <Card>
            <CardHeader>
                {is_loaded ? <h3 className='mb-0'>{consumption_points[index].last_type} - Lieferstelle</h3> : <Skeleton height={24} width={300} />}
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        <InputHandler
                            content_title="Jahresverbrauch (kWh)"
                            type="text"
                            name="predicted_consumption"
                            placeholder='kWh'
                            value={handleFormatNumber(consumption_points[index].predicted_consumption)}
                            disabled={input_disabled}
                            on_change={(name, value) => this.props.update_state(name, value, index)}
                            input_group_text="kWh"
                            error_state={error_messages[index].predicted_consumption_error}
                            is_loaded={is_loaded}
                            min_max_attribute={consumption_min_max}

                        />
                    </Col>
                    <Col>
                        {tariff.customer_type !== 'PRIVATE' && <InputHandler
                            content_title='Verbrauchsprofil'
                            type='select'
                            name='last_profile'
                            value={consumption_points[index].last_profile}
                            disabled={input_disabled}
                            on_change={(name, value) => this.props.update_state(name, value, index)}
                            select_options={load_profiles[consumption_points[index].last_type]}
                            is_loaded={is_loaded}
                            remove_empty_option
                        />}
                    </Col>
                </Row>

                <AddressForm address={consumption_points[index].address}
                    error_messages={error_messages[index]}
                    on_change={(values) => this.props.update_state('address', values, index)}
                    form_id={'address'+index}
                    set_state={(state) => this.props.update_state('state', state, index)}
                    is_loaded={is_loaded}
                    disabled={input_disabled}
                />
            </CardBody>
        </Card>
    }
}

export default ConsumptionPointForm;