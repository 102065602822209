import React from 'react';
import {Row, Col} from 'reactstrap';
import PrimaryButton from "../Buttons/PrimaryButton";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {deHashVar} from "../../Functions/handleHashedVariable";
import {project_name} from "../../Functions/global";
import GreyLinkFeedbackModalButton from "../Buttons/GreyLinkFeedbackModalButton";
const supplier_id = deHashVar(localStorage.getItem('evuSecurityToken'));
const consultant_id = deHashVar(localStorage.getItem('consultantSecurityToken'));

class AcceptChanges extends React.Component {
    state = {
        alert: null,
    };

    questionAlert = (type) => {
        let {modal_secure_question_accept, modal_secure_question_discard, edit_changes} = this.props;
        this.setState({
            alert: (
                <ReactBSAlert
                    custom
                    style={{ display: "block", marginTop: "-400px" }}
                    title="Achtung"
                    customIcon={
                        <div
                            className="swal2-icon swal2-question swal2-animate-question-icon"
                            style={{ display: "flex" }}
                        >
                            <span className="swal2-icon-text">!</span>
                        </div>
                    }
                    onConfirm={(props) => {
                        this.setState({alert: null});
                        edit_changes(type);
                    }}
                    onCancel={() => this.setState({alert: null})}
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Ja"
                    cancelBtnBsStyle="default"
                    cancelBtnText="Abbrechen"
                    showCancel={true}
                    btnSize=""
                >
                    {(type === "accepted") ? modal_secure_question_accept : modal_secure_question_discard}
                </ReactBSAlert>
            )
        });
    };

    render() {
        const {alert} = this.state;
        const {discard_btn, question, accept_btn, agency_id, matching_agency_text, disable_edit_history_btn} = this.props;
        let current_agency = null;
        if (project_name === "EVU_PROJECT") current_agency = supplier_id; // todo for consultant project
        else if (project_name === "CONSULTANT_PROJECT") current_agency = consultant_id; // todo for consultant project

        return <div className='accept-changes-footer'>
            {alert}
                {(+agency_id === +current_agency) ?
                    <Row>
                    <Col md={7} className='text-wrapper'>
                        {matching_agency_text}
                    </Col>
                    <Col className='buttons-wrapper' md={5}>
                            <GreyLinkFeedbackModalButton
                                classes='d-inline'
                                disabled={disable_edit_history_btn}
                                text={<b>Widerrufen</b>}
                                actionText='Möchten Sie die Änderungen wirklich ablehnen?'
                                onClick={() => this.questionAlert("declined")}
                            />
                    </Col>
                    </Row>
                    :
                    <Row>
                <Col md={7} className='text-wrapper'>
                    {question}
                </Col>
                <Col className='buttons-wrapper' md={5}>
                    <PrimaryButton
                        classes='mr-2'
                       onClick={() => this.questionAlert("accepted")}
                       value={accept_btn}
                       disabled={disable_edit_history_btn}
                    />
                    <GreyLinkFeedbackModalButton
                        classes='d-inline'
                        disabled={disable_edit_history_btn}
                        text={<b>{discard_btn}</b>}
                        actionText='Möchten Sie die Änderungen wirklich ablehnen?'
                        onClick={() => this.questionAlert("declined")}
                    />
                </Col>
                    </Row>
                }

        </div>
    }
}

export default AcceptChanges;