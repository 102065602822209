import validateVar from "./validation/validateVariable";

function getEnergySource(energy_source, medium_type) {
  if (!validateVar(energy_source)) return "- / -";
  else {
      if (energy_source === "grey" && medium_type === "ELECTRICITY") return "Graustrom";
      else if (energy_source === "green" && medium_type === "ELECTRICITY") return "Grünstrom";
      else if (energy_source === "grey" && medium_type === "GAS") return "Erdgas";
      else if (energy_source === "green" && medium_type === "GAS") return "Ökogas";
      else return "- / -";
  }
}
export default getEnergySource;
