import React from 'react';
import {Modal} from "reactstrap";
import PrimaryButton from "../Displays/Buttons/PrimaryButton";
import GreyLinkFeedbackModalButton from "../Displays/Buttons/GreyLinkFeedbackModalButton";
import validateVar from "../Functions/validation/validateVariable";

const warningIcon = <div className="mb-3 swal2-icon swal2-warning swal2-animate-warning-icon d-flex"><span
    className="swal2-icon-text">!</span></div>;

const infoIcon = <div className="mb-3 swal2-icon swal2-info swal2-animate-info-icon d-flex"><span
    className="swal2-icon-text">i</span></div>;

const successIcon = <div className="swal2-icon swal2-success swal2-animate-success-icon" >
        <div className="swal2-success-circular-line-left" />
        <span className="swal2-success-line-tip"/> <span className="swal2-success-line-long"/>
        <div className="swal2-success-ring"/>
        <div className="swal2-success-fix" />
        <div className="swal2-success-circular-line-right" />
    </div>;

class CustomAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    toggle_modal = () => {
        const {show_modal} = this.state;
        this.setState({show_modal: !show_modal});
    };

    showModal = (data) => {
        this.setState({
            show_modal: true,
            data: data
        })
    };

    turnOffModal(){
        const {modal_closed} = this.props;
        this.setState({
            data: null,
            show_modal: false
        });
        if(validateVar(modal_closed)) this.props.modal_closed();
    };

    render() {
        const {title, text, primaryBtnText, secondaryBtnText, allowToggle, text_addition} = this.props;

        let {data} = this.state;

        let alertIcon = warningIcon;
        if(this.props.infoIcon) alertIcon = infoIcon;
        if(this.props.successIcon) alertIcon = successIcon;

        return(<Modal
            className={"modal-dialog-centered custom-alert "+this.props.classes}
            size="md"
            isOpen={this.state.show_modal}
            toggle={allowToggle ? () => this.turnOffModal() : null}
        >
            {alertIcon}
            <div className='custom-alert-title'>{title}</div>
            <div className='custom-alert-description mb-2'>
                {text}
            </div>
            <div className='mb-4 modal-text-addition'>
                {text_addition}
            </div>
            {primaryBtnText && <PrimaryButton id='submitAlert' onClick={() => this.props.primaryBtnClicked(data)} value={primaryBtnText}
                            classes='btn-modal w-100'/>}
            {secondaryBtnText && <GreyLinkFeedbackModalButton text={secondaryBtnText} onClick={() => this.props.secondaryBtnClicked()}/>}
        </Modal>)
    }
}

export default CustomAlert;