import React from "react";
import PropTypes from "prop-types"
import Skeleton from 'react-loading-skeleton';
//prop.color: green/orange/red

const Badge = (props) => {
    const {color, classes, is_loaded, value} = props;
    return(is_loaded ? <span className={'badge-pill badge-'+ color +' '+classes}>{value}</span> : <Skeleton className={classes} width={160} height={20} /> )
};

Badge.defaultProps = {
    is_loaded: true,
}

Badge.propTypes = {
    color: PropTypes.oneOf(['green', 'orange', 'red', 'gray']),
    value: PropTypes.string,
    classes: PropTypes.string,
};

export default Badge;