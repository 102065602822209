import React from 'react';

function LoadingWheel(props) {
    return <>
        <div className="loader">
            <ul className='loader-ul'>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
                <li>
                    <div/>
                </li>
            </ul>
        </div>
        {props.title && <h2 className='mb-0'>{props.title}</h2>}
        </>;
}

export default LoadingWheel;