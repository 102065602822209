/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import AddTariffHeader from "./components/AddTariffHeader";
// custom components
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import {
    initializeTariff,
    updateTariff,
    initTmpList,
    resetRedirect,
    redirect,
} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import Skeleton from "react-loading-skeleton";
import {apiError} from "../../../module_components/Functions/global";
import {tree, TariffStatus} from "../../../globalNavigation";
import {deHashVar} from "../../../module_components/Functions/handleHashedVariable";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import MaterialUIStepper from "./components/MaterialUIStepper";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import Validation from "../../../module_components/Objects/Components/validation";
import GreyLinkButton from "../../../module_components/Displays/Buttons/GreyLinkButton";
import Badge from "../../../module_components/Displays/Badges/Badge";
import {dynamic_action_call, upload_file_new} from "../../../module_components/Index";
const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));
/*
    START CLASS
 */

class TariffHandleFileData extends Component {
    constructor(props) {
        super(props);
        this.id = parseInt(props.match.params.id);

        this.state = {
            sign_type: 'ONLINE', // ONLINE / OFFLINE
            is_loaded: false,
            disable_submit: true,
            disable_reverse: false,
            file_name_agb: "",
            file_name_label: "",
            contract_templates: [],
            error_messages: {},
            tariff: {},
            name: "",
            description: "",
            selectedContractID: 0,
            submit_loading: false
        }
    }

    componentDidMount(){

        if (this.id > 0){
            this.props.onInitialize({
                supplier_id: supplier_id,
                tariff_id: this.id
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {tariff, contract_templates} = this.props;
        const {selectedContractID} = this.state;

        if (validateVar(tariff) && tariff !== prevProps.tariff) {
            let options = {};
            if (tariff.medium_type === "ELECTRICITY") options.is_electricity = 1;
            if (tariff.medium_type === "GAS") options.is_gas = 1;
            if (tariff.is_slp === "YES") options.is_slp = 1;
            if (tariff.is_rlm === "YES") options.is_rlm = 1;
            if (tariff.customer_type === "PRIVATE") options.is_private = 1;
            if (tariff.customer_type === "BUSINESS") options.is_business = 1;
            let params = {
                options: options
            };
            this.props.onInitTmpList(params);

            if (tariff === apiError) {
                this.setState({
                    disable_submit: false
                })
            }  else {
                let sign_type = tariff.sign_type;
                if (tariff.accepts_electronic_signature === 'YES' && tariff.sign_type === 'OFFLINE') {
                    sign_type = 'E_SIGNATURE';
                }
                //check if the agb or labelling file got uploaded to finish the loading bar animation
                let labelling_file_uploaded = false;
                let agb_file_uploaded = false;
                if(validateVar(prevProps.tariff)){
                    if(prevProps.tariff.labelling_file_id !== tariff.labelling_file_id) labelling_file_uploaded = true
                    if(prevProps.tariff.agb_file_id !== tariff.agb_file_id) agb_file_uploaded = true
                }

                this.setState({
                    tariff: {...tariff},
                    sign_type: sign_type,
                    labelling_file_uploaded: labelling_file_uploaded,
                    agb_file_uploaded: agb_file_uploaded,
                    selectedContractID: (validateVar(tariff.contract_template_id)) ?  tariff.contract_template_id : selectedContractID
                });
                this.handle_files(tariff);
                this.setState({
                    is_loaded: true,
                });
            }
        }

        if (validateVar(contract_templates) && contract_templates !== prevProps.contract_templates) { //

        }
    }

    handle_retrun = () => {
        const {tariff} = this.state;
        let tree_id = (validateVar(tariff.tree_id)) ? tariff.tree_id : 0;
        this.props.redirect(tree + '/'+ this.id + '/' + tree_id);
    };

    handle_files = (tariff) => {
        let medium_type = tariff.medium_type;
        let agb = false;
        let label = false;
        if (validateVar(tariff.agb_file_id) && tariff.agb_file_id > 0 ) {
            agb = true;
            this.setState({
                file_name_agb: "AGB",
            });
        }

        if (validateVar(tariff.labelling_file_id) && tariff.labelling_file_id > 0 ) {
            label = true;
            this.setState({
                file_name_label: "KENNZEICHNUNG",
            });
        }

        if (medium_type === "ELECTRICITY" && agb && label) {
            this.setState({
                disable_submit: false
            })
        } else if (medium_type === "GAS" && agb){
            this.setState({
                disable_submit: false
            })
        }
    };

    update_state = (state_name, value) => {
        this.setState({
            [state_name]: value
        });
    };

    handle_submit = () => {
        const {selectedContractID} = this.state;
        let {sign_type} = this.state;

        let submitted_data = {
            contract_template_id: selectedContractID
        };

        // validation start
        // define special cases for error validation
        let special_cases = {
            contract_template_id: {case_name: "select"},
        };
        // call abstract error validation and set values in state
        let validation_result = new Validation(submitted_data, special_cases);
        this.setState({error_messages: validation_result.error_messages});
        // validation end

        let accepts_electronic_signature = 'NO';
        if (sign_type === 'E_SIGNATURE') {
            sign_type = 'OFFLINE';
            accepts_electronic_signature = 'YES';
        }

        if (!validation_result.has_error){
            this.setState({submit_loading: true});
            let data = {
                tariff_id: this.id,
                supplier_id: supplier_id,
                contract_template_id: selectedContractID,
                sign_type: sign_type, // ONLINE / OFFLINE
                accepts_electronic_signature: accepts_electronic_signature, //yes / no
            };
            this.props.onUpdateTariff(data, TariffStatus + '/'+ this.id);
            this.setState({
                disable_submit: true,
                disable_reverse: true,
            })
        }
    };

    get_matching_templates = () => {
        let {contract_templates} = this.props;
        let {tariff} = this.state;
        let matching_templates = [];
        for(let index in contract_templates){
            if (contract_templates.hasOwnProperty(index)) {
                let currentTemplate = contract_templates[index];
                if (currentTemplate.is_electricity === "YES" && tariff.medium_type === "ELECTRICITY"){
                    if (currentTemplate.is_slp === "YES" && currentTemplate.is_rlm === "YES"){
                        matching_templates.push({
                            key: currentTemplate.id,
                            value: currentTemplate.name,
                        });
                    }
                    else if (currentTemplate.is_slp === "YES" && tariff.is_slp === "YES"){
                        matching_templates.push({
                            key: currentTemplate.id,
                            value: currentTemplate.name,
                        });
                    } else if(currentTemplate.is_rlm === "YES" && tariff.is_rlm === "YES"){
                        matching_templates.push({
                            key: currentTemplate.id,
                            value: currentTemplate.name,
                        });
                    }
                }
                else if (currentTemplate.is_gas === "YES" && tariff.medium_type === "GAS") {
                    if (currentTemplate.is_slp === "YES" && currentTemplate.is_rlm === "YES"){
                        matching_templates.push({
                            key: currentTemplate.id,
                            value: currentTemplate.name,
                        });
                    }
                    else if (currentTemplate.is_slp === "YES" && tariff.is_slp === "YES"){
                        matching_templates.push({
                            key: currentTemplate.id,
                            value: currentTemplate.name,
                        });
                    } else if(currentTemplate.is_rlm === "YES" && tariff.is_rlm === "YES"){
                        matching_templates.push({
                            key: currentTemplate.id,
                            value: currentTemplate.name,
                        });
                    }
                }
            }
        }
        return matching_templates;
    };


    render() {
        const {dynamic_action_call, default_file_upload} = this.props;
        const {agb_error, labelling_error, contract_template_id_error} = this.state.error_messages;
        const {disable_submit, is_loaded, tariff, selectedContractID, submit_loading, sign_type} = this.state;
        let {agb, labelling, agb_file_uploaded, labelling_file_uploaded} = this.state;
        let allow_labelling_delete = false;

        console.log('sign_type', sign_type);

        if(validateVar(tariff)) {
            if(validateVar(tariff.agb_file_id)) {
                agb = 'AGB wurde hochgeladen und gespeichert';
            }
            if(validateVar(tariff.labelling_file_id)) {
                if(labelling || tariff.labelling_file_id)labelling = 'Die Kennzeichnung wurde hochgeladen und gespeichert';
                if(tariff.medium_type === 'GAS'){
                    allow_labelling_delete = true;
                }
            }
        }
        let contract_templates = this.get_matching_templates();
        return (
            <>
                {this.state.alert}
                <AddTariffHeader>
                    <div className='table-centered'>
                        {validateVar(tariff.name) ? <h2 className='h2 light mb-0 d-inline-block mr-3'>Tarif bearbeiten - {tariff.name}</h2> : <span className='pr-2'><Skeleton width={320} height={30}/></span>}
                        {validateVar(tariff.lifecycle_status) ? <Badge value={tariff.lifecycle_status} color={tariff.status_color} /> : <Skeleton width={160} height={30}/>}
                    </div>
                </AddTariffHeader>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody className='py-1'>
                                    <MaterialUIStepper onRedirect={(url) => this.props.redirect(url)} active={2} tariffId={this.id} treeId={tariff.tree_id} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h3 className='mb-0'>Dokumente</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <InputHandler
                                                content_title='Vertragsmuster'
                                                type='select'
                                                name='selectedContractID'
                                                value={selectedContractID}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                select_options={contract_templates}
                                                error_state={contract_template_id_error}
                                            />
                                        </Col>
                                        <Col>
                                            <InputHandler
                                                content_title='Vertragstyp'
                                                type='select'
                                                name='sign_type'
                                                value={sign_type}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                remove_empty_option
                                                select_options={[{key: 'ONLINE', value: 'Online'},
                                                    {key: 'OFFLINE', value: 'Offline'},
                                                    {key: 'E_SIGNATURE', value: 'Offline + E-Signatur'}]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='down-up-load-contract flex-row d-flex'>
                                        <Col sm={12} md={6}>
                                            <InputHandler
                                                content_title='AGB'
                                                type="file_upload_new"
                                                name='agb'
                                                value={agb}
                                                file_id={tariff.agb_file_id}
                                                public_static={1}
                                                file_type="pdf" // image / pdf / excel -> default
                                                upload_function={{
                                                    class: 'tariff',
                                                    function_name: 'uploadFile',
                                                    dispatch_type: 'UPDATE_TARIFF',
                                                    params: {tariff_id: tariff.id, type: 'agb'}
                                                }}
                                                error_state={agb_error}
                                                upload_finished={agb_file_uploaded}
                                                upload_file={true}
                                                is_loaded={is_loaded}
                                                allow_reupload={true}
                                                allow_download={true}
                                                dynamic_action_call={dynamic_action_call}
                                                default_file_upload={default_file_upload}
                                            />
                                        </Col>
                                        <Col>
                                            <InputHandler
                                                content_title='Kennzeichnung'
                                                type="file_upload_new"
                                                name='labelling'
                                                value={labelling}
                                                file_id={tariff.labelling_file_id}
                                                file_type="images" // image / pdf / excel -> default
                                                upload_function={{
                                                    class: 'tariff',
                                                    function_name: 'uploadFile',
                                                    dispatch_type: 'UPDATE_TARIFF',
                                                    params: {tariff_id: tariff.id, type: 'labelling'}
                                                }}
                                                delete_function={{
                                                    class: 'tariff',
                                                    function_name: 'update',
                                                    dispatch_type: 'UPDATE_TARIFF',
                                                    params: {tariff_id: tariff.id, labelling_file_id: -1}
                                                }}
                                                error_state={labelling_error}
                                                public_static={1}
                                                optional={(tariff.medium_type === "GAS")}
                                                upload_finished={labelling_file_uploaded}
                                                upload_file={true}
                                                is_loaded={is_loaded}
                                                allow_delete={allow_labelling_delete}
                                                allow_reupload={true}
                                                allow_download={true}
                                                dynamic_action_call={dynamic_action_call}
                                                default_file_upload={default_file_upload}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <PrimaryButton id='primary_button' onClick={this.handle_submit} disabled={disable_submit || !is_loaded || submit_loading} loading={submit_loading} value='Weiter' classes='full-page-submit' />
                            <GreyLinkButton disabled={!is_loaded} text='Zurück' classes='mt-4 mb-3 pb-2 eless-font-bold' onClick={this.handle_retrun} />
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

let mapStateToProps = function(state) {
    return {
        tariff: state.tariffs.tariff,
        contract_templates: state.contract_templates,
        redirect: state.redirect
    }
};

let mapDispatchToProps = {
    onInitialize: initializeTariff,
    onUpdateTariff: updateTariff,
    onInitTmpList: initTmpList,
    onResetRedirect: resetRedirect,
    redirect: redirect,
    //FileUpload requests
    dynamic_action_call: dynamic_action_call,
    default_file_upload: upload_file_new,
};

let TariffFilesContainer = connect(mapStateToProps, mapDispatchToProps)(TariffHandleFileData);

export default TariffFilesContainer;

