import React from 'react';
import Skeleton from "react-loading-skeleton";
import validateVar from "../../Functions/validation/validateVariable";

class NotificationsValue extends React.Component {
    render(){
        let {value, is_loaded, text} = this.props;
        if (!validateVar(value)) value = 0;
        let tool_tipp = (+value === 0) ? "Sie haben keine unbearbeiteten " + text :  "Sie haben unbearbeitete " + text  ;
        return (is_loaded) ? <div className={"position-relative"} tabIndex="0" data-toggle="tooltip" title={tool_tipp}>{text} <span className='notifications-count ml-2'>{value}</span></div> : <Skeleton width={10} height={20}/>
    }
}
export default NotificationsValue;