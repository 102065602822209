let initialState = null;

function upload_load_profiles(state = initialState, action) {
  if (action.type === "UPLOAD_LP") {
    return action.res;
  } else {
    return state;
  }
}

export default upload_load_profiles;
