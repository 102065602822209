import React from 'react';
import {Card, CardBody, CardHeader, Modal} from "reactstrap";
import CardHeaderText from "../../../module_components/PageContent/CardHeaderText";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import InputHandler from "../../../module_components/Inputs/InputHandler";
import validateVar from "../../../module_components/Functions/validation/validateVariable";
import formatPriceForApi from "../../../module_components/Functions/formatPriceForApi";
import is_true from "../../../module_components/Functions/is_true";
import is_false from "../../../module_components/Functions/is_false";
import compare_objects from "../../../module_components/Functions/compare_objects";

class ChangeOfferPriceModal extends React.Component {
    state = {
        price_rate: '',
        old_price_rate: null,
        socket: '',
        old_socket: null,
        error_messages: {},
        show_modal: false,
    };

    toggle_modal = () => {
        this.setState({show_modal: !this.state.show_modal})
    };

    turn_off_modal(){
        //KZ / 08.03.2022 / state has to be reseted here to show the placeholder values inside the inputs after submit
        this.setState({show_modal: false, price_rate: '', socket: ''});
    };

    update_state = (state_name, value) => {
        this.setState({[state_name]: value});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {offer} = this.props
        let {old_price_rate, old_socket} = this.state

        if(validateVar(offer) && prevProps.offer !== offer){
            if(!validateVar(old_price_rate) && !validateVar(old_socket)){
                let energy_price = offer.price_type === 'energy_price';
                if(is_true(energy_price) && validateVar(offer.price_rate)) old_price_rate = offer.price_rate;
                else if(is_false(energy_price) && validateVar(offer.contract_price_gross)) old_price_rate = offer.contract_price_gross;
                if(validateVar(offer.socket)) old_socket = offer.socket;
                this.setState({old_price_rate: old_price_rate, old_socket: old_socket})
            }
        }

        // KZ / 14.03.2022 / after offer got updated, also update the states to show the updated placeholder values
        if(validateVar(prevProps.offer) && !compare_objects(prevProps.offer, offer)){
            this.setState({old_price_rate: offer.price_rate, old_socket: offer.socket});
        }
    }

    handleSubmit = () => {
        const {price_rate, socket} = this.state;
        const {offer} = this.props;
        // validation start
        // define special cases for error validation
        let params = {offer_result_id: offer.id, tariff_type: offer.tariff_type, options: {with_prediction: 1}};
        let energy_price = offer.price_type === 'energy_price';
        if(is_true(energy_price) && validateVar(price_rate)) params.price_rate = formatPriceForApi(price_rate);
        if(is_false(energy_price) && validateVar(price_rate)) params.contract_price = formatPriceForApi(price_rate);
        if(validateVar(socket)) params.socket = formatPriceForApi(socket);
        this.props.on_submit(params);
        this.turn_off_modal();
    };

    render() {
        const {price_rate, socket, old_price_rate, old_socket, error_messages, show_modal} = this.state;
        const {offer} = this.props;
        let energy_price = offer.price_type === 'energy_price';
        let business = offer.customer_type_tariff_display === 'Gewerbekunde';

        return(<Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={show_modal}
            toggle={() => this.toggle_modal()}
        >
            <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent modal-title">
                        <CardHeaderText title='Angebot bearbeiten' />
                    </CardHeader>
                    <CardBody className="px-lg-4 py-lg-4">
                        <InputHandler
                            content_title={energy_price ? "Energiepreis (Netto)" : is_true(business) ? 'Arbeitspreis (Netto)' : 'Arbeitspreis (Brutto)'}
                            type="text"
                            name="price_rate"
                            placeholder={old_price_rate}
                            value={price_rate}
                            disabled={false}
                            on_change={(name, value) => this.update_state(name, value)}
                            error_state={error_messages.price_rate_error}
                            input_group_text={'ct / kWh'}
                            no_margin
                        />
                        {offer.total_surcharge_without_supplier > 0 ? <div className='padding-for-alert-icon static-warning-notification mb-4'>
                            <div className='warning-alert-icon'>Bitte beachten Sie bei der Bearbeitung des Preises, dass
                                eine Provision von {offer.total_surcharge_without_supplier_display} berücksichtigt
                                werden muss.
                            </div>
                        </div> : <div className={'mb-4'}/>}

                        <InputHandler
                            content_title={energy_price || business ? 'Grundgebühr (Netto)' : 'Grundgebühr (Brutto)'}
                            type="text"
                            name="socket"
                            placeholder={old_socket}
                            value={socket}
                            disabled={false}
                            on_change={(name, value) => this.update_state(name, value)}
                            error_state={error_messages.socket_error}
                            input_group_text={'€ / Monat'}
                            no_margin
                        />
                        {offer.total_socket_surcharge > 0 && <div className='padding-for-alert-icon static-warning-notification mb-4'>
                            <div className='warning-alert-icon'>Bitte beachten Sie bei der Bearbeitung der Grundgebühr,
                                dass eine Provision von {offer.total_socket_surcharge_yearly_display} berücksichtigt
                                werden muss.
                            </div>
                        </div>}

                        <PrimaryButton id='saveSettings' onClick={() => this.handleSubmit()} value='Speichern' classes='btn-modal w-100 mt-4' />
                    </CardBody>
                </Card>
            </div>
        </Modal>)
    }
}

export default ChangeOfferPriceModal;