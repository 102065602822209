/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "cropperjs/dist/cropper.css";
import "react-image-lightbox/style.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
import AdminLayout from "./module_components/Layouts/Admin.jsx";
import AuthLayout from "./module_components/Layouts/Auth.jsx";

// Manager imports
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./module_components/Reducer";
import getUserRole from "./module_components/Functions/getUserRole";
import checkEvuCredentials from "./module_components/Functions/checkUserLogin";

//Fancy React Components addon
import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import checkGuestUrl from "./module_components/Functions/checkGuestUrl";

const PrivateRoute = ({ component: Component, ...rest }) => {
  var authentication = getUserRole();
  return (
    <Route
      {...rest}
      render={props =>
        authentication === "admin" ||
        authentication === "manager" ||
        authentication === "user" ? (
          <AdminLayout {...props} />
        ) : (
          <AuthLayout denied={true} {...props} />
        )
      }
    />
  );
};

// https://github.com/zalmoxisus/redux-devtools-extension#usage
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

if (!checkGuestUrl()) {
  checkEvuCredentials();
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <PrivateRoute path={"/:path"} />
        <Redirect from="/" to={"/auftraege"} />
      </Switch>
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);
