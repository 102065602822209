import React from "react";
import {CardHeader, Col, Row, CardBody} from "reactstrap";
import InputHandler from "../../../Inputs/InputHandler";
import {connect} from "react-redux";
import validateVar from "../../../Functions/validation/validateVariable";
import {get_price_table_templates, update_price_table_values, init_price_table_values} from "../../../../actions";
import Validation from "../../../Objects/Components/validation";
import is_false from "../../../Functions/is_false";
import isEmptyObject from "../../../Functions/isEmptyObject";
import get_standardised_date from "../../../Functions/get_standardised_date";

class TariffPriceTable extends React.Component {
    constructor(props) {
        super(props);
        let default_date = new Date();
        this.min_date = new Date();
        let max_date = new Date();
        max_date.setFullYear(max_date.getFullYear() +5);
        this.max_date = max_date;
        default_date.setMinutes(0);
        default_date.setHours(default_date.getHours() + 24); // add default + 24 hours
        this.state = {
            price_table_file: {},
            is_loaded: false,
            input_disabled: false,
            template_options: [],
            template_value_is_loaded: false,
            page_values: {
                template_id: null,
                date: default_date,
                type: 'net',
                file_id: null,
                file_name: null,
                error_messages: {
                    template_id_error: false,
                    date_error:false,
                    type_error: false,
                    file_id_error: false,
                },
            }
        };
    }

    componentDidMount(){
        let {tariff} = this.props;
        let {page_values} = this.state; // use default data
        let page_values_tmp = {
            template_id: tariff.price_template_id,
            date: new Date(get_standardised_date(tariff.price_valid_until)),
            file_name: tariff.price_table_file_name,
            type: tariff.price_type_matrix,
            file_id: tariff.price_file_id,
        };
        let validation_res = new Validation(page_values_tmp, {});
        if (is_false(validation_res.has_error)) page_values = page_values_tmp; // if valid data is loaded set default values to loaded data

        let params = { // get filter params for possibille table_templates
            options: {
                is_electricity: (tariff.medium_type === "ELECTRICITY") ? 1 : 0,
                is_gas: (tariff.medium_type === "GAS") ? 1 : 0,
                is_rlm: (tariff.is_rlm === "YES") ? 1 : 0,
                is_slp: (tariff.is_slp === "YES") ? 1 : 0,
                is_private: (tariff.customer_type === "PRIVATE") ? 1 : 0,
                is_business: (tariff.customer_type === "BUSINESS") ? 1 : 0,
                is_green: (tariff.energy_source === "green") ? 1 : 0
            }
        };
        this.setState({is_loaded: true}); // tariff is loaded
        this.props.get_price_table_templates(params); // init pricing templates
        this.props.init_price_table_values(page_values); // write data in reducer (needed if you submit to early)
    }

    componentDidUpdate(prevProps) {
        const {tariff_obj} = this.props;
        let {page_values} = this.state;
        if (validateVar(tariff_obj.pricing_templates) && tariff_obj.pricing_templates !== prevProps.tariff_obj.pricing_templates && !validateVar(prevProps.tariff_obj.pricing_templates)) {
            this.setState({
                template_value_is_loaded: true,
                price_templates: tariff_obj.pricing_templates,
                template_id: (validateVar(tariff_obj.tariff.price_template_id)) ? tariff_obj.tariff.price_template_id : null
            });
        }

        if(validateVar(tariff_obj.pricing_values) && tariff_obj.pricing_values !== prevProps.tariff_obj.pricing_values && validateVar(tariff_obj.tariff.price_template_id)){
            let page_values_loaded = tariff_obj.pricing_values;
            page_values_loaded.error_messages = page_values.error_messages;

            this.setState({
                page_values: page_values_loaded,
            });
        }
    }

    update_state = (name, value) => {
        let {page_values} = this.state;
        let {error_messages} = page_values;
        if (name === "file_object") {
            page_values.file_id = value.file_id;
            page_values.file_name = value.file_name;
            error_messages.file_name_error = false;
            error_messages.file_id_error = false;
        } else {
            page_values[name] = value;
            let error_message_name = name + "_error";
            error_messages[error_message_name] = false;
        }
        page_values.error_messages = error_messages;
        this.props.update_price_table_values(page_values);
    };

    render() {
        const {is_loaded_page, dynamic_action_call, default_file_upload} = this.props;
        const {price_table_file, input_disabled, template_value_is_loaded, price_templates, page_values} = this.state;
        let {type, template_id, date, error_messages, file_name, file_id} = page_values;
        if (validateVar(price_table_file) && !isEmptyObject(price_table_file)) file_name = price_table_file.name;

        return <>
            <CardHeader className={'pb-2'}>
                <Row>
                    <Col>
                        <h3>Tarifbezogene Preistabelle</h3>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className='pt-4'>
                <Row>
                    <Col>
                        <InputHandler
                            content_title='Preistabellen-Datei'
                            type="file_upload_new"
                            name='price_table_file'
                            value={file_name}
                            file_type="xlsx" // image / pdf / excel -> default
                            upload_function={{params: {type: 'pricing_tables'}}}
                            public_static={0}
                            download_function={true}
                            file_id={file_id}
                            return_file_id={(file_object) => this.update_state("file_object", file_object)}
                            error_state={error_messages.file_id_error}
                            upload_file={(file) => this.update_state("price_table_file", file)}
                            is_loaded={is_loaded_page}
                            allow_reupload={true}
                            allow_download={true}
                            dynamic_action_call={dynamic_action_call}
                            default_file_upload={default_file_upload}
                        />
                    </Col>
                    <Col>
                        <InputHandler
                            content_title='Angegebene Preisgrundlage'
                            type='radio'
                            name='type'
                            first_radio={{id: 'gross', text: 'Brutto', value: 'gross'}}
                            second_radio={{id: 'net', text: 'Netto',  value: 'net'}}
                            value={type}
                            is_loaded={is_loaded_page}
                            on_change={(name, value) => this.update_state(name, value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputHandler
                            content_title='Tabellenformat'
                            type='select'
                            name='template_id'
                            value={template_id}
                            on_change={(name, value) => this.update_state(name, value)}
                            is_loaded={template_value_is_loaded && is_loaded_page}
                            select_options={price_templates}
                            error_state={error_messages.template_id_error}
                        />
                    </Col>
                    <Col>
                        <InputHandler content_title='Gültigkeit'
                            type="date"
                            id='date'
                            name='date'
                            min_date={this.min_date}
                            max_date={this.max_date}
                            value={date}
                            disabled={input_disabled}
                            on_change={(name, date) => this.update_state(name, date)}
                            error_state={error_messages.date_error}
                            show_month_dropdown={true}
                            show_year_dropdown={true}
                            dropdown_mode='select'
                            classes='form-control w-100'
                            is_loaded={is_loaded_page}
                            show_time={true}
                        />
                    </Col>
                </Row>
            </CardBody>
        </>
    }
}


let mapStateToProps = function(state)   {
    return {
        tariff_obj: state.tariffs,
        pricing: state.pricing,
    }
};

let mapDispatchToProps = {
    get_price_table_templates: get_price_table_templates,
    upload_file: get_price_table_templates,
    update_price_table_values: update_price_table_values,
    init_price_table_values: init_price_table_values,

};

let TariffPriceTableContainer = connect(mapStateToProps, mapDispatchToProps)(TariffPriceTable);

export default TariffPriceTableContainer;
