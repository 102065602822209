import * as ERROR_CODE from "../Errors/error_codes";
import validateVar from "../Functions/validation/validateVariable";
import {displaySuccessMessage, displayErrorMessage} from "./user_feedback";
import {
    apiError
} from "../Functions/global";
import {LoginPath, MyTasks} from "../../globalNavigation";
import {hashVar} from "../Functions/handleHashedVariable";
import Employee from "../Objects/employees";
import logoutEmployee from "../Functions/supplierActions";
import is_true from "../Functions/is_true";

export function affiliate_login(function_name ,params, url, file) {
    return function (dispatch) {
        Employee.affiliate_login(function_name ,params, url, file).then(res => {
            if (res !== apiError && res !== "Permission") {
                logoutEmployee(false); // remove all data in localStorage without redirect
                let agencyID = res.agency;
                let customerID = res.customer_id;
                let userRole = res.roles[0].name;
                let status = res.roles[0].pivot.status;
                if (status !== "active") {
                    let message = "Bitte aktivieren Sie Ihren Account, loggen Sie sich hierzu in Ihren Mail Account ein und drücken auf den von uns zugesandten Link.";
                    let headline = "Nutzer nicht aktiviert";
                    dispatch({type: "ERROR_EMPLOYEE", res: "ERR"});
                    if (status === "inactive") {
                        message = "Ihr Account wurde deaktiviert. Derzeit ist es Ihnen nicht möglich sich einzuloggen";
                        headline = "Account deaktiviert";
                    }
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displayErrorMessage(
                            message, // content
                            headline, //headline
                            null, // url,
                            false, //automatic redirect
                            true,       //modal
                            null, // primaryBtnText
                            "Bestätige",// secondaryBtnText
                            true, //allowToggle (user can close modal without pressing a btn)
                            false,// successIcon
                            true,// infoIcon
                            "danger" //type - danger/success
                        ),
                    });
                } else {
                    let hashedAgencyID = hashVar(agencyID);
                    let hashedCustomerID = hashVar(customerID);
                    let hashedRole = hashVar(userRole);

                    localStorage.setItem("consultantSecurityToken", hashedAgencyID); // agency_id = consultant_id
                    localStorage.setItem("consultantEmployeeSecurityToken", hashedCustomerID); // customer_id = employee_id
                    localStorage.setItem("consultantUserAccount", hashedRole);

                    //login successful redirect to user page
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "", // content
                            "", //headline
                            MyTasks, // url,
                            true, //automatic redirect
                            false,       //modal
                        ),
                    });


                    // dispatch({type: "LOGIN_EMPLOYEE", res: hashedAgencyID});
                }
            } else if (res === "Permission") {
                dispatch({type: "ERROR_EMPLOYEE", res: apiError});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Kombination aus Passwort und E-Mail scheint nicht zu stimmen. Falls Sie Ihr Passwort vergessen haben sollten, klicken Sie auf 'Passwort vergessen'.", // content
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        false,       //modal
                    ),
                });
            } else {
                dispatch({type: "ERROR_EMPLOYEE", res: apiError});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Aufgrund von Wartungsarbeiten ist der Login derzeit leider nicht verfügbar. Bitte versuchen Sie es später erneut.", // content
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function platform_employee(function_name , params, method, is_list, url, file) {
    return function (dispatch) {
        Employee.platform_employee(function_name, params, method, is_list, url, file).then(res => {
            if (res !== apiError){
                if (is_true(is_list)){ // return list
                    if(method === 'PUT'){
                        if (validateVar(url) && url === LoginPath) { //todo bei update employee beim registrieren laufen wir in dieses case rein, sollen wir das anders strukturieren?
                            dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(
                                    "Ihr Benutzerkonto wurde erfolgreich angelegt.", // content
                                    "Erfolg!", //headline
                                    url, // url,
                                    false, //automatic redirect
                                    true,       //modal
                                    'zum Login',
                                    null
                                )});
                        }else dispatch({type: "UPDATE_EMPLOYEE_ARRAY", res: res});
                    }else{
                        dispatch({type: "INITIALIZE_EMPLOYEE_LIST", res: res});
                        if(method === 'POST'){
                            dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(
                                    "Eine Einladung wurde an die angegebene E-Mail Adresse verschickt.", // content
                                    "Erfolg", //headline
                            )});
                        }
                    }
                } else {
                    if(method === 'PUT'){
                        dispatch({type: "UPDATE_EMPLOYEE", res: res}); // update single employee
                    }else{
                        dispatch({type: "INITIALIZE_EMPLOYEE", res: res}); // return single employee
                    }
                }
            }else {
                if (is_true(is_list) && url !== LoginPath)dispatch({type: "INITIALIZE_EMPLOYEE_LIST", res: []});  // show empty table if error
                if (url === LoginPath)dispatch({type: "EMPLOYEE_RESET", res: apiError});
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Mitarbeiterdaten konnte nicht geladen werden. (Fehlercode: " + ERROR_CODE.GetEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}

export function activate_employee(params) {
    return function(dispatch) {
        Employee.activate_employee(params).then(res => {
            if (res !== apiError) {
                dispatch({type: "ACTIVATE_EMPLOYEE", res: res});
            } else {
                dispatch({type: "SET_USER_MESSAGE", res: displayErrorMessage('Ihr Account konnte nicht aktiviert werden, versuchen Sie es bitte später noch einmal. (Fehlercode: '+ERROR_CODE.UpdateEmployeeError+')')});
                dispatch({type: "ERROR_EMPLOYEE", res: apiError});
            }
        });
    };
}

export function password_forgotten(function_name, params, method) {
    return function(dispatch) {
        Employee.password_forgotten(function_name, params, method).then(res => {
            if (res !== apiError) {
                dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(
                        "Ihnen wurde eine Mail zum zurücksetzen Ihres Passworts zugesendet. Bitte prüfen Sie Ihr Postfach.", // content
                        "Erfolg!", //headline
                        null, // url,
                        false, //automatic redirect
                        false,       //modal
                    )});
                dispatch({type: "LOGIN_EMPLOYEE", res: res}); //end loading animation
            } else {
                dispatch({type: "SET_USER_MESSAGE", res: displayErrorMessage('Es ist ein Fehler beim versenden der E-Mail aufgetreten. Überprüfen Sie bitte Ihre Eingabe noch einmal. (Fehlercode: '+ERROR_CODE.ResetPasswordError+')')});
                dispatch({type: "ERROR_EMPLOYEE", res: apiError});
            }
        });
    };
}

export function reset_password(function_name, params, method) {
    return function(dispatch) {
        Employee.reset_password(function_name, params, method).then(res => {
            if (res !== apiError) {
                dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(
                        "Passwort erfolgreich geändert.", // content
                        "Erfolg!", //headline
                        LoginPath, // url,
                        false, //automatic redirect
                        true,       //modal
                        'zum Login',
                        null
                    )});
            } else {
                dispatch({type: "SET_USER_MESSAGE", res: displayErrorMessage('Es ist ein Fehler aufgetreten. Ggf müssen Sie eine neue E-Mail anfordern. (Fehlercode: '+ERROR_CODE.SetNewPasswordError+')')});
                dispatch({type: "ERROR_EMPLOYEE", res: apiError});
            }
        });
    };
}

export function update_employee_status(function_name, employees) {
    return function(dispatch) {
        Employee.update_employee_status(employees).then(res => {
            let updated_employee = res[0];
            let error_count = res[1];
            if (res !== apiError && error_count === 0) {
                dispatch({type: "UPDATE_EMPLOYEE_ARRAY", res: updated_employee});
            }else if (res !== apiError && error_count > 0) {
                dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(
                        updated_employee.length + " von " + employees.length + " Accounts konnten geupdated werden.", // content
                        "Erfolg", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        true,// infoIcon
                    )});
                dispatch({type: "UPDATE_EMPLOYEE_ARRAY", res: updated_employee});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Mitarbeiterdaten konnten nicht aktualisiert werden. (Fehlercode: " + ERROR_CODE.UpdateEmployeeError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
                dispatch({type: "RETURN_EMPLOYEE_STATE"});
            }
        });
    };
}

// export function initializeEmployees() {
//     return function (dispatch) {
//         Employee.instantiateList().then(res => {
//             if (res !== $.apiError)
//                 dispatch({type: "INITIALIZE_EMPLOYEE_LIST", res: res});
//             else {
//                 dispatch({type: "INITIALIZE_EMPLOYEE_LIST", res: []});
//                 dispatch({
//                     type: "SET_USER_MESSAGE",
//                     res: displayErrorMessage(
//                         "Die Mitarbeiterlisteee konnte nicht geladen werden. (Fehlercode: " + ERROR_CODE.GetEmployeeListError + ")", // content
//                         "Fehler", //headline
//                         null, // url, // url
//                         false, //automatic redirect
//                         true,       //modal
//                         null, // primaryBtnText
//                         "Bestätige",// secondaryBtnText
//                         true, //allowToggle (user can close modal without pressing a btn)
//                         false,// successIcon
//                         false,// infoIcon
//                         "danger" //type - danger/success
//                     ),
//                 });
//             }
//         });
//     };
// }



