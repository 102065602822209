import {
  apiCalculationManagementService,
  apiError,
  apiFileSystemService,
  apiFunctionDownloadCalculation,
  apiFunctionReturnResource,
  apiFunctionTestTariff,
  apiPricingService
} from "../Functions/global";
import Tariff from "./tariff";
import validateVar from "../Functions/validation/validateVariable";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";

class TestCalculation {
  /*
              Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
              Call Communication Class with matching global defined Vars for API Request
              return Array with all TariffCalculatedContainer Obj
         */
  static async calculation_test(params) {
    params = getAgencyID(params);
      let communication_params = {
          service: apiCalculationManagementService,
          get_function: apiFunctionTestTariff,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      this.c = getCommunicationClass(communication_params);


    var res = await this.c.load(params);
    let data = res.result;
    if (res.status === "ok") {
      if (res.result === "No result") return "No result";

      if (data.hasOwnProperty("supplier")) {
        if (
            data.supplier.hasOwnProperty("information") &&
            typeof validateVar(data.supplier.information.logo_file_id)
        ) {
          let logo_id = data.supplier.information.logo_file_id;
          // add the logo url to the result obj
          var logo_url = await TestCalculation.getDownloadUrl(logo_id);
        }
      }

      if (
          data.hasOwnProperty("tariff_information") &&
          typeof validateVar(data.tariff_information.labelling_file_id)
      ) {
        let label_id = data.tariff_information.labelling_file_id;
        var label_url = await TestCalculation.getDownloadUrl(label_id);
      }
      //add all files for this tariff
      res.result.files = {
        logo_url: logo_url,
        label_url: label_url
      };

      return new Tariff(res.result);
    } else return apiError;
  }

  static async getDownloadUrl(logo_id) {
      let communication_params = {
          service: apiFileSystemService,
          get_function: "",
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: apiFunctionReturnResource,
          params: null,
      };
      this.c = getCommunicationClass(communication_params);
      return await this.c.download({ id: logo_id }, "image/png", true);
  }

  download(res_id) {
      let communication_params = {
          service: apiPricingService,
          get_function: "",
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: apiFunctionDownloadCalculation,
          params: null,
      };
      this.c = getCommunicationClass(communication_params);
      return this.c.download({
        offer_result_id: res_id,
        data_type: "download"
      });
  }
}

export default TestCalculation;
