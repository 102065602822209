import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";

let company_information_vars = {
    logo_file_id: null,
    brochure_file_id: null,
    description: {
        description: null,
        company_description: null,
    },
    foundation_year: null,
    employee_count: {
        employee_count: null,
        employee_count_display: {
            function_name: "handleFormatNumber"
        }
    },
    customer_count: {
        customer_count: null,
        customer_count_display: {
            function_name: "handleFormatNumber"
        }
    },
    sales_revenue: {
        sales_revenue: null,
        sales_revenue_display: {
            function_name: "handleFormatNumber"
        }
    },
    status_as: null,
    favicon_id: null,
    locale: null,



};

class Company_Information {
    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(company_information_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }
}

export default Company_Information;
