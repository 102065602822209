let initialState = null;

function clean_tree(state = initialState, action) {
  if (action.type === "CLEAN_TREES") {
    return action.res;
  } else if (action.type === "RESET_CLEAN_TREE") {
    return action.res;
  } else {
    return state;
  }
}

export default clean_tree;
