import React from "react";
import Button from "reactstrap/lib/Button";
import ButtonLoadingAnimation from "../../LoadingAnimations/ButtonLoadingAnimation";
import PropTypes from 'prop-types';

const TooltipButton = (props) => {
    var classes = '';
    if(typeof props.classes !== 'undefined') classes = props.classes;

    return(
        <Button disabled={props.disabled} onClick={props.onClick} className={'m-0 btn eless-btn '+classes}>
            <span className={props.hitboxClasses}>{props.value} {props.loading && <ButtonLoadingAnimation id={props.id +'Loading'} />}</span>
        </Button>)
};

TooltipButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.string.isRequired,
    id: PropTypes.string,
    classes: PropTypes.string,
};

export default TooltipButton;