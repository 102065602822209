import validateVar from "./validation/validateVariable";
import {emptyValue} from './global';

function getBadgeColor(status, green_arr = [], orange_arr = [], red_arr = [], blue_array = [], pink_array = []){
    if(validateVar(status) && status !== emptyValue){
        if (green_arr.includes(status)) return "green";
        else if (orange_arr.includes(status)) return "orange";
        else if (red_arr.includes(status)) return "red";
        else if (blue_array.includes(status)) return "blue";
        else if (pink_array.includes(status)) return "pink";
        else return 'gray';
    }else return 'gray'
}
export default getBadgeColor;