import React from 'react';
import SimpleHeader from "../../../module_components/Displays/Headers/SimpleHeader";
import {
    Card,
    CardBody,
    CardHeader, Col,
    ListGroup, Row
} from "reactstrap";
import Container from "reactstrap/lib/Container";
import {ActiveIntegrations} from "../../../globalNavigation";
import {initializeIntegrationList, addIntegration} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import IntegrationsListItem from "./Components/IntegrationsListItem";
import {deHashVar} from "../../../module_components/Functions/handleHashedVariable";
const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));


class IntegrationsList extends React.Component{

    state = {
        fadeClass: 'wait-for-fade',
        toggleAnimation: false,
    };

    componentDidMount() {
        this.props.initialize();
        this.integrationIdAdded = null;
    }

    addCompany = (data, integration) => {
        this.integrationIdAdded = integration.id; //save the id so we can animate the right integration from the list on rerender
        this.props.add(data, integration, supplier_id);
        this.setState({toggleAnimation: true});
    };

    fadeInContent = () => {
        if(this.state.fadeClass === 'wait-for-fade') this.setState({fadeClass: 'fade-in'});
    };

    animateAndRedirect = () => {
        this.setState({fadeClass: 'fade-out'});
        setTimeout(() => {
            window.location.href = ActiveIntegrations;
        }, 500) ;
    };

    render() {
        let integration_items = this.props.integration_list;

        return (
            <>
                <SimpleHeader name="Integrationen" headerText='Hier finden Sie eine Übersicht Ihrer Integrationen.'/>
                <Container className={"mt--6 dashboard-content "+this.state.fadeClass} fluid>
                    {this.fadeInContent()}
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col sm={10}>
                                    <h5 className="h3 mb-0">Integrationen hinzufügen</h5>
                                </Col>
                                <Col sm={2} className='text-right'><i onClick={() => this.animateAndRedirect()} className="fas fa-times close-integrations" /></Col>
                            </Row>
                        </CardHeader>
                        <CardHeader className="py-0">
                            {/*} <Form> //falls suche gefragt wird
                                <FormGroup className="mb-0">
                                    <InputGroup className="input-group-lg input-group-flush">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <span className="fas fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="Suche" type="search" />
                                    </InputGroup>
                                </FormGroup>
                            </Form> */}
                        </CardHeader>
                        { integration_items ?
                        <CardBody className='pb-5 mb-5'>
                            <ListGroup className="list my--3 integrations" flush>
                                {integration_items.map((integration_item, i) => {
                                    let toggleAnimation = false;
                                    if(this.integrationIdAdded !== null && this.state.toggleAnimation){
                                        if(this.integrationIdAdded === integration_item.id) toggleAnimation = true;
                                    }
                                    return <IntegrationsListItem toggleAnimation={toggleAnimation} add={(data, item) => this.addCompany(data, item)} item={integration_item} key={i} arrayKey={i} />
                                })}
                            </ListGroup>
                        </CardBody> : <></>}
                    </Card>
                </Container>
            </>
        )
    }
}

let mapStateToProps = function(state)   {
    return {
        integration_list: state.integration,
    }
};

let mapDispatchToProps = {
    initialize: initializeIntegrationList,
    add: addIntegration
};

let IntegrationsListContainer = connect(mapStateToProps, mapDispatchToProps)(IntegrationsList);

export default IntegrationsListContainer;