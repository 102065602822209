import validateVar from "./validation/validateVariable";
import { logoutUser } from "./consultantActions";
import { deHashVar } from "./handleHashedVariable";
import {plattform_data_array, project_names} from "./global";

//evu:  evuSecurityToken && employeeSecurityToken
//enermakler:  consultantSecurityToken && consultantEmployeeSecurityToken

function checkEvuCredentials() {
  /* SESSION ID CHECK */
  let storageSecurityToken = ''
  let storageEmployeeSecurityToken = ''
  switch(process.env.REACT_APP_PROJECT_NAME){ //local storage variables are named differently in different projects
      case project_names.ehub:
          storageSecurityToken = 'evuSecurityToken';
          storageEmployeeSecurityToken = 'employeeSecurityToken';
          break;
      case project_names.enermakler:
      case project_names.wattline:
          storageSecurityToken = 'consultantSecurityToken';
          storageEmployeeSecurityToken = 'consultantEmployeeSecurityToken';
          break;
      default:
          alert('Undefined project name in env');
          break;
  }

  let securityToken = parseInt(deHashVar(localStorage.getItem(storageSecurityToken)));
  let employeeSecurityToken = parseInt(deHashVar(localStorage.getItem(storageEmployeeSecurityToken)));
  if (checkID(securityToken) || checkID(employeeSecurityToken)) {
      logoutUser();
  }
}

function checkID(id){
  let invalid = false;
  if (!validateVar(id) || !Number.isInteger(id) ) invalid = true;
  return invalid;
}




function checkPlattformVars() {
    let vars_existing = true;
    plattform_data_array.map((item) => {
        if (validateVar(localStorage.getItem((item))))vars_existing = false;
    });
    return vars_existing;
}

export default checkEvuCredentials;
