import React from 'react';
import Skeleton from "react-loading-skeleton";
import FileSaver from 'file-saver';

class PrevStateDownloadText extends React.Component {

    render() {
        const {is_loaded, text, file_name, file_url} = this.props;

        return(<>{is_loaded ? <><span className='changes-detected-download' onClick={() => FileSaver.saveAs(file_url, file_name)}>!</span>
            {text}</> : <Skeleton width={250} height={30}/>}</>)
    }
}

export default PrevStateDownloadText;