/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    initializeCompanyEnermakler,
    handle_employee_actions,
    handle_team_actions
} from '../../Index/new_index';

// reactstrap components
import {
    Card,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import SimpleHeader from "../../Displays/Headers/SimpleHeader.jsx";
// custom components
import DropdownButton from "../../Displays/Buttons/DropdownButton";
import PrimaryButton from "../../Displays/Buttons/PrimaryButton";
import validateVar from "../../Functions/validation/validateVariable";
import getUserRole from "../../Functions/getUserRole";
import {adminRole, managerRole, project_name, project_names} from "../../Functions/global";
import Badge from "../../Displays/Badges/Badge";
import Table from "../../Displays/Tables/Table";
import getAgencyID from "../../Functions/getAgencyID";
import CreateUserModal from "./Modals/CreateUserModal";
import EditUserModal from "./Modals/EditUserModal";

const default_table_columns = [
    {
        dataField: "employee_id",
        text: "ID",
        sort: true,
        classes: 'linked-col',
    },
    {
        dataField: "email",
        text: "E-Mail",
        sort: true,
        classes: 'linked-col',
        style: {fontWeight: 'bold'}
    },
    {
        dataField: "full_name",
        text: "Name",
        sort: true,
        classes: 'linked-col',
    },
    {
        dataField: "title_display",
        text: "Rolle",
        sort: true,
        classes: 'linked-col',
    },
    {
        dataField: "status_display",
        text: "Status",
        formatter: (cell, object) =>  <Badge value={cell} color={object.status_color} />,
        sort: true,
        classes: 'linked-col'
    },
];

/*
    START CLASS
 */
class UserManagementTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdown_disabled: true,
            is_loaded: false,
            dropdownOptions: ['delete', 'deactivate', 'activate'],
            selected_ids: [],
            non_selectable: [],
            table_columns: default_table_columns
        };
        
        this.create_user_modal = React.createRef();
        this.edit_user_modal = React.createRef();
        this.table_input_modal = React.createRef();
    }

    componentDidUpdate(prevProps, prevState){
        let {employees, team_list, company} = this.props;
        if (validateVar(employees) && validateVar(company) && (company !== prevProps.company)) {
            if (validateVar(employees)) {
                if (!this.state.is_loaded) {
                    if(getUserRole() === managerRole){
                        let non_selectable = [];
                        for (let employee of employees){
                            if(employee.hasOwnProperty('title')){
                                if(employee.title === adminRole){
                                    non_selectable.push(employee.id);
                                }
                            }
                        }
                        this.setState({non_selectable: non_selectable})
                    }
                    if(project_name !== project_names.wattline && !company.features.includes('team')) this.setState({is_loaded: true}); //wattline requires team_list to be loaded
                    if(project_name === project_names.wattline || company.features.includes('team')) this.props.handle_team_actions("platform_team", getAgencyID({}, true, false), "GET");
                } else {
                    if(project_name === project_names.enermakler) this.props.initializeCompany();
                    this.create_user_modal.current.turn_off_modal();
                }
            }
        }

        if(validateVar(employees) && employees !== prevProps.employees){
            this.create_user_modal.current.turn_off_modal();
        }

        if(validateVar(company) && (project_name === project_names.wattline || company.features.includes('team'))){
            if(validateVar(team_list) && team_list !== prevProps.team_list) this.setState({is_loaded: true});
        }
    }

    componentDidMount() {
        let params = getAgencyID({}, true)
        params.options = {with_teams: 1};
        this.props.handle_employee_actions("platform_employee", params, 'GET', true);
    }

    row_selected = (selected_ids) => {
        let {dropdown_disabled} = this.state;
        let dropdown_options = ['delete'];

        if(selected_ids.length > 1) {
            this.setState({dropdown_options: ['delete']});

            let not_matching = false;
            let status = null;
            selected_ids.forEach(
                id => {
                    let selected_employee = this.props.employees.find(x => x.id === id);
                    let status_employee = selected_employee.status;
                    if (!validateVar(status)) status =  selected_employee.status;
                    if (status_employee !== status) not_matching = true;
                }
            );
            if (!not_matching){
                if (status === "active") dropdown_options = ['delete', 'deactivate'];
                else if (status === "inactive") dropdown_options = ['delete', 'activate'];
                else dropdown_options = ['delete'];
            }
        }else {
            let id = selected_ids[0];
            if(typeof id !== "undefined"){
                let selected_employee = this.props.employees.find(x => x.id === id);
                if(selected_employee.status === "active") dropdown_options = ['delete', 'deactivate'];
                else if (selected_employee.status === "inactive") dropdown_options = ['delete', 'activate'];
            }
        }

        //Enable / Disable options dropdown Button
        if(dropdown_disabled && selected_ids.length > 0) dropdown_disabled = false;
        else if(!dropdown_disabled && selected_ids.length === 0) dropdown_disabled = true;

        this.setState({dropdown_options: dropdown_options, selected_ids: selected_ids, dropdown_disabled: dropdown_disabled});
    };

    delete_from_table = () => {
      const {company} = this.props;
      let {selected_ids} = this.state;
      var selected_employees = [];
        selected_ids.forEach(
          id => {
              let selected_employee = this.props.employees.find(x => x.id === id);
              let params = {
                  role_id: selected_employee.title_id,
                  email: selected_employee.email
              };
              params = getAgencyID(params, true, false);
              if(project_name === project_names.wattline || company.features.includes('team')) params.options = {with_teams: 1};
              selected_employees.push(params);
          }
      );

      this.props.handle_employee_actions("platform_employee", selected_employees, "DELETE", true);
      this.row_selected([]);
    };

    change_status_from_table = () => {
        let {selected_ids} = this.state;

        var selected_employees = [];
        selected_ids.forEach(
            id => {
                let selected_employee = this.props.employees.find(x => x.id === id);
                selected_employees.push(selected_employee);
            }
        );
        this.props.handle_employee_actions("update_employee_status", selected_employees);
        this.row_selected([]);
    };

    toggle_create_user_modal = () => {
        if(this.create_user_modal.current !== null){
            this.create_user_modal.current.toggle_modal();
        }
    };

    toggle_edit_user_modal = (data) => {
        if(this.edit_user_modal.current !== null) {
            this.edit_user_modal.current.toggle_modal(data);
        }
    };

    add_employee_from_modal = (params) => {
        const {company} = this.props;
        if(project_name === project_names.wattline || company.features.includes('team')) params.options = {with_teams: 1};
        this.props.handle_employee_actions("platform_employee", params, "POST", true);
    };

    update_employee_from_modal = (params) => {
        params = getAgencyID(params, true, false);
        this.props.handle_employee_actions("platform_employee", params, "PUT");
        this.edit_user_modal.current.turn_off_modal();
    };
    
    get_table_name = (company) => {
        if(project_name === project_names.enermakler) {
            if(validateVar(company)) {
                if(company.features.includes('team')) return 'employees_with_team';
                else return 'enermakler_employees';
            }
        }
        if(project_name === project_names.ehub) return 'ehub_employees';
        if(project_name === project_names.wattline) return 'user';
        return null;
    }

    render() {
        const {company, employees, team_list} = this.props;
        const {is_loaded, dropdown_disabled, dropdown_options, selected_ids, table_columns} = this.state;

        return (
            <>
                <CreateUserModal company={company} team_list={team_list} add_employee={this.add_employee_from_modal} employees={employees} ref={this.create_user_modal} />
                <EditUserModal company={company} team_list={team_list} update_employee={this.update_employee_from_modal} ref={this.edit_user_modal} />
                <SimpleHeader name="Nutzerverwaltung" headerText={''} />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col>
                            <Card>
                                <Table table_name={this.get_table_name(company)}
                                    data={employees}
                                    key_field={'employee_id'}
                                    columns={table_columns}
                                    is_loaded={is_loaded}
                                    paginator_strings={["Nutzer", "Nutzern"]}
                                    empty_table_message={"Es gibt keine Nutzer"}
                                    selected_ids={selected_ids}
                                    on_checkbox_click={this.row_selected}
                                    break_width='700'
                                    linked_col_click={(row) => this.toggle_edit_user_modal(employees.find(x => x.id === row.id))}
                                    update_columns={(table_columns) => this.setState({table_columns: table_columns})}>
                                        <span className='responsive-button-wrapper'>
                                            <DropdownButton
                                                tooltipProp={"Nutzer"}
                                                disabled={dropdown_disabled}
                                                wrapper_classes='mr-3'
                                                include={dropdown_options}
                                                deleteSelected={() => this.delete_from_table()}
                                                deactivateSelected={() => this.change_status_from_table()}
                                                activateSelected={() => this.change_status_from_table()}
                                            />
                                        </span>
                                        <span className='responsive-button-wrapper'>
                                            <PrimaryButton disabled={false} onClick={() => this.toggle_create_user_modal()} value={'Nutzer hinzufügen'} classes='mr-3' />
                                        </span>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

let mapStateToProps = function(state)   {
    return {
        employees: state.employee,
        company: state.company,
        team_list: state.team_list,
    }
};

let mapDispatchToProps = {
    initializeCompany: initializeCompanyEnermakler,
    handle_employee_actions: handle_employee_actions,
    handle_team_actions: handle_team_actions,
};

let UserManagementContainer = connect(mapStateToProps, mapDispatchToProps)(UserManagementTable);

export default UserManagementContainer;