
function handleCssVariable(variable, css_format = true, default_format = false){
    if (css_format) {
        return '--' + variable.replace(/_/g, "-");
    }
    else if (default_format) {
        let name_translated = variable.replace(/-/g, "_");
        return name_translated.replace("__", "");
    }
    else {
        alert("Fehler");
    }

}
export default handleCssVariable;