import React from 'react'
import {Card, Col, Container, Row} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SimpleHeader from "../../../module_components/Displays/Headers/SimpleHeader";
import BootstrapTable from "react-bootstrap-table-next";
import PrimaryButton from "../../../module_components/Displays/Buttons/PrimaryButton";
import {Integrations} from "../../../globalNavigation";
import {initializeIntegrations, deleteIntegration} from "../../../actions";
import connect from "react-redux/es/connect/connect";
import MoreOptions from "../../../module_components/Displays/Buttons/MoreOptions";
import IntegrationsTableLoading from "../../../module_components/LoadingAnimations/IntegrationsTableLoading";
import NoTableDataIndication from "../../../module_components/PageContent/NoTableDataIndication";
import BadgeDot from "../../../module_components/Displays/Badges/BadgeDot";

class IntegrationsTable extends React.Component {
    state = {
        fadeClass: 'wait-for-fade',
    };

    componentDidMount() {
        this.props.initialize();
    }

    fadeInContent = () => {
        if(this.state.fadeClass === 'wait-for-fade') this.setState({fadeClass: 'fade-in'});
    };

    hanleIntegrationOption = (params) => {
        if (params.action === "delete"){
            this.props.delete(this.props.integration, params.integration_id);
        }
    };

    animateAndRedirect = () => {
        this.setState({fadeClass: 'fade-out'});
        setTimeout(() => {
            window.location.href = Integrations;
        }, 500) ;
    };

    render() {
        let integrations = this.props.integration;
        return (
            <>
                <SimpleHeader name="Integrationen" headerText='Hier finden Sie eine Übersicht Ihrer Integrationen.'/>
                <Container className={"mt--6 "+this.state.fadeClass} fluid>
                    {this.fadeInContent()}
                    <Row>
                        <div className="col">
                            <Card>
                                {integrations ? <ToolkitProvider
                                    data={integrations}
                                    keyField="id"
                                    columns={[
                                        {
                                            dataField: "show_logo",
                                            text: "",
                                            style: {width: "150px"}
                                        },
                                        {
                                            dataField: "company",
                                            text: "Integration",
                                            sort: true,
                                            style: {fontWeight: 'bold', display: "table-cell", verticalAlign: 'middle'}
                                        },
                                        {
                                            dataField: "status_display",
                                            text: "Status",
                                            sort: true,
                                            formatter: (cell, row) => <BadgeDot value={cell} color={row.status_color} />,
                                            style: {display: "table-cell", verticalAlign: 'middle'}
                                        },
                                        {
                                            dataField: "id",
                                            text: "",
                                            formatter: (cell) => <MoreOptions optionClicked={(params) => this.hanleIntegrationOption(params)} integration_id={cell} />,
                                            sort: false,
                                            classes: 'linked-col'
                                        }
                                    ]}
                                    search
                                >
                                    {props => (
                                        <div className="pt-4 custom-table">
                                            <div className="container-fluid">
                                                <Row className='mb-4'>
                                                    <Col className='table-title-wrapper pb-3' xs={12} sm={6} lg={3}>
                                                        <b>Alle Integrationen</b>
                                                    </Col>
                                                    <Col xs={12} sm={6} lg={9} className={'text-right'}>
                                                        <span><PrimaryButton disabled={false} onClick={() => this.animateAndRedirect()} value={'Integration hinzufügen'} /></span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                noDataIndication={ () => <NoTableDataIndication text={'Sie haben noch keine Integrationen'} /> }
                                                wrapperClasses="table-responsive mb-0"
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider> : <IntegrationsTableLoading />}
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

let mapStateToProps = function(state)   {
    return {
        integration: state.integration,
    }
};

let mapDispatchToProps = {
    initialize: initializeIntegrations,
    delete: deleteIntegration,
};

let IntegrationsTableContainer = connect(mapStateToProps, mapDispatchToProps)(IntegrationsTable);

export default IntegrationsTableContainer;
