import validateVar from "../Functions/validation/validateVariable";
import isEmptyObject from "../Functions/isEmptyObject";
import History from "./Components/history";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import {apiError} from "../Functions/global";
const notification_vars = {
    id: {
        id: null,
        message_id: null
    },
    status: {
        status: null,
        status_display: {
            function_name: "getNotificationStatus"
        },
        badge_color: {
            function_name: "getBadgeColor",
            params: [["viewed"], ["push"], [""]]
        }
    },
    subject: null,
    is_message: null,
    linked_object: null,
    recipient_agency_id: null,
    recipient_employee_id: null,
    sender_agency_idsender_agency_id: null,
    sender_employee_id: null,
    sender_logo_id: null,
    sender_name: null,

    contract_id: null,
    protocol_id: null,
    type: {
        type: null,
        type_display: {
            function_name: "displayMessageType"
        }
    },
    message: {
        message: null,
        message_short: {
            function_name: "shortenString",
            params: ["0", "50"]
        }
    },
    created_at: {
        created_at: null,
        created_at_display: {
            function_name: "handleFormatDateForFrontend",
            params: ["true"]
        },
    },
    url_with_mesage_id: {
        function_name: "getMessageUrl",
        params: ["%%type", "%%linked_object", "%%protocol_id", "%%message_id"]
    },
    url: {
        function_name: "getMessageUrl",
        params: ["%%type", "%%linked_object", "%%protocol_id"]
    }
};

//This class is responsible for the navbar notifications (ring-bell & messages)
class Notification {
    constructor(data, status = "open") {
        if (validateVar(data) && !isEmptyObject(data)) {
            this.history = {};
            // this.get_contract(contract_vars, data, data.history, []);
            let class_content = new History(notification_vars, data, {}, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static async handle_viewed(params = {}, methode = "GET") {
        let function_url = "notification/" + params.type;
            params = getAgencyID(params);
        let communication_params = {
            method: methode, // GET / POST / PUT / DELETE  -> method
            function_url:  function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);

        let result = await c.request_handler();
        if (result !== apiError) {
            if (validateVar(result.elements) && Array.isArray(result.elements)) {
                let return_val = [];
                for(let notification of result.elements) {
                    notification.is_message = params.is_message;
                    return_val.push(new Notification(notification));
                }
                return return_val;
            } else return new Notification(result);
        } else return apiError;
    }


}

export default Notification;
