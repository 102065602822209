import React from "react";
import validateVar from "../Functions/validation/validateVariable";

class LoadingAnimationDotted extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            loading_text: props.text,
            loading_spinner: (validateVar(props.loading_spinner)) ? props.loading_spinner : false,
            delay: (validateVar(props.delay)) ? props.delay : 700
        };
    }

    componentDidMount() {
        let {delay} = this.state;
        for (var i = 0; i < 1000; i++) {
            setTimeout(function() {
                forLoop(delay);
            }, 8+4*delay*i);
        }

        var that=this;

        function forLoop(delay) {
            setTimeout(function() {
                that.setState({loading_text: that.props.text+' .'});
                setTimeout(function() {
                    that.setState({loading_text: that.props.text+' ..'});
                    setTimeout(function() {
                        that.setState({loading_text: that.props.text+' ...'});
                        setTimeout(function() {
                            that.setState({loading_text: that.props.text+' '});
                        }, delay);
                    }, delay);
                }, delay);
            }, delay);
        }
    }

    render() {
        const {loading_spinner} = this.state;
        return(
            <>
                {(loading_spinner) ?
                <span className="fa-1x">
                    <i className="fas fa-circle-notch fa-spin" /> {this.state.loading_text}
                </span>: this.state.loading_text}
            </>
        );
    }
}

export default LoadingAnimationDotted;