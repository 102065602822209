import validateVar from "../Functions/validation/validateVariable";
import { apiError } from "../Functions/global";
import { displayErrorMessage, displaySuccessMessage } from "./user_feedback";
import Offer from "../Objects/offer";
import Table from "../Objects/table";
import Analytics from "../Objects/analytics";
import * as ERROR_CODE from "../Errors/error_codes";
import Clearing from "../Objects/clearing";
import Calculation from "../Objects/calculation";
import CalculationPackage from "../Objects/calculation_packet";
import NotificationSignature from "../Objects/notification_signature";
import RecommendedAction from "../Objects/recommended_action";
import RecommendedActionInformation from "../Objects/recommended_action_information";
import Tariff from "../Objects/tariff";
import File from "../Objects/files";
import {tariff_list} from "../../globalNavigation";
import callClassWithString from "../Functions/callClassWithString";
import Chronik from "../Objects/chronik";
import * as action_employee from "./action_employee";
import * as action_team from "./action_team";
import CompanyEnermakler from "../Objects/company_enermakler";
import ExternalOffer from "../Objects/external_offer";
import LoadProfile from "../Objects/loadProfiles";
import is_true from "../Functions/is_true";
import ConsumptionPoint from "../Objects/consumption_point";

export function handle_price_ranking(params, method) {
  return function(dispatch) {
    Offer.handle_price_ranking(params, method).then(res => {
      let is_list = false;
      if (validateVar(params)) is_list = true; // check if list request is called
      if (res !== apiError) {
        if (Array.isArray(res)) {
          // return list
          if (res.includes(apiError)) {
            // includes error
            if (params.is_list) dispatch({ type: "INITIALIZE_TABLE", res: [] });
          } else {
            // SUCCESS
            dispatch({ type: "INITIALIZE_TABLE", res: res });
          } // todo upload list
        } else {
          if (method === "POST")
            dispatch({ type: "CREATED_NEW_TABLE_LAYOUT", res: res });
          else if (method === "PUT")
            dispatch({ type: "UPDATE_TABLE_LAYOUT", res: res });
          else if (method === "DELETE")
            dispatch({ type: "DELETE_TABLE_LAYOUT", res: res });
          else dispatch({ type: "INIT_OFFER", res: res });
        }
      } else {
        dispatch({ type: "INIT_OFFER", res: [] }); // show empty table if error
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Tabellen Layouts konnten nicht geladen werden. (Fehlercode: " +
              // ERROR_CODE.GetLayoutError +
              ")", // content
            "Fehler", //headline
            null, // url,
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function handle_table_actions(params, method) {
  return function(dispatch) {
    Table.handle_table_actions(params, method).then(res => {
      let is_list = false;
      if (validateVar(params)) is_list = true; // check if list request is called
      if (res !== apiError) {
        if (Array.isArray(res)) {
          // return list
          if (res.includes(apiError)) {
            // includes error
            if (params.is_list) dispatch({ type: "INITIALIZE_TABLE", res: [] });
          } else {
            // SUCCESS
            dispatch({ type: "INITIALIZE_TABLE", res: res });
          } // todo upload list
        } else {
          if (method === "POST") {
            dispatch({ type: "CREATED_NEW_TABLE_LAYOUT", res: res });
            dispatch({
              type: "SET_USER_MESSAGE",
              res: displaySuccessMessage(
                "Das neue Tabellenlayout wurde gespeichert.", // content
                "Erfolg", //headline
                null, // url,
                false, //automatic redirect
                false //modal
              )
            });
          } else if (method === "PUT") {
            dispatch({ type: "UPDATE_TABLE_LAYOUT", res: res });
            dispatch({
              type: "SET_USER_MESSAGE",
              res: displaySuccessMessage(
                "Das Tabellenlayout wurde aktualisiert.", // content
                "Erfolg", //headline
                null, // url,
                false, //automatic redirect
                false //modal
              )
            });
          } else if (method === "DELETE")
            dispatch({ type: "DELETE_TABLE_LAYOUT", res: res });
          else dispatch({ type: "INITIALIZE_TABLE", res: res });
          // dispatch({type: "INITIALIZE_TABLE", res: res}); // return single employee
        }
      } else {
        dispatch({ type: "INITIALIZE_TABLE", res: apiError }); // show empty table if error
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Tabellen Layouts konnten nicht geladen werden. (Fehlercode: " +
              // ERROR_CODE.GetLayoutError +
              ")", // content
            "Fehler", //headline
            null, // url,
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function handle_chart_data(params, method) {
  return function(dispatch) {
    Analytics.handle_chart_data(params, method).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_CHART_DATA", res: res });
      } else {
        dispatch({ type: "INITIALIZE_CHART_DATA", res: {} });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Daten konnten nicht geladen werden. (Fehlercode: " +
              ERROR_CODE.GetAnalyticsData +
              ")", // content
            "Fehler", //headline
            null, // url,
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function handle_overview_tasks(params, method) {
  return function(dispatch) {
    Analytics.handle_overview_tasks(params, method).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_OVERVIEW_TASKS", res: res });
      } else {
        dispatch({ type: "INITIALIZE_OVERVIEW_TASKS", res: {} });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die offenen Aufgaben konnten nicht geladen werden. (Fehlercode: " +
              ERROR_CODE.GetOverviewTasks +
              ")", // content
            "Fehler", //headline
            null, // url,
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function handle_notification_signature_actions(params, method = 'GET') {
  return function(dispatch) {
    NotificationSignature.handle_notification_signature_actions(params, method).then(res => {
      if (res !== apiError) {
            dispatch({type: "GET_NOTIFICATION_SIGNATURES", res: res});
      } else {
        dispatch({
            type: "SET_USER_MESSAGE",
            res: displayErrorMessage(
                "Beim laden der E-Mail Signaturen ist ein Fehler aufgetreten.", // content
                "Fehler", //headline
                null,
                false, //automatic redirect
                true, //modal
                null,
                "Zurück", // secondaryBtnText
                true //allowToggle (user can close modal without pressing a btn)
            )
        });
      }
    });
  };
}

export function initClearingList(params = null) {
    return function(dispatch) {
        // dispatch({
        //   type: "INITIALIZE_CLEARING_LIST",
        //   res: {
        //     clearing: [],
        //     total_count: 0,
        //     next_offset: null
        //   }
        // });

        Clearing.handle_clearing(params).then(res => {
            if (res !== apiError) {
                if (Array.isArray(res.clearing)) {
                    // return list
                    dispatch({ type: "INITIALIZE_CLEARING_LIST", res: res });
                } else {
                    dispatch({ type: "INITIALIZE_CLEARING", res: res }); // return single employee
                }
            } else {
                if (!validateVar(params))
                    dispatch({ type: "INITIALIZE_CLEARING_LIST", res: "ERR" }); //return list error
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Aufgabenliste konnte nicht geladen werden. (Fehlercode: )", // content
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        true, //modal
                        null, // primaryBtnText
                        "Bestätige", // secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false, // successIcon
                        false, // infoIcon
                        "danger" //type - danger/success
                    )
                });
            }
        });
    };
}

export function handle_calculation_packet_actions(params, method = "GET", function_name = null, use_agency_id = false, file = null){
  //TODO Alle actions wie diese hier gestalten!
  return function(dispatch){
    CalculationPackage.handle_calculation_packet_actions(params, method, function_name, use_agency_id, file).then(res => {
      const {error_message, success_message, error_res} = params.internal;
      const dispatch_type = params.internal.dispatch_type;
      if (res !== apiError) {
        if(validateVar(dispatch_type)) dispatch({type: dispatch_type, res: res});
        if(validateVar(success_message)) dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(success_message, "Erfolg")});
      } else {
          if (typeof error_res !== "undefined") dispatch({type: dispatch_type, res: error_res});
          dispatch({type: "SET_USER_MESSAGE",
              res: displayErrorMessage(
                  error_message, // content
                  "Fehler", //headline
                  null, // url
                  true, //automatic redirect
                  true,       //modal
                  null, // primaryBtnText
                  "Zurück",// secondaryBtnText
                  false, //allowToggle (user can close modal without pressing a btn)
                  false,// successIcon
                  false,// infoIcon
                  "danger" //type - danger/success
          ),
        });
      }
    });
  }
}

export function handle_calculation_actions(params, method = "GET", function_name = null, use_agency_id = false){
  return function(dispatch){
    Calculation.handle_calculation_actions(params, method, function_name, use_agency_id).then(res => {
      const {error_message, success_message, error_res} = params.internal;
      const dispatch_type = params.internal.dispatch_type;
      if (res !== apiError) {
        if(validateVar(dispatch_type)) dispatch({type: dispatch_type, res: res});
        if(validateVar(success_message)) dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(success_message, "Erfolg")});
      } else {
        if (typeof error_res !== "undefined") dispatch({type: dispatch_type, res: error_res});
        dispatch({type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
              error_message, // content
              "Fehler", //headline
              null, // url
              true, //automatic redirect
              true,       //modal
              null, // primaryBtnText
              "Zurück",// secondaryBtnText
              false, //allowToggle (user can close modal without pressing a btn)
              false,// successIcon
              false,// infoIcon
              "danger" //type - danger/success
          ),
        });
      }
    });
  }
}

/* Diese funktionen wurden von Index/index.js kopiert weil sie aus einen unbekannten grund über die Index/index.js in Komponenten nicht aufrufbar sind */

export function redirect(url) {
  return function (dispatch) {
    if (validateVar(url)) {
      dispatch({
        type: "SET_USER_MESSAGE",
        res: displaySuccessMessage(
            "", // content
            "", //headline
            url, // url
            true, //automatic redirect
            false,       //modal
        ),
      });
    } else {
      dispatch({
        type: "SET_USER_MESSAGE",
        res: displayErrorMessage(
            "Beim Weiterleiten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.RedirectError + ")", // content
            "Fehler", //headline
            null, // url
            false, //automatic redirect
            true,       //modal
            null, // primaryBtnText
            "Zurück",// secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false,// successIcon
            false,// infoIcon
            "danger" //type - danger/success
        ),
      });
    }
  };
}

export function upload_file_new(params, file, field_name) {
  return function(dispatch) {
    File.upload_file_new(params, file).then(res => {
      if (res !== apiError) {
        let result = res;
        if (validateVar(field_name)) result = { ...res, field_name };
        dispatch({ type: "FILE_UPLOADED", res: result });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
              "Bei dem Hochladen der Datei ist ein Fehler aufgetreten.",
              "Fehler", //headline
              null, // url
              tariff_list, // url
              true, //modal
              null, // primaryBtnText
              "Bestätigen", // secondaryBtnText
              false, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              false, // infoIcon
              "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function dynamic_action_call(
    class_name,
    function_name,
    dispatch_type,
    params,
    file = null,
    field_name = null
) {
  return function(dispatch) {
    let class_object = callClassWithString(class_name, null);
    class_object.constructor[function_name](params, file).then(res => {
      let result = res;
      if (validateVar(field_name)) result = { ...res, field_name };
      if (res !== apiError) {
        dispatch({ type: dispatch_type, res: result });
      } else {
        dispatch({ type: dispatch_type, res: apiError });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
              "Es ist ein Fehler aufgetreten.",
              "Fehler", //headline
              null, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätigen", // secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              false, // infoIcon
              "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function initializeChronik(params, site_name = null, id = null) {
  return function(dispatch) {
    Chronik.init(params, site_name, id).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INIT_CHRONIK", res: res });
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
              "Beim Laden der Chronik ist etwas schief gelaufen. (Fehlercode: ", // content
              "Fehler", //headline
              null, // url
              false, //automatic redirect
              true, //modal
              null, // primaryBtnText
              "Bestätigen", // secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false, // successIcon
              false, // infoIcon
              "danger" //type - danger/success
          )
        });
      }
    });
  };
}



export function handle_recommended_actions(params, method = "GET", function_name = null, file = null){
  return function(dispatch){
      RecommendedActionInformation.handle_recommended_actions(params, method, function_name, file).then(res => {
          const {dispatch_type, error_message, success_message, error_res} = (validateVar(params.internal)) ? params.internal : {};
      if (res !== apiError) {
          if(validateVar(success_message)) dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(success_message, "Erfolg")});
          if(validateVar(dispatch_type)) {
            if(validateVar(res.offer_recommendations) && res.offer_recommendations.length > 0 && method === "GET"){
                for (let offer of res.offer_recommendations) {
                let additional_options =  (offer.offer_result_ids && offer.offer_result_ids.length > 0) ? {filter_offer_results: offer.offer_result_ids} : null;
                let append_to_tariff = {
                  offer_duration: offer.offer_duration,
                  offer_begin: offer.offer_begin,
                  offer_end: offer.offer_end,
                  consumption: res.total_mean_consumption,
                };
                    console.log("res.total_mean_consumption", res.total_mean_consumption);

                    dispatch({type: "TARIFF_RESET_LIST", res: null});
                if (additional_options)  {
                    Tariff.getCalculatedTariffs(offer.offer_id, additional_options, append_to_tariff).then(res => {
                        dispatch({type: "APPEND_TO_TARIFF_LIST", res: res});
                    });
                }
              }
            }
            dispatch({type: dispatch_type, res: res});
        }
      } else {
          if (typeof error_res !== "undefined") dispatch({type: dispatch_type, res: error_res});
          dispatch({type: "SET_USER_MESSAGE",
              res: displayErrorMessage(
                  error_message, // content
                  "Fehler", //headline
                  null, // url
                  true, //automatic redirect
                  true,       //modal
                  null, // primaryBtnText
                  'Ok',// secondaryBtnText
                  false, //allowToggle (user can close modal without pressing a btn)
                  false,// successIcon
                  false,// infoIcon
                  "danger" //type - danger/success
            ),
        });
      }
    });
  }
}



export function handle_external_offer(params, method = "GET", function_name = null, file = null){
  return function(dispatch){
      ExternalOffer.handle_external_offer(params, method, function_name, file).then(res => {
          const {dispatch_type, error_message, success_message, error_res, redirect} = (validateVar(params.internal)) ? params.internal : {};
          if (res !== apiError) {
          if(validateVar(success_message)) dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(success_message, "Erfolg")});
          if (redirect){ // redirect
              dispatch({
                  type: "SET_USER_MESSAGE",
                  res: displaySuccessMessage(
                      "", //  content
                      "", // headline
                      redirect,
                      true, // automatic_redirect
                      false // modal
                  )
              });
          }
          else if(validateVar(dispatch_type)) {
            dispatch({type: dispatch_type, res: res});
          }
      } else {
          if (typeof error_res !== "undefined") dispatch({type: dispatch_type, res: error_res});
          dispatch({type: "SET_USER_MESSAGE",
              res: displayErrorMessage(
                  error_message, // content
                  "Fehler", //headline
                  null, // url
                  true, //automatic redirect
                  true,       //modal
                  null, // primaryBtnText
                  'Ok',// secondaryBtnText
                  false, //allowToggle (user can close modal without pressing a btn)
                  false,// successIcon
                  false,// infoIcon
                  "danger" //type - danger/success
            ),
        });
      }
    });
  }
}


export function handle_consumption_point(params, method = "GET", function_name = null, file = null){
  return function(dispatch){
      ConsumptionPoint.handle_consumption_point(params, method, function_name, file).then(res => {
          const {dispatch_type, error_message, success_message, error_res, redirect} = (validateVar(params.internal)) ? params.internal : {};
          if (res !== apiError) {
          if(validateVar(success_message)) dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(success_message, "Erfolg")});
          if (redirect){ // redirect
              dispatch({
                  type: "SET_USER_MESSAGE",
                  res: displaySuccessMessage(
                      "", //  content
                      "", // headline
                      redirect,
                      true, // automatic_redirect
                      false // modal
                  )
              });
          }
          else if(validateVar(dispatch_type)) {
            dispatch({type: dispatch_type, res: res});
          }
      } else {
          if (typeof error_res !== "undefined") dispatch({type: dispatch_type, res: error_res});
          dispatch({type: "SET_USER_MESSAGE",
              res: displayErrorMessage(
                  error_message, // content
                  "Fehler", //headline
                  null, // url
                  true, //automatic redirect
                  true,       //modal
                  null, // primaryBtnText
                  'Ok',// secondaryBtnText
                  false, //allowToggle (user can close modal without pressing a btn)
                  false,// successIcon
                  false,// infoIcon
                  "danger" //type - danger/success
            ),
        });
      }
    });
  }
}




export function handle_load_profile(params, method = "GET", function_name = null, file = null, is_download = false){
  return function(dispatch){
      LoadProfile.handle_load_profile(params, method, function_name, file, is_download).then(res => {
          const {dispatch_type, error_message, success_message, error_res} = (validateVar(params.internal)) ? params.internal : {};
      if (res !== apiError) {
          if(validateVar(success_message)) dispatch({type: "SET_USER_MESSAGE", res: displaySuccessMessage(success_message, "Erfolg")});
          if(validateVar(dispatch_type)) {
            dispatch({type: dispatch_type, res: res});
          }
      } else {
          if (typeof error_res !== "undefined") dispatch({type: dispatch_type, res: error_res});
          dispatch({type: "SET_USER_MESSAGE",
              res: displayErrorMessage(
                  error_message, // content
                  "Fehler", //headline
                  null, // url
                  true, //automatic redirect
                  true,       //modal
                  null, // primaryBtnText
                  'Ok',// secondaryBtnText
                  false, //allowToggle (user can close modal without pressing a btn)
                  false,// successIcon
                  false,// infoIcon
                  "danger" //type - danger/success
            ),
        });
      }
    });
  }
}




export function handle_offer_actions(params, method = "GET", function_name = null, file = null, dispatch_data){
  return function(dispatch){
      Offer.handle_offer_actions(params, method, function_name, file).then(res => {
          let {dispatch_type, error_message, success_message, error_res, error_dispatch_type} = params.internal;
      if (res !== apiError) {
          if(validateVar(dispatch_type)) {

            dispatch({type: dispatch_type, res: res});
        }
      } else {
          error_message = validateVar(error_message) ? error_message : "Es ist ein Fehler aufgetreten";
          if (typeof error_res !== "undefined") dispatch({type: dispatch_type, res: error_res});
          if (validateVar(error_dispatch_type) && error_dispatch_type === "PUSH") {
            dispatch({type: "SET_USER_MESSAGE", res: displayErrorMessage(error_message, "Fehler")});
          } else {
              dispatch({type: "SET_USER_MESSAGE",
                  res: displayErrorMessage(
                      error_message, // content
                      "Fehler", //headline
                      null, // url
                      true, //automatic redirect
                      true,       //modal
                      null, // primaryBtnText
                      "Zurück",// secondaryBtnText
                      false, //allowToggle (user can close modal without pressing a btn)
                      false,// successIcon
                      false,// infoIcon
                      "danger" //type - danger/success
                  ),
              });
          }

      }
    });
  }
}


export function handle_employee_actions(function_name = null ,params = null, method = "GET", is_list = false,  url = null, file = null  ) {
  if (validateVar(function_name)){
    return function (dispatch) {
      dispatch(action_employee[function_name](function_name, params, method, is_list, url, file ))
    }
  }
}

export function handle_team_actions(function_name = null ,params = null, method = "GET",   url = null, file = null  ) {
  if (validateVar(function_name)){
    return function (dispatch) {
      dispatch(action_team[function_name](function_name ,params, method, url, file ))
    }
  }
}





export function initializeCompanyEnermakler() {
  return function (dispatch) {
    CompanyEnermakler.instantiate().then(res => {
      if (res !== apiError) {
        dispatch({type: "INITIALIZE_COMPANY", res: res});
      } else {
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
              "Bei dem laden der Firmendaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetCompanyError + ")", // content
              "Fehler", //headline
              null, // url, // url
              false, //automatic redirect
              true,       //modal
              null, // primaryBtnText
              "Bestätige",// secondaryBtnText
              true, //allowToggle (user can close modal without pressing a btn)
              false,// successIcon
              false,// infoIcon
              "danger" //type - danger/success
          ),
        });
      }
    });
  };
}


