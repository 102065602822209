export default function getTotalSurcharge(
    consultant_charge_amount = 0,
    consultant_charge_unit = 0,
    consultant_charge_amount2 = 0,
    consultant_charge_unit2 = 'EUR_PER_YEAR',
    eless_fee_amount = 0,
    eless_fee_unit = "CT_PER_KWH",
    supplier_fee_rate = 0,
    agency_fee_amount = 0,
    agency_fee_unit = "CT_PER_KWH",
    transaction_fee_amount = 0,
    transaction_fee_unit = "CT_PER_KWH",
    unit_to_add = "CT_PER_KWH"
) {
    let total_surcharge_unformated = 0;
    if (consultant_charge_unit === unit_to_add) total_surcharge_unformated = total_surcharge_unformated + +consultant_charge_amount;
    if (eless_fee_unit === unit_to_add) total_surcharge_unformated = total_surcharge_unformated + +eless_fee_amount;
    if (agency_fee_unit === unit_to_add) total_surcharge_unformated = total_surcharge_unformated + +agency_fee_amount;
    if (transaction_fee_unit === unit_to_add) total_surcharge_unformated = total_surcharge_unformated + +transaction_fee_amount;
    if(+consultant_charge_amount2 > 0 && consultant_charge_unit2 === 'EUR_PER_YEAR' && unit_to_add === 'EUR_PER_MONTH') total_surcharge_unformated += consultant_charge_amount2 / 12;
    total_surcharge_unformated = total_surcharge_unformated + +supplier_fee_rate;
    return total_surcharge_unformated;
}

function get_yearly_surcharge(eur_per_month) {
    return (eur_per_month * 12);
}
