function translateSwitchStatus(switching_status) {
    if (switching_status === "open") return "Ausstehend";
    else if (switching_status === "successful") return "Bestätigt";
    else if (switching_status === "error") return "Storniert";
    else if (switching_status === "announced") return "In Bearbeitung";
    else if (switching_status === "pending") return "Klärfall";
    else if (switching_status === "billed") return "Abgerechnet";
    else return "-/-";
}

export default translateSwitchStatus;