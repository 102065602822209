import {connect} from "react-redux";
import React, { Component } from 'react';
import {
    init_all_attributes,
    init_tree,
    reset_tree,
    create_empty_tree,
    handle_tree_submit,
    handle_price_table_submit,
    sort_tree_groups,
    group_copy,
    group_add,
    sort_branches,
    group_edit,
    group_delete,
    add_attribute_to_group,
    add_branch,
    edit_branch,
    delete_branch,
    delete_attribute_from_group,
    edit_branch_operator,
    set_text_form
} from "../../Index/SubIndex/price_strategy_new_tree";
import validateVar from "../../Functions/validation/validateVariable";
import AllAttributeModal from "./Components/AllAttributesModal";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import is_true from "../../Functions/is_true";
import SimpleHeader from "../../Displays/Headers/SimpleHeader";
import AttributeField from "./Components/AttributeField";
import GroupsModal from "./Components/GroupsModal";
import CustomAlert from "../../CustomAlert/CustomAlert";
import is_false from "../../Functions/is_false";
import {showErrorMessage, init_tariff, redirect} from "../../../actions/index";
import compare_and_validate_tree_branches from "../../Functions/validation/validate_tree_branch";
import GroupHeader from "./Components/GroupHeader";
import AddTariffHeader from "../../../pages/CalculationManagement/Tariffs/components/AddTariffHeader";
import Skeleton from "react-loading-skeleton";
import TooltipButton from "../../Displays/Buttons/TooltipButton";
import GreyLinkButton from "../../Displays/Buttons/GreyLinkButton";
import {handleTariff, TariffDocs} from "../../../globalNavigation";
import SubmitLoadingAnimationModal from "../../LoadingAnimations/SubmitLoadingAnimationModal";
import handleFormatDate from "../../Functions/handleFormatDate";
import TariffPriceTableContainer from "./Components/TariffPriceTable";
import Validation from "../../Objects/Components/validation";
import {update_price_table_values} from "../../../actions";
import MaterialUIStepper from "../../../pages/CalculationManagement/Tariffs/components/MaterialUIStepper";
import Badge from "../../Displays/Badges/Badge";
import {dynamic_action_call, upload_file_new} from "../../Index";

class NewTree extends Component {
    constructor(props) {
        super(props);
        let {tree_id, id} = props.match.params;
        let is_global_edit_able = false;
        let is_global_page = (props.match.path === "/globale-preisstrategie");
        this.tree_id = tree_id;
        this.tariff_id = id;
        this.is_global = false;
        this.state = {
            is_loaded_tree: false,
            submitted: false,
            is_original_global_tree: true,
            is_loaded_tariff: false,
            is_global_page: is_global_page,
            is_global_edit_able: is_global_edit_able,
            is_loaded_tree_global: false,
            disable_all_dropdown_group_btn: false,
            atrribute_expansion_obj: {},
            all_atributes: [],
            display_modal: (is_true(is_global_page)) ? "Lade globale Attribute" : "Lade Tarif",
            loading_modal_id: (is_true(is_global_page)) ? "loading-global-attributes" : "loading-tariff",
            // show_loading_modal: true,
            warning_modal_content: {
                title:'Gruppierung löschen',
                text: 'Sind Sie sich sicher, dass Sie die Gruppierung löschen wollen?',
                primaryBtnText: 'Löschen',
                secondaryBtnText: ""
            }
        };
        this.all_attribute_list_modal = React.createRef();
        this.handle_groups_modal = React.createRef();
        this.warning_modal = React.createRef();
        this.price_table = React.createRef();

        // submit btn handling
        if (is_global_page) {// Globale Kriterien
            this.dynamicSettings = {
                submitButtonText: 'Speichern',
                submitFunction: () => this.save_tree(),
                submitCustomClass: 'primary-btn',
                cancelButtonText: 'Abbrechen',
                cancelFunction: () => this.reset_global_tree(),
                cancelCustomClass: '',
            };
        }else{
            this.dynamicSettings = {
                submitButtonText: 'Weiter',
                submitFunction: () => this.save_tree(),
                submitCustomClass: 'primary-btn p-0',
                cancelButtonText: 'Zurück',
                cancelFunction: () => this.props.redirect(handleTariff + '/' + id),
                cancelCustomClass: 'arrow-before',
            };
        }
    }

    componentDidMount() {
        let {is_global_page} = this.state;
        if (is_false(is_global_page)) {
            let init_tariff_params = {
                tariff_id: this.tariff_id,
                options: {
                    with_price_matrix: 1
                }
            };
            this.props.init_tariff(init_tariff_params); // get attribute list
        } else {
            let params = {
                options: {
                    is_global: 1,
                }
            };
            this.props.init_all_attributes(params); // get attribute list for global tree
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let {tree_attributes, tree, tariff_obj, company, message_feedback} = this.props;
        let tariff = tariff_obj.tariff;
        let {is_global_edit_able, all_atributes, is_global_page} = this.state;
        if (validateVar(tree_attributes.attributes) && tree_attributes.attributes !== prevProps.tree_attributes.attributes) {
            let all_atributes = JSON.parse(JSON.stringify(tree_attributes.attributes));
            all_atributes.push(...tree_attributes.global_attributes);
            if (!validateVar(tree.tree)) { // if not defined get tariff tree or create new tree for tariff
                if (this.tree_id > 0) { // load existing tree
                    this.props.init_tree({tree_id: this.tree_id}, all_atributes); // init tree data -> tree_attributes to add aditional data in class
                    this.setState({
                        display_modal: "Lade Bewertungskriterien",
                        loading_modal_id: "loading-pricingcriteria",
                    });
                } else { // create a new tree
                    let empty_tree = this.create_empty_fake_tree(tariff);
                    this.props.create_empty_tree(empty_tree);
                }
            }
            this.setState({
                all_atributes: all_atributes
            });
        }

        if (validateVar(message_feedback) && message_feedback !== prevProps.message_feedback) {
            this.setState({
                submitted: false
            })
        }

        if (company !== prevProps.company || tree_attributes.global_attributes !==  prevProps.global_attributes) {
            if (validateVar(company) && validateVar(tree_attributes.global_attributes) && this.state.stop !== 1) {
                company.add_on = 1;
                if (is_true(is_global_page)) { // only global tree
                    if (!validateVar(tree.global_tree)) {
                        this.setState({
                            display_modal: "Lade Bewertungskriterien",
                            loading_modal_id: "loading-pricingcriteria"
                        });
                        this.tree_id = company.global_tree_id;
                        if (company.global_tree_id > 0) {
                            this.props.init_tree({tree_id: company.global_tree_id, global: true}, tree_attributes.global_attributes); // init tree data -> tree_attributes to add aditional data in class
                        } else {
                            let empty_global_tree = this.create_empty_fake_tree();
                            this.props.create_empty_tree(empty_global_tree);
                        }
                    }
                } else { // all attributes for tree
                    let options = {
                        is_global: 0,
                    };
                    if (tariff.medium_type === "ELECTRICITY") options.is_electricity = 1;
                    if (tariff.medium_type === "GAS") options.is_gas = 1;
                    if (tariff.is_slp === "YES") options.is_slp = 1;
                    if (tariff.is_rlm === "YES") options.is_rlm = 1;
                    if (tariff.customer_type === "PRIVATE") options.is_private = 1;
                    if (tariff.customer_type === "BUSINESS") options.is_business = 1;
                    let params = {
                        options: options
                    };
                    this.props.init_all_attributes(params); // get attribute list
                }
                this.setState({
                    allowed_group_quantity: validateVar(company.allowed_group_quantity) ? company.allowed_group_quantity : 1,
                    stop: 1
                })
            }
        }

        if (validateVar(tree.tree) && tree.tree !== prevProps.tree.tree) {
            if (!validateVar(tree.global_tree)) {
                if (validateVar(company.global_tree_id) && company.global_tree_id > 0) {
                    this.props.init_tree({tree_id: company.global_tree_id, global: true}, all_atributes); // init tree data -> tree_attributes to add aditional data in class
                } else {
                    let empty_global_tree = this.create_empty_fake_tree();
                    this.props.create_empty_tree(empty_global_tree);
                }
            }
            this.tree_id = tree.tree.id;
            this.create_tree(tree.tree);
        }

        if (validateVar(tree.global_tree) && tree.global_tree !== prevProps.tree.global_tree) {
            let is_original_global_tree =(JSON.stringify(tree.global_tree) === JSON.stringify(tree.original_global_tree));
            if (!validateVar(prevProps.tree.global_tree) && validateVar(tree.global_tree)) {
                this.setState({
                    display_modal: false
                });
            }
            this.create_tree(tree.global_tree, [], [], is_original_global_tree);
        }

        if (validateVar(tariff) && tariff !== prevProps.tariff_obj.tariff) {
            this.tree_id = tariff.tree_id;
            let params = {
                options: {
                    is_global: 1,
                }
            };
            this.props.init_all_attributes(params); // get attribute list for global tree
            this.setState({
                is_loaded_tariff: true,
                display_modal: "Lade Attribute",
                loading_modal_id: "loading-attributes",
            })
        }

        if (validateVar(is_global_edit_able) && is_global_edit_able !== prevState.is_global_edit_able) {
            let is_original_global_tree =(JSON.stringify(tree.global_tree) === JSON.stringify(tree.original_global_tree));
            this.create_tree(tree.global_tree, [], [], is_original_global_tree);
        }
    }

    reset_global_tree = () => {
        let {tree} = this.props;
        if (tree.original_global_tree !== tree.global_tree) {
            this.props.reset_tree(tree.original_global_tree);
        }
    };

    create_empty_fake_tree = (tariff = null) => {
        return  {
            id: 0,
            is_electricity: (validateVar(tariff) && tariff.medium_type === "ELECTRICITY") ? 1 : 0,
            is_gas: (validateVar(tariff) && tariff.medium_type === "GAS") ? 1 : 0,
            is_rlm: (validateVar(tariff) && tariff.is_rlm === "YES") ? 1 : 0,
            is_global: (!validateVar(tariff)),
            is_slp: (validateVar(tariff) && tariff.is_slp === "YES") ? 1 : 0,
            type: "list",
            groups: [{
                name: (validateVar(tariff)) ?  "Neuer Baum" : "",
                id: 1,
                description: (validateVar(tariff)) ? "Beschreiben Sie ihren Baum" : "",
                description_short:  (validateVar(tariff)) ? "Beschreiben Sie ihren Baum"  : "",
                tree_attributes: [],
            }],
        };
    };

    create_tree = (tree, attibute_error_array = [], group_error_array = [], is_original_global_tree) => {
        let  {is_global_edit_able, allowed_group_quantity} = this.state;
        let all_groups = [];
        let groups_length = 0;
        let is_global = tree.is_global;
        let state_name = (is_true(is_global)) ? "all_groups_global" : "all_groups";
        let is_loaded_name = (is_true(is_global)) ? "is_loaded_tree_global" : "is_loaded_tree";
        let dropdown_options_group = [ // group actions if group length is 1
            "group_rename",
            "group_copy",
            "group_add",
        ];
        if  (validateVar(tree.groups) && tree.groups.length > 0) {
            groups_length = tree.groups.length;
            if ((+groups_length) >= +allowed_group_quantity) {
                dropdown_options_group = [ // group actions if group length is 1
                    "group_rename",
                ];
            } // not payed for more groups so disable adding groups
            if (groups_length > 1) { // delete enabled if there is more than 1 group
                dropdown_options_group.push("group_delete")
            }
        }

        for (let idx in tree.groups) {
            if (tree.groups.hasOwnProperty(idx)){
                let group = tree.groups[idx];
                let dropdown_btn_sort_value = this.get_sort_dropdown_btn_value(idx, groups_length);
                let group_has_error = (group_error_array.includes(group.id));
                all_groups.push(this.create_group_header(group, dropdown_btn_sort_value, idx, dropdown_options_group, group_has_error, is_global, is_global_edit_able, is_original_global_tree));
                let attribute_length = 0;
                if (validateVar(group.tree_attributes)) attribute_length = group.tree_attributes.length;
                let last_attribute_of_group = false; // check if is last attribute to set margin bottom
                for (let index in group.tree_attributes) {
                    if (group.tree_attributes.hasOwnProperty(index)) {
                        if (+index + 1 === +attribute_length) last_attribute_of_group = true;
                        let tree_attribute = group.tree_attributes[index];
                        let unique_id = group.id + "-" + tree_attribute.attribute_id;
                        let attribute_field = this.create_attribute_field(tree_attribute, last_attribute_of_group, unique_id, group.id, attibute_error_array, is_global, is_global_edit_able);
                        all_groups.push(attribute_field);
                        if (is_true(last_attribute_of_group) && +idx+1 !== +groups_length) { // if last attribute in grp but not last grp -> show "oder" between groups
                            all_groups.push(
                                <div className='my-5 text-center'>
                                    ODER
                                </div>
                            )
                        }
                    }
                }
            }
        }
        this.setState({
            [is_loaded_name]: true,
            [state_name]: all_groups,
        });
    };


    handle_global_tree_editable = () => {
        let {is_global_edit_able} = this.state;
        this.setState({
            is_global_edit_able: !is_global_edit_able
        });
    };


    get_sort_dropdown_btn_value = (current_index, groups_length) => {
        if (groups_length === 1) return [];
        groups_length = groups_length -1; // current_index starts at 0 so we do - 1 on the grp_len to compare them
        let drop_down_values = [];
        if (+current_index === 0) { // first group
            drop_down_values = ["sort_down"]; // can only be sorted down
        } else if (+current_index === +groups_length) { // last group
            drop_down_values = ["sort_up"] // can only be sorted up
        } else { // default
            drop_down_values = ["sort_down", "sort_up"]
        }
        return drop_down_values;
    };

    get_multiselect_values_from_branch = (multiselect_values) => {
        let api_multiselect = [];
        for (let value of multiselect_values) {
            api_multiselect.push({
                comp_op: "EQ",
                expr_a: "VARIABLE",
                expr_b: {
                    VALUE: value
                }
            });
        }
        return api_multiselect;
    };

    convert_branches_in_api_format = (branches, attribute) => {
        let tree_branches_api_format = [];
        let has_error = false;
        for (let index in branches) {
            if (branches.hasOwnProperty(index)) {
                let branch = branches[index];
                let compare_object = compare_and_validate_tree_branches(index, branches, true, attribute.type);
                if (is_false(has_error)) {
                    has_error = ((!is_false(branch.has_error)) || (!is_false(branch.has_error_price)) || (!is_false(compare_object.has_error))) ? true : false;
                }
                let branch_tmp = {};
                if (branch.type === "multiselect") {
                    branch_tmp = {
                        constraints: {
                            logic_op: "OR",
                            cond_list: this.get_multiselect_values_from_branch(branch.comparison_value_1),
                        },
                        base_price: branch.base_price,
                        kwh_rate: branch.price,
                    };
                }
                else if (branch.comparison_operator === "AND") {
                    let branch_value_1 = branch.comparison_value_1;
                    let branch_value_2 = branch.comparison_value_2;

                    if(branch.type === 'date'){
                        branch_value_1 = handleFormatDate(branch.comparison_value_1);
                        branch_value_2 = handleFormatDate(branch.comparison_value_2)
                    }

                    branch_tmp = {
                        base_price: branch.base_price,
                        kwh_rate: branch.price,
                        constraints: {
                            logic_op: branch.comparison_operator,
                            cond_list: [
                                {
                                    comp_op: "GE",
                                    expr_a: "VARIABLE",
                                    expr_b: {
                                        VALUE: branch_value_1
                                    },
                                },
                                {
                                    comp_op: "LE",
                                    expr_a: "VARIABLE",
                                    expr_b: {
                                        VALUE: branch_value_2
                                    },
                                }],
                        }
                    };
                } else {
                    let branch_value_1 = branch.comparison_value_1;

                    if(branch.type === 'date'){
                        branch_value_1 = handleFormatDate(branch.comparison_value_1)
                    }

                    branch_tmp = {
                        constraints: {
                            comp_op: branch.comparison_operator, // todo check on this?
                            expr_a: "VARIABLE",
                            expr_b: {
                                VALUE: branch_value_1
                            },
                        },
                        base_price: branch.base_price,
                        kwh_rate: branch.price,
                    };
                }
                tree_branches_api_format.push(branch_tmp);
            }
        }
        return {
            tree_branches: tree_branches_api_format,
            has_error: has_error
        };
    };

    convert_tree_attributes_in_api_format = (tree_attributes) => {
        let tree_attributes_api_format = [];
        let has_error = [];
        for (let attribute of tree_attributes) {
            let branches_obj = this.convert_branches_in_api_format(attribute.branches, attribute);
            if (is_true(branches_obj.has_error)) {
                has_error.push(attribute.attribute_id);
            }
            let attribute_tmp = {
                attribute_id: attribute.attribute_id,
                branches: branches_obj.tree_branches
            };
            tree_attributes_api_format.push(attribute_tmp);
        }
        return {
            has_error: has_error,
            tree_attributes: tree_attributes_api_format,
        };
    };


    check_global_tree_branches_for_changes = (original_branches, compare_branches) => {
        if (original_branches.length !== compare_branches.length) return true;
        else {
            for (let index in original_branches) {
                if (original_branches.hasOwnProperty(index)) {
                    let original_branch = {
                        base_price: original_branches[index].base_price,
                        price: original_branches[index].price,
                        comparison_operator: original_branches[index].comparison_operator,
                        comparison_value_1: original_branches[index].comparison_value_1,
                        comparison_value_2: original_branches[index].comparison_value_2,
                    };
                    let compare_branch = {
                        base_price: compare_branches[index].base_price,
                        price: compare_branches[index].price,
                        comparison_operator: compare_branches[index].comparison_operator,
                        comparison_value_1: compare_branches[index].comparison_value_1,
                        comparison_value_2: compare_branches[index].comparison_value_2,
                    };
                    if (JSON.stringify(original_branch) !== JSON.stringify(compare_branch)) {
                        return true;
                    }
                }
            }
        }
    };


    check_if_attributes_were_deleted = (current_group, original_group) => {
        let original_is_empty = (original_group.tree_attributes.length === 0);
        if (current_group.length === 0 ) {
            if (is_false(original_is_empty)) return true;
        }
        else {
            current_group = current_group[0];
            for (let original_attribute of original_group.tree_attributes) {
                if (!validateVar(current_group.tree_attributes.find(x => x.attribute_id === original_attribute.attribute_id))) return true;
            }
        }
        return false;
    };

    set_price_table_error = (validation_result, price_table_data) => {
        if(is_true(validation_result.has_error)) { // show errors in price_table
            price_table_data.error_messages = validation_result.error_messages; // update error messages
            this.setState({
                submitted: false
            });
            this.props.update_price_table_values(price_table_data); // set reducer
        }
    };

    save_tree = () => {
        let {tree, tree_attributes, tariff_obj} = this.props;
        let {all_atributes, is_global_page} = this.state;
        this.setState({
            submitted: true
        });

        let tree_attributes_obj = {};
        let global_tree_attributes_obj = {};
        let global_tree = tree.global_tree;
        let tariff_tree = tree.tree;
        let selected_tree = (is_true(is_global_page)) ? global_tree : tariff_tree; // select which tree is edited
        let attribute_error_array = [];
        let global_attribute_error_array = [];
        let group_error_array = [];
        let save_groups = [];
        let original_attributes = [];
        if(validateVar(tree.original_global_tree)) original_attributes = tree.original_global_tree.groups[0].tree_attributes; // get original global tree attributes for comparison if global tree was was edited
        let add_attributes_to_tariff = [];
        if (global_tree !== tree.original_global_tree && original_attributes !== [] && is_false(is_global_page)) { // check for changes in
            let group = global_tree.groups[0];
            if (validateVar(group) && group.tree_attributes.length > 0) {
                global_tree_attributes_obj = this.convert_tree_attributes_in_api_format(group.tree_attributes);
                if (global_tree_attributes_obj.has_error.length > 0) {
                    global_attribute_error_array.push(...global_tree_attributes_obj.has_error);
                }
                for (let attribute of group.tree_attributes) { // for loop to compare current global tree with original loaded global tree
                    if (original_attributes.length > 0) {
                        let attribute_id_tmp = attribute.attribute_id;
                        let compare_attribute_from_original_global_tree = original_attributes.find(x => x.attribute_id === attribute_id_tmp);
                        if (!validateVar(compare_attribute_from_original_global_tree) || (validateVar(compare_attribute_from_original_global_tree) && this.check_global_tree_branches_for_changes(compare_attribute_from_original_global_tree.branches, attribute.branches))) {
                            let attribute_in_api_format = global_tree_attributes_obj.tree_attributes.find(x => x.attribute_id === attribute.attribute_id);
                            if (validateVar(attribute_in_api_format)) {
                                add_attributes_to_tariff.push(attribute_in_api_format); // get all added attributes
                            }
                        }
                    } else {
                        let attribute_in_api_format = global_tree_attributes_obj.tree_attributes.find(x => x.attribute_id === attribute.attribute_id);
                        if (validateVar(attribute_in_api_format)) {
                            add_attributes_to_tariff.push(attribute_in_api_format);
                        }
                    }
                }
            }
        }
        let tariff = tariff_obj.tariff;
        let tree_is_empty = true;
        let {groups} = selected_tree;
        for (let group of groups){
            if (group.tree_attributes.length > 0) {
                tree_is_empty = false;
                tree_attributes_obj = this.convert_tree_attributes_in_api_format(group.tree_attributes);
                if (tree_attributes_obj.has_error.length > 0) {
                    attribute_error_array.push(...tree_attributes_obj.has_error);
                }
                if (add_attributes_to_tariff.length > 0 && global_attribute_error_array.length === 0) {
                    tree_attributes_obj.tree_attributes.push(...add_attributes_to_tariff); // add global edited attributes to all groups
                }
                let new_group = {
                    id: group.id,
                    description: group.description,
                    name: group.name,
                    tree_attributes: tree_attributes_obj.tree_attributes,
                };
                save_groups.push(new_group);
            } else {
                if (!is_global_page) group_error_array.push(group.id);
            }
        }
        let tree_data = {
            id:  (validateVar(this.tree_id)) ? this.tree_id : 0,
            tariff_id:  (validateVar(this.tariff_id)) ? this.tariff_id : 0,
            is_rlm:  validateVar(tariff_tree) && validateVar(tariff_tree.is_rlm) ? tariff_tree.is_rlm : 0,
            is_global:  (is_global_page) ? 1 : 0,
            is_slp: validateVar(tariff_tree) && validateVar(tariff_tree.is_slp) ? tariff_tree.is_slp : 0,
            is_gas:  validateVar(tariff_tree) && validateVar(tariff_tree.is_gas) ? tariff_tree.is_gas : 0,
            is_electricity:  validateVar(tariff_tree) && validateVar(tariff_tree.is_electricity) ? tariff_tree.is_electricity : 0,
            type: validateVar(tariff_tree) && validateVar(tariff_tree.type) ? tariff_tree.type : "list",
            groups: save_groups
        };
        let tree_has_error = (is_false(is_global_page) && tariff.tariff_type === "STATIC+TABLE") ? false : this.tree_error_handling(tree,selected_tree, attribute_error_array, group_error_array, global_attribute_error_array);
        let tree_id =  (validateVar(this.tree_id)) ? this.tree_id : 0;
        let method = (tree_id > 0) ? "PUT" : "POST"; // create new tree or update
        if (is_true(is_global_page)) { // if its only global tree
            if (is_false(tree_has_error)) { // only execute request if tree has no errors
                if (is_true(this.check_if_attributes_were_deleted(save_groups, tree.original_global_tree.groups[0]))) {
                    let data = {
                        method: method,
                        tree_data: tree_data,
                        attributes: tree_attributes.global_attributes
                    };
                    this.toggle_warning_modal("delete_warning", data); // show warning if a attribute from global tree was deleted (will delete this attributes in tariffs too if overwritten)
                } else {
                    this.props.handle_tree_submit(method, tree_data, tree_attributes.global_attributes); // update / create request
                }
            }
        } else {
            if (tariff.tariff_type === "STATIC+TABLE") { // tariff with price_table -> submit tree and price table
                let price_table_data = tariff_obj.pricing_values; // reducer values
                let validation_result = this.validate_price_table_data(price_table_data);
                if(is_false(validation_result.has_error)){ // only execute request if tree and price_table has no errors
                    price_table_data.valid_until = handleFormatDate(price_table_data.date, true); // date format for api
                    price_table_data.tariff_id = this.tariff_id;
                    if (is_true(tree_is_empty)) { // wenn der baum empty ist (in fall von preistabelle ok)
                        let original_tree = tree.original_tree;
                        // check ob der bei seitenladen geladene baum gruppen hatte ( wenn ja müssen wir ihn löschen)
                        if (validateVar(original_tree) && validateVar(original_tree.groups.length >= 1) && original_tree.groups[0].tree_attributes.length > 0) {
                            this.props.handle_tree_submit(method, tree_data, all_atributes, price_table_data); // update tree and price table
                        } else { // wenn er vorher schon leer war können wir den baum speichern request skippen und speichern nur die preistabelle
                            this.props.handle_price_table_submit(price_table_data); // speicher preistable
                        }
                    } else { // update tree with price table
                        let tree_has_error = this.tree_error_handling(tree, selected_tree, attribute_error_array, group_error_array, global_attribute_error_array);
                        if (is_false(tree_has_error)) {
                            this.props.handle_tree_submit(method, tree_data, all_atributes, price_table_data); // update tree and price table
                        } else {
                            this.set_price_table_error(validation_result, price_table_data);
                        }
                    }
                }else { // error found -> set error_state in reducer so child component can show it
                    this.tree_error_handling(tree,selected_tree, attribute_error_array, group_error_array, global_attribute_error_array);
                    this.set_price_table_error(validation_result, price_table_data);
                }
            } else {  // only submit normal tree
                if (is_false(tree_has_error)) { // only execute request if tree has no errors
                    tree_data.tariff_id = this.tariff_id;
                    this.props.handle_tree_submit(method, tree_data, all_atributes);
                }
            }
        }
    };

    tree_error_handling = (tree, selected_tree, attribute_error_array, group_error_array, global_attribute_error_array) => {
        let has_error = false;// return value
        if (attribute_error_array.length > 0 || group_error_array.length > 0 || global_attribute_error_array.length > 0) { // if error found
            has_error = true; // return value
            this.setState({
                submitted: false
            });
            if (attribute_error_array.length > 0 || global_attribute_error_array.length > 0) this.props.showErrorMessage('Bitte verbessern Sie die fehlerhaften Eingaben', 'Fehler bei dem Speichern');
            else if (group_error_array.length > 0) this.props.showErrorMessage('Eine oder mehrere Gruppen haben keine Attribute', 'Fehler bei dem Speichern');
            if (attribute_error_array.length > 0 || group_error_array.length > 0) this.create_tree(selected_tree, attribute_error_array, group_error_array); // reload normal tree if error
            if (global_attribute_error_array.length > 0) this.create_tree(tree.global_tree, global_attribute_error_array, []) // reload  global tree // todo is_original_global_tree
        }
        return has_error;
    };

    validate_price_table_data = (price_table_data) => {
        let special_cases = {
            template_id: {case_name: 'select'},
            date: {case_name: "date"},
        };
        return new Validation(price_table_data, special_cases);
    };

    toggle_all_attribute_list_modal = (group, is_global) => {
        this.all_attribute_list_modal.current.toggleModal(group, is_global);
    };

    toggle_warning_modal = (modal_type, inserted_data) => {
        let data = {};
        if (modal_type === "group") {
            data = {
                group_id: inserted_data,
                type: "delete",
                action: "group"
            };
            this.setState({
                warning_modal_content: {
                    title:'Gruppierung löschen',
                    text: 'Sind Sie sich sicher, dass Sie die Gruppierung löschen wollen?',
                    primaryBtnText: 'Löschen'
                }
            });
            // this.warning_modal.current.showModal(data);
        } else if (modal_type === "delete_warning") {
            this.setState({
                warning_modal_content: {
                    title:'Achtung',
                    text: 'Sie sind im Begriff ein oder mehrere globale Attribute vollständig aus dem Baum zu entfernen. Sollte dieses Attribut in den Tarifen überschrieben worden sein, werden auch diese Bepreisungskriterien gelöscht (Dies geschiet automatisch und kann einige Minuten dauern).',
                    primaryBtnText: 'Fortfahren',
                    secondaryBtnText: 'Abbrechen'
                }
            });
            data = inserted_data;
            data.action = "save_tree";
        } else {
            this.setState({
                warning_modal_content: {
                    title:'Globale Kriterien zurücksetzen?',
                    text: 'Sind Sie sich sicher, dass Sie die Globalen Kriterien auf die originalen globalen Kriterien zurücksetzen wollen?',
                    primaryBtnText: 'Zurücksetzen',
                    secondaryBtnText: 'Abbrechen'
                }
            });
            data = {
                action: "reset_global_tree"
            }
        }
        this.warning_modal.current.showModal(data);
    };

    toggle_handle_groups_modal = (type, group = null) => {
        this.handle_groups_modal.current.toggleModal(type, group);
    };

    add_attribute_to_group_ = (data) => {
        let {tree_attributes} = this.props;
        let attribute_type =  (is_true(data.global)) ? "global_attributes" : "attributes";
        data.tree_attributes = tree_attributes[attribute_type];
        this.props.add_attribute_to_group(data);
    };

    handle_groups_modal_action = (data) => {
        if (data.type === "add") {
            data.tree_id = this.tree_id;
            this.props.group_add(data)
        } else if (data.type === "copy") {
            this.props.group_copy(data)
        } else if (data.type === "edit") {
            this.props.group_edit(data);
        }else if (data.type === "delete") {
            this.warning_modal.current.turnOffModal();
            this.props.group_delete(data.group_id)
        }else if (data.type === "delete") {
            this.warning_modal.current.turnOffModal();
            this.props.group_delete(data.group_id);
        } else alert("Es ist ein Fehler aufgetreten")
    };

    create_group_header = (group, dropdown_btn_sort_value = [], index, dropdown_options_group, group_has_error = false, is_global, is_global_edit_able, is_original_global_tree) => {
        let {disable_all_dropdown_group_btn, is_global_page} = this.state;
        return <GroupHeader
            group={group}
            is_global={is_global}
            is_global_edit_able={is_global_edit_able}
            is_original_global_tree={is_original_global_tree}
            is_global_page={is_global_page}
            classes={(is_true(group_has_error)) ? "alert-border" : ""}
            dropdown_options_group={dropdown_options_group}
            disable_all_dropdown_group_btn={disable_all_dropdown_group_btn}
            dropdown_btn_sort_value={dropdown_btn_sort_value}
            toggle_handle_groups_modal={this.toggle_handle_groups_modal}
            handle_global_tree_editable={this.handle_global_tree_editable}
            reset_global_tree={this.reset_global_tree}
            toggle_warning_modal={this.toggle_warning_modal}
            sort_tree_groups={this.props.sort_tree_groups}
            toggle_all_attribute_list_modal={this.toggle_all_attribute_list_modal}
            index={index}
        />
    };

    add_branch = (data, unique_id) => {
        data.unique_id = unique_id;
        this.props.add_branch(data);
    };

    delete_branch = (data, unique_id) => {
        data.unique_id = unique_id;
        this.props.delete_branch(data);
    };

    create_attribute_field = (tree_attribute, last_attribute_of_group = false, unique_id, group_id, attibute_error_array, is_global, is_global_edit_able) => {
        let bg_color_class = "bg-secondary";
        let {is_global_page} = this.state;
        if (validateVar(tree_attribute.is_edited)) bg_color_class = "bg-edited";
        let margin = (is_true(last_attribute_of_group)) ? " mb-5" : " mb-1"; // more margin to create space between tree groups
        return <>
            <AttributeField
            tree_attribute={tree_attribute}
            is_global={is_global}
            is_global_edit_able={is_global_edit_able}
            is_global_page={is_global_page}
            unique_id={unique_id}
            attibute_error_array={attibute_error_array}
            margin_bottom={margin}
            bg_color_class={bg_color_class}
            group_id={group_id}
            add_branch={(data) => this.add_branch(data, unique_id)}
            set_text_form={(data) => this.props.set_text_form(data, unique_id)}
            edit_branch={(data) => this.props.edit_branch(data)}
            delete_branch={(data) => this.delete_branch(data, unique_id)}
            edit_branch_operator={(data) => this.props.edit_branch_operator(data)}
        />
        </>;
    };

    get_submit_button = (is_loaded) => {
        let {submitted} = this.state;
        return  <div>
            <span id={'tooltipWrapper'}>
                <TooltipButton
                    id='submitPricestrategy'
                    disabled={(is_false(is_loaded) || is_true(submitted))}
                    loading={(is_true(submitted))}
                    value={this.dynamicSettings.submitButtonText}
                    onClick={() => this.save_tree()}
                    classes={'full-page-submit '+this.dynamicSettings.submitCustomClass}
                />
            </span>
                <GreyLinkButton
                    text={this.dynamicSettings.cancelButtonText}
                    classes='mt-4 mb-3 pb-2 eless-font-bold'
                    onClick={this.dynamicSettings.cancelFunction}
                    disabled={!is_loaded}
                />
        </div>
    };

    handling_warning_modal_action = (data) => {
        if (data.action === "reset_global_tree") {
            this.reset_global_tree();
        } else if (data.action === "save_tree") {
            this.props.handle_tree_submit(data.method, data.tree_data, data.attributes);
        } else {
            this.handle_groups_modal_action(data)
        }
        this.warning_modal.current.turnOffModal();
    };

    render() {
        let {tree_attributes, tariff_obj, dynamic_action_call, default_file_upload} = this.props;
        let tariff = tariff_obj.tariff;
        let {all_groups, all_groups_global, is_loaded_tree,is_loaded_tree_global, is_global_page, is_loaded_tariff, loading_modal_id, display_modal, show_loading_modal, warning_modal_content} = this.state;
        let {title, text, primaryBtnText, secondaryBtnText} = warning_modal_content;

        return (
            <>
                <SubmitLoadingAnimationModal id={loading_modal_id} display={display_modal}/>
                <AllAttributeModal
                    listAttributes={tree_attributes}
                    show_global_attributes={false}
                    add_attribute={(data) => this.add_attribute_to_group_(data)}
                    delete_attribute={(data) => this.delete_branch()}
                    ref={this.all_attribute_list_modal}
                />
                <GroupsModal
                    ref={this.handle_groups_modal}
                    submit={this.handle_groups_modal_action}
                />
                <CustomAlert
                title={title}
                text={text}
                primaryBtnText={primaryBtnText}
                primaryBtnClicked={(data) => this.handling_warning_modal_action(data)}
                secondaryBtnClicked={() => this.warning_modal.current.turnOffModal()}
                secondaryBtnText={secondaryBtnText}
                ref={this.warning_modal}
                allowToggle
            />
                {(is_global_page) ?
                    <SimpleHeader name="Globale Kriterien" headerText={''} />
                    :
                <AddTariffHeader>
                    <div className='table-centered'>
                        {is_true(is_loaded_tariff) ? <h2 className='light mb-0 d-inline-block mr-3'>Tarif bearbeiten - {tariff.name}</h2> : <span className='pr-2'><Skeleton width={320} height={30}/></span>}
                        {is_true(is_loaded_tariff) && validateVar(tariff.lifecycle_status) ? <Badge value={tariff.lifecycle_status} color={tariff.status_color} /> : <Skeleton width={160} height={30}/>}
                    </div>
                </AddTariffHeader>}
                <Container className='mt--6 pb-3' fluid>
                    {(!is_global_page) && <Row>
                        <Col>
                            <Card>
                                <CardBody className='py-1'>
                                    <MaterialUIStepper onRedirect={(url) => this.props.redirect(url)} active={1} tariffId={this.tariff_id} treeId={this.tree_id} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}
                    <Card>
                        <CardHeader>
                            <h2 className={'mb-0'}>Globale Kriterien</h2>
                        </CardHeader>
                        <CardBody id='global-criteria' className='tree-attributes'>
                            {is_true(is_loaded_tree_global) && all_groups_global.map((group) => {
                                return group;
                            })}
                            {is_true(is_global_page) && <div>
                                {this.get_submit_button(is_loaded_tree_global)}
                            </div>}
                        </CardBody>
                    </Card>
                    {is_false(is_global_page) && <>
                    <Card>
                        <CardHeader>
                            <h3 className='mb-0'>Tarifbezogene Kriterien</h3>
                        </CardHeader>
                        <CardBody id='tariff-criteria' className='tree-attributes'>
                            {is_true(is_loaded_tree) && all_groups.map((group) => {
                                return group;
                            })}
                        </CardBody>
                    </Card>
                    {(validateVar(tariff) && tariff.tariff_type === "STATIC+TABLE") &&
                    <Card className="mt-3" fluid>
                        <TariffPriceTableContainer
                            is_loaded_page={is_false(display_modal)}
                            tariff={tariff}
                            ref={this.price_table}
                            dynamic_action_call={dynamic_action_call}
                            default_file_upload={default_file_upload}
                        />
                    </Card>}
                    {(is_loaded_tariff) && this.get_submit_button(is_loaded_tariff && is_loaded_tree_global)}
                    </>}
                </Container>

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        tree_attributes: state.tree_attributes,
        tariff_obj: state.tariffs,
        tree: state.tree,
        company: state.company,
        message_feedback: state.message_feedback,
    };
};

const mapDispatchToProps =  {
    init_all_attributes: init_all_attributes,
    update_price_table_values: update_price_table_values,
    init_tree: init_tree,
    init_global_tree: init_tree,
    init_tariff: init_tariff,
    reset_tree: reset_tree,
    create_empty_tree: create_empty_tree,
    handle_tree_submit: handle_tree_submit,
    handle_price_table_submit: handle_price_table_submit,
    sort_tree_groups: sort_tree_groups,
    add_attribute_to_group: add_attribute_to_group,
    delete_attribute_from_group: delete_attribute_from_group,
    group_add: group_add,
    group_copy: group_copy,
    group_delete: group_delete,
    group_edit: group_edit,
    delete_branch: delete_branch,
    add_branch: add_branch,
    set_text_form: set_text_form,
    edit_branch: edit_branch,
    sort_branches: sort_branches,
    edit_branch_operator: edit_branch_operator,
    showErrorMessage: showErrorMessage,
    redirect: redirect,
    //FileUpload requests
    dynamic_action_call: dynamic_action_call,
    default_file_upload: upload_file_new,
};


export default connect(mapStateToProps, mapDispatchToProps)(NewTree);

