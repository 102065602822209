import validateVar from '../Functions/validation/validateVariable';
import {apiError, emptyValue} from '../Functions/global';
import getMediumType from "../Functions/getMediumType";
import getGermanDateFormat from "../Functions/getGermanDateFormat";
import ConsumptionPoint from "./consumption_point";
import Contract from "../../module_components/Objects/contract";
import getTranslatedCustomerSegment from "../Functions/getTranslatedCustomerSegment";
import getTranslatedEEIndividual from "../Functions/getTranslatedEEIndividual";
import getDisplayConsumption from "../Functions/getDisplayConsumption";
import handleFormatWattlineId from "../Functions/handleFormatWattlineId";
import getCommunicationClass from "../Functions/getCommunication";
import RecommendedAction from "./recommended_action";
import getTimestamp from "../Functions/getTimestamp";
import handleFormatDateForFrontend from "../Functions/handleFormatDateForFrontend";
import handleFormatNumber from "../Functions/handleFormatNumber";
import translate_external_status from "../Functions/translate_external_status";
import getBadgeColor from "../Functions/getBadgeColor";

class CalculationUnit {
    constructor(data, status = "open") {
        //basic
        this.calculation_unit_id = '';
        this.calculation_unit_id_display = '';
        this.bundle_type = '';
        this.bundle_type_display = '';
        this.consumption_type = '';
        this.created_at = '';
        this.created_at_display = '';
        this.created_at_timestamp = '';
        this.customer_advice = '';
        this.customer_advice_display = '';
        this.customer_bundle_id = '';
        this.customer_bundle_name = '';
        this.customer_branche_wattline = '';
        this.customer_bundle_number = '';
        this.customer_name = '';
        this.customer_contact_person = '';
        this.customer_phone = '';
        this.customer_email = '';
        this.customer_number = '';
        this.customer_segment = '';
        this.end_of_term = '';
        this.end_of_term_display = '';
        this.end_of_term_timestamp = '';
        this.enery_source = '';
        this.limit = '';
        this.medium_type = '';
        this.medium_type_display = '';
        this.number_consumption_points = '';
        this.offset = '';
        this.primary_contact = '';
        this.updated_at = '';
        this.energy_source_display = '';
        this.status = '';
        this.translated_status = '';
        this.status_color = '';
        this.calculation_packet_id = '';
        this.calculation_packet_id_display = '';
        this.ee_individual = '';
        this.total_mean_consumption = '';
        this.total_mean_consumption_formatted = '';



        this.last_recommended_action_token = '';
        this.last_external_supplier_token = '';
        this.last_external_additional_supplier_token = '';



        this.total_mean_consumption_display = '';
        this.user = '';
        this.country = '';
        this.bundle_number = '';
        this.bundle_name = '';
        this.branche_wz08 = '';
        this.coop = '';
        this.calculation_offer_begin = '';
        this.recommended_actions = [];
        this.additional_supplier = emptyValue;
        this.alternative_supplier = emptyValue;
        this.calculated_offers = '';
        this.external_status = '';
        this.additional_external_status = '';

        this.consumption_points = [];
        this.contracts = [];
        this.customer_information = emptyValue;
        this.customer_pkv_information = emptyValue;

        //load basic info
        if (validateVar(data) && validateVar(data.id)) {
            // standard variables
            this.calculation_unit_id = validateVar(data.id) ? data.id : '';
            this.calculation_unit_id_display = validateVar(data.id) ? handleFormatWattlineId(data.id, 'KAE') : '';
            this.bundle_type = validateVar(data.bundle_type) ? data.bundle_type : '';
            this.consumption_type = validateVar(data.consumption_type) ? data.consumption_type : '';
            this.created_at = validateVar(data.created_at) ? data.created_at : '';
            this.customer_advice = validateVar(data.customer_advice) ? data.customer_advice : '';
            this.customer_bundle_id = validateVar(data.customer_bundle_id) ? data.customer_bundle_id : '';
            this.customer_bundle_name = validateVar(data.customer_bundle_name) ? data.customer_bundle_name : '';
            this.customer_name = validateVar(data.customer_name) ? data.customer_name : '';
            this.customer_number = validateVar(data.customer_number) ? data.customer_number : '';
            this.customer_segment = validateVar(data.customer_segment) ? data.customer_segment : '';
            this.customer_segment_display = validateVar(data.customer_segment) ? getTranslatedCustomerSegment(data.customer_segment) : emptyValue;
            this.end_of_term = validateVar(data.end_of_term) ? data.end_of_term : emptyValue;
            this.end_of_term_display = validateVar(data.end_of_term) ? getGermanDateFormat(data.end_of_term) : emptyValue;
            this.end_of_term_timestamp = validateVar(data.end_of_term) ? getTimestamp(data.end_of_term) : emptyValue;
            this.energy_source = validateVar(data.energy_source) ? data.energy_source : emptyValue;
            this.energy_source_display = validateVar(data.energy_source) ? this.getEnergySourceDisplay(data.energy_source) : emptyValue;
            this.limit = validateVar(data.limit) ? data.limit : '';
            this.medium_type = validateVar(data.medium_type) ? data.medium_type : '';
            this.number_consumption_points = validateVar(data.number_consumption_points) ? data.number_consumption_points : '';
            this.offset = validateVar(data.offset) ? data.offset : '';
            this.primary_contact = validateVar(data.primary_contact) ? data.primary_contact : '';
            this.updated_at = validateVar(data.updated_at) ? data.updated_at : '';
            this.contract_duration = validateVar(data.default_term) ? this.getContractDurationDisplay(data.default_term) : 'Keine Vorgabe';
            this.default_term = validateVar(data.default_term) ? data.default_term : 1;
            this.status = validateVar(data.status) ? data.status : '';
            this.translated_status = validateVar(data.status) ? this.getTranslatedStatus(data.status) : '';
            this.status_color = validateVar(data.status) ? this.getStatusColor(data.status) : '';
            this.calculation_packet_id = validateVar(data.calculation_packet_id) ? data.calculation_packet_id : '';
            this.calculation_packet_id_display = validateVar(data.calculation_packet_id) ? handleFormatWattlineId(data.calculation_packet_id, 'KAP') : '';
            this.ee_individual = validateVar(data.ee_individual) ? data.ee_individual : emptyValue;
            this.ee_individual_display = validateVar(data.ee_individual) ? getTranslatedEEIndividual(data.ee_individual) : emptyValue;
            this.total_mean_consumption = validateVar(data.total_mean_consumption) ? data.total_mean_consumption : emptyValue;
            this.total_mean_consumption_formatted = validateVar(data.total_mean_consumption) ? handleFormatNumber(data.total_mean_consumption) : emptyValue;
            this.min_quantity_tolerance = validateVar(data.min_quantity_tolerance) ? handleFormatNumber(data.min_quantity_tolerance) : 0;
            this.max_quantity_tolerance = validateVar(data.max_quantity_tolerance) ? handleFormatNumber(data.max_quantity_tolerance) : 0;
            this.total_mean_consumption_display = validateVar(data.total_mean_consumption) ? getDisplayConsumption(data.total_mean_consumption) : emptyValue;
            this.user = validateVar(data.user) ? data.user : emptyValue;
            this.country = validateVar(data.country) ? data.country : emptyValue;
            this.bundle_number = validateVar(data.bundle_number) ? data.bundle_number : emptyValue;
            this.bundle_name = validateVar(data.bundle_name) ? data.bundle_name : emptyValue;
            this.coop = validateVar(data.coop) ? data.coop : emptyValue;
            this.calculation_offer_begin = validateVar(data.calculation_offer_begin) ? data.calculation_offer_begin : emptyValue;
            this.calculated_offers = validateVar(data.calculated_offers) ? data.calculated_offers : emptyValue;
            this.last_recommended_action_token = '';
            this.last_external_supplier_token = '';
            this.last_external_additional_supplier_token = '';
            this.last_recommended_action_token = validateVar(data.last_recommended_action_token) ? data.last_recommended_action_token : "";
            this.last_external_supplier_token = validateVar(data.last_external_supplier_token) ? data.last_external_supplier_token : "";
            this.last_external_additional_supplier_token = validateVar(data.last_external_additional_supplier_token) ? data.last_external_additional_supplier_token : "";
            this.calculation_offer_begin_display = validateVar(data.calculation_offer_begin) ? handleFormatDateForFrontend(data.calculation_offer_begin) : emptyValue;
            this.external_status = validateVar(data.external_status) ? data.external_status : emptyValue;
            this.additional_external_status = validateVar(data.additional_external_status) ? data.additional_external_status : emptyValue;
            this.translated_external_status = validateVar(data.external_status) ? translate_external_status(data.external_status) : emptyValue;
            this.translated_additional_external_status = validateVar(data.additional_external_status) ? translate_external_status(data.additional_external_status) : emptyValue;
            this.external_status_color = validateVar(data.external_status) ? getBadgeColor(data.external_status, ['submitted'], ['checkout', 'empty'], ['overdue']) : emptyValue;
            this.additional_external_status_color = validateVar(data.additional_external_status) ? getBadgeColor(data.additional_external_status, ['submitted'], ['checkout', 'empty'], ['overdue']) : emptyValue;



            if (validateVar(data.recommended_actions) && data.recommended_actions.length > 0) {
                for (let recommended_action of data.recommended_actions) {
                    this.recommended_actions.push(new RecommendedAction(recommended_action));
                }
            }

            // display variables
            this.medium_type_display = validateVar(data.medium_type) ? getMediumType(data.medium_type) : '';
            this.customer_advice_display = validateVar(data.customer_advice) ? this.getCustomerAdviceDisplay(data.customer_advice) : '';
            this.created_at_display = validateVar(data.created_at) ? getGermanDateFormat(data.created_at) : '';
            this.created_at_timestamp = validateVar(data.created_at) ? getTimestamp(data.created_at) : '';
            this.bundle_type_display = validateVar(data.bundle_type) ? this.getBundleTypeDisplay(data.bundle_type) : '';
            this.coop_display = validateVar(data.coop) ? this.getCoopDisplay(data.coop) : emptyValue;

            if (validateVar(data.customer)) {
                const customer = data.customer;
                this.customer_id = customer.id;
                if (validateVar(customer.customer_information)) {
                    const customer_information = customer.customer_information;

                    this.customer_name = customer_information.company;
                    this.customer_first_name = customer_information.first_name;
                    this.customer_last_name = customer_information.last_name;
                    this.customer_contact_person = this.customer_first_name + ' ' + this.customer_last_name;
                    this.customer_phone = customer_information.phone;
                    this.customer_email = customer_information.email;

                    if(customer_information.billing_address){
                        const customer_billing = customer_information.billing_address;

                        this.customer_billing_postal_code = validateVar(customer_billing.zip) ? customer_billing.zip : '';
                        this.customer_billing_street = validateVar(customer_billing.line1) ? customer_billing.line1 : '';
                        this.customer_billing_house_number = validateVar(customer_billing.line2) ? customer_billing.line2 : '';
                        this.customer_billing_city = validateVar(customer_billing.city) ? customer_billing.city : '';
                        this.customer_billing_street_and_house_number = this.customer_billing_street + ' ' + this.customer_billing_house_number;
                        this.customer_billing_postal_code_and_city = this.customer_billing_postal_code + ' ' + this.customer_billing_city;
                    }
                }

                if (validateVar(customer.wattline_information)) {
                    const wattline_info = customer.wattline_information;

                    this.customer_branche_wattline = wattline_info.branche_wz08;
                    this.customer_bundle_number = validateVar(wattline_info.bundle_number) ? wattline_info.bundle_number : emptyValue;
                    this.customer_bundle_name = wattline_info.bundle_name;
                    this.customer_information = validateVar(wattline_info.customer_information) ? wattline_info.customer_information : emptyValue;
                    this.customer_pkv_information = validateVar(wattline_info.pkv_information) ? wattline_info.pkv_information : emptyValue;
                }
            }

            if (validateVar(data.contracts)) {
                let contracts = data.contracts;
                for (let contract of contracts) {
                    if (typeof contract === 'object') {
                        this.contracts.push(new Contract(contract));
                    }
                }
            }

            if (data.hasOwnProperty('consumption_points')) {
                let consumption_points = data.consumption_points;
                for (let index in consumption_points) {
                    if (consumption_points.hasOwnProperty(index)) {
                        let consumption_point = consumption_points[index];
                        this.consumption_points.push(new ConsumptionPoint(consumption_point));
                    }
                }
            }
        }
    }

    getContractDurationDisplay(default_term) {
        if (default_term === '12 Months') return '12 Monate';
        else if (default_term === '24 Months') return "24 Monate";
        else if (default_term === '36 Months') return "36 Monate";
        else if (default_term === 'individual') return 'Individuell';
        else return 'Keine Vorgabe';
    }

    getDisplayValueFromYesOrNo(value) {
        if (value === 'YES') return 'Ja';
        else if (value === 'NO') return 'Nein';
        else return value;
    }

    getCustomerAdviceDisplay(customer_advice) {
        if (customer_advice === 'YES') return 'Ja';
        else if (customer_advice === 'NO') return 'Nein';
    }

    getBundleTypeDisplay(bundle_type) {
        if (bundle_type === 'automatic') return 'Automatisch';
        else return 'Manuell';
    }

    getEnergySourceDisplay(energy_source) {
        if (energy_source === 'green') return 'Ja';
        else return 'Nein';
    }

    getCoopDisplay(coop) {
        if (coop === 'YES') return 'Ja';
        else return 'Nein';
    }

    getTranslatedStatus(status){
        if (status === 'active') return 'Aktiv';
        else if (status === 'deleted') return 'Gelöscht';
        else if (status === 'submitted') return 'Eingereicht';
        else if (status === 'blocked') return 'Blockiert';
        else return '';
    }

    getStatusColor(status){
        if (status === 'active') return 'green';
        else if (status === 'deleted') return 'red';
        else if (status === 'submitted') return 'orange';
        else if (status === 'blocked') return 'grey';
        else return '';
    }

    /*
      Instantiate a new Calculation Unit with its ID as Param
      Call Communication Class with matching global defined Vars for API Request
      return new Calculation Unit Class(es)
   */
    static async get_calculation_unit_list(params) {
        let communication_params = {
            method: 'GET', // GET / POST / PUT / DELETE  -> method
            function_url: 'wattline/calculation/unit',
            params: params
        };

        let c = getCommunicationClass(communication_params);

        let result = await c.request_handler();
        if (result !== apiError) {
            let listResults = [];
            let units = result.units;

            for (var i = 0; i < units.length; i++) {
                listResults.push(new CalculationUnit(units[i]));
            }
            return {
                units: listResults,
                next_offset: result.next_offset,
                last_request: result.last_request,
                total_count: result.total_count
            };
        } else return result;
    }


    static async get_calculation_unit(id) {
        let communication_params = {
            method: 'GET', // GET / POST / PUT / DELETE  -> method
            function_url: 'wattline/calculation/unit/' + id
        };

        let c = getCommunicationClass(communication_params);

        let result = await c.request_handler();
        if (result !== apiError) {
            return new CalculationUnit(result);
        } else return result;
    }


    static async start_request(communication) {
        return await communication.request_handler();
    }
}

export default CalculationUnit;


