import React from "react";
import Button from "reactstrap/lib/Button";
import ButtonLoadingAnimation from "../../LoadingAnimations/ButtonLoadingAnimation";
import PropTypes from 'prop-types';

const PrimaryButton = (props) => {
    var classes = '';
    if(typeof props.classes !== 'undefined') classes = props.classes;

    return(
        <Button disabled={props.disabled} onClick={props.onClick} className={'m-0 btn eless-btn primary-btn '+classes}>
            {props.value} {props.loading && <ButtonLoadingAnimation />}
        </Button>)
};

PrimaryButton.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.oneOf(PropTypes.string, PropTypes.object),
    classes: PropTypes.string,
    loading: PropTypes.bool,
};

export default PrimaryButton;