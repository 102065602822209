let initialState = null;

function net_fee(state = initialState, action) {
  if (action.type === "GET_NET_FEE") {
    return action.res;
  } else {
    return state;
  }
}

export default net_fee;
