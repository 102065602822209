import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";

let add_ons_vars = {
    customers: {
        is_sub_arr: "customers",
        max_customers: {
            max_customers_addition: null,
            max_customers_sum: {
                function_name: "getMaxEmployeeSum" // todo auslagern
            }
        },
        quantity: {
            quantity_customers_addition: null
        },
        unit_price: {
            unit_customer_price: null
        },
    },
    employees: {
        is_sub_arr: "customers",
        max_customers: {
            max_employees_addition: null,
            max_employees_sum: {
                function_name: "getMaxEmployeeSum" // todo auslagern
            }
        },
        quantity: {
            quantity_employees_addition: null
        },
        unit_price: {
            unit_employees_price: null
        },
    },
    tree_groups: {
        is_sub_arr: "tree_groups",
        quantity: {
            allowed_group_quantity: null
        }
    }
};

class Add_Ons {
    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(add_ons_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }
}

export default Add_Ons;
