import {emptyValue} from "./global";
import validateVar from "./validation/validateVariable";

function getMemoDisplay(memos, type){
    if(Array.isArray(memos) && memos.length > 0) {
        let memo = memos.find(memo => memo.subcategory === type);
        if(validateVar(memo) && validateVar(memo.memo)) return memo.memo;
        else return null;
    }else return null;
}

export default getMemoDisplay;