/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Navbar,
    Nav,
    Container,
    NavItem,
} from "reactstrap";
// functions
import {logoutUser} from "../../Functions/consultantActions";
// redux
import validateVar from "../../Functions/validation/validateVariable";
import getUserRole from "../../Functions/getUserRole";
import {all_urls} from "../../../globalNavigation";
import EmailLoadingAnimation from "./LoadingAnimations/EmailLoadingAnimation";
import MessagesAndNotifications from "../../PageContent/MessagesAndNotifications";
import RoundedImage from "../../Displays/Images/RoundedImage";
import {static_download_url} from "../../Functions/global";
import getAgencyID from "../../Functions/getAgencyID";
import is_false from "../../Functions/is_false";

class AdminNavbar extends React.Component {
    state = {
        is_loaded: false,
        show_max_message_count: 5,
    };

    componentDidUpdate(prevProps, prevState) {
        //call is in parent component admin.jsx
        let employee = this.props.employee;
        let company = this.props.company;
        if (employee !== prevProps.employee || company !== prevProps.company) {
            if((validateVar(employee)) && validateVar(company)){
                this.setCompanyData(company, employee);
            }
        }
    }

    setCompanyData = (company = null, employee = null) => {
        //always shown company data
        this.setState({
            company_data: {
                notifications_count: company.notifications_count,
                notifications: company.notifications,
                email: employee.email,
            },
            logo_image_source_url: static_download_url+employee.picture_id,
            employee_email: employee.email,
            is_loaded: true
        });
    };

    getDropDownItems = () => {
        let return_arr = [];

        for(let route of all_urls){
            let on_click = () => this.props.onRedirect(route.path);
            if(!validateVar(route.path) && route.component === 'subscribtion') on_click = () => this.props.chargeBeeModalOpen();

            let hide_for_current_agency = validateVar(route.exclude_agencies) && route.exclude_agencies.includes(getAgencyID(null, true).agency_id);
            if (route.category === "header" &&
                route.permissions.includes(getUserRole()) && //render item if current user has permissions for it
                is_false(hide_for_current_agency) //dont render DropdownItem for defined agencies in "exclude_agencies"
            ) {
                return_arr.push(<DropdownItem
                    onClick={on_click}
                    className={'cursor-pointer'}
                >
                    {validateVar(route.icon) &&  <i className={route.icon} />}
                    <span>{route.title}</span>
                </DropdownItem>)
            }
        }

        return return_arr;
    };

    render() {
        let {company_data, is_loaded, logo_image_source_url, employee_email} = this.state;
        const drodown_items = this.getDropDownItems()

        if(validateVar(company_data)){
            company_data.notifications_count = 1;
            company_data.notifications = [
                {url: '#', type: 'Benachrichtigung', message: 'Titel der Notification', status: 'new'}
            ]
        }

        return (
            <Navbar
                className={classnames(
                    "navbar-top navbar-expand border-bottom",
                    { "navbar-dark bg-info": this.props.theme === "dark" },
                    { "navbar-light bg-secondary": this.props.theme === "light" }
                )}
            >
                <Container fluid>
                    <Collapse navbar isOpen={true}>
                        <Nav className="align-items-center" navbar>
                            <NavItem className="d-xl-none">
                                <div
                                    className={classnames(
                                        "pr-3 sidenav-toggler",
                                        { active: this.props.sidenavOpen },
                                        { "sidenav-toggler-dark": this.props.theme === "dark" }
                                    )}
                                    onClick={this.props.toggleSidenav}
                                >
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                    </div>
                                </div>
                            </NavItem>

                        </Nav>
                        <Nav className="align-items-center ml-auto" navbar>

                            <MessagesAndNotifications/>
                            {is_loaded ?<UncontrolledDropdown  className='ml-3' nav>
                                <DropdownToggle className="white-color pr-0" color="" tag="a">
                                    <Media className="align-items-center cursor-pointer">
                                        <RoundedImage id='logo_image_display' url={logo_image_source_url} />
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold">
                                                {employee_email}
                                            </span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu className='pb-0 email-dropdown-menu' right>
                                    {drodown_items}
                                    {drodown_items.length > 0 && <DropdownItem divider />}
                                    <DropdownItem
                                        onClick={() => logoutUser()}
                                        className={'cursor-pointer text-center pt-0'}
                                    >
                                        <b>Logout</b>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>: <EmailLoadingAnimation />}
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}
AdminNavbar.defaultProps = {
    toggleSidenav: () => {},
    sidenavOpen: false,
    theme: "dark"
};
AdminNavbar.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf(["dark", "light"])
};

export default AdminNavbar;