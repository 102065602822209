/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from 'react';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Collapse,
} from 'reactstrap';
// core components
import AddTariffHeader from './components/AddTariffHeader';
// custom components
import PrimaryButton from '../../../module_components/Displays/Buttons/PrimaryButton';
import {addTariff, initializeTariff, redirect, showErrorMessage, updateTariff} from '../../../actions';
import connect from 'react-redux/es/connect/connect';
import {
    apiError,
    cancellation_period_options, empty_select_value,
    price_guarantee_type_options
} from '../../../module_components/Functions/global';
import {tree, tariff_list} from '../../../globalNavigation';
import {deHashVar} from '../../../module_components/Functions/handleHashedVariable';
import hasChanged from '../../../module_components/Functions/hasChanged';
import MaterialUIStepper from './components/MaterialUIStepper';
import validateVar from '../../../module_components/Functions/validation/validateVariable';
import InputHandler from '../../../module_components/Inputs/InputHandler';
import validateNumberMaxTwoDecimals from '../../../module_components/Functions/validation/validateNumberMaxTwoDecimals';
import Skeleton from 'react-loading-skeleton';
import replace_all_dots from "../../../module_components/Functions/replace_all_dots";
import GreyLinkButton from "../../../module_components/Displays/Buttons/GreyLinkButton";
import Validation from "../../../module_components/Objects/Components/validation";
import scrollToElement from "../../../module_components/Functions/scrollTo";
import is_true from "../../../module_components/Functions/is_true";
import Badge from "../../../module_components/Displays/Badges/Badge";
import is_false from "../../../module_components/Functions/is_false";

const supplier_id = deHashVar(localStorage.getItem('evuSecurityToken'));
const calculation_types = [
    {
        key: 'STANDARD',
        value: 'Dynamisch (Kalkulation über Bewertungsbaum)'
    }, {
        key: 'STATIC',
        value: 'Statisch'
    },{
        key: 'STATIC+TABLE',
        value: 'Statisch + Preistabelle'
    },
    {
        key: 'SPOT',
        value: 'Spotpreis (Börse)'
    }];
/*
    START CLASS
 */
class TariffHandleBasicData extends Component {
    constructor(props) {
        super(props);
        this.id = parseInt(props.match.params.id);
        let default_values = {
            name: '',
            description: '',
            price_guarantee_type: 'energy_price',
            price_type: 'energy_price',
            netfee_type: 'auto',
            medium_type: 'ELECTRICITY',
            tariff_type: 'STANDARD',
            customer_type: 'BUSINESS',//customer_type, //CUSTOMER  / BUSINESS
            energy_source: 'grey',
            cancellation_period: 'none',
            contract_extension: 'none',
            quantity_tolerance: '0',
            is_rlm: 'YES', //yes / no
            is_slp: 'YES', //yes / no
            payment_sepa_direct_debit: 'YES',
            payment_bank_transfer: 'YES',
            payment_cash: 'YES',
            payment_options: this.get_payment_options_for_new_tariff(),
        };

        if (this.id === 0) {
            this.state = { //Set default values on create
                quantity_tolerance_checked: false,
                next_step: false, //redirect
                disable_submit: false,
                submit_loading: false,
                cancellation_period_disabled: true,
                tree_id: 0,
                tariff_data: {...default_values},
                prevState: {...default_values},
                is_loaded: true,
                has_changed: false,
                redirect: false,
                tariff_edit: false,
                included_payment_options: ['payment_sepa_direct_debit', 'payment_bank_transfer', 'payment_cash'],
                included_payment_options_original: ['payment_sepa_direct_debit', 'payment_bank_transfer', 'payment_cash'],
                error_messages: {}
            };
        } else {
            this.state = {
                quantity_tolerance_checked: false,
                tariff_edit: true,
                next_step: false, //redirect
                is_loaded: false,
                has_changed: false,
                redirect: false,
                disable_submit: false,
                submit_loading: false,
                tariff_data: {},
                included_payment_options: [],
                error_messages: {},
            }
        }
    }

    componentDidMount() {
        if (this.id > 0) {
            this.props.onInitialize({
                supplier_id: supplier_id,
                tariff_id: this.id
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let {is_loaded, submit_loading, has_changed} = this.state;

        let tariff = {...this.props.tariff};
        if (validateVar(tariff) && tariff !== prevProps.tariff && validateVar(tariff.id)) {
            if (!is_loaded && tariff !== apiError && validateVar(prevProps.tariff) && validateVar(prevProps.tariff.id)) { // edit
                if (validateVar(tariff.is_added)) this.id = tariff.id;
                let quantity_tolerance_checked = false;
                if (tariff.quantity_tolerance > 0) quantity_tolerance_checked = true;

                let cancellation_period_disabled = true;
                if (tariff.contract_extension !== 'none') cancellation_period_disabled = false;

                // MULTISELECT
                let included_payment_options = []; // selected options in multiselect field
                let included_payment_options_default = []; // resetting
                let included_payment_options_original = []; // compare values with changed options (-> has_changed function)
                tariff.payment_options.map((payment_option) => {
                    if (+payment_option.accepted === 1) included_payment_options.push(payment_option.value); // push selected options -> set values
                    if (+payment_option.accepted === 1) included_payment_options_original.push(payment_option.value);
                    included_payment_options_default.push(payment_option.value);
                });

                this.setState({
                    included_payment_options_default: included_payment_options_default,
                    included_payment_options: included_payment_options,
                    included_payment_options_original: included_payment_options_original,
                    quantity_tolerance_disabled: !quantity_tolerance_checked,
                    quantity_tolerance_checked: quantity_tolerance_checked,
                    cancellation_period_disabled: cancellation_period_disabled,
                    tree_id: parseInt(tariff.tree_id),
                    redirect: true,
                    tariff_data: tariff,
                    is_loaded: true,
                });
            }else if (is_loaded && tariff !== apiError && submit_loading && !has_changed) {
                if (tariff.id > 0) this.id = tariff.id;
                this.setState({submit_loading: false});
            }
        }
    }

    get_payment_options_for_submit = (tariff_data) => { // set payment option values for saving function
        let payment_options = tariff_data.payment_options;
        let {included_payment_options} = this.state;

        for( let index in payment_options) {
            if (payment_options.hasOwnProperty(index)){
                let option =  payment_options[index];
                if (included_payment_options.includes(option.value)) tariff_data[option.value] = 'YES';
                else tariff_data[option.value] = 'NO';
            }
        }

        return tariff_data;
    };

    get_payment_options_for_new_tariff = () => {
        return [
            {
                label: 'SEPA-Lastschrift',
                value: 'payment_sepa_direct_debit',
                id: 'payment_sepa_direct_debit',
                accepted: 1
            },
            {
                label: 'Überweisung',
                value:  'payment_bank_transfer',
                id:  'payment_bank_transfer',
                accepted: 1
            },
            {
                label: 'Barzahlung',
                value:  'payment_cash',
                id:  'payment_cash',
                accepted: 1
            }
        ];
    };

    validate_checkboxes = () => {
        let {tariff_data} = this.state;
        if(tariff_data.is_rlm === 'NO' && tariff_data.is_slp === 'NO'){//On this page only this two inputs need to be validated
            return 'Wählen Sie bitte mindestens einen Zählertyp';
        } else return false;
    };

    handle_return = () => {
        this.props.redirect(tariff_list);
    };

    /* Vertragsverlängerung */
    handle_contract_extention_change = (value) => { //Disable cancallationPeriod if contract doesn't lengten automatically
        let {tariff_data} = this.state;
        //KZ / 02022022 / if there is no contract_extention then there is no cancellation period, if there is any
        // contract_extention then cancellation_period gets enabled and set to empty if it was 'none' before
        if(value === 'none'){
            tariff_data.cancellation_period = 'none';
            this.setState({cancellation_period_disabled: true});
        }else{
            if(tariff_data.cancellation_period === 'none') tariff_data.cancellation_period = empty_select_value;
            this.setState({cancellation_period_disabled: false});
        }
    };
    /* End Vertragsverlängerung */

    /* Mehr-/ Mindermengentoleranz */
    set_valid_quantity_tolerance = (value) => {
        let {tariff_data} = this.state;
        let replaced_value = value.replace(',', '.');

        if ((validateNumberMaxTwoDecimals(replaced_value) && (
            (replaced_value.length >= 2 && replaced_value.charAt(1) === '.') ||
            (replaced_value.length >= 3 && replaced_value.charAt(2) === '.') ||
            replaced_value.length < 3))
            || replaced_value === '') return value;
        else if(value.length === 3 && value === '100') return value; // KZ / 02022022 / allow 100 %
        else return tariff_data.quantity_tolerance;
    };

    update_state = (state_name, value) => {
        let prev_props = this.props.tariff;
        let {tariff_data, prevState, included_payment_options, included_payment_options_original, quantity_tolerance_checked} = this.state;
        let has_changed = false;
        if (this.id === 0) prev_props = prevState;
        if (state_name === 'included_payment_options') { // check if name is part of the current state (calculation_data)
            included_payment_options = value;
            state_name = 'dummy_name';
            value = undefined;
        }else if(state_name === 'quantity_tolerance'){
            value = this.set_valid_quantity_tolerance(value)
        }else if(state_name === 'quantity_tolerance_checked'){ // If checkbox unckecked, set tolerance to 0;
            quantity_tolerance_checked = !quantity_tolerance_checked;
            if (is_false(quantity_tolerance_checked)) tariff_data.quantity_tolerance = 0;
        }else if(state_name === 'contract_extension') {
            this.handle_contract_extention_change(value);
        }else if(state_name === 'customer_type'){
            if (value === "BUSINESS") {
                tariff_data.price_guarantee_type = "energy_price";
                tariff_data.netfee_type = "auto";
                tariff_data.price_type = "energy_price";
            } else if (value === "PRIVATE") {
                tariff_data.is_slp = "YES";
                tariff_data.is_rlm = "NO";
                tariff_data.price_type = "contract_price";
                tariff_data.quantity_tolerance = 0;
                quantity_tolerance_checked = false;
            }
        }else if(state_name === 'price_type'){
            if (value === "energy_price") {
                tariff_data.netfee_type = "auto";
                tariff_data.price_guarantee_type = "energy_price";
            }
        }else if(state_name === 'tariff_type'){
            if (value === "SPOT" || value === "STANDARD") {
                if (value === "SPOT") {
                    tariff_data.price_guarantee_type  = "none"
                }
                tariff_data.netfee_type = "auto";
            }else if (value === "STANDARD") {
                tariff_data.price_guarantee_type  = "energy_price"
            }
        }else if (state_name === 'is_rlm'){
            if(value === 'YES') {
                tariff_data.price_type = "energy_price";
                tariff_data.netfee_type = "auto";
            }
        }
        tariff_data[state_name] = value; // set new value in current state
        if (included_payment_options.length !== included_payment_options_original.length) has_changed = true; // first check if length is different (no need for a loop)
        else { // check if every value is the same in the array if same length
            included_payment_options_original.map((option) => {
                if (!included_payment_options.includes(option)) {
                    has_changed = true;
                }
            });
        }

        if(is_false(has_changed)) has_changed = hasChanged(value, state_name, prev_props, tariff_data);

        this.setState({
            tariff_data: tariff_data,
            has_changed: has_changed,
            included_payment_options: included_payment_options,
            quantity_tolerance_checked: quantity_tolerance_checked,
        });
    };

    validate_form = () => {
        const {tariff_data, included_payment_options} = this.state;

        let form_data = {
            name: tariff_data.name,
            description: tariff_data.description,
            included_payment_options: included_payment_options,
            cancellation_period: tariff_data.cancellation_period,
        };

        let special_cases = {
            included_payment_options: {case_name: 'multi_select'},
            cancellation_period: {case_name: 'select'},

        };
        let validation_result = new Validation(form_data, special_cases);
        validation_result.error_messages.is_slp_error = this.validate_checkboxes();
        if(validation_result.error_messages.is_slp_error) validation_result.has_error = true;
        this.setState({
            error_messages: validation_result.error_messages
        });

        if(validation_result.has_error) scrollToElement(document.getElementById('firstElement'));

        return validation_result.has_error;
    };

    redirect_to_next_step = () => {
        const {tariff} = this.props;
        const {tree_id} = this.state;

        let has_error = this.validate_form();

        if (validateVar(tariff) && !has_error) {
            this.setState({submit_loading: true});
            this.props.redirect(tree + "/" + this.id + '/' + tree_id);
        }
    };

    handle_submit = () => {//this function can be called through the primary_button or the 'footer'-submit (default)
        let {tariff_data, tree_id} = this.state;

        let quantity_tolerance_value = tariff_data.quantity_tolerance+'';
        quantity_tolerance_value = quantity_tolerance_value.replace(',', '.');
        let has_error = this.validate_form();

        if (!has_error){
            this.setState({submit_loading: true});

            tariff_data = this.get_payment_options_for_submit(tariff_data);
            let data = {
                supplier_id: supplier_id,
                name: tariff_data.name,
                description: tariff_data.description,
                tree_id: tree_id,
                medium_type: tariff_data.medium_type,
                tariff_type: tariff_data.tariff_type,
                customer_type: tariff_data.customer_type, //CUSTOMER  / BUSINESS
                energy_source: tariff_data.energy_source,
                cancellation_period: tariff_data.cancellation_period,
                contract_extension: tariff_data.contract_extension,
                price_guarantee_type: tariff_data.price_guarantee_type,
                price_type: tariff_data.price_type,
                quantity_tolerance: quantity_tolerance_value,
                is_rlm: tariff_data.is_rlm, //yes / no
                is_slp: tariff_data.is_slp, //yes / no
                payment_cash: tariff_data.payment_cash, //yes / no
                payment_bank_transfer: tariff_data.payment_bank_transfer, //yes / no
                payment_sepa_direct_debit: tariff_data.payment_sepa_direct_debit, //yes / no
                netfee_type: tariff_data.netfee_type //auto / manual
            };
            if (this.id === 0)  {
                this.props.onAddTariff(data);
            }else if (this.id > 0) {
                data.tariff_id = this.id;
                this.props.onUpdateTariff(data, tree + '/' + this.id + '/' + tree_id);
            }
        }
    };

    get_disable_netfee = (tariff_data, tariff_edit) => {
        if (is_true(tariff_edit)) return true;
        else {
            let {tariff_type, price_type} = tariff_data;
            return !(price_type === "contract_price" && (tariff_type === "STATIC" || tariff_type === 'STATIC+TABLE')); //KZ / 01022022 / disable input if those criteria arent met
        }
    };


    get_disable_price_type = (tariff_data, tariff_edit) => {
        if (is_true(tariff_edit)) return true;
        else {
            let {customer_type, tariff_type, is_rlm} = tariff_data;
            if (customer_type === "PRIVATE" || is_rlm === 'YES') {
                return true;
            }else if(tariff_type === 'STATIC+TABLE')return true;
        }
    };

    get_disable_is_rlm = (tariff_data, tariff_edit) => {
        if (is_true(tariff_edit)) return true;
        else {
            let {customer_type} = tariff_data;
            if (customer_type === "PRIVATE") {
                return true;
            }
        }
    };

    get_price_guarantee_type_options = (tariff_data) => {
        const {tariff_type, price_type} = tariff_data;
        let all_options = price_guarantee_type_options;
        if (tariff_type === "SPOT") {
            return [all_options[3]]; // Keine Preisgarantie, value: none
        }else if (price_type === 'energy_price'){
            return [all_options[0]]; // Energiepreisgarantie, value: energy_price
        }else { //if contract_price
            return [all_options[0], all_options[1], all_options[2]]; // Energiepreisgarantie, value: energy_price;
            // Vollständige Preisgarantie, value: contract_price; Eingeschränkte Preisgarantie, value: limited
        }
    };

    get_cancellation_period_options = (tariff_data) => {
        let all_options = cancellation_period_options;
        if(tariff_data.contract_extension === 'none'){
            return [all_options[0]];
        }else{
            return [all_options[1], all_options[2], all_options[3]] // 2 Wochen, value: 2 weeks; 1 Monat, value: 1 month; 3 Monate, 3 months;
        }
    }

    render() {
        const {tariff_data, included_payment_options, is_loaded, error_messages} = this.state;
        const {tariff_edit, submit_loading, has_changed, cancellation_period_disabled, quantity_tolerance_checked} = this.state;
        let netfee_disabled = this.get_disable_netfee(tariff_data, tariff_edit);
        let price_type_disabled = this.get_disable_price_type(tariff_data, tariff_edit);
        let quantity_tolerance_checked_disabled = (tariff_data.customer_type === "PRIVATE");
        let is_rlm_disabled = this.get_disable_is_rlm(tariff_data, tariff_edit);
        let price_guarantee_type_options = this.get_price_guarantee_type_options(tariff_data);
        let price_guarantee_type_disabled = (tariff_data.tariff_type === "SPOT" || tariff_data.price_type === 'energy_price');
        let show_price_note = (tariff_data.netfee_type === "auto" && (tariff_data.price_guarantee_type === 'contract_price' || tariff_data.price_guarantee_type === 'limited'));
        let cancellation_period_options = this.get_cancellation_period_options(tariff_data);

        return (
            <>
                <AddTariffHeader>
                    <div className='table-centered'>
                        <h2 className='h2 white-color mb-0 d-inline-block mr-3'>{tariff_edit ? 'Tarif bearbeiten' : 'Tarif erstellen'}</h2>
                        {is_loaded ? validateVar(tariff_data.lifecycle_status) && <Badge value={tariff_data.lifecycle_status} color={tariff_data.status_color} /> : <Skeleton width={160} height={30}/>}
                    </div>
                </AddTariffHeader>
                <Container className='mt--6 pb-3' fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody className='py-1'>
                                    <MaterialUIStepper active={0} tariffId={this.id} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card id='firstElement'>
                                <CardHeader>
                                    <h3 className='mb-0'>Tarifbezeichnung</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={12}>
                                            <InputHandler placeholder='Bitte wählen Sie einen Tarifnamen'
                                                          type='text'
                                                          name='name'
                                                          on_change={(name, value) => this.update_state(name, value)}
                                                          content_title='Tarifname'
                                                          value={tariff_data.name}
                                                          error_state={error_messages.name_error}
                                                          is_loaded={is_loaded} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <InputHandler placeholder='Bitte geben Sie eine Tarifbeschreibung an'
                                                          type='textarea'
                                                          name='description'
                                                          on_change={(name, value) => this.update_state(name, value)}
                                                          content_title='Tarifbeschreibung'
                                                          value={tariff_data.description}
                                                          error_state={error_messages.description_error}
                                                          is_loaded={is_loaded} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h3 className='mb-0'>Grundlegende Klassifizierung</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <InputHandler
                                                content_title='Kundentyp'
                                                type='radio'
                                                name='customer_type'
                                                first_radio={{id: 'privat', text: 'Privat', value: 'PRIVATE'}}
                                                second_radio={{id: 'business', text: 'Gewerbe',  value: 'BUSINESS'}}
                                                value={tariff_data.customer_type}
                                                disabled={tariff_edit}
                                                is_loaded={is_loaded}
                                                on_change={(name, value) => this.update_state(name, value)}
                                            />
                                        </Col>
                                        <Col>
                                            <InputHandler content_title='Energieträger'
                                                          type='radio'
                                                          name='medium_type'
                                                          first_radio={{id: 'electricity', text: 'Strom', value: 'ELECTRICITY'}}
                                                          second_radio={{id: 'gas', text: 'Gas', value: 'GAS'}}
                                                          value={tariff_data.medium_type}
                                                          disabled={tariff_edit}
                                                          is_loaded={is_loaded}
                                                          on_change={(name, value) => this.update_state(name, value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <InputHandler content_title='Zählertyp'
                                                          type='two_checkboxes'
                                                          name='slp_rlm'
                                                          first_checkbox={{id: 'is_slp', name: 'is_slp',  text: 'SLP', value: tariff_data.is_slp, checked_value: 'YES', unchecked_value: 'NO', disabled: is_rlm_disabled}}
                                                          second_checkbox={{id: 'is_rlm', name: 'is_rlm', text: 'RLM', value: tariff_data.is_rlm, checked_value: 'YES', unchecked_value: 'NO', disabled: is_rlm_disabled}}
                                                          disabled={tariff_edit}
                                                          is_loaded={is_loaded}
                                                          on_change={(name, value) => this.update_state(name, value)}
                                                          error_state={error_messages.is_slp_error}
                                                          no_margin
                                            />
                                        </Col>
                                        <Col>
                                            <InputHandler content_title='Preistyp'
                                                          type='radio'
                                                          name='price_type'
                                                          first_radio={{id: 'energy_price', text: 'Energiepreis', value: 'energy_price'}}
                                                          second_radio={{id: 'contract_price', text: 'Arbeitspreis', value: 'contract_price'}}
                                                          value={tariff_data.price_type}
                                                          disabled={price_type_disabled}
                                                          is_loaded={is_loaded}
                                                          on_change={(name, value) => this.update_state(name, value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row sm={12} md={6}>
                                        <Col>
                                            <InputHandler
                                                content_title='Kalkulation'
                                                type='select'
                                                name='tariff_type'
                                                value={tariff_data.tariff_type}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                disabled={tariff_edit}
                                                is_loaded={is_loaded}
                                                select_options={calculation_types}
                                            />
                                        </Col>
                                        <Col>
                                            <InputHandler content_title='Netzentgelte, Ablagen, Umlagen & Steuern'
                                                          type='radio'
                                                          name='netfee_type'
                                                          first_radio={{id: 'auto', text: 'Aut. abfragen', value: 'auto'}}
                                                          second_radio={{id: 'manual', text: 'Manuell angeben', value: 'manual'}}
                                                          value={tariff_data.netfee_type}
                                                          disabled={netfee_disabled}
                                                          is_loaded={is_loaded}
                                                          on_change={(name, value) => this.update_state(name, value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h3 className='mb-0'>Erweiterte Klassifizierung</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <InputHandler
                                                content_title='Preisgarantie'
                                                type='select'
                                                name='price_guarantee_type'
                                                value={tariff_data.price_guarantee_type}
                                                disabled={price_guarantee_type_disabled}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                select_options={price_guarantee_type_options}
                                                remove_empty_option
                                                no_margin={!price_guarantee_type_disabled}
                                            />
                                        </Col>
                                        <Col>
                                            <InputHandler
                                                type='multiselect'
                                              content_title='Zahlungsart'
                                              name='included_payment_options'
                                              on_change={(name, selected) => this.update_state(name, selected)}
                                              multiselect_options={tariff_data.payment_options}
                                              id={"payment_method"}
                                              is_loaded={is_loaded}
                                              value={included_payment_options}
                                              error_state={error_messages.included_payment_options_error}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Collapse isOpen={show_price_note}>
                                                <div className='padding-for-alert-icon static-warning-notification mb-4 mt-0'>
                                                    <div className='warning-alert-icon'>Die Netzentgelte zur Berechnung eines Arbeitspreises werden von einem Drittanbieter bezogen. Es kann daher keine Haftung für die Richtigkeit der bezogenen Daten übernommen werden!</div>
                                                </div>
                                            </Collapse>
                                        </Col>
                                        <Col />
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <InputHandler
                                                content_title='Autom. Vertragverlängerung'
                                                type='select'
                                                name='contract_extension'
                                                value={tariff_data.contract_extension}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                is_loaded={is_loaded}
                                                select_options={[{key: 'none', value: 'Keine Vertragsverlängerung'},
                                                    {key: '1 month', value: '1 Monat'},
                                                    {key: '3 months', value: '3 Monate'},
                                                    {key: '6 months', value: '6 Monate'},
                                                    {key: '12 months', value: '12 Monate'}]}
                                            />
                                        </Col>
                                        <Col>
                                            <InputHandler
                                                content_title='Kündigungsfrist'
                                                type='select'
                                                name='cancellation_period'
                                                value={tariff_data.cancellation_period}
                                                on_change={(name, value) => this.update_state(name, value)}
                                                disabled={cancellation_period_disabled}
                                                is_loaded={is_loaded}
                                                error_state={error_messages.cancellation_period_error}
                                                select_options={cancellation_period_options}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <InputHandler content_title='Einschränkung der Mehr- / Mindermengentoleranz'
                                                          type='checkbox'
                                                          id='quantity_tolerance_checked'
                                                          name='quantity_tolerance_checked'
                                                          value={quantity_tolerance_checked}
                                                          disabled={quantity_tolerance_checked_disabled}
                                                          is_loaded={is_loaded}
                                                          text='Dieser Tarif hat eine eingeschränkte Mehr-/ Mindermengentoleranz'
                                                          on_change={(name, value) => this.update_state(name, value)}
                                            />
                                        </Col>
                                        <Col>
                                            <InputHandler content_title='Mehr -/ Mindermengentoleranz'
                                                          type='text'
                                                          id='quantity_tolerance'
                                                          name='quantity_tolerance'
                                                          value={replace_all_dots(tariff_data.quantity_tolerance + '')}
                                                          disabled={!quantity_tolerance_checked}
                                                          is_loaded={is_loaded}
                                                          on_change={(name, value) => this.update_state(name, value)}
                                                          input_group_text='%'
                                                          remove_zero_on_focus
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputHandler content_title={(tariff_data.medium_type === "GAS") ? "CO2-Neutraler Tarif" : 'Öko-Tarif'}
                                                  type='checkbox'
                                                  id='energy_source'
                                                  name='energy_source'
                                                  value={tariff_data.energy_source}
                                                  checked_value='green'
                                                  unchecked_value='grey'
                                                  is_loaded={is_loaded}
                                                  text={(tariff_data.medium_type === "GAS") ? "Ja, das ist ein CO2-Neutraler Tarif" : 'Ja, das ist ein Öko-Tarif'}
                                                  on_change={(name, value) => this.update_state(name, value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {(has_changed) || (+this.id === 0) ?
                    <PrimaryButton id='primary_button' onClick={() => this.handle_submit()} disabled={submit_loading || !is_loaded} value='Speichern und Weiter' classes='full-page-submit' loading={submit_loading} />
                    : <PrimaryButton id='primary_button' onClick={() => this.redirect_to_next_step()} disabled={submit_loading || !is_loaded} value='Weiter' classes='full-page-submit' loading={submit_loading} />}

                    <GreyLinkButton text='Zurück' classes='mt-4 mb-3 pb-2 eless-font-bold' onClick={() => this.handle_return} />
                </Container>
            </>
        );
    }
}
let mapStateToProps = function(state)   {
    return {
        tariff: state.tariffs.tariff,
    }
};

let mapDispatchToProps = {
    onInitialize: initializeTariff,
    onAddTariff: addTariff,
    onUpdateTariff: updateTariff,
    redirect: redirect,
    showErrorMessage: showErrorMessage,
};

let AddTariffContainer = connect(mapStateToProps, mapDispatchToProps)(TariffHandleBasicData);

export default AddTariffContainer;
