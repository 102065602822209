import Company_wattline from "../Objects/company_wattline";
import * as ERROR_CODE from "../Errors/error_codes";
import Plattform from "../Objects/plattform";
import getUserRole from "../Functions/getUserRole";
import validateVar from "../Functions/validation/validateVariable";
import handleCustomData from "../Functions/setPlattformColor";
import {displaySuccessMessage, displayErrorMessage} from "./user_feedback";
import {apiError} from "../Functions/global";

export function affiliate(function_name ,params, url, file) {
    return function (dispatch) {
        Company_wattline.consultant_get(function_name ,params, url, file).then(res => {
            if (res !== apiError) {
                dispatch({type: "INITIALIZE_COMPANY", res: res});
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Bei dem laden der Firmendaten ist ein Fehler aufgetreten. (Fehlercode: " + ERROR_CODE.GetCompanyError + ")", // content
                        "Fehler", //headline
                        null, // url, // url
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        false,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    };
}


export function get_plattform_settings(function_name, agency_id = 0, login = false) {
    return function (dispatch) {
        Plattform.get_plattform_settings(agency_id).then(res => {
            if (res !== apiError) {
                let userRole = getUserRole();
                if (validateVar(agency_id) && login) {
                    dispatch({
                        type: "SET_USER_MESSAGE",
                        res: displaySuccessMessage(
                            "", // content
                            "Erfolg", //headline
                            null, // url
                            true, //automatic redirect
                        ),
                    });
                }
                handleCustomData(false, res);
                dispatch({type: "CHANGE_PLATTFORM", res: res})
            } else {
                dispatch({
                    type: "SET_USER_MESSAGE",
                    res: displayErrorMessage(
                        "Die Farben von Ihrem Account konnten nicht geladen werden (Fehlercode: " + ERROR_CODE.GetEmployeeColors + ")",
                        "Fehler", //headline
                        null, // url,
                        false, //automatic redirect
                        true,       //modal
                        null, // primaryBtnText
                        "Bestätige",// secondaryBtnText
                        true, //allowToggle (user can close modal without pressing a btn)
                        false,// successIcon
                        true,// infoIcon
                        "danger" //type - danger/success
                    ),
                });
            }
        });
    }
}


export function updateCompany(params) {
    return function (dispatch) {
        Company_wattline.update(params).then(res => {
            if (res !== apiError) {
                dispatch({type: "UPDATE_COMPANY", res: res});
            } else dispatch({type: "SET_USER_MESSAGE", res: displayErrorMessage()});
        });
    };
}

