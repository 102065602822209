import Communication from "./Components/Communication/communication";
import validateVar from "../Functions/validation/validateVariable";
import {apiError, apiFunctionValidateCustomerInformation, apiSolvencyService} from "../Functions/global";
import getCommunicationClass from "../Functions/getCommunication";

class Buergel {

    constructor(data) {
        /*
         *   default value for vars in this class
         *   add new vars here
         * */
        //basic
        this.key = "";
        this.value = "";

        //load basic info

        if (validateVar(data) && validateVar(data.company) !== "undefined") {
            //if data found
            this.key = !validateVar(data.key) ? "" : +data.key +1; //TODO remove "+1" on Select 0 option update
            this.value =
                !validateVar(data.company)
                    ? ""
                    : data.company +" [" +data.street + " - " + data.house_number + "]";
        }
    }




    static async getValidCustomerName(params) {
        // this.c = new Communication(
        //     apiSolvencyService,
        //     apiFunctionValidateCustomerInformation,
        //     "",
        //     "",
        //     "",
        //     "",
        //     params
        // );
        let communication_params = {
            service: apiSolvencyService,
            get_function: apiFunctionValidateCustomerInformation,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);


        let res = await c.load();
        if (res.status === "ok"){
            let data = res.result;

            if(data === -1) data = 'no_result'; //suddenly we get -1 as response from this request which is probably ment to be "no_result"

            if (data !== "no_result" && data !== "match") {
                let select_field_val = [];
                let counter = 1;
                for (let index in data) {
                    if (data.hasOwnProperty(index)){
                        let customer = data[index];
                        customer.key = index;

                        select_field_val[counter] = new Buergel(customer);
                        counter++;
                    }
                }
                return select_field_val;
            } return data;

        } else return apiError;
    }
}

export default Buergel;
