import React from 'react';

class ColoredSpans extends React.Component {

    render() {
        let {value, color} = this.props;

       let class_name = "";
        if (color === "green")  class_name = "green-font";
        else if (color === "red")  class_name = "red-font";


        return <span className={class_name}>{value}</span>
    }
}

export default ColoredSpans;