import {
  apiCalculationManagementService,
  apiError,
  apiFunctionContractModify,
  apiFunctionCreateOffer,
  apiFunctionDeleteMemo,
  apiFunctionGetContract,
  apiFunctionGetOffer,
  apiFunctionGetOfferByToken,
  apiFunctionListCheckout,
  apiFunctionModifyOffer,
  apiFunctionSaveCheckout,
  apiInformationManagementService,
  static_download_url
} from "../Functions/global";
import validateVar from "../Functions/validation/validateVariable";
import History from "./Components/history";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";
import OfferResult from "./offer_result";
import Employee from "./employees";
import Price from "./price";
const default_logo_url = 24281;

let offer_vars = {
  translation_object: {
    contract_begin_display: "Angebots-ID",
    contract_end_display: "Datum",
    translation: "Platz",
    payment_method_display: "Verbrauch",
    supplier_name: "Energieversorger", // todo
    offer_begin: "Lieferbeginn", // todo
    offer_end: "Lieferende", // todo
  },
  id: {
    offer_id: null
  },
  channel_cluster: null,
  protocol_id: null,
  placement: null,
  customer_type: {
    customer_type_offer: null,
      customer_type: null
  },
  company: null,
  validity: null,
  expires_at: {
    expires_at: null,
    expires_at_seconds: {
      function_name: 'getExpiredInSeconds',
    }
  },
  consultant_id: null,
  customer_id: null,
  offer_begin: {
    offer_begin: null,

  },
  offer_begin_display: {
      function_name: "handleFormatDateForFrontend",
      parmas: ["%%offer_begin", false]
  },
  offer_end: {
    offer_end: null,
  },
  offer_end_display: {
      function_name: "handleFormatDateForFrontend",
      parmas: ["%%offer_end", false]
  },
  is_rlm: null,
  is_slp: null,
  medium_type: {
    medium_type: null,
    medium_type_display: {
      function_name: "getMediumType"
    }
  },
  predicted_consumption: {
    predicted_consumption: null,
    predicted_consumption_display: {
      function_name: 'getDisplayConsumption'
    }
  },
  consumption_point_count: null,
  mean_consumption: {
    mean_consumption: null,
    consumption: null
  },
  analytics_info: {
    is_sub_arr: 'analytics_info',
    ranking: null,
    agency_type: {
      agency_type: null,
      agency_type_display: {
        function_name: "get_agency_type"
      }
    }
  },
  requested_margin: {
      is_sub_arr: "requested_margin",
      unit: {
          margin_unit: null,
      },
      amount: {
          margin_amount: null,
          margin_amount_display: {
              function_name: "handleFormatPrice",
              params: ["3", "ct / kWh"]
          },
      },
      unit2: {
          margin_unit: null,
      },
      amount2: {
          margin_amount2: null,
          margin_amount_display2: {
              function_name: "handleFormatPrice",
              params: ["2", "€ / Jahr"]
          },
      },
      consultant_margin: null, //ct / kWh for Input field
      consultant_margin2: null //€ / Year for Input field
  },
  calculation_information: {
    is_sub_arr: 'calculation_information',
    consumption: {
      consumption: null,
      consumption_display: {
        function_name: "getDisplayConsumption"
      }
    }
  },
  tariff: {
    is_class_arr: 'tariff',
    is_top_level: true,
  },
  offer: {
    is_sub_arr: 'offer',
    created_at: {
      created_at: null,
      created_at_display: {
        function_name: "getGermanDateFormat",
        params: [true]
      }
    },
    last_typ: {
      function_name: "getLastType",
      params: ["%%is_slp", "%%is_rlm"]
    },
    medium_type: {
      medium_type: null,
      medium_type_display: {
        function_name: "getMediumType"
      }
    },
    // consultant_id: null,
    // customer_id: null,
    expires_at: {
      expires_at:null,
      expires_at_seconds: {
        function_name: 'getExpiredInSeconds',
      }
    },
    predicted_consumption: {
      predicted_consumption: null,
      predicted_consumption_display: {
        function_name: 'handleFormatNumber'
      }
    },
    offer_begin: {
      offer_begin: null,

    },
      offer_begin_display: {
          function_name: "handleFormatDateForFrontend",
          params: ["%%offer_begin", false]
      },
    offer_end: {
      offer_end: null,

    },
      offer_end_display: {
          function_name: "handleFormatDateForFrontend",
          params: ["%%offer_end", false],
      },
    consumption_points: {
      is_class_arr: "consumption_point",
    }
  },
  price: {
      is_class_arr: "price",
      is_top_level: true,
  },
  keyfacts: {
    is_class_arr: "keyfacts",
      is_top_level: true,
  },

  customer_type_display: {
    function_name: "getCustomerType",
    params: ["%%customer_type_offer"]
  },

  consumption_type: null,
  supplier_name: null,
  tariff_name: null,
  status: null,
  status_color: {
    function_name: "getBadgeColor",
    params: ["%%status", ["concluded"], ["open"], ["expired"]]
  },
  status_display: {
    function_name: "translateOfferStatus",
    params: ["%%status"]
  },
  reporting: {
    is_class_arr: "reporting",
  },
  offer_information: {
    is_sub_arr: 'offer_information',
    token: null,
    access_key: null,
    change_dates: null,
    change_consumption_points: null,
    expiry: null,
  },
  comparison_results: {
    is_sub_arr: 'comparison_results',
    comparison_results: {
      default_value: []
    },
  }
};

class Offer {
  static default_values = offer_vars;
  constructor(data) {
    if (validateVar(data)) {
      let class_content = new History(offer_vars, data, data.history, []);
      for (let name in class_content) {
        if (class_content.hasOwnProperty(name)) {
          this[name] = class_content[name];
        }
      }
    }
  }

  /*
                  Instantiate a new Customer with its ID as Param
                  Call Communication Class with matching global defined Vars for API Request
                  return new Customer Clnass
               */
  static async init(id, type = "extended") {
    let params = {
      contract_id: id,
      type: type,
      options: {
        with_contract_signed: 1,
        with_prediction: 1
      }
    };
    params = getAgencyID(params); // sets values for agency/ employee
      let communication_params = {
          service: apiCalculationManagementService,
          get_function: apiFunctionGetContract,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);

    let res = await c.load();
    if (res.status === "ok") {
      let result = res.result;
      if (validateVar(result.authority_file))
        result.authority_file_url = await this.getDownloadUrl(
          result.authority_file,
          "application/pdf",
          true
        );

      return new Offer(result);
    } else return apiError;
  };

  static async getOffer(data) {
    let params = {
      offer_id: data.offer_id,
      customer_id: data.customer_id,
      options: {
        with_consumption_point_count: 1,
        with_predicted_consumption: 1,
        with_comparison_results: 1
      }
    };
    params = getAgencyID(params);
    /*
    param
     */
    let c = getCommunicationClass({
      service: apiCalculationManagementService,
      get_function: apiFunctionGetOffer,
      params: params
    });
    var res = await c.load();
    if (res.status === "ok") {
      res.result.default_url = static_download_url + default_logo_url;
      return  new Offer(res.result);
    }
    else return "ERR";
  };


  static async getOfferByToken(token) {
    let params = {
      token: token,
      options: {
        with_consumption_point_count: 1,
        with_predicted_consumption: 1,
        with_comparison_results: 1
      }
    };

    params = getAgencyID(params);

    let c = getCommunicationClass({
      service: apiCalculationManagementService,
      get_function: apiFunctionGetOfferByToken,
      params: params
    });

    var res = await c.load();
    if (res.status === "ok") {
      res.result.default_url = static_download_url + default_logo_url;
      return  new Offer(res.result);
    }
    else return "ERR";
  }

  static async updateOffer(params) {
    params = getAgencyID(params);
    params.options = {};
    params.options.with_consumption_point_count = 1;
    params.options.with_predicted_consumption = 1;
    params.options.with_comparison_results = 1;
    let c = getCommunicationClass({
      service: apiCalculationManagementService,
      get_function: apiFunctionModifyOffer,
      params: params
    });
    var res = await c.load();
    if (res.status === "ok") {
      res.result.default_url = static_download_url + default_logo_url;
      return  new Offer(res.result);
    }
    else return apiError;
  };

  static async getOffersForCustomer(customer_id) {
    let params = getAgencyID({});

    if (validateVar(customer_id)) params.customer_id = customer_id;
    else params.options = {
      with_customer_info: 1
    };
    let c = getCommunicationClass({
      service: apiCalculationManagementService,
      get_function: apiFunctionListCheckout,
      params: params
    });
    return c.load().then(res => {
      if (res.status === "ok"){
        let offer_results = [];
        let saved_offers = res.result;
        if (saved_offers !== "[]" && saved_offers.length > 0) {
          saved_offers.map((saved_offer) => {
            offer_results.push(new OfferResult(saved_offer));
          });
        }
        return offer_results;
      }
      else return "ERR";
    });
  };

  static async save_offer_result(params) {
    params = getAgencyID(params);
    let c = getCommunicationClass({
      service: apiCalculationManagementService,
      get_function: apiFunctionSaveCheckout,
      params: params
    });

    var res = await c.load();
    if (res.status === "ok") return res;
    else return "ERR";
  };

  static async createOffer(params) {
    params = getAgencyID(params);
    let c = getCommunicationClass({
          service: apiCalculationManagementService,
          get_function: apiFunctionCreateOffer,
          params: params
        });
    var res = await c.load();
    if (res.status === "ok") return  new Offer(res.result);
    else return apiError;
  };

  static async deleteMultipleOffers(selected_array){
    let error_count = 0;
    let deleted_ids = [];
    for (let offer of selected_array) { // loop through all values (objects or ids)
      let params = getAgencyID({});
      params.id = offer.memo_id;
      let c = getCommunicationClass({  // define communication class for request
        service: apiInformationManagementService,
        delete_function: apiFunctionDeleteMemo,
      });
      let res = await c.delete_async(params);  // start "async" (not normal delete!) delete request
      if (res.status === "ok"){
        deleted_ids.push(offer.memo_id); // get ids for updating redux states later
      } else {
        error_count = error_count +1; // get error count for user feedback
      }
    }
    if (deleted_ids.length === 0) return "ERR";  // if no data was deletet send ERR back
    return [deleted_ids, error_count]; // send deleted ids, and error quote back
  };

  delete() {
    let c = getCommunicationClass({
      service: apiInformationManagementService,
      delete_function: apiFunctionDeleteMemo,
    });

    let params = getAgencyID({});
    params.memo = this;
    params.customer_id = this.customer_id;
    let res = c.delete(params);
    return res;
  };

  static async initList(params) {
    params = getAgencyID(params); // sets values for agency/ employee
    params = getAgencyID(params);
    let function_url = "analytics/ranking_calculations/"+params.supplier_id;

    // let c = new CommunicationNew(
    //   methode, // GET / POST / PUT / DELETE  -> method
    //   function_url,
    //   params
    // );


    let communication_params = {
      method: 'GET', // GET / POST / PUT / DELETE  -> method
      function_url:  function_url,
      params: params
    };
    let c = getCommunicationClass(communication_params);

    let res = await c.request_handler();

    if (res !== apiError) {
      if (res.elements === "[]") res.elements = [];
      let offers = [];
      let elements = res.elements;
      for (let i = 0; i < res.elements.length; i++) {
        offers.push(new Offer(elements[i]));
      }

      return {
        offer: offers,
        next_offset: res.next_offset,
        last_request: res.last_request,
        total_count: res.total_count
      };
    } else return apiError;
  }

  static async handle_price_ranking(params, methode = "GET") {
    let function_url = "offer/offer_result";
    if (methode === "PUT" || methode === "DELETE" || methode === "GET") {
      function_url = function_url + "/" + params.id;
    }
    params = getAgencyID(params);

      let communication_params = {
          method: methode, // GET / POST / PUT / DELETE  -> method
          function_url:  function_url,
          params: params
      };
      let c = getCommunicationClass(communication_params);
    let result = await c.request_handler();
    if (result !== apiError) {
      let return_obj = {};
      if (validateVar(params.is_list)) {
        let result_list = [];
        let layout_options = null;
        for (let index in result) {
          if (result.hasOwnProperty(index)) {
            layout_options = result[index].options;
            result_list.push(new Offer(result[index]));
          }
        }

        return_obj.layouts = result_list;
        return_obj.options = layout_options;

        return return_obj;
      } else {
        // return_obj = {};
        // return_obj = (result.hasOwnProperty("offer")) ? new Offer(result.offer) : null;
        // return_obj = (result.hasOwnProperty("offer")) ? new Offer(result.tariff) : null;
        return new Offer(result);
      }
    } else return apiError;
  }

  /*
                  update function - params must be a valid object
               */
  static async update(params) {
    params = getAgencyID(params); // sets values for agency/ employee
      let communication_params = {
          service: apiCalculationManagementService,
          get_function: "",
          update_function: apiFunctionContractModify,
          delete_function: "",
          add_function: "",
          download_function: "",
          params: null,
      };
      let c = getCommunicationClass(communication_params);



    var res = await c.update(params);
    if (res.status === "ok") {
      let result = res.result;
      if (validateVar(result.authority_file))
        result.authority_file_url = await this.getDownloadUrl(
          result.authority_file,
          "application/pdf",
          true
        );
      return new Offer(result);
    }
    return apiError;
  }

  static dummy() {
    let output = {};
    output = this.build_dummy(offer_vars);
    return output;
  }

  static build_dummy(vars) {
    let output = {};
    for (let index in vars) {
      if (vars.hasOwnProperty(index)) {
        if (!validateVar(vars[index])) {
          output[index] = "-/-";
        } else if (typeof vars[index] === "object") {
          if (validateVar(vars[index].is_sub_arr)) {
            output[index] = this.build_dummy(vars[index]);
          } else {
            output[index] = "-/-";
          }
        }
      }
    }
    return output;
  }


    static async handle_offer_actions(params = {}, method, call_function_name, file){
        params = getAgencyID(params);
        let communication_params = {
            method: method,
            params: params,
            function_url: 'offer',
        };
        if(method === 'GET'){
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }if(method === 'PUT'){
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }else if(validateVar(call_function_name)){ //so far only used for send_mail
            communication_params.function_url = communication_params.function_url+ "/" + call_function_name;
        }
        let c = getCommunicationClass(communication_params, file);
        let res = await c.request_handler();
        if (res !== apiError) {
            if (validateVar(params.internal) && params.internal.dispatch_type === 'OFFER_MARGIN') {
                return true;
            } else {
                return new Offer(res);
            }
        } else return apiError;
    }
}

export default Offer;
