let initialState = null;

function employee_data(state = initialState, action) {
  if (action.type === "INITIALIZE_EMPLOYEE") {
    return action.res;
  } else if (action.type === "UPDATE_SINGLE_EMPLOYEE") {
    return action.res;
  }else if (action.type === "DELETE_EMPLOYEE_ERR") {
    return action.res;
  }else if (action.type === "EMPLOYEE_DATA_RESET") {
    return action.res;
  } else {
    return state;
  }
}

export default employee_data;
