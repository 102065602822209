import handleFormatPrice from "./handleFormatPrice";

function getConsultantFeeInEuro(fee = 0, consumption = 0) {
    if (typeof consumption  === "undefined" || consumption === null || consumption === "" || consumption === 0)
        return "- / -";
    if (typeof fee  === "undefined" || fee === null || fee === "")
         return "- / -";
    if (fee === 0) return "Entfällt";
    let feeInEuro = (fee * consumption) / 100;
    return handleFormatPrice(feeInEuro, 2, "€ / Jahr");
}

export default getConsultantFeeInEuro;