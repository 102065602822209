import validateVar from "./validation/validateVariable";
import {emptyValue} from "./global";
import get_standardised_date from "./get_standardised_date";

function handleFormatDateForFrontend(date, time = false) {
  if (validateVar(date) && date !== emptyValue) {
      var dt = new Date(get_standardised_date(date));
      let date_return =  (
          ("0" + dt.getDate()).slice(-2) +
          "." +
          ("0" + (dt.getMonth() + 1)).slice(-2) +
          "." +
          dt.getFullYear()
      );

      if (time) {
          date_return = date_return + ' ' + ('0'+ dt.getHours()).slice(-2) + ":" + ('0'+ dt.getMinutes()).slice(-2) + ' Uhr';
      }
      return date_return;
  } else return emptyValue;

}
export default handleFormatDateForFrontend;
