import {
    apiCalculationManagementService,
    apiError,
    apiFileSystemService, apiFunctionContractExport,
    apiFunctionContractModify,
    apiFunctionCreateContract,
    apiFunctionDisplayContract,
    apiFunctionGenerateContract,
    apiFunctionGetContract,
    apiFunctionGetContractList,
    apiFunctionReturnResource,
    apiFunctionUploadContractFile,
    apiServiceContractGenerator, static_download_url
} from "../Functions/global";
import validateVar from "../Functions/validation/validateVariable";
import History from "./Components/history";
import Tariff from "./tariff";
import getAgencyID from "../Functions/getAgencyID";
import callFunctionWithString from "../Functions/callFunctionWithString";
import isEmptyObject from "../Functions/isEmptyObject";
import getCommunicationClass from "../Functions/getCommunication";
import callClassWithString from "../Functions/callClassWithString";
import Offer from "./offer";

let contract_vars = {
    translation_object: {
        contract_begin_display: "Vertragsbeginn",
        contract_end_display: "Vertragsende",
        translation: "Vollmacht",
        payment_method_display: "Zahlungsart",
        price_rate_full_display: "Preis",
        authority_file: "Maklervollmacht"
    },
    private_customer_company_data: 'Entspricht dem Ansprechpartner',
    has_history: true,
    id: {
        id: null,
        contract_id: null,
    },
    employee_name: null,
    protocol_id: null,
    authority_file: {
        authority_file: null,
    },
    authority_file_url: null,
    cancellation_reason: {
        cancellation_reason: null,
        cancellation_reason_display: {
            function_name: 'getCancellationReasonDisplay'
        },
    },
    files: {
        is_sub_arr: "files",
        logo_url: null,
        label_url: null,
        brochure_url: null
    },
    channel_cluster: null,
    mean_consumption: {
        mean_consumption: null,
        consumption: null,
        display_consumption: {
            function_name: "getDisplayConsumption"
        }
    },
    contract_file: null,
    commission_recipient: {
        default_value: "eless Systems GmbH",
    },
    consultant_employee_data: {
        is_class_arr: "consultant_employee_data",
        is_top_level: true,
    },
    customer_id: null,
    supplier_name: null,
    internal_contract_id: null,
    internal_customer_id: null,
    contract_begin: {
        contract_begin: null,
        contract_begin_display: {
            function_name: "getGermanDateFormat"
        },
        contract_begin_timestamp: {
            function_name: "getTimestamp"
        },
        contract_begin_date_format: {
            function_name: "createNewDateObject"
        }
    },
    contract_end: {
        contract_end: null,
        contract_end_display: {
            function_name: "getGermanDateFormat"
        },
        contract_end_timestamp: {
            function_name: "getTimestamp"
        },
        contract_end_date_format: {
            function_name: "createNewDateObject"
        }
    },
    confirmed_at: null,
    process_until: {
        valid_until: null,
        valid_until_time_stamp: {
            function_name: "get_time_stamp"
        },
        valid_until_display: {
            function_name: "getGermanDateFormat",
            params: [true],
        }
    }, // named valid till in FE / todo replace
    contract_file_name: {
        file_name: null
    }, // named file_name in FE / todo replace
    submitted_at: {
        submitted_at: null,
        submitted_at_display: {
            function_name: "getGermanDateFormat"
        }
    }, // named file_name in FE / todo replace
    payment_method: {
        payment_method: null,
        payment_method_display: {
            function_name: "getPaymentMethod"
        }
    },
    customer: {
        is_class_arr: "customer",
        is_top_level: true,
        has_history: true,
        // customer_type: {
        //     customer_type: null,
        //     customer_type_display: {
        //         function_name: "getCustomerType",
        //         translation: "Kundentyp"
        //     }
        // },
        // customer_information: {
        //     is_class_arr: "customer_information",
        //     is_top_level: true,
        //
        // },
        // solvency_information: {
        //     is_sub_arr: "solvency_information",
        //     translation_object: {
        //         solvency_score_display: "Bonität",
        //         solvency_score_full_display: "Bonität"
        //     },
        //     date: {
        //         solvency_date: null,
        //         solvency_date_display: {
        //             function_name: "handleFormatDateForFrontend"
        //         }
        //     },
        //     score: {
        //         solvency_score: null,
        //         solvency_score_display: {
        //             function_name: "getSolvencyDisplay",
        //             params: ["%%solvency_date_display"]
        //         }
        //     },
        //     solvency_score_full_display: {
        //         function_name: "solvency_score_full_display",
        //         params: [
        //             "%%solvency_score_display",
        //             "%%solvencyProvider",
        //             "%%solvency_date_display"
        //         ]
        //     }
        // }
    },
    documents: {
        is_sub_arr: "documents",
        translation_object: {
            signed_id: 'Unterschriebener Vertrag'
        },
        has_history: true,
        valid_id: null,
        signed_id: null,
    },
    offer_result: {
        is_sub_arr: "offer_result",
        has_history: true,
        id: {
            offer_res_id: null
        },
        tariff: {
            is_class_arr: "tariff",
            is_top_level: true,
        },
        offer_id: {
            offer_res_offer_id: null
        },
        keyfacts: {
            is_class_arr: "keyfacts",
            is_top_level: true,
        },
        price: {
            is_class_arr: "price",
            is_top_level: true,
        },
        tariff_id: {
            offer_res_tariff_id: null,
            tariff_id: null
        },
        created_at: {
            offer_res_created_at: null
        },
        offer_res_updated_at: {
            offer_res_updated_at: null
        },
        offer: {
            is_sub_arr: "offer",
            has_history: true,
            consultant_id: {
                offer_consultant_id: null,
                display_source: {
                    function_name: "getContractSource",
                    params: ["%%offer_consultant_id"]
                }
            },
            id: {
                offer_id: null
            },
            customer_id: {
                offer_customer_id: null
            },
            offer_begin: null,
            created_at: {
                offer_created_at: null
            },
            offer_end: null,
            medium_type: {
                offer_medium_type: null,
                display_medium_type: {
                    function_name: "getMediumType",
                    params: ["%%offer_medium_type"]
                }
            },
            is_slp: null,
            is_rlm: null
        },
    },
    display_begin: {
        function_name: "getGermanDateFormat",
        params: ["%%contract_begin"]
    },
    display_created: {
        display_created: {
            function_name: "getGermanDateFormat",
            params: ["%%offer_created_at"]
        },
        display_created_timestamp: {
            function_name: "getTimestamp",
            params: ['%%offer_created_at']
        },
    },
    token: {
        token: null,
        contract_token: null,
    },
    status: {
        time_diff: {
            function_name: "getTimeDiff",
            params: ["%%valid_until"]
        },
        status_original: null,
        status: {
            function_name: "getContractStatus",
            params: ["%%time_diff"]
        },
    },
    translated_status: {
        function_name: "translateContractStatus",
        params: ["%%status"]
    },
    status_color: {
        function_name: "getBadgeColor",
        params: [
            "%%status",
            ["accepted", "confirmed"],
            ["submitted", "customer_checkout"],
            ["cancelled", "expired"],
            ["revoked"],
        ]
    },
    last_typ: {
        function_name: "getLastType",
        params: ["%%is_slp", "%%is_rlm"]
    },
    sign_type_display: {
        function_name: "getSignTypeDisplay",
        params: ["%%sign_type"]
    },
    energy_source_display: {
        function_name: "getEnergySource",
        params: ["%%energy_source", "%%offer_medium_type"]
    },
    revoke_until: {
        revoke_until: null,
        revoke_until_display: {
            function_name: "getGermanDateFormat",
            params: [true]
        },
        revoke_able: {
            function_name: "is_contract_revoke_able",
            params: ["%%customer_type", "%%status"]
        }
    },
};

class Contract {
    static default_values = contract_vars;

    constructor(data, status = "open") {
        if (validateVar(data) && !isEmptyObject(data)) {
            this.history = {};
            // this.get_contract(contract_vars, data, data.history, []);
            let class_content = new History(contract_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }

    static async instantiate(id, type = "extended") {
        let params = {
            contract_id: id,
            type: type,
            options: {with_contract_signed: 1}
        };
        params = getAgencyID(params); // sets values for agency/ employee


        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);


        // this.c = new Communication(
        //     apiCalculationManagementService,
        //     apiFunctionGetContract,
        //     apiFunctionContractModify,
        //     "",
        //     "",
        //     "",
        //     params
        // );
        let res = await c.load();
        if (res.status === "ok") {
            let result = res.result;
            if (validateVar(result.authority_file))
                result.authority_file_url = await this.getDownloadUrl(
                    result.authority_file,
                    "application/pdf",
                    true
                );

            return new Contract(result);
        } else return apiError;
    }

    static async getDownloadUrl(id, type = "image/png") {
        let params = {
            id: id
        };
        params = getAgencyID(params); // sets values for agency/ employee
        let communication_params = {
            service: apiFileSystemService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionReturnResource,
            params: null,
        };
        let c = getCommunicationClass(communication_params);
        return await c.download(params, type, true);
    }

    static async instantiateList(params) {
        params.type = "extended";
        params = getAgencyID(params); // sets values for agency/ employee
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetContractList,
            params: params,
        };
        let c = getCommunicationClass(communication_params);

        let res = await c.load();
        if (res.status === "ok") {
            res.result.elements = callFunctionWithString("check_for_list_feedback", [res.result]);
            let contracts = [];
            let elements = res.result.elements;
            for (let i = 0; i < elements.length; i++) {
                contracts.push(new Contract(elements[i]));
            }
            return {
                contracts: contracts,
                next_offset: res.result.next_offset,
                last_request: res.result.last_request,
                total_count: res.result.total_count
            };
        } else return apiError;
    }

    /*
                    update function - params must be a valid object
                 */
    static async update(params) {

        params = getAgencyID(params); // sets values for agency/ employee


        let communication_params = {
            service: apiCalculationManagementService,
            update_function: apiFunctionContractModify,
        };
        let c = getCommunicationClass(communication_params);
        var res = await c.update(params);
        if (res.status === "ok") return new Contract(res.result);
        return apiError;
    }

    static async uploadSignedContract(params, file) {
        params = getAgencyID(params); // sets values for agency/ employee


        let communication_params = {
            service: apiServiceContractGenerator,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: apiFunctionUploadContractFile,
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);
        let res = await c.add(params, file);
        if (res.status === "ok") return res.result;
        else return apiError;
    }

    static async modify_offer_res(params) {
        params = getAgencyID(params); // sets values for agency/ employee
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "modify_offer_result",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);


        let res = await c.update(params);
        if (res.status === "ok") {
            if(validateVar(params.protocol_id)) return res.result;
            else return new Offer(res.result);
        }
        else return apiError;
    }

    static async upload_snigned_contract(params, file) {

        params = getAgencyID(params); // sets values for agency/ employee
        let communication_params = {
            service: apiServiceContractGenerator,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: apiFunctionUploadContractFile,
            download_function: "",
            params: null,
        };
        let c = getCommunicationClass(communication_params);

        let res = await c.add(params, file);
        if (res.status === "ok") return res.result;
        else return apiError;
    }

    static async download(params) {
        let res = null;
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionContractExport,
            params: null,
        };
        let c = getCommunicationClass(communication_params);
        let data = getAgencyID({});
        if (Array.isArray(params)) {
            for (let id of params) {
                data.contract_id = id;
            }
            res = await c.download(data);
        } else {
            res = await c.download(data);
        }
        return res;
    }

    static dummy() {
        let output = {};
        output = this.build_dummy(contract_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    }else if (validateVar(vars[index].is_class_arr)) {
                        let class_name = callClassWithString(index);
                        output[index] = class_name.constructor.dummy();
                    }else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }


    // OLD CALLS
    static async instantiateContract(contract_id, type = "extended") {
        let params = {
            contract_id: contract_id,
            type: type,
            options: {with_contract_signed: 1, with_consultant_employee_data: 1},
        };
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetContract,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);

        let res = await c.load();
        if (res.status === "ok") {
            let result = res.result;
            if (validateVar(result.authority_file)) result.authority_file_url = await this.getDownloadUrl(result.authority_file, "application/pdf", true);
            return new Contract(result);
        } else {
            return "ERR";
        }
    }


    static async generateContract(params) {
        params = getAgencyID(params);

        // let c = new Communication(
        //     apiServiceContractGenerator,
        //     apiFunctionGenerateContract,
        //     apiFunctionGenerateContract,
        //     "",
        //     "",
        //     "",
        //     params
        // );
        let communication_params = {
            service: apiServiceContractGenerator,
            get_function: apiFunctionGenerateContract,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params,
        };
        let c = getCommunicationClass(communication_params);

        let res = await c.load();
        if (res.status === "ok") return "CONTRACT GENERATED";
        else return apiError;
    }

    static async displayContract(params, download_contract) {
        params = getAgencyID(params);
        // let c = new Communication(
        //     apiServiceContractGenerator,
        //     apiFunctionDisplayContract,
        //     apiFunctionGenerateContract,
        //     apiFunctionDisplayContract,
        //     apiFunctionDisplayContract,
        //     apiFunctionDisplayContract,
        //     params
        // );

        let communication_params = {
            service: apiServiceContractGenerator,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionDisplayContract,
            params: null,
        };
        let c = getCommunicationClass(communication_params);

        let res = await c.download(params, "application/pdf", true, download_contract);
        if (validateVar(res.status)) return apiError;
        else return res;
    }


    static async getContractForOffer(params, type = "extended") {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: apiFunctionGetContract,
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionDisplayContract,
            params: params,
        };
        let c = getCommunicationClass(communication_params);


        let res = await c.load();
        if (res.status === "ok") {
            let result = res.result;
            let data = [];
            if (result.hasOwnProperty("offer_result")) {
                let offer_res = result.offer_result;
                let offer = (result.offer_result && result.offer_result.offer) ? result.offer_result.offer : null;
                if (offer_res.hasOwnProperty("tariff")) {
                    let tariff = offer_res.tariff;

                    tariff.contract_begin = result.contract_begin;
                    tariff.contract_end = result.contract_end;
                    tariff.authority_file = result.authority_file;
                    tariff.contract_id = result.id;
                    tariff.offer_result_id = offer_res.id;
                    tariff.payment_method = result.payment_method;
                    tariff.contract_file = result.contract_file;
                    tariff.supplier_name = result.supplier_name;
                    tariff.contract_status = result.status;
                    tariff.consultant_employee = result.consultant_employee;

                    // offer_res.tariff.
                    if (tariff.hasOwnProperty("supplier")) {
                        if (
                            tariff.supplier.hasOwnProperty("settings") &&
                            typeof validateVar(tariff.supplier.settings.accept_differing_billing_address)
                        ) {
                            let settings = tariff.supplier.settings;
                            tariff.accept_differing_billing_address = settings.accept_differing_billing_address;
                            tariff.cancellation_service = settings.cancellation_service;
                        }
                        if (
                            tariff.supplier.hasOwnProperty("information") &&
                            typeof validateVar(tariff.supplier.information.logo_file_id)
                        ) {
                            let logo_id = tariff.supplier.information.logo_file_id;
                            let brochure_file_id = tariff.supplier.information.brochure_file_id;
                            // let logo_id = tariff.supplier.information.brochure_file_id;
                            // add the logo url to the result obj
                            // var logo_url = await this.getDownloadUrl(logo_id);
                            var logo_url = static_download_url + logo_id;
                            var brochure_url_obj = await this.getDownloadUrl(brochure_file_id);
                            if (
                                tariff.hasOwnProperty("tariff_information") &&
                                typeof validateVar(tariff.tariff_information.labelling_file_id)
                            ) {
                                let label_id = tariff.tariff_information.labelling_file_id;
                                var label_url_obj = await this.getDownloadUrl(label_id);
                            }


                            //add all files for this tariff
                            tariff.files = {
                                logo_url: logo_url,
                                label_url: label_url_obj.url,
                                brochure_url: brochure_url_obj.url
                            };
                        }
                    }
                    return {
                        tariff: new Tariff(tariff),
                        offer: new Offer(offer)
                    };
                }
            }
        } else {
            return apiError;
        }
    }


    static async getAllContractsForUser(id) {
        let params = {
            customer_id: id,
            type: "extended"
        };
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "contract_list",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: "",
            params: params
        };
        let c = getCommunicationClass(communication_params);


        var res = await c.load();
        let listResults = [];
        if (res.status === "ok") {
            if (res.result === "[]" || res.result === [] || res.result.elements === [] || res.result.elements === "[]") {
                return {
                    contracts: [],
                    next_offset: res.result.next_offset,
                    last_request: res.result.last_request,
                    total_count: res.result.total_count
                };
            }
            for (var i = 0; i < res.result.elements.length; i++) {
                listResults.push(new Contract(res.result.elements[i]));
            }
            return {
                contracts: listResults,
                next_offset: res.result.next_offset,
                last_request: res.result.last_request,
                total_count: res.result.total_count
            };
        } else return apiError;
    }

    /*
              Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
              Call Communication Class with matching global defined Vars for API Request
              return Array with all TariffCalculatedContainer Obj
       */
    // static async instantiateList() {
    //     this.c = new Communication(
    //         apiCalculationManagementService,
    //         apiFunctionGetContractList,
    //         "",
    //         "",
    //         "",
    //         "",
    //         { consultant_id: consultant_id, employee_id: employee_id, type: "extended" }
    //     );
    //     let res = await this.c.load();
    //     if (res.status === "ok"){
    //         if (res.result === "[]") return [];
    //         let listResults = [];
    //         for (var i = 0; i < res.result.length; i++) {
    //             listResults.push(new Contract(res.result[i]));
    //         }
    //         return listResults;
    //     } else return "ERR";
    // }


    static async createContract(params) {
        params = getAgencyID(params);
        let communication_params = {
            service: apiCalculationManagementService,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: apiFunctionCreateContract,
            download_function: "",
            params: params
        };
        let c = getCommunicationClass(communication_params);


        let res = await c.add(params);
        if (res.status === "ok") return new Contract(res.result);
        else if (res.result === "Permission denied. Consumption Point bound in offer.") return "Bound";
        else return apiError;
    }


    static async getContractFile(params) {
        params = getAgencyID(params); // sets values for agency/ employee

        let communication_params = {
            service: apiServiceContractGenerator,
            get_function: "",
            update_function: "",
            delete_function: "",
            add_function: "",
            download_function: apiFunctionDisplayContract,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        return await c.download(
            params,
            "application/pdf",
            true
        );
    }

    static async download_csv_export(params){
        let function_url = "contract/generator/export";
        params = getAgencyID(params, true);

        let communication_params = {
            method: 'GET', // GET / POST / PUT / DELETE  -> method
            function_url:  function_url,
            params: params,
            is_download: true
        };
        let c = getCommunicationClass(communication_params);
        let res = await c.request_handler();
        if (res !== apiError)
            return true; // no api error
        else
            return res; // api error
    }

}

export default Contract;
