import validateVar from "../module_components/Functions/validation/validateVariable";
import { apiError } from "../module_components/Functions/global";
import { displayErrorMessage, displaySuccessMessage } from "./user_feedback";
import Offer from "../module_components/Objects/offer";
import Table from "../module_components/Objects/table";
import Analytics from "../module_components/Objects/analytics";
import * as ERROR_CODE from "../error_codes";
import Clearing from "../module_components/Objects/clearing";

export function handle_price_ranking(params, method) {
  return function(dispatch) {
    Offer.handle_price_ranking(params, method).then(res => {
      let is_list = false;
      if (validateVar(params)) is_list = true; // check if list request is called
      if (res !== apiError) {
        if (Array.isArray(res)) {
          // return list
          if (res.includes(apiError)) {
            // includes error
            if (params.is_list) dispatch({ type: "INITIALIZE_TABLE", res: [] });
          } else {
            // SUCCESS
            dispatch({ type: "INITIALIZE_TABLE", res: res });
          } // todo upload list
        } else {
          if (method === "POST")
            dispatch({ type: "CREATED_NEW_TABLE_LAYOUT", res: res });
          else if (method === "PUT")
            dispatch({ type: "UPDATE_TABLE_LAYOUT", res: res });
          else if (method === "DELETE")
            dispatch({ type: "DELETE_TABLE_LAYOUT", res: res });
          else dispatch({ type: "INIT_OFFER", res: res });
        }
      } else {
        dispatch({ type: "INIT_OFFER", res: [] }); // show empty table if error
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Tabellen Layouts konnten nicht geladen werden. (Fehlercode: " +
              // ERROR_CODE.GetLayoutError +
              ")", // content
            "Fehler", //headline
            null, // url,
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function handle_table_actions(params, method) {
  return function(dispatch) {
    Table.handle_table_actions(params, method).then(res => {
      let is_list = false;
      if (validateVar(params)) is_list = true; // check if list request is called
      if (res !== apiError) {
        if (Array.isArray(res)) {
          // return list
          if (res.includes(apiError)) {
            // includes error
            if (params.is_list) dispatch({ type: "INITIALIZE_TABLE", res: [] });
          } else {
            // SUCCESS
            dispatch({ type: "INITIALIZE_TABLE", res: res });
          } // todo upload list
        } else {
          if (method === "POST") {
            dispatch({ type: "CREATED_NEW_TABLE_LAYOUT", res: res });
            dispatch({
              type: "SET_USER_MESSAGE",
              res: displaySuccessMessage(
                "Das neue Tabellenlayout wurde gespeichert.", // content
                "Erfolg", //headline
                null, // url,
                false, //automatic redirect
                false //modal
              )
            });
          } else if (method === "PUT") {
            dispatch({ type: "UPDATE_TABLE_LAYOUT", res: res });
            dispatch({
              type: "SET_USER_MESSAGE",
              res: displaySuccessMessage(
                "Das Tabellenlayout wurde aktualisiert.", // content
                "Erfolg", //headline
                null, // url,
                false, //automatic redirect
                false //modal
              )
            });
          } else if (method === "DELETE")
            dispatch({ type: "DELETE_TABLE_LAYOUT", res: res });
          else dispatch({ type: "INITIALIZE_TABLE", res: res });
          // dispatch({type: "INITIALIZE_TABLE", res: res}); // return single employee
        }
      } else {
        dispatch({ type: "INITIALIZE_TABLE", res: apiError }); // show empty table if error
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Tabellen Layouts konnten nicht geladen werden. (Fehlercode: " +
              // ERROR_CODE.GetLayoutError +
              ")", // content
            "Fehler", //headline
            null, // url,
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function handle_chart_data(params, method) {
  return function(dispatch) {
    Analytics.handle_chart_data(params, method).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_CHART_DATA", res: res });
      } else {
        dispatch({ type: "INITIALIZE_CHART_DATA", res: {} });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Daten konnten nicht geladen werden. (Fehlercode: " +
              ERROR_CODE.GetAnalyticsData +
              ")", // content
            "Fehler", //headline
            null, // url,
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function handle_overview_tasks(params, method) {
  return function(dispatch) {
    Analytics.handle_overview_tasks(params, method).then(res => {
      if (res !== apiError) {
        dispatch({ type: "INITIALIZE_OVERVIEW_TASKS", res: res });
      } else {
        dispatch({ type: "INITIALIZE_OVERVIEW_TASKS", res: null });
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Daten konnten nicht geladen werden. (Fehlercode: " +
              ERROR_CODE.GetOverviewTasks +
              ")", // content
            "Fehler", //headline
            null, // url,
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function initClearingList(params = null) {
  return function(dispatch) {
    // dispatch({
    //   type: "INITIALIZE_CLEARING_LIST",
    //   res: {
    //     clearing: [],
    //     total_count: 0,
    //     next_offset: null
    //   }
    // });
    Clearing.handle_clearing(params).then(res => {
      if (res !== apiError) {
        if (Array.isArray(res.clearing)) {
          // return list
          dispatch({ type: "INITIALIZE_CLEARING_LIST", res: res });
        } else {
          dispatch({ type: "INITIALIZE_CLEARING", res: res }); // return single employee
        }
      } else {
        if (!validateVar(params))
          dispatch({ type: "INITIALIZE_CLEARING_LIST", res: "ERR" }); //return list error
        dispatch({
          type: "SET_USER_MESSAGE",
          res: displayErrorMessage(
            "Die Aufgabenliste konnte nicht geladen werden. (Fehlercode: )", // content
            "Fehler", //headline
            null, // url,
            false, //automatic redirect
            true, //modal
            null, // primaryBtnText
            "Bestätige", // secondaryBtnText
            true, //allowToggle (user can close modal without pressing a btn)
            false, // successIcon
            false, // infoIcon
            "danger" //type - danger/success
          )
        });
      }
    });
  };
}

export function handle_reset_actions(reset_function_name) {
  return function(dispatch) {
    dispatch({ type: "RESET_" + reset_function_name.toUpperCase(), res: null });
  };
}

// export function handle_team_actions(function_name = null ,params = null, method = "GET",   url = null, file = null  ) {
//     if (validateVar(function_name)){
//         return function (dispatch) {
//             dispatch(action_team[function_name](function_name ,params, method, url, file ))
//         }
//     }
// }
