import {apiBearer, apiContentType, apiURL} from "../../../Functions/global";
import isEmptyObject from "../../../Functions/isEmptyObject";
import is_true from "../../../Functions/is_true";

class Communication {
    constructor(
        service = "",
        get_function = "",
        update_function = "",
        delete_function = "",
        add_function = "",
        download_function = "",
        params = "",
        file = "",
        version = ""
    ) {
        this.service = service;
        this.get_function = get_function;
        this.update_function = update_function;
        this.delete_function = delete_function;
        this.add_function = add_function;
        this.download_function = download_function;
        this.params = params;
        this.version = version;
    }

    async load() {
        return await this.request(this.get_function, this.params);
    }

    update(params) {
        return this.request(this.update_function, params);
    }

    download(params, type = "text/csv", return_link = false, download_contract) {
        return this.file_download(
            this.download_function,
            params,
            type,
            return_link,
            download_contract
        );
    }

    delete(params) {
        return this.request(this.delete_function, params);
    }

    async delete_async(params) {
        return this.request(this.delete_function, params);
    }

    async add(params, file) {
        return this.request(this.add_function, params, file);
    }

    async request(function_name, params, file = "") {
        let content_type = apiContentType;
        var data;
        if (file !== "") {
            content_type = "multipart/form-data";
            data = new FormData();
            data.append("service", this.service);
            data.append("function", function_name);
            data.append("file", file);
            for (let [key, value] of Object.entries(params)) {
                if (typeof value === "object") {
                    for (let [key_, value_] of Object.entries(value)) {
                        data.append("param[" + key + "][" + key_ + "]", value_);
                    }
                } else data.append("param[" + key + "]", value);
            }
            let options = {
                method: "POST",
                version: this.version,
                headers: {
                    Authorization: apiBearer
                },
                http_errors: false,
                body: data
            };

            var f = await fetch(apiURL, options);
            f = f.json();
        } else {
            data = {
                version: this.version,
                service: this.service,
                function: function_name,
                param: params,
            };

            data = JSON.stringify(data);

            var f = await fetch(apiURL, {
                method: "POST",
                version: this.version,
                headers: {
                    Authorization: apiBearer,
                    "Content-Type": content_type
                },
                http_errors: false,
                body: data
            });

            if (f.status === 200) f = await f.json();
        }
        return f;
    }

    async file_download(function_name, params, type, return_link, download_contract = false) {
        let data = {
            version: this.version,
            service: this.service,
            function: function_name,
            param: params
        };
        data = JSON.stringify(data);
        var f = await fetch(apiURL, {
            method: "POST",
            headers: {
                Authorization: apiBearer,
                "Content-Type": apiContentType
            },
            http_errors: false,
            body: data
        });
        if (f.status === 200) {
            let file_name_headers = "download";
            if (!isEmptyObject(f.headers.get("Content-Disposition"))) {
                let file_name_headers = f.headers.get("Content-Disposition");
                let possible_strings_to_replace = ['attachment;', 'filename=', 'filename*=', '"', ' ', ' '];
                for (let to_replace of possible_strings_to_replace){
                    file_name_headers = file_name_headers.replaceAll(to_replace, '');
                }
                file_name_headers = decodeURIComponent(file_name_headers);
                if (return_link) {
                    return await Communication.return_blob(f, file_name_headers, download_contract);
                }
            } else {
                if (return_link) {
                    return await Communication.return_blob(f, file_name_headers);
                }
            }
            let url_obj = await Communication.return_blob(f, file_name_headers);
            var link = document.createElement("a");
            link.href = url_obj.url;
            link.download = url_obj.file_name;
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);

            return true;
        } else {
            return f;
        }
    }

    static async return_blob(f, headers_file_name, download_contract, return_blob = false){
        let newBlob = null;
        let blob = await f.blob();
        newBlob = new Blob([blob], {type: f.headers.get('Content-Type')});

        newBlob.name = headers_file_name;
        let data = window.URL.createObjectURL(newBlob);

        if (is_true(return_blob)) return data;
        else if (window.navigator && window.navigator.msSaveOrOpenBlob && download_contract) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return true;
        } else {
            return {
                file_name: headers_file_name,
                url: data,

            };
        }
    }
}

export default Communication;
