import validateVar from "../Functions/validation/validateVariable";
import Tree from "../Objects/tree";
import {description_display_length} from "../Functions/global";
import is_true from "../Functions/is_true";
import handleFormatDateForFrontend from "../Functions/handleFormatDateForFrontend";
import get_standardised_date from "../Functions/get_standardised_date";
let initialState = {};

function tree(state = initialState, action) {
    let new_state = JSON.parse(JSON.stringify(state));
    let tree = (validateVar(action.res) && is_true(action.res.global)) ? new_state.global_tree : new_state.tree;
    if (action.type === "INIT_TREE") {
        let {tree} = action.res;
        let tree_copy = JSON.parse(JSON.stringify(tree)); // get original global tree
        return get_return_state(tree, new_state, null, tree_copy);
    } if (action.type === "INIT_GLOBAL_TREE") {
        let {tree} = action.res;
        let global_tree_copy = JSON.parse(JSON.stringify(tree)); // get original global tree
        if (validateVar(new_state.tree) && validateVar(new_state.tree.tree_attributes_global_edited) && new_state.tree.tree_attributes_global_edited.length > 0) {
            let tree_attributes = tree.groups[0].tree_attributes;
            for (let overwritten_attributes of new_state.tree.tree_attributes_global_edited) {
                let index = tree_attributes.findIndex(x => x.attribute_id === overwritten_attributes.attribute_id);
                if (validateVar(index) && index !== -1){
                    tree_attributes.splice(index, 1);
                }
            }
            // https://9gag.com/gag/av5VEZb
            tree_attributes.push(...new_state.tree.tree_attributes_global_edited);
        }
        return get_return_state(tree, new_state, global_tree_copy);
    }
    else if (action.type === "CREATE_EMPTY_TREE") {
        let empty_tree = action.res;
        let global_tree_copy = null; // only needed if global
        if (empty_tree.is_global) {
            global_tree_copy = JSON.parse(JSON.stringify(empty_tree)); // get original global tree
        }
        return get_return_state(action.res, new_state, global_tree_copy);
    }else if (action.type === "RESET_TREE") {
        return get_return_state(action.res, new_state);
    } else if (action.type === "RE_ORDER_TREE_GROUPS") {
        let {type, from} = action.res;
        tree.groups = re_order_groups(from, type, tree.groups);
        return new_state;
    } else if (action.type === "ADD_ATTRIBUTE_TO_GROUP") {
        let {attribute, group_id, tree_attributes} = action.res;
        let group = tree.groups.find(x => x.id === group_id);
        attribute.is_expanded = true;
        let attribute_new = new Tree(attribute, tree_attributes);
        attribute_new.group_index = 0;
        group.tree_attributes.push(attribute_new);
        return new_state;
    } else if (action.type === "GROUP_EDIT") {
        let data = action.res;
        let group = tree.groups.find(x => x.id === data.group_id);
        group.name = data.name;
        group.description = data.description;
        group.description_short = create_short_description(group.description);
        return new_state;
    } else if (action.type === "GROUP_DELETE") {
        let group_id = action.res;
        let group_index_in_array = tree.groups.findIndex(x => x.id === group_id);
        tree.groups.splice(group_index_in_array, 1);
        return new_state;
    } else if (action.type === "GROUP_ADD") {
        let data = action.res;
        let new_group = {
            description: data.description,
            description_short: create_short_description(data.description),
            id: get_unused_group_id(1, tree),
            name: data.name,
            tree_attributes: [],
            tree_id: data.tree_id,
        };
        tree.groups.push(new_group);
        return new_state;
    } else if (action.type === "GROUP_COPY") {
        let group_data = action.res;
        let selected_group = JSON.parse(JSON.stringify(tree.groups.find(x => x.id === group_data.group_id))); // get group you want to copy
        selected_group.id = get_unused_group_id(group_data.group_id, tree);
        selected_group.name = group_data.name;
        selected_group.description = group_data.description;
        selected_group.description_short = create_short_description(selected_group.description);
        tree.groups.push({...selected_group});
        return new_state;
    } else if (action.type === "BRANCH_DELETE") {
        let {condition_index, unique_id} = action.res;
        let {group_id, attribute_id} = handle_unique_id(unique_id); // unique = group_id "-" attribute_id
        let selected_group = tree.groups.find(x => +x.id === +group_id); // get group you want need to edit
        let selected_attribute = selected_group.tree_attributes.find(x => +x.attribute_id === +attribute_id); // get group you want need to edit
        selected_attribute.branches.splice(condition_index, 1); // delete branch
        if (selected_attribute.branches.length === 0) { // if no branches left -> delete Attribute
            let selected_attribute_index = selected_group.tree_attributes.findIndex(x => +x.attribute_id === +attribute_id); // index to delete attribute
            selected_group.tree_attributes.splice(selected_attribute_index, 1); // delete attribute
        } else {
            selected_attribute.used_operators = get_used_oprators(selected_attribute.branches);
        }

        if (selected_attribute.type === "multiselect") {
            let max_options = selected_attribute.options.length;
            let multiselect_options_obj = check_multiselect_options(selected_attribute.branches, max_options);
            let {disable_add_btn} = multiselect_options_obj;
            selected_attribute.disable_add = (is_true(check_multiselect_length_options(selected_attribute, false, true)) || is_true(disable_add_btn));
        }
        return new_state;
    } else if (action.type === "BRANCH_ADD") {
        let {unique_id} = action.res;
        let {group_id, attribute_id} = handle_unique_id(unique_id); // unique = group_id "-" attribute_id
        let selected_group = tree.groups.find(x => +x.id === +group_id); // get group you want to add the branch in
        let selected_attribute = selected_group.tree_attributes.find(x => +x.attribute_id === +attribute_id); // get attribute you want to add the branch in
        if (selected_attribute.type === "multiselect") {
            let max_options = selected_attribute.options.length;
            let multiselect_options_obj = check_multiselect_options(selected_attribute.branches, max_options);
            let {disable_add_btn} = multiselect_options_obj;
            selected_attribute.disable_add = (is_true(check_multiselect_length_options(selected_attribute, true)) || is_true(disable_add_btn));
        } else {
            selected_attribute.used_operators = get_used_oprators(selected_attribute.branches);
        }
        let object = Tree.create_fake_branch(null, selected_attribute);
        selected_attribute.branches.push(object.conditions[0]); // must take [0] here
        return new_state;
    } else if (action.type === "SET_TEXT_FORM") {
        let {unique_id, index, text_form} = action.res;
        let {group_id, attribute_id} = handle_unique_id(unique_id); // unique = group_id "-" attribute_id
        let selected_group = tree.groups.find(x => +x.id === +group_id); // get group you want to add the branch in
        let selected_attribute = selected_group.tree_attributes.find(x => +x.attribute_id === +attribute_id); // get attribute you want to add the branch in
        selected_attribute.branches[index].text_form = text_form;
        return new_state;
    } else if (action.type === "BRANCH_EDIT") {
        let {condition_index, unique_id, condition} = action.res;
        let {group_id, attribute_id} = handle_unique_id(unique_id); // unique = group_id "-" attribute_id
        let selected_group = tree.groups.find(x => +x.id === +group_id); // get group you want need to edit
        let selected_attribute = selected_group.tree_attributes.find(x => +x.attribute_id === +attribute_id); // get group you want need to edit
        if (selected_attribute.type === "date") {
            if(validateVar(condition.comparison_value_1)){
                condition.comparison_value_1_display = handleFormatDateForFrontend(condition.comparison_value_1);
                condition.comparison_value_1 = new Date(get_standardised_date(condition.comparison_value_1)).getTime();
            }
        } else if (selected_attribute.type === "multiselect") {
            let max_options = selected_attribute.options.length;
            let multiselect_options_obj = check_multiselect_options(selected_attribute.branches, max_options);
            let {disable_add_btn} = multiselect_options_obj;
            selected_attribute.disable_add = (is_true(check_multiselect_length_options(selected_attribute)) || is_true(disable_add_btn));
        }
        selected_attribute.branches[condition_index] = JSON.parse(JSON.stringify(condition));
        return new_state;
    } else if (action.type === "EDIT_BRANCH_OPERATOR") {
        let {condition_index, unique_id, operator} = action.res;
        let {group_id, attribute_id} = handle_unique_id(unique_id); // unique = group_id "-" attribute_id
        let selected_group = tree.groups.find(x => +x.id === +group_id); // get group you want need to edit
        let selected_attribute = selected_group.tree_attributes.find(x => +x.attribute_id === +attribute_id); // get group you want need to edit
        selected_attribute.branches[condition_index].comparison_operator = operator;
        selected_attribute.used_operators = get_used_oprators(selected_attribute.branches);
        return new_state;
    } else return state;
}

function get_return_state(result, state, original_global_tree = null, original_tree = null) {
    return {
        global_tree: is_true(result.is_global) ? result : state.global_tree,
        tree: !is_true(result.is_global) ? result : state.tree,
        original_global_tree: (validateVar(original_global_tree)) ? original_global_tree : state.original_global_tree,
        original_tree: (validateVar(original_tree)) ? original_tree : state.original_tree
    }
}

function handle_unique_id(unique_id) {
    if (validateVar(unique_id)) {
        let result = unique_id.split("-");
        return {
            group_id: result[0],
            attribute_id: result[1],
        }
    }
}

function get_used_oprators(branches) {
    let used_operators = [];
    for (let branch of branches) {
        used_operators.push(branch.comparison_operator);
    }
    return used_operators;
}


function check_multiselect_options(branches, max_options_length) {
    let used_option_len = 0;
    let disable_add_btn = false;
    let remove_empty_index = [];
    // let index = 0;
    for (let branch of branches) {
        let branch_length = branch.comparison_value_1.length;
        used_option_len = +used_option_len + +branch_length;
        // if (+branch_length === 0)  {
        //     remove_empty_index.push(index);
        // }
        // index = index +1;
    }
    if (used_option_len === max_options_length) {
        disable_add_btn = true;
    }

    return {
        disable_add_btn: disable_add_btn,
        remove_empty_index: remove_empty_index
    };
}

function check_multiselect_length_options(selected_attribute, is_add = false, is_delete = false) {
    let disable_add = false;
    let max_options_length = selected_attribute.options.length;
    let current_branches_length = selected_attribute.branches.length;
    if (is_true(is_add)) current_branches_length = current_branches_length+1; // since the added branch is not existing yet we have to add 1 manualy
    if (is_true(is_delete)) current_branches_length = current_branches_length-1; // since the added branch is not existing yet we have to add 1 manualy
    if (+max_options_length === (+current_branches_length)) {
        disable_add = true;
    }
    return disable_add;
}

function create_short_description(description) {
    // shorten description if is longer than description_display_length -> responsiv
    return validateVar(description) ? (description.length > description_display_length) ? description.substr(0, description_display_length) + "..." : description : "";
}

function get_unused_group_id(group_id, state) {
    let till = 9999;
    let check_id = group_id;
    for (let i = 0; i < till; i++) {
        check_id = check_id + 1;
        if (!validateVar(state.groups.find(x => x.id === check_id))) {
            break;
        }
    }
    return check_id;
}

function re_order_groups(from, type, groups) {
    let to = null;
    if (type === "move_up") to = from - 1;
    else if (type === "move_down") to = from + 1;
    groups.splice(to, 0, groups.splice(from, 1)[0]);
    return groups;
}

export default tree;
