import {LoginPath} from "../../globalNavigation";

export default function logoutEmployee(redirect = true) {
  localStorage.removeItem("evuSecurityToken");
  localStorage.removeItem("employeeSecurityToken");
  localStorage.removeItem("evuEmail");
  localStorage.removeItem("evuUserAccount");
  localStorage.removeItem("integrations");
  if (redirect === true) redirectToLogin();
}


function redirectToLogin() {
  window.location.href = LoginPath;
}
