import validateVar from "./validation/validateVariable";
import check_if_ios from "./check_if_ios";
import get_standardised_date from "./get_standardised_date";

/*
    @param dateformat type string, value (date/time) specifies if date value contains time or not
 */
function convertToIECompatibleDate(value, dateformat = 'date') {
    let date = [];
    value = get_standardised_date(value);
    if(dateformat === 'time'){
        //separate time from date + create array of 3 values (year / month / day)
        if(validateVar(value)) {
            date = value.split('T')[0].split('-');
            for(let timepart of value.split('T')[1].split(':')){
                date.push(timepart);
            }
        }

        return date;
    }else if(dateformat === 'date'){
        //create array of 3 values (year / month / day). (there is no time value in offer_end so dont need to split on " ";
        if(validateVar(value)) {
            date = value.split('-');
        }
        //Need to pass date this way to "new Date()" to help internet explorer understanding it.
        return date;
    }
}

export default convertToIECompatibleDate;