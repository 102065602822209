import validateVar from "./validateVariable";

function isValidUserRole(role) {
  if (validateVar(role)) {
    if (role === "admin" || role === "nutzer" || role === "manager") {
      return true;
    }
  } else {
    return false;
  }
}
export default isValidUserRole;
