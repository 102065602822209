import React from "react";
import PropTypes from "prop-types";
import validateVar from "../../Functions/validation/validateVariable";

class Checkbox extends React.Component {
   onlyOne = (checkbox) => { // function die alle anderen checkboxen mit dem selben namen unchecked
        var checkboxes = document.getElementsByName(this.props.name);
        checkboxes.forEach((item) => {
            if (item !== checkbox) {
                this.props.on_click(item.name, false);
                item.checked = false
            }
        })
    };


    handleToggleCheckbox = (e) => { // Enable / Disable Checkbox
        const {checked_value, unchecked_value} = this.props;
        let value = '';

        if(e.target.checked) {
            value = validateVar(checked_value) ? checked_value : true;
            if (validateVar(this.props.checkOnlyOne)) this.onlyOne(e.target);
            this.props.on_click(e.target.name, value);
        } else {
            value = validateVar(unchecked_value) ? unchecked_value : false;
            this.props.on_click(e.target.name, value);
        }
    };

    render(){
        var checked = false;
        const {classes, value, checked_value, unchecked_value, id, disabled, text, label_classes, name} = this.props;
        if (value+"" === checked_value+"") checked = true;

        return(<div className='custom-control custom-checkbox'>
            <input disabled={disabled}
                onChange={(e) => this.handleToggleCheckbox(e)}
                className={"custom-control-input " + classes}
                data-not-checked={unchecked_value}
                data-is-checked={checked_value}
                id={id}
                type="checkbox"
                name={name}
                checked={checked}
            />
            <label
                className={"custom-control-label "+ label_classes}
                htmlFor={id}
            >
                <span>{text}</span>
            </label>
        </div>)
    }
}

Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.string.isRequired,
    ]),
    classes: PropTypes.string,
    label_classes: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.bool.isRequired
    ]),
    on_click: PropTypes.func.isRequired
};

export default Checkbox;