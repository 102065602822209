import React from 'react';
import Skeleton from "react-loading-skeleton";

class PrevStateDownloadValue extends React.Component {

    render() {
        const {is_loaded, value_edited, name, history_obj, object} = this.props;

        return(<>{is_loaded ? <div className='changeable-data-download'>
            {value_edited && <span className='changes-detected-download'
                                        onClick={() => this.props.download(name, history_obj[name])}> !
            </span>}
            <span className='text-underline cursor-pointer'
                  onClick={() => this.props.download(name, object[name])}>
                Download
            </span>
        </div> : <Skeleton width={250} height={20}/>}</>)
    }
}

export default PrevStateDownloadValue;