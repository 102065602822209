import {Row, Col} from "reactstrap";
import React from "react";
import PropTypes from 'prop-types';
import Checkbox from './Checkbox/Checkbox';

const TwoCheckboxes = (props) => {
    let firstCheckboxDisabled = props.first_checkbox.disabled;
    let secondCheckboxDisabled = props.second_checkbox.disabled;

    if (props.disabled === true) {
        firstCheckboxDisabled = true;
        secondCheckboxDisabled = true;
    }

    return <div className='max-form-width'>
        <Row>
            <Col xl={6}>
                <Checkbox id={props.first_checkbox.id}
                          name={props.first_checkbox.name}
                          value={props.first_checkbox.value}
                          disabled={firstCheckboxDisabled}
                          text={props.first_checkbox.text}
                          checked_value={props.first_checkbox.checked_value}
                          unchecked_value={props.first_checkbox.unchecked_value}
                          on_click={(name, value) => props.on_change(name, value)}
                />
            </Col>
            <Col xl={6}>
                <Checkbox id={props.second_checkbox.id}
                          name={props.second_checkbox.name}
                          value={props.second_checkbox.value}
                          disabled={secondCheckboxDisabled}
                          text={props.second_checkbox.text}
                          checked_value={props.second_checkbox.checked_value}
                          unchecked_value={props.second_checkbox.unchecked_value}
                          on_click={(name, value) => props.on_change(name, value)}
                />
            </Col>
        </Row>
    </div>
};


TwoCheckboxes.propTypes = {
    name: PropTypes.string.isRequired, //Name of the Radios (ideally same as the state name)
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([ // default value
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
    ]),
    first_checkbox: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,  // Displayed Radio-Text
        value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
        ]),
        checked_value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
        ]),  // The value which will be passed to parent from the selected Radio
        unchecked_value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
        ]),  // The value which will be passed to parent from the selected Radio
    }),
    second_radio: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired, // Displayed Radio-Text
        value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
        ]), // The value which will be passed to parent from the selected Radio
        checked_value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
        ]),  // The value which will be passed to parent from the selected Radio
        unchecked_value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
        ]),
    }),
};

export default TwoCheckboxes;
