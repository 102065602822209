/* CALCULATION */
export const CalculateOfferError = "0001";
export const ReCalculateTariffsError = "0002";
export const GetCalculatedTariffsError = "0003";
export const GetNetFeeError = "0004";

/* GET */
export const GetPfcList = "1001";
export const GetTariffList = "1002";
export const GetTariff = "1003";
export const GetIntegrations = "1004";
export const GetIntegrationList = "1005";
export const GetCompny = "1006";
export const GetContract = "1007";
export const GetConsumptionPoints = "1008";
export const GetBankData = "1009";
export const GetAttributes = "1010";
export const GetConsultants = "1011";
export const GetEmployee = "1012";
export const GetEmployees = "1012";
export const GetGlobalPFC = "1013";
export const GetContractTemplate = "1014";
export const GetAllAnalyticsData = "1015";
export const GetAnalyticsData = "1016";
export const GetLoadProfile = "1017";
export const GetChronik = "1018";
export const GetLayoutError = "1019";
export const GetOverviewTasks = "1020";

/* ADD */
export const AddPfc = "2001";
export const AddTariff = "2002";
export const AddIntegration = "2003";
export const AddGlobalTree = "2004";
export const AddChronik = "2005";

/* UPDATE */
export const UpdateTariff = "3001";
export const UpdateTariffTable = "3002";
export const UpdateContract = "3003";
export const UpdateEmployee = "3004";
export const UpdateCompanyAndPartner = "3005";
export const UpdateCompany = "3006";
export const UpdateSupplierGlobalTree = "3007";
export const UpdateEmployeeError = "7001";

/* DELETE */
export const DeleteIntegrations = "5001";
export const DeleteTariff = "5002";
export const DeleteGlobalTree = "5003";
export const DeletePFC = "5004";
export const DeleteEmployee = "5005";
export const DeleteCompanyFile = "5006";

/* UPLOAD */
export const UploadSnignedContract = "8000";
export const UploadCompanyFile = "8001";
export const UploadTariffFile = "8002";
export const CleanTree = "8003";

/* CASUAL */
export const CopyTariff = "4001";
export const TestCalculate = "6001";
export const RedirectError = "7000";
