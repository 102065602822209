const invalid_malos = ["00000000000", "12312312312", "55555555555"];

function validateMaloId(maloVal) {
    //maloVal = number with length of 11 characters
    // Abb. 1
    if (invalid_malos.includes(maloVal)) return false;
    if (maloVal.match(/^[0-9]+$/)){
        // validitation
        // step a)
        var ungerade = 0;
        var count;
        for (count = 0; count < 10; count++) {
            ungerade += parseInt(maloVal.substr(count, 1));
            count++;
        }
        // step b)
        var gerade = 0;
        for (count = 1; count < 10; count++) {
            gerade += parseInt(maloVal.substr(count, 1));
            count++;
        }
        gerade *= 2;
        // step c)
        var sum = gerade + ungerade; // == subtrahent
        // step d)
        var minuend = 10 * Math.ceil(sum / 10);
        var expected = minuend - sum;
        //return true if correct / false if not correct
        return parseInt(maloVal.substr(10, 1)) === expected;
    }
}

export default validateMaloId;