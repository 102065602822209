import History from "../history";
import validateVar from "../../../Functions/validation/validateVariable";
import callClassWithString from "../../../Functions/callClassWithString";

let billing_address_vars = {
    is_sub_arr: "billing_address",
    has_history: true,
    translation_object: {
        billing_street: "Strasse (Rechnungsanschrift)",
        billing_house_number: "Hausnummer (Rechnungsanschrift)",
        billing_city: "Ort (Rechnungsanschrift)",
        billing_zip: "Postleitzahl (Rechnungsanschrift)",
        billing_country: "Land (Rechnungsanschrift)",
    },
    line2: {
        line2: null,
        house_number: null,
        billing_house_number: null
    },
    line1: {
        line1: null,
        street: null,
        billing_street: null,
        address: {
            function_name: 'combine_strings',
            params: [' ', '%%billing_house_number']
        }
    },
    city: {
        city: null,
        billing_city: null
    },
    state: {
        state: null,
        billing_state: null,
    },
    country: {
        county: null,
        billing_country: null
    },
    zip: {
        zip: null,
        postal_code: null,
        billing_zip: null,
    },
    company: {
        billing_company: null
    },
    billing_street_and_house_number: {
        function_name: "combine_strings",
        params: ["%%billing_street", " ", "%%billing_house_number"]
    },

    billing_postal_code_and_city: {
        function_name: "combine_strings",
        params: ["%%billing_zip", " ", "%%billing_city"]
    },


};

class Billing_Address {
    static default_values = billing_address_vars;
    constructor(data, status = "open") {
        if (validateVar(data)) {
            this.history = {};
            let class_content = new History(billing_address_vars, data, data.history, [], status);
            for (let name in class_content) {
                if (class_content.hasOwnProperty(name)) {
                    this[name] = class_content[name];
                }
            }
        }
    }


    static dummy() {
        let output = {};
        output = this.build_dummy(billing_address_vars);
        return output;
    }

    static build_dummy(vars) {
        let output = {};
        for (let index in vars) {
            if (vars.hasOwnProperty(index)) {

                if (!validateVar(vars[index])) {
                    output[index] = "-/-";
                } else if (typeof vars[index] === "object") {
                    if (validateVar(vars[index].is_sub_arr)) {
                        output[index] = this.build_dummy(vars[index]);
                    } else if (validateVar(vars[index].is_class_arr)) {

                        let class_name = callClassWithString(index);
                        output[index] = class_name.constructor.dummy();
                    } else {
                        output[index] = "-/-";
                    }
                }
            }
        }
        return output;
    }
}

export default Billing_Address;
