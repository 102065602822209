/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

class DashboardHeader extends React.Component {
    render() {
        return (
            <>
                <div
                    className="header header-dark bg-info pb-6 content__title content__title--calendar dashboard-header">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center pt-4">
                                <Col lg="6" xs="12" className='mb-4'>
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}

DashboardHeader.propTypes = {
    name: PropTypes.string,
    headerText: PropTypes.string
};

export default DashboardHeader;
