import Communication from "./Components/Communication/communication";
import {
    apiElectricityProfileService,
    apiError, apiFileSystemService,
    apiFunctionDownLoadLp,
    apiFunctionGetProfiles, apiFunctionReturnResource,
    apiGasProfileService,
    apiServiceLoadProfile
} from "../Functions/global";
import validateVar from "../Functions/validation/validateVariable";
import getCommunicationClass from "../Functions/getCommunication";
import getAgencyID from "../Functions/getAgencyID";

class LoadProfile {
  constructor(data) {
    /*
     *   default value for vars in this class
     *   add new vars here
     * */
    //basic
    this.key = "";
    this.value = "";

    //load basic info

    if (validateVar( data) &&  validateVar(data.id)) {
      //if data found
      this.key = typeof data.code === "undefined" ? "" : data.code;
      this.value =
          typeof data.name === "undefined"
              ? ""
              : data.name + " (" + data.code + ")";
    }
  }

  /*
              Instantiate a new Array with all TariffCalculatedContainer Obj for this Provider in it
              Call Communication Class with matching global defined Vars for API Request
              return Array with all TariffCalculatedContainer Obj
         */
  static async getLps(medium_type, last_type) {
    let service = apiElectricityProfileService;
    if (medium_type === "GAS") {
      service = apiGasProfileService;
    }
    this.c = new Communication(
        service,
        apiFunctionGetProfiles,
        "",
        "",
        "",
        "",
        { consumption_type: last_type }
    );

      let communication_params = {
          service: service,
          get_function: apiFunctionGetProfiles,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: apiFunctionReturnResource,
          params: { consumption_type: last_type },
      };
      let c = getCommunicationClass(communication_params);


    var res = await c.load();
    if (res.status === "ok") {
      let listResults = [];
      for (var i = 0; i < res.result.length; i++) {
        listResults.push(new LoadProfile(res.result[i]));
      }
      return listResults;
    } else return apiError;
  }

  static async download_load_profile(id) {
    let params = {
      id: id,
      data_type: "download",
      type: "raw"
    };
    // let c = new Communication(
    //     apiServiceLoadProfile,
    //     "",
    //     "",
    //     "",
    //     "",
    //     apiFunctionDownLoadLp,
    //     params,
    //     ""
    // );



      let communication_params = {
          service: apiServiceLoadProfile,
          get_function: apiFunctionGetProfiles,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: apiFunctionDownLoadLp,
          params: params,
      };
      let c = getCommunicationClass(communication_params);


    let res = await c.download(params);
    if (
        validateVar(res.statusText) &&
        res.statusText === "Internal Server Error"
    )
      res = apiError;
    return res;
  }


    static async handle_load_profile(params, method, function_name, file, is_download) {
        params = getAgencyID(params);
        let communication_params = {
            method: method,
            params: params,
            function_url: function_name,
            is_download: is_download,
        };

        if(method === 'GET'){
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }if(method === 'PUT'){
            if (validateVar(params.id)) communication_params.function_url = communication_params.function_url+ "/" + params.id;
        }
        let c = getCommunicationClass(communication_params, file);
        let res = await c.request_handler();

        if (res !== apiError) {
            return "ok";
        } else {
            return apiError;
        }
    }
}

export default LoadProfile;
