import React from 'react';
import Skeleton from "react-loading-skeleton"
import PropTypes from "prop-types";
import validateVar from "../../Functions/validation/validateVariable";

class BasicValueDisplay extends React.Component {

    render() {
        let {value, text, object, is_loaded, max_length} = this.props;

        if (validateVar(object) && validateVar(object[value])) value = object[value];

        value = validateVar(value) ? value : "";
        text = validateVar(text) ? text : "";
        let string_value = text + value;

        if (validateVar(max_length)) {
            if (string_value.length > max_length) {
                string_value = string_value.slice(0, max_length) + '...';
            }
        }

        return <>
            {(is_loaded) ? string_value : <Skeleton width={100} height={20}/>}
        </>
    }
}

BasicValueDisplay.defaultProps = {
    text: ''
};

BasicValueDisplay.propTypes = {
    value: PropTypes.string,
    is_laoded: PropTypes.string,
    max_length: PropTypes.number,
};

export default BasicValueDisplay;