import { apiBearerNew, apiError, apiUrlNew } from "../../../Functions/global";
import validateVar from "../../../Functions/validation/validateVariable";
import isEmptyObject from "../../../Functions/isEmptyObject";
import is_true from "../../../Functions/is_true";

class Communication {
  constructor(
    method = "", // call mothods (GET / PUT / POST / DELETE)
    function_url = "", // url with function call (if mothode: "GET" with get params in URL)
    params = null,
    file = null,
    is_download = false, // false = not download / true = download / return_link = return download link
    file_type = null, // "image/png" / "application/pdf" / "text/csv"
    version = ""
  ) {
    this.method = method; // method
    this.function_url = function_url;
    this.params = params;
    this.file = file;
    this.is_download = is_download; // request is download
    this.file_type = file_type; // file type (must be set if download is not false)
    this.version = version;
    this.api_url = (validateVar(version)) ?  apiUrlNew + version : apiUrlNew; // todo check how to implement service version
    this.api_bearer = apiBearerNew;
    this.content_type = "application/json";
  }

  async request_handler() {
    return await this.request();
  }

  async request() {
    let { method, function_url, params, file, api_bearer, content_type } = this;
    let result = null;
    let headers = null;

    //remove the handle_action params without deleting them from the original object to be able to use the variables inside the action after the request
    if(validateVar(params) && validateVar(params.internal)) {
      params = {...params};
      delete params.internal;
    }

    if (validateVar(file)) {
      // if file is uploaded
      let body = null;
      body = new FormData();
      body.append("file", file);
      for (let [key, value] of Object.entries(params))
        body.append(key, value);
      headers = {
        Authorization: api_bearer
      };
      result = await this.execute_request(body, headers, params);
    } else {
      // handle normal request (without file input)
      let has_params = false;
      headers = {
        Authorization: api_bearer,
        "Content-Type": content_type
      };

      // if method === GET || PUT we have to append the params to the function_call
      if (method === "GET") {
        let param_string = "";
        if (validateVar(params)) {
          // only loop params if params given
          for (const [key, value] of Object.entries(params)) {
            // loop though all params to append the values to the function_call_url
            let connection = validateVar(param_string) ? "&" : "?";
            if (Array.isArray(value)) {
              // todo is array function
                for (let index in value) {
                  if (value.hasOwnProperty(index)) {
                      let current_value = value[index];
                      param_string =
                          param_string +
                          connection +
                          key +
                          "[" +
                          index +
                          "]=" +
                          current_value;
                  }
                  connection = validateVar(param_string) ? "&" : "?";

                }
            } else if (validateVar(value) && typeof value === "object") {
              // loop through object
              for (const [sub_key, sub_value] of Object.entries(value)) {
                param_string =
                  param_string +
                  connection +
                  key +
                  "[" +
                  sub_key +
                  "]=" +
                  sub_value;
                connection = validateVar(param_string) ? "&" : "?";
              }
            } else {
              // normal value
              param_string = param_string + connection + key + "=" + value;
            }
          }
        }
        this.function_url = function_url + param_string;
      } else has_params = true;

      let body = null;
      if (has_params) {
        body = JSON.stringify(params);
      } // this.params is object with params for request (only used when not "GET" OR "DELETE" Method
      result = await this.execute_request(body, headers, params);
    }
    return result;
  }

  static async return_blob(f, headers_file_name, download_contract, return_blob = false){
    let newBlob = null;
    let blob = await f.blob();
    newBlob = new Blob([blob], {type: f.headers.get('Content-Type')});
    newBlob.name = headers_file_name;
    let data = window.URL.createObjectURL(newBlob);

    if (is_true(return_blob)) return data;
    else if (window.navigator && window.navigator.msSaveOrOpenBlob && download_contract) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return true;
    } else {
      return {
        file_name: headers_file_name,
        url: data,
      };
    }
  }

  async execute_request(body, headers, params) {
    let {method, function_url, api_url, is_download} = this;
    try {
      let fetch_res = await fetch(api_url + function_url, {
        method: method,
        headers: headers,
        body: body
      });

      if (fetch_res.status === 200) {
        if (!is_download) {
          // normal request
          return await fetch_res.json();
        } else {
          // download file
          let file_name_headers = 'download';

          if(validateVar(params) && validateVar(params.file_name)) file_name_headers = params.file_name;

              if (!isEmptyObject(fetch_res.headers.get("Content-Disposition"))) {
                file_name_headers = fetch_res.headers.get("Content-Disposition");
                  let possible_strings_to_replace = ['attachment;', 'filename=', 'filename*=', '"', ' ', ' '];
                  for (let to_replace of possible_strings_to_replace){
                      file_name_headers = file_name_headers.replaceAll(to_replace, '');
                  }
                  file_name_headers = decodeURIComponent(file_name_headers);
                  if (is_download === "return_link") {
                      return await Communication.return_blob(fetch_res, file_name_headers, true);
                  }
              } else {
                if (is_download === "return_link") {
                      return await Communication.return_blob(fetch_res, file_name_headers);
                  }
              }
              let url_obj = await Communication.return_blob(fetch_res, file_name_headers);
              var link = document.createElement("a");
              link.href = url_obj.url;
              link.download = url_obj.file_name;
              link.click();
            setTimeout(async function() {
              let newBlob = new Blob([url_obj], {type: file_name_headers});
              newBlob.name = file_name_headers;
              let data = window.URL.createObjectURL(newBlob);
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
          }
          return true;
        } return apiError;
    } catch (e) {
      console.log("error in request " + function_url);
      console.log("error: " + e);
      return apiError;
    }
  }
}

export default Communication;
