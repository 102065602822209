let initialState = null;

function expires(state = initialState, action) {
    if (action.type === "EXPIRES_AT") {
        return action.res;
    } else {
    return state;
  }
}

export default expires;
