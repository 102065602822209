import validateVar from "./validation/validateVariable";
import is_true from "./is_true";

/*
    default for multiselect -> if is normal select set param
 */
function dataToSelectField(key, value, data, compare_data = null, is_normal_selectbox = false, is_object = false) {

    let value_ = "value"; //multi select input
    let label_ = "label";
    if (is_normal_selectbox) { //standard select input
        value_ = "key";
        label_ = "value";
    }
    let select_field = [];
    if(Array.isArray(data)){
        data.map((row) => {
                let disabled = false;
                if (validateVar(compare_data)) { //compare data is an object of options which have to be disabled
                    let result = compare_data.find(x => x[key] === row[key]);
                    if (validateVar(result)) disabled = true;
                }
                select_field.push({
                    [value_]: row[key] + "",
                    [label_]: row[value] + "",
                    disabled: disabled
                })
            }
        );
        return select_field;
    } else if(is_true(is_object)){
        for(let [key, value] of Object.entries(data)){
            let disabled = false;

            select_field.push({
                [value_]: key + "",
                [label_]: value + "",
                disabled: disabled
            })
        }
        return select_field;
    }return [];
}

export default dataToSelectField;
