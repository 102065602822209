import Communication from "./Components/Communication/communication_new";

import validateVar from "../Functions/validation/validateVariable";
import { apiError } from "../Functions/global";
import CommunicationNew from "./Components/Communication/communication_new";
import getAgencyID from "../Functions/getAgencyID";
import getCommunicationClass from "../Functions/getCommunication";

class Table {
  constructor(data) {
    this.id = "";
    this.layout_name = "";
    this.table_name = "";
    this.table_layout = [];
    this.is_selected = false;
    this.table_layout_array = [];
    this.type = "";

    //load basic info
    if (
      (validateVar(data) && validateVar(data.id)) ||
      (validateVar(data) && validateVar(data.agency_id))
    ) {
      //if data found
      this.id = validateVar(data.id) ? data.id : "";
      this.is_selected = validateVar(data.is_selected)
        ? data.is_selected
        : false;
      this.layout_name = validateVar(data.layout_name) ? data.layout_name : "";
      this.table_layout = validateVar(data.table_layout)
        ? data.table_layout
        : [];
      this.table_layout_array = validateVar(data.table_layout)
        ? this.get_selected_layouts(data.table_layout)
        : [];
      this.table_name = validateVar(data.table_name) ? data.table_name : "";
      this.component_name = validateVar(data.component_name)
        ? data.component_name
        : "";
      this.component = validateVar(data.component) ? data.component : "";
      this.disabled = validateVar(data.disabled) ? data.badge_name : false;
    }
  }

  get_selected_layouts(layouts) {
    let value_arr = [];
    for (let index in layouts) {
      if (layouts.hasOwnProperty(index)) {
        value_arr.push(layouts[index].value);
      }
    }
    return value_arr;
  }

  static async handle_table_actions(params, method) {
    let function_url = "platform/table_layout";
    if (method === "PUT" || method === "DELETE")
      function_url = function_url + "/" + params.layout_id;
      params = getAgencyID(params, true);

    // let c = new Communication(
    //   methode, // GET / POST / PUT / DELETE  -> method
    //   function_url,
    //   params
    // );


      let communication_params = {
          method: method, // GET / POST / PUT / DELETE  -> method
          function_url:  function_url,
          params: params
      };
      let c = getCommunicationClass(communication_params);


    let layout_result = await c.request_handler();
    if (layout_result !== apiError) {
      let return_obj = {};
      if (validateVar(params.is_list)) {
        let result_list = [];
        let layout_options = null;
        for (let index in layout_result) {
          if (layout_result.hasOwnProperty(index)) {
            layout_options = layout_result[index].options;
            result_list.push(new Table(layout_result[index]));
          }
        }

        return_obj.layouts = result_list;
        return_obj.options = layout_options;

        return return_obj;
      } else {
        return {
          layouts: new Table(layout_result),
          options: layout_result.options
        };
      }
    } else return apiError;
  }
}

export default Table;
