import validateVar from "./validateVariable";
import is_true from "../is_true";
const two_values_operators = ['AND'];
const operators = {
    GE: function(ge, x, xparam = 'comparison_value_1') {return +ge.comparison_value_1 <= +x[xparam]},
    LE: function(le, x, xparam= 'comparison_value_1') {return +le.comparison_value_1 >= +x[xparam]},
    EQ: function(eq, x, xparam= 'comparison_value_1') {return +eq.comparison_value_1 === +x[xparam]},
    AND: function(and, x, xparam= 'comparison_value_1') {return +and.comparison_value_1 <= +x[xparam] && +and.comparison_value_2 >= +x[xparam]},
};


function compare_and_validate_tree_branches(branch_index, branches, submit_mode = false, type = null){
    let has_error = false;
    let branch_current = branches[branch_index];
    let used_options = [];

    for(let index in branches){
        if(branches.hasOwnProperty(index)){
            let comp_branch = branches[index];

            let current_branch_empty = check_if_empty(branch_current);
            let comp_branch_empty = check_if_empty(comp_branch);
            if(!has_error && current_branch_empty && submit_mode) has_error = true; //set error in submit_mode if the current branch has no valid value
            /* Don't validate when:
                - error was already found for this branch
                - current_branch & comp_branch indexes are the same
                - one of the branches has an empty value
             */

            if(!has_error && +branch_index !== +index && !comp_branch_empty && !current_branch_empty && type !== "multiselect"){
                has_error = validate(branch_current, comp_branch)
            }else if(!has_error && !current_branch_empty && +branch_index === +index && two_values_operators.includes(branch_current.comparison_operator)){// check if value 1 is lower than value 2 (value 1 = GE and value 2 = LE)
                has_error = operators['LE'](branch_current, branch_current, 'comparison_value_2'); // compare AND value_1 with value_2
            }

            if (type === "multiselect" && +branch_index !== +index) { // get used options for multiselect
                if (validateVar(comp_branch.comparison_value_1) && Array.isArray(comp_branch.comparison_value_1) && comp_branch.comparison_value_1.length > 0) {
                    // used_options.push(comp_branch.comparison_value_1);
                    used_options = used_options.concat(comp_branch.comparison_value_1);
                }
            }
        }
    }
    if (is_true(has_error)) has_error = "Bitte tätigen Sie eine gültige Eingabe";
    // if (is_true(has_error_price)) has_error_price = "Bitte tätigen Sie eine gültige Eingabe";
    return {has_error: has_error, used_options: used_options};
}

function validate(branch_current, comp_branch){
    if (branch_current.type === "multiselect") return true; // do not validate for multiselect fields
    //compare for example EQ with LE (===)
    let compare_in_branch_error = false;

    let check_current_to_comp = operators[branch_current.comparison_operator](branch_current, comp_branch);
    //if the compare branch is for example an AND use comparison_value_2 for check also
    if(two_values_operators.includes(comp_branch.comparison_operator) && !check_current_to_comp){ // check if comp branch is overlapping with current
        check_current_to_comp = operators[branch_current.comparison_operator](branch_current, comp_branch, 'comparison_value_2');
    }

    //after compare of EQ with LE compare them reversed. LE with EQ (>=)
    let check_comp_to_current = operators[comp_branch.comparison_operator](comp_branch, branch_current);

    return (check_current_to_comp || check_comp_to_current || compare_in_branch_error);
}


function check_if_empty(branch){
    let contains_empty_values = false;
    let value_1 = branch.comparison_value_1;
    let value_2 = branch.comparison_value_2;
    if (Array.isArray(value_1) && value_1.length === 0)  contains_empty_values = true;
    else if(branch.comparison_operator === 'AND' && (!validateVar(value_1) || !validateVar(value_2))) contains_empty_values = true;
    else if(!validateVar(value_1)) contains_empty_values = true;
    return contains_empty_values;
}

export function check_valid_input_current_branch(branch){
    let check_values = [ // check this vars for not allowed values
    {
        value: branch.comparison_value_1,
        name: "comparison_value_1",
    },{
        value: branch.price,
        name: "price",
    },{
        value: branch.base_price,
        name: "base_price",
    }];

    if(branch.comparison_operator === 'AND') { // if operator === AND -> check comparison_value_2 as well
        check_values.push({
            value: branch.comparison_value_2,
            name: "comparison_value_2"}
        );
    }
    let has_error = false;
    let has_error_price = false;
    for (let values of check_values) {
        if (validateVar(values.value)) {
            let value = values.value+'';
            let last_char = value.substr(-1);
            if (last_char === "," ||  last_char === ".") {
                if ((values.name === "price") || (values.name === "base_price")) {
                    has_error_price = "Bitte tätigen Sie eine valide Eingabe";
                }
                else has_error = "Bitte tätigen Sie eine valide Eingabe";
            }
        } else {
            if ((values.name === "price") || (values.name === "base_price")) {
                has_error_price = "Bitte tätigen Sie eine valide Eingabe";
            }
        }
    }
    return {
        has_error: has_error,
        has_error_price: has_error_price
    };
}


export default compare_and_validate_tree_branches;