import {emptyValue} from "./global";

function getContractStatus(status, time_diff){
    /*
        if time_diff = empty value contract is not valid anymore
     */
    if (time_diff === emptyValue && status !== 'confirmed' && status !== 'cancelled') {
        if (status === "revoked") return status;
        else return "expired";
    }
    return status;
}

export default getContractStatus;