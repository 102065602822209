import React from 'react';
import AcceptChanges from "../Footers/AcceptChanges";
import GreyLinkFeedbackModalButton from "../Buttons/GreyLinkFeedbackModalButton";
import PropTypes from 'prop-types';
import cn from 'classnames';
import {Col, Row} from "reactstrap";
import validateVar from "../../Functions/validation/validateVariable";
import is_true from "../../Functions/is_true";
import is_false from "../../Functions/is_false";
import checkDisplayRequirements from "../../Functions/checkDisplayRequirements";
import isEmptyObject from "../../Functions/isEmptyObject";
import PrevStateDownloadValue from "./PrevStateDownloadValue";
import PrevStateValue from "./PrevStateValue";

/* E-Hub & Enermakler Component */
class ChangeableContractData extends React.Component {
    constructor(props) {
        super(props);
        this.object = {};
        this.agency_id = null;
        this.properties = props.properties;
        this.download = props.download;
        this.object_history = props.object_history;
        this.changes_detected = false;//display Accept/Decline actions
        this.history_id = null;//display Accept/Decline actions

        this.state = {
            is_loaded: false,
        };
    }

   componentDidUpdate(prevProps) {
       let {is_loaded, object, object_history} = this.props;

       if (validateVar(object) && object !== prevProps.object && is_true(is_loaded)) {
           this.object = object;
           this.setState({
               is_loaded: is_loaded,
           });
       }

       if (validateVar(object_history) && object_history !== prevProps.object_history) {
           this.object_history = object_history;
       }
   }

    check_front_end_display = (property) => {
        if(property.frontend_display){
            if (validateVar(property.display_requirements_frontend)) {
                return checkDisplayRequirements(this.object, property, true);
            } else return true;
        }else return false;
    };
   
   get_content = (is_loaded = false) => {
        let has_frontend_displays = false;
        let content = null;
        let has_history = false;
        //get the rows to render and check if current properties is going to be displayed by setting "has_frontend_displays"
          if (is_true(is_loaded)) {
              content = this.properties.map((property, index) => {
                  let history_obj = null;
                  let history_type = null;
                  let display_property = this.check_front_end_display(property);
                  if (!has_frontend_displays && display_property) has_frontend_displays = true;
                  // if ((validateVar(this.object_history) && typeof property.name === "object")) { // todo contract page update privat customer check
                  //     for (let name in property.name) {
                  //         if (property.name.hasOwnProperty(name)) {
                  //             if (validateVar(this.object[name])) {
                  //                 let conditioned_name = this.object[name]; // get the object value to get the name appended to a condition
                  //                 let filtered_name = property.name[name][conditioned_name]; // get the conditionaded value -> if null dont display -> else display conditioned value
                  //                 property.name = filtered_name;
                  //             }
                  //         }
                  //     }
                  // }
                  if ((validateVar(this.object_history) && validateVar(this.object_history[property.name]) && !isEmptyObject(this.object_history[property.name]))) { // || (validateVar(object_history) && validateVar(object_history[property.api_name]))) {
                      has_history = true;
                      history_obj = this.object_history[property.name];

                      //save history_id or history_ids to pass them in the history/respond request
                      if(!validateVar(this.history_id)) {
                          this.history_id = history_obj.history_id;
                          history_type = history_obj.history_type;
                      }
                      else if(!Array.isArray(this.history_id) && this.history_id !== history_obj.history_id) this.history_id = [{id: this.history_id, object_type: history_type}, {id: history_obj.history_id, object_type: history_obj.history_type}];
                      else if(Array.isArray(this.history_id) && !this.history_id.includes(this.history_id.find(obj => obj.id === history_obj.history_id))) this.history_id.push({id: history_obj.history_id, object_type: history_obj.history_type});

                      if(validateVar(this.object_history[property.name])) this.agency_id = this.object_history[property.name].agency_id;
                  }
                  if (validateVar(property.frontend_display) && display_property) {
                      return <Row>
                          <Col sm={12} md={4} lg={3} className='left-info-col title'>
                              <h4>{property.title}:</h4>
                          </Col>
                          <Col sm={12} md={8} lg={9} className='value'>
                              {property.download ? <PrevStateDownloadValue key={index}
                                              name={property.name}
                                              object={this.object}
                                              history_obj={history_obj}
                                              download={this.download} //download function
                                              is_loaded={is_loaded} /> :
                                  <PrevStateValue key={index}
                                                  name={property.name}
                                                  object={this.object}
                                                  history_obj={history_obj}
                                                  download={this.download} //download function
                                                  is_loaded={is_loaded} />
                              }
                          </Col>
                      </Row>
                  } else return null;
              });

              this.changes_detected = has_history;
          } else if (is_false(is_loaded)) {
              content = this.properties.map((property, index) => {
                  this.changes_detected = false; //reset if object updated
                  if (!has_frontend_displays) has_frontend_displays = this.check_front_end_display(property);

                  if (validateVar(property.frontend_display) && !is_false(property.loading_display)) {
                      return <Row>
                          <Col sm={12} md={4} lg={3} className='left-info-col title'>
                              <h4>{property.title}:</h4>
                          </Col>
                          <Col sm={12} md={8} lg={9} className='value'>
                              {property.download ? <PrevStateDownloadValue key={index}
                                                                           name={property.name}
                                                                           is_loaded={false} /> :
                                  <PrevStateValue key={index}
                                                  name={property.name}
                                                  is_loaded={false} />
                              }
                          </Col>
                      </Row>
                  } else return null;
              });
          }

          return {has_frontend_displays: has_frontend_displays, content: content};
   };

   get_changes = (edit_changes, toggle_modal, api_call, disable_edit_history_btn) => {
       return (is_true(this.changes_detected)) ? <div className='mt-4'>
               <AcceptChanges
                   question={"Möchten Sie die vorgeschlagenen Änderungen akzeptieren?"}
                   matching_agency_text={"Ihre Anfrage wird bearbeitet. Sie werden benachrichtigt sobald die Änderungen bearbeitet wurden."}
                   modal_secure_question_accept={"Möchten Sie die Änderungen im Auftrag wirklich akzeptieren?"}
                   modal_secure_question_discard={"Möchten Sie die Änderungen im Auftrag wirklich ablehnen?"}
                   accept_btn={"Akzeptieren"}
                   discard_btn={"Ablehnen"}
                   edit_changes={(type) => edit_changes(type, this.history_id, api_call)}
                   agency_id={this.agency_id}
                   disable_edit_history_btn={disable_edit_history_btn}
               />
           </div> :
           (toggle_modal && !disable_edit_history_btn) && <div className='pr-4'>
               <GreyLinkFeedbackModalButton onClick={toggle_modal}
                                            is_loaded={true}
                                            classes='text-right eless-color'
                                            text='Angaben ändern'/>
           </div>
   };


    render() {
        const {title, border_bottom, is_loaded, edit_changes, toggle_modal, api_call, disable_edit_history_btn} = this.props;

        let content_obj = this.get_content(is_loaded);
        if(!content_obj.has_frontend_displays) return null; //when has_frontend_displays is false, there is no property to be displayed here

        return <div className={'info-section' + cn({' custom-border-bottom': border_bottom})}>
            <h3>{title}</h3>
            {content_obj.content}
            {this.get_changes(edit_changes, toggle_modal, api_call, disable_edit_history_btn)}
        </div>
    }
}

ChangeableContractData.propTypes = {
    title: PropTypes.string,
    properties: PropTypes.object,
    object: PropTypes.object,
    object_history: PropTypes.object,
    changes_detected: PropTypes.bool,
    modal_toggler_text: PropTypes.string,
    is_loaded: PropTypes.bool.isRequired,
    edit_changes: PropTypes.func,
    toggle_modal: PropTypes.func,
};

export default ChangeableContractData;