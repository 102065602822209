import {SingleContract} from "../../globalNavigation";
import ConsumptionPointPage from "../Pages/Contracts/Components/ConsumptionPointPage";
import validateVar from "./validation/validateVariable";

function getMessageURL(obj_type, object_id, protocol_id, message_id = null) {
    let url = null;

    if (obj_type === "consumption_point")  {
        url = ConsumptionPointPage + "/" + object_id ;
    } else {
        if (validateVar(message_id)) {
            url = SingleContract + "/" + object_id + "/" + protocol_id + "/" + message_id;
        } else {
            url = SingleContract + "/" + object_id + "/" + protocol_id  ;
        }
    }
    return url;
}


export default getMessageURL;
