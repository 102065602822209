import { deHashVar } from "../Functions/handleHashedVariable";
import {
    apiCalculationAnalyticsService, apiError,
    apiFunctionAnalyticsAverageContracts, apiFunctionAnalyticsAverageTariffs,
    apiFunctionAnalyticsConsumptionPointLocation, apiFunctionAnalyticsRankingTariffs
} from "../Functions/global";
import getAgencyID from "../Functions/getAgencyID";
import validateVar from "../Functions/validation/validateVariable";
import getCommunicationClass from "../Functions/getCommunication";
const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));

class Analytics {
  static async get_analytics() {
    /* ConsumptionPointLocation */
      let params = getAgencyID({});
      let communication_params = {
          service: apiCalculationAnalyticsService,
          get_function: apiFunctionAnalyticsConsumptionPointLocation,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      let c = getCommunicationClass(communication_params);

    var res = await c.load();
    let data = {};
    if (res.status === "ok") {
      data.consumptionPointLocation = res.result;
    } else data.consumptionPointLocation = apiError;

    /* ConsumptionPointLocation */

      communication_params = {
          service: apiCalculationAnalyticsService,
          get_function: apiFunctionAnalyticsAverageContracts,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      c = getCommunicationClass(communication_params);
    res = await c.load();
    if (res.status === "ok") {
      data.averageContractsDetails = res.result;
    }else data.averageContractsDetails = apiError;
      communication_params = {
          service: apiCalculationAnalyticsService,
          get_function: apiFunctionAnalyticsAverageTariffs,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      c = getCommunicationClass(communication_params);
      res = await c.load();

    if (res.status === "ok") {
      data.averageTariffsDetails = res.result;
    }else data.averageTariffsDetails = apiError;
      communication_params = {
          service: apiCalculationAnalyticsService,
          get_function: apiFunctionAnalyticsRankingTariffs,
          update_function: "",
          delete_function: "",
          add_function: "",
          download_function: "",
          params: params,
      };
      c = getCommunicationClass(communication_params);
    res = await c.load();
    if (res.status === "ok") {
      data.tariffsRanking = res.result;
    } else data.tariffsRanking = apiError;
    return data;
  }

    static async handle_overview_tasks(params, method) {
        let function_url = "analytics/overview_tasks";
        params = getAgencyID(params, true);
        let agency_id = (validateVar(params.consultant_id)) ? params.consultant_id : (validateVar(params.supplier_id)) ? supplier_id : null;
        let test = getAgencyID({}, true);
        if (method === "GET" || method === "DELETE")
            function_url = function_url + "/" + test.agency_id;
        let communication_params = {
            method: method, // GET / POST / PUT / DELETE  -> method
            function_url:  function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);
        return await c.request_handler();
    }

    static async handle_chart_data(params, method) {
        let function_url = "analytics/timeline_contracts";
        params = getAgencyID(params);
        if (method === "GET" || method === "DELETE"){
            let agency_id =  (validateVar(params.supplier_id)) ? params.supplier_id : params.consultant_id;
            function_url = function_url + "/" + agency_id;
        }

        let communication_params = {
            method: method, // GET / POST / PUT / DELETE  -> method
            function_url:  function_url,
            params: params
        };
        let c = getCommunicationClass(communication_params);

        return await c.request_handler();
    }
}

export default Analytics;
