import React from 'react';
import {Row, Col} from "reactstrap";
import validateVar from "../../../../../Functions/validation/validateVariable";
import {apiError, gasNetfeeBasicInfo, static_download_url} from "../../../../../Functions/global";
class TariffInformation extends React.Component {
    create_data_rows = (rows, label_name, value_name) => {
        return rows.map((row) => {
            return (validateVar(row[label_name] && validateVar(row[value_name]))) ? <Row className='custom-border-bottom tariff-price-details-row'>
                <Col md={3} className='tariff-details-row-title'>
                    <b>{row[label_name]}</b>
                </Col>
                <Col md={9}>
                    {row[value_name]}
                </Col>
            </Row> : null;
        })
    };

    render() {
        var {tariff, net_fee, tariff_information, offer} = this.props;
        let spot = tariff.tariff_type === 'SPOT';
        let headline_values = [
            {label: "Anbieter", value: tariff.supplier_name},
            {label: "Beschreibung", value: tariff.description}
        ];
        
        let is_private_tariff = (tariff.customer_type === "PRIVATE");
        let spot_info = (is_private_tariff) ? "inkl" : "zzgl";
        let tariff_information_values = [
            {label: tariff_information.price_title, value: (spot) ? "Spotpreis + Service " + spot_info + ". Netzentgelte, Umlagen, Steuern & Abgaben" :
                    (is_private_tariff) ? tariff_information.price_display : tariff_information.price_display + ' zzgl. Netzentgelte, Umlagen, Steuern & Abgaben'},
            {label: "Grundgebühr", value: tariff_information.socket_display},
            {label: "Preisgarantie", value: tariff.price_guarantee_type_display},
            {label: "Ökoangebot", value: tariff.energy_source === 'green' ? 'Ja' : 'Nein'},
            {label: (is_private_tariff) ? null : "Mehr / Mindermengentoleranz", value: (is_private_tariff) ? null : tariff.quantity_tolerance_display} ,
            {label: "Autom. Verlängerung", value: tariff.contract_extension_display},
            {label: "Kündigungsfrist", value: tariff.cancellation_period_display},
            {label: "Vertragstyp", value: tariff.sign_type_display},
            {label: tariff.label_file_display, value: (validateVar(tariff.labelling_file_id)) ? <a target={"_blank"}
                                                          href={static_download_url + tariff.labelling_file_id}>
                    <span className='black-link-button'>Download</span>
                </a> : "Keine Datei hinterlegt"},
            {label: "AGB", value:   <a target={"_blank"} href={static_download_url + tariff.agb_file_id}>
                    <span className='black-link-button'>Download</span>
                </a>},
            {label: "Angebots-ID", value: offer.id},
        ];

        return(
            <div className="pt-4 px-4">
                {this.create_data_rows(headline_values, "label", "value")}
                {(tariff.medium_type === "GAS" && net_fee !== apiError && validateVar(net_fee)) &&
                    <>
                        {net_fee.map((net_fee) => {
                            if (gasNetfeeBasicInfo.includes(net_fee.name)) {
                                return <Row className='custom-border-bottom tariff-price-details-row'>
                                    <Col md={3} className='tariff-details-row-title'>
                                        <b>{net_fee.name_translated}</b>
                                    </Col>
                                    <Col md={9}>
                                        {net_fee.value_euro}
                                    </Col>
                                </Row>
                            } else return null;
                        })}
                    </>}
                {this.create_data_rows(tariff_information_values, "label", "value")}
            </div>
        );
    }
}

export default TariffInformation;