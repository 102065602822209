import React from 'react';
import PropTypes from 'prop-types';

const NotificationsCount = (props) => {

    if(props.count > 0){
        if(props.count > 99){
            return <span className='new-events-count endless'><span>99+</span></span>
        }else{
            if(props.count > 9) return <span className='new-events-count big'><span>{props.count}</span></span>
            else return <span className='new-events-count low'><span>{props.count}</span></span>
        }
    }else{
        return ''
    }
};

NotificationsCount.propTypes = {
    count: PropTypes.number.isRequired,
};

export default NotificationsCount;
