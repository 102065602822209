import {project_name, project_names} from "../Functions/global";
import Chronik from "../Objects/chronik";

let initialState = null;

function chronik(state = initialState, action) {
  if (action.type === "INIT_CHRONIK") {
    return action.res;
  } else if (action.type === "ADD_CHRONIK") {
    let new_state = [];
    if (project_names.wattline === project_name) new_state.push(new Chronik(action.res));
    state.forEach(element => {
      new_state.push(element);
    });
    if (project_names.wattline !== project_name) new_state.push(action.res); //Chronik object's "create" function already creates Class out of response
    return new_state;
  } else {
    return state;
  }
}

export default chronik;
