import Calculation from "../Objects/calculation";
import validateVar from "../Functions/validation/validateVariable";

let initialState = {};

function calculation(state = initialState, action) {
  let new_state = {...state};
  if (action.type === "CALCULATED") {
    return get_return_state("calculation", action.res, new_state);
  } else  if (action.type === "CALCULATE_RESET") {
    return get_return_state("calculation", action.res, new_state);
  } else if(action.type === "CALCULATE_DATE") {
    return get_return_state("date", action.res, new_state); //TODO im Frontend zum laufen bringen
  } else if(action.type === "GET_MARGINS") {
    let margins = [];
    let response = action.res;
    if(validateVar(action.res.margins)) response = action.res.margins;
    for(let margin of response){
      margins.push(new Calculation(margin));
    }
    return get_return_state("margins", margins, new_state);
  } else return state;
}

function get_return_state(name, result, state) {
  return {
    calculation: name === "calculation" ? result : state.calculation,
    date: name === "date" ? result : state.date,
    margins: name === "margins" ? result : state.margins,
  };
}

export default calculation;
