let initialState = null;

function buergel(state = initialState, action) {
    if (action.type === "VALIDATE_CUSTOMER_NAME") {
      return action.res;
    } else if (action.type === "RESET_BUERGEL") return action.res;
    else {
    return state;
  }
}

export default buergel;
