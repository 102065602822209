let initialState = {};

function tree_attributes(state = initialState, action) {
  let new_state = JSON.parse(JSON.stringify(state));
  if(action.type === "INIT_ALL_ATTRIBUTES" || action.type === "INIT_GLOBAL_ATTRIBUTES"){
      return {
          global_attributes: action.type === "INIT_ALL_ATTRIBUTES" ? new_state.global_attributes : action.res.attributes,
          attributes: action.type === "INIT_GLOBAL_ATTRIBUTES" ? state.attributes : action.res.attributes,
      }
  }else return state;
}

export default tree_attributes;
